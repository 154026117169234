import { FC, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UserRole } from '@shared/types';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const KakaoCallbackPage: FC = function KakaoCallbackPage() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const roles = JSON.parse(searchParams.get('roles') ?? `["${UserRole.BugoCustomer}"]`);
  const navigate = useNavigate();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  // const [kakaoMethod, setKakaoMethod] = useRecoilState(kakaoMethodSelector);
  // const [impUid, setImpUid] = useRecoilState(impUidSelector);

  const { signupOrLoginByKakao } = useAuth();

  const redirectUrlAfterLogin = sessionStorage.getItem('redirectUrlAfterLogin');

  useEffect(() => {
    const run = async () => {
      // code 유무 확인
      if (!code) {
        navigate(-1);
      }
      // code 있음
      else {
        console.log({ code, roles });
        await signupOrLoginByKakao({ code, roles });
        notificateSuccess(notifiacationInstance, '로그인 성공');
        navigate(redirectUrlAfterLogin ?? '/');

        // if (kakaoMethod === KakaoMethod.REGISTER_CHECK || kakaoMethod === null) {
        //   const user = await isRegisteredByKakao(code);
        //   if (user) {
        //     setKakaoMethod(KakaoMethod.LOGIN);
        //     window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;
        //   } else {
        //     setKakaoMethod(KakaoMethod.SIGNUP);
        //     navigate(`/auth/callback/ci/?kakao=1`);
        //   }
        // } else if (kakaoMethod === KakaoMethod.LOGIN) {
        //   setKakaoMethod(KakaoMethod.REGISTER_CHECK);
        //   await kakaoLogin(code);
        //   toastSuccess('로그인 성공');
        //   navigate(redirectToAfterLogin ? redirectToAfterLogin : '/flower-store');
        //   setRedirectToAfterLogin('/flower-store');
        // } else if (kakaoMethod === KakaoMethod.SIGNUP) {
        //   setKakaoMethod(KakaoMethod.REGISTER_CHECK);
        //   if (impUid) {
        //     // 기존 유저가 있는지 일단 확인
        //     const user = await isRegisteredByCi(impUid);
        //     // 있으면 바로 링크 진행
        //     if (user) {
        //       await loginByCi(impUid);
        //       await linkByKakao(code);
        //       setKakaoMethod(KakaoMethod.LOGIN);
        //       window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;
        //     } else {
        //       await signupByKakao({
        //         code: code,
        //         imp_uid: impUid ?? '',
        //         roles: [Role.CUSTOMER],
        //       });
        //     }
        //     setImpUid(null);
        //     navigate(redirectToAfterLogin ? redirectToAfterLogin : '/flower-store');
        //   } else {
        //     toastError('본인인증정보가 없습니다. 인증 후 다시 시도해주세요');
        //   }
        // } else if (kakaoMethod === KakaoMethod.UPGRADE) {
        //   // setKakaoMethod(KakaoMethod.REGISTER_CHECK);
        //   if (!dtoken.isTempCustomer()) {
        //     console.log('already upgraded');
        //     navigate(-1);
        //   }
        //   if (impUid) {
        //     await upgradeByKakao({ code: code, imp_uid: impUid });
        //     setKakaoMethod(KakaoMethod.REGISTER_CHECK);
        //     setImpUid(null);
        //     navigate(redirectToAfterLogin ? redirectToAfterLogin : '/flower-store');
        //   } else {
        //     navigate(`/auth/callback/ci/?kakao=1&&title=카카오로 회원전환`);
        //   }
        // } else if (kakaoMethod === KakaoMethod.LINK) {
        //   setKakaoMethod(KakaoMethod.REGISTER_CHECK);
        //   try {
        //     await linkByKakao(code);
        //     toastSuccess('카카오링크 완료');
        //     setKakaoMethod(KakaoMethod.LOGIN);
        //     window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_API_KEY}&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code`;
        //   } catch (err: any) {
        //     toastError(err?.response?.msg);
        //   }
        // } else if (kakaoMethod === KakaoMethod.CHANGE) {
        //   setKakaoMethod(KakaoMethod.REGISTER_CHECK);
        //   await changeKakao(code);
        // }
      }
    };
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className="h-screen w-full">{/* <Loading></Loading> */}</div>;
};
