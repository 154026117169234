import { FC, useMemo } from 'react';

import { SellerSidebar } from '@service/seller/containers/SellerSideBar/SellerSidebar';
import { SellerTopNavBar } from '@service/seller/containers/SellerTopNavBar/SellerTopNavBar';
import SellerBugoSearchPage from '@service/seller/page/SellerBugoSearchPage';
import SellerPastDeliverySearchPage from '@service/seller/page/SellerPastDeliverySearchPage';
import SellerShopInfoPage from '@service/seller/page/SellerShopInfoPage';
import SellerShopItemInfoManageMainPage from '@service/seller/page/SellerShopItemInfoManageMainPage';
import SellerShopItemManageByShopItemInfoPage from '@service/seller/page/SellerShopItemManageByShopItemInfoPage';
import SellerShopItemManageMainPage from '@service/seller/page/SellerShopItemManageMainPage';
import { SellerWaitingListPage } from '@service/seller/page/SellerWaitingListPage';
import { useSellerShopHook } from '@shared/api/shop/shop.hook';
import { ErrorFallback } from '@shared/components/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes, useParams } from 'react-router-dom';

import { SellerAccountRouter } from './SellerAccountRouter';
import { SellerShopUseShopOrderRouter } from './SellerShopUseShopOrderRouter';

export const ShopRouter: FC = function ShopRouter() {
  // !공지사용x
  // useAnnouncePopupHook();
  const { shopId } = useParams();
  const shopId_ = shopId ?? 'undefined';
  useSellerShopHook(shopId_);

  const topNavBarRender = useMemo(() => {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SellerTopNavBar />
      </ErrorBoundary>
    );
  }, []);

  const sideBarRender = useMemo(() => {
    return <SellerSidebar shopId={shopId_} />;
  }, [shopId_]);

  return (
    <>
      {sideBarRender}
      <div className="min-h-full lg:pl-64">
        <div className="flex flex-1 flex-col">
          {topNavBarRender}
          <Routes>
            <Route path="info" element={<SellerShopInfoPage />} />
            <Route path="*" element={<SellerShopUseShopOrderRouter />} />
            <Route path="past-delivery" element={<SellerPastDeliverySearchPage />} />
            <Route path="bugo-search" element={<SellerBugoSearchPage />} />
            <Route path="waiting-list" element={<SellerWaitingListPage />} />
            <Route path="account/*" element={<SellerAccountRouter />} />
            <Route path="shop-item">
              <Route index element={<SellerShopItemManageMainPage />} />
              <Route
                path="shop-item-info/:shopItemInfoId"
                element={<SellerShopItemManageByShopItemInfoPage />}
              />
            </Route>
            <Route path="shop-item-info">
              <Route index element={<SellerShopItemInfoManageMainPage />} />
            </Route>
            {/* <Route path="shop-item" element={<ShopItemManagePage />} /> */}
            {/* <Route path="settings" element={<ShopSettingsPage />} /> */}
          </Routes>
        </div>
      </div>
    </>
  );
};
