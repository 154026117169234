import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopItemInBugo } from '@shared/api/shopItem/shopItem.interface';
import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { Link } from 'react-router-dom';

interface Props {
  shopItem?: ShopItemInBugo;
  bugoId: string;
  openId: string;
}

export const SameItemBuyModal: FC<Props> = function SameItemBuyModal({
  shopItem,
  bugoId,
  openId,
}: Props) {
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const onCloseModal = () => {
    setOpen(false);
    history.back();
  };
  if (shopItem) {
    const shopItemImageUrl = shopItem.image?.url ?? shopItem.shopItemInfo?.image?.url;

    return (
      <Modal openId={openId} position="center">
        <div
          className="grid"
          style={{
            gridTemplateColumns: 'minmax(0, 0.4fr) minmax(0, 0.6fr)',
          }}
        >
          <div className="aspect-w-10 aspect-h-10 w-full overflow-hidden">
            <img
              src={`${process.env.REACT_APP_BUCKET_URL}/w400/${shopItemImageUrl}`}
              alt="상품이미지"
              className="object-cover"
            />
          </div>
          <div className="flex h-full flex-col justify-around gap-y-3">
            <div>
              <p className="whitespace-pre-line break-keep text-18 font-bold">
                {shopItem.name}
              </p>
            </div>
            <p className="whitespace-pre-line break-keep text-17 font-medium">
              동일한 상품으로 마음을 전하시겠습니까?
            </p>
            <div className="flex gap-8">
              <Link
                to={`/flower-store/item/${shopItem._id}/checkout/?bugoId=${bugoId}`}
                className="center-box"
              >
                <Button className="h-10 w-fit rounded-none px-4 text-17 text-white theme-bg-main sm:px-6 sm:text-19">
                  확인
                </Button>
              </Link>
              <Button
                className="filled-gray-300 h-10 w-fit rounded-none px-4 text-17 sm:px-6 sm:text-19"
                onClick={onCloseModal}
              >
                취소
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};
