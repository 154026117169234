//* 부고 재전송 모달
import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { sendCourtesy } from '@shared/api/bugo/bugo.controller';
import { feventHookUrl } from '@shared/api/fevent/fevent.hook';
import Modal from '@shared/components/Modal';
import { Icon } from '@shared/components/icons';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { AlertMethod } from '@shared/types';
import { useMutation, useQueryClient } from 'react-query';
import kakaoTalk from 'src/assets/image/kakaoTalk.png';
interface Props {
  bugoId: string;
  feventId: string;
  memberName: string;
  openId: string;
}

export const CourtesyReSendModal: FC<Props> = function CourtesyReSendModal({
  bugoId,
  feventId,
  memberName,
  openId,
}: Props) {
  const { bugoRole } = useAuth();
  const { BugoMessage } = Icon();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();
  const { isLoading: sendingBugo, mutateAsync: mutateSendAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(feventHookUrl(feventId, bugoRole()));
        notificateSuccess(notifiacationInstance, '답례글발송 성공');
      },
    },
  );

  const sendCourtesyAlimTalkFunc = async () => {
    await sendCourtesy([bugoId], bugoRole(), AlertMethod.KAKAO);
  };
  const sendCourtesyMessageFunc = async () => {
    await sendCourtesy([bugoId], bugoRole(), AlertMethod.MESSAGE);
  };

  return (
    <Modal openId={openId}>
      <div className="space-y-4 px-8">
        <h3 className="bugo-h1 text-center">상주님에게 답례글 재전송</h3>
        <p className="text-center font-medium">{memberName}님</p>
        <div className="center-box gap-8 pt-4">
          <div className="flex flex-col items-center justify-center gap-1">
            <button
              className="center-box wh-14 cursor-pointer rounded-md bg-yellow-300 p-1 shadow-md"
              disabled={sendingBugo}
              onClick={async () => {
                await mutateSendAsync(sendCourtesyAlimTalkFunc());
              }}
            >
              <div className="rounded-md bg-kakao">
                <img src={kakaoTalk} alt="카카오톡" />
              </div>
            </button>
            <p className="text-sm font-medium">카카오톡</p>
          </div>
          <div className="flex flex-col items-center justify-center gap-1">
            <button
              className="center-box wh-14 rounded-md bg-blue-100 p-2 shadow-md"
              disabled={sendingBugo}
              onClick={async () => {
                await mutateSendAsync(sendCourtesyMessageFunc());
              }}
            >
              <BugoMessage className="h-full w-full theme-fill-main" />
            </button>
            <p className="text-sm font-medium">문자</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
