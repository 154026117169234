import { FC, ReactNode, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { Loading } from '@shared/components/Loading';
import Tabs from '@shared/components/Tabs';
import { useParams, useSearchParams } from 'react-router-dom';

import FeventDeceasedInfoForm from '../containers/FeventDeceasedInfoForm/FeventDeceasedInfoForm';
import FeventMournerInfoForm from '../containers/FeventMournerInfoForm/FeventMournerInfoForm';
import { useTitleHook } from '../hooks/useTitleHook';

const getEditPage = (
  step: number,
  fevent: FeventOne,
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>,
): ReactNode | null => {
  switch (step) {
    case 0:
      return (
        <FeventDeceasedInfoForm
          fevent={fevent}
          editMode={true}
          setSelectedTab={setSelectedTab}
        />
      );
    case 1:
      if (fevent) {
        return <FeventMournerInfoForm fevent={fevent} editMode={true} />;
      } else {
        return;
      }
    default:
      return <FeventDeceasedInfoForm fevent={fevent} editMode={true} />;
  }
};

const FeventEditPage: FC = () => {
  useTitleHook('부고 수정', undefined, undefined, true, '/');
  const { feventId } = useParams();
  const [searchParams, _] = useSearchParams();
  const step = searchParams.get('step');
  const stepNumber = Number(step);

  const feventState = useRecoilValue(feventStateSelectorFamily(feventId ?? 'undefined'));

  const titleItems = ['고인정보', '상주정보'];
  const [selectedTab, setSelectedTab] = useState<number>(stepNumber);

  const formRender = useMemo(() => {
    if (feventId) {
      if (feventState.status === 'success' && feventState.data) {
        const fevent = feventState.data;
        return getEditPage(selectedTab, fevent, setSelectedTab);
      } else {
        return (
          <div className="center-box h-screen-15">
            <Loading />
          </div>
        );
      }
    } else {
      return null;
    }
  }, [feventId, feventState.data, feventState.status, selectedTab]);

  return (
    <div className="relative space-y-6 p-6">
      {/* tabs */}
      <div className="fixed top-14 left-0 z-30 w-full border-t border-gray-200 bg-white">
        <Tabs
          items={titleItems}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          className="bg-none"
        ></Tabs>
      </div>
      <div className="pt-4">{formRender}</div>
    </div>
  );
};

export default FeventEditPage;
