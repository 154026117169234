// mongodb에 user 정보가 없는 회원(회원가입 직후 최초 1회)이 추가정보 입력하는 페이지
import React, { FC } from 'react';

import { BugoSignupInitForm } from '@service/bugo/components/BugoSignupInitForm';
import { useTitleHook } from '@service/bugo/hooks/useTitleHook';

const SignupInitPage: FC = () => {
  useTitleHook('추가정보 입력');
  return (
    <React.Fragment>
      {/* Title */}
      <div className="mt-8 pl-4 pr-4">
        <div className="px-3 pt-4 pb-4 shadow-smd theme-bg-JM05">
          {/* SubmitForm */}
          <BugoSignupInitForm />
        </div>
      </div>
    </React.Fragment>
  );
};

export { SignupInitPage };
