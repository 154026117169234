export enum FuneralHomeInfoRequestStatus {
  Todo = 'Todo',
  Reject = 'Reject',
  Done = 'Done',
}

export type FuneralHomeInfoRequest<FuneralHomeInfo = string, Image = string> = {
  _id: string;
  funeralHomeInfo: FuneralHomeInfo;
  address: string;
  coord: {
    x: string;
    y: string;
  };
  name: string;
  phoneNumber: string;
  postalCode?: string;
  parkingAddress: string;
  parkingGuideImage?: Image;
  status: FuneralHomeInfoRequestStatus;
};

export type FuneralHomeInfoEmbed = Omit<
  FuneralHomeInfoRequest,
  '_id' | 'funeralHomeInfo' | 'status' | 'parkingGuideImage'
>;
