import { selector } from 'recoil';

import { Bugo } from '@shared/api/bugo/bugo.interface';
import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Shop } from '@shared/api/shop/shop.interface';
import { QueryDataState } from '@shared/interfaces';
import { RoomInfo } from '@shared/interfaces/roomInfo.interface';

import { Member } from '../member/member.interface';
import { callOrderListDataAtom, callOrderListStatusAtom } from './callOrder.atom';
import { CallOrder } from './callOrder.interface';

export const sellerCallOrderListStateSelector = selector<
  QueryDataState<
    CallOrder<
      Bugo<string, Fevent<string, FuneralHomeInfo, RoomInfo>, string, Member>,
      Shop
    >[]
  >
>({
  key: 'sellerCallOrderListStateSelector',
  get: ({ get }) => {
    return {
      status: get(callOrderListStatusAtom),
      data: get(callOrderListDataAtom),
    };
  },
});
