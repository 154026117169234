import { FC } from 'react';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { ShopOrderInBugo } from '@shared/api/shopOrder/shopOrder.interface';
import { PaymentState } from '@shared/types';

import { FlowerBottomBanner } from './FlowerBottomBanner';
import { FlowerEasyBuyModalButton } from './FlowerEasyBuyModalButton';
import { FlowerOrderListTable } from './FlowerOrderListTable';

interface Props {
  bugo: BugoData;
}

export const FlowerPromotionBox: FC<Props> = function ({ bugo }: Props) {
  //* bugo에 이미 shopOrders가 있음
  const shopOrderListInFevent = bugo.fevent.shopOrders.filter((order) => {
    return [PaymentState.PAID, PaymentState.CONFIRMED].includes(
      order.paymentDetail.status,
    );
  }) as unknown as ShopOrderInBugo[];

  const emptyEasyBuyCard = (
    <div className="w-full break-keep border border-gray-300 bg-white">
      <div className="my-2 flex items-center justify-between py-1 px-2 text-15">
        <div className="flex-1 text-center leading-5">
          <p className="px-2 font-medium">
            <span className="font-bold text-[#164E63]">첫번째로</span>
            &nbsp; 고인과 유가족 분들께 힘이 되어주세요
          </p>
        </div>
        <FlowerEasyBuyModalButton bugoId={bugo._id} />
      </div>
    </div>
  );

  const easyBuyCard = (
    <div className="w-full break-keep border border-gray-300 bg-white">
      <div className="my-2 flex items-center justify-between py-1 px-2 leading-5">
        <div className="flex-1 text-center font-bold">
          <p>고인과 유가족 분들께</p>
          <p>힘이 되어주세요</p>
        </div>
        <FlowerEasyBuyModalButton bugoId={bugo._id} />
      </div>
    </div>
  );

  return (
    <div className="space-y-3 break-keep">
      <div className="px-2">
        <p className="font-bold text-gray-500">꽃으로 마음을 보내신 분들</p>
      </div>

      {shopOrderListInFevent.length === 0 && emptyEasyBuyCard}
      {shopOrderListInFevent.length !== 0 && easyBuyCard}
      {shopOrderListInFevent.length > 0 && (
        <FlowerOrderListTable shopOrders={shopOrderListInFevent} bugoId={bugo._id} />
      )}
      <FlowerBottomBanner bugo={bugo} />
    </div>
  );
};
