import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { Button } from '@shared/components/Button';
import { Link, useParams } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';

export const FeventBugoPreviewLinkPage: FC = function FeventBugoPreviewLinkPage() {
  useTitleHook('부고 미리보기 (상주님 선택)');
  const { feventId } = useParams();
  const feventState = useRecoilValue(feventStateSelectorFamily(feventId ?? 'undefined'));

  const render = useMemo(() => {
    if (feventState.status === 'success' && feventState.data) {
      const fevent = feventState.data;
      const memberList = fevent.memberOrderList;
      return (
        <div>
          {memberList.map((item) => {
            if (item?.bugo?._id) {
              return (
                <div key={item._id} className="w-full py-1 text-center font-medium">
                  <Link to={`/bugo/${item?.bugo?._id}/preview`}>
                    {' '}
                    <Button type="button" key={item._id} className="w-full theme-bg-2">
                      <p className="text-base font-medium">
                        {item.relationType}&nbsp;&nbsp;
                        {item.fullName}
                      </p>
                    </Button>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      );
    }
  }, [feventState.data, feventState.status]);

  const bottomButton = useMemo(() => {
    return (
      <div className="fixed bottom-0 z-20 h-14 w-full max-w-inherit">
        <div className="h-full w-full theme-bg-12">
          <Link className="center-box h-full w-full gap-1 text-base font-bold" to={`/`}>
            <span className="text-white">완료(홈으로)</span>
          </Link>
        </div>
      </div>
    );
  }, []);
  return (
    <div className="relative max-w-inherit">
      <div className="p-4">{render}</div>
      {bottomButton}
    </div>
  );
};
