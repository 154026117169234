//* 멤버별 부고 발송을 위해 설정을 변경할 수 있고, 멤버별 부고발송여부등을 보여주는 카드
import { FC, useState } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { BugoReSendModal } from '@service/bugo/components/BugoReSendModal';
import { MemberBugoIFormInputs } from '@service/bugo/interfaces/memberBugoSend.interface';
import { createBugo, updateBugo } from '@shared/api/bugo/bugo.controller';
import { Bugo } from '@shared/api/bugo/bugo.interface';
import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { updateMember } from '@shared/api/member/member.controller';
import { Member } from '@shared/api/member/member.interface';
import { Button } from '@shared/components/Button';
import ResizableBox from '@shared/components/ResizableBox';
import { RoundedTag } from '@shared/components/RoundedTag';
import TelField from '@shared/components/TelField';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UserRole } from '@shared/types';
import { Control, UseFormRegister, useWatch } from 'react-hook-form';

const DURATION = 0.3;
interface Props {
  member: Member<string, Bugo, CashAccount>;
  index: number;
  register: UseFormRegister<MemberBugoIFormInputs>;
  control: Control<MemberBugoIFormInputs, any>;
}

export const MemberBugoSendCard: FC<Props> = function MemberBugoSendCard({
  member,
  index,
  register,
  control,
}: Props) {
  const { bugoRole, userProfile } = useAuth();
  const foldId = `memberBugoSendCard-${member._id}`;
  const [unfold, setUnFold] = useRecoilState(simpleOpenAtomFamily(foldId));
  const onUnFoldBtnClick = () => {
    setUnFold((curr) => !curr);
  };

  const reSendOpenId = `memberBugoSendCard-reSend-${member._id}`;
  const setReSendOpen = useSetRecoilState(simpleOpenAtomFamily(reSendOpenId));

  const phoneNumber =
    useWatch({
      control,
      name: `memberBugo.${index}.phoneNumber`,
      defaultValue: member.phoneNumber,
    }) ?? member.phoneNumber;

  const mournerText = useWatch({
    control,
    name: `memberBugo.${index}.mournerText`,
  });

  const [bugoId, setBugoId] = useState(member.bugo?._id ?? '');

  const updateMemberAndBugoBeforeSend = async () => {
    const memberUpdate = {
      _id: member._id,
      phoneNumber: phoneNumber,
    };

    await updateMember(memberUpdate, bugoRole());

    if (member?.bugo?._id) {
      const bugoUpdate = {
        _id: member.bugo?._id,
        mournerText: mournerText,
      };
      await updateBugo(bugoUpdate, bugoRole());
    } else {
      if (userProfile) {
        const bugoCreate = {
          user: userProfile._id,
          fevent: member.fevent,
          bugoBrand: userProfile.roles.includes(UserRole.BugoAgencyAdmin)
            ? userProfile.bugoAgencyAdminDetail?.bugoBrands[0]?._id
            : userProfile.bugoBrand,
          member: member._id,
          mournerText: mournerText,
          schedule: {
            courtesySend: false,
          },
          config: {
            isEnable: true,
          },
        };
        const bugo = await createBugo(bugoCreate, bugoRole());
        setBugoId(bugo._id);
      }
    }
  };

  return (
    <ResizableBox
      duration={DURATION}
      className="rounded-2xl text-sm font-bold shadow-smd theme-bg-1"
    >
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex h-full flex-wrap items-center gap-1.5">
          <h4 className="leading-7">{member.relationType}</h4>
          {member.fullName && (
            <RoundedTag text={member.fullName} className="theme-bg-5"></RoundedTag>
          )}
          {member.bugo?.lastSentPhoneNumber && (
            <RoundedTag text={'전송완료'} className="theme-bg-8"></RoundedTag>
          )}
        </div>
        <div className="flex">
          <Button
            type="button"
            onClick={() => onUnFoldBtnClick()}
            className={`button-no-border h-full break-keep px-1 text-xs leading-5 ${
              !unfold ? 'theme-text-main' : 'theme-text-8'
            }`}
          >
            {!unfold ? '재전송' : '접기'}
          </Button>
        </div>
      </div>
      <div className={`grid grid-cols-2 gap-2 px-2 pb-3 ${!unfold ? 'hidden' : 'block'}`}>
        <TelField
          value={phoneNumber}
          register={register}
          name={`memberBugo.${index}.phoneNumber`}
          className="w-full"
        />
        <Button
          className="button-rectangle h-full text-white theme-bg-main"
          onClick={async () => {
            //* bugo update 먼저
            await updateMemberAndBugoBeforeSend();
            setReSendOpen(true);
          }}
        >
          재전송
        </Button>
        <BugoReSendModal
          bugoId={bugoId}
          feventId={member.fevent}
          memberName={member.fullName}
          openId={reSendOpenId}
        />
        <div className="col-span-2 text-sm">
          <textarea
            placeholder={`개인별 상주말씀 (미작성시 고인정보에서 작성한 상주말씀이 적용됩니다.)`}
            className="input-box min-h-16 w-full p-2 font-medium theme-bg-1 theme-border-1 placeholder:theme-text-6"
            {...register(`memberBugo.${index}.mournerText`)}
          ></textarea>
        </div>
      </div>
    </ResizableBox>
  );
};
