import { FC } from 'react';

import MobileSelect from '@shared/components/select/MobileSelect';
import PCSelect, { IPCSelectProps } from '@shared/components/select/PCSelect';
import { ISelectOption } from '@shared/interfaces/select.interface';
import { useMediaQuery } from 'react-responsive';

/**
 * @param placeholder
 * @param optionList select의 option들.
 * @param control from react-hook-form
 * @param name from react-hook-form
 * @returns Select for desktop
 */
export const makeOptionList = (options: ISelectOption[] | string[]): ISelectOption[] => {
  if (options.length > 0) {
    if (typeof options[0] === 'string') {
      const typedOptions = options as string[];
      return typedOptions.map((option) => {
        return {
          value: option,
          label: option,
        };
      });
    } else {
      const typedOptions = options as ISelectOption[];
      return typedOptions.map((option) => {
        return {
          value: option.value,
          label: option.label,
        };
      });
    }
  } else {
    return [];
  }
};

const Select: FC<IPCSelectProps> = function Select({
  placeholder,
  optionList,
  control,
  name,
  defaultValue = '',
  className,
  optionClassName,
  ...props
}) {
  const options = makeOptionList(optionList);
  const isMobile = !useMediaQuery({
    query: '(min-width: 768px)',
  });

  return isMobile ? (
    <MobileSelect
      placeholder={placeholder}
      optionList={options}
      control={control}
      name={name}
      defaultValue={defaultValue}
      {...props}
    />
  ) : (
    <PCSelect
      placeholder={placeholder}
      optionList={options}
      control={control}
      name={name}
      defaultValue={defaultValue}
      className={className}
      optionClassName={optionClassName}
      {...props}
    />
  );
};

export default Select;
