import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { BottomPopup } from '@shared/components/BottomPopup';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { CustomCallback } from '@shared/types';
import { formatDate } from '@shared/utils/formatDate';
import { useId } from 'react-id-generator';

import DatePickerModal from './DatePickerModal';

interface Props {
  value?: Date | null;
  onChange: CustomCallback<Date | null, void>;
  placeholder?: string;
  className?: string;
}

export const DatePicker: FC<Props> = ({
  value,
  onChange,
  placeholder,
  className = '',
}: Props) => {
  const { CalendarPlaceHolder, Calendar, X } = Icon();
  const [id] = useId(1, 'datepicker');
  const openId = `datepicker-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const onClear = () => onChange && onChange(null);

  return (
    <>
      <div
        className={`date-picker flex cursor-pointer justify-between theme-bg-1 theme-border-1 ${className}`}
        onClick={() => {
          setOpen(true);
        }}
      >
        <div className="flex flex-1 items-center">
          {!value ? (
            <CalendarPlaceHolder className="stroke-none" />
          ) : (
            <Calendar className="stroke-none" />
          )}
          <p className={`${!value ? 'theme-text-6' : 'theme-text-1'}`}>
            {!value
              ? placeholder
              : formatDate(value, {
                  dateSeparater: '.',
                  contains: {
                    day: true,
                  },
                })}
          </p>
        </div>
        {value && <X onClick={onClear} className="hidden stroke-slate-400 md:block" />}
      </div>
      <BottomPopup openId={openId}>
        <div className="w-screen bg-transparent">
          <DatePickerModal value={value} onDateChange={onChange} openId={openId} />
        </div>
      </BottomPopup>
    </>
  );
};
