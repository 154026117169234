import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import {
  adminNavBackUrlAtom,
  adminNavBarUseAtom,
  adminNavBarUseNavLeftAtom,
  adminNavRightTextAtom,
  adminNavTitleAtom,
} from '@service/bugo/state';

export const useAdminTitleHook = function (
  title: string,
  rightText?: string,
  onRightTextClick?: () => void,
  use = true,
  backUrlString?: string,
  useNavLeft = true,
) {
  const setTitle = useSetRecoilState(adminNavTitleAtom);
  const setRightText = useSetRecoilState(adminNavRightTextAtom);
  const setUse = useSetRecoilState(adminNavBarUseAtom);
  const setNavLeftUse = useSetRecoilState(adminNavBarUseNavLeftAtom);
  const setBackUrl = useSetRecoilState(adminNavBackUrlAtom);

  useEffect(() => {
    setTitle(title);
    setRightText(rightText ?? '');
    setUse(use);
    setNavLeftUse(useNavLeft);
    if (backUrlString) {
      setBackUrl(backUrlString);
    }
    return () => {
      setTitle('');
      setRightText('');
      setUse(true);
      setNavLeftUse(true);
      setBackUrl(null);
    };
  }, [
    backUrlString,
    onRightTextClick,
    rightText,
    setBackUrl,
    setNavLeftUse,
    setRightText,
    setTitle,
    setUse,
    title,
    use,
    useNavLeft,
  ]);
};
