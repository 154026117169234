import { FC, useMemo, useState } from 'react';

import { SearchIcon } from '@heroicons/react/solid';
import { searchShopOrderListForSellerByShopUrl } from '@shared/api/shopOrder/shopOrder.hook';
import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { ErrorFallback } from '@shared/components/ErrorFallback';
import { api_ } from '@shared/plugIn/axios';
import { useAuth } from '@shared/state/hooks/useAuth';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { PastDeliveryManageContent } from '../containers/DeliveryManageContent/PastDeliveryManageContent';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

const SellerPastDeliverySearchPage: FC = function SellerPastDeliverySearchPage() {
  const { shopId } = useParams();
  useSellerTopNavHook('과거배송검색');
  const [searchText, setSearchText] = useState<string>('');
  const { sellerRole } = useAuth();

  const [shopOrderList, setShopOrderList] = useState<ShopOrderForSeller[] | undefined>(
    undefined,
  );

  const onSearch = async () => {
    const { data: shopOrderList } = await api_.get<ShopOrderForSeller[]>(
      searchShopOrderListForSellerByShopUrl(`${shopId}`, sellerRole(), searchText),
    );
    setShopOrderList(shopOrderList);
  };

  const searchBar = () => {
    return (
      <div className="relative flex w-full items-center theme-text-8 focus-within:theme-text-9">
        <input
          id="search-field"
          name="search-field"
          className="block h-full border py-4 pl-8 pr-3 placeholder-gray-500 theme-text-9 sm:text-sm"
          placeholder="배송 간편 검색"
          type="search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.currentTarget.value);
          }}
        />
        <div
          className="h-full cursor-pointer border-y border-r p-[15px] theme-bg-main theme-border-main"
          onClick={onSearch}
        >
          <SearchIcon className="h-5 w-5 theme-text-4" aria-hidden="true" />
        </div>
      </div>
    );
  };

  const deliveryManageBoxRender = useMemo(() => {
    if (shopOrderList) {
      return <PastDeliveryManageContent shopOrderList={shopOrderList} />;
    } else {
      return null;
    }
  }, [shopOrderList]);

  return (
    <div className="mx-auto max-w-6xl px-4 pb-8 sm:px-6 lg:px-8">
      <div className="mt-8 space-y-4">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {searchBar()}
          {deliveryManageBoxRender}
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default SellerPastDeliverySearchPage;
