import { FC } from 'react';

import AdminBugoBrandAddPage from '@service/admin/page/AdminBugoBrandAddPage';
import AdminBugoBrandAgencyAdminPage from '@service/admin/page/AdminBugoBrandAgencyAdminPage';
import { AdminBugoBrandAgencyBranchAdminPage } from '@service/admin/page/AdminBugoBrandAgencyBranchAdminPage';
import AdminBugoBrandAgencyWorkerPage from '@service/admin/page/AdminBugoBrandAgencyWorkerPage';
import AdminBugoBranEditPage from '@service/admin/page/AdminBugoBrandEditPage';
import AdminBugoBrandMultiAgencyEditPage from '@service/admin/page/AdminBugoBrandMultiAgencyEditPage';
import AdminBugoBrandPage from '@service/admin/page/AdminBugoBrandPage';
import { useBugoBrandListHook } from '@shared/api/bugoBrand/bugoBrand.hook';
import { Route, Routes } from 'react-router-dom';

const AdminBugoBrandRouter: FC = () => {
  useBugoBrandListHook();
  return (
    <Routes>
      <Route path="" element={<AdminBugoBrandPage />} />
      <Route path=":bugoBrandId">
        <Route path="edit" element={<AdminBugoBranEditPage />} />
        <Route path="multi-agency-edit" element={<AdminBugoBrandMultiAgencyEditPage />} />
        <Route path="agency-workers" element={<AdminBugoBrandAgencyWorkerPage />} />
        <Route path="agency-admin" element={<AdminBugoBrandAgencyAdminPage />} />
        <Route
          path="agency-branch-admin"
          element={<AdminBugoBrandAgencyBranchAdminPage />}
        />
      </Route>
      <Route path="add" element={<AdminBugoBrandAddPage />} />
    </Routes>
  );
};

export default AdminBugoBrandRouter;
