import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { sellerShopStateSelectorFamily } from '@shared/api/shop/shop.selector';
import { shopOrderListForSellerByShopAccountStateSelectorFamily } from '@shared/api/shopOrder/shopOrder.selector';
import { Loading } from '@shared/components/Loading';
import { useParams } from 'react-router-dom';

import { SellerAccountContent } from '../containers/SellerAccountContent/SellerAccountContent';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

const SellerAccountPage: FC = function SellerAccountPage() {
  useSellerTopNavHook('간편 정산내역');
  const { shopId } = useParams();
  const shopState = useRecoilValue(sellerShopStateSelectorFamily(shopId ?? 'undefined'));

  const shopOrderListState = useRecoilValue(
    shopOrderListForSellerByShopAccountStateSelectorFamily(`${shopId}`),
  );
  const shop = useMemo(() => {
    if (shopState.status === 'success' && shopState.data) {
      return shopState.data;
    }
  }, [shopState.data, shopState.status]);

  const render = useMemo(() => {
    if (shopOrderListState.status === 'success' && shopOrderListState.data && shop) {
      const shopOrderList = shopOrderListState.data;
      return <SellerAccountContent shopOrderList={shopOrderList} shop={shop} />;
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [shop, shopOrderListState.data, shopOrderListState.status]);

  return <div className="mx-auto max-w-6xl px-4 pb-8 sm:px-6 lg:px-8">{render}</div>;
};

export default SellerAccountPage;
