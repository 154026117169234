import { UserBugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import {
  agencyAdminCreateWorker,
  agencyAdminUpdateWorker,
} from '@shared/api/user/user.controller';
import { UserCustomTheme, UserRole } from '@shared/types';

import {
  BugoBrandAgencyWorkerFormInputs,
  BugoBrandAgencyWorkerFormInputsEnum,
} from './AgencyWorkerUpsertForm.interface';

//*bugoBrandAgencyWorker Upsert 함수
export const onAgencyWorkerUpsert = async (
  data: BugoBrandAgencyWorkerFormInputs,
  bugoBrand: UserBugoBrand,
  editMode: boolean,
  agencyWorkerId?: string,
) => {
  //수정
  if (editMode && agencyWorkerId) {
    const bugoBrandAgencyWorkerDto = {
      _id: agencyWorkerId,
      'info.name': data[BugoBrandAgencyWorkerFormInputsEnum.Name],
      'info.phoneNumber': data[BugoBrandAgencyWorkerFormInputsEnum.PhoneNumber],
      'bugoAgencyWorkerDetail.teamType':
        data[BugoBrandAgencyWorkerFormInputsEnum.TeamType],
      'bugoAgencyWorkerDetail.teamName':
        data[BugoBrandAgencyWorkerFormInputsEnum.TeamName],
      'bugoAgencyWorkerDetail.region': data[BugoBrandAgencyWorkerFormInputsEnum.Region],
    };
    const agencyWorkerUserUpdated = await agencyAdminUpdateWorker(
      bugoBrandAgencyWorkerDto,
    );
    return agencyWorkerUserUpdated;
  }
  // 생성
  else {
    if (
      data[BugoBrandAgencyWorkerFormInputsEnum.UserName] &&
      data[BugoBrandAgencyWorkerFormInputsEnum.Password]
    ) {
      const bugoBrandAgencyWorkerCreateDto = {
        username: data[BugoBrandAgencyWorkerFormInputsEnum.UserName],
        password: data[BugoBrandAgencyWorkerFormInputsEnum.Password],
        roles: [UserRole.BugoAgencyWorker, UserRole.StoreCustomer],
        info: {
          name: data[BugoBrandAgencyWorkerFormInputsEnum.Name],
          gender: '',
          birth: '',
          phoneNumber: data[BugoBrandAgencyWorkerFormInputsEnum.PhoneNumber],
          theme:
            (bugoBrand.bugoAgency?.theme as UserCustomTheme) ?? UserCustomTheme.Default,
        },
        bugoBrand: bugoBrand._id,
        bugoAgencyWorkerDetail: {
          cemeteryList: [],
          region: data[BugoBrandAgencyWorkerFormInputsEnum.Region],
          teamType: data[BugoBrandAgencyWorkerFormInputsEnum.TeamType],
          teamName: data[BugoBrandAgencyWorkerFormInputsEnum.TeamName],
        },
      };

      // 팀장 user 생성
      const agencyAdminWorkerCreated = await agencyAdminCreateWorker(
        bugoBrandAgencyWorkerCreateDto,
      );

      return agencyAdminWorkerCreated;
    }
  }
};
