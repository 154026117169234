import { Image } from '@shared/api/media/media.interface';

export enum BugoBrandMultiAgencyFormInputsEnum {
  CorpName = 'corpName',
  NickName = 'nickName',
  PhoneNumber = 'phoneNumber',
  Address = 'address',
  HeaderImage = 'headerImage',
  FooterImage = 'footerImage',
  Theme = 'theme',
  FrontUrl = 'frontUrl',
  RegAlimTalkWebDomain = 'regAlimTalkWebDomain',
  HomePageUrl = 'homepageUrl',
  KakaoAppJavascriptKey = 'kakaoAppJavascriptKey',
  KakaoTemplateBugoButtonId = 'kakaoTemplateBugoButtonId',
}

export interface BugoBrandMultiAgencyFormInputs {
  [BugoBrandMultiAgencyFormInputsEnum.CorpName]: string;
  [BugoBrandMultiAgencyFormInputsEnum.NickName]: string;
  [BugoBrandMultiAgencyFormInputsEnum.PhoneNumber]: string;
  [BugoBrandMultiAgencyFormInputsEnum.Address]: string;
  [BugoBrandMultiAgencyFormInputsEnum.HeaderImage]?: Image;
  [BugoBrandMultiAgencyFormInputsEnum.FooterImage]?: Image;
  [BugoBrandMultiAgencyFormInputsEnum.Theme]: string;
  [BugoBrandMultiAgencyFormInputsEnum.FrontUrl]: string;
  [BugoBrandMultiAgencyFormInputsEnum.RegAlimTalkWebDomain]: string;
  [BugoBrandMultiAgencyFormInputsEnum.HomePageUrl]: string;
  [BugoBrandMultiAgencyFormInputsEnum.KakaoAppJavascriptKey]: string;
  [BugoBrandMultiAgencyFormInputsEnum.KakaoTemplateBugoButtonId]: string;
}
