import { selectorFamily } from 'recoil';

import { QueryDataState } from '@shared/interfaces';

import { Image } from '../media/media.interface';
import {
  shopItemInfoListByShopDataAtomFamily,
  shopItemInfoListByShopStatusAtomFamily,
} from './shopItemInfo.atom';
import { ShopItemInfo } from './shopItemInfo.interface';

export const shopItemInfoListByShopStateSelectorFamily = selectorFamily<
  QueryDataState<ShopItemInfo<string, Image, Image>[]>,
  string
>({
  key: 'shopItemInfoListStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(shopItemInfoListByShopStatusAtomFamily(id)),
        data: get(shopItemInfoListByShopDataAtomFamily(id)),
      };
    },
});
