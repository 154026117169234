//* 문구정보 출력함

//! 사용되는 페이지 목록
//* OrderPaymentResultPage
//* OrderDetailPage

import { FC } from 'react';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';

interface Props {
  shopOrder: ShopOrderForStore;
}

export const PhraseInfoCard: FC<Props> = function PhraseInfo({ shopOrder }: Props) {
  const sender = shopOrder.bugoDetail.senderPhrase;
  const condolence = shopOrder.bugoDetail.condolencePhrase;

  return (
    <div className="border-t px-4 py-3 text-sm theme-bg-1 theme-border-1">
      {/* 보내는 사람 */}
      <div>
        <div className="theme-text-8">보내는 사람</div>
        <p className="mt-1 font-bold">{sender}</p>
      </div>
      {/* 조의 문구 */}
      <div className="mt-3">
        <div className="theme-text-8">조의문구</div>
        <p className="mt-1 font-bold">{condolence}</p>
      </div>
    </div>
  );
};
