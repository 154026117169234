import {
  ShopOrderForSeller,
  ShopOrderForStore,
} from '@shared/api/shopOrder/shopOrder.interface';
import { IconFormer } from '@shared/components/IconsFormer';
import { DeliveryState, PaymentState } from '@shared/types';
import Tag from 'antd/es/tag';

interface ProgressIndicatorProps {
  order: ShopOrderForSeller | ShopOrderForStore;
  size?: string;
}

const stateCircle = function stateCircle(
  name: string,
  index: number,
  state: number,
  size?: string,
) {
  if (size === 'small') {
    return (
      <div
        className="flex w-12 flex-col items-center justify-center gap-2 font-medium sm:w-20"
        key={index}
      >
        {name === 'ellipsis' ? (
          <div className="flex">
            <IconFormer.Ellipsis
              className={`wh-4 sm:wh-6 ${
                state >= index ? 'theme-fill-main' : 'fill-gray-400'
              }`}
            />
            <IconFormer.Ellipsis
              style={{ marginLeft: '-2px' }}
              className={`wh-4 sm:wh-6 ${
                state >= index ? 'theme-fill-main' : 'fill-gray-400'
              }`}
            />
          </div>
        ) : (
          <div
            className={`wh-6 relative z-10 flex items-center justify-center rounded-md sm:wh-10 sm:rounded-xl ${
              state >= index ? 'theme-bg-main' : 'border bg-white'
            }`}
          >
            {name === '결제완료' && (
              <IconFormer.Check
                className={`wh-4 sm:wh-6 ${
                  state >= index ? 'text-white' : 'text-gray-400'
                }`}
              />
            )}
            {name === '상품준비중' && (
              <IconFormer.Loading
                className={`wh-4 sm:wh-6 ${
                  state >= index ? 'fill-white' : 'fill-gray-400'
                }`}
              />
            )}
            {name === '배송중' && (
              <IconFormer.DeliveryBlue
                className={`wh-4 sm:wh-6 ${
                  state >= index ? 'fill-white' : 'fill-gray-400'
                }`}
              />
            )}
            {name === '배송완료' && (
              <IconFormer.LocationCheck
                className={`wh-4 sm:wh-6 ${
                  state >= index ? 'fill-white' : 'fill-gray-400'
                }`}
              />
            )}
            <p
              className={`absolute -bottom-1 translate-y-full break-keep text-12 sm:text-sm ${
                state >= index ? 'text-black' : 'text-gray-500'
              }`}
            >
              {name}
            </p>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div
        className="flex w-12 flex-col items-center justify-center gap-2 pb-12 font-medium sm:w-20"
        key={index}
      >
        {name === 'ellipsis' ? (
          <div className="flex">
            <IconFormer.Ellipsis
              className={`wh-6 sm:wh-8 ${
                state >= index ? 'theme-fill-main' : 'fill-white'
              }`}
            />
            <IconFormer.Ellipsis
              style={{ marginLeft: '-2px' }}
              className={`wh-6 sm:wh-8 ${
                state >= index ? 'theme-fill-main' : 'fill-white'
              }`}
            />
          </div>
        ) : (
          <div
            className={`wh-10 relative z-10 flex items-center justify-center rounded-2xl text-base sm:wh-14 sm:rounded-3xl sm:text-18 ${
              state >= index ? 'theme-bg-main' : 'bg-white'
            }`}
          >
            {name === '결제완료' && (
              <IconFormer.Check
                className={`wh-6 sm:wh-8 ${
                  state >= index ? 'text-white' : 'text-gray-400'
                }`}
              />
            )}
            {name === '상품준비중' && (
              <IconFormer.Loading
                className={`wh-6 sm:wh-8 ${
                  state >= index ? 'fill-white' : 'fill-gray-400'
                }`}
              />
            )}
            {name === '배송중' && (
              <IconFormer.DeliveryBlue
                className={`wh-6 sm:wh-8 ${
                  state >= index ? 'fill-white' : 'fill-gray-400'
                }`}
              />
            )}
            {name === '배송완료' && (
              <IconFormer.LocationCheck
                className={`wh-6 sm:wh-8 ${
                  state >= index ? 'fill-white' : 'fill-gray-400'
                }`}
              />
            )}
            <p
              className={`absolute -bottom-1 translate-y-full break-keep text-12 sm:text-base ${
                state >= index ? 'text-white' : 'text-gray-200'
              }`}
            >
              {name}
            </p>
          </div>
        )}
      </div>
    );
  }
};

const presentState = (order: ShopOrderForStore | ShopOrderForSeller) => {
  if ([PaymentState.PAID, PaymentState.CONFIRMED].includes(order.paymentDetail.status)) {
    switch (order.deliveryDetail.status) {
      case DeliveryState.BEFORE_PREPARING:
        return 0;
      case DeliveryState.PREPARING:
        return 2;
      case DeliveryState.DELIVERYING:
        return 4;
      case DeliveryState.DELIVERED:
        return 6;
      default:
        return 0;
    }
  } else return 0;
};

const states = [
  '결제완료',
  'ellipsis',
  '상품준비중',
  'ellipsis',
  '배송중',
  'ellipsis',
  '배송완료',
];
export const DeliveryProgressIndicator = function DeliveryProgressIndicator({
  order,
  size,
}: ProgressIndicatorProps) {
  const state = presentState(order);
  if (size === 'small') {
    if (order.paymentDetail.status === PaymentState.CANCELED) {
      return <Tag className="rounded-md p-2 font-medium">취소완료</Tag>;
    } else {
      return (
        <div className="relative flex h-full items-center justify-center bg-white p-4">
          {states.map((item, index) => {
            return stateCircle(item, index, state, size);
          })}
        </div>
      );
    }
  } else {
    if (order.paymentDetail.status === PaymentState.CANCELED) {
      return <Tag className="rounded-md p-2 font-medium">취소완료</Tag>;
    } else {
      return (
        <div className="relative flex h-full items-center justify-center p-4 text-12 text-white">
          {states.map((item, index) => {
            return stateCircle(item, index, state);
          })}
        </div>
      );
    }
  }
};
