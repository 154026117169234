import { CashAccount } from './cashAccount.interface';
const checkSameCashAccountInputAndCashAccountOld = (
  cashAccountInput: {
    name: string;
    bank: string;
    account: string;
  },
  cashAccountOld: CashAccount,
) => {
  return (
    cashAccountInput.name === cashAccountOld.name &&
    cashAccountInput.bank === cashAccountOld.bank &&
    cashAccountInput.account === cashAccountOld.account
  );
};

export { checkSameCashAccountInputAndCashAccountOld };
