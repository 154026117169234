import { useEffect } from 'react';

export interface ICssVariable {
  property: string;
  value: string | null;
}

export const useCssVariables = (param: ICssVariable | ICssVariable[]) => {
  useEffect(() => {
    // https://stackoverflow.com/questions/37801882/how-to-change-css-root-color-variables-in-javascript
    let array = [];

    if (Array.isArray(param)) array = [...param];
    else array.push(param);

    array.forEach((variable) =>
      document.documentElement.style.setProperty(variable.property, variable.value),
    );
  }, [param]);
};
