import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useId } from 'react-id-generator';

import { AgencyAdminAccountDetailModal } from './AgencyAdminAccountDetailModal';

interface Props {
  shopOrderList: ShopOrderAgencyAdmin[];
}

export const AgencyAdminAccountDetailModalButton: FC<Props> =
  function AgencyAdminAccountDetailModalButton({ shopOrderList }: Props) {
    const [id] = useId(1, 'agencyAdminDetailModalButton');

    const openId = `${id}`;

    const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
    return (
      <div>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          상세보기
        </Button>
        <AgencyAdminAccountDetailModal
          openId={openId}
          shopOrderList={shopOrderList}
        ></AgencyAdminAccountDetailModal>
      </div>
    );
  };
