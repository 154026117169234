import { atomFamily } from 'recoil';

import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { ShopOrder } from '@shared/api/shopOrder/shopOrder.interface';
import { QueryStatus } from 'react-query';

export type FeventListByWorkerId = Pick<
  Fevent<
    string,
    Pick<FuneralHomeInfo, '_id' | 'name'>,
    string,
    undefined,
    Pick<ShopOrder, '_id'>[]
  >,
  '_id' | 'createdAt' | 'funeralHomeInfoEmbed' | 'funeralHomeInfo' | 'shopOrders'
>[];

export type FeventListByWorkerIdState = {
  status: QueryStatus;
  data: FeventListByWorkerId | null;
};

export const feventListByWorkerIdStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'feventListByWorkerIdStatusAtomFamily',
  default: 'idle',
});

export const feventListByWorkerIdDataAtomFamily = atomFamily<
  FeventListByWorkerId | null,
  string
>({
  key: 'feventListByWorkerIdDataAtomFamily',
  default: null,
});
