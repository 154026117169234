import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import {
  CourtesyAsset,
  CourtesyAssetCreate,
  CourtesyAssetUpdate,
  CourtesyContent,
  CourtesyContentCreate,
  CourtesyContentUpdate,
} from './courtesyAsset.interface';

const createCourtesyAsset = async (
  courtesyAssetCreate: CourtesyAssetCreate,
  bugoRole: LowerRole,
) => {
  const { data: courtesyAsset } = await api_.post<CourtesyAsset>(
    `/${bugoRole}/courtesy-asset`,
    courtesyAssetCreate,
  );
  return courtesyAsset;
};

const updateCourtesyAsset = async (
  courtesyAssetUpdate: CourtesyAssetUpdate,
  bugoRole: LowerRole,
) => {
  const { data: courtesyAsset } = await api_.patch(
    `/${bugoRole}/courtesy-asset/${courtesyAssetUpdate._id}`,
    courtesyAssetUpdate,
  );
  return courtesyAsset;
};

const deleteCourtesyAsset = async (courtesyAssetId: string, bugoRole: LowerRole) => {
  await api_.delete(`/${bugoRole}/courtesy-asset/${courtesyAssetId}`);
};

const createCourtesyContent = async (
  courtesyContentCreate: CourtesyContentCreate,
  bugoRole: LowerRole,
) => {
  const { data: courtesyContent } = await api_.post<CourtesyContent>(
    `/${bugoRole}/courtesy-content`,
    courtesyContentCreate,
  );
  return courtesyContent;
};

const updateCourtesyContent = async (
  courtesyContentUpdate: CourtesyContentUpdate,
  bugoRole: LowerRole,
) => {
  const { data: courtesyContent } = await api_.patch(
    `/${bugoRole}/courtesy-content/${courtesyContentUpdate._id}`,
    courtesyContentUpdate,
  );
  return courtesyContent;
};

const deleteCourtesyContent = async (courtesyContentId: string, bugoRole: LowerRole) => {
  await api_.delete(`/${bugoRole}/courtesy-content/${courtesyContentId}`);
};

export {
  createCourtesyAsset,
  createCourtesyContent,
  updateCourtesyAsset,
  deleteCourtesyAsset,
  updateCourtesyContent,
  deleteCourtesyContent,
};
