import { atom } from 'recoil';

/**
 * open, setOpen을 openId하나로 관리
 */
export const bugoNavTitleAtom = atom({
  key: 'bugoNavBarTitleAtom',
  default: '',
});

export const bugoNavRightTextAtom = atom({
  key: 'bugoNavRightTextAtom',
  default: '',
});

export const bugoNavRightPhoneNumberAtom = atom({
  key: 'bugoNavRightPhoneNumberAtom',
  default: '',
});

export const bugoNavBarUseAtom = atom({
  key: 'bugoNavBarUseAtom',
  default: true,
});

export const bugoNavBarUseNavLeftAtom = atom({
  key: 'bugoNavBarUseNavLeftAtom',
  default: true,
});

export const bugoNavBackUrlAtom = atom<string | null>({
  key: 'bugoNavBackUrlAtom',
  default: null,
});

export const sellerNavTitleAtom = atom({
  key: 'sellerNavTitleAtom',
  default: '',
});

export const sellerNavSearchBarIdAtom = atom<string>({
  key: 'sellerNavSearchBarIdAtom',
  default: '',
});

export const adminNavTitleAtom = atom({
  key: 'adminNavBarTitleAtom',
  default: '',
});

export const adminNavRightTextAtom = atom({
  key: 'adminNavRightTextAtom',
  default: '',
});

export const adminNavBarUseAtom = atom({
  key: 'adminNavBarUseAtom',
  default: true,
});

export const adminNavBarUseNavLeftAtom = atom({
  key: 'adminNavBarUseNavLeftAtom',
  default: true,
});

export const adminNavBackUrlAtom = atom<string | null>({
  key: 'adminNavBackUrlAtom',
  default: null,
});
