//* 답례글 링크 전송 페이지에서 답례글 보냈는지 보여주기 위한 녀석
import { FC } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { MemberCourtesyIFormInputs } from '@service/bugo/interfaces/memberCourtesySend.interface';
import { Bugo } from '@shared/api/bugo/bugo.interface';
import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { updateMember } from '@shared/api/member/member.controller';
import { Member } from '@shared/api/member/member.interface';
import { Button } from '@shared/components/Button';
import ResizableBox from '@shared/components/ResizableBox';
import { RoundedTag } from '@shared/components/RoundedTag';
import TelField from '@shared/components/TelField';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Control, UseFormRegister, useWatch } from 'react-hook-form';

import { CourtesyReSendModal } from './CourtesyReSendModal';

interface Props {
  member: Member<string, Bugo, CashAccount>;
  index: number;
  register: UseFormRegister<MemberCourtesyIFormInputs>;
  control: Control<MemberCourtesyIFormInputs, any>;
}
const DURATION = 0.3;
export const MemberCourtesyCard: FC<Props> = function MemberCourtesyCard({
  member,
  index,
  register,
  control,
}: Props) {
  const { bugoRole } = useAuth();
  const foldId = `memberCourtesySendCard-${member._id}`;
  const [unfold, setUnFold] = useRecoilState(simpleOpenAtomFamily(foldId));
  const onUnFoldBtnClick = () => {
    setUnFold((curr) => !curr);
  };
  const reSendOpenId = `memberCourtesySendCard-reSend-${member._id}`;
  const setReSendOpen = useSetRecoilState(simpleOpenAtomFamily(reSendOpenId));

  const phoneNumber =
    useWatch({
      control,
      name: `memberCourtesy.${index}.phoneNumber`,
      defaultValue: member.phoneNumber,
    }) ?? member.phoneNumber;

  const updateMemberBeforeCourtesySend = async () => {
    if (member.phoneNumber !== phoneNumber) {
      const memberUpdate = {
        _id: member._id,
        phoneNumber: phoneNumber,
      };

      await updateMember(memberUpdate, bugoRole());
    }
  };

  return (
    <ResizableBox
      duration={DURATION}
      className="rounded-2xl text-sm font-bold shadow-smd theme-bg-1"
    >
      <div className="flex items-center justify-between px-4 py-2">
        <div className="flex h-full flex-wrap items-center gap-1.5">
          <h4 className="leading-7">{member.relationType}</h4>
          {member.fullName && (
            <RoundedTag text={member.fullName} className="theme-bg-5"></RoundedTag>
          )}
          {member.bugo?.schedule?.courtesySend && (
            <RoundedTag text={'답례글전송완료'} className="theme-bg-8"></RoundedTag>
          )}
        </div>
        <div className="flex">
          <Button
            type="button"
            onClick={() => onUnFoldBtnClick()}
            className={`button-no-border h-full break-keep px-1 text-xs leading-5 ${
              !unfold ? 'theme-text-main' : 'theme-text-8'
            }`}
          >
            {!unfold ? '개인별전송' : '접기'}
          </Button>
        </div>
      </div>
      <div className={`grid grid-cols-2 gap-2 px-2 pb-3 ${!unfold ? 'hidden' : 'block'}`}>
        <TelField
          value={phoneNumber}
          register={register}
          name={`memberCourtesy.${index}.phoneNumber`}
          className="w-full"
        />
        <Button
          className="button-rectangle h-full text-white theme-bg-main"
          onClick={async () => {
            await updateMemberBeforeCourtesySend();
            setReSendOpen(true);
          }}
        >
          재전송
        </Button>
      </div>
      <CourtesyReSendModal
        bugoId={member.bugo?._id ?? ''}
        feventId={member.fevent}
        memberName={member.fullName}
        openId={reSendOpenId}
      />
    </ResizableBox>
  );
};
