import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import EnquiryListTable from '@service/bugo/containers/EnquiryListTable/EnquiryListTable';
import { useEnquiryListhook } from '@shared/api/enquiry/enquiry.hook';
import { EnquiryType } from '@shared/api/enquiry/enquiry.interface';
import { enquiryListStateSelector } from '@shared/api/enquiry/enquiry.selector';
import { Loading } from '@shared/components/Loading';
import Tabs from '@shared/components/Tabs';
import SearchBar from '@shared/containers/SearchBar/SearchBar';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';

import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const SEARCH_TEXT_ID = 'enquiriesPage';
const tabItems: readonly string[] = ['부고', '스토어', '셀러'];

const AdminEnquiriesPage: FC = () => {
  useAdminTitleHook('문의사항 관리');

  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));
  const [selectedTab, setSelectedTab] = useState<number>(0);

  //* enquiry list를 불러오는 admin용 hook.
  useEnquiryListhook();

  const enquiryListState = useRecoilValue(enquiryListStateSelector);

  const tabFilteredList = useMemo(() => {
    if (enquiryListState.status !== 'success' || !enquiryListState.data) return null;

    switch (selectedTab) {
      case 0:
      default:
        return enquiryListState.data.filter(
          (enquiry) => enquiry.type === EnquiryType.Bugo,
        );
      case 1:
        return enquiryListState.data.filter(
          (enquiry) => enquiry.type === EnquiryType.Store,
        );
      case 2:
        return enquiryListState.data.filter(
          (enquiry) => enquiry.type === EnquiryType.Seller,
        );
    }
  }, [enquiryListState, selectedTab]);

  return (
    <div className="space-y-4">
      <Tabs items={tabItems} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className="px-4">
        <SearchBar
          searchTextAtomId={SEARCH_TEXT_ID}
          placeholder="유저이름/문의제목으로 검색하세요"
        />
      </div>
      <div>
        {tabFilteredList ? (
          <EnquiryListTable enquiryList={tabFilteredList} searchValue={searchText} />
        ) : (
          <div className="center-box h-screen-15">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminEnquiriesPage;
