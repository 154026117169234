import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { adminCreateUser, adminUpdateUser } from '@shared/api/user/user.controller';
import { UserRole } from '@shared/types';

import {
  BugoBrandAgencyBranchAdminFormInputs,
  BugoBrandAgencyBranchAdminFormInputsEnum,
} from './AdminBugoBrandAgencyBranchAdminForm.interface';

//* bugoBrandAgencyBranchAdmin Upsert 함수
export const onAgencyBranchAdminUpsert = async (
  data: BugoBrandAgencyBranchAdminFormInputs,
  bugoBrand: BugoBrand | BugoBrandData,
  editMode: boolean,
  agencyUserId?: string,
) => {
  //수정
  if (editMode && agencyUserId) {
    const bugoBrandBranchAgencyAdminDto = {
      _id: agencyUserId,
      'info.name': data[BugoBrandAgencyBranchAdminFormInputsEnum.Name],
      'info.theme': data[BugoBrandAgencyBranchAdminFormInputsEnum.Theme],
      'bugoAgencyBranchAdminDetail.teamType':
        data[BugoBrandAgencyBranchAdminFormInputsEnum.TeamType],
      'bugoAgencyBranchAdminDetail.teamName':
        data[BugoBrandAgencyBranchAdminFormInputsEnum.TeamName],
    };
    const agencyBranchAdminUserUpdated = await adminUpdateUser(
      bugoBrandBranchAgencyAdminDto,
    );
    return agencyBranchAdminUserUpdated;
  }
  // 생성
  else {
    if (
      data[BugoBrandAgencyBranchAdminFormInputsEnum.UserName] &&
      data[BugoBrandAgencyBranchAdminFormInputsEnum.Password]
    ) {
      const bugoBrandAgencyBranchAdminCreateDto = {
        username: data[BugoBrandAgencyBranchAdminFormInputsEnum.UserName],
        password: data[BugoBrandAgencyBranchAdminFormInputsEnum.Password],
        roles: [UserRole.BugoAgencyBranchAdmin, UserRole.StoreCustomer],
        info: {
          name: data[BugoBrandAgencyBranchAdminFormInputsEnum.Name],
          gender: '',
          birth: '',
          phoneNumber: '',
          theme: data[BugoBrandAgencyBranchAdminFormInputsEnum.Theme],
        },
        bugoBrand: bugoBrand._id,
        bugoAgencyBranchAdminDetail: {
          teamType: data[BugoBrandAgencyBranchAdminFormInputsEnum.TeamType],
          teamName: data[BugoBrandAgencyBranchAdminFormInputsEnum.TeamName],
        },
      };
      //1. 관리자 user생성
      const agencyBranchAdminUserCreated = await adminCreateUser(
        bugoBrandAgencyBranchAdminCreateDto,
      );

      return agencyBranchAdminUserCreated;
    }
  }
};
