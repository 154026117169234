import { FC, useMemo, useState } from 'react';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { User } from '@shared/api/user/user.interface';
import { api_ } from '@shared/plugIn/axios';
import { useAsync } from 'react-use';

//*관리자용 부고정보 페이지 내의 BugoBrand 정보
interface Props {
  fevent: FeventOne;
}

export const BugoBrandAgencyInfo: FC<Props> = function BugoBrandAgencyInfo({
  fevent,
}: Props) {
  const [user, setUser] = useState<User | undefined>(undefined);
  const [bugoBrand, setBugoBrand] = useState<BugoBrand | undefined>(undefined);

  useAsync(async () => {
    const { data: bugoBrand } = await api_.get<BugoBrand>(
      `/admin/bugo-brand/${fevent.bugoBrand}`,
    );
    if (bugoBrand) {
      setBugoBrand(bugoBrand);
    }
    const { data: user } = await api_.get<User>(`/admin/user/${fevent.user}`);
    if (bugoBrand) {
      setUser(user);
    }
  }, [fevent]);

  const content = useMemo(() => {
    return (
      <div className="flex items-center gap-2">
        <p>{bugoBrand?.bugoAgency?.nickName}</p>
        <p>{user?.info.name}</p>
        <p>{user?.info.phoneNumber}</p>
      </div>
    );
  }, [bugoBrand?.bugoAgency?.nickName, user?.info.name, user?.info.phoneNumber]);

  return content;
};
