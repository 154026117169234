import { BugoData } from '@shared/api/bugo/bugo.interface';
import { CourtesyContent } from '@shared/api/courtesyAsset/courtesyAsset.interface';

//* CourtesyCotent를 해당 member의 정보에 맞게 string으로 변경
const getCourtesyStringFromContent = (
  courtesyContent: CourtesyContent,
  bugo: BugoData,
) => {
  const deceasedName = bugo.fevent.deceasedInfo.name;
  const memberName = bugo.member.fullName;
  const now = new Date();
  const dateString =
    now.getFullYear() + '년 ' + (now.getMonth() + 1) + '월 ' + now.getDate() + '일';

  return courtesyContent.content
    .replace('${deceasedName}', deceasedName)
    .replace('${memberName}', memberName)
    .replace('${dateString}', dateString);
};

export { getCourtesyStringFromContent };
