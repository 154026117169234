//* 부고문자페이지 조문객이 받는 페이지
import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { FeventStatus } from '@shared/api/fevent/fevent.interface';
import { ErrorFallback } from '@shared/components/ErrorFallback';
import { Loading } from '@shared/components/Loading';
import ErrorNotFound from '@shared/containers/ErrorNotFound/ErrorNotFound';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { FeventDoneAlertModal } from '../containers/Obituary/FeventDoneAlertModal';
import Obituary from '../containers/Obituary/Obituary';
import { useTitleHook } from '../hooks/useTitleHook';

const BugoObituaryPage: FC = function BugoObituaryPage() {
  const { bugoId } = useParams();
  const bugoId_ = bugoId ?? 'undefined';
  const bugoState = useRecoilValue(bugoStateSelectorFamily(bugoId_));
  useTitleHook('', '', undefined, false);

  const feventDoneModalOpenId = 'bugoObituary-endFeventAlertModal';
  const setFeventDoneOpen = useSetRecoilState(
    simpleOpenAtomFamily(feventDoneModalOpenId),
  );

  useEffect(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      if (bugoState.data.fevent.status === FeventStatus.Done) {
        setFeventDoneOpen(true);
      }
    }
  }, [bugoState.data, bugoState.status, setFeventDoneOpen]);

  const obituary = useMemo(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      if (bugoState.data.fevent?.meta?.isDeleted) {
        return <ErrorNotFound announceString="해당 부고 파기됨" />;
      } else {
        return (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Obituary bugo={bugoState.data} />
            <FeventDoneAlertModal openId={feventDoneModalOpenId} />
          </ErrorBoundary>
        );
      }
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [bugoState.data, bugoState.status]);

  return <div className="max-w-inherit select-none">{obituary}</div>;
};

export default BugoObituaryPage;
