import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useShopItemInfoListByShopHook } from '@shared/api/shopItemInfo/shopItemInfo.hook';
import { shopItemInfoListByShopStateSelectorFamily } from '@shared/api/shopItemInfo/shopItemInfo.selector';
import { Icon } from '@shared/components/icons';
import { Loading } from '@shared/components/Loading';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useParams } from 'react-router-dom';

import { AddShopItemInfoModal } from '../components/AddShopItemInfoModal';
import { ShopItemInfoCard } from '../components/ShopItemInfoCard';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

/**
 * ShopItem을 관리하기위한 메인 페이지로 ShopItemInfo들을 그리드형태로 보여줘서 해당 ShopItemInfo 클릭 시 해당
 * ShopItemInfo와 관련된 ShopItem을 생성 및 수정할 수 있는 페이지로 이동
 */

const SellerShopItemManageMainPage: FC = () => {
  const { shopId } = useParams();
  useSellerTopNavHook('도매상품관리');
  const setUploadModal = useSetRecoilState(
    simpleOpenAtomFamily('imageUploader-addShopItemCard'),
  );
  const { CirclePlusLight } = Icon();

  //! (1) 해당 shop에서 관리할 수 있는 shopItemInfo를 모두 불러옴
  useShopItemInfoListByShopHook(`${shopId}`);

  const shopItemInfoListState = useRecoilValue(
    shopItemInfoListByShopStateSelectorFamily(`${shopId}`),
  );

  const addShopItemCard = useMemo(() => {
    return (
      <button
        type="button"
        className="add-shop-item-card center-box gap-2 p-8"
        onClick={() => {
          setUploadModal(true);
        }}
      >
        <div className="flex justify-center">
          <CirclePlusLight className="inner-content wh-12 fill-gray-400" />
        </div>
        <span className="inner-content mt-2 block text-lg font-medium text-gray-900">
          새 상품 추가
        </span>
      </button>
    );
  }, [CirclePlusLight, setUploadModal]);

  const pageContentRender = useMemo(() => {
    if (shopItemInfoListState.status === 'success' && shopItemInfoListState.data) {
      return (
        <div className="mt-8">
          <h2 className="text-lg font-medium leading-6 text-gray-900">상품 메뉴 관리</h2>
          <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2 2xl:grid-cols-3">
            {shopItemInfoListState.data.map((shopItemInfo) => {
              return (
                <ShopItemInfoCard shopItemInfo={shopItemInfo} key={shopItemInfo._id} />
              );
            })}
            {addShopItemCard}
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [addShopItemCard, shopItemInfoListState.data, shopItemInfoListState.status]);

  return (
    <div className="min-h-screen-4">
      <section className="px-4 sm:px-6 lg:mx-auto lg:max-w-7xl lg:px-8">
        {pageContentRender}
        <AddShopItemInfoModal openId="imageUploader-addShopItemCard" />
      </section>
    </div>
  );
};

export default SellerShopItemManageMainPage;
