import { FC } from 'react';

import ShopItemCheckoutPage from '@service/store/page/ShopItemCheckoutPage';
import StoreItemDetailPage from '@service/store/page/StoreItemDetailPage';
import { useShopItemForStoreHook } from '@shared/api/shopItem/shopItem.hook';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const ItemIdRouter: FC = () => {
  const { storeRole } = useAuth();
  const { itemId } = useParams<{ itemId?: string }>();
  useShopItemForStoreHook(itemId ?? '', storeRole());

  return (
    <Routes>
      <Route path="" element={<StoreItemDetailPage />} />
      <Route path="checkout" element={<ShopItemCheckoutPage />} />
      <Route path="checkout/:feventId" element={<ShopItemCheckoutPage />} />
      <Route path="*" element={<Navigate to="/flower-store" />} />
    </Routes>
  );
};

export default ItemIdRouter;
