export enum EnquiryInputs {
  Name = 'name',
  Tel = 'tel',
  Title = 'title',
  Description = 'description',
  Files = 'files',
}

export interface IEnquiryInputs {
  [EnquiryInputs.Name]: string;
  [EnquiryInputs.Tel]: string;
  [EnquiryInputs.Title]: string;
  [EnquiryInputs.Description]: string;
  [EnquiryInputs.Files]: FileList;
}
