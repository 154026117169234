import { selector, selectorFamily } from 'recoil';

import { QueryDataState } from '@shared/interfaces';

import { Rebate } from '../rebate/rebate.interface';
import { ShopItemInfo } from '../shopItemInfo/shopItemInfo.interface';
import {
  sellerShopItemListByShopDataAtomFamily,
  sellerShopItemListByShopStatusAtomFamily,
  shopItemForStoreDataAtomFamily,
  shopItemForStoreStatusAtomFamily,
  shopItemListByBugoForStoreDataAtom,
  shopItemListByBugoForStoreStatusAtom,
  storeShopItemDataAtomFamily,
  storeShopItemStatusAtomFamily,
  testShopItemListForBugoDataAtom,
  testShopItemListForBugoStatusAtom,
} from './shopItem.atom';
import {
  ShopItem,
  ShopItemForStoreState,
  ShopItemListByBugoForStoreState,
} from './shopItem.interface';

export const storeShopItemStateSelectorFamily = selectorFamily<
  ShopItemForStoreState,
  string
>({
  key: 'storeShopItemStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(storeShopItemStatusAtomFamily(id)),
        data: get(storeShopItemDataAtomFamily(id)),
      };
    },
});

export const storeShopItemListByBugoStateSelector =
  selector<ShopItemListByBugoForStoreState>({
    key: 'storeShopItemListByBugoStateSelector',
    get: ({ get }) => {
      return {
        status: get(shopItemListByBugoForStoreStatusAtom),
        data: get(shopItemListByBugoForStoreDataAtom),
      };
    },
  });

export const shopItemForStoreStateSelectorFamily = selectorFamily<
  ShopItemForStoreState,
  string
>({
  key: 'shopItemForStoreStateSelectorFamily',
  get:
    (shopItemId) =>
    ({ get }) => {
      return {
        status: get(shopItemForStoreStatusAtomFamily(shopItemId)),
        data: get(shopItemForStoreDataAtomFamily(shopItemId)),
      };
    },
});

export const testShopItemListForBugoStateSelector =
  selector<ShopItemListByBugoForStoreState>({
    key: 'testShopItemListForBugoStateSelector',
    get: ({ get }) => {
      return {
        status: get(testShopItemListForBugoStatusAtom),
        data: get(testShopItemListForBugoDataAtom),
      };
    },
  });

export const sellerShopItemListBySHopStateSelectorFamily = selectorFamily<
  QueryDataState<ShopItem<ShopItemInfo, string, Rebate>[]>,
  string
>({
  key: 'sellerShopItemListBySHopStateSelectorFamily',
  get:
    (shopItemId) =>
    ({ get }) => {
      return {
        status: get(sellerShopItemListByShopStatusAtomFamily(shopItemId)),
        data: get(sellerShopItemListByShopDataAtomFamily(shopItemId)),
      };
    },
});
