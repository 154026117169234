import { FC, useCallback, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { rebateShopOrderList } from '@shared/api/shopOrder/shopOrder.controller';
import { shopOrderListForSellerByShopAccountUrl } from '@shared/api/shopOrder/shopOrder.hook';
import { ShopOrderForSellerAccount } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import ConfirmModal from '@shared/components/ConfirmModal';
import { LoadingModal } from '@shared/components/LoadingModal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { formatDate } from '@shared/utils/formatDate';
import _ from 'lodash';
import { useId } from 'react-id-generator';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

//* Admin이 해당 상조정산지급 여부를 확인 후 돈을 지급한 후 정산 버튼을 눌러 isRebated true 및 rebatedDate 를 설정하기 위함

interface Props {
  shopOrderList: ShopOrderForSellerAccount[];
}

export const RebateButton: FC<Props> = function RebateButton({ shopOrderList }: Props) {
  const [id] = useId(1, 'rebateButton');
  const openId = `sellerRebateButton-${id}-${formatDate(
    shopOrderList[0]?.paymentDetail.purchasedAt,
    {
      contains: {
        year: true,
        month: true,
      },
    },
  )}`;

  const { shopId } = useParams();
  const { sellerRole, isAdmin } = useAuth();
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      notificateSuccess(notifiacationInstance, '정산 완료');
      queryClient.invalidateQueries(
        shopOrderListForSellerByShopAccountUrl(`${shopId}`, sellerRole()),
      );
    },
    onError: () => {
      notificateError(notifiacationInstance, '관리자계정 저장 실패');
    },
  });

  const onRebateClick = useCallback(async () => {
    const shopOrderListIdList = shopOrderList.map((shopOrder) => {
      return shopOrder._id;
    });
    try {
      if (shopOrderListIdList) {
        const shopOrder = await rebateShopOrderList(shopOrderListIdList);
        console.log(shopOrder);
      }
    } catch (err: any) {
      console.error(err);
    }
  }, [shopOrderList]);

  const isRebateComplete = useMemo(() => {
    const notRebatedOrder = shopOrderList.find((order) => {
      return _.isNil(order.paymentDetail.isRebated) || !order.paymentDetail.isRebated;
    });

    if (notRebatedOrder) {
      return false;
    } else {
      return true;
    }
  }, [shopOrderList]);

  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        disabled={!isAdmin() || isRebateComplete}
        className={`shadow-sm  ${
          shopOrderList.length === 0
            ? 'cursor-default theme-text-7 theme-bg-8'
            : isRebateComplete
            ? 'cursor-default bg-green-500 theme-text-4'
            : 'theme-text-4 theme-bg-main'
        } `}
      >
        {shopOrderList.length > 0
          ? isRebateComplete
            ? '정산 완료'
            : '정산 처리'
          : '정산 없음'}
      </Button>
      <LoadingModal open={isLoading} title={'처리중'}></LoadingModal>
      <ConfirmModal
        title={'정산 처리'}
        openId={openId}
        buttonTitle={'확인'}
        onClick={() => {
          mutate(onRebateClick());
        }}
      ></ConfirmModal>
    </>
  );
};
