//* 양 사이드가 동그란 태그
import { FC } from 'react';

interface Props {
  text: string;
  className?: string;
}

export const RoundedTag: FC<Props> = function ({ text, className }: Props) {
  return (
    <div className={`${className} bugo-tag w-fit text-black`}>
      <span className="font-bold">{text}</span>
    </div>
  );
};
