//* 조문객이 보는 Router
import { FC } from 'react';

import BugoObituaryPage from '@service/bugo/page/BugoObituaryPage';
import BugoObituaryPreviewPage from '@service/bugo/page/BugoObituaryPreviewPage';
import { useBugoHook } from '@shared/api/bugo/bugo.hook';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const BugoIdRouter: FC = () => {
  const { bugoId } = useParams();
  useBugoHook(bugoId ?? 'undefined', false);
  return (
    <Routes>
      <Route path="" element={<BugoObituaryPage />} />
      <Route path="preview" element={<BugoObituaryPreviewPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default BugoIdRouter;
