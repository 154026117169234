import {
  createBugoBrand,
  updateBugoBrand,
} from '@shared/api/bugoBrand/bugoBrand.controller';
import { BugoBrandType } from '@shared/api/bugoBrand/bugoBrand.interface';
import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import { BugoBrandFormInputs, BugoBrandFormInputsEnum } from './BugoBrandForm.interface';

export const onBugoBrandUpsert = async (
  data: BugoBrandFormInputs,
  bugoBrandId?: string,
) => {
  const bugoBrandDto = {
    type: BugoBrandType.BugoAgency,
    bugoAgency: {
      corpName: data[BugoBrandFormInputsEnum.CorpName],
      nickName: data[BugoBrandFormInputsEnum.NickName],
      phoneNumber: data[BugoBrandFormInputsEnum.PhoneNumber],
      address: data[BugoBrandFormInputsEnum.Address],
      headerImage: data[BugoBrandFormInputsEnum.HeaderImage]._id,
      footerImage: data[BugoBrandFormInputsEnum.FooterImage]?._id,
      rebate: data[BugoBrandFormInputsEnum.Rebate],
      theme: data[BugoBrandFormInputsEnum.Theme],
      frontUrl: data[BugoBrandFormInputsEnum.FrontUrl],
      regAlimTalkWebDomain: data[BugoBrandFormInputsEnum.RegAlimTalkWebDomain],
      regions: data[BugoBrandFormInputsEnum.Regions],
      teamTypes: data[BugoBrandFormInputsEnum.TeamTypes],
      teamNames: data[BugoBrandFormInputsEnum.TeamNames],
      homepageUrl: data[BugoBrandFormInputsEnum.HomePageUrl],
      kakaoAppJavascriptKey: data[BugoBrandFormInputsEnum.KakaoAppJavascriptKey],
      kakaoTemplateBugoButtonId: data[BugoBrandFormInputsEnum.KakaoTemplateBugoButtonId],
      shareRebateWithWorker: data[BugoBrandFormInputsEnum.ShareRebateWithWorker],
    },
  };

  // 수정
  if (bugoBrandId) {
    const bugoBrandUpdated = await updateBugoBrand(
      { ...bugoBrandDto, _id: bugoBrandId },
      LowerRole.Admin,
    );
    return bugoBrandUpdated;
  }
  // 생성
  else {
    //1. bugoBrand 생성
    const bugoBrandCreated = await createBugoBrand(bugoBrandDto, LowerRole.Admin);

    //2. distribution network 생성
    const dto = {
      bugoBrand: bugoBrandCreated._id,
      shop: data[BugoBrandFormInputsEnum.Shop],
    };

    await api_.post('admin/distribution-network/all', dto);
    return bugoBrandCreated;
  }
};
