import { NotificationInstance } from 'antd/es/notification/interface';

import {
  notificateError,
  notificateSuccess,
} from './../plugIn/ant-notification/ant-notifiaction';
export const unsecuredCopyToClipboard = (
  text: string,
  successText: string,
  notifiacationInstance: NotificationInstance | null,
) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand('copy');
    notificateSuccess(notifiacationInstance, successText);
  } catch (err) {
    notificateError(notifiacationInstance, `오류 발생:  ${err}`);
  }
  document.body.removeChild(textArea);
};
