import { FC, useEffect, useState } from 'react';

import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

type DateObject = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

interface IProps {
  dateRange: [any, any];
  onChange: RangePickerProps['onChange'];
  size?: 'small' | 'middle' | 'large';
}

const { RangePicker } = DatePicker;

const DateFilterSearcher: FC<IProps> = ({
  dateRange,
  onChange,
  size = 'middle',
}: IProps) => {
  const [monthDate, setMonthDate] = useState<dayjs.Dayjs | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const onDateChange = (values: DateObject, formatString: [string, string]) => {
    if (onChange && (!values || !values[0] || !values[1])) {
      onChange([null, null], formatString);
      return;
    }

    if (!onChange || !values || !values[0] || !values[1]) return;

    const from = dayjs(values[0]).startOf('date');
    const to = dayjs(values[1]).endOf('date');

    onChange([from, to], formatString);
  };

  const removeRangeSearchParams = () => {
    const param = searchParams.get('range');

    if (param) {
      searchParams.delete('range');
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    if (searchParams.get('range')) {
      setMonthDate(null);
    }
  }, [searchParams]);

  return (
    <div className="flex flex-col gap-1">
      <DatePicker
        picker="month"
        placeholder="월별 선택"
        locale={locale}
        size={size}
        value={monthDate}
        monthCellRender={(date) => date.format('M월')}
        onChange={(date) => {
          setMonthDate(date);
          removeRangeSearchParams();
          if (date) {
            const startDate = date.startOf('month');
            const endDate = date.endOf('month');
            const startDateStr = startDate.format('YYYY-MM-DD');
            const endDateStr = endDate.format('YYYY-MM-DD');

            onDateChange([startDate, endDate], [startDateStr, endDateStr]);
          }
        }}
      />
      <RangePicker
        size={size}
        value={dateRange}
        onChange={(date, dateStr) => {
          removeRangeSearchParams();
          setMonthDate(null);
          onDateChange(date, dateStr);
        }}
        locale={locale}
      />
    </div>
  );
};

export default DateFilterSearcher;
