import React from 'react';

import { FallbackProps } from 'react-error-boundary';

import { Button } from './Button';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" className="rounded-md border-4 border-rose-600">
      <p className="m-2">Something went wrong:</p>
      <p className="m-1 underline decoration-red-800">{error.message}</p>
      <Button
        className="button-no-border filled-blue-800 absolute bottom-2 right-2 h-10 px-3.5 text-base"
        text="다시 시도"
        onClick={resetErrorBoundary}
      />
    </div>
  );
};

export { ErrorFallback };
