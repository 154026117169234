import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { sellerFeventAppliedearchValueAtom } from '@service/seller/containers/SellerFeventSearchTable/state/sellerFeventSearchTable.atom';
import { makeDoneCallOrder } from '@shared/api/callOrder/callOrder.controller';
import { callOrderListHookUrl } from '@shared/api/callOrder/callOrder.hook';
import { sellerCallOrderListStateSelector } from '@shared/api/callOrder/callOrder.selector';
import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Button } from '@shared/components/Button';
import { RoomInfo } from '@shared/interfaces/roomInfo.interface';
import { DeathCharacter } from '@shared/miscValues';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { formatFeventDateString } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';
import { useQueryClient } from 'react-query';

type SellerCallOrderTableData = {
  _id: string;
  id: string;
  funeralHomeInfo: string;
  roomInfo: string;
  deceasedName: string;
  address: string;
  enterance: string;
  coffinOut: string;
  fevent: Fevent<string, FuneralHomeInfo, RoomInfo>;
};

export const SellerCallOrderTable: FC = () => {
  const { sellerRole } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();
  const { data } = useRecoilValue(sellerCallOrderListStateSelector);
  const setAppliedSearchValue = useSetRecoilState(sellerFeventAppliedearchValueAtom);

  const sellerFeventTableData: SellerCallOrderTableData[] = useMemo(() => {
    if (_.isNull(data)) return [];
    return _.map(data, (item, idx) => {
      return {
        _id: item._id,
        id: `${item.bugo?._id}-${idx}`,
        funeralHomeInfo:
          item.bugo?.fevent.funeralHomeInfoEmbed?.name ??
          item.bugo?.fevent.funeralHomeInfo.name ??
          '',
        roomInfo:
          item.bugo?.fevent.roomInfoEmbed?.name ?? item.bugo?.fevent.roomInfo?.name ?? '',
        address:
          item.bugo?.fevent.funeralHomeInfoEmbed?.address ??
          item.bugo?.fevent.funeralHomeInfo.address ??
          '',
        deceasedName: item.bugo?.fevent.deceasedInfo.name ?? '',
        memberName: item.bugo?.member.fullName ?? '',
        enterance: formatFeventDateString(
          item.bugo?.fevent.deceasedInfo.enterance,
          false,
        ),
        coffinOut: formatFeventDateString(
          item.bugo?.fevent.deceasedInfo.coffinOut,
          false,
        ),
        fevent: item.bugo?.fevent,
      };
    });
  }, [data]);

  const makeCallOrderDone = async (callOrderId: string) => {
    try {
      await makeDoneCallOrder(callOrderId, sellerRole());
      queryClient.invalidateQueries(callOrderListHookUrl(sellerRole()));
      notificateSuccess(notifiacationInstance, '성공');
    } catch (err: any) {
      notificateError(notifiacationInstance, '실패');
      console.error(err);
    }
  };

  const columns: TableColumnsType<SellerCallOrderTableData> = [
    {
      title: '장례식장',
      dataIndex: 'funeralHomeInfo',
      key: 'funeralHomeInfo',
      align: 'center',
    },
    {
      title: '호실',
      dataIndex: 'roomInfo',
      key: 'roomInfo',
      align: 'center',
    },
    {
      title: '고인성함',
      dataIndex: 'deceasedName',
      key: 'deceasedName',
      align: 'center',
      render: (name: string) => {
        return (
          <p>
            {DeathCharacter} {name}님
          </p>
        );
      },
    },
    {
      title: '상주성함',
      dataIndex: 'memberName',
      key: 'memberName',
      align: 'center',
    },
    {
      title: '입실일',
      dataIndex: 'enterance',
      key: 'enterance',
      align: 'center',
    },
    {
      title: '발인일',
      dataIndex: 'coffinOut',
      key: 'coffinOut',
      align: 'center',
    },
    {
      title: '검색',
      align: 'center',
      render: (value: SellerCallOrderTableData) => {
        return (
          <Button
            className="shadow-sm"
            onClick={() => {
              setAppliedSearchValue(value.deceasedName);
            }}
          >
            찾기
          </Button>
        );
      },
    },
    {
      title: '삭제',
      align: 'center',
      render: (value: SellerCallOrderTableData) => {
        return (
          <Button
            className="shadow-sm"
            onClick={() => {
              makeCallOrderDone(value._id);
            }}
          >
            삭제
          </Button>
        );
      },
    },
  ];

  return (
    <div className="space-y-4">
      <div className="flex flex-col items-start space-y-4">
        <p>전화주문 요청 목록</p>
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([sellerFeventTableData])}
        pagination={{ position: ['bottomCenter'] }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    </div>
  );
};
