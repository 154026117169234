import { FC, useState } from 'react';

import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import ResizableBox from '@shared/components/ResizableBox';

const DURATION = 0.3;

interface IProps {
  name: string;
}

const StoreMyPageHeader: FC<IProps> = ({ name }: IProps) => {
  const { ChevronDown, ChevronUp } = Icon();
  const [fold, setFold] = useState<boolean>(true);

  const onFoldBtnClick = () => setFold((curr) => !curr);

  return (
    <ResizableBox duration={DURATION}>
      <div className="flex items-center justify-between px-4 py-3 font-medium leading-5 theme-bg-2">
        <p>
          <span className="theme-text-main">{name}</span>님 환영합니다
        </p>
        {/* TODO: Theme에 맞는 색상의 아이콘으로 대체 */}
        <Button
          onClick={onFoldBtnClick}
          className="button-no-border font-medium theme-text-8"
        >
          회원정보 {fold ? <ChevronDown /> : <ChevronUp />}
        </Button>
      </div>
      {/* TODO: 회원 정보 채워 넣어야 함 */}
      {!fold && <div className="h-[200px] theme-bg-main"></div>}
    </ResizableBox>
  );
};

export default StoreMyPageHeader;
