/* eslint-disable no-constant-condition */
//* 모바일용 부고 등록 내역 조회에서 부고 디테일을 보여주는 모달
import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useFeventHook } from '@shared/api/fevent/fevent.hook';
import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import Modal from '@shared/components/Modal';
import PaginationTable from '@shared/containers/PaginationTable/PaginationTable';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { formatDate, getAfter5days } from '@shared/utils/formatDate';
import { phoneNumberStringFormator } from '@shared/utils/phoneNumberStringFormator';

interface Props {
  openId: string;
  feventId: string;
}

const borderColorGenerator = (customTheme: UserCustomTheme) => {
  switch (customTheme) {
    case UserCustomTheme.Dark:
      return 'border-gray-500';
    case UserCustomTheme.Default:
    case UserCustomTheme.Preed:
    case UserCustomTheme.DaeMyung:
    default:
      return 'border-gray-300';
  }
};

const BugoDetailModal: FC<Props> = ({ openId, feventId }: Props) => {
  useFeventHook(feventId);
  const { data: fevent, status } = useRecoilValue(feventStateSelectorFamily(feventId));

  const customTheme = useRecoilValue(customThemeAtom);

  const privacyDeleted = useMemo(() => {
    return fevent?.meta.isDeleted ?? false;
  }, [fevent]);
  const paidShopOrderList = useMemo(() => {
    return fevent?.shopOrders ?? [];
  }, [fevent]);

  const memberSentBugo = useMemo(() => {
    return (
      fevent?.memberOrderList.filter((member) => {
        return member.bugo?.lastSentPhoneNumber;
      }) ?? []
    );
  }, [fevent]);

  const render = useMemo(() => {
    if (fevent) {
      return (
        <Modal id={openId} openId={openId} className="w-full" position="center">
          <div className="space-y-4 text-sm">
            <h4 className="text-base font-bold">부고상세정보</h4>
            {/* Contents */}
            <div className="space-y-3">
              <div className="flex">
                <span className="flex-[1] font-bold">등록지도사</span>
                <span className="flex-[3]">
                  {getAgencyWorkerTeamName(fevent.user)}&nbsp;
                  {fevent.user.info.name}
                </span>
              </div>
              <div className="flex">
                <span className="flex-[1] font-bold">등록일자</span>
                <span className="flex-[3]">
                  {formatDate(fevent.createdAt, {
                    dateSeparater: '.',
                    contains: {
                      hours: true,
                      minutes: true,
                    },
                  })}
                </span>
              </div>
              <div className="flex">
                <span className="flex-[1] font-bold">
                  {privacyDeleted ? '파기일자' : '파기예정일자'}
                </span>
                <span className="flex-[3]">
                  {privacyDeleted
                    ? formatDate(fevent.meta.deletedAt, {
                        dateSeparater: '.',
                        contains: {
                          hours: true,
                          minutes: true,
                        },
                      })
                    : formatDate(
                        fevent.meta.willBeDeletedAt ??
                          getAfter5days(fevent.deceasedInfo.coffinOut.date),
                        {
                          dateSeparater: '.',
                          contains: {
                            hours: true,
                            minutes: true,
                          },
                        },
                      )}
                </span>
              </div>
              <div className="flex">
                <span className="flex-[1] font-bold">장례식장</span>
                <span className="flex-[3]">
                  {(fevent.funeralHomeInfoEmbed ?? fevent.funeralHomeInfo)?.name}
                </span>
              </div>
              <div className="flex">
                <span className="flex-[1] pt-3 font-bold">{`부고발송(${memberSentBugo.length})`}</span>
                <div
                  className={`${
                    fevent.meta.isDeleted ? 'crossed' : ''
                  } max-h-26 flex-[3] space-y-3 overflow-y-scroll border p-3 ${borderColorGenerator(
                    customTheme,
                  )}`}
                >
                  {fevent.meta.isDeleted ? (
                    <div className="center-box">
                      <div className="max-w-max bg-white p-1 font-bold">
                        개인정보 파기됨
                      </div>
                    </div>
                  ) : (
                    fevent.memberOrderList.map((member) => (
                      <div
                        key={`member-${member._id}`}
                        className="flex space-x-4 break-keep"
                      >
                        <span>{member.fullName} 상주님</span>
                        <span>{phoneNumberStringFormator(member.phoneNumber)}</span>
                        <span>(발송 {member.bugo?.lastSentPhoneNumber ? 'O' : 'X'})</span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            {/* 화환구매상황 */}
            {paidShopOrderList.length !== 0 && (
              <div className="space-y-2">
                <h5 className="font-bold">화환구매상황</h5>
                <PaginationTable
                  columns={paidShopOrderList.map((shopOrder) => ({
                    name: shopOrder.orderDetail.shopItemEmbed.name,
                    paymentByCash:
                      shopOrder.paymentDetail.paymentByCash?.toLocaleString() + '원',
                    createdAt: formatDate(shopOrder.paymentDetail.purchasedAt, {
                      dateSeparater: '.',
                      contains: {
                        year: false,
                        hours: true,
                        minutes: true,
                      },
                    }),
                  }))}
                  keyListForOrder={['createdAt', 'name', 'paymentByCash']}
                  columnNames={{
                    createdAt: '주문시각',
                    name: '상품명',
                    paymentByCash: '결제금액',
                  }}
                />
              </div>
            )}
          </div>
        </Modal>
      );
    } else {
      return (
        <Modal id={openId} openId={openId} className="w-full" position="center">
          <div className="space-y-4 text-sm">
            <h4 className="text-base font-bold">부고상세정보</h4>
            {/* Contents */}
            <div className="space-y-3">
              <div className="flex">
                <span className="flex-[1] font-bold">등록지도사</span>
                <span className="flex-[3]"></span>
              </div>
              <div className="flex">
                <span className="flex-[1] font-bold">등록일자</span>
                <span className="flex-[3]"></span>
              </div>
              <div className="flex">
                <span className="flex-[1] font-bold">
                  {privacyDeleted ? '파기일자' : '파기예정일자'}
                </span>
                <span className="flex-[3]"></span>
              </div>
              <div className="flex">
                <span className="flex-[1] font-bold">장례식장</span>
                <span className="flex-[3]"></span>
              </div>
              <div className="flex">
                <span className="flex-[1] pt-3 font-bold">{`부고발송(${memberSentBugo.length})`}</span>
                <div
                  className={`max-h-26 flex-[3] space-y-3 overflow-y-scroll border p-3 ${borderColorGenerator(
                    customTheme,
                  )}`}
                ></div>
              </div>
            </div>
            {/* 화환구매상황 */}
            {paidShopOrderList.length !== 0 && (
              <div className="space-y-2">
                <h5 className="font-bold">화환구매상황</h5>
                <PaginationTable
                  columns={paidShopOrderList.map((shopOrder) => ({
                    name: shopOrder.orderDetail.shopItemEmbed.name,
                    paymentByCash:
                      shopOrder.paymentDetail.paymentByCash?.toLocaleString() + '원',
                    createdAt: formatDate(shopOrder.paymentDetail.purchasedAt, {
                      dateSeparater: '.',
                      contains: {
                        year: false,
                        hours: true,
                        minutes: true,
                      },
                    }),
                  }))}
                  keyListForOrder={['createdAt', 'name', 'paymentByCash']}
                  columnNames={{
                    createdAt: '주문시각',
                    name: '상품명',
                    paymentByCash: '결제금액',
                  }}
                />
              </div>
            )}
          </div>
        </Modal>
      );
    }
  }, [
    customTheme,
    fevent,
    memberSentBugo.length,
    openId,
    paidShopOrderList,
    privacyDeleted,
  ]);

  return render;
};

export default BugoDetailModal;
