import { ButtonHTMLAttributes, FC } from 'react';

import { useNavigate } from 'react-router-dom';

export interface ConfirmButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  disabled: boolean;
  to?: string;
}

const SubmitConfirmButton: FC<ConfirmButtonProps> = function Button({
  text,
  disabled,
  to,
  children,
  className = '',
  onClick,
  ...props
}: ConfirmButtonProps) {
  const navigate = useNavigate();
  const buttonColorStyle = (disabled: boolean) => {
    if (disabled) return 'bg-gray-300 border-gray-300';
    return 'bg-gray-800 border-gray-800';
  };

  return (
    <div className="absolute bottom-10 w-full max-w-inherit pl-6 pr-6">
      <Button
        className={`h-13 w-full rounded-none border-solid text-14 text-white ${buttonColorStyle(
          disabled,
        )} ${className}`}
        disabled={disabled}
        onClick={to ? () => navigate(to) : onClick}
        {...props}
      >
        {text ?? children}
      </Button>
    </div>
  );
};

export { SubmitConfirmButton };
