//* 휴대번호 있고 없고 나타내는 Tag
import { FC } from 'react';

import { Icon } from '@shared/components/icons';
interface Props {
  phoneNumber: string;
}

export const PhoneTag: FC<Props> = function PhoneTag({ phoneNumber }: Props) {
  const { Phone } = Icon();
  if (phoneNumber && phoneNumber !== '010') {
    return <Phone className="wh-5 theme-fill-main" />;
  } else {
    return null;
  }
};
