import { MemberIFormInput } from '@service/bugo/interfaces/memberAddEdit.interface';
import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { api_ } from '@shared/plugIn/axios';
import { Gender, LowerRole } from '@shared/types';
import { getReverseType } from '@shared/utils/memberUtils';

import { Bugo } from '../bugo/bugo.interface';
import {
  createCashAccount,
  updateCashAccount,
} from '../cashAccount/cashAccount.controller';
import { checkSameCashAccountInputAndCashAccountOld } from '../cashAccount/cashAccount.util';
import { Member, MemberCreate, MemberUpdate } from './member.interface';
import { checkSameMemberInputAndMemberOld } from './member.utils';

const createMember = async (memberCreate: MemberCreate, bugoRole: LowerRole) => {
  const { data: member } = await api_.post<Member>(`/${bugoRole}/member`, memberCreate);
  return member;
};

const updateMember = async (memberUpdate: MemberUpdate, bugoRole: LowerRole) => {
  const { data: member } = await api_.patch<Member>(
    `/${bugoRole}/member/${memberUpdate._id}`,
    memberUpdate,
  );
  return member;
};

//* onSubmit 시 member fields를 바탕으로 member를 생성, update하여 해당 memberOrderList를 리턴해줌
const getMemberOrderListByFormInputs = async (
  memberIFormInputs: MemberIFormInput[],
  memberOrderListOld: Member<string, Bugo, CashAccount>[],
  feventId: string,
  deceasedSex: Gender,
  bugoRole: LowerRole,
) => {
  const memberOrderList = await Promise.all(
    memberIFormInputs.map(async (memberIFormInput) => {
      //* 멤버가 존재한다면 수정
      if (memberIFormInput._id) {
        const memberOld = memberOrderListOld.find((item) => {
          return item._id === memberIFormInput._id;
        });
        const cashAccountOld = memberOld?.cashAccount;

        // * cashAccount는 id가 있건 없건 isVerified 상황에서는
        // * 무조건 값이 바뀐 경우에는 새로 생성을 하는게 맞음
        // * 그래야 후에 같은 id를 쓸 수 있음
        // * 다만 계좌 선택 시에 인증된 계좌만 가져오게 하면 됨

        let cashAccountId: string | undefined | null = memberIFormInput.cashAccountId;
        //* 계좌 업데이트 여부 확인
        const cashAccountByInput = {
          name: memberIFormInput.accountHolder,
          account: memberIFormInput.cashAccount,
          bank: memberIFormInput.bank,
        };
        // * cashAccountOld가 있으면서 기존 값과 다른경우, 혹은 cashAccountOld 자체가 없는 경우
        if (
          (cashAccountOld &&
            !checkSameCashAccountInputAndCashAccountOld(
              cashAccountByInput,
              cashAccountOld,
            )) ||
          !cashAccountOld
        ) {
          // * memberIFormInput.isVerified인 경우는 계좌선택, 계좌 확인을 통해 cashAccount가 인증된 것이므로 아무것도 안해도됨
          if (!memberIFormInput.isVerified) {
            //* id 있을 경우 update
            if (memberIFormInput.cashAccountId) {
              const cashAccountUpdate = {
                _id: memberIFormInput.cashAccountId,
                name: memberIFormInput.accountHolder,
                account: memberIFormInput.cashAccount,
                bank: memberIFormInput.bank,
              };
              await updateCashAccount(cashAccountUpdate, bugoRole);
            }
            //* id 없을 경우 생성
            else {
              if (memberIFormInput.bank && memberIFormInput.cashAccount) {
                const cashAccountCreate = {
                  name: memberIFormInput.accountHolder,
                  account: memberIFormInput.cashAccount,
                  bank: memberIFormInput.bank,
                  fevent: feventId,
                };
                const cashAccount = await createCashAccount(cashAccountCreate, bugoRole);
                cashAccountId = cashAccount._id;
              } else {
                cashAccountId = null;
              }
            }
          }
        }
        if (
          (memberOld && !checkSameMemberInputAndMemberOld(memberIFormInput, memberOld)) ||
          !memberOld ||
          cashAccountId !== cashAccountOld?._id
        ) {
          const memberUpdate = {
            _id: memberIFormInput._id,
            fevent: feventId,
            fullName: memberIFormInput.name,
            shortName: memberIFormInput.name,
            relationType: memberIFormInput.relationType,
            reverseType: getReverseType(memberIFormInput.relationType, deceasedSex),
            phoneNumber: memberIFormInput.phoneNumber,
            cashAccount: cashAccountId,
            cashAccountModalConfirmed: memberIFormInput.cashAccountModalConfirmed,
          };
          await updateMember(memberUpdate, bugoRole);
        }
        return memberIFormInput._id;
      }
      //* 멤버가 없다면 생성
      else {
        //* 계좌 확인, 인증을 통해 cashAccount를 생성하고 해당 id를 memberIFormInput에 박아 넣을 것이기 때문에
        //* member 생성 전에 cashAccountId 존재 가능,
        //* cashAccountId 존재시
        let cashAccountId = memberIFormInput.cashAccountId;

        if (!memberIFormInput.isVerified) {
          if (memberIFormInput.cashAccountId) {
            const cashAccountUpdate = {
              _id: memberIFormInput.cashAccountId,
              name: memberIFormInput.accountHolder,
              accont: memberIFormInput.cashAccount,
              bank: memberIFormInput.bank,
            };
            await updateCashAccount(cashAccountUpdate, bugoRole);
          } else {
            if (memberIFormInput.bank || memberIFormInput.cashAccount) {
              const cashAccountCreate = {
                name: memberIFormInput.accountHolder,
                account: memberIFormInput.cashAccount,
                bank: memberIFormInput.bank,
                fevent: feventId,
              };
              const cashAccount = await createCashAccount(cashAccountCreate, bugoRole);
              cashAccountId = cashAccount._id;
            }
          }
        }
        const memberCreate = {
          fevent: feventId,
          fullName: memberIFormInput.name,
          shortName: memberIFormInput.name,
          relationType: memberIFormInput.relationType,
          reverseType: getReverseType(memberIFormInput.relationType, deceasedSex),
          phoneNumber: memberIFormInput.phoneNumber,
          cashAccount: cashAccountId,
          cashAccountModalConfirmed: memberIFormInput.cashAccountModalConfirmed,
          privacyAgreement: false,
        };

        const memberCreated = await createMember(memberCreate, bugoRole);
        return memberCreated._id;
      }
    }),
  );
  return memberOrderList;
};

export { createMember, updateMember, getMemberOrderListByFormInputs };
