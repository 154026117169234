import { ComponentType, FC, SVGProps } from 'react';

import { ReactComponent as Back } from 'src/assets/icon/icon-back.svg';
import { ReactComponent as BigCheckBlue } from 'src/assets/icon/icon-big-check-blue.svg';
import { ReactComponent as BigCheck } from 'src/assets/icon/icon-big-check.svg';
import { ReactComponent as BugoMessageBlue } from 'src/assets/icon/icon-bugo-message-blue.svg';
import { ReactComponent as BugoMessage } from 'src/assets/icon/icon-bugo-message.svg';
import { ReactComponent as CalendarPlaceHolder } from 'src/assets/icon/icon-calendar-placeholder.svg';
import { ReactComponent as Calendar } from 'src/assets/icon/icon-calendar.svg';
import { ReactComponent as CaretDown } from 'src/assets/icon/icon-caret-down-solid.svg';
import { ReactComponent as CheckBox } from 'src/assets/icon/icon-check-box.svg';
import { ReactComponent as Check } from 'src/assets/icon/icon-check.svg';
import { ReactComponent as ChevronDown } from 'src/assets/icon/icon-chevron-down.svg';
import { ReactComponent as ChevronRight } from 'src/assets/icon/icon-chevron-right.svg';
import { ReactComponent as ChevronUp } from 'src/assets/icon/icon-chevron-up.svg';
import { ReactComponent as CircleMinusLight } from 'src/assets/icon/icon-circle-minus-light.svg';
import { ReactComponent as CirclePlusLight } from 'src/assets/icon/icon-circle-plus-light.svg';
import { ReactComponent as X } from 'src/assets/icon/icon-close.svg';
import { ReactComponent as CoffinCrossSolid } from 'src/assets/icon/icon-coffin-cross-solid.svg';
import { ReactComponent as Copy } from 'src/assets/icon/icon-copy.svg';
import { ReactComponent as Courtesy } from 'src/assets/icon/icon-courtesy.svg';
import { ReactComponent as CSBlue } from 'src/assets/icon/icon-cs-blue.svg';
import { ReactComponent as CS } from 'src/assets/icon/icon-cs.svg';
import { ReactComponent as CustomerCenter } from 'src/assets/icon/icon-customer-center.svg';
import { ReactComponent as DeliveryBlue } from 'src/assets/icon/icon-delivery-blue.svg';
import { ReactComponent as Delivery } from 'src/assets/icon/icon-delivery.svg';
import { ReactComponent as DPBlue } from 'src/assets/icon/icon-dp-blue.svg';
import { ReactComponent as DP } from 'src/assets/icon/icon-dp.svg';
import { ReactComponent as Ellipsis } from 'src/assets/icon/icon-ellipsis.svg';
import { ReactComponent as EmptyUser } from 'src/assets/icon/icon-empty-user.svg';
import { ReactComponent as ErrorBlue } from 'src/assets/icon/icon-error-blue.svg';
import { ReactComponent as Error } from 'src/assets/icon/icon-error.svg';
import { ReactComponent as Home } from 'src/assets/icon/icon-home.svg';
import { ReactComponent as LinkBlue } from 'src/assets/icon/icon-link-blue.svg';
import { ReactComponent as Link } from 'src/assets/icon/icon-link.svg';
import { ReactComponent as Loading } from 'src/assets/icon/icon-loading.svg';
import { ReactComponent as LocationCheck } from 'src/assets/icon/icon-location-check.svg';
import { ReactComponent as LocationDotSolid } from 'src/assets/icon/icon-location-dot-solid.svg';
import { ReactComponent as MagnifyingGlassRegular } from 'src/assets/icon/icon-magnifying-glass-regular.svg';
import { ReactComponent as Menu } from 'src/assets/icon/icon-menu.svg';
import { ReactComponent as ModifyBlue } from 'src/assets/icon/icon-modify-blue.svg';
import { ReactComponent as Modify } from 'src/assets/icon/icon-modify.svg';
import { ReactComponent as MoneyBagBlue } from 'src/assets/icon/icon-money-bag-blue.svg';
import { ReactComponent as MoneyBag } from 'src/assets/icon/icon-money-bag.svg';
import { ReactComponent as OtherApps } from 'src/assets/icon/icon-other-apps.svg';
import { ReactComponent as PencilBlue } from 'src/assets/icon/icon-pencil-blue.svg';
import { ReactComponent as Pencil } from 'src/assets/icon/icon-pencil.svg';
import { ReactComponent as Phone } from 'src/assets/icon/icon-phone.svg';
import { ReactComponent as PlusSolid } from 'src/assets/icon/icon-plus-solid.svg';
import { ReactComponent as Question } from 'src/assets/icon/icon-question.svg';
import { ReactComponent as Receipt } from 'src/assets/icon/icon-receipt.svg';
import { ReactComponent as RegisterBlue } from 'src/assets/icon/icon-register-blue.svg';
import { ReactComponent as Register } from 'src/assets/icon/icon-register.svg';
import { ReactComponent as SendBlue } from 'src/assets/icon/icon-send-blue.svg';
import { ReactComponent as Send } from 'src/assets/icon/icon-send.svg';
import { ReactComponent as Setting } from 'src/assets/icon/icon-settings.svg';
import { ReactComponent as ShareKakao } from 'src/assets/icon/icon-share-kakao.svg';
import { ReactComponent as ShareMessage } from 'src/assets/icon/icon-share-message.svg';
import { ReactComponent as TimePlaceholder } from 'src/assets/icon/icon-time-placeholder.svg';
import { ReactComponent as Time } from 'src/assets/icon/icon-time.svg';
import { ReactComponent as UpFromBracket } from 'src/assets/icon/icon-up-from-bracket.svg';
import { ReactComponent as UploadImage } from 'src/assets/icon/icon-upload-image.svg';
import { ReactComponent as UserBlue } from 'src/assets/icon/icon-user-blue.svg';
import { ReactComponent as User } from 'src/assets/icon/icon-user.svg';
import { ReactComponent as View } from 'src/assets/icon/icon-view.svg';
import { ReactComponent as WreathBig } from 'src/assets/icon/icon-wreath-big.svg';
import { ReactComponent as WreathBlue } from 'src/assets/icon/icon-wreath-blue.svg';
import { ReactComponent as FlowerBlue } from 'src/assets/icon/icon-wreath-flower-blue.svg';
import { ReactComponent as Flower } from 'src/assets/icon/icon-wreath-flower.svg';
import { ReactComponent as Wreath } from 'src/assets/icon/icon-wreath.svg';

function withStroke<T extends SVGProps<SVGSVGElement>>(Icon: ComponentType<T>): FC<T> {
  return ({ className = '', ...props }) => (
    <Icon className={`stroke-current ${className}`} {...(props as T)} />
  );
}

export const IconFormer = {
  Back: withStroke(Back),
  BigCheckBlue: withStroke(BigCheckBlue),
  BigCheck: withStroke(BigCheck),
  BugoMessageBlue: withStroke(BugoMessageBlue),
  BugoMessage: withStroke(BugoMessage),
  CalendarPlaceHolder: withStroke(CalendarPlaceHolder),
  Calendar: withStroke(Calendar),
  ChevronDown: withStroke(ChevronDown),
  CheckBox: withStroke(CheckBox),
  ChevronRight: withStroke(ChevronRight),
  ChevronUp: withStroke(ChevronUp),
  CircleMinusLight: withStroke(CircleMinusLight),
  Check: withStroke(Check),
  CirclePlusLight: withStroke(CirclePlusLight),
  CoffinCrossSolid: withStroke(CoffinCrossSolid),
  Courtesy: withStroke(Courtesy),
  CSBlue: withStroke(CSBlue),
  CS: withStroke(CS),
  DeliveryBlue: withStroke(DeliveryBlue),
  DPBlue: withStroke(DPBlue),
  DP: withStroke(DP),
  Home: withStroke(Home),
  EmptyUser: withStroke(EmptyUser),
  Link: withStroke(Link),
  ErrorBlue: withStroke(ErrorBlue),
  Error: withStroke(Error),
  LocationDotSolid: withStroke(LocationDotSolid),
  Menu: withStroke(Menu),
  LinkBlue: withStroke(LinkBlue),
  MagnifyingGlassRegular: withStroke(MagnifyingGlassRegular),
  ModifyBlue: withStroke(ModifyBlue),
  Phone: withStroke(Phone),
  Modify: withStroke(Modify),
  MoneyBagBlue: withStroke(MoneyBagBlue),
  Send: withStroke(Send),
  MoneyBag: withStroke(MoneyBag),
  OtherApps: withStroke(OtherApps),
  PencilBlue: withStroke(PencilBlue),
  Pencil: withStroke(Pencil),
  PlusSolid: withStroke(PlusSolid),
  RegisterBlue: withStroke(RegisterBlue),
  User: withStroke(User),
  X: withStroke(X),
  Register: withStroke(Register),
  SendBlue: withStroke(SendBlue),
  Delivery: withStroke(Delivery),
  Question: withStroke(Question),
  Setting: withStroke(Setting),
  ShareKakao: withStroke(ShareKakao),
  ShareMessage: withStroke(ShareMessage),
  Flower: withStroke(Flower),
  TimePlaceholder: withStroke(TimePlaceholder),
  Time: withStroke(Time),
  UserBlue: withStroke(UserBlue),
  View: withStroke(View),
  WreathBig: withStroke(WreathBig),
  WreathBlue: withStroke(WreathBlue),
  CaretDown: withStroke(CaretDown),
  FlowerBlue: withStroke(FlowerBlue),
  Wreath: withStroke(Wreath),
  Receipt: withStroke(Receipt),
  UpFromBracket: withStroke(UpFromBracket),
  UploadImage: withStroke(UploadImage),
  Copy: withStroke(Copy),
  Ellipsis: withStroke(Ellipsis),
  LocationCheck: withStroke(LocationCheck),
  Loading: withStroke(Loading),
  CustomerCenter: withStroke(CustomerCenter),
};
