import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import AnnouncementCard from '@service/bugo/components/AnnouncementCard';
import { useBugoBrandAnnouncementListForWorkerhook } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.hook';
import { BugoBrandAnnouncementStatus } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { bugoBrandAnnouncementListForWorkerStateSelector } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.selector';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { Loading } from '@shared/components/Loading';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';
import { Link } from 'react-router-dom';

const BugoMainAnouncementList: FC = () => {
  const { bugoRole } = useAuth();

  useBugoBrandAnnouncementListForWorkerhook();

  const announcementListState = useRecoilValue(
    bugoBrandAnnouncementListForWorkerStateSelector,
  );

  const [viewAllList, setViewAllList] = useState<boolean>(false);
  const [listLen, setListLen] = useState<number>(0);
  const { PlusSolid } = Icon();

  const announcementList = useMemo(() => {
    if (announcementListState.status === 'success' && announcementListState.data) {
      const visibleList = announcementListState.data.filter(
        (announcement) => announcement.status === BugoBrandAnnouncementStatus.Visible,
      );

      const announcementList = viewAllList ? visibleList : visibleList.slice(0, 2);
      setListLen(visibleList.length);

      return announcementList.map((announcement) => (
        <AnnouncementCard
          key={'announcement-card-' + announcement._id}
          announcement={announcement}
        />
      ));
    } else {
      <div className="center-box min-h-screen-15">
        <Loading />
      </div>;
    }
  }, [announcementListState.data, announcementListState.status, viewAllList]);

  return (
    <>
      {announcementListState.status === 'success' && announcementListState.data && (
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <p className="text-14 font-bold">공지사항</p>
            {/* TODO: bugoBrandId 연결 */}
            {[LowerRole.Admin, LowerRole.BugoAgencyAdmin].includes(bugoRole()) && (
              <Link to="bugo-brand/announcement/add">
                <div className="cursor-pointer px-2 py-1">
                  <PlusSolid className="aspect-square w-5" />
                </div>
              </Link>
            )}
          </div>
          {announcementList}
          <div className="flex justify-center">
            {listLen > 2 && (
              <Button
                onClick={() => setViewAllList((curr) => !curr)}
                className="button-fold"
              >
                <p>{viewAllList ? '접기' : '전체보기'}</p>
              </Button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BugoMainAnouncementList;
