import { FC, useState } from 'react';

import { Icon } from './icons';
import ResizableBox from './ResizableBox';

const DURATION = 0.3;

interface IProps {
  title: string;
  initialFolded?: boolean;
  children?: React.ReactNode;
}

const Collapse: FC<IProps> = ({ title, initialFolded = true, children }: IProps) => {
  const [isFolded, setIsFolded] = useState<boolean>(initialFolded);
  const { ChevronDown } = Icon();

  const onClick = () => setIsFolded((prev) => !prev);

  return (
    <ResizableBox
      duration={DURATION}
      className={`theme-boder-1 whitespace-pre-line break-keep border-b text-sm font-bold ${
        isFolded ? 'theme-text-8' : 'theme-text-1'
      }`}
    >
      <button
        onClick={onClick}
        className="flex w-full items-center justify-between px-4 pb-3 pt-[11px]"
      >
        <h5 className={`pr-[17.33px] text-start`}>{title}</h5>
        <ChevronDown
          // onClick={onClick}
          className={`items-center stroke-none ${isFolded ? '' : 'rotate-180'}`}
        />
      </button>
      {!isFolded && (
        <p className="whitespace-pre-line break-keep px-4 py-3 text-[12px] font-normal theme-text-7 theme-bg-1">
          {children}
        </p>
      )}
    </ResizableBox>
  );
};

export default Collapse;
