import { FC } from 'react';

import { CustomCallback } from '@shared/types';

import { Button } from './Button';

interface IProps {
  items: readonly string[];
  selectedTab: number;
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
  className?: string;
  onTabClick?: CustomCallback<number, void>;
}

const Tabs: FC<IProps> = ({
  items,
  selectedTab,
  setSelectedTab,
  className = '',
  onTabClick,
}: IProps) => {
  const onClick = (tab: number) => {
    if (onTabClick) {
      onTabClick(tab);
    }
    setSelectedTab(tab);
  };

  return (
    <div>
      <div
        style={{
          gridTemplateColumns: `repeat(${items.length}, minmax(0, 1fr))`,
        }}
        className={`${
          className ? className : 'theme-bg-2'
        } grid grid-cols-4 text-sm theme-text-8 `}
      >
        {items.map((item, index) => (
          <Button
            key={index}
            onClick={() => onClick(index)}
            className={`button-rectangle h-full py-3 text-center ${
              index === selectedTab ? 'font-bold theme-text-1' : ''
            }`}
          >
            {item}
          </Button>
        ))}
      </div>
      {/* Underline of selected tab */}
      <div
        style={{
          width: `calc(100%/${items.length})`,
          transform: `translateX(calc(100%*${selectedTab}))`,
        }}
        className={`h-0.5 -translate-y-0.5 transition-transform theme-bg-main`}
      ></div>
    </div>
  );
};

export default Tabs;
