//* Modal: 가운데 중심에 특정 팝업(모달) 띄우기용으로 사용 모바일에서는 아래쪽이 default로 뜸

import { Fragment, HTMLAttributes } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { Dialog, Transition } from '@headlessui/react';
import { useModalHook } from '@shared/hooks/useModalHook';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { CustomCallback } from '@shared/types';

import { IconFormer } from './IconsFormer';

interface Props extends HTMLAttributes<HTMLDivElement> {
  openId: string;
  onClose?: CustomCallback<any, void>;
  className?: string;
  frameClassName?: string;
  position?: 'start' | 'center' | 'end';
}
/**
 * modal
 * @param openId
 * @param onClose: (option) 종료시 callback 함수
 * @param className: (option) 해당 모달의 className
 * @param frameClassName: (option) 해당 모달의 위치 조정 가능
 * @param position: (option) start 위 center 중간 end 아래
 * @returns
 */
export default function Modal({
  openId,
  children,
  onClose,
  className,
  frameClassName,
  position = 'end',
}: Props) {
  const [open, setOpen] = useRecoilState(simpleOpenAtomFamily(openId));
  const customTheme = useRecoilValue(customThemeAtom);
  useModalHook(openId);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`${customTheme} fixed inset-0 z-30 overflow-y-auto`}
        onClose={(e) => {
          if (onClose) {
            onClose(e);
          } else {
            setOpen(false);
          }
          history.back();
        }}
      >
        <div
          style={{
            alignItems: position,
          }}
          className={`${frameClassName} flex min-h-screen justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className={`hidden sm:inline-block sm:h-screen ${
              position === 'start' ? 'sm:align-top' : `sm:align-middle`
            }`}
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${className} mb-8 inline-block transform rounded-lg px-5 pt-7 pb-4 text-left align-bottom shadow-xl transition-all  theme-text-1 theme-bg-2 sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle`}
            >
              <div className="absolute top-0 right-0">
                <IconFormer.X
                  className="cursor-pointer"
                  onClick={(e) => {
                    if (onClose) {
                      onClose(e);
                    } else {
                      setOpen(false);
                    }
                    history.back();
                  }}
                ></IconFormer.X>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
        <button className="sr-only"></button>
      </Dialog>
    </Transition.Root>
  );
}
