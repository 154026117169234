import { DeathCharacter } from '@shared/miscValues';
import { Gender } from '@shared/types';

export const deceasedNameString = (
  name: string,
  sex?: Gender,
  age?: number,
  nameDetail?: string,
) => {
  const stringElement = [
    DeathCharacter,
    name,
    nameDetail && '(' + nameDetail + ')',
    (sex || age) &&
      '(' + (sex ? sex : '') + (sex && age ? '/' : '') + (age ? age + '세' : '') + ')',
  ];

  return stringElement.join(' ');
};
