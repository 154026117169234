import { forwardRef, InputHTMLAttributes } from 'react';

import { useRecoilValue } from 'recoil';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { CustomCallback, UserCustomTheme } from '@shared/types';
import { mainColorGenerator } from '@shared/utils/mainColorGenerator';
import { useId } from 'react-id-generator';

import { Icon } from './icons';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  //! onCheckedChange에 checked state를 변경하는 코드가 필수로 있어야함!!
  onCheckedChange: CustomCallback<boolean, any>;
}

const bg18ColorGenerator = (customTheme: UserCustomTheme) => {
  switch (customTheme) {
    case UserCustomTheme.Dark:
      return '#f3f4f6';
    case UserCustomTheme.Default:
    case UserCustomTheme.Preed:
    case UserCustomTheme.DaeMyung:
    default:
      return '#fff';
  }
};

export const CheckBox = forwardRef<HTMLInputElement, IProps>(
  ({ checked, onCheckedChange, className = '', ...props }, ref) => {
    const { CheckBox } = Icon();
    const [id] = useId(1, 'checkbox');
    const customTheme = useRecoilValue(customThemeAtom);

    return (
      <label
        style={{
          backgroundColor: checked
            ? mainColorGenerator(customTheme)
            : bg18ColorGenerator(customTheme),
          borderColor: checked ? mainColorGenerator(customTheme) : '#d1d5db',
        }}
        className={`center-box relative h-5 w-5 cursor-pointer rounded-none border-2 transition-colors ${className}`}
      >
        <input
          ref={ref}
          id={id}
          className="absolute top-0 left-0 h-full w-full cursor-pointer opacity-0"
          type="checkbox"
          {...props}
          onChange={(event) => onCheckedChange(event.currentTarget.checked)}
          checked={checked}
        />
        {checked && <CheckBox />}
      </label>
    );
  },
);
