//* 부고 전송 페이지 Fevent 단위

import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { Loading } from '@shared/components/Loading';
import { useParams } from 'react-router-dom';

import FeventSendBugoForm from '../containers/FeventBugoSendForm/FeventSendBugoForm';
import { useTitleHook } from '../hooks/useTitleHook';

const FeventBugoSendPage: FC = function FeventBugoSendPage() {
  useTitleHook('부고전송');

  const { feventId } = useParams();

  const feventState = useRecoilValue(feventStateSelectorFamily(feventId ?? 'undefined'));
  const feventBugSendForm = useMemo(() => {
    if (feventState.status === 'success' && feventState.data) {
      return <FeventSendBugoForm fevent={feventState.data} />;
    } else
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
  }, [feventState.data, feventState.status]);
  return <div className="space-y-6 p-6">{feventBugSendForm}</div>;
};

export default FeventBugoSendPage;
