import { selector } from 'recoil';

import {
  funeralHomeInfoListDataAtom,
  funeralHomeInfoListStatusAtom,
} from './funeralHomeInfo.atom';
import { FuneralHomeInfoListState } from './funeralHomeInfo.interface';
export const FuneralHomeInfoListStateSelector = selector<FuneralHomeInfoListState>({
  key: 'funeralHomeInfoListStateSelector',
  get: ({ get }) => {
    return {
      status: get(funeralHomeInfoListStatusAtom),
      data: get(funeralHomeInfoListDataAtom),
    };
  },
});
