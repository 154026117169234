import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoBrandListHookUrl } from '@shared/api/bugoBrand/bugoBrand.hook';
import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import { Label } from '@shared/components/Label';
import { LoadingModal } from '@shared/components/LoadingModal';
import { TextField } from '@shared/components/TextField';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import {
  BugoBrandAgencyBranchAdminFormInputs,
  BugoBrandAgencyBranchAdminFormInputsEnum,
} from './AdminBugoBrandAgencyBranchAdminForm.interface';
import { onAgencyBranchAdminUpsert } from './AdminBugoBrandAgencyBranchAdminForm.util';

//* 상조 관리자 계정 생성 및 수정을 위한 Form
interface Props {
  bugoBrand: BugoBrand | BugoBrandData;
  agencyBranchAdminUser?: User;
  editMode?: boolean;
}

export const AdminBugoBrandAgencyBranchAdminForm: FC<Props> =
  function AdminBugoBrandAgencyBranchAdminForm({
    bugoBrand,
    agencyBranchAdminUser,
    editMode,
  }: Props) {
    const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
    const queryClient = useQueryClient();
    const { isLoading, mutate } = useMutation(defaultApiMutationOptions, {
      onSuccess: () => {
        notificateSuccess(notifiacationInstance, '저장 완료');
        queryClient.invalidateQueries(bugoBrandListHookUrl);
      },
      onError: () => {
        notificateError(notifiacationInstance, '관리자계정 저장 실패');
      },
    });

    const { register, handleSubmit, control } =
      useForm<BugoBrandAgencyBranchAdminFormInputs>({
        defaultValues: {
          name: agencyBranchAdminUser?.info.name,
          theme: agencyBranchAdminUser?.info.theme,
          teamType: agencyBranchAdminUser?.bugoAgencyBranchAdminDetail?.teamType,
          teamName: agencyBranchAdminUser?.bugoAgencyBranchAdminDetail?.teamName,
        },
      });

    const onSubmit: SubmitHandler<BugoBrandAgencyBranchAdminFormInputs> = async (
      data,
    ) => {
      try {
        const ret = await onAgencyBranchAdminUpsert(
          data,
          bugoBrand,
          editMode ?? false,
          agencyBranchAdminUser?._id,
        );
        console.log(ret);
      } catch (err: any) {
        console.error(err);
      }
    };

    const teamTypeOptionList = useMemo(() => {
      if (bugoBrand) {
        const teamTypeList = bugoBrand.bugoAgency?.teamTypes as string[];
        return teamTypeList;
      } else return [];
    }, [bugoBrand]);

    const teamNameOptionList = useMemo(() => {
      if (bugoBrand) {
        const teamNameList = bugoBrand.bugoAgency?.teamNames;
        return teamNameList as string[];
      } else return [];
    }, [bugoBrand]);

    return (
      <>
        <form
          className="space-y-4"
          onSubmit={(e) => {
            mutate(handleSubmit(onSubmit)(e));
          }}
        >
          <div className="grid grid-cols-2 gap-4">
            {editMode ? (
              <div className="flex h-full gap-4 font-bold">
                <Label>계정</Label>
                <p>{agencyBranchAdminUser?.username}</p>
              </div>
            ) : (
              <TextField
                {...register(BugoBrandAgencyBranchAdminFormInputsEnum.UserName)}
                label="아이디"
                placeholder="아이디"
                className="w-full"
              />
            )}
            {editMode ? (
              <div></div>
            ) : (
              <TextField
                className="w-full"
                {...register(BugoBrandAgencyBranchAdminFormInputsEnum.Password)}
                label="비밀번호"
                placeholder="비밀번호"
              />
            )}
            <TextField
              {...register(BugoBrandAgencyBranchAdminFormInputsEnum.Name)}
              label="이름"
              placeholder="이름"
              className="w-full"
            />
            <div className="label-col">
              <Label>테마</Label>
              <Select
                placeholder="테마"
                optionList={[
                  { value: 'Default', label: '기본' },
                  { value: 'Preed', label: '프리드' },
                  { value: 'Dark', label: '다크' },
                  { value: 'Daemyung', label: '대명' },
                ]}
                control={control}
                name={BugoBrandAgencyBranchAdminFormInputsEnum.Theme}
              ></Select>
            </div>
            <div className="label-col">
              <Label>팀타입</Label>
              <Select
                placeholder="팀타입"
                optionList={teamTypeOptionList}
                control={control}
                name={BugoBrandAgencyBranchAdminFormInputsEnum.TeamType}
              ></Select>
            </div>
            <div className="label-col">
              <Label>지사이름</Label>
              <Select
                placeholder="지사이름"
                optionList={teamNameOptionList}
                control={control}
                name={BugoBrandAgencyBranchAdminFormInputsEnum.TeamName}
              ></Select>
            </div>
          </div>
          <div className="pt-4">
            <Button
              disabled={isLoading}
              className="button-rectangle h-10 rounded-md theme-text-3 theme-bg-main"
              type="submit"
            >
              저장
            </Button>
          </div>
        </form>
        <LoadingModal open={isLoading} title={'저장중'} />
      </>
    );
  };
