import { FC } from 'react';

import OrderDeliveryPage from '@service/store/page/OrderDeliveryPage';
import OrderDetailPage from '@service/store/page/OrderDetailPage';
import OrderPaymentResultPage from '@service/store/page/OrderPaymentResultPage';
import { usePublicShopOrderForStoreHook } from '@shared/api/shopOrder/shopOrder.hook';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const PublicOrderIdRouter: FC = () => {
  const { storeRole } = useAuth();
  const { orderId } = useParams();

  usePublicShopOrderForStoreHook(orderId ?? 'undefined', storeRole(), Boolean(orderId));

  return (
    <Routes>
      <Route path="" element={<OrderDetailPage />} />
      <Route path="delivery" element={<OrderDeliveryPage />} />
      <Route path="payment" element={<OrderPaymentResultPage />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

export default PublicOrderIdRouter;
