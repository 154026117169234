import { FC, useMemo } from 'react';

import {
  BugoBrandAnnouncementFormInputs,
  BugoBrandAnnouncementFormInputsEnum,
} from '@service/bugo/interfaces/bugoBrandAnnouncementForm.interface';
import { BugoBrandAnnouncement } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { Button } from '@shared/components/Button';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import TextEditor from '@shared/components/TextEditor';
import { TextField } from '@shared/components/TextField';
import { CheckBox } from '@shared/containers';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { Location, useLocation } from 'react-router-dom';

interface IProps {
  onSubmit: SubmitHandler<BugoBrandAnnouncementFormInputs>;
  defaultAnnouncement?: BugoBrandAnnouncement;
}

interface ILocationState extends Location {
  state: {
    selectedTeamType?: string;
  } | null;
}

const BugoBrandAnnouncementForm: FC<IProps> = ({
  onSubmit,
  defaultAnnouncement,
}: IProps) => {
  const { userProfile } = useAuth();
  const { state: teamTypeState } = useLocation() as ILocationState;

  // teamTypes initialize
  const teamTypes = useMemo(() => {
    const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];
    const teamTypeList = bugoBrand?.bugoAgency?.teamTypes ?? [];

    const result: CheckBoxFilter = {};

    teamTypeList.forEach((teamType) => (result[teamType] = false));

    // default value 적용
    if (defaultAnnouncement?.teamTypes) {
      // Edit Page
      defaultAnnouncement.teamTypes.forEach(
        (defaultTeamType) => (result[defaultTeamType] = true),
      );
    } else {
      // Add Page
      const selectedTeamType = teamTypeState?.selectedTeamType;
      const condition =
        selectedTeamType && ['*', ...Object.keys([result])].includes(selectedTeamType);
      if (condition) {
        if (selectedTeamType === '*') {
          Object.keys(result).forEach((key) => (result[key] = true));
        } else {
          result[selectedTeamType] = true;
        }
      }
    }
    return result;
  }, [
    defaultAnnouncement?.teamTypes,
    teamTypeState?.selectedTeamType,
    userProfile?.bugoAgencyAdminDetail?.bugoBrands,
  ]);

  const { register, handleSubmit, setValue, control } =
    useForm<BugoBrandAnnouncementFormInputs>({
      defaultValues: {
        title: defaultAnnouncement?.title ?? '',
        type: defaultAnnouncement?.type ?? '',
        content: defaultAnnouncement?.content ?? '',
        isReadRequired: defaultAnnouncement?.isReadRequired ?? false,
        teamTypes,
      },
    });

  const contentValue = useWatch({
    control,
    name: BugoBrandAnnouncementFormInputsEnum.Content,
  });
  const isReadRequiredValue = useWatch({
    control,
    name: BugoBrandAnnouncementFormInputsEnum.IsReadRequired,
  });
  const teamTypesValue = useWatch({
    control,
    name: BugoBrandAnnouncementFormInputsEnum.TeamTypes,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {/* Text Inputs */}
      <TextField
        type="text"
        label="제목"
        placeholder="제목을 입력해주세요."
        className="w-full text-sm"
        labelClassname="pl-2 pb-1 font-bold"
        {...register(BugoBrandAnnouncementFormInputsEnum.Title, { required: true })}
      />
      <TextField
        type="text"
        label="말머리"
        placeholder="ex) 업데이트, 공지사항, 이벤트"
        className="w-full text-sm"
        labelClassname="pl-2 pb-1 font-bold"
        {...register(BugoBrandAnnouncementFormInputsEnum.Type, { required: true })}
      />
      {/* 공지대상 */}
      <div>
        <p className="pl-2 pb-1 font-bold">공지대상</p>
        {/* 공지대상 필터 */}
        <div className="flex justify-around border theme-bg-1 theme-border-1">
          <CheckboxFilter
            filter={teamTypesValue}
            setFilter={(checked) =>
              setValue(BugoBrandAnnouncementFormInputsEnum.TeamTypes, checked)
            }
          />
        </div>
      </div>
      {/* Textarea Input */}
      <div>
        <p className="pl-2 pb-1 font-bold">내용</p>
        <TextEditor
          value={contentValue}
          onChange={(value) =>
            setValue(BugoBrandAnnouncementFormInputsEnum.Content, value)
          }
        />
      </div>
      {/* 필독공지여부 */}
      <label className="flex items-center justify-between border-b px-2 pb-3">
        <h5 className="font-bold">필독공지여부</h5>
        <CheckBox
          checked={isReadRequiredValue}
          onCheckedChange={(checked) =>
            setValue(BugoBrandAnnouncementFormInputsEnum.IsReadRequired, checked)
          }
        />
      </label>
      {/* Submit Button */}
      <div className="grid grid-cols-2">
        <div />
        <Button
          type="submit"
          className="button-rectangle mt-4 bg-gray-900 py-3 text-base text-white"
        >
          제출하기
        </Button>
      </div>
    </form>
  );
};

export default BugoBrandAnnouncementForm;
