import React, { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { AccountInput } from '@shared/components/AccountInput';
import { userProfileSelector } from '@shared/state/atom/auth.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const BugoSignupInitForm: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const setUserProfile = useSetRecoilState(userProfileSelector);

  const navigate = useNavigate();
  const { userProfile, token, getMe } = useAuth();

  useEffect(() => {
    // console.log(token);
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  useEffect(() => {
    // console.log('userProfile: ', userProfile);
    if (userProfile) {
      navigate('/');
    }
  }, [userProfile, navigate]);

  const onSubmit: SubmitHandler<FieldValues> = async (data, e) => {
    if (!token) return;
    // let roleName: UserRole;

    // if (role === '상조 관리자') {
    //   roleName = UserRole.BugoAgencyAdmin;
    // } else if (role === '상조 지도사') {
    //   roleName = UserRole.BugoAgencyWorker;
    // } else {
    //   roleName = UserRole.BugoCustomer;
    // }

    // await signupInit(signupInitBody).then((result) => {
    //   // console.log(result);
    // });
    const userProfile = await getMe(token);
    // console.log('userProfile: ', userProfile);
    if (userProfile) {
      setUserProfile(userProfile);
      // console.log(data, e);
    }
  };
  const onError: SubmitErrorHandler<FieldValues> = (errors, e) => {
    // console.log(errors, e);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="mt-8">
        {/* Name */}
        <AccountInput
          register={register}
          registerName={'name'}
          type="text"
          text="이름"
          placeholder="이름"
        />
        {/* Gender */}
        <AccountInput
          register={register}
          registerName={'gender'}
          type="text"
          text="성별"
          placeholder="성별"
        />
        {/* Birth */}
        <AccountInput
          register={register}
          registerName={'birth'}
          type="text"
          text="생년월일"
          placeholder="생년월일"
        />
        {/* PhoneNumber */}
        <AccountInput
          register={register}
          registerName={'phoneNumber'}
          type="text"
          text="휴대폰 번호"
          placeholder="휴대폰 번호"
        />
        {/* <Select
          className="mt-3 rounded-lg"
          control={control}
          name="role"
          optionList={['상조 관리자', '상조 지도사']}
          placeholder="역할"
        ></Select> */}
      </div>
      {/* ButtonSet */}
      <div>
        {/* SubmitButton */}
        <BugoBasicButton
          type="submit"
          className="mt-8 theme-bg-main"
          colorTheme="primary"
          text="가입 완료하기"
        />
      </div>
    </form>
  );
};

export { BugoSignupInitForm };
