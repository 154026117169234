import { ButtonHTMLAttributes, FC } from 'react';

import { Button } from '@shared/components/Button';
import { useNavigate } from 'react-router-dom';

type StoreColorTheme = 'primary' | 'secondary';

//TODO: BugoBasicButton과 합쳐서 shared로 이동?
export interface ConfirmButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  colorTheme?: StoreColorTheme;
  to?: string;
}

const StoreBasicButton: FC<ConfirmButtonProps> = function ({
  text,
  to,
  children,
  colorTheme = 'primary',
  className = '',
  onClick,
  ...props
}: ConfirmButtonProps) {
  const navigate = useNavigate();

  const buttonColorStyle = (colorTheme: StoreColorTheme) => {
    switch (colorTheme) {
      case 'primary':
        return 'bg-myApricot border-myApricot';

      case 'secondary':
        return 'bg-gray-800 border-gray-800';
    }
  };

  return (
    <Button
      className={`button-rectangle h-13 border-solid text-14 text-white ${buttonColorStyle(
        colorTheme,
      )} ${className}`}
      onClick={to ? () => navigate(to) : onClick}
      {...props}
    >
      {text ?? children}
    </Button>
  );
};

export { StoreBasicButton };
