import { FC } from 'react';

import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { User } from '@shared/api/user/user.interface';
import Modal from '@shared/components/Modal';

import { AdminBugoBrandAgencyBranchAdminForm } from '../containers/AdminBugoBrandAgencyBranchAdminForm/AdminBugoBrandAgencyBranchAdminForm';

//*관리자가 상조 지사 관리자를 수정 및 생성할때 사용하는 모달
interface Props {
  openId: string;
  bugoBrand: BugoBrand | BugoBrandData;
  agencyBranchAdminUser?: User;
}

export const AdminAgencyBranchAdminUpsertModal: FC<Props> =
  function AdminAgencyBranchAdminUpsertModal({
    openId,
    bugoBrand,
    agencyBranchAdminUser,
  }: Props) {
    return (
      <Modal openId={openId}>
        <AdminBugoBrandAgencyBranchAdminForm
          bugoBrand={bugoBrand}
          agencyBranchAdminUser={agencyBranchAdminUser}
          editMode={agencyBranchAdminUser ? true : false}
        />
      </Modal>
    );
  };
