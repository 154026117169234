import React from 'react';

import { useRecoilValue } from 'recoil';

import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { AccountConfirmInput } from '@shared/components/AccountConfirmInput';
import { AccountInput } from '@shared/components/AccountInput';
import { notificateError } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UserRole } from '@shared/types';
import { FieldValues, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const StoreSignupForm: React.FC = () => {
  const { register, setValue, handleSubmit, getValues, control } = useForm();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { signupByUsernamePassword } = useAuth();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<FieldValues> = async (data, e) => {
    const { username, password, passwordCheck, isValid } = data;
    if (!isValid) notificateError(notifiacationInstance, '아이디 중복 확인을 해주세요.');
    if (password !== passwordCheck)
      notificateError(notifiacationInstance, '패스워드가 일치하지 않습니다.');
    if (isValid && password === passwordCheck) {
      await signupByUsernamePassword({
        username,
        password,
        roles: [UserRole.BugoCustomer, UserRole.StoreCustomer],
      });

      navigate('/flower-store');
    }
  };
  const onError: SubmitErrorHandler<FieldValues> = (errors, e) => {
    // console.log(errors, e);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div className="mt-8">
        {/* Id */}
        <AccountConfirmInput
          register={register}
          setValue={setValue}
          getValues={getValues}
          control={control}
          inputRegisterName={'username'}
          confirmRegisterName={'isValid'}
          type="text"
          text="아이디"
          placeholder="아이디"
        />
        {/* Password */}
        <AccountInput
          register={register}
          registerName={'password'}
          type="password"
          text="비밀번호"
          placeholder="비밀번호"
        />
        {/* Password check */}
        <AccountInput
          register={register}
          registerName={'passwordCheck'}
          type="password"
          text="비밀번호 확인"
          placeholder="비밀번호 확인"
        />
      </div>
      {/* ButtonSet */}
      <div>
        {/* SubmitButton */}
        <BugoBasicButton
          type="submit"
          className="mt-8 rounded-lg theme-bg-main"
          colorTheme="primary"
          text="가입하기"
        />
      </div>
    </form>
  );
};

export { StoreSignupForm };
