import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import { ShopItem, ShopItemCreate, ShopItemUpdate } from './shopItem.interface';

export const createShopItem = async (shopItem: ShopItemCreate, role: LowerRole) => {
  const { data } = await api_.post<ShopItem>(`${role}/shop-item`, shopItem);
  return data;
};

export const updateShopItem = async (shopItem: ShopItemUpdate, role: LowerRole) => {
  const { data } = await api_.patch<ShopItem>(
    `${role}/shop-item/${shopItem._id}`,
    shopItem,
  );
  return data;
};
