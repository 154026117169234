import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { deleteCourtesyContent } from '@shared/api/courtesyAsset/courtesyAsset.controller';
import { courtesyContentListByBugoBrandHookUrl } from '@shared/api/courtesyAsset/courtesyAsset.hook';
import { CourtesyContent } from '@shared/api/courtesyAsset/courtesyAsset.interface';
import DismissModal from '@shared/components/DismissModal';
import { Icon } from '@shared/components/icons';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useQueryClient } from 'react-query';

interface Props {
  couortesyContent: CourtesyContent;
}

export const CourtesyContentCard: FC<Props> = function CourtesyContentCard({
  couortesyContent,
}: Props) {
  const { X } = Icon();
  const { bugoRole, bugoBrandId } = useAuth();
  const deleteOpenId = `courtesyContentCard-delete-${couortesyContent._id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(deleteOpenId));

  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const deleteClick = async () => {
    try {
      await deleteCourtesyContent(couortesyContent._id, bugoRole());
      queryClient.invalidateQueries(
        courtesyContentListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
      );
      notificateSuccess(notifiacationInstance, '삭제되었습니다');
    } catch (error) {
      console.error(error);
      notificateError(notifiacationInstance, '에러');
    }
  };

  return (
    <div className="relative">
      <div
        className="absolute right-0 top-0 z-10 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <X className="wh-8" />
      </div>
      <div className="pb-2">
        <div className="bg-white p-4 py-6">
          <p className="whitespace-pre-wrap">{couortesyContent.content}</p>
        </div>
      </div>
      <div className="center-box ">
        <p className="font-medium">{couortesyContent.name}</p>
      </div>
      <DismissModal
        openId={deleteOpenId}
        onClick={deleteClick}
        buttonTitle={'삭제'}
        title={'답례 예문 삭제'}
      ></DismissModal>
    </div>
  );
};
