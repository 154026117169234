//* filter selector

import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilState } from 'recoil';

import { Dropdown, MenuProps } from 'antd';

import { CheckBox } from '..';
import { Button } from '../../components/Button';
import { filteredListAtomFamily } from './filteredList.atom';

interface Props {
  filterNameList: string[];
  filteredListAtomId: string;
  textContent?: string;
}

export const FilterSelctor: FC<Props> = function FilterSelctor({
  filterNameList,
  filteredListAtomId,
  textContent,
}: Props) {
  const [open, setOpen] = useState(false);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'final') {
      setOpen(false);
    }
  };
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const [filteredList, setFilteredList] = useRecoilState(
    filteredListAtomFamily(filteredListAtomId),
  );

  useEffect(() => {
    const filteredList = filterNameList.map((item) => {
      return {
        name: item,
        checked: true,
      };
    });
    setFilteredList(filteredList);
    //! 처음에는 일단 다 체크해서 만들어놓음
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: MenuProps['items'] = filterNameList.map((itemName, index) => {
    return {
      key: index,
      label: (
        <label className="grid cursor-pointer grid-cols-auto-1fr gap-4">
          <CheckBox
            checked={filteredList[index]?.checked ?? false}
            onCheckedChange={(checked: boolean) => {
              const tempFilteredList = [...filteredList];
              tempFilteredList[index] = {
                name: itemName,
                checked: checked,
              };
              setFilteredList(tempFilteredList);
            }}
          />
          <p className="">{itemName}</p>
        </label>
      ),
    };
  });
  items.push({
    key: 'final',
    label: (
      <div className="center-box -mx-2 mt-2 h-8 rounded-md bg-gray-800 text-white">
        확인
      </div>
    ),
  });
  items.unshift({
    key: 'all',
    label: (
      <label className="my-2 grid cursor-pointer grid-cols-auto-1fr gap-4">
        <CheckBox
          checked={filteredList.reduce((accu, prev) => {
            return accu && (prev?.checked ?? false);
          }, true)}
          onCheckedChange={(checked: boolean) => {
            const tempFilteredList = [...filteredList];
            tempFilteredList.map((_, index) => {
              tempFilteredList[index] = {
                name: filterNameList[index] ?? '',
                checked: checked,
              };
            });
            setFilteredList(tempFilteredList);
          }}
        />
        <p className="font-bold">전체</p>
      </label>
    ),
  });

  const displableNameList = filteredList.filter((item) => {
    return item?.checked;
  });
  const displayName = useMemo(() => {
    if (displableNameList.length === 1) {
      return displableNameList[0]?.name;
    } else if (displableNameList.length === 0) {
      return textContent ?? '지사선택';
    } else if (displableNameList.length === filterNameList.length) {
      return '전체선택됨';
    } else {
      return '다중선택됨';
    }
  }, [displableNameList, filterNameList.length, textContent]);

  return (
    <div>
      <Dropdown
        menu={{ items, onClick: handleMenuClick }}
        trigger={['click']}
        placement={'bottom'}
        onOpenChange={handleOpenChange}
        open={open}
        arrow={true}
        autoAdjustOverflow={false}
      >
        <Button className="shadow-sm">{displayName}</Button>
      </Dropdown>
    </div>
  );
};
