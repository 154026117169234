import { FC } from 'react';

import { TextField } from '@shared/components/TextField';
import AutoComplete from '@shared/containers/AutoComplete/AutoComplete';
import { Control, UseFormRegister } from 'react-hook-form';

const phraseCondolenceList: string[] = [
  '삼가 故人의 冥福을 빕니다',
  '삼가 고인의 명복을 빕니다',
  '謹弔',
  '천국에서 편히 쉬소서',
  '주님의 위로가 함께 하소서',
  '極樂往生發願',
  '극락왕생발원',
];

interface IProps {
  register: UseFormRegister<any>;
  control: Control<any, any>;
  names: {
    phraseSender: string;
    phraseCondolence: string;
  };
}

const PhraseInfoInputs: FC<IProps> = ({
  register,
  control,
  names: { phraseSender, phraseCondolence },
}: IProps) => {
  return (
    <div className="font-bold">
      <h4>문구 작성</h4>
      <div className="grid grid-cols-1 grid-rows-2 gap-y-3 pt-3">
        <TextField
          className="w-full"
          placeholder="보내는 사람 (서울대학교 동문 일동)"
          scrollOnFocus={true}
          {...register(phraseSender, { required: true })}
        />
        <AutoComplete
          placeholder="조의문구 선택 및 직접 입력"
          optionList={phraseCondolenceList}
          control={control}
          name={phraseCondolence}
        />
      </div>
    </div>
  );
};

export default PhraseInfoInputs;
