import { atom, atomFamily } from 'recoil';

import { User } from '@auth0/auth0-react';
import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import {
  ShopOrder,
  ShopOrderForSeller,
  ShopOrderForSellerAccount,
  ShopOrderForWorker,
  ShopOrderListByBugo,
  ShopOrderListForStore,
} from '@shared/api/shopOrder/shopOrder.interface';
import { QueryStatus } from 'react-query';

import { Image } from '../media/media.interface';
import { ShopOrderForAgencyAdmin, ShopOrderForStore } from './shopOrder.interface';

export const shopOrderListByBugoStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListByBugoStatusAtom',
  default: 'idle',
});

export const shopOrderListByBugoDataAtom = atom<ShopOrderListByBugo | null>({
  key: 'shopOrderListByBugoDataAtom',
  default: null,
});

export const shopOrderListForStoreStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListForStoreStatusAtom',
  default: 'idle',
});

export const shopOrderListForStoreDataAtom = atom<ShopOrderListForStore | null>({
  key: 'shopOrderListForStoreDataAtom',
  default: null,
});

export const shopOrderForStoreStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'shopOrderForStoreStatusAtomFamily',
  default: 'idle',
});

export const shopOrderForStoreDataAtomFamily = atomFamily<
  ShopOrderForStore | null,
  string
>({
  key: 'shopOrderForStoreDataAtomFamily',
  default: null,
});

//*상조관리자가 볼 화환내역 페이지용

export const shopOrderListForAgencyAdminStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListForAgencyAdminStatusAtom',
  default: 'idle',
});

export const shopOrderListForAgencyAdminDataAtom = atom<ShopOrderForAgencyAdmin[] | null>(
  {
    key: 'shopOrderListForAgencyAdminDataAtom',
    default: null,
  },
);

//*상조관리자가 볼 화환내역 페이지용 (refactoring)

export type ShopOrderAgencyAdmin = Pick<
  ShopOrder<
    string,
    Pick<FuneralHomeInfo, '_id' | 'name' | 'address'>,
    string,
    string,
    Pick<
      Fevent<Pick<User, '_id' | 'info' | 'bugoAgencyWorkerDetail'>>,
      '_id' | 'user' | 'deceasedInfo'
    >,
    Image
  >,
  | '_id'
  | 'paymentDetail'
  | 'orderDetail'
  | 'fevent'
  | 'createdAt'
  | 'bugoDetail'
  | 'funeralHomeInfo'
  | 'deliveryDetail'
>;

export type ShopOrderListAgencyAdminState = {
  status: QueryStatus;
  data: ShopOrderAgencyAdmin[] | null;
};

export const shopOrderListAgencyAdminStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListAgencyAdminStatusAtom',
  default: 'idle',
});

export const shopOrderListAgencyAdminDataAtom = atom<ShopOrderAgencyAdmin[] | null>({
  key: 'shopOrderListAgencyAdminDataAtom',
  default: null,
});

//* seller 용 배송관리용
export const shopOrderListForSellerByShopStatusAtomFamily = atomFamily<
  QueryStatus,
  string
>({
  key: 'shopOrderListForSellerByShopStatusAtomFamily',
  default: 'idle',
});

export const shopOrderListForSellerByShopDataAtomFamily = atomFamily<
  ShopOrderForSeller[] | null,
  string
>({
  key: 'shopOrderListForSellerByShopDataAtomFamily',
  default: null,
});

//* seller waiting
export const shopOrderWaitingListForSellerByShopStatusAtomFamily = atomFamily<
  QueryStatus,
  string
>({
  key: 'shopOrderWaitingListForSellerByShopStatusAtomFamily',
  default: 'idle',
});

export const shopOrderWaitingListForSellerByShopDataAtomFamily = atomFamily<
  ShopOrderForSeller[] | null,
  string
>({
  key: 'shopOrderWaitingListForSellerByShopDataAtomFamily',
  default: null,
});

//* seller notpaid
export const shopOrderNotPaidListForSellerByShopStatusAtomFamily = atomFamily<
  QueryStatus,
  string
>({
  key: 'shopOrderNotPaidListForSellerByShopStatusAtomFamily',
  default: 'idle',
});

export const shopOrderNotPaidListForSellerByShopDataAtomFamily = atomFamily<
  ShopOrderForSeller[] | null,
  string
>({
  key: 'shopOrderNotPaidListForSellerByShopDataAtomFamily',
  default: null,
});

//* seller account
export const shopOrderListForSellerByShopAccountStatusAtomFamily = atomFamily<
  QueryStatus,
  string
>({
  key: 'shopOrderListForSellerByShopAccountStatusAtomFamily',
  default: 'idle',
});

export const shopOrderListForSellerByShopAccountDataAtomFamily = atomFamily<
  ShopOrderForSellerAccount[] | null,
  string
>({
  key: 'shopOrderListForSellerByShopAccountDataAtomFamily',
  default: null,
});

//* worker account
export const shopOrderListForWorkerStatusAtom = atom<QueryStatus>({
  key: 'shopOrderListForWorkerStatusAtom',
  default: 'idle',
});

export const shopOrderListForWorkerDataAtom = atom<ShopOrderForWorker[] | null>({
  key: 'shopOrderListForWorkerDataAtom',
  default: null,
});
