import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoBrandListHookUrl } from '@shared/api/bugoBrand/bugoBrand.hook';
import { BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import DismissModal from '@shared/components/DismissModal';
import { LoadingModal } from '@shared/components/LoadingModal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useMutation, useQueryClient } from 'react-query';

import { onBugoBrandMultiAgencyDelete } from './AdminBugoBrandMultiAgency.util';

interface Props {
  openId: string;
  bugoBrand: BugoBrandData;
  multiAgencyIndex: number;
}

export const AdminBUgoBrandMultiAGencyDeleteModal: FC<Props> = function ({
  openId,
  bugoBrand,
  multiAgencyIndex,
}) {
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      notificateSuccess(notifiacationInstance, '저장 완료');
      queryClient.invalidateQueries(bugoBrandListHookUrl);
    },
    onError: () => {
      notificateError(notifiacationInstance, '상조정보 저장 실패');
    },
  });

  const onDeleteMultiAgency = async () => {
    const ret = await onBugoBrandMultiAgencyDelete(
      bugoBrand._id,
      bugoBrand.bugoAgencyList?.map((bugoMultiAgency) => {
        return {
          ...bugoMultiAgency,
          headerImage: bugoMultiAgency.headerImage?._id,
          footerImage: bugoMultiAgency.footerImage?._id,
        };
      }) ?? [],
      multiAgencyIndex,
    );
    console.log('ret', ret);
  };
  return (
    <>
      <DismissModal
        openId={openId}
        onClick={(e) => {
          e.preventDefault();
          mutate(onDeleteMultiAgency());
        }}
        buttonTitle={'삭제'}
        title={'행사 상조 삭제'}
      ></DismissModal>
      <LoadingModal open={isLoading} title={'저장중'} />
    </>
  );
};
