// 부고카드 클릭시 나오는 fevent manage modal

import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Icon } from '@shared/components/icons';
import Modal from '@shared/components/Modal';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
  feventId: string;
}

export const FeventManageModal: FC<Props> = function FeventManageModal({
  title,
  feventId,
}: Props) {
  const {
    Link: LinkIcon,
    Pencil,
    Send,
    View,
    LocationDotSolid,
    MagnifyingGlassRegular,
  } = Icon();
  const { isAdmin } = useAuth();
  const openId = `fevent-manage-modal-${feventId}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  return (
    <Modal openId={openId} position="center">
      <div className="space-y-4">
        <div>
          <p className="bugo-h1 break-keep">{title}</p>
        </div>
        <div className="flex flex-col gap-2">
          <Link
            onClick={() => setOpen(false)}
            to={`/fevent/${feventId}/edit`}
            className="flex items-center rounded-md border p-2 px-4 shadow-sm"
          >
            <Pencil className="rounded-full theme-bg-18" />
            <div className="center-box h-full pl-1">
              <p className="bugo-h3 text-sm">부고수정</p>
            </div>
          </Link>

          <Link
            onClick={() => setOpen(false)}
            to={`/fevent/${feventId}/obituary-send`}
            className="flex items-center rounded-md border p-2 px-4 shadow-sm"
          >
            <Send className="rounded-full theme-bg-18" />

            <div className="center-box h-full pl-1">
              <p className="bugo-h3 text-sm">부고 발송</p>
            </div>
          </Link>
          <Link
            onClick={() => setOpen(false)}
            className="flex items-center rounded-md border p-2 px-4 shadow-sm"
            to={`/fevent/${feventId}/courtesy-send`}
          >
            <LinkIcon className="rounded-full theme-bg-18" />
            <div className="center-box h-full pl-1">
              <p className="bugo-h3 text-sm">답례글 링크 발송</p>
            </div>
          </Link>
          <Link
            onClick={() => setOpen(false)}
            className="flex items-center rounded-md border p-2 px-4 shadow-sm"
            to={`/fevent/${feventId}/preview-link`}
          >
            <View className="wh-6 rounded-full theme-bg-18" />
            <div className="center-box h-full pl-1">
              <p className="bugo-h3 text-sm">부고미리보기</p>
            </div>
          </Link>
          {isAdmin() && (
            <Link
              onClick={() => setOpen(false)}
              className="flex items-center rounded-md border p-2 px-4 shadow-sm"
              to={`/fevent/${feventId}/obituary-preview`}
            >
              <LocationDotSolid className="wh-6 rounded-full theme-fill-1" />
              <div className="center-box h-full pl-1">
                <p className="bugo-h3 text-sm">지도보기</p>
              </div>
            </Link>
          )}
          {isAdmin() && (
            <Link
              onClick={() => setOpen(false)}
              className="flex items-center rounded-md border p-2 px-4 shadow-sm"
              to={`/fevent/${feventId}/admin-info`}
            >
              <MagnifyingGlassRegular className="wh-6 rounded-full theme-fill-1" />
              <div className="center-box h-full pl-1">
                <p className="bugo-h3 text-sm">관리자용 부고정보</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </Modal>
  );
};
