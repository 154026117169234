import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { simpleBugoBrandListStateSelector } from '@shared/api/bugoBrand/bugoBrand.selector';
import { DistributionNetwork } from '@shared/api/distributionNetwork/distributionNetwork.interface';
import { useSellerShopListHook } from '@shared/api/shop/shop.hook';
import { Shop } from '@shared/api/shop/shop.interface';
import { sellerShopListStateSelector } from '@shared/api/shop/shop.selector';
import { Button } from '@shared/components/Button';
import { Label } from '@shared/components/Label';
import Modal from '@shared/components/Modal';
import FuneralHomeInfoSearcher from '@shared/containers/FuneralHomeInfoSearcher/FuneralHomeInfoSearcher';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { api_ } from '@shared/plugIn/axios';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useForm } from 'react-hook-form';

interface Props {
  openId: string;
  distributionNetwork?: DistributionNetwork<BugoBrand, string, Shop>;
}

export const AdminDistributionNetworkUpsertModal: FC<Props> =
  function AdminDistributionNetworkUpsertModal({ openId, distributionNetwork }: Props) {
    useSellerShopListHook();
    const shopListState = useRecoilValue(sellerShopListStateSelector);
    const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
    const title = distributionNetwork ? 'DN 수정' : 'DN 등록';

    const { control, setValue, handleSubmit } = useForm<{
      bugoBrand: string;
      funeralHomeInfo: string;
      shop: string;
      _id?: string;
    }>({
      defaultValues: {
        bugoBrand: distributionNetwork?.bugoBrand._id,
        funeralHomeInfo: distributionNetwork?.funeralHomeInfo,
        shop: distributionNetwork?.shop._id,
        _id: distributionNetwork?._id,
      },
    });

    useEffect(() => {
      if (distributionNetwork) {
        setValue('_id', distributionNetwork._id);
        setValue('shop', distributionNetwork.shop._id);
        setValue('funeralHomeInfo', distributionNetwork.funeralHomeInfo);
        setValue('bugoBrand', distributionNetwork.bugoBrand._id);
      }
    }, [distributionNetwork, setValue]);

    const simpleBugoBrandState = useRecoilValue(simpleBugoBrandListStateSelector);

    const onFuneralHomeSelect = (funeralHomeId: string) => {
      setValue('funeralHomeInfo', funeralHomeId);
    };
    const bugoBrandSelect = useMemo(() => {
      if (simpleBugoBrandState.status === 'success' && simpleBugoBrandState.data) {
        const bugoBrandList = simpleBugoBrandState.data;
        const optionList = bugoBrandList.map((item) => {
          return {
            label: item.bugoAgency?.corpName ?? item._id,
            value: item._id,
          };
        });
        return (
          <Select
            optionList={optionList}
            placeholder="부고브랜드"
            control={control}
            name={'bugoBrand'}
          ></Select>
        );
      }
    }, [control, simpleBugoBrandState.data, simpleBugoBrandState.status]);

    const shopSelect = useMemo(() => {
      if (shopListState.status === 'success' && shopListState.data) {
        const shopList = shopListState.data;
        const optionList = shopList.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        });
        return (
          <Select
            optionList={optionList}
            placeholder="Shop"
            control={control}
            name={'shop'}
          ></Select>
        );
      }
    }, [control, shopListState.data, shopListState.status]);

    const onSubmit = async (data: {
      bugoBrand: string;
      funeralHomeInfo: string;
      shop: string;
      _id?: string;
    }) => {
      try {
        //수정
        if (data._id) {
          const updateDN = {
            _id: data._id,
            bugoBrand: data.bugoBrand,
            funeralHomeInfo: data.funeralHomeInfo,
            shop: data.shop,
          };
          const { data: dn } = await api_.patch(
            `admin/distribution-network/${data._id}`,
            updateDN,
          );
          console.log(dn);
        }
        //등록
        else {
          const createDN = {
            bugoBrand: data.bugoBrand,
            funeralHomeInfo: data.funeralHomeInfo,
            shop: data.shop,
          };
          const { data: dn } = await api_.post(`admin/distribution-network`, createDN);
          console.log(dn);
        }
        notificateSuccess(notifiacationInstance, '완료');
      } catch (err: any) {
        console.error(err);
        notificateError(notifiacationInstance, '실패');
      }
    };

    return (
      <Modal openId={openId} className="w-full" position="center">
        <h3 className="pb-5 text-base font-bold">{title}</h3>
        <div className="space-y-4">
          <div>
            <Label>장례식장</Label>
            <FuneralHomeInfoSearcher
              value={''}
              onValueChange={onFuneralHomeSelect}
              onEmbedSelect={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          </div>

          <div className="label-col">
            <Label>부고브랜드</Label>
            {bugoBrandSelect}
          </div>

          <div className="label-col">
            <Label>Shop</Label>
            {shopSelect}
          </div>
          <Button
            className="button-rectangle theme-text-3 theme-bg-main"
            onClick={handleSubmit(onSubmit)}
          >
            {title}
          </Button>
        </div>
      </Modal>
    );
  };
