import React, { useCallback } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { useTitleHook } from '@service/bugo/hooks/useTitleHook';

import { ReactComponent as KaKaoLogo } from '/src/assets/svg/kakao-svgrepo-com.svg';

import { Stepper } from '@shared/containers/Stepper/Stepper';
import { stepperStatusAtomFamily } from '@shared/containers/Stepper/state/Stepper.atom';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';

const AuthorizationStep = () => {
  return (
    <div className="mt-4 px-3 py-10 pb-4 shadow-smd theme-bg-1">
      {/* Description */}
      <div>본인인증 후 아이디 확인 및 비밀번호 변경이 가능합니다.</div>
      {/* ButtonSet */}
      <div>
        {/* SubmitButton */}
        <BugoBasicButton className="mt-3 theme-bg-main" text="휴대폰 본인인증" />
        {/* KakaoButton */}
        <BugoBasicButton className="mt-3 bg-kakao-yellow text-kakao-brown">
          <KaKaoLogo className="absolute h-5 w-5" />
          <span>카카오로 인증하기</span>
        </BugoBasicButton>
      </div>
    </div>
  );
};

const FindIdStep = () => {
  return (
    <div className="mt-4 px-3 py-10 pb-4 shadow-smd theme-bg-1">
      {/* Description */}
      <div>
        고객님의 아이디는 <span className="font-bold">"asdasd"</span> 입니다.
      </div>
      {/* ButtonSet */}
      <div>
        {/* SubmitButton */}
        <BugoBasicButton className="mt-3 theme-bg-main" text="비밀번호 변경하기" />
        {/* KakaoButton */}
        <BugoBasicButton className="mt-3" colorTheme="secondary" text="로그인" />
      </div>
    </div>
  );
};

const ChangePasswordStep = () => {
  return (
    <div className="mt-4 px-3 py-10 pb-4 shadow-smd theme-bg-1">
      {/* Description */}
      <div>변경하실 비밀번호를 입력해주세요.</div>
      {/* InputSet */}
      <div>
        {/* Id */}
        <div className="mt-4">
          <span className="text-14 theme-text-9">새 비밀번호</span>
          <input
            type="password"
            className="relative mt-1 h-11 w-full rounded-none border border-gray-300 py-3 pl-4 text-16 font-bold  focus:theme-border-main sm:text-sm"
            placeholder="새 비밀번호"
          />
        </div>
        {/* Password */}
        <div className="mt-4">
          <span className="text-14">비밀번호 확인</span>
          <input
            type="password"
            className="relative mt-1 h-11 w-full rounded-none border border-gray-300 py-3 pl-4 text-16 font-bold  focus:theme-border-main sm:text-sm"
            placeholder="비밀번호 확인"
          />
        </div>
      </div>
      {/* ButtonSet */}
      <div>
        {/* SubmitButton */}
        <BugoBasicButton className="mt-8 theme-bg-main" text="변경하기" />
      </div>
    </div>
  );
};

const FindMyIdPage: React.FC = () => {
  useTitleHook('아이디/비밀번호 찾기');
  //TODO: text용도, 나중에 지워야 함
  const [theme, setTheme] = useRecoilState(customThemeAtom);

  const themeToggle = useCallback(() => {
    switch (theme) {
      case UserCustomTheme.Default:
        setTheme(UserCustomTheme.Preed);
        break;
      case UserCustomTheme.Preed:
        setTheme(UserCustomTheme.Dark);
        break;
      case UserCustomTheme.Dark:
        setTheme(UserCustomTheme.Default);
        break;
      default:
        setTheme(UserCustomTheme.Dark);
    }
  }, [theme, setTheme]);
  //TODO: 여기까지
  const atomName = 'bugo-FindMyId';
  const setStepperState = useSetRecoilState(stepperStatusAtomFamily(atomName));

  //TODO: auth 추가 후 기능 재정의
  //! test
  const testOnclickHandler = useCallback(() => {
    setStepperState((curr) => (curr + 1) % 3);
  }, [setStepperState]);

  return (
    <div onClick={themeToggle}>
      <Stepper
        atomName="bugo-FindMyId"
        stepNames={['본인인증', '아이디 찾기', '비밀번호 변경']}
      >
        <AuthorizationStep />
        <FindIdStep />
        <ChangePasswordStep />
      </Stepper>
    </div>
  );
};

export { FindMyIdPage };
