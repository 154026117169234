import { FC, useMemo, useRef } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { bugoByMemberIdStateSelectorFamily } from '@shared/api/bugo/bugo.selector';
import { Button } from '@shared/components/Button';
import _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { courtesyContentStringAtom } from '../containers/MemberCourtesy/courtesy.atom';
import { getCourtesyStringFromContent } from '../containers/MemberCourtesy/courtesyGenerator';
import { useTitleHook } from '../hooks/useTitleHook';
import { ILocationState } from './CourtesySenderPage';

const DEFAULT_TAB = 0;

//! Not Used
const CourtesyEditPage: FC = () => {
  useTitleHook('답례인사 편집', '완료');

  const { state } = useLocation() as ILocationState;
  const { memberId } = useParams<{ memberId?: string }>();

  const bugoState = useRecoilValue(bugoByMemberIdStateSelectorFamily(`${memberId}`));

  const [courtesyContentString, setCourtesyContentString] = useRecoilState(
    courtesyContentStringAtom,
  );

  const naviagte = useNavigate();

  const textRef = useRef<HTMLTextAreaElement>(null);

  // CourtesySenderPage를 통해서가 아니라 url을 통해 직접 접속했을 떄 default tab 값으로 설정.
  const selectedTab = state?.tab ?? DEFAULT_TAB;
  const selectedCourtesyContent = state?.courtesyContent;

  const goToSender = () =>
    naviagte(`/member/${memberId}/courtesy`, {
      state: {
        tab: selectedTab,
        courtesyContent: selectedCourtesyContent,
        editedCourtesyContent: textRef.current?.value ?? '',
      },
      replace: true,
    });

  useTitleHook('답례인사 편집', ' ');
  const completeEdit = () => {
    setCourtesyContentString(textRef.current?.value ?? '');
    goToSender();
  };

  const textAreaContent = useMemo(() => {
    if (bugoState.status === 'success' && bugoState.data) {
      return (
        <textarea
          className="min-h-[328px] w-full border bg-white px-4 py-3 text-sm text-[12px] theme-border-main"
          ref={textRef}
          defaultValue={
            courtesyContentString ??
            (_.isUndefined(selectedCourtesyContent)
              ? ''
              : getCourtesyStringFromContent(selectedCourtesyContent, bugoState.data))
          }
          autoFocus
        ></textarea>
      );
    }
  }, [bugoState.data, bugoState.status, courtesyContentString, selectedCourtesyContent]);

  return (
    <div className="px-4 pt-6">
      {textAreaContent}
      {/* Buttons */}
      <div className="mt-[60px] grid grid-cols-2 gap-2 pb-10 font-bold text-white">
        <Button
          text="취소"
          onClick={goToSender}
          className="button-rectangle theme-bg-10"
        />
        <Button
          text="완료"
          onClick={completeEdit}
          className="button-rectangle theme-bg-main"
        />
      </div>
    </div>
  );
};

export default CourtesyEditPage;
