import { FC } from 'react';

import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import Map from '@shared/components/Map';
import { CustomCallback } from '@shared/types';

interface IProps {
  selectedFuneralHome: FuneralHomeInfo<undefined, string>;
  onConfirm: CustomCallback<void, any>;
  onCancle: CustomCallback<void, any>;
  goToFormStep: CustomCallback<void, any>;
}

const FuneralHomeInfoSearchDetail: FC<IProps> = ({
  selectedFuneralHome,
  onConfirm,
  onCancle,
  goToFormStep,
}: IProps) => {
  const { ChevronUp } = Icon();
  return (
    <div className="relative">
      <div className="flex items-center justify-between">
        <p className="font-bold">{selectedFuneralHome.name}</p>
        <ChevronUp className="cursor-pointer" onClick={() => onCancle()} />
      </div>
      <p className=" text-sm theme-text-8">{selectedFuneralHome.address}</p>
      <div className="aspect-w-13 aspect-h-7 mt-4 w-full">
        <div className="h-full w-full">
          <Map coord={selectedFuneralHome.coord} name={selectedFuneralHome.name} />
        </div>
      </div>
      <p className="mt-4 font-bold">전화번호</p>
      <p className="text-sm">{selectedFuneralHome.phoneNumber}</p>
      <p className="mt-4 font-bold">주차안내</p>
      <p className="text-sm theme-text-8">{selectedFuneralHome.parkingAddress}</p>
      <p className="mt-4 font-bold">
        위 정보가 틀릴 경우{' '}
        <span className="cursor-pointer theme-text-main" onClick={() => goToFormStep()}>
          장례식장 직접입력
        </span>
        을 눌러주세요
      </p>
      <Button
        onClick={() => onConfirm()}
        className="button-rectangle mt-4 text-white theme-bg-main"
      >
        확인
      </Button>
    </div>
  );
};

export default FuneralHomeInfoSearchDetail;
