import { FC } from 'react';

import { motion } from 'framer-motion';
import useMeasure from 'react-use-measure';

interface IProps {
  children?: React.ReactNode;
  duration: number;
  className?: string;
}

const ResizableBox: FC<IProps> = ({ children, duration, className = '' }: IProps) => {
  const [ref, { height }] = useMeasure();
  //! rerendering 못하게 주석처리함
  // const [toExitAnima, setToExitAnima] = useState<boolean>(true);

  // useEffect(() => {
  //   setToExitAnima((curr) => !curr);
  // }, [children]);

  return (
    <motion.div
      animate={{ height }}
      transition={{ duration, bounce: 0, ease: 'easeInOut' }}
      className={`overflow-y-hidden ${className}`}
    >
      <motion.div
        // !rerendering 못하게 주석처리함
        //  key={String(toExitAnima)}
        ref={ref}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default ResizableBox;
