import { FC } from 'react';

import { useRecoilState } from 'recoil';

import { Icon } from '@shared/components/icons';

import { searchTextAtomFamily } from './state/searchBar.atom';

interface IProps {
  searchTextAtomId: string;
  placeholder?: string;
}

const SearchBar: FC<IProps> = ({ searchTextAtomId, placeholder }: IProps) => {
  const { MagnifyingGlassRegular } = Icon();
  const [searchText, setSearchText] = useRecoilState(
    searchTextAtomFamily(searchTextAtomId),
  );

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchText(event.currentTarget.value);

  return (
    <div className="relative flex w-full items-center space-x-2 border px-4 theme-bg-1 theme-border-1 focus:theme-border-main">
      <input
        type="text"
        placeholder={placeholder}
        className="w-full py-3 text-sm font-bold theme-text-1 placeholder:theme-text-6"
        value={searchText}
        onChange={onTextChange}
      />
      <div className="">
        <MagnifyingGlassRegular className="wh-5 theme-fill-2" />
      </div>
    </div>
  );
};

export default SearchBar;
