import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import Map from '@shared/components/Map';
import NavigatorsModal from '@shared/components/NavigatorsModal';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { unsecuredCopyToClipboard } from '@shared/utils/clipboard.util';
import { useId } from 'react-id-generator';

interface Props {
  bugo: BugoData;
}

export const ObituaryFuneralHomeInfo: FC<Props> = function ObituaryFuneralHomeInfo({
  bugo,
}: Props) {
  const { Phone, MapLocation, TaxiBus } = Icon();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const [id] = useId(1, 'navigatorsModal');
  const [publicTransportId] = useId(1, 'publicNavigatorsModal');
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(id));
  const setPublicOpen = useSetRecoilState(simpleOpenAtomFamily(publicTransportId));

  const funeralHomeInfo = bugo.fevent.funeralHomeInfoEmbed ?? bugo.fevent.funeralHomeInfo;

  return (
    <div className="space-y-6">
      <div className="space-y-2 px-4">
        <p className="px-2 text-16 font-bold leading-6 text-gray-500">오시는 길</p>
        {funeralHomeInfo && (
          <div className="aspect-w-13 aspect-h-7 w-full">
            <div className="h-full w-full">
              <Map coord={funeralHomeInfo?.coord} name={funeralHomeInfo?.name} />
            </div>
          </div>
        )}
        {funeralHomeInfo && (
          <div>
            <div className="flex items-center justify-between px-2">
              <p className="text-base">{funeralHomeInfo.name}</p>
            </div>
            <div className="break-keep px-2 text-sm">
              <p className="text-gray-500">
                {funeralHomeInfo.address}
                &nbsp;&nbsp;&nbsp;
                <span
                  className="font-bold underline theme-text-main"
                  onClick={async (): Promise<void> => {
                    window.navigator.clipboard
                      .writeText(`${funeralHomeInfo.address}`)
                      .then(() => {
                        notificateSuccess(notifiacationInstance, '주소복사 완료');
                      })
                      .catch(() => {
                        unsecuredCopyToClipboard(
                          funeralHomeInfo.address,
                          '주소 복사 완료',
                          notifiacationInstance,
                        );
                      });
                  }}
                >
                  주소복사
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-3 gap-2 px-4">
        <a href={`tel:${funeralHomeInfo.phoneNumber}`} className="w-full">
          <Button className="center-box w-full gap-2 bg-white text-gray-700">
            <div className="center-box wh-6 -mr-1">
              <Phone className="wh-5 fill-gray-700" />
            </div>
            <p>장례식장</p>
          </Button>
        </a>
        <Button
          onClick={() => setOpen(true)}
          className="center-box gap-2 bg-white text-gray-700"
        >
          <MapLocation className="wh-6 fill-gray-700" />
          <p>길찾기</p>
        </Button>
        {id && (
          <NavigatorsModal
            openId={id}
            coords={funeralHomeInfo.coord}
            destinationName={funeralHomeInfo.name}
          />
        )}
        <Button
          onClick={() => setPublicOpen(true)}
          className="center-box gap-2 bg-white text-gray-700"
        >
          <TaxiBus className="wh-6 fill-gray-700" />
          <p>대중교통</p>
        </Button>
        {publicTransportId && (
          <NavigatorsModal
            openId={publicTransportId}
            coords={funeralHomeInfo.coord}
            destinationName={funeralHomeInfo.name}
            publicTransport={true}
          />
        )}
      </div>
      {funeralHomeInfo.parkingAddress && (
        <div>
          <div className="space-y-2 px-6">
            <p className="text-16 font-bold leading-6 text-gray-500">주차 안내</p>
            <p className="text-sm">{funeralHomeInfo.parkingAddress}</p>
          </div>
        </div>
      )}
    </div>
  );
};
