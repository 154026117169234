export enum SellerShopInfoFormEnum {
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  BusinessNumber = 'businessNumber',
  Address = 'address',
  AddressDetail = 'addressDetail',
  PostalCode = 'postalCode',
}

export interface ISellerShopInfoForm {
  [SellerShopInfoFormEnum.Name]: string;
  [SellerShopInfoFormEnum.PhoneNumber]: string;
  [SellerShopInfoFormEnum.BusinessNumber]: string;
  [SellerShopInfoFormEnum.Address]: string;
  [SellerShopInfoFormEnum.AddressDetail]: string;
  [SellerShopInfoFormEnum.PostalCode]: string;
}
