import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { FuneralHomeInfoList } from './funeralHomeInfo.interface';

export const funeralHomeInfoListStatusAtom = atom<QueryStatus>({
  key: 'funeralHomeInfoListStatusAtom',
  default: 'idle',
});

export const funeralHomeInfoListDataAtom = atom<FuneralHomeInfoList | null>({
  key: 'funeralHomeInfoListDataAtom',
  default: null,
});
