import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { EnquiryList, UserEnquiryList } from './enquiry.interface';

export const enquiryListStatusAtom = atom<QueryStatus>({
  key: 'enquryListStatusAtom',
  default: 'idle',
});

export const enquiryListDataAtom = atom<EnquiryList | null>({
  key: 'enquryListDataAtom',
  default: null,
});

export const userEnquiryListStatusAtom = atom<QueryStatus>({
  key: 'userEnquiryListStatusAtom',
  default: 'idle',
});

export const userEnquiryListDataAtom = atom<UserEnquiryList | null>({
  key: 'userEnquiryListDataAtom',
  default: null,
});
