import { FC } from 'react';

import OrderListPage from '@service/store/page/OrderListPage';
import StoreMyPage from '@service/store/page/StoreMyPage';
import { StoreUpgradeCustomerPage } from '@service/store/page/UpgradeCustomerPage';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes } from 'react-router-dom';

import OrderIdRouter from './OrderIdRouter';

const StoreAuthRouter: FC = () => {
  const { guestAuthenticated } = useAuth();

  if (guestAuthenticated) {
    sessionStorage.removeItem('redirectUrlAfterStoreLogin');

    return (
      <Routes>
        <Route path="user">
          <Route path="mypage" element={<StoreMyPage />}></Route>
          <Route path="confirm-password" element={<div>ConfirmPasswordPage</div>}></Route>
        </Route>

        <Route path="order">
          <Route path="" element={<OrderListPage />} />
          <Route path=":orderId/*" element={<OrderIdRouter />} />
        </Route>

        <Route path="upgrade/customer" element={<StoreUpgradeCustomerPage />} />
        <Route path="*" element={<Navigate to="" />} />
      </Routes>
    );
  } else {
    return <Navigate to="/flower-store/login"></Navigate>;
  }
};

export default StoreAuthRouter;
