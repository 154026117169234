import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { formatDate } from '@shared/utils/formatDate';

import { ShopOrderDetailInfoCard } from './ShopOrderDetailInfoCard';

const NotPaidOrderCard = ({ order }: { order: ShopOrderForSeller }) => {
  const deliveryDetail = order.deliveryDetail;
  const shopItem = order.orderDetail.shopItem;

  const onDeleteClick = async () => {
    try {
      alert('삭제하였습니다.');
    } catch (err: any) {
      alert('실패');
    }
  };

  return (
    <div className="space-y-2">
      <div className="h-auto w-full overflow-hidden rounded-lg border shadow">
        <div className="p-5 pb-3">
          <dl>
            <dt className="truncate text-lg font-medium text-gray-900">
              {shopItem.name}
            </dt>
            <dt className="truncate text-base font-medium text-gray-900">
              {shopItem.priceRetail.toLocaleString()}원
            </dt>
            <dd className="space-y-0.5">
              <p className="whitespace-pre-wrap text-sm text-gray-700">
                {deliveryDetail.receiverAddress}
              </p>
              <p className="text-sm text-gray-700">
                결제시도일:{' '}
                {formatDate(order.paymentDetail.requestedAt, {
                  dateSeparater: '.',
                  contains: {
                    year: false,
                    day: true,
                    hours: true,
                    minutes: true,
                  },
                })}
              </p>
            </dd>
          </dl>
          {/* <div className="flex justify-between pt-2">
            <Button
              className="button-sm bg-warn text-white hover:bg-red-400"
              onClick={onDeleteClick}
            >
              삭제
            </Button>
          </div> */}
        </div>
        <div className="space-y-2 bg-gray-50 px-5 py-2">
          <p>주문번호 : {order._id}</p>
          <ShopOrderDetailInfoCard shopOrder={order} />
        </div>
      </div>
    </div>
  );
};

export { NotPaidOrderCard };
