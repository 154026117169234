import { FC } from 'react';

import { BugoLoginPage } from '@service/bugo/page/BugoLoginPage';
import { Navigate, Route, Routes } from 'react-router-dom';

import AdminAuthRouter from './AdminRouters/AdminAuthRouter';

export const AdminRouter: FC = function AdminRouter() {
  const isAdminLoggedIn = true;

  return (
    <Routes>
      {/* TODO:관리자용 로그인 페이지 */}
      <Route path="login" element={<BugoLoginPage />}></Route>
      {/* login required */}
      <Route
        path="*"
        element={isAdminLoggedIn ? <AdminAuthRouter /> : <Navigate to="/login" />}
      />
    </Routes>
  );
};
