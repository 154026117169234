import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import {
  sellerNavSearchBarIdAtom,
  sellerNavTitleAtom,
} from '../../bugo/state/atom/navBar.atom';

//TODO: shared로 이동 혹은 store에도 생성
export const useSellerTopNavHook = function (title: string, searchBarId = '') {
  const setTitle = useSetRecoilState(sellerNavTitleAtom);
  const setSearchBarId = useSetRecoilState(sellerNavSearchBarIdAtom);

  useEffect(() => {
    setTitle(title);
    setSearchBarId(searchBarId);
    return () => {
      setTitle('');
      setSearchBarId('');
    };
  }, [searchBarId, setSearchBarId, setTitle, title]);
};
