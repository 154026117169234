import { FC, useMemo } from 'react';

import { ShopOrderForWorker } from '@shared/api/shopOrder/shopOrder.interface';
import Modal from '@shared/components/Modal';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

interface Props {
  openId: string;
  shopOrderList: ShopOrderForWorker[];
}

type AccountData = {
  id: string;
  index: number;
  shopOrder: ShopOrderForWorker;
  requestedAt: Date;
  funeralHomeInfo: string;
  senderPhrase: string;
  itemName: string;
  priceRetail: number;
  priceRebateWorker?: number;
};

export const AgencyWorkerAccountDetailModal: FC<Props> =
  function AgencyWorkerAccountDetailModal({ openId, shopOrderList }: Props) {
    const columns: TableColumnsType<AccountData> = [
      {
        title: <p className="break-keep text-xs">순번</p>,
        dataIndex: 'index',
        key: 'index',
        align: 'center',
      },
      {
        title: <p className="break-keep text-xs">정산정보</p>,
        dataIndex: 'shopOrder',
        key: 'shopOrder',
        align: 'center',
        render: (shopOrder: ShopOrderForWorker, record) => {
          return (
            <div className="grid grid-cols-auto-1fr gap-1 text-left text-xs">
              <p>결제일</p>
              <p>
                {formatDate(record.requestedAt, {
                  dateSeparater: '/',
                  contains: {
                    year: false,
                  },
                })}
              </p>
              <p>장례식장</p>
              <p>{record.funeralHomeInfo.split('(')[0]}</p>
              <p className="font-bold theme-text-main">상품명</p>
              <p className="font-bold theme-text-main">{record.itemName}</p>
              <p>문구</p>
              <p>{record.senderPhrase}</p>
            </div>
          );
        },
      },
      {
        title: <p className="break-keep text-xs">정산금</p>,
        dataIndex: 'priceRebateWorker',
        key: 'priceRebateWorker',
        align: 'center',
        render: (priceRebateWorker: string) => {
          return (
            <p className="break-keep">{(priceRebateWorker ?? 0).toLocaleString()}원</p>
          );
        },
      },
    ];

    const tableData: AccountData[] = useMemo(() => {
      return _.map(shopOrderList, (order, index) => {
        return {
          id: order._id,
          index: shopOrderList.length - index,
          shopOrder: order,
          requestedAt: order.paymentDetail.requestedAt as Date,
          funeralHomeInfo: order.funeralHomeInfo.name,
          senderPhrase: order.bugoDetail.senderPhrase,
          itemName: order.orderDetail.shopItemEmbed.name,
          priceRetail: order.orderDetail.shopItemEmbed.priceRetail,
          priceRebateWorker: order.orderDetail.shopItemEmbed.priceRebateWorker,
        };
      });
    }, [shopOrderList]);

    return (
      <Modal openId={openId} className="max-w-full px-1 sm:max-w-[80%]">
        <div className="space-y-4">
          <div>
            <Table
              className="w-full"
              columns={columns}
              dataSource={_.flatMapDeep([tableData])}
              pagination={{
                position: ['bottomCenter'],
                defaultPageSize: 5,
              }}
              rowKey={'id'}
              bordered
              size={'small'}
            />
          </div>
        </div>
      </Modal>
    );
  };
