//* 답례글 배경 이미지, 답례글 내용 관리 페이지

import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  useCourtesyAssetListByBugoBrandHook,
  useCourtesyContentListByBugoBrandHook,
} from '@shared/api/courtesyAsset/courtesyAsset.hook';
import {
  courtesyAssetListByBugoBrandSelector,
  courtesyContentListByBugoBrandSelector,
} from '@shared/api/courtesyAsset/courtesyAsset.selector';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { UpsertMode } from '@shared/types';

import { CourtesyAssetCard } from '../containers/CourtesyAssetCard/CourtesyAssetCard';
import { CourtesyAssetUpsertModal } from '../containers/CourtesyAssetUpsertModal/CourtesyAssetUpsertModal';
import { CourtesyContentCard } from '../containers/CourtesyContentCard/CourtesyContentCard';
import { CourtesyContentUpsertModal } from '../containers/CourtesyContentUpsertModal/CourtesyContentUpsertModal';
import { useTitleHook } from '../hooks/useTitleHook';

const CourtesyAssetContentManagePage: FC = function CourtesyAssetContentManagePage() {
  useTitleHook('답례글 어셋 관리');

  useCourtesyAssetListByBugoBrandHook();
  useCourtesyContentListByBugoBrandHook();

  const courtesyAssetListState = useRecoilValue(courtesyAssetListByBugoBrandSelector);
  const courtesyContentListState = useRecoilValue(courtesyContentListByBugoBrandSelector);

  console.log(courtesyContentListState);

  const { PlusSolid } = Icon();

  const courtesyAssetCardList = useMemo(() => {
    if (courtesyAssetListState.status === 'success' && courtesyAssetListState.data) {
      const courtesyAssetList = courtesyAssetListState.data;

      return courtesyAssetList.map((item) => {
        return (
          <div key={item._id}>
            <CourtesyAssetCard courtesyAsset={item} />
          </div>
        );
      });
    }
  }, [courtesyAssetListState.data, courtesyAssetListState.status]);

  const courtesyContentCardList = useMemo(() => {
    if (courtesyContentListState.status === 'success' && courtesyContentListState.data) {
      const courtesyContentList = courtesyContentListState.data;
      return courtesyContentList.map((item) => {
        return (
          <div key={item._id}>
            <CourtesyContentCard couortesyContent={item} />
          </div>
        );
      });
    }
  }, [courtesyContentListState.data, courtesyContentListState.status]);

  const courtesyAssetAddOpenId = `courtesy-asset-add-open`;
  const setCourtesyAssetAddOpen = useSetRecoilState(
    simpleOpenAtomFamily(courtesyAssetAddOpenId),
  );
  const courtesyContentAddOpenId = `courtesy-content-add-open`;
  const setCourtesyContentAddOpen = useSetRecoilState(
    simpleOpenAtomFamily(courtesyContentAddOpenId),
  );

  return (
    <>
      <div className="space-y-6 p-4 py-6">
        <div className="space-y-3">
          <h3 className="bugo-h1">답례 이미지 관리</h3>
          <div className="w-full border-b"></div>
          <div className="flex justify-end">
            <Button
              className="button-fold"
              onClick={() => {
                setCourtesyAssetAddOpen(true);
              }}
            >
              <div className="center-box gap-1">
                <PlusSolid className="wh-4" />
                추가하기
              </div>
            </Button>
          </div>
          <div className="grid grid-cols-4 gap-4">{courtesyAssetCardList}</div>
        </div>
        <div className="space-y-3">
          <h3 className="bugo-h1">답례 예문 관리</h3>
          <div className="w-full border-b"></div>
          <div className="flex justify-end">
            <Button
              className="button-fold"
              onClick={() => {
                setCourtesyContentAddOpen(true);
              }}
            >
              <div className="center-box gap-1">
                <PlusSolid className="wh-4" />
                추가하기
              </div>
            </Button>
          </div>
          <div className="grid grid-cols-3 gap-4">{courtesyContentCardList}</div>
        </div>
      </div>
      <CourtesyAssetUpsertModal
        openId={courtesyAssetAddOpenId}
        mode={UpsertMode.Insert}
      />
      <CourtesyContentUpsertModal
        openId={courtesyContentAddOpenId}
        mode={UpsertMode.Insert}
      />
    </>
  );
};

export default CourtesyAssetContentManagePage;
