//* shopOrder에 맞는 수령인정보 출력함
//! 사용되는 페이지
//* OrderDetailPage

import { FC } from 'react';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';

interface Props {
  shopOrder: ShopOrderForStore;
}

export const ReceiverInfoCard: FC<Props> = function ReceiverInfoCard({ shopOrder }) {
  const receiver = shopOrder.deliveryDetail.receiverName;
  const receiverAddress = shopOrder.deliveryDetail.receiverAddress;
  return (
    <div className="border-t px-4 py-3 text-sm theme-bg-1 theme-border-1">
      {/* 받는 사람 */}
      <div>
        <div className="theme-text-8">받으시는분</div>
        <p className="mt-1 font-bold">{receiver}</p>
      </div>
      {/* 주소 */}
      <div className="mt-3">
        <div className="theme-text-8">배송지</div>
        <p className="mt-1 font-bold">{receiverAddress}</p>
      </div>
    </div>
  );
};
