import { SetterOrUpdater } from 'recoil';

import { NotificationInstance } from 'antd/es/notification/interface';

import { notificateError } from './../ant-notification/ant-notifiaction';

declare global {
  interface Window {
    IMP: any;
  }
}

export const phoneCertification = function (
  redirectUrl: string,
  setCiAuthUid: SetterOrUpdater<string | null>,
  notifiacationInstance: NotificationInstance | null,
) {
  const IMP = window.IMP;
  // 가맹점 식별 코드로 생성
  IMP.init(process.env.REACT_APP_IMP_CODE);
  // // console.log(redirectUrl);
  //imp certification 호출
  IMP.certification(
    {
      //params
      m_redirect_url: redirectUrl, // 다시 돌아갈 원래 url
      popup: true,
    },
    function (rsp: {
      success: any;
      imp_uid: string | ((currVal: string | null) => string | null) | null;
      error_msg: any;
    }) {
      //callback
      if (rsp.success) {
        // 인증성공
        // // console.log(rsp);
        // // console.log(redirectUrl);
        setCiAuthUid(rsp.imp_uid);
        window.location.href = redirectUrl;
      } else {
        //인증 실패
        notificateError(
          notifiacationInstance,
          `인증에 실패하였습니다.\n에러:${rsp.error_msg}`,
        );
      }
    },
  );
};
