import { FC, useCallback, useState } from 'react';
import 'react-quill/dist/quill.bubble.css';

import { useSetRecoilState } from 'recoil';

import {
  bugoBrandAnnouncementListDataAtom,
  bugoBrandAnnouncementListStatusAtom,
} from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.atom';
import { deleteBugoBrandAnnouncement } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.controller';
import { bugoBrandAnnouncementListHookUrl } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.hook';
import { BugoBrandAnnouncement } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { Button } from '@shared/components/Button';
import ResizableBox from '@shared/components/ResizableBox';
import UnClosableConfirmModal from '@shared/components/UnClosableConfirmModal';
import { Icon } from '@shared/components/icons';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';
import { deleteOneByIdAndSetListState } from '@shared/utils/deleteOne';
import { formatDate } from '@shared/utils/formatDate';
import { useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import { useNavigate } from 'react-router-dom';

const DURATION = 0.3;

interface IProps {
  announcement: BugoBrandAnnouncement;
  children?: React.ReactNode;
}

const AnnouncementCard: FC<IProps> = ({ announcement, children }: IProps) => {
  const [isFolded, setIsFolded] = useState<boolean>(true);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const setAnnouncementListData = useSetRecoilState(bugoBrandAnnouncementListDataAtom);
  const setAnnouncementListStatus = useSetRecoilState(
    bugoBrandAnnouncementListStatusAtom,
  );

  const { bugoRole } = useAuth();
  const navigate = useNavigate();

  const { ChevronDown } = Icon();

  const onClick = () => setIsFolded((prev) => !prev);

  const onEditClick = () => navigate(`bugo-brand/announcement/${announcement._id}/edit`);

  const onDeleteClick = () => setDeleteModalOpen(true);

  const cancleDelete = () => setDeleteModalOpen(false);

  const confirmDelete = useCallback(async () => {
    await deleteOneByIdAndSetListState<BugoBrandAnnouncement>(
      announcement._id,
      setAnnouncementListData,
      setAnnouncementListStatus,
      async () => {
        await deleteBugoBrandAnnouncement(announcement._id, bugoRole());
        queryClient.invalidateQueries(bugoBrandAnnouncementListHookUrl(bugoRole()));
      },
    );
  }, [
    announcement._id,
    bugoRole,
    queryClient,
    setAnnouncementListData,
    setAnnouncementListStatus,
  ]);

  return (
    <>
      <ResizableBox duration={DURATION}>
        <div
          className={`whitespace-pre-line break-keep rounded-sm text-xs shadow-smd theme-text-1 theme-bg-1`}
        >
          <button
            onClick={onClick}
            className={`flex w-full flex-col px-4 py-2 ${
              isFolded ? '' : 'border-b theme-border-1'
            }`}
          >
            <div className={`flex w-full items-center justify-between`}>
              {/* Type */}
              <div className="center-box gap-4">
                <h5 className={`pl-2 text-start font-bold theme-text-main`}>
                  {announcement.type}
                </h5>
                {[LowerRole.Admin, LowerRole.BugoAgencyAdmin].includes(bugoRole()) && (
                  <div
                    className={`center-box gap-2 text-start font-bold theme-text-main`}
                  >
                    {announcement.teamTypes.map((teamType) => {
                      return (
                        <div
                          className="rounded-[4px] border p-1 theme-border-main"
                          key={announcement._id + teamType}
                        >
                          {teamType}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>

              <ChevronDown
                className={`items-center stroke-none ${isFolded ? '' : 'rotate-180'}`}
              />
            </div>
            {/* Title */}
            <h6 className="pl-1 pb-2">{announcement.title}</h6>
            {/* CreatedAt */}
            <span className="pl-1 theme-text-6">
              {formatDate(announcement.createdAt, {
                dateSeparater: '.',
              })}
            </span>
          </button>
          {!isFolded && (
            <div className="space-y-3 p-4 text-[12px] font-normal">
              <div className="">
                <ReactQuill
                  className="quill-readonly"
                  value={announcement.content}
                  readOnly={true}
                  theme="bubble"
                />
              </div>
              {/* 삭제 / 수정 버튼 */}
              {[LowerRole.Admin, LowerRole.BugoAgencyAdmin].includes(bugoRole()) && (
                <div className="flex items-center justify-end space-x-2">
                  <Button
                    onClick={onEditClick}
                    className="h-8 px-4 text-12 shadow-sm theme-text-9 theme-bg-1 theme-border-1"
                  >
                    <p className="font-medium">수정</p>
                  </Button>
                  <Button
                    onClick={onDeleteClick}
                    className="h-8 px-4 text-12 shadow-sm theme-text-9 theme-bg-1 theme-border-1"
                  >
                    <p className="font-medium">삭제</p>
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </ResizableBox>
      <UnClosableConfirmModal
        open={deleteModalOpen}
        title={`'${announcement.title}'를(을) 삭제합니다`}
        description="공지를 삭제하시겠습니까?"
        cancleBtnCallback={cancleDelete}
        confirmBtnCallback={confirmDelete}
      />
    </>
  );
};

export default AnnouncementCard;
