import { ReactNode, useCallback } from 'react';

import { PaginationTableRender } from './PaginationTable';

interface IProps<T> {
  data: T;
  keyListForOrder: (keyof T)[];
  rowIndex: number;
  render?: PaginationTableRender<T>;
  containerClassName?: string;
}

const PaginationTableRow = <T,>({
  data,
  keyListForOrder,
  rowIndex,
  render,
  containerClassName,
}: IProps<T>) => {
  const renderItem = useCallback(
    (key: keyof T, index: number) => {
      if (!render || !render[key]) {
        return (
          <p
            key={`pagination-table-row-item-${index}`}
            className="whitespace-pre-line break-keep text-center"
          >
            {data[key] as ReactNode}
          </p>
        );
      }

      const renderFunc = render[key];

      if (renderFunc)
        return renderFunc({
          data: data[key],
          record: data,
          index: rowIndex,
        });

      return <></>;
    },
    [data, render, rowIndex],
  );

  return (
    <div className={`flex ${containerClassName}`}>
      {keyListForOrder.map((key, index) => {
        return (
          <div
            className="center-box w-full whitespace-pre-line break-keep p-2 text-center"
            key={`pagination-table-row-${index}`}
          >
            {renderItem(key, index)}
          </div>
        );
      })}
    </div>
  );
};

export default PaginationTableRow;
