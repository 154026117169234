import { MemberIFormInput } from '@service/bugo/interfaces/memberAddEdit.interface';

import { Bugo } from '../bugo/bugo.interface';
import { CashAccount } from '../cashAccount/cashAccount.interface';
import { Member } from './member.interface';

const checkSameMemberInputAndMemberOld = (
  memberInput: MemberIFormInput,
  memberOld: Member<string, Bugo, CashAccount>,
) => {
  return (
    memberInput._id === memberOld._id &&
    memberInput.name === memberOld.fullName &&
    memberInput.relationType === memberOld.relationType &&
    memberInput.phoneNumber === memberOld.phoneNumber &&
    memberInput.cashAccountId === memberOld.cashAccount?._id
  );
};

export { checkSameMemberInputAndMemberOld };
