export const relationTypeCategoryList: string[] = [
  '상 주',
  '자',
  '아 들',
  '자 부',
  '며느리',
  '녀',
  '딸',
  '사 위',
  '손',
  '손 자',
  '손 녀',
  '부 군',
  '배우자',
  '부',
  '모',
  '형 제',
  '자 매',
  '손 부',
  '손 서',
  '외 손',
  '외손자',
  '외손녀',
  '미망인',
  '兄弟',
  '姊妹',
  '子',
  '女',
  '孫',
];

export const DeathCharacter = '故';

export enum BankEnum {
  국민 = '국민',
  신한 = '신한',
  하나 = '하나',
  우리 = '우리',
  IBK기업 = 'IBK기업',
  농협 = '농협',
  KDB산업 = 'KDB산업',
  수협 = '수협',
  우체국 = '우체국',
  한국씨티 = '한국씨티',
  SC제일 = 'SC제일',
  카카오뱅크 = '카카오뱅크',
  케이뱅크 = '케이뱅크',
  토스뱅크 = '토스뱅크',
  경남 = '경남',
  광주 = '광주',
  iM뱅크 = 'iM뱅크(대구)',
  부산 = '부산',
  전북 = '전북',
  제주 = '제주',
  저축 = '저축',
  산림조합 = '산림조합',
  새마을금고 = '새마을금고',
  신협 = '신협',
  도이치 = '도이치',
  중국건설 = '중국건설',
  중국공상 = '중국공상',
  HSBC = 'HSBC',
  BNP파리바 = 'BNP파리바',
  JP모간체이스 = 'JP모간체이스',
  지역농축협 = '지역농축협',
  BOA = 'BOA',
}

export const bankCategoryList: BankEnum[] = [
  BankEnum.국민,
  BankEnum.신한,
  BankEnum.하나,
  BankEnum.우리,
  BankEnum.IBK기업,
  BankEnum.농협,
  BankEnum.KDB산업,
  BankEnum.수협,
  BankEnum.우체국,
  BankEnum.한국씨티,
  BankEnum.SC제일,
  BankEnum.카카오뱅크,
  BankEnum.케이뱅크,
  BankEnum.토스뱅크,
  BankEnum.경남,
  BankEnum.광주,
  BankEnum.iM뱅크,
  BankEnum.부산,
  BankEnum.전북,
  BankEnum.제주,
  BankEnum.저축,
  BankEnum.산림조합,
  BankEnum.새마을금고,
  BankEnum.신협,
  BankEnum.도이치,
  BankEnum.중국건설,
  BankEnum.중국공상,
  BankEnum.HSBC,
  BankEnum.BNP파리바,
  BankEnum.JP모간체이스,
  BankEnum.지역농축협,
  BankEnum.BOA,
];
