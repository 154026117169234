import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopOrderForSellerAccount } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useId } from 'react-id-generator';

import { SellerAccountDetailModal } from './SellerAccountDetailModal';

interface Props {
  shopOrderList: ShopOrderForSellerAccount[];
}

export const SellerAccountDetaiModalButton: FC<Props> =
  function SellerAccountDetaiModalButton({ shopOrderList }: Props) {
    const [id] = useId(1, 'sellerAccoundDetailModalButton');

    const openId = `${id}`;

    const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
    return (
      <div>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          상세보기
        </Button>
        <SellerAccountDetailModal
          openId={openId}
          shopOrderList={shopOrderList}
        ></SellerAccountDetailModal>
      </div>
    );
  };
