import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import {
  courtesyAssetListByBugoBrandDataAtom,
  courtesyAssetListByBugoBrandStatusAtom,
  courtesyAssetListByMemberDataAtom,
  courtesyAssetListByMemberStatusAtom,
  courtesyContentListByBugoBrandDataAtom,
  courtesyContentListByBugoBrandStatusAtom,
  courtesyContentListByMemberDataAtom,
  courtesyContentListByMemberStatusAtom,
} from './courtesyAsset.atom';

export const courtesyAssetListByBugoBrandHookUrl = (
  bugoBrandId: string,
  bugoRole: LowerRole,
) => {
  return `${bugoRole}/courtesy-asset/?${queryString({
    filter: {
      bugoBrand: bugoBrandId,
    },
    populate: {
      path: 'backgroundImage',
    },
  })}`;
};

//* bugoBrand owner가 해당 bugoBrandId로 본인의
export const useCourtesyAssetListByBugoBrandHook = function () {
  const { bugoRole, bugoBrandId } = useAuth();

  useRecoilQuery(
    courtesyAssetListByBugoBrandStatusAtom,
    courtesyAssetListByBugoBrandDataAtom,
    courtesyAssetListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
    fetcher,
  );
};

export const courtesyContentListByBugoBrandHookUrl = (
  bugoBrandId: string,
  bugoRole: LowerRole,
) => {
  return `${bugoRole}/courtesy-content/?${queryString({
    filter: {
      bugoBrand: bugoBrandId,
    },
  })}`;
};

//* bugoBrand owner가 해당 bugoBrandId로 본인의
export const useCourtesyContentListByBugoBrandHook = function () {
  const { bugoRole, bugoBrandId } = useAuth();

  useRecoilQuery(
    courtesyContentListByBugoBrandStatusAtom,
    courtesyContentListByBugoBrandDataAtom,
    courtesyContentListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
    fetcher,
  );
};

//* member가 접근할수 있는 courtesyAsset
export const courtesyAssetListByMemberHookUrl = (memberId: string) => {
  return `public/courtesy-asset/by-member/${memberId}?${queryString({
    populate: {
      path: 'backgroundImage',
    },
  })}`;
};

export const useCourtesyAssetListByMemberHook = function (memberId: string) {
  useRecoilQuery(
    courtesyAssetListByMemberStatusAtom,
    courtesyAssetListByMemberDataAtom,
    courtesyAssetListByMemberHookUrl(memberId),
    fetcher,
  );
};

export const courtesyContentListByMemberHookUrl = (memberId: string) => {
  return `public/courtesy-content/by-member/${memberId}`;
};

//* bugoBrand owner가 해당 bugoBrandId로 본인의
export const useCourtesyContentListByMemberHook = function (memberId: string) {
  useRecoilQuery(
    courtesyContentListByMemberStatusAtom,
    courtesyContentListByMemberDataAtom,
    courtesyContentListByMemberHookUrl(memberId),
    fetcher,
  );
};
