import { FC } from 'react';

import { Icon } from '@shared/components/icons';
import { Control, Controller } from 'react-hook-form';

interface IProps {
  defaultValue?: number;
  control: Control<any, any>;
  name: string;
}

const ShopItemCounter: FC<IProps> = ({ control, name, defaultValue = 1 }: IProps) => {
  const { CircleMinusLight, CirclePlusLight } = Icon();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value, ref, onBlur } }) => (
        <div className="flex items-center justify-between">
          <button
            className={`rounded-full bg-white ${
              value === 1 ? 'cursor-default' : 'cursor-pointer'
            }`}
            onClick={(event) => {
              event.preventDefault();
              if (value > 1) onChange(value - 1);
            }}
          >
            <CircleMinusLight
              className={`wh-6 rounded-full ${
                value === 1 ? 'fill-gray-300' : 'fill-gray-500'
              }`}
            />
          </button>
          <h5 ref={ref} onBlur={onBlur} className="text-xs font-bold text-gray-500">
            {value}
          </h5>
          <button
            className={`rounded-full bg-white ${
              value === 999 ? 'cursor-default' : 'cursor-pointer'
            }`}
            onClick={(event) => {
              event.preventDefault();
              if (value < 999) onChange(value + 1);
            }}
          >
            <CirclePlusLight
              className={`wh-6  rounded-full ${
                value === 999 ? 'fill-gray-300' : 'fill-gray-500'
              }`}
            />
          </button>
        </div>
      )}
    />
  );
};

export default ShopItemCounter;
