import { FC } from 'react';

import TimeKeeper from 'react-timekeeper';

interface TimeKeeperProps {
  time: string | undefined;
  setTime: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const MyTimeKeeper: FC<TimeKeeperProps> = function MyTimeKeeper({
  time,
  setTime,
}: TimeKeeperProps) {
  return (
    <div className="some-wrapper ">
      <TimeKeeper
        time={time}
        onChange={(newTime) => {
          setTime(newTime.formatted24);
        }}
        hour24Mode
        coarseMinutes={5}
        forceCoarseMinutes
        switchToMinuteOnHourSelect
      />
    </div>
  );
};
