import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useSellerShopListHook } from '@shared/api/shop/shop.hook';
import { sellerShopListStateSelector } from '@shared/api/shop/shop.selector';
import { Button } from '@shared/components/Button';
import { Loading } from '@shared/components/Loading';
import { Link, useNavigate } from 'react-router-dom';

export const SellerHomePage: FC = function SellerHomePage() {
  useSellerShopListHook();
  const shopsState = useRecoilValue(sellerShopListStateSelector);
  const navigate = useNavigate();

  // 한개면 해당 페이지로 바로 navigate
  useEffect(() => {
    if (shopsState.status === 'success' && shopsState.data) {
      if (shopsState.data.length === 1 && shopsState.data[0]) {
        navigate(`/flower-seller/shop/${shopsState.data[0]._id}`);
      }
    }
  }, [navigate, shopsState.data, shopsState.status]);

  const shopsButtonRender = useMemo(() => {
    if (shopsState.status === 'success' && shopsState.data) {
      const shopList = shopsState.data;
      return shopList.map((shop) => {
        return (
          <Link key={shop._id} to={`/flower-seller/shop/${shop._id}`}>
            <Button text={shop.name} className="filled-indigo-700 p-1"></Button>
          </Link>
        );
      });
    } else
      return (
        <div className="center-box h-screen-15">
          <Loading></Loading>
        </div>
      );
  }, [shopsState.data, shopsState.status]);

  return (
    <div>
      <div className="space-y-4 p-8">
        <p className="font-medium">꽃집 바로가기</p>
        <div className="flex gap-4">{shopsButtonRender}</div>
      </div>
    </div>
  );
};
