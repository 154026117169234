import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { PaymentState } from '@shared/types';

import {
  feventListByWorkerIdDataAtomFamily,
  feventListByWorkerIdStatusAtomFamily,
} from './workerDetailModal.atom';

export const feventListByWorkerIdUrl = (workerId: string, bugoRole: string) =>
  `${bugoRole}/fevent/?${queryString({
    select: ['_id', 'createdAt', 'funeralhomeInfoEmbed'],
    filter: { user: workerId },
    populate: [
      {
        path: 'funeralHomeInfo',
        select: ['_id', 'name'],
      },
      {
        path: 'shopOrders',
        match: {
          'paymentDetail.status': { $in: [PaymentState.PAID, PaymentState.CONFIRMED] },
        },
        select: ['_id'],
      },
    ],
  })}`;

//* useFeventListByWorkerId
export const useFeventListByWorkerId = function (workerId: string, disabled?: boolean) {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    feventListByWorkerIdStatusAtomFamily(workerId),
    feventListByWorkerIdDataAtomFamily(workerId),
    feventListByWorkerIdUrl(workerId, bugoRole()),
    fetcher,
    disabled,
  );
};
