import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';
import _ from 'lodash';

import { CallOrderCreateDto, CallOrderStatus } from './callOrder.interface';

export const createCallOrder = async (callOrder: CallOrderCreateDto, role: LowerRole) => {
  try {
    //TODO: sellerRole같은 걸로 만들어서 useAuth에서 사용하도록.
    const roleFilter = [LowerRole.Admin, LowerRole.StoreSeller, LowerRole.Public];
    const prefixRole = _.includes(roleFilter, role) ? role : LowerRole.Public;
    const { data: callOrder_ } = await api_.post(`${prefixRole}/call-order`, callOrder);
    return callOrder_;
  } catch (err: any) {
    return null;
  }
};

export const makeDoneCallOrder = async (callOrderId: string, role: LowerRole) => {
  try {
    const doneCallOrder = {
      _id: callOrderId,
      status: CallOrderStatus.Done,
    };

    const { data: callOrder_ } = await api_.patch(
      `${role}/call-order/${callOrderId}`,
      doneCallOrder,
    );
    return callOrder_;
  } catch (err: any) {
    console.error(err?.response?.data);
    return null;
  }
};
