import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { Rebate } from '@shared/api/rebate/rebate.interface';
import { sellerShopStateSelectorFamily } from '@shared/api/shop/shop.selector';
import { RoundedTag } from '@shared/components/RoundedTag';
import { api_ } from '@shared/plugIn/axios';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';

/**
 * Shop에서 준비해야할 Rebate와 연결된 BugoBrand와의 관계를 표시함
 * ex) 아띠
 * 표준 - 휴앤올, 위드라이프, 동반, 유원웰, 평화누리
 * Aplus - 효담
 * Preed - 프리드
 * AllWorker - 더리본
 * gaonprime - 가온프라임
 */

export const RebateBugoBrandRelationDisplay: FC =
  function RebateBugoBrandRelationDisplay() {
    const { shopId } = useParams();
    const [rebateList, setRebateList] = useState<Rebate[]>([]);

    const [bugoBrandList, setBugoBrandList] = useState<BugoBrand[]>([]);

    const shopState = useRecoilValue(sellerShopStateSelectorFamily(`${shopId}`));

    useAsync(async () => {
      const { data: rebateList } = await api_.get<Rebate[]>('admin/rebate');
      if (rebateList) {
        setRebateList(rebateList);
      }

      const { data: bugoBrandList } = await api_.get<BugoBrand[]>('admin/bugo-brand');
      if (bugoBrandList) {
        setBugoBrandList(bugoBrandList);
      }
    }, []);

    const render = useMemo(() => {
      if (bugoBrandList && rebateList) {
        return rebateList.map((rebate) => {
          return (
            <div className="flex gap-2" key={rebate._id}>
              <p>{rebate.name}:</p>
              <div className="flex flex-wrap gap-1">
                {bugoBrandList
                  .filter((bugoBrand) => {
                    return bugoBrand.bugoAgency?.rebate === rebate._id;
                  })
                  .map((bugoBrand) => {
                    const hasRelationShip = shopState.data?.bugoBrands.find(
                      (bugobrand) => {
                        return bugobrand._id === bugoBrand._id;
                      },
                    );
                    return (
                      <RoundedTag
                        key={'render' + bugoBrand._id}
                        className={`${hasRelationShip ? 'theme-bg-5' : 'theme-bg-6'}`}
                        text={bugoBrand.bugoAgency?.corpName ?? ''}
                      ></RoundedTag>
                    );
                  })}
              </div>
            </div>
          );
        });
      }
    }, [bugoBrandList, rebateList, shopState.data?.bugoBrands]);

    const shopRelationbugoBrandRender = useMemo(() => {
      if (shopState.status === 'success' && shopState.data) {
        const shop = shopState.data;
        return (
          <div>
            <p>{shop.name}:</p>
            <div className="flex flex-wrap gap-1">
              {shop.bugoBrands.map((bugoBrand) => {
                return (
                  <RoundedTag
                    className="theme-bg-5"
                    key={bugoBrand._id}
                    text={bugoBrand.bugoAgency?.corpName ?? ''}
                  ></RoundedTag>
                );
              })}
            </div>
          </div>
        );
      }
    }, [shopState.data, shopState.status]);

    return (
      <div className="space-y-4">
        <div className="space-y-3">
          <p className="bugo-h1">리베이트-부고브랜드 관계</p>
          <div className="space-y-1.5">{render}</div>
        </div>
        <div className="space-y-3">
          <p className="bugo-h1">연결된 부고브랜드 상황</p>
          {shopRelationbugoBrandRender}
        </div>
      </div>
    );
  };
