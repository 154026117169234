import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useShopOrderListAgencyAdminHookHook } from '@shared/api/shopOrder/shopOrder.hook';
import { shopOrderListAgencyAdminStateSeletor } from '@shared/api/shopOrder/shopOrder.selector';
import { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { Loading } from '@shared/components/Loading';
import { ReloadButton } from '@shared/components/ReloadButton';
import StatPageSideBar from '@shared/components/StatPageSideBar';
import SearchBar from '@shared/containers/SearchBar/SearchBar';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import { AgencyAdminShopOrderListTable } from '../containers/AgencyAdminShopOrderListTable/AgencyAdminShopOrderListTable';
import AgencyAdminShopOrderListTableMobile from '../containers/AgencyAdminShopOrderListTable/AgencyAdminShopOrderListTableMobile';
import { useTitleHook } from '../hooks/useTitleHook';

const SEARCH_TEXT_ID = 'agencyAdminFlowerStatPage';

const AgencyAdminFlowerStatPage: FC = function AgencyAdminFlowerStatPage() {
  const { bugoBrandId } = useParams();
  const bugoBrandId_ = bugoBrandId ?? 'undefined';
  useTitleHook('관리자용 페이지', undefined, undefined, true, '/');
  //* bugoBrand에 속해있는 user를 가져오는 hook
  // useWorkerUserByBugoBrandHook(bugoBrandId_);

  /*
  TODO useShopOrderListByBugoBrandHook -> useShopOrderListAgencyAdminHook
  ? 사용되는 shopOrder의 fields
    - _id
    - paymentDetail
    - orderDetail
    - funeralHomeInfo(pop)
      - address
      - name
    - bugoDetail
      - senderPhrase
    - fevent(pop)
      - _id
      - user(pop)
        - _id
        - name

  */
  useShopOrderListAgencyAdminHookHook();
  const shopOrderListState = useRecoilValue(shopOrderListAgencyAdminStateSeletor);
  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));

  const { userProfile, bugoRole } = useAuth();

  //*mobile 기준
  const isMobile = !useMediaQuery({
    query: '(min-width: 1024px)',
  });

  const teamTypesFilter = useMemo(() => {
    if (bugoRole() === LowerRole.BugoAgencyBranchAdmin) {
      return null;
    } else {
      const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];
      const teamTypes = bugoBrand?.bugoAgency?.teamTypes ?? [];
      const result: CheckBoxFilter = {};
      teamTypes.forEach((teamType) => (result[teamType] = true));

      return result;
    }
  }, [bugoRole, userProfile?.bugoAgencyAdminDetail?.bugoBrands]);

  const table = useMemo(() => {
    if (shopOrderListState.status === 'success' && shopOrderListState.data) {
      return (
        <AgencyAdminShopOrderListTable
          shopOrderList={shopOrderListState.data}
          teamTypesFilter={teamTypesFilter}
        />
      );
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [shopOrderListState, teamTypesFilter]);

  if (isMobile) {
    return (
      <div>
        {/* Search Bar */}
        <div className="p-4">
          <SearchBar
            searchTextAtomId={SEARCH_TEXT_ID}
            placeholder="소속/지도사성함/지역/회원번호로 검색"
          />
        </div>
        {shopOrderListState.status === 'success' && shopOrderListState.data ? (
          <AgencyAdminShopOrderListTableMobile
            orderList={shopOrderListState.data}
            teamTypesFilter={teamTypesFilter}
            searchValue={searchText}
          />
        ) : (
          <div className="center-box h-screen-15">
            <Loading />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex">
        {/* Sidebar */}
        <div className="flex flex-col">
          <StatPageSideBar />
        </div>
        <div className="mx-auto max-w-screen-2xl flex-1">
          {/* Table */}
          <div className="py-8 px-10">
            <div className="flex justify-between">
              <div>
                <p className="text-20 font-bold">화환 내역 조회</p>
                <p className="font-sm pt-2 text-gray-500">
                  조건을 설정하여 조회할 수 있습니다. (최대 15개월 이내의 데이터를
                  조회가능)
                </p>
              </div>
              <ReloadButton />
            </div>
            <div className="pt-4">{table}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default AgencyAdminFlowerStatPage;
