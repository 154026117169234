import { atomFamily } from 'recoil';

export const adminBugoBrandMultiAgencyModalOpenId = (bugoBrandId: string) =>
  `AdminBugoBrandMultiAgencyEdit-modal-${bugoBrandId}`;

export const adminBugoBrandMultiAgencyDeleteModalOpenId = (bugoBrandId: string) =>
  `AdminBugoBrandMultiAgencyDelete-modal-${bugoBrandId}`;

export const adminBugoBrandMultiAgencyIndexAtom = atomFamily<number | undefined, string>({
  key: 'adminBugoBrandMultiAgencyIndexAtom',
  default: undefined,
});

export const adminBugoBrandMultiAgencyDeleteIndexAtom = atomFamily<
  number | undefined,
  string
>({
  key: 'adminBugoBrandMultiAgencyDeleteIndexAtom',
  default: undefined,
});
