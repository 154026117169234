import { FeventDate } from '@shared/types';

import { FeventStatus } from './fevent.interface';
export const getFeventStatus = (status: FeventStatus, enteranceDate?: FeventDate) => {
  const now = new Date();
  if (
    status === FeventStatus.Doing &&
    enteranceDate &&
    enteranceDate.timeDefined &&
    enteranceDate.date &&
    now.getTime() < enteranceDate.date.getTime()
  ) {
    return FeventStatus.Todo;
  } else {
    return status;
  }
};
