//* 로그인, 회원가입 등 계정 관련 Page에서 사용되는 기본적인 Input
import React, { InputHTMLAttributes } from 'react';

import { FieldValues, UseFormRegister } from 'react-hook-form';

// tailwindcss classname을 inline으로 사용해 JSX 부분의 가독성이 떨어지는것 방지
const classNames = {
  signupInput:
    'relative mt-1 h-11 w-full rounded-none border border-gray-300 py-3 pl-4 text-15 font-bold theme-bg-1 focus:theme-border-main sm:text-sm',
};

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  registerName: string;
  text?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  inputClassName?: string;
  register: UseFormRegister<FieldValues>;
}
const AccountInput: React.FC<InputProps> = ({
  registerName,
  text,
  type,
  placeholder = '',
  className = '',
  inputClassName,
  register,
  ...props
}: InputProps) => {
  // tailwindcss classname
  const { signupInput } = classNames;

  return (
    <div className={`mt-2 text-15 ${className}`}>
      <input
        type={type}
        className={`${signupInput} ${inputClassName} rounded-lg bg-[#F9FAFB]`}
        placeholder={placeholder}
        autoComplete="off"
        autoCapitalize="off"
        {...props}
        {...register(registerName)}
      />
    </div>
  );
};

export { AccountInput };
