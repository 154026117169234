import { FC } from 'react';

import { phoneNumberStringFormator } from '@shared/utils/phoneNumberStringFormator';
import { UseFormRegister } from 'react-hook-form';

import { TextField, TextFieldProps } from './TextField';

interface IProps extends TextFieldProps {
  value: string;
  register: UseFormRegister<any>;
  name: string;
  className?: string;
  required?: boolean;
}

const TelField: FC<IProps> = ({
  value,
  register,
  name,
  className,
  required,
  placeholder = '휴대전화번호',
  ...props
}: IProps) => {
  return (
    <TextField
      type="tel"
      className={className}
      placeholder={placeholder}
      value={phoneNumberStringFormator(value)}
      maxLength={13}
      minLength={12}
      {...register(name, {
        required,
        maxLength: 11,
        minLength: 10,
        setValueAs: (value) => value.replace(/[^0-9]/gi, ''),
      })}
      {...props}
    />
  );
};

export default TelField;
