import React, { FC } from 'react';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { memberNameFunc, mobileVacantMemberSpace } from '@shared/utils/memberUtils';

interface Props {
  bugo: BugoData;
}

export const ObituaryMemberGrid: FC<Props> = function ObituaryMemberGrid({
  bugo,
}: Props) {
  const memberOrderList = bugo.fevent.memberOrderList;

  /**
   * 1. 존재하는 relationship set 화
   * 2. relationship 순으로 member 묶기
   * 3. 배치
   */
  const relationTypeList = Array.from(
    new Set(
      memberOrderList.map((item) => {
        return item.relationType;
      }),
    ),
  );

  const memberListByRelationType = relationTypeList.map((relation) => {
    return {
      relationType: relation,
      memberList: memberOrderList.filter((item) => {
        return item.relationType === relation;
      }),
    };
  });

  return (
    <>
      {memberListByRelationType.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <p className="font-bold text-gray-500">{item.relationType}</p>
            <p className="whitespace-pre-wrap break-keep font-normal">
              {item.memberList?.reduce((accu, prev, index) => {
                if (index === 0) {
                  return accu + memberNameFunc(prev?.shortName);
                } else {
                  return (
                    accu +
                    (accu.endsWith(mobileVacantMemberSpace) ? '' : ', ') +
                    memberNameFunc(prev?.shortName)
                  );
                }
              }, '')}
            </p>
          </React.Fragment>
        );
      })}
    </>
  );
};
