import React, { FC } from 'react';

import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { formatFeventDateString } from '@shared/utils/formatDate';

//*관리자용 부고정보 페이지 내의 DeceasedInfo 정보
interface Props {
  fevent: FeventOne;
}

export const DeceasedInfo: FC<Props> = function DeceasedInfo({ fevent }: Props) {
  const deceasedInfo = fevent.deceasedInfo;

  const memberOrderList = fevent.memberOrderList;

  const relationTypeList = Array.from(
    new Set(
      memberOrderList.map((item) => {
        return item.relationType;
      }),
    ),
  );

  const memberListByRelationType = relationTypeList.map((relation) => {
    return {
      relationType: relation,
      memberList: memberOrderList.filter((item) => {
        return item.relationType === relation;
      }),
    };
  });

  return (
    <>
      <p className="font-bold text-gray-500">고인성함</p>
      <p>{deceasedInfo.name}</p>
      <p className="font-bold text-gray-500">성별</p>
      <p>{deceasedInfo.sex}</p>
      <p className="font-bold text-gray-500">나이</p>
      <p>{deceasedInfo.age}</p>

      {deceasedInfo.enterance?.date && (
        <>
          <p className="font-bold text-gray-500">입실</p>
          <p className="font-normal">{formatFeventDateString(deceasedInfo.enterance)}</p>
        </>
      )}
      {deceasedInfo.coffinIn?.date && (
        <>
          <p className="font-bold text-gray-500">입관</p>
          <p className="font-normal">{formatFeventDateString(deceasedInfo.coffinIn)}</p>
        </>
      )}
      {deceasedInfo.coffinOut?.date && (
        <>
          <p className="font-bold text-gray-500">발인</p>
          <p className="font-normal">{formatFeventDateString(deceasedInfo.coffinOut)}</p>
        </>
      )}
      {deceasedInfo.cemetery && (
        <>
          <p className="font-bold text-gray-500">장지</p>
          <p className="font-normal">{deceasedInfo.cemetery}</p>
        </>
      )}

      <p className="font-bold text-gray-500">빈소</p>
      <p className="font-normal">
        {fevent.funeralHomeInfoEmbed?.name ?? fevent.funeralHomeInfo?.name}
      </p>

      <p className="font-bold text-gray-500">호실</p>
      <p className="font-normal">{fevent.roomInfoEmbed?.name ?? fevent.roomInfo?.name}</p>
      <div className="py-0.5"></div>
      <div className="py-0.5"></div>
      {memberListByRelationType.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <p className="font-bold text-gray-500">{item.relationType}</p>
            <p className="font-normal">
              {item.memberList?.reduce((accu, prev, index) => {
                if (index === 0) {
                  return (
                    accu +
                    prev?.shortName +
                    '[ ' +
                    (prev.phoneNumber ? prev.phoneNumber : '번호등록 X') +
                    ' | ' +
                    (prev.bugo?.lastSentPhoneNumber ? '발송 O' : '발송 X') +
                    ' ]'
                  );
                } else {
                  return accu + ', ' + prev?.shortName;
                }
              }, '')}
            </p>
          </React.Fragment>
        );
      })}
    </>
  );
};
