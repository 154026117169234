import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { deleteCourtesyAsset } from '@shared/api/courtesyAsset/courtesyAsset.controller';
import { courtesyAssetListByBugoBrandHookUrl } from '@shared/api/courtesyAsset/courtesyAsset.hook';
import { CourtesyAssetData } from '@shared/api/courtesyAsset/courtesyAsset.interface';
import DismissModal from '@shared/components/DismissModal';
import { Icon } from '@shared/components/icons';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { Image } from 'antd';
import { useQueryClient } from 'react-query';

interface Props {
  courtesyAsset: CourtesyAssetData;
}

export const CourtesyAssetCard: FC<Props> = function CourtesyAssetCard({
  courtesyAsset,
}: Props) {
  const { X } = Icon();
  const { bugoRole, bugoBrandId } = useAuth();
  const deleteOpenId = `courtesyAssetCard-delete-${courtesyAsset._id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(deleteOpenId));

  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const deleteClick = async () => {
    try {
      await deleteCourtesyAsset(courtesyAsset._id, bugoRole());
      queryClient.invalidateQueries(
        courtesyAssetListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
      );
      notificateSuccess(notifiacationInstance, '삭제되었습니다');
    } catch (error) {
      console.error(error);
      notificateError(notifiacationInstance, '에러');
    }
  };

  return (
    <div className="relative">
      <div
        className="absolute right-0 top-0 z-10 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <X className="wh-8" />
      </div>
      <div>
        <Image src={fileBucketUrl(courtesyAsset.backgroundImage.url, 'w1080')}></Image>
      </div>
      <div className="center-box">
        <p className="font-medium">{courtesyAsset.name}</p>
      </div>
      <DismissModal
        openId={deleteOpenId}
        onClick={deleteClick}
        buttonTitle={'삭제'}
        title={'답조글 배경 삭제'}
      ></DismissModal>
    </div>
  );
};
