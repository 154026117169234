import { atom, atomFamily } from 'recoil';

import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';

export const feventIdInMemberListEditorAtom = atom<string | undefined>({
  key: 'feventIdInMemberListEditorAtom',
  default: undefined,
});

export const cashAccountListByFeventAtom = atom<CashAccount[]>({
  key: 'cashAccountListByFeventAtom',
  default: [],
});

export const relationshipAtomFamily = atomFamily<string, string>({
  key: 'relationshipAtom',
  default: '',
});
