import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Enquiry } from '@shared/api/enquiry/enquiry.interface';
import { Image } from '@shared/api/media/media.interface';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useId } from 'react-id-generator';

import EnquiryDetailModal from './EnquiryDetailModal';

interface IProps {
  enquiry: Enquiry<User, Image> | null;
}

const EnquiryDetailModalOpener: FC<IProps> = ({ enquiry }: IProps) => {
  const [id] = useId(1, 'enquiryDetailModalOpener');
  const openId = `modal-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const { ChevronRight } = Icon();

  const onClick = () => {
    if (enquiry) setOpen(true);
  };

  return (
    <>
      <Button className="rounded-sm shadow-sm" onClick={onClick}>
        <ChevronRight />
      </Button>
      {enquiry && <EnquiryDetailModal openId={openId} enquiry={enquiry} />}
    </>
  );
};

export default EnquiryDetailModalOpener;
