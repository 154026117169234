import { FC, useMemo } from 'react';

import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import Modal from '@shared/components/Modal';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

interface Props {
  openId: string;
  shopOrderList: ShopOrderAgencyAdmin[];
}

type AccountData = {
  id: string;
  index: number;
  shopOrder: ShopOrderAgencyAdmin;
  requestedAt: Date;
  address: string;
  funeralHomeInfo: string;
  deceasedName: string;
  coffinOutDate: string;
  senderPhrase: string;
  itemName: string;
  priceRetail: number;
  priceRebateAgency: number;
  priceRebateWorker: number;
  teamName: string;
  workerName: string;
  workerPhoneNumber: string;
};

export const AgencyAdminAccountDetailModal: FC<Props> =
  function AgencyAdminAccountDetailModal({ openId, shopOrderList }: Props) {
    const { userBugoBrand, bugoRole, userProfile } = useAuth();
    const bugoBrand = userBugoBrand;

    const columns: () => TableColumnsType<AccountData> = () => {
      if (bugoBrand?.bugoAgency?.shareRebateWithWorker) {
        return [
          {
            title: <p className="break-keep">순번</p>,
            dataIndex: 'index',
            key: 'index',
            align: 'center',
          },
          {
            title: <p className="break-keep">결제일</p>,
            dataIndex: 'requestedAt',
            key: 'requestedAt',
            align: 'center',
            render: (requestedAt: Date) => {
              return (
                <p>
                  {formatDate(requestedAt, {
                    dateSeparater: '/',
                    contains: {
                      year: false,
                    },
                  })}
                </p>
              );
            },
          },
          {
            title: '주소',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            render: (address: string) => {
              return <p className="break-keep">{address.split('(')[0]}</p>;
            },
          },
          {
            title: '장례식장',
            dataIndex: 'funeralHomeInfo',
            key: 'funeralHomeInfo',
            align: 'center',
            render: (funeralHomeInfo: string) => {
              return <p className="break-keep">{funeralHomeInfo}</p>;
            },
          },
          {
            title: '고인명',
            dataIndex: 'deceasedName',
            key: 'deceasedName',
            align: 'center',
            render: (deceasedName: string) => {
              return <p className="break-keep">{deceasedName}</p>;
            },
          },
          {
            title: '발인일',
            dataIndex: 'coffinOutDate',
            key: 'coffinOutDate',
            align: 'center',
            render: (coffinOutDate: string) => {
              return <p className="break-keep">{coffinOutDate}</p>;
            },
          },
          {
            title: '문구',
            dataIndex: 'senderPhrase',
            key: 'senderPhrase',
            align: 'center',
            render: (senderPhrase: string) => {
              return <p className="break-keep">{senderPhrase}</p>;
            },
          },
          {
            title: '상품명',
            dataIndex: 'itemName',
            key: 'itemName',
            align: 'center',
            render: (itemName: string) => {
              return <p className="break-keep">{itemName}</p>;
            },
          },
          {
            title: '소비자가',
            dataIndex: 'priceRetail',
            key: 'priceRetail',
            align: 'center',
            render: (priceRetail: number) => {
              return <p className="break-keep">{priceRetail.toLocaleString()}원</p>;
            },
          },
          {
            title: '상조',
            dataIndex: 'priceRebateAgency',
            key: 'priceRebateAgency',
            align: 'center',
            render: (priceRebateAgency: number) => {
              return <p className="break-keep">{priceRebateAgency.toLocaleString()}원</p>;
            },
          },
          {
            title: <p className="break-keep">지도사</p>,
            dataIndex: 'priceRebateWorker',
            key: 'priceRebateWorker',
            align: 'center',
            render: (priceRebateWorker: string) => {
              return (
                <p className="break-keep">
                  {(priceRebateWorker ?? 0).toLocaleString()}원
                </p>
              );
            },
          },

          {
            title: <p className="break-keep">소속</p>,
            dataIndex: 'teamName',
            key: 'teamName',
            align: 'center',
            render: (teamName: string) => {
              return <p className="break-keep">{teamName}</p>;
            },
          },
          {
            title: <p className="break-keep">지도사</p>,
            dataIndex: 'workerName',
            key: 'workerName',
            align: 'center',
            render: (workerName: string) => {
              return <p>{workerName}</p>;
            },
          },
          {
            title: <p className="break-keep">휴대번호</p>,
            dataIndex: 'workerPhoneNumber',
            key: 'workerPhoneNumber',
            align: 'center',
            render: (workerPhoneNumber: string) => {
              return <p>{workerPhoneNumber}</p>;
            },
          },
        ];
      } else {
        return [
          {
            title: <p className="break-keep">순번</p>,
            dataIndex: 'index',
            key: 'index',
            align: 'center',
          },
          {
            title: <p className="break-keep">결제일</p>,
            dataIndex: 'requestedAt',
            key: 'requestedAt',
            align: 'center',
            render: (requestedAt: Date) => {
              return (
                <p>
                  {formatDate(requestedAt, {
                    dateSeparater: '/',
                    contains: {
                      year: false,
                    },
                  })}
                </p>
              );
            },
          },
          {
            title: '주소',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            render: (address: string) => {
              return <p className="break-keep">{address.split('(')[0]}</p>;
            },
          },
          {
            title: '장례식장',
            dataIndex: 'funeralHomeInfo',
            key: 'funeralHomeInfo',
            align: 'center',
            render: (funeralHomeInfo: string) => {
              return <p className="break-keep">{funeralHomeInfo}</p>;
            },
          },
          {
            title: '고인명',
            dataIndex: 'deceasedName',
            key: 'deceasedName',
            align: 'center',
            render: (deceasedName: string) => {
              return <p className="break-keep">{deceasedName}</p>;
            },
          },
          {
            title: '발인일',
            dataIndex: 'coffinOutDate',
            key: 'coffinOutDate',
            align: 'center',
            render: (coffinOutDate: string) => {
              return <p className="break-keep">{coffinOutDate}</p>;
            },
          },
          {
            title: '문구',
            dataIndex: 'senderPhrase',
            key: 'senderPhrase',
            align: 'center',
            render: (senderPhrase: string) => {
              return <p className="break-keep">{senderPhrase}</p>;
            },
          },
          {
            title: '상품명',
            dataIndex: 'itemName',
            key: 'itemName',
            align: 'center',
            render: (itemName: string) => {
              return <p className="break-keep">{itemName}</p>;
            },
          },
          {
            title: '소비자가',
            dataIndex: 'priceRetail',
            key: 'priceRetail',
            align: 'center',
            render: (priceRetail: number) => {
              return <p className="break-keep">{priceRetail.toLocaleString()}원</p>;
            },
          },
          {
            title: (
              <p className="break-keep">
                {bugoRole() === LowerRole.BugoAgencyBranchAdmin
                  ? userProfile?.bugoAgencyBranchAdminDetail?.teamName
                  : bugoBrand?.bugoAgency?.nickName}
              </p>
            ),
            dataIndex: 'priceRebateAgency',
            key: 'priceRebateAgency',
            align: 'center',
            render: (priceRebateAgency: number) => {
              return (
                <p className="break-keep">{priceRebateAgency?.toLocaleString()}원</p>
              );
            },
          },

          {
            title: <p className="break-keep">소속</p>,
            dataIndex: 'teamName',
            key: 'teamName',
            align: 'center',
            render: (teamName: string) => {
              return <p className="break-keep">{teamName}</p>;
            },
          },
          {
            title: <p className="break-keep">지도사</p>,
            dataIndex: 'workerName',
            key: 'workerName',
            align: 'center',
            render: (workerName: string) => {
              return <p>{workerName}</p>;
            },
          },
          {
            title: <p className="break-keep">휴대번호</p>,
            dataIndex: 'workerPhoneNumber',
            key: 'workerPhoneNumber',
            align: 'center',
            render: (workerPhoneNumber: string) => {
              return <p>{workerPhoneNumber}</p>;
            },
          },
        ];
      }
    };

    const tableData: AccountData[] = useMemo(() => {
      return _.map(shopOrderList, (order, index) => {
        return {
          id: order._id,
          index: shopOrderList.length - index,
          shopOrder: order,
          requestedAt: order.paymentDetail.requestedAt as Date,
          address: order.funeralHomeInfo.address,
          funeralHomeInfo: order.funeralHomeInfo.name,
          deceasedName: order.fevent.deceasedInfo.name,
          coffinOutDate: formatDate(order.fevent.deceasedInfo.coffinOut.date, {
            dateSeparater: '/',
            contains: {
              year: false,
            },
          }),
          senderPhrase: order.bugoDetail.senderPhrase,
          itemName: order.orderDetail.shopItemEmbed.name,
          priceRetail: order.orderDetail.shopItemEmbed.priceRetail,
          priceRebateAgency:
            order.orderDetail.shopItemEmbed.priceRebateAgency ??
            order.orderDetail.shopItemEmbed.priceRebate,
          priceRebateWorker: order.orderDetail.shopItemEmbed.priceRebateWorker,
          teamName: order.fevent?.user.bugoAgencyWorkerDetail?.teamName ?? '',
          workerName: order.fevent?.user.info.name ?? '',
          workerPhoneNumber: order.fevent?.user.info.phoneNumber ?? '',
        };
      });
    }, [shopOrderList]);

    //제출용
    const csvDataForSubmit = tableData.map((item) => {
      if (bugoBrand?.bugoAgency?.shareRebateWithWorker) {
        return {
          순번: item.index,
          접수일자: formatDate(item.requestedAt, {
            dateSeparater: '/',
            contains: {
              year: false,
            },
          }),
          주소: item.address.split('(')[0],
          장례식장: item.funeralHomeInfo,
          고인명: item.deceasedName,
          발인일: item.coffinOutDate,
          문구: item.senderPhrase,
          상품명: item.itemName,
          [(bugoRole() === LowerRole.BugoAgencyBranchAdmin
            ? userProfile?.bugoAgencyBranchAdminDetail?.teamName
            : bugoBrand?.bugoAgency?.nickName) ?? '상조회사']:
            item.priceRebateAgency.toLocaleString(),
          지도사: item.priceRebateWorker?.toLocaleString(),
          소속: item.teamName,
          지도사성함: item.workerName,
        };
      } else {
        return {
          순번: item.index,
          접수일자: formatDate(item.requestedAt, {
            dateSeparater: '/',
            contains: {
              year: false,
            },
          }),
          주소: item.address.split('(')[0],
          장례식장: item.funeralHomeInfo,
          고인명: item.deceasedName,
          발인일: item.coffinOutDate,
          문구: item.senderPhrase,
          상품명: item.itemName,
          [(bugoRole() === LowerRole.BugoAgencyBranchAdmin
            ? userProfile?.bugoAgencyBranchAdminDetail?.teamName
            : bugoBrand?.bugoAgency?.nickName) ?? '상조회사']:
            item.priceRebateAgency.toLocaleString(),
          소속: item.teamName,
          지도사성함: item.workerName,
        };
      }
    });

    return (
      <Modal openId={openId} className="max-w-[80%] sm:max-w-[80%]">
        <div className="space-y-4">
          <div className="flex items-center justify-between pt-2 pr-2">
            <h2 className="bugo-h1 text-20">상세 세부 내역</h2>
            <div className="center-box gap-2">
              <CSVLink
                filename={'Expense_Table.csv'}
                data={_.flatMapDeep([csvDataForSubmit])}
                className="center-box rounded-md px-4 py-2 font-bold theme-bg-13"
              >
                <p className="theme-text-4">엑셀 다운로드</p>
              </CSVLink>
            </div>
          </div>
          <div>
            <Table
              className="w-full"
              columns={columns()}
              dataSource={_.flatMapDeep([tableData])}
              pagination={{
                position: ['bottomCenter'],
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: [10, 15, 20, 50, 100],
              }}
              rowKey={'id'}
              bordered
              size={'small'}
            />
          </div>
        </div>
      </Modal>
    );
  };
