import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useShopOrderListForWorkerHook } from '@shared/api/shopOrder/shopOrder.hook';
import { shopOrderListForWorkerSelector } from '@shared/api/shopOrder/shopOrder.selector';
import { Loading } from '@shared/components/Loading';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate } from 'react-router-dom';

import { AgencyWorkerAnnualAccountedTable } from '../containers/AgencyWorkerAccount/AgencyWorkerAnnualAccountTable';
import { NeedAccountTable } from '../containers/AgencyWorkerAccount/AgencyWorkerNeedAccountTable';
import { useTitleHook } from '../hooks/useTitleHook';

const WorkerAccouintPointPage: FC = function WorkerAccouintPointPage() {
  useTitleHook('정산금 내역');

  const { userProfile, userBugoBrand } = useAuth();

  useShopOrderListForWorkerHook();

  const shopOrderListState = useRecoilValue(shopOrderListForWorkerSelector);

  const accountTable = useMemo(() => {
    if (shopOrderListState.status === 'success' && shopOrderListState.data) {
      const shopOrderList = shopOrderListState.data;

      const needShopOrderList = shopOrderList.filter((order) => {
        return !order.paymentDetail.isRebated;
      });

      return (
        <div>
          <NeedAccountTable shopOrderList={needShopOrderList} />
        </div>
      );
    }
  }, [shopOrderListState.data, shopOrderListState.status]);

  const accountedTable = useMemo(() => {
    if (shopOrderListState.status === 'success' && shopOrderListState.data) {
      const shopOrderList = shopOrderListState.data;

      return (
        <div>
          <AgencyWorkerAnnualAccountedTable shopOrderList={shopOrderList} />
        </div>
      );
    }
  }, [shopOrderListState.data, shopOrderListState.status]);

  if (userBugoBrand) {
    if (userBugoBrand?.bugoAgency?.shareRebateWithWorker) {
      return (
        <div className="space-y-3 py-3 text-sm">
          {/* 환영합니다~~~ */}
          <div className="center-box py-2 theme-bg-1">
            <p className="center-box text-base font-medium">
              <strong className="theme-text-main">{userProfile?.info.name}</strong>님
              환영합니다
            </p>
          </div>
          {/* 정산 예정내역 */}
          <div className="py-4 px-4 theme-bg-1">{accountTable}</div>
          {/* 연간 정산 내역 */}
          <div className="py-4 px-4 theme-bg-1">{accountedTable}</div>
        </div>
      );
    } else {
      return <Navigate to="/"></Navigate>;
    }
  } else {
    return (
      <div className="center-box h-screen-15">
        <Loading></Loading>
      </div>
    );
  }
};

export default WorkerAccouintPointPage;
