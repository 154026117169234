import { FC, HTMLAttributes } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BottomPopup } from '@shared/components/BottomPopup';
import { Icon } from '@shared/components/icons';
import DatePickerModal from '@shared/containers/DatePicker/DatePickerModal';
import { feventTimeAtomFamily } from '@shared/containers/TimePicker/time.atom';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { FeventDate } from '@shared/types';
import { formatDate } from '@shared/utils/formatDate';
import { UseFormSetValue } from 'react-hook-form';
import { useId } from 'react-id-generator';

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string;
  fDate: FeventDate; // useWatch로 변화되는거 지켜보는 값, 이 값은 FeventDatePicker와 FeventTimePicker에서 변경 됨에 따라 같이 변경됨
  setValue: UseFormSetValue<any>;
  pickerId: string;
}

//* FeventDatePicker 날짜 선택 register의 해당 name의 맞는 setValue를 처리함
export const FeventDatePicker: FC<Props> = ({
  fDate,
  name,
  setValue,
  placeholder,
  pickerId,
  className = '',
  ...props
}: Props) => {
  const { CalendarPlaceHolder, Calendar, X } = Icon();
  const [id] = useId(1, 'datepicker');
  const openId = `datepicker-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  //timePicker가 갖고 있는 time값
  const time = useRecoilValue(feventTimeAtomFamily(pickerId));

  //* date 초기화
  const onClear = () => {
    setValue(name, {
      date: null,
      timeDefined: fDate.timeDefined,
    });
  };

  //* 기존 timeDefined에서 정의된 시간 값이 있으면 보존하도록함
  const onDatePickerModalValueChange = (date: Date | null) => {
    if (fDate.timeDefined && time && date) {
      const tempDate = new Date(date);
      tempDate.setHours(time.getHours());
      tempDate.setMinutes(time.getMinutes());
      setValue(name, { date: tempDate, timeDefined: fDate.timeDefined });
    } else {
      setValue(name, { date, timeDefined: fDate.timeDefined });
    }
  };

  return (
    <>
      <div
        className={`date-picker flex cursor-pointer justify-between theme-bg-1 theme-border-1 ${className}`}
        onClick={() => {
          setOpen(true);
        }}
        {...props}
      >
        <div className="flex flex-1 items-center">
          {!fDate?.date ? (
            <CalendarPlaceHolder className="stroke-none" />
          ) : (
            <Calendar className="stroke-none" />
          )}
          <p className={`${!fDate?.date ? 'theme-text-6' : 'theme-text-1'}`}>
            {!fDate?.date
              ? placeholder
              : formatDate(fDate?.date, {
                  dateSeparater: '.',
                  contains: {
                    day: true,
                  },
                })}
          </p>
        </div>
        {fDate?.date && (
          <X
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
            className="hidden stroke-slate-400 sm:block"
          />
        )}
      </div>
      <BottomPopup openId={openId}>
        <div className="w-screen bg-transparent">
          <DatePickerModal
            value={fDate?.date}
            onDateChange={onDatePickerModalValueChange}
            openId={openId}
            placeholder={placeholder}
          />
        </div>
      </BottomPopup>
    </>
  );
};
