import { FC, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  MemberCourtesyIFormInput,
  MemberCourtesyIFormInputs,
} from '@service/bugo/interfaces/memberCourtesySend.interface';
import { sendCourtesy } from '@shared/api/bugo/bugo.controller';
import { feventHookUrl } from '@shared/api/fevent/fevent.hook';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { BottomPopup } from '@shared/components/BottomPopup';
import { Button } from '@shared/components/Button';
import { LoadingModal } from '@shared/components/LoadingModal';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { formatDate } from '@shared/utils/formatDate';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { MemberCourtesyCard } from './MemberCourtesyCard';

interface Props {
  fevent: FeventOne;
}

export const FeventCourtesySender: FC<Props> = function FeventCourtesySender({ fevent }) {
  const { bugoRole } = useAuth();
  const { register, handleSubmit, setValue, control } =
    useForm<MemberCourtesyIFormInputs>();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { isLoading: sendingCourtesy, mutateAsync: mutateSendAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        notificateSuccess(notifiacationInstance, '답례글발송 성공');
        if (fevent) {
          queryClient.invalidateQueries(feventHookUrl(fevent._id, bugoRole()));
        }
      },
    },
  );
  const memberOrderList = fevent.memberOrderList;
  const memberHasPhoneNumberList = memberOrderList?.filter(
    (member) => member?.phoneNumber,
  );

  const previewOpenId = `sendCourtesy-preview-${fevent._id}`;
  const setPreviewModalOpen = useSetRecoilState(
    simpleOpenAtomFamily(`sendCourtesy-preview-${fevent._id}`),
  );

  // _id 및 초기값 세팅
  useEffect(() => {
    memberHasPhoneNumberList.map((member, index) => {
      setValue(`memberCourtesy.${index}._id`, member._id);
      setValue(`memberCourtesy.${index}.bugo`, member.bugo);
      setValue(`memberCourtesy.${index}.bugoId`, member.bugo?._id as string);
      setValue(`memberCourtesy.${index}.phoneNumber`, member.phoneNumber);
    });
  }, [memberHasPhoneNumberList, setValue]);

  //* 전체 답례글 발송 함수
  const sendCourtesyFunc = async (
    memberCourtesyIFormInputs: MemberCourtesyIFormInput[],
  ) => {
    /*답례글 발송*/
    const bugoIdList = memberCourtesyIFormInputs.map((memberCourtesyInput) => {
      return memberCourtesyInput.bugoId;
    });
    const result = await sendCourtesy(bugoIdList, bugoRole());
    console.log('답례글 전송 결과', result);
  };

  const onSubmit: SubmitHandler<MemberCourtesyIFormInputs> = async (data) => {
    await mutateSendAsync(sendCourtesyFunc(data.memberCourtesy));
  };

  const courtesySendScheduleInfoString = () => {
    if (
      fevent.deceasedInfo.coffinOut?.date &&
      fevent.deceasedInfo.coffinOut?.timeDefined
    ) {
      const courtesyScheduleDate = new Date(fevent.deceasedInfo.coffinOut.date);
      courtesyScheduleDate.setDate(courtesyScheduleDate.getDate() + 1);
      courtesyScheduleDate.setHours(9);
      courtesyScheduleDate.setMinutes(0);

      return (
        <p className="text-12 font-medium">
          <span> 자동발송시각: </span>
          <span className="font-bold theme-text-main">
            {`${formatDate(courtesyScheduleDate, {
              dateSeparater: '.',
              contains: {
                hours: true,
                minutes: true,
                amPm: true,
              },
            })} 
            `}
          </span>
          [발인 다음날 오전 9시]
        </p>
      );
    }
  };

  return (
    <div>
      <div className="mb-3 space-y-2 text-sm font-bold">
        <p>상주님께 답례글 링크를 전송합니다</p>
        <div>
          <p className="text-12 font-medium">
            상주님이 지인분들께 답례글을 보내드리실 수 있도록,
          </p>
          <p className="text-12 font-medium">답례글 링크를 보내드립니다.</p>
        </div>
        {courtesySendScheduleInfoString()}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-3">
          {memberHasPhoneNumberList.map((member, index) => {
            return (
              <MemberCourtesyCard
                key={member._id}
                member={member}
                register={register}
                index={index}
                control={control}
              />
            );
          })}
        </div>
        <div className="mt-8 space-y-4">
          {/* <Button
            type="button"
            className="button-rectangle text-white theme-bg-14"
            onClick={() => {
              setPreviewModalOpen(true);
            }}
          >
            답례글 설정하기
          </Button> */}

          <Button
            type="submit"
            className="button-rectangle text-white theme-bg-main disabled:theme-text-3 disabled:theme-bg-10"
          >
            상주님께 답례글전송
          </Button>
          <div className="grid grid-cols-2 gap-2">
            <Button
              type="button"
              className="button-rectangle text-white theme-bg-12"
              onClick={() => {
                setPreviewModalOpen(true);
              }}
            >
              답례글 미리보기
            </Button>
            <div>
              <Link to="/">
                <Button className="button-rectangle text-white theme-bg-12">
                  완료(홈으로)
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </form>
      <BottomPopup openId={previewOpenId}>
        <div className="w-screen rounded-t-2xl p-6 theme-text-1 theme-bg-1">
          {/* Title */}
          <h4 className="text-center text-sm font-bold">상주님 선택</h4>
          {/* Item List */}
          <ul className="max-h-64 overflow-y-auto pt-3 pb-4">
            {memberHasPhoneNumberList?.map((member) => (
              <li
                key={`select-${member?._id}`}
                onClick={() => {
                  navigate(`/member/${member._id}/courtesy/preview`);
                }}
                className={`cursor-pointer border-b py-3 text-center leading-5 theme-border-1`}
              >
                {member?.fullName}
              </li>
            ))}
          </ul>
        </div>
      </BottomPopup>
      <LoadingModal open={sendingCourtesy} title="답례글 전송중"></LoadingModal>
    </div>
  );
};
