import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilState } from 'recoil';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { CourtesyAssetData } from '@shared/api/courtesyAsset/courtesyAsset.interface';
import ResizableBox from '@shared/components/ResizableBox';
import { Icon } from '@shared/components/icons';
import { fileBucketUrl } from '@shared/utils/fileUtils';

import { courtesyAssetAtom } from './courtesy.atom';

interface Props {
  courtesyAssetList: CourtesyAssetData[];
  bugo: BugoData;
}

const DURATION = 0.3;

//* 해당 멤버가 답례글에서 이미지 어셋 선택기
export const MemberCourtesyAssetSelector: FC<Props> =
  function MemberCourtesyAssetSelector({ courtesyAssetList, bugo }: Props) {
    const { ChevronDown, ChevronUp, BigCheck } = Icon();
    const [fold, setFold] = useState(false);
    //* courtesyAsset 해당 멤버의 답례글 선택기에서 갖고 있는 값
    const [courtesyAsset, setCourtesyAsset] = useRecoilState(courtesyAssetAtom);

    const defaultCourtesyAssetId = bugo.fevent.courtesyAsset;

    const defaultCourtesyAsset = courtesyAssetList.find((item) => {
      return item._id === defaultCourtesyAssetId;
    });

    // !초기값 courtesyAsset -> defaultCourtesyAsset(fevent) -> 0번째
    useEffect(() => {
      if (!courtesyAsset) {
        const defaultAsset = defaultCourtesyAsset ?? courtesyAssetList[0];
        if (defaultAsset) setCourtesyAsset(defaultAsset);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const courtesyAssetImageList = useMemo(() => {
      return courtesyAssetList.map((item) => {
        return (
          <div
            key={item._id}
            className={`space-y-2`}
            onClick={() => {
              setCourtesyAsset(item);
            }}
          >
            <div className="relative">
              <img
                src={fileBucketUrl(item.backgroundImage.url, 'w400')}
                className={`${courtesyAsset?._id === item._id ? 'opacity-40' : ''}`}
              />
              {courtesyAsset?._id === item._id && (
                <div className="absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2">
                  <div>
                    <BigCheck />
                    <p className="font-medium">선택됨</p>
                  </div>
                </div>
              )}
            </div>

            <p className="text-center text-xs font-bold">{item.name}</p>
          </div>
        );
      });
    }, [BigCheck, courtesyAsset?._id, courtesyAssetList, setCourtesyAsset]);

    return (
      <div className="space-y-4 bg-white pt-4">
        <div
          className={`flex items-center justify-between px-4 ${
            fold ? 'cursor-pointer' : ''
          }`}
          onClick={() => {
            if (fold) {
              setFold(!fold);
            }
          }}
        >
          <h1 className="bugo-h1 ">답례 이미지 선택</h1>
          <div className="flex items-center">
            <div>
              {courtesyAsset && (
                <p className="button-fold rounded-lg font-medium shadow-sm">
                  {courtesyAsset?.name}
                </p>
              )}
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setFold(!fold);
              }}
            >
              {fold ? <ChevronDown /> : <ChevronUp />}
            </div>
          </div>
        </div>
        <ResizableBox duration={DURATION}>
          {!fold && (
            <div className="grid grid-cols-4 gap-2 p-2">{courtesyAssetImageList}</div>
          )}
        </ResizableBox>
      </div>
    );
  };
