import { FC, useState } from 'react';

import { Enquiry, EnquiryStatus } from '@shared/api/enquiry/enquiry.interface';
import { Image } from '@shared/api/media/media.interface';
import ResizableBox from '@shared/components/ResizableBox';
import { Icon } from '@shared/components/icons';
import { formatDate } from '@shared/utils/formatDate';

const DURATION = 0.3;

const tagNames = {
  [EnquiryStatus.Todo]: '접수',
  [EnquiryStatus.Doing]: '접수',
  [EnquiryStatus.Done]: '답변완료',
};

interface IProps {
  enquiry: Enquiry | Enquiry<string, Image>;
}

const EnquiryCollapse: FC<IProps> = ({ enquiry }: IProps) => {
  const [isFold, setIsFold] = useState<boolean>(true);

  const { ChevronDown } = Icon();

  const onClick = () => setIsFold((curr) => !curr);

  return (
    <ResizableBox duration={DURATION}>
      <div
        onClick={onClick}
        className="flex cursor-pointer items-center justify-between p-2"
      >
        <div className="space-y-2">
          <div className="flex items-center space-x-3 text-xs">
            {/* Tag */}
            <div className="rounded-full px-2 py-1 theme-bg-5">
              {tagNames[enquiry.status]}
            </div>
            {/* 문의한 날짜 */}
            <div>
              {formatDate(enquiry.createdAt, {
                dateSeparater: '.',
                contains: {
                  hours: true,
                  minutes: true,
                },
              })}
            </div>
          </div>
          <div>
            <h5 className="px-2">{enquiry.title}</h5>
          </div>
        </div>
        <div className={`transition-all duration-300 ${isFold ? '' : 'rotate-180'}`}>
          <ChevronDown />
        </div>
      </div>
      {!isFold && (
        <div className="px-4 text-xs">
          {/* 문의내용 */}
          <div className="border-y px-2 py-2 leading-6 theme-border-1">
            <p className="whitespace-pre-line break-keep">{enquiry.content}</p>
          </div>
          {/* 답변내용 */}
          <div className="space-y-2 px-2 py-2 leading-6">
            <h6 className="font-bold">답변내용</h6>
            <p className="whitespace-pre-line break-keep">{enquiry.answer}</p>
          </div>
        </div>
      )}
    </ResizableBox>
  );
};

export default EnquiryCollapse;
