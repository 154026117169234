import { ButtonHTMLAttributes, FC } from 'react';

import { Button } from '@shared/components/Button';
import { useNavigate } from 'react-router-dom';

type ButtonColorTheme = 'primary' | 'secondary' | 'default';

export interface ConfirmButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  colorTheme?: ButtonColorTheme;
  to?: string;
}

const BugoBasicButton: FC<ConfirmButtonProps> = function ({
  text,
  to,
  children,
  colorTheme = 'primary',
  className = '',
  onClick,
  ...props
}: ConfirmButtonProps) {
  const navigate = useNavigate();

  const buttonColorStyle = (colorTheme: ButtonColorTheme) => {
    switch (colorTheme) {
      case 'primary':
        return 'bg-myApricot border-myApricot';

      case 'secondary':
        return 'bg-gray-800 border-gray-800';
      case 'default':
        return 'bg-white border border-1 border-solid border-[#0186B5] text-[#0186B5]';
    }
  };

  return (
    <Button
      className={`button-rectangle h-10 border-solid text-14 text-white ${buttonColorStyle(
        colorTheme,
      )} ${className}`}
      onClick={to ? () => navigate(to) : onClick}
      {...props}
    >
      {text ?? children}
    </Button>
  );
};

export { BugoBasicButton };
