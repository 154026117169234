import { FC, useEffect, useMemo, useState } from 'react';

import { useTitleHook } from '@service/bugo/hooks/useTitleHook';
import {
  privacyPolicyText,
  purchasePolicyText,
  standardTermsText,
} from '@shared/utils/textData';
import _ from 'lodash';
import { Link } from 'react-router-dom';

// import BugoTabs from '../components/BugoTabs';

/**
 * 부고 FAQ 페이지
 * @returns Page
 */

const tabItems: readonly string[] = ['이용약관', '개인정보 처리방침', '배송 및 환불규정'];

const buttonColorGenerator = (isValid: boolean) => {
  if (isValid) return 'bg-gray-800';
  return 'bg-gray-300';
};

const TermsPage: FC = () => {
  useTitleHook('약관');
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [checkedIndex, setCheckedIndex] = useState<number[]>([]);

  useEffect(() => {
    setCheckedIndex((curr) => {
      const concatList = _.concat(curr, [selectedTab]);
      const result = _.sortBy(_.uniq(concatList));
      return result;
    });
  }, [selectedTab]);

  const isValid = useMemo(() => {
    return _.isEqual(checkedIndex, [0, 1, 2]);
  }, [checkedIndex]);

  const tabContent = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return <span>{standardTermsText}</span>;
      case 1:
        return <span>{privacyPolicyText}</span>;
      case 2:
        return <span>{purchasePolicyText}</span>;
      default:
        return <span>invalid index</span>;
    }
  }, [selectedTab]);

  return (
    <div>
      {/* Selector */}
      {/* <BugoTabs items={tabItems} selectedTab={selectedTab} setSelectedTab={setSelectedTab} /> */}
      <div className="py-6 px-4 text-sm font-bold">
        {/* Collapse */}
        <div className="h-[500px] w-full overflow-auto border-[1px] border-solid border-myApricot p-2">
          {tabContent}
        </div>
        {/* Enquiry Box */}
        <div className="mt-8 px-8 pb-4">
          <h5 className="whitespace-nowrap text-center">
            모든 약관을 확인하시고 진행해주세요.
          </h5>
          <Link
            aria-disabled={!isValid}
            to="/signup"
            className={`mx-auto mt-4 flex h-[52px] w-40 items-center justify-center ${buttonColorGenerator(
              isValid,
            )} text-white`}
          >
            동의합니다
          </Link>
        </div>
      </div>
    </div>
  );
};

export { TermsPage };
