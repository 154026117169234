import { FC, useEffect } from 'react';

import { Icon } from '@shared/components/icons';

type Props = {
  context: string;
  buttonTitle?: string;
};
declare global {
  interface Window {
    Kakao: any;
  }
}
const KakaoCourtesyShareButton: FC<Props> = ({ context, buttonTitle }: Props) => {
  const { ShareKakao } = Icon();
  const kakao = window.Kakao;

  useEffect(() => {
    if (kakao) {
      if (!kakao.isInitialized()) {
        kakao.init(process.env.REACT_APP_KAKAO_SHARE_JAVASCRIPT_KEY);
      }
      kakao.Share.createDefaultButton({
        container: '#kakaotalk-sharing-btn',
        objectType: 'text',
        text: context,
        buttonTitle: buttonTitle ?? ' ',
        link: {
          webUrl:
            process.env.REACT_APP_KAKAO_SHARE_COURTESY_LINK_URL ?? 'https://gipoom.com',
          mobileWebUrl:
            process.env.REACT_APP_KAKAO_SHARE_COURTESY_LINK_URL ?? 'https://gipoom.com',
        },
      });
    }
  }, [context, kakao]);

  return (
    <button id="kakaotalk-sharing-btn">
      <ShareKakao />
    </button>
  );
};

export default KakaoCourtesyShareButton;
