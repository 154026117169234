import { FC } from 'react';

import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { User } from '@shared/api/user/user.interface';
import Modal from '@shared/components/Modal';

import { AdminBugoBrandAgencyWorkerForm } from '../containers/AdminBugoBrandAgencyWorkerForm/AdminBugoBrandAgencyWorkerForm';

//*관리자가 상조 팀장을 수정 및 생성할때 사용하는 모달
interface Props {
  openId: string;
  bugoBrand: BugoBrand | BugoBrandData;
  agencyWorkerUser?: User;
}

export const AdminAgencyWorkerUpsertModal: FC<Props> =
  function AdminAgencyWorkerUpsertModal({ openId, bugoBrand, agencyWorkerUser }: Props) {
    return (
      <Modal openId={openId}>
        <AdminBugoBrandAgencyWorkerForm
          bugoBrand={bugoBrand}
          agencyWorkerUser={agencyWorkerUser}
          editMode={agencyWorkerUser ? true : false}
        />
      </Modal>
    );
  };
