import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  createCourtesyContent,
  updateCourtesyContent,
} from '@shared/api/courtesyAsset/courtesyAsset.controller';
import { courtesyContentListByBugoBrandHookUrl } from '@shared/api/courtesyAsset/courtesyAsset.hook';
import { CourtesyContent } from '@shared/api/courtesyAsset/courtesyAsset.interface';
import { Label } from '@shared/components/Label';
import Modal from '@shared/components/Modal';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UpsertMode } from '@shared/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import {
  CourtesyContentUpsertFormEnum,
  CourtesyContentUpsertFormInputs,
} from './CourtesyContentUpsertForm.interface';

interface Props {
  openId: string;
  mode: UpsertMode;
  courtesyContent?: CourtesyContent;
}

/**
 *  답례글 내용 UpsertModal
 *  openId
 *  mode: UpsertMode 수정, 등록
 *  courtesyContent: 수정시 기본 값
 * */
export const CourtesyContentUpsertModal: FC<Props> = function CourtesyContentUpsertModal({
  openId,
  mode,
  courtesyContent,
}: Props) {
  const { bugoBrandId, bugoRole } = useAuth();
  const { register, handleSubmit } = useForm<CourtesyContentUpsertFormInputs>({
    defaultValues: {
      [CourtesyContentUpsertFormEnum.Id]: courtesyContent?._id,
      [CourtesyContentUpsertFormEnum.Name]: courtesyContent?.name,
      [CourtesyContentUpsertFormEnum.BugoBrand]: courtesyContent?.bugoBrand,
      [CourtesyContentUpsertFormEnum.Content]: courtesyContent?.content,
    },
  });

  const onCloseModal = () => {
    setOpen(false);
    history.back();
  };

  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { isLoading, mutateAsync } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        courtesyContentListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
      );
      notificateSuccess(notifiacationInstance, '저장완료');
      onCloseModal();
    },
    onError: () => {
      notificateError(notifiacationInstance, '에러');
    },
  });
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const modalTitle = mode === UpsertMode.Insert ? '답례예문 추가' : '답례예문 수정';

  const onSubmit: SubmitHandler<CourtesyContentUpsertFormInputs> = async (data) => {
    data[CourtesyContentUpsertFormEnum.BugoBrand] = bugoBrandId;

    //* 필수정보 확인
    if (
      !data[CourtesyContentUpsertFormEnum.Name] ||
      !data[CourtesyContentUpsertFormEnum.BugoBrand] ||
      !data[CourtesyContentUpsertFormEnum.Content]
    ) {
      alert(`필수정보를 모두 입력해주세요`);
      throw Error('필수정보를 모두 입력해주세요');
    }

    try {
      //업데이트시
      if (mode === UpsertMode.Update) {
        {
          if (courtesyContent) {
            const courtesyAssetUpdate = {
              ...data,
              _id: courtesyContent._id,
            };
            await updateCourtesyContent(courtesyAssetUpdate, bugoRole());
          }
        }
      }
      //등록시
      else {
        await createCourtesyContent(data, bugoRole());
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal openId={openId}>
      <div className="space-y-4">
        <p className="bugo-h1">{modalTitle}</p>
        <TextField
          className="w-full"
          {...register(CourtesyContentUpsertFormEnum.Name)}
          label="이름"
        ></TextField>
        <div className="label-col">
          <Label>예문</Label>
          <textarea
            placeholder="예문의 내용을 입력해주세요"
            className="textfield h-36 w-full py-3 text-sm"
            {...register(CourtesyContentUpsertFormEnum.Content)}
          ></textarea>
        </div>
        <div className="pt-5 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 sm:pt-8">
          <button
            disabled={isLoading}
            type="button"
            className={`inline-flex w-full justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none sm:col-start-2`}
            onClick={async (e) => {
              await mutateAsync(handleSubmit(onSubmit)(e));
            }}
          >
            저장
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0"
            onClick={onCloseModal}
          >
            취소
          </button>
        </div>
      </div>
    </Modal>
  );
};
