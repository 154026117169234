//* 지사 관리자 유저 수정 및 생성을 위한 Form interface

import { UserCustomTheme } from '@shared/types';

export enum BugoBrandAgencyBranchAdminFormInputsEnum {
  UserName = 'username',
  Password = 'password',
  Name = 'name',
  Theme = 'theme',
  TeamType = 'teamType',
  TeamName = 'teamName',
}

export interface BugoBrandAgencyBranchAdminFormInputs {
  [BugoBrandAgencyBranchAdminFormInputsEnum.UserName]?: string;
  [BugoBrandAgencyBranchAdminFormInputsEnum.Password]?: string;
  [BugoBrandAgencyBranchAdminFormInputsEnum.Name]: string;
  [BugoBrandAgencyBranchAdminFormInputsEnum.Theme]: UserCustomTheme;
  [BugoBrandAgencyBranchAdminFormInputsEnum.TeamType]: string;
  [BugoBrandAgencyBranchAdminFormInputsEnum.TeamName]: string;
}
