//* 비회원 유저가 회원으로 전환할 수 있는 Page
import React, { FC, useCallback } from 'react';

import { ReactComponent as Logo } from '/src/assets/svg/logo.svg';

import { useRecoilState } from 'recoil';

import { useStoreTitleHook } from '@service/store/hooks/useStoreTitleHook';

import { ReactComponent as KaKaoLogo } from '/src/assets/svg/kakao-svgrepo-com.svg';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';

import { StoreBasicButton } from '../components/StoreBasicButton';

const mockCiData = {
  name: '김정민',
  point: 3000,
};

const StoreUpgradeCustomerPage: FC = () => {
  useStoreTitleHook('회원 전환');
  //TODO: text용도, 나중에 지워야 함
  const [theme, setTheme] = useRecoilState(customThemeAtom);

  const themeToggle = useCallback(() => {
    switch (theme) {
      case UserCustomTheme.Default:
        setTheme(UserCustomTheme.Preed);
        break;
      case UserCustomTheme.Preed:
        setTheme(UserCustomTheme.Dark);
        break;
      case UserCustomTheme.Dark:
        setTheme(UserCustomTheme.Default);
        break;
      default:
        setTheme(UserCustomTheme.Dark);
    }
  }, [theme, setTheme]);
  //TODO: 여기까지

  return (
    <React.Fragment>
      <div onClick={themeToggle} className="mt-10 grid place-items-center">
        <Logo className="h-[64px] w-[216px]" />
      </div>
      <div className="mt-8 pl-4 pr-4">
        <div className="py-10 px-3 pb-4 shadow-smd theme-bg-1">
          <div>
            <span>
              <span className="font-bold">{mockCiData.name}</span>
              님, 회원으로 전환하시면 여러가지 혜택을 누릴 수 있습니다.
            </span>
          </div>

          <div className="mt-5">
            <span>
              회원가입시{' '}
              <span className="font-bold">
                {mockCiData.point.toLocaleString('ko-KR')}
              </span>
              포인트 획득
            </span>
          </div>
          <StoreBasicButton className="mt-3 theme-bg-main">가입하기</StoreBasicButton>
          <StoreBasicButton className="mt-3 bg-kakao-yellow text-kakao-brown">
            <KaKaoLogo className="absolute h-5 w-5" />
            <span>카카오로 시작하기</span>
          </StoreBasicButton>
        </div>
      </div>
    </React.Fragment>
  );
};

export { StoreUpgradeCustomerPage };
