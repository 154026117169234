import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useUserEnquiryListHook } from '@shared/api/enquiry/enquiry.hook';
import { userEnquiryListStateSelector } from '@shared/api/enquiry/enquiry.selector';
import { Loading } from '@shared/components/Loading';

import EnquiryCollapse from '../containers/EnquiryCollapse/EnquiryCollapse';
import { useTitleHook } from '../hooks/useTitleHook';

const BugoMyEnquiriesPage: FC = () => {
  useTitleHook('내 문의내역');
  useUserEnquiryListHook();

  const userEnquiryListState = useRecoilValue(userEnquiryListStateSelector);
  const enquiryListRender = useMemo(() => {
    if (userEnquiryListState.status === 'success' && userEnquiryListState.data) {
      const enqueryList = userEnquiryListState.data;
      return enqueryList.map((enquiry) => (
        <div className="theme-bg-1">
          <EnquiryCollapse enquiry={enquiry} />
        </div>
      ));
    } else {
      return (
        <div className="">
          <div className="center-box py-8">
            <Loading />
          </div>
        </div>
      );
    }
  }, [userEnquiryListState.data, userEnquiryListState.status]);

  return (
    <div className="space-y-2 py-2 text-sm">
      {enquiryListRender}

      <div className="center-box">
        <p className="py-2 text-xs">최근 1년간 문의 내역만 조회 가능합니다.</p>
      </div>
    </div>
  );
};

export default BugoMyEnquiriesPage;
