import { FC, useMemo } from 'react';

import { BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { Button } from '@shared/components/Button';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';
import { Link } from 'react-router-dom';

interface Props {
  bugoBrandList: BugoBrandData[];
}

type BugoBrandTableData = {
  id: string;
  name: string;
  workerNum: number;
  agencyAdmin: string[];
};

const columns: TableColumnsType<BugoBrandTableData> = [
  {
    title: '상조',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
  },
  {
    title: '지도사수',
    dataIndex: 'workerNum',
    key: 'workerNum',
    align: 'center',
  },
  {
    title: '관리자',
    dataIndex: 'agencyAdmin',
    key: 'agencyAdmin',
    align: 'center',
    render: (admin: string[]) => {
      return <p>{admin.length}</p>;
    },
  },
  {
    title: '자세히보기',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (id) => {
      return (
        <div className="center-box gap-2">
          <Link to={`${id}/edit`}>
            <Button className="shadow-sm">수정</Button>
          </Link>
          <Link to={`${id}/multi-agency-edit`}>
            <Button className="shadow-sm">멀티상조</Button>
          </Link>
          <Link to={`${id}/agency-admin`}>
            <Button className="shaodw-sm">관리자</Button>
          </Link>
          <Link to={`${id}/agency-branch-admin`}>
            <Button className="shaodw-sm">지사</Button>
          </Link>
          <Link to={`${id}/agency-workers`}>
            <Button className="shaodw-sm">지도사</Button>
          </Link>
        </div>
      );
    },
  },
];

export const AdminBugoBrandListTable: FC<Props> = function AdminBugoBrandListTable({
  bugoBrandList,
}: Props) {
  const tableData: BugoBrandTableData[] = useMemo(() => {
    return _.map(bugoBrandList, (bugoBrand): BugoBrandTableData => {
      return {
        id: bugoBrand._id,
        name: bugoBrand.bugoAgency?.corpName ?? '',
        workerNum: bugoBrand.workers.length,
        agencyAdmin: bugoBrand.users,
      };
    });
  }, [bugoBrandList]);

  return (
    <div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'name'}
        bordered
        size={'small'}
      />
    </div>
  );
};
