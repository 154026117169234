import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AgencyWorkerUpsertModal } from '@service/bugo/components/AgencyWorkerUpsertModal';
import { UserBugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { agencyAdminUpdateWorker } from '@shared/api/user/user.controller';
import { workerUserByBugoBrandLightHookUrl } from '@shared/api/user/user.hook';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import DismissModal from '@shared/components/DismissModal';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Table, TableColumnsType } from 'antd';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

// import { AdminAgencyWorkerUpsertModal } from './AdminAgencyWorkerUpsertModal';

interface Props {
  workerList: User[];
  bugoBrand: UserBugoBrand;
}

type WorkerTableData = {
  id: string;
  index: number;
  name: string;
  user: User;
  isDeleted: boolean;
  phoneNumber: string;
  teamType: string;
  teamName: string;
  region: string;
};

export const AgencyWorkerTable: FC<Props> = function AgencyWorkerTable({
  workerList,
  bugoBrand,
}: Props) {
  const editOpenId = 'agencyWorkerTable-edit-worker';
  const deleteOpenId = 'agencyWorkerTable-delete-worker';
  const setEditOpen = useSetRecoilState(simpleOpenAtomFamily(editOpenId));
  const setDeleteOpen = useSetRecoilState(simpleOpenAtomFamily(deleteOpenId));
  const [selectWorker, setSelectWorker] = useState<User | undefined>(undefined);
  const { bugoRole } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      notificateSuccess(notifiacationInstance, '완료');
      queryClient.invalidateQueries(
        workerUserByBugoBrandLightHookUrl(bugoRole(), bugoBrand._id),
      );
    },
    onError: () => {
      notificateError(notifiacationInstance, '실패');
    },
  });
  const [searchValue, setSearchValue] = useState<string>('');

  const columns: TableColumnsType<WorkerTableData> = [
    {
      width: '5%',
      title: <div className="break-keep">index</div>,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (index: number) => {
        return <p className="break-keep text-xs">{index}</p>;
      },
    },
    {
      title: '성함',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '휴대전화번호',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
    },
    {
      title: '팀타입',
      dataIndex: 'teamType',
      key: 'teamType',
      align: 'center',
    },
    {
      title: '소속팀',
      dataIndex: 'teamName',
      key: 'teamName',
      align: 'center',
      render: (teamName: string) => {
        return <p className="font-medium">{teamName}</p>;
      },
    },
    {
      title: '소속지역',
      dataIndex: 'region',
      key: 'region',
      align: 'center',
      render: (region: string) => {
        return <p className="font-medium">{region}</p>;
      },
    },
    {
      title: '상세기능',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (user: User) => {
        return (
          <div className="center-box gap-2">
            <Button
              className="shadow-sm"
              onClick={() => {
                setSelectWorker(user);
                setEditOpen(true);
              }}
            >
              수정
            </Button>
            <Button
              className="shadow-sm"
              onClick={() => {
                setSelectWorker(user);
                setDeleteOpen(true);
              }}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];

  const teamNameChecBoxFilter: CheckBoxFilter = {};
  bugoBrand.bugoAgency?.teamNames.forEach(
    (teamName) => (teamNameChecBoxFilter[teamName] = true),
  );

  const [selectedTeamNames, setSelectedTeamNames] =
    useState<CheckBoxFilter>(teamNameChecBoxFilter);

  const searchedValue = useMemo(() => {
    const searcher = new FuzzySearch(workerList, [
      'info.name',
      'bugoAgencyWorkerDetail.region',
      'bugoAgencyWorkerDetail.teamType',
      'bugoAgencyWorkerDetail.teamName',
      'info.phoneNumber',
    ]);
    return searcher.search(searchValue);
  }, [workerList, searchValue]);

  const filteredList = searchedValue.filter((worker) => {
    return selectedTeamNames[worker.bugoAgencyWorkerDetail?.teamName ?? ''];
  });

  const tableData: WorkerTableData[] = useMemo(() => {
    return _.map(filteredList as User[] | null, (worker, index) => {
      return {
        id: worker._id,
        index: filteredList.length - index,
        user: worker,
        name: worker.info.name ?? '',
        isDeleted: worker.isDeleted ?? false,
        phoneNumber: worker.info.phoneNumber ?? '',
        teamType: worker.bugoAgencyWorkerDetail?.teamType ?? '',
        teamName: worker.bugoAgencyWorkerDetail?.teamName ?? '',
        region: worker.bugoAgencyWorkerDetail?.region ?? '',
      };
    });
  }, [filteredList]);

  const onDeleteWorker = async (worker: User) => {
    const deleteUser = {
      _id: worker._id,
      isDeleted: true,
    };
    await agencyAdminUpdateWorker(deleteUser);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4 border px-4 text-sm theme-bg-1 theme-border-1">
        <div className={`center-box self-stretch bg-opacity-40 px-2 font-bold`}>소속</div>
        <div className="flex-1 py-1 pr-2">
          <div className="flex flex-wrap gap-x-3">
            <CheckboxFilter
              filter={selectedTeamNames}
              setFilter={(checked) => setSelectedTeamNames(checked)}
            />
          </div>
        </div>
      </div>
      <TextField
        className="w-1/2 border-gray-500"
        type={'search'}
        onChange={onChangeHandler}
        placeholder="검색 상조 지도사명 / 지역 / 휴대번호 / 팀"
      />
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 15,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
      <AgencyWorkerUpsertModal
        openId={editOpenId}
        bugoBrand={bugoBrand}
        agencyWorkerUser={selectWorker}
      />
      <DismissModal
        openId={deleteOpenId}
        onClick={() => {
          if (selectWorker) {
            mutate(onDeleteWorker(selectWorker));
          } else {
            alert('선택된 지도사님이 존재하지 않습니다.');
          }
        }}
        buttonTitle={'삭제'}
        title={'지도사 삭제'}
        subtitle={`${selectWorker?.info.name}님을 삭제합니다.`}
      ></DismissModal>
    </div>
  );
};
