import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import BugoBrandAnnouncementForm from '@service/store/components/BugoBrandAnnouncementForm';
import {
  createBugoBrandAnnouncement,
  createBugoBrandAnnouncementUrl,
} from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.controller';
import {
  BugoBrandAnnouncementCreate,
  BugoBrandAnnouncementStatus,
} from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { LoadingModal } from '@shared/components/LoadingModal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';
import { BugoBrandAnnouncementFormInputs } from '../interfaces/bugoBrandAnnouncementForm.interface';

const BugoBrandAnnouncementAddPage: FC = () => {
  useTitleHook('공지사항 생성');
  const { userProfile, bugoRole, bugoBrandId } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const navigate = useNavigate();

  // loding modal open state
  const queryClient = useQueryClient();
  const { isLoading: processingAnnouncement, mutateAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(createBugoBrandAnnouncementUrl(bugoRole()));
      },
    },
  );

  const onSubmit: SubmitHandler<BugoBrandAnnouncementFormInputs> = async (data) => {
    if (!userProfile?._id) return;

    const { type, title, content, isReadRequired, teamTypes } = data;

    const announcementCreateDto: BugoBrandAnnouncementCreate = {
      user: userProfile._id,
      bugoBrand: bugoBrandId,
      status: BugoBrandAnnouncementStatus.Visible,
      type,
      title,
      content,
      isReadRequired,
      teamTypes: Object.keys(teamTypes).filter((teamType) => teamTypes[teamType]),
    };

    const onAnnouncementCreate = async () => {
      const createdAnnouncement = await createBugoBrandAnnouncement(
        announcementCreateDto,
        bugoRole(),
      );

      if (createdAnnouncement) {
        notificateSuccess(notifiacationInstance, '공지가 정상적으로 등록되었습니다.');
        navigate('/');
      } else {
        notificateError(notifiacationInstance, '공지가 정상적으로 등록되지 않았습니다.');
      }
    };

    mutateAsync(onAnnouncementCreate());
  };

  return (
    <>
      <div className="p-4">
        {/* Title */}
        <h1 className="py-8 text-center text-xl font-bold">공지사항</h1>
        {/* Form */}
        <BugoBrandAnnouncementForm onSubmit={onSubmit} />
      </div>
      <LoadingModal open={processingAnnouncement} title={'공지 생성중'} />
    </>
  );
};

export default BugoBrandAnnouncementAddPage;
