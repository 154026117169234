import { FC } from 'react';

import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';
import { Link, useLocation, useParams } from 'react-router-dom';

import { Icon } from './icons';

const linkColorGenerator = (isAccent: boolean) =>
  isAccent ? 'theme-bg-main theme-text-4' : 'theme-text-main';

const StatPageSideBar: FC = () => {
  const { bugoBrandId } = useParams();
  const { pathname } = useLocation();

  const { userProfile, bugoRole, userBugoBrand } = useAuth();

  const { WreathBig, BugoMessage, Receipt } = Icon();

  const bugoBrand = userBugoBrand;

  return (
    <aside className="w-60 flex-1 bg-white p-4">
      {/* 회사 / 유저 정보 */}
      <div className="space-y-2 border-b border-gray-300 py-4 font-bold">
        <h6 className="px-2 text-xs text-gray-500">{bugoBrand?.bugoAgency?.corpName}</h6>
        <h4 className="rounded-md p-3 theme-text-main theme-bg-5">
          {bugoRole() === LowerRole.BugoAgencyBranchAdmin
            ? userProfile?.bugoAgencyBranchAdminDetail?.teamName
            : bugoBrand?.bugoAgency?.nickName}{' '}
          관리자
        </h4>
      </div>
      {/* 버튼 리스트 */}
      <div className="space-y-3 py-4 text-xs font-bold">
        <div>
          <Link
            to={`/bugo-brand/${bugoBrandId}/flower-stat`}
            className={`flex items-center space-x-3 rounded-md p-2 ${linkColorGenerator(
              pathname.endsWith('flower-stat'),
            )}`}
          >
            <WreathBig className="m-1" />
            <h5>화환 내역</h5>
          </Link>
        </div>
        <div>
          <Link
            to={`/bugo-brand/${bugoBrandId}/bugo-stat`}
            className={`flex items-center space-x-3 rounded-md p-2 ${linkColorGenerator(
              pathname.endsWith('bugo-stat'),
            )}`}
          >
            <BugoMessage />
            <h5>부고 발송 내역</h5>
          </Link>
        </div>
        <div>
          <Link
            to={`/bugo-brand/${bugoBrandId}/account`}
            className={`flex items-center space-x-3 rounded-md p-2 ${linkColorGenerator(
              pathname.endsWith('account'),
            )}`}
          >
            <div className="p-1">
              <Receipt
                className={`wh-6 ${pathname.endsWith('account') ? 'fill-white' : ''}`}
              />
            </div>
            <h5>정산 내역</h5>
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default StatPageSideBar;
