import { FC } from 'react';

import { Button } from './Button';
import { Icon } from './icons';

export const ReloadButton: FC = function ReloadButton() {
  const { RotateRight } = Icon();

  return (
    <Button
      className="center-box h-full gap-1 theme-bg-1"
      onClick={() => {
        window.location.reload();
      }}
    >
      <RotateRight className="wh-4" />
      새로고침
    </Button>
  );
};
