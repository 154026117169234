import { atom } from 'recoil';

import {
  CourtesyAssetData,
  CourtesyContent,
} from '@shared/api/courtesyAsset/courtesyAsset.interface';

export const courtesyMessageAtom = atom<string[] | null>({
  key: 'courtesyMessageAtom',
  default: null,
});

//* 선택된 courtesyAsset의 Id를 저장함
export const courtesyAssetAtom = atom<CourtesyAssetData | null>({
  key: 'courtesyAssetAtom',
  default: null,
});

//* 선택된 courtesyContent 자체를 저장함
export const courtesyContentAtom = atom<CourtesyContent | null>({
  key: 'courtesyContentAtom',
  default: null,
});

//* 수정한 courtesyContentString
export const courtesyContentStringAtom = atom<string | null>({
  key: 'courtesyContentStringAtom',
  default: null,
});
