import { QueryDataState } from '@shared/interfaces';

export enum BugoBrandAnnouncementStatus {
  Visible = 'Visible',
  Invisible = 'Invisible',
}

export type BugoBrandAnnouncement<User = string, BugoBrand = string> = {
  _id: string;
  user: User;
  bugoBrand: BugoBrand;
  status: BugoBrandAnnouncementStatus;
  type: string;
  title: string;
  content: string;
  isReadRequired: boolean;
  teamTypes: string[];
  regions?: string[];
  teamNames?: string[];
  createdAt: Date;
  updatedAt: Date;
};

export type BugoBrandAnnouncementList = BugoBrandAnnouncement[];

export type BugoBrandAnnouncementState = QueryDataState<BugoBrandAnnouncement>;

export type BugoBrandAnnouncementListState = QueryDataState<BugoBrandAnnouncementList>;

export type BugoBrandAnnouncementCreate = Omit<
  BugoBrandAnnouncement,
  '_id' | 'createdAt' | 'updatedAt'
>;

export type BugoBrandAnnouncementUpdate = Pick<
  BugoBrandAnnouncement,
  '_id' | 'title' | 'type' | 'content' | 'isReadRequired' | 'teamTypes'
>;
