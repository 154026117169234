import { FC, useEffect, useState } from 'react';

import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import ClockLoader from '@shared/components/ClockLoader';
import { FileUploader } from '@shared/components/FileUploader';
import { Icon } from '@shared/components/icons';
import Map from '@shared/components/Map';
import MobileSelect from '@shared/components/select/MobileSelect';
import PCSelect from '@shared/components/select/PCSelect';
import AutoComplete from '@shared/containers/AutoComplete/AutoComplete';
import BankSelect from '@shared/containers/BankSelect/BankSelect';
import Select from '@shared/containers/Select/Select';
import { ISelectOption } from '@shared/interfaces/select.interface';
import { SubmitHandler, useForm } from 'react-hook-form';

import { useTitleHook } from '../hooks/useTitleHook';
/**
 * 부고 등록 페이지
 * @returns page, container
 */

const autoCompleteOptions: string[] = ['태호', '기훈', '정민'];

const optionList: ISelectOption[] = [
  {
    value: '0',
    label: '태호',
  },
  {
    value: '1',
    label: '기훈',
  },
  { value: '2', label: '정민' },
];

interface ITestInput {
  // select
  pcTest: string;
  mobileTest: string;
  test: string;
  // autoComplete
  autoTest: string;
}

const UnitTestPage: FC = function FeventAddPage() {
  const { BugoMessage } = Icon();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<CheckBoxFilter>({
    a: false,
    b: false,
    c: false,
  });

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 500);
  }, []);

  useTitleHook('유닛 테스트');
  const [autoCompleteValue, setAutoCompleteValue] = useState<string | null>(null);
  const [selectValue, setSelectValue] = useState<ISelectOption | null>(null);

  const { handleSubmit, control } = useForm<ITestInput>();

  const onSubmit: SubmitHandler<ITestInput> = (data) => {
    // console.log(data);
  };

  return (
    <div className="space-y-6 p-4 py-6">
      <div>
        <BankSelect control={control} name="bank" placeholder="은행" />
      </div>
      <div className="flex justify-around theme-bg-1">
        <CheckboxFilter filter={filter} setFilter={setFilter} />
      </div>
      <ClockLoader />
      <div className="h-[400px] w-full">
        <Map
          coord={{
            x: '126.9784147',
            y: '37.5666805',
          }}
          name="고려대학교 구로병원 장례식장"
        />
      </div>
      {/* <Splash isLoading={isLoading} /> */}
      {/* <DatePicker
        value={null}
        onDateChange={(date: Date | null) => {
          // console.log(date);
        }}
        placeholder="별세일"
      /> */}

      <BugoMessage className="wh-40" />

      <FileUploader />

      <form onSubmit={handleSubmit(onSubmit)}>
        <PCSelect
          placeholder="고인과의 관계"
          optionList={optionList}
          control={control}
          name="pcTest"
        />
        <MobileSelect
          placeholder="고인과의 관계"
          optionList={optionList}
          control={control}
          name="mobileTest"
        />
        <Select
          placeholder="고인과의 관계"
          optionList={optionList}
          control={control}
          name="test"
        />
        <AutoComplete
          placeholder="고인과의 관계"
          optionList={autoCompleteOptions}
          control={control}
          name="autoTest"
        />
        <button>Submit</button>
      </form>
    </div>
  );
};

export default UnitTestPage;
