import { FC, HTMLAttributes, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { CustomCallback } from '@shared/types';
import { DAY_LIST } from '@shared/utils/dayList';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  openId: string;
  onDateChange: CustomCallback<Date | null, void>;
  value?: Date | null;
}

const getPrevMonthDateList = (lastDateOfPrevMonth: Date): Date[] => {
  const result = [];
  const year = lastDateOfPrevMonth.getFullYear();
  const month = lastDateOfPrevMonth.getMonth();
  const day = lastDateOfPrevMonth.getDay();
  const date = lastDateOfPrevMonth.getDate();

  // visible month가 일요일부터 시작해 저번 달이 보여질 필요가 없는 경우.
  if (day === 6) return [];

  // visible month의 첫 주의 일요일부터 lastDateOfPrevMonth까지 반환.
  for (let i = day; i >= 0; --i) result.push(new Date(year, month, date - i));

  return result;
};

const getThisMonthDateList = (lastDateOfThisMonth: Date): Date[] => {
  const result = [];
  const year = lastDateOfThisMonth.getFullYear();
  const month = lastDateOfThisMonth.getMonth();
  const lastDate = lastDateOfThisMonth.getDate();

  for (let i = 1; i <= lastDate; ++i) result.push(new Date(year, month, i));

  return result;
};

const getNextMonthDateList = (firstDateOfNextMonth: Date): Date[] => {
  const result = [];
  const year = firstDateOfNextMonth.getFullYear();
  const month = firstDateOfNextMonth.getMonth();
  const day = firstDateOfNextMonth.getDay();

  // visible month가 일요일로 끝나 다음 달이 보여질 필요가 없는 경우.
  if (day === 0) return [];

  // visible month의 마지막 주의 firstDateOfNextMonth부터 토요일까지 반환.
  for (let i = 1; i <= 6 - day + 1; ++i) result.push(new Date(year, month, i));

  return result;
};

// 보고있는 달과 그 양 옆 달의 35(28)개 일의 datelist를 반환.
const getDateListOfMonth = (dateParam: Date) => {
  const year = dateParam.getFullYear();
  const month = dateParam.getMonth();

  const lastDateOfPrevMonth = new Date(year, month, 0); // 저번 달의 마지막 날
  const lastDateOfThisMonth = new Date(year, month + 1, 0); // 이번 달의 마지막 날
  const firstDateOfNextMonth = new Date(year, month + 1, 1); // 이번 달의 마지막 날

  const prevMonthDateList: Date[] = getPrevMonthDateList(lastDateOfPrevMonth);
  const thisMonthDateList: Date[] = getThisMonthDateList(lastDateOfThisMonth);
  const nextMonthDateList: Date[] = getNextMonthDateList(firstDateOfNextMonth);

  return [prevMonthDateList, thisMonthDateList, nextMonthDateList];
};

const compareDate = (firstDate: Date, secondDate: Date): boolean => {
  return (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() === secondDate.getMonth() &&
    firstDate.getDate() === secondDate.getDate()
  );
};

const DatePickerModal: FC<IProps> = ({
  openId,
  value,
  onDateChange,
  placeholder,
}: IProps) => {
  const { ChevronRight } = Icon();
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const initialDate = value ?? new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);
  const [visibleMonthDate, setVisibleMonthDate] = useState<Date>(initialDate);

  const onPrevMonthClick = () =>
    setVisibleMonthDate(
      (curr) => new Date(curr.getFullYear(), curr.getMonth() - 1, curr.getDate()),
    );

  const onNextMonthClick = () =>
    setVisibleMonthDate(
      (curr) => new Date(curr.getFullYear(), curr.getMonth() + 1, curr.getDate()),
    );

  const onDateBtnClick = (date: Date) => {
    setSelectedDate(date);
  };

  const onConfirm = () => {
    if (onDateChange) onDateChange(selectedDate);
    setOpen(false);
    history.back();
  };

  return (
    <div className="rounded-t-2xl px-4 py-6 text-sm theme-text-1 theme-bg-1">
      {/* Top */}
      <h4 className="bugo-h1 pb-1 text-center">{placeholder}</h4>
      <div className="flex items-center justify-between px-[13px]">
        <ChevronRight onClick={onPrevMonthClick} className="rotate-180" />
        {/* Visible Year / Month */}
        <h4 className="font-bold">
          {visibleMonthDate.getFullYear()}년 {visibleMonthDate.getMonth() + 1}월
        </h4>
        <ChevronRight onClick={onNextMonthClick} />
      </div>
      {/* Main Content */}
      <div>
        {/* 요일 */}
        <div className="grid grid-cols-7 pt-4">
          {DAY_LIST.map((day) => (
            <span key={day} className="inline-block py-2.5 text-center theme-text-8">
              {day}
            </span>
          ))}
        </div>
        {/* 날짜 */}
        <div className="grid grid-cols-7">
          {getDateListOfMonth(visibleMonthDate).map((month, i) =>
            month.map((date) => (
              <div className="flex justify-center" key={`calender-${date.getDate()}`}>
                <button
                  key={date.getTime()}
                  onClick={i === 1 ? () => onDateBtnClick(date) : undefined}
                  className={`wh-10 inline-block rounded-full text-center ${
                    i === 1 ? 'font-bold' : 'theme-text-6'
                  } ${
                    compareDate(date, selectedDate)
                      ? 'rounded-full text-white theme-bg-main'
                      : ''
                  }`}
                >
                  {date.getDate()}
                </button>
              </div>
            )),
          )}
        </div>
      </div>
      {/* Confirm Button */}
      <div className="px-2 pt-6 pb-4">
        <Button onClick={onConfirm} className="button-rectangle text-white theme-bg-main">
          확인
        </Button>
      </div>
    </div>
  );
};

export default DatePickerModal;
