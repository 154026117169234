import _ from 'lodash';

export const phoneNumberStringFormator = (s: string | undefined, arr?: any): string => {
  if (_.isUndefined(s)) return '';
  s = s.replace(/-/gi, '');
  let j = 0;
  let out = '';
  if (arr) {
    for (let i = 0; i < s.length; i++) {
      if (j < arr.length && i >= arr[j]) {
        out = out + '-';
        j = j + 1;
      }
      out = out + s[i];
    }
    return out;
  } else {
    arr = s.length === 10 ? [3, 6] : [3, 7];
    for (let i = 0; i < s.length; i++) {
      if (j < arr.length && i >= arr[j]) {
        out = out + '-';
        j = j + 1;
      }
      out = out + s[i];
    }
    return out;
  }
};

export const callNumberStringFormater = (s: string | undefined, arr?: any): string => {
  if (_.isUndefined(s)) return '';
  s = s.replace(/-/gi, '');
  let j = 0;
  let out = '';
  if (arr) {
    for (let i = 0; i < s.length; i++) {
      if (j < arr.length && i >= arr[j]) {
        out = out + '-';
        j = j + 1;
      }
      out = out + s[i];
    }
    return out;
  } else {
    if (s.slice(0, 2) === '02') {
      arr = s.length === 9 ? [2, 5] : [2, 6];
      for (let i = 0; i < s.length; i++) {
        if (j < arr.length && i >= arr[j]) {
          out = out + '-';
          j = j + 1;
        }
        out = out + s[i];
      }
      return out;
    } else {
      arr = s.length === 10 ? [3, 6] : [3, 7];
      for (let i = 0; i < s.length; i++) {
        if (j < arr.length && i >= arr[j]) {
          out = out + '-';
          j = j + 1;
        }
        out = out + s[i];
      }
      return out;
    }
  }
};
