import React, { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { Label } from '@shared/components/Label';
import Modal from '@shared/components/Modal';
import { TextField } from '@shared/components/TextField';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import {
  fileBucketUrl,
  imageSelectHandlerWithPreview,
  singleImageSelectHandlerWithPreview,
} from '@shared/utils/fileUtils';
import { useForm } from 'react-hook-form';

interface ImageUploaderProps {
  openId: string;
  title: string;
  onSubmit: (data: any, files: any) => Promise<void>;
  useName?: boolean;
}

const ImageUploader: FC<ImageUploaderProps> = ({
  openId,
  title,
  onSubmit,
  useName = true,
}: ImageUploaderProps) => {
  const { register, handleSubmit } = useForm();
  const [open, setOpen] = useRecoilState(simpleOpenAtomFamily(`imageUploader-${openId}`));
  const [files, setFiles] = useState<any>(null);
  const [previews, setPreviews] = useState<any>([]);
  const { UploadImage, X } = Icon();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (open === false) {
      timeout = setTimeout(() => {
        setPreviews([]);
        setFiles(null);
      }, 200);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [open]);

  const previewRender = useMemo(() => {
    return (
      <>
        {previews.map((preview: any, index: number) => {
          return (
            <div
              key={index}
              className="relative flex h-full flex-col justify-end rounded-lg border p-2 shadow-md"
            >
              <div className="absolute right-1 top-1">
                <button
                  type="button"
                  onClick={() => {
                    const tempFiles = files.slice();
                    tempFiles.splice(index, 1);
                    const tempPreviews = previews.slice();
                    tempPreviews.splice(index, 1);
                    setFiles(tempFiles);
                    setPreviews(tempPreviews);
                  }}
                >
                  <X className="wh-5 fill-gray-400 hover:fill-red-600" />
                </button>
              </div>
              <img src={preview.url} alt="prview" />
              {useName && (
                <TextField
                  className="h-10 w-full"
                  label="이름"
                  labelClassname="text-sm font-medium"
                  {...register(`imageName.${files[index]?.name?.split('.')[0]}`, {
                    required: true,
                  })}
                />
              )}
            </div>
          );
        })}
      </>
    );
  }, [files, previews, register, useName]);

  const onSubmit_ = async (data: any) => {
    onSubmit(data, files);
  };

  return (
    <Modal openId={`imageUploader-${openId}`}>
      <form>
        <div className="space-y-4">
          <Label>{title}</Label>
          <div className="relative mt-4 flex flex-col items-center justify-center border-2 border-dashed border-gray-300 py-5">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e: any) => {
                imageSelectHandlerWithPreview(e, setFiles, setPreviews);
              }}
              className="absolute h-full w-full cursor-pointer rounded-xl opacity-0"
            ></input>
            <UploadImage />
            <p className="text-14">
              <span className="pr-1 text-blue-500">클릭</span> 또는 사진 파일을
              드래그하세요 <br />
            </p>
            <p className="text-12 text-gray-500">PNG, JPG, GIF up to 25MB</p>
          </div>
          <div className="py-4">
            <div className="border-t-2 border-gray-300 "></div>
          </div>
          <div className="grid-responsive-box container mx-auto">{previewRender}</div>
          <div className="flex justify-end">
            <Button
              text="업로드"
              className="filled-gray-800"
              onClick={handleSubmit(onSubmit_)}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

interface ImageUploadBoxProps {
  title: string;
  setFile: React.Dispatch<any>;
  defaultImageUrl?: string;
}

const ImageUploadBox: FC<ImageUploadBoxProps> = function ImageUploadBox({
  title,
  setFile,
  defaultImageUrl,
}: ImageUploadBoxProps) {
  const [preview, setPreview] = useState<any>();
  const { UploadImage, X } = Icon();

  const preivewRender = useMemo(() => {
    if (preview) {
      return (
        <div>
          <Label text="미리보기" className="label-sm"></Label>
          <img src={preview} alt="미리보기" className="object-cover" />
        </div>
      );
    } else if (defaultImageUrl) {
      return (
        <div>
          <Label text="기존 파일" className="label-sm"></Label>
          <img
            src={fileBucketUrl(defaultImageUrl, 'w200')}
            alt="기존파일"
            className="object-cover"
          />
        </div>
      );
    }
  }, [defaultImageUrl, preview]);

  return (
    <form>
      <div className="">
        <Label>{title}</Label>
        <div className="flex gap-2">
          <div className="relative mt-4 flex w-1/2 flex-col items-center justify-center border-2 border-dashed border-gray-300 py-5 px-2">
            <input
              type="file"
              accept="image/*"
              onChange={(e: any) => {
                singleImageSelectHandlerWithPreview(e, setFile, setPreview);
              }}
              className="absolute h-full w-full cursor-pointer rounded-xl opacity-0"
            ></input>
            <UploadImage />
            <p className="text-14">
              <span className="pr-1 text-blue-500">클릭</span> 또는 사진 파일을
              드래그하세요
              <br />
            </p>
            <p className="text-12 text-gray-500">PNG, JPG, GIF up to 25MB</p>
          </div>
          <div className="w-1/2 ">
            <div className="aspect-w-1 aspect-h-1 shrink-0 overflow-hidden">
              {preivewRender}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

interface ImageUploadListBoxProps {
  title: string;
  files: any[];
  setFiles: React.Dispatch<any[]>;
}

const ImageListUploadBox: FC<ImageUploadListBoxProps> = function ImageUploadBox({
  title,
  files,
  setFiles,
}: ImageUploadListBoxProps) {
  const [previews, setPreviews] = useState<any>();
  const { UploadImage, X } = Icon();

  const preivewRender = useMemo(() => {
    if (previews) {
      return (
        <div>
          {previews.map((preview: any, index: number) => {
            return (
              <div
                key={index}
                className="relative flex h-full flex-col justify-end rounded-lg border p-2 shadow-md"
              >
                <div className="absolute right-1 top-1">
                  <button
                    type="button"
                    onClick={() => {
                      const tempFiles = files.slice();
                      tempFiles.splice(index, 1);
                      const tempPreviews = previews.slice();
                      tempPreviews.splice(index, 1);
                      setFiles(tempFiles);
                      setPreviews(tempPreviews);
                    }}
                  >
                    <X className="wh-5 fill-gray-400 hover:fill-red-600" />
                  </button>
                </div>
                <img src={preview.url} alt="prview" />
              </div>
            );
          })}
        </div>
      );
    }
  }, [X, files, previews, setFiles]);

  return (
    <form>
      <div className="">
        <Label>{title}</Label>
        <div className="flex gap-2">
          <div className="relative mt-4 flex w-1/2 flex-col items-center justify-center border-2 border-dashed border-gray-300 py-5">
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e: any) => {
                imageSelectHandlerWithPreview(e, setFiles, setPreviews);
              }}
              className="absolute h-full w-full cursor-pointer rounded-xl opacity-0"
            ></input>
            <UploadImage />
            <p className="text-14">
              <span className="pr-1 text-blue-500">클릭</span> 또는 사진 파일들을
              드래그하세요
              <br />
            </p>
            <p className="text-12 text-gray-500">PNG, JPG, GIF up to 25MB</p>
          </div>
          <div className="w-1/2 ">
            <div className="aspect-w-1 aspect-h-1 shrink-0 overflow-hidden">
              {preivewRender}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

interface SimpleImageUploadBoxProps {
  title: string;
  setFile: React.Dispatch<any>;
  defaultImageUrl?: string;
  classname?: string;
}

const SimpleImageUploadBox: FC<SimpleImageUploadBoxProps> =
  function SimpleImageUploadBox({
    title,
    setFile,
    defaultImageUrl,
    classname,
  }: SimpleImageUploadBoxProps) {
    const [preview, setPreview] = useState<any>();
    const { UploadImage } = Icon();

    const previewRender = useMemo(() => {
      if (preview) {
        return (
          <div className="">
            <img src={preview} alt="미리보기" className="object-cover"></img>
          </div>
        );
      } else if (defaultImageUrl) {
        return (
          <div>
            <img
              src={fileBucketUrl(defaultImageUrl, 'w200')}
              alt="기존파일"
              className="object-cover"
            />
          </div>
        );
      } else {
        return (
          <div className="center-box space-x-4">
            <UploadImage className="" />
            <p className={classname}>{title} 파일을 업로드 해주세요.</p>
          </div>
        );
      }
    }, [UploadImage, classname, defaultImageUrl, preview, title]);

    return (
      <div
        className={`w-full items-center rounded-md border border-gray-200 p-4 text-gray-400 ${classname}`}
      >
        <input
          type="file"
          accept="image/*"
          onChange={(e: any) => {
            singleImageSelectHandlerWithPreview(e, setFile, setPreview);
          }}
          className="absolute h-full w-full cursor-pointer rounded-xl opacity-0"
        ></input>
        {previewRender}
      </div>
    );
  };

export { ImageUploader, ImageUploadBox, ImageListUploadBox, SimpleImageUploadBox };
