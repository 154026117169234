import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { createEnquiry } from '@shared/api/enquiry/enquiry.controller';
import {
  EnquiryCreate,
  EnquiryStatus,
  EnquiryType,
} from '@shared/api/enquiry/enquiry.interface';
import { postImage } from '@shared/api/uploadImage';
import { Button } from '@shared/components/Button';
import { FileUploader } from '@shared/components/FileUploader';
import { LoadingModal } from '@shared/components/LoadingModal';
import TelField from '@shared/components/TelField';
import { TextField } from '@shared/components/TextField';
import { EnquiryInputs, IEnquiryInputs } from '@shared/interfaces/enquiry.interface';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { checkFilesSize } from '@shared/utils/checkFilesSize';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';

const ALLOWED_FILE_FORMAT = 'image/*';
const FILES_SIZE_LIMIT = 50000000; // 50mb

const BugoEnquiryPage: FC = () => {
  useTitleHook('문의하기');
  const { userProfile, assetRole } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const navigate = useNavigate();

  const { register, handleSubmit, control } = useForm<IEnquiryInputs>();

  const telValue = useWatch({ control, name: EnquiryInputs.Tel, defaultValue: '' });

  // loding modal open state
  const queryClient = useQueryClient();
  const { isLoading: processingEnquiry, mutateAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`/${assetRole()}/enquiry`);
      },
    },
  );

  const onSubmit: SubmitHandler<IEnquiryInputs> = async (data) => {
    const isAllowed = checkFilesSize(data.files, FILES_SIZE_LIMIT);

    if (isAllowed) {
      // Post Image
      let imageId: string | null = null;
      if (data.files[0]) {
        const image = await postImage(data.files[0], assetRole());
        imageId = image._id;
      }

      const enquiryCreateDto: EnquiryCreate = {
        user: userProfile?._id ?? '',
        type: EnquiryType.Bugo,
        name: data.name,
        phoneNumber: data.tel,
        title: data.title,
        content: data.description,
        image: imageId ?? null,
        status: EnquiryStatus.Todo,
      };

      const onEnquiryCreate = async () => {
        const createdEnquiry = await createEnquiry(enquiryCreateDto);

        if (createdEnquiry) {
          notificateSuccess(notifiacationInstance, '문의가 정상적으로 요청되었습니다.');
          navigate('/');
        } else {
          notificateError(
            notifiacationInstance,
            '문의가 정상적으로 요청되지 않았습니다.',
          );
        }
      };

      mutateAsync(onEnquiryCreate());
    } else {
      // 파일 크기가 초과됨.
      notificateError(notifiacationInstance, '파일의 크기를 줄여주세요.');
    }
  };

  return (
    <>
      <div className="p-4">
        {/* Title */}
        <h1 className="py-8 text-center text-xl font-bold">1:1 문의</h1>
        {/* Inputs */}
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Text Inputs */}
          <TextField
            type="text"
            label="성함"
            placeholder="성함을 입력해주세요."
            className="w-full text-sm"
            labelClassname="pl-2 pb-1 font-bold"
            {...register(EnquiryInputs.Name, { required: true })}
          />
          <TelField
            label="연락처"
            labelClassname="pl-2 pb-1 font-bold"
            value={telValue}
            register={register}
            name={EnquiryInputs.Tel}
            className="w-full text-sm"
            required={true}
          />
          <TextField
            type="text"
            label="제목"
            placeholder="제목을 입력해주세요."
            className="w-full text-sm"
            labelClassname="pl-2 pb-1 font-bold"
            {...register(EnquiryInputs.Title, { required: true })}
          />
          {/* Textarea Input */}
          <div>
            <div className="label-col">
              <p className="pl-2 pb-1 font-bold">문의 내용</p>
            </div>
            <div className="relative">
              <textarea
                placeholder="문의 내용을 입력해주세요."
                className="textfield h-36 w-full py-3 text-sm"
                {...register(EnquiryInputs.Description, { required: true })}
              />
            </div>
          </div>
          {/* File Input */}
          <div>
            <div className="label-col">
              <p className="pl-2 font-bold">첨부파일</p>
              <span className="inline-block pl-2 pb-6 text-sm">
                최대 50mb까지 업로드 가능합니다.
              </span>
            </div>
            <div className="relative">
              <FileUploader
                multiple
                accept={ALLOWED_FILE_FORMAT}
                {...register(EnquiryInputs.Files)}
              />
            </div>
          </div>
          {/* Submit Button */}
          <div className="grid grid-cols-2">
            <div />
            <Button
              type="submit"
              className="button-rectangle mt-4 bg-gray-900 py-3 text-base text-white"
            >
              제출하기
            </Button>
          </div>
        </form>
      </div>
      <LoadingModal open={processingEnquiry} title={'문의 요청중'} />
    </>
  );
};

export default BugoEnquiryPage;
