import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import { User } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { DatePicker } from '@shared/containers/DatePicker/DatePicker';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { getTodayFrom, getTodayTo } from '@shared/utils/dateUtils';
import { formatDate } from '@shared/utils/formatDate';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';

import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';
import AgencyAdminShopOrderStatusBar from './AgencyAdminShopOrderStatusBar';

interface IProps {
  orderList: ShopOrderAgencyAdmin[];
  teamTypesFilter: CheckBoxFilter | null;
  searchValue: string;
}

type ShopOrderTableData = {
  id: string;
  user: Pick<User, '_id' | 'info' | 'bugoAgencyWorkerDetail'>;
  shopItemName: string;
  createdAt: Date | '';
  paymentAt: Date | '';
  priceRetail: number;
};

const AgencyAdminShopOrderListTableMobile: FC<IProps> = ({
  orderList,
  teamTypesFilter,
  searchValue,
}: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [dateFilteredShopOrderList, setDateFilteredFeventList] = useState<
    ShopOrderAgencyAdmin[]
  >(orderList ?? []);

  // TeamType Filtering
  const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

  const teamTypesFilteredFeventList = useMemo(() => {
    if (selectedTeamTypes) {
      return dateFilteredShopOrderList.filter(
        (shopOrder) =>
          selectedTeamTypes[
            shopOrder.fevent?.user?.bugoAgencyWorkerDetail?.teamType ?? ''
          ],
      );
    } else {
      return dateFilteredShopOrderList;
    }
  }, [dateFilteredShopOrderList, selectedTeamTypes]);

  // Search
  const resultValue = useMemo(() => {
    const searcher = new FuzzySearch(teamTypesFilteredFeventList, [
      'fevent.registerNumber',
      'fevent.user.info.name',
      'fevent.user.bugoAgencyWorkerDetail.teamName',
      'fevent.user.bugoAgencyWorkerDetail.teamType',
      'fevent.user.bugoAgencyWorkerDetail.region',
      'orderDetail.shopItemEmbed.name',
    ]);
    return searcher.search(searchValue);
  }, [teamTypesFilteredFeventList, searchValue]);

  // dateRange 적용.
  useEffect(() => {
    if (!orderList) return;

    const rangeFilteredList = orderList.filter((fevent) => {
      if (!fevent.createdAt) return false;
      const createdAt = new Date(fevent.createdAt).getTime();

      if (dateFrom && dateTo) {
        return dateFrom.getTime() <= createdAt && createdAt <= dateTo.getTime();
      }

      // dateRange 적용 안되어 있으면 다 보여주기.
      if (!dateFrom && !dateTo) return true;

      // 한 쪽만 적용되어 있으면 false.
      return false;
    });

    setDateFilteredFeventList(rangeFilteredList);
  }, [dateFrom, dateTo, orderList]);

  const tableData: ShopOrderTableData[] = useMemo(() => {
    return _.map(
      resultValue as ShopOrderAgencyAdmin[] | null,
      (shopOrder): ShopOrderTableData => {
        return {
          id: shopOrder._id,
          shopItemName: shopOrder.orderDetail.shopItemEmbed.name,
          user: shopOrder.fevent?.user,
          priceRetail: shopOrder.orderDetail.shopItemEmbed.priceRetail,
          createdAt: shopOrder.createdAt,
          paymentAt: shopOrder.paymentDetail.purchasedAt ?? '',
        };
      },
    );
  }, [resultValue]);

  const columns: TableColumnsType<ShopOrderTableData> = [
    {
      title: <div className="break-keep">주문일</div>,
      dataIndex: 'paymentAt',
      key: 'paymentAt',
      align: 'center',
      render: (value: Date) => {
        return (
          <p className="text-xs text-gray-600">
            {formatDate(value, {
              contains: { year: false, date: true },
              dateSeparater: '/',
            })}
          </p>
        );
      },
    },
    {
      width: '15%',
      title: '소속',
      dataIndex: 'user',
      key: 'teamType',
      align: 'center',
      render: (user: User) => {
        return <p className="break-keep text-xs">{getAgencyWorkerTeamName(user)}</p>;
      },
    },
    {
      width: '15%',
      title: '지도사',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (user: User) => {
        return (
          <WorkerDetailModalOpener workerName={user.info.name} workerId={user._id} />
        );
      },
    },
    {
      title: '상품명',
      dataIndex: 'shopItemName',
      key: 'name',
      align: 'center',
      render: (name) => {
        return <p className="break-keep text-xs font-medium">{name}</p>;
      },
    },

    {
      title: <div className="break-keep">결제금액</div>,
      dataIndex: 'priceRetail',
      key: 'priceRetail',
      align: 'center',
      render: (value: number) => {
        return (
          <p className="break-keep text-xs text-gray-600">
            {(value / 10000).toFixed(1).toLocaleString()}만원
          </p>
        );
      },
    },
  ];

  const onDateFromChange = (date: Date | null) => {
    // 설정된 시작일이 종료일보다 클 경우 switch.
    if (dateTo && date && dateTo.getTime() < date.getTime()) {
      setDateFrom(getTodayFrom(dateTo));
      setDateTo(getTodayTo(date));
      return;
    }

    setDateFrom(date ? getTodayFrom(date) : null);
  };

  const onDateFromTo = (date: Date | null) => {
    // 설정된 종료일이 시작일보다 작을 경우 switch.
    if (dateFrom && date && dateFrom.getTime() > date.getTime()) {
      setDateTo(getTodayTo(dateFrom));
      setDateFrom(getTodayFrom(date));
      return;
    }

    setDateTo(date ? getTodayTo(date) : null);
  };

  return (
    <div className="space-y-2 px-2">
      {/* Date Range Picker */}
      <div className="flex items-center space-x-2 bg-opacity-60">
        {/* 시작일 */}
        <DatePicker
          placeholder="시작일"
          value={dateFrom}
          onChange={onDateFromChange}
          className="flex-1"
        />
        <div>~</div>
        {/* 종료일 */}
        <DatePicker
          placeholder="종료일"
          value={dateTo}
          onChange={onDateFromTo}
          className="flex-1"
        />
      </div>
      {/* 소속 필터 */}
      {selectedTeamTypes && (
        <div className="flex items-center border text-xs theme-bg-1 theme-border-1">
          <div
            className={`center-box self-stretch bg-opacity-40 px-2 font-bold ${mainBgColorGenerator(
              customTheme,
            )}`}
          >
            소속
          </div>
          <div className="flex flex-1 items-center justify-around">
            <CheckboxFilter
              filter={selectedTeamTypes}
              setFilter={(checked) => setSelectedTeamTypes(checked)}
            />
          </div>
        </div>
      )}
      <AgencyAdminShopOrderStatusBar shopOrderList={resultValue} />
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{ position: ['bottomCenter'], pageSize: 15 }}
        rowKey={'id'}
        size={'small'}
        onHeaderRow={() => ({
          className: `text-xs font-bold bg-opacity-40 ${mainBgColorGenerator(
            customTheme,
          )}`,
        })}
      />
    </div>
  );
};

export default AgencyAdminShopOrderListTableMobile;
