import { FC, useCallback, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { createShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.controller';
import { shopItemInfoListByShopUrl } from '@shared/api/shopItemInfo/shopItemInfo.hook';
import { postImage, postImages } from '@shared/api/uploadImage';
import { Button } from '@shared/components/Button';
import { Label } from '@shared/components/Label';
import Modal from '@shared/components/Modal';
import { TextField } from '@shared/components/TextField';
import Select from '@shared/containers/Select/Select';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useForm, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { ImageUploadBox, ImageUploader } from './ImageUploadBox';

interface Props {
  openId: string;
}
export const AddShopItemInfoModal: FC<Props> = function AddShopItemInfoModal({
  openId,
}: Props) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const { sellerRole, isAdmin } = useAuth();
  const queryClient = useQueryClient();
  const isEnabled = useWatch({ control, name: 'isEnabled' });
  //   const openId = `addShopItemCard`;
  const [imageFile, setImageFile] = useState<any>(null);
  const [detailImageFiles, setDetailImageFiles] = useState<FileList>();
  const { shopId } = useParams();
  const setDetailImageUploaderOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const onDetailImageUploaderFunc = useCallback(
    async (data: any, files: any) => {
      setDetailImageFiles(files);
      setDetailImageUploaderOpen(false);
    },
    [setDetailImageUploaderOpen],
  );

  const onSubmit = useCallback(
    async (data: any) => {
      const shopItem = {
        ...data,
        shop: shopId,
      };
      let detailImages;
      try {
        const image = await postImage(imageFile, sellerRole());
        if (detailImageFiles) {
          const imageList = await postImages(detailImageFiles, sellerRole());
          detailImages = imageList;
        }
        const shopItemWithImage = {
          ...shopItem,
          image: image._id,
          detailImages: detailImages,
        };
        await createShopItemInfo(shopItemWithImage, sellerRole());
        setDetailImageUploaderOpen(false);
        await queryClient.invalidateQueries(
          shopItemInfoListByShopUrl(shopId ?? 'undefined', sellerRole()),
        );
        notificateSuccess(notifiacationInstance, '상품추가 성공!');
      } catch (err: any) {
        console.log(err);
      }
    },
    [
      detailImageFiles,
      imageFile,
      notifiacationInstance,
      queryClient,
      setDetailImageUploaderOpen,
      shopId,
      sellerRole,
    ],
  );
  return (
    <>
      <Modal openId={openId}>
        <div className="space-y-4">
          <h2 className="text-xl font-bold leading-6 text-gray-900">상품추가</h2>
          <div className="space-y-2">
            <TextField
              className="textfield-sm w-full"
              labelClassname="label-sm"
              label="상품명"
              {...register('name', {
                required: {
                  value: true,
                  message: '상품명을 입력해주세요',
                },
              })}
              //   error={errors?.name}
              //   errorMessage={errors?.name?.message}
            />
            <div className="flex gap-2">
              <TextField
                className="textfield-sm w-full"
                labelClassname="label-sm"
                label="정가"
                {...register('priceRetailShow')}
              />
              <TextField
                className="textfield-sm w-full"
                labelClassname="label-sm"
                label="판매가"
                {...register('priceRetail')}
              />
            </div>
            <div className="flex gap-2">
              <div className="w-half-1">
                <TextField
                  className="textfield-sm w-full"
                  labelClassname="label-sm"
                  label="도매가"
                  {...register('priceWhole')}
                />
              </div>
              <div className="w-half-1">
                <label>판매여부</label>
                <Select
                  className="textfield-sm w-full"
                  //   label="판매여부"
                  //   labelClassname="label-sm"
                  optionList={[
                    { value: true, label: '가능' },
                    { value: false, label: '불가능' },
                  ]}
                  defaultValue={isEnabled ? '가능' : '불가능'}
                  control={control}
                  name="isEnabled"
                  placeholder="가능"
                />
              </div>
            </div>
          </div>
          <div>이미지 업로드</div>
          <ImageUploadBox title="대표이미지" setFile={setImageFile} />
          <div className="label-col">
            <Label className="label-sm pl-0" text="상세 이미지" />

            <Button
              className="button-sm filled-indigo-500"
              onClick={() => {
                setDetailImageUploaderOpen(true);
              }}
            >
              이미지(여러장) 업로드
            </Button>
            <Label className="label-sm" text="상세 설명" />
            <textarea
              className="expandable-textarea w-full overflow-y-scroll"
              {...register('detailInfo')}
            />
          </div>
          <div className="flex justify-end">
            <Button
              className="filled-gray-800"
              onClick={(e) => {
                handleSubmit(onSubmit)(e);
              }}
            >
              추가
            </Button>
          </div>
          <ImageUploader
            openId={openId}
            title="상세이미지 업로드"
            onSubmit={onDetailImageUploaderFunc}
            useName={false}
          />
        </div>
      </Modal>
    </>
  );
};
