import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { sellerFeventAppliedearchValueAtom } from '@service/seller/containers/SellerFeventSearchTable/state/sellerFeventSearchTable.atom';
import { FeventByShop } from '@shared/api/fevent/fevent.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { TextField } from '@shared/components/TextField';
import { DeathCharacter } from '@shared/miscValues';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { formatFeventDateString } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';

import { SellerShopOrderCreateModal } from '../SellerShopOrderCreateModal/SellerShopOrderCreateModal';

interface Props {
  feventList: FeventByShop[];
}

type SellerFeventSerachTableData = {
  id: string;
  funeralHomeInfo: string;
  roomInfo: string;
  deceasedName: string;
  address: string;
  enterance: string;
  coffinOut: string;
  fevent: FeventByShop;
};

export const SellerFeventSearchTable: FC<Props> = function ({ feventList }: Props) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [appliedearchValue, setAppliedSearchValue] = useRecoilState(
    sellerFeventAppliedearchValueAtom,
  );
  const [selectedFevent, setSelectedFevent] = useState<FeventByShop | null>(null);
  const { CS, MagnifyingGlassRegular } = Icon();
  const createModalOpenId = `sellerShopOrderCreate`;
  const setCreateModalOpen = useSetRecoilState(simpleOpenAtomFamily(createModalOpenId));

  const searchedValue = useMemo(() => {
    const searcher = new FuzzySearch(feventList, [
      'funeralHomeInfo.name',
      'funeralHomeInfo.address',
      'funeralHomeInfoEmbed.name',
      'funeralHomeInfoEmbed.address',
      'memberOrderList.fullName',
      'deceasedInfo.name',
    ]);
    return searcher.search(appliedearchValue);
  }, [feventList, appliedearchValue]);

  useEffect(() => {
    setSearchValue(appliedearchValue);
  }, [appliedearchValue]);

  const sellerFeventTableData: SellerFeventSerachTableData[] = useMemo(() => {
    return _.map(searchedValue as FeventByShop[] | null, (fevent) => {
      return {
        id: fevent._id,
        funeralHomeInfo:
          fevent.funeralHomeInfoEmbed?.name ?? fevent.funeralHomeInfo.name ?? '',
        roomInfo: fevent.roomInfoEmbed?.name ?? fevent.roomInfo?.name ?? '',
        address:
          fevent.funeralHomeInfoEmbed?.address ?? fevent.funeralHomeInfo.address ?? '',
        deceasedName: fevent.deceasedInfo.name ?? '',
        enterance: formatFeventDateString(fevent.deceasedInfo.enterance, false),
        coffinOut: formatFeventDateString(fevent.deceasedInfo.coffinOut, false),
        fevent: fevent,
      };
    });
  }, [searchedValue]);

  const onCallOrderClick = (record: SellerFeventSerachTableData) => {
    setSelectedFevent(record.fevent);
    setCreateModalOpen(true);
  };

  const columns: TableColumnsType<SellerFeventSerachTableData> = [
    {
      title: '장례식장',
      dataIndex: 'funeralHomeInfo',
      key: 'funeralHomeInfo',
      align: 'center',
    },
    {
      title: '호실',
      dataIndex: 'roomInfo',
      key: 'roomInfo',
      align: 'center',
    },
    {
      title: '고인성함',
      dataIndex: 'deceasedName',
      key: 'deceasedName',
      align: 'center',
      render: (name: string) => {
        return (
          <p>
            {DeathCharacter} {name}님
          </p>
        );
      },
    },
    {
      title: '입실일',
      dataIndex: 'enterance',
      key: 'enterance',
      align: 'center',
    },
    {
      title: '발인일',
      dataIndex: 'coffinOut',
      key: 'coffinOut',
      align: 'center',
    },
    {
      title: '전화주문',
      align: 'center',
      render: (_, record) => {
        return (
          <div className="center-box">
            <Button
              className="rounded-sm p-0.5 shadow-sm"
              onClick={() => {
                onCallOrderClick(record);
              }}
            >
              <CS className="wh-6" />
            </Button>
          </div>
        );
      },
    },
  ];

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col items-start space-y-4">
        <p>부고를 검색하여 전화주문을 완성하세요</p>
        <div className="center-box gap-2">
          <TextField
            placeholder="부고검색(고인/상주/장례식장)"
            onChange={onChangeHandler}
            value={searchValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setAppliedSearchValue(searchValue);
              }
            }}
          ></TextField>
          <Button
            className="center-box gap-2"
            onClick={() => {
              setAppliedSearchValue(searchValue);
            }}
          >
            <MagnifyingGlassRegular className="wh-4" />
            <p>검색</p>
          </Button>
        </div>
      </div>
      {appliedearchValue && (
        <Table
          className="w-full"
          columns={columns}
          dataSource={_.flatMapDeep([sellerFeventTableData])}
          pagination={{ position: ['bottomCenter'] }}
          rowKey={'id'}
          bordered
          size={'small'}
        />
      )}
      <SellerShopOrderCreateModal
        openId={createModalOpenId}
        fevent={selectedFevent}
      ></SellerShopOrderCreateModal>
    </div>
  );
};
