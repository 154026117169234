import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Icon } from '@shared/components/icons';
import { makeOptionList } from '@shared/containers/Select/Select';
import { ISelectOption, ISelectProps } from '@shared/interfaces/select.interface';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { Controller } from 'react-hook-form';
import { useId } from 'react-id-generator';

import { BottomPopup } from '../BottomPopup';

/**
 *
 * @param placeholder value가 null일 때 label 값.
 * @param optionList select의 option들.
 * @param control useForm()으로부터 얻을 수 있는 값.
 * @param name register()의 첫 번째 인자로 들어가는 값.
 * @param openId 선택시 종료하기 위한 openID
 * @returns MobileSelect using react-hook-form
 */

const MobileSelect: FC<ISelectProps> = ({
  placeholder,
  optionList,
  control,
  name,
  defaultValue = '',
}: ISelectProps) => {
  const { ChevronDown } = Icon();
  const [id] = useId(1, 'mobileSelect');
  const openId = `mobileSelect-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const options = makeOptionList(optionList);
  const onConfirm = (option: ISelectOption, onChange: (...event: any[]) => void) => {
    // set value in react hook form
    onChange(option.value);
    // close modal
    setOpen(false);
  };

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={(
        { field: { onChange, value, onBlur, ref } }, // value: string;
      ) => (
        <>
          <div
            className="select relative flex cursor-pointer items-center justify-between pr-1 text-sm font-bold theme-bg-1 theme-border-1"
            onClick={() => {
              setOpen(true);
            }}
            ref={ref}
            onBlur={onBlur}
          >
            {value === '' ? (
              <p className="theme-text-6">{placeholder}</p>
            ) : (
              <p>{options.find((option) => option.value === value)?.label}</p>
            )}
            <div>
              <ChevronDown />
            </div>
          </div>
          <BottomPopup openId={openId}>
            <div className="w-screen rounded-t-2xl p-6 theme-text-1 theme-bg-1">
              {/* Title */}
              <h4 className="text-center text-sm font-bold">{placeholder}</h4>
              {/* Item List */}
              <ul className="max-h-64 overflow-y-auto pt-3 pb-4">
                {options.map((option) => (
                  <li
                    key={`${option.value}-${option.label}`}
                    onClick={() => onConfirm(option, onChange)}
                    className={`cursor-pointer border-b py-3 text-center leading-5 theme-border-1 ${
                      option.value === value ? 'text-white theme-bg-main' : ''
                    }`}
                  >
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>
          </BottomPopup>
        </>
      )}
    />
  );
};

export default MobileSelect;
