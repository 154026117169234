import { updateBugoBrand } from '@shared/api/bugoBrand/bugoBrand.controller';
import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { adminCreateUser, adminUpdateUser } from '@shared/api/user/user.controller';
import { LowerRole, UserRole } from '@shared/types';

import {
  BugoBrandAgencyAdminFormInputs,
  BugoBrandAgencyAdminFormInputsEnum,
} from './AdminBugoBrandAgencyAdminForm.interface';

//* bugoBrandAgencyAdmin Upsert 함수
export const onAgencyAdminUpsert = async (
  data: BugoBrandAgencyAdminFormInputs,
  bugoBrand: BugoBrand | BugoBrandData,
  editMode: boolean,
  agencyUserId?: string,
) => {
  //수정
  if (editMode && agencyUserId) {
    const bugoBrandAgencyAdminDto = {
      _id: agencyUserId,
      'info.name': data[BugoBrandAgencyAdminFormInputsEnum.Name],
      'info.theme': data[BugoBrandAgencyAdminFormInputsEnum.Theme],
    };
    const agencyAdminUserUpdated = await adminUpdateUser(bugoBrandAgencyAdminDto);
    return agencyAdminUserUpdated;
  }
  // 생성
  else {
    if (
      data[BugoBrandAgencyAdminFormInputsEnum.UserName] &&
      data[BugoBrandAgencyAdminFormInputsEnum.Password]
    ) {
      const bugoBrandAgencyAdminCreateDto = {
        username: data[BugoBrandAgencyAdminFormInputsEnum.UserName],
        password: data[BugoBrandAgencyAdminFormInputsEnum.Password],
        roles: [UserRole.BugoAgencyAdmin, UserRole.StoreCustomer],
        info: {
          name: data[BugoBrandAgencyAdminFormInputsEnum.Name],
          gender: '',
          birth: '',
          phoneNumber: '',
          theme: data[BugoBrandAgencyAdminFormInputsEnum.Theme],
        },
        bugoBrand: bugoBrand._id,
      };
      //1. 관리자 user생성
      const agencyAdminUserCreated = await adminCreateUser(bugoBrandAgencyAdminCreateDto);
      //2. bugoBrand의 users update
      const bugoBrandUpdate = {
        _id: bugoBrand._id,
        users: [...bugoBrand.users, agencyAdminUserCreated._id],
      };
      const bugoBrandUpdated = await updateBugoBrand(bugoBrandUpdate, LowerRole.Admin);
      return bugoBrandUpdated;
    }
  }
};
