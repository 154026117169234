//* 배송조회 페이지에서만 사용 배송상태와 중요시각을 볼 수 있음

import { FC, useCallback } from 'react';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';
import { DeliveryProgressIndicator } from '@shared/containers/DeliveryProgressIndicator/DeliveryProgressIndicator';
import { DeliveryState } from '@shared/types';
import { formatDate } from '@shared/utils/formatDate';

interface Props {
  shopOrder: ShopOrderForStore;
}

export const OrderDeliveryStateCard: FC<Props> = function ({ shopOrder }) {
  const deliveryTimeDisplay = useCallback((shopOrder: ShopOrderForStore) => {
    if (shopOrder.deliveryDetail.status === DeliveryState.DELIVERED) {
      return (
        <p className="w-full p-2 pt-8 text-center text-22 font-medium">
          {shopOrder.deliveryDetail.deliveredAt &&
            formatDate(shopOrder.deliveryDetail.deliveredAt, {
              dateSeparater: '.',
              timeSeparater: ':',
              contains: {
                year: false,
                day: true,
                hours: true,
                minutes: true,
              },
            })}
          &nbsp; 도착 완료
        </p>
      );
    } else {
      return (
        <p className="w-full p-2 pt-8 text-center text-22 font-medium">
          {formatDate(shopOrder.deliveryDetail.estimatedDeliveredAt, {
            dateSeparater: '.',
            timeSeparater: ':',
            contains: {
              year: false,
              day: true,
              hours: true,
              minutes: true,
            },
          })}
          &nbsp;도착 예정
        </p>
      );
    }
  }, []);

  return (
    <div className="space-y-2 text-18 font-medium ">
      <div className="bg-gray-800 text-white">
        {/* 주요 시각 Dispaly */}
        {deliveryTimeDisplay(shopOrder)}
        {/* 상태 출력 */}
        <DeliveryProgressIndicator order={shopOrder} />{' '}
      </div>
    </div>
  );
};
