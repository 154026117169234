// FeventAddPage에서 장지 관리 버튼을 눌렀을 때 보여지는 장지 관리 모달.
import { FC, useRef, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { TextField } from '@shared/components/TextField';
import { ISelectOption } from '@shared/interfaces/select.interface';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { UseFormSetValue } from 'react-hook-form';

interface IProps {
  setValue: UseFormSetValue<any>;
  name: string;
  optionList: ISelectOption[] | string[];
  openId: string;
}

const CemeteryAdmin: FC<IProps> = ({ name, setValue, optionList, openId }: IProps) => {
  const optionStringList = optionList.map((option) => {
    if (typeof option === 'string') {
      return option;
    } else {
      return option.value;
    }
  });

  const [localOptList, setLocalOptList] = useState<string[]>(optionStringList);
  const [localSelectedOpt, setLocalSelectedOpt] = useState<string | null>(null);
  const textFieldRefs = useRef<(HTMLInputElement | null)[]>([]);

  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const onOptionChange = (index: number) =>
    // local list 변경
    setLocalOptList((curr) => {
      const value = textFieldRefs.current[index]?.value;
      const list = [...curr];
      list[index] = value ?? '';
      return list;
    });

  const onOptionDelete = (index: number) => {
    // 삭제된 것이 localSelectedOpt라면 localSelectedOpt 초기화.
    setLocalSelectedOpt((curr) => (curr === localOptList[index] ? null : curr));
    // local list에서 삭제.
    setLocalOptList((curr) => curr.filter((_, i) => i !== index));
  };

  const onOptionSelect = (index: number) =>
    // localSelectedOpt 변경.
    setLocalSelectedOpt(localOptList[index] ?? null);

  const onOptionAdd = () =>
    // local list에 ""을 추가.
    setLocalOptList((curr) => [...curr, '']);

  const onSave = () => {
    // local list에서 ""을 제거.
    const list = localOptList.filter((opt) => opt !== '');

    // global option list 변경.
    // ex) setGlobalOptionList(list);

    // setSelectedOpt.
    if (localSelectedOpt) setValue(name, localSelectedOpt);

    // close modal
    setOpen(false);
  };

  return (
    <div className="rounded-t-2xl bg-white px-4 py-5 font-medium">
      {/* Header */}
      <div className="border-b border-gray-300 pb-2">
        <h3 className="text-base font-bold">장지 관리</h3>
        <p className="pt-2 text-xs text-gray-500">
          아래에서 자주 사용하실 장지 목록을 설정하실 수 있습니다.
        </p>
      </div>
      {/* Contents */}
      <div className="flex flex-col gap-y-2 py-4">
        {localOptList.map((option, i) => (
          <div key={i} className="grid grid-cols-2 gap-x-4">
            <div>
              <TextField
                className="w-full rounded-md font-normal"
                value={option}
                onChange={() => onOptionChange(i)}
                ref={(el) => (textFieldRefs.current[i] = el)}
              />
            </div>
            <div className="grid grid-cols-2 gap-x-2 py-1">
              <Button
                className="w-full bg-green-500 text-white shadow-sm hover:bg-green-400"
                onClick={() => onOptionSelect(i)}
              >
                &#x2713; 선택
              </Button>
              <Button
                className="w-full border border-red-500 text-red-500 shadow-sm"
                onClick={() => onOptionDelete(i)}
              >
                X 삭제
              </Button>
            </div>
          </div>
        ))}
      </div>
      {/* Footer */}
      <div className="flex justify-between text-white">
        <Button onClick={onOptionAdd} className="bg-blue-600 px-4 py-1 shadow-sm">
          + 추가
        </Button>
        <Button onClick={onSave} className="bg-myApricot px-10 py-1 shadow-sm">
          저장
        </Button>
      </div>
    </div>
  );
};

export default CemeteryAdmin;
