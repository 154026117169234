import { FC } from 'react';

import { Icon } from '@shared/components/icons';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Link } from 'react-router-dom';

export const AgencyMainLinkList: FC = function AgencyMainLinkList() {
  const { ChevronRight, Flower, Modify } = Icon();
  const { bugoBrandId } = useAuth();
  return (
    <div className="flex flex-col gap-3">
      <Link
        to={`/bugo-brand/${bugoBrandId}/bugo-stat`}
        className="px-3 py-2 pb-4 shadow-smd theme-bg-1"
      >
        <div className="grid w-full grid-cols-auto-1fr-auto items-center justify-between">
          <div className="pl-2 pr-4 ">
            <div className="center-box wh-10 rounded-full theme-bg-18">
              <Modify />
            </div>
          </div>
          <p className="bugo-h1 w-full text-start">부고등록 현황</p>
          <div className="center-box wh-10">
            <ChevronRight />
          </div>
          <div></div>
          <p className="noto-sans-kor text-start text-12 font-normal leading-5 theme-text-8">
            부고 등록 현황을 확인합니다
          </p>
        </div>
      </Link>
      <Link
        to={`/bugo-brand/${bugoBrandId}/flower-stat`}
        className="px-3 py-2 pb-4 shadow-smd theme-bg-1"
      >
        <div className="grid w-full grid-cols-auto-1fr-auto items-center justify-between">
          <div className="pl-2 pr-4">
            <div className="center-box wh-10 rounded-full theme-bg-18">
              <Flower />
            </div>
          </div>
          <p className="bugo-h1 w-full text-start">화환판매 현황</p>
          <div className="center-box wh-10">
            <ChevronRight />
          </div>
          <div></div>
          <p className="noto-sans-kor text-start text-12 font-normal leading-5 theme-text-8">
            화환 판매 현황을 확인합니다
          </p>
        </div>
      </Link>
    </div>
  );
};
