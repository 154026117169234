import { forwardRef, InputHTMLAttributes, useRef } from 'react';

import { useId } from 'react-id-generator';
export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassname?: string;
  required?: boolean;
  scrollOnFocus?: boolean;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      required,
      labelClassname,
      className,
      children,
      autoComplete = 'off',
      scrollOnFocus = false,
      ...props
    },
    ref,
  ) => {
    const [id] = useId(1, 'textfield');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const handleClick = () => {
      const containerHeight = window.innerHeight;
      const contentHeight = document.documentElement.scrollHeight;
      const maxScrollY = contentHeight - containerHeight;

      if (inputRef.current && scrollOnFocus) {
        const currentScrollY = window.scrollY;
        const inputTop = inputRef.current.getBoundingClientRect().top;
        const inputTopWithScroll = Math.max(0, inputTop + currentScrollY - 180);

        const handleResize = () => {
          inputRef.current?.scrollIntoView({ behavior: 'smooth' });
        };

        const animateScroll = (timestamp: number) => {
          if (timestamp < start + duration) {
            const progress = (timestamp - start) / duration;
            const scrollTop = Math.round(
              inputTopWithScroll * progress + startScrollY * (1 - progress),
            );
            window.scrollTo(0, scrollTop);
            if (maxScrollY > currentScrollY || inputTopWithScroll < currentScrollY) {
              requestAnimationFrame(animateScroll);
            }
          } else {
            window.removeEventListener('resize', handleResize);
          }
        };

        window.addEventListener('resize', handleResize);

        const duration = 250;
        const start = performance.now();
        const startScrollY = window.scrollY;

        requestAnimationFrame(animateScroll);
      }
    };

    return (
      <div className="label-col">
        {label && <p className={`${labelClassname} label`}>{label}</p>}
        <div className="relative">
          <input
            autoComplete={autoComplete}
            {...props}
            ref={(el) => {
              inputRef.current = el;
              if (typeof ref === 'function') {
                ref(el);
              } else if (ref !== null) {
                ref.current = el;
              }
            }}
            id={id}
            onClick={handleClick}
            className={`${className} ${
              required ? 'border-warn' : 'theme-border-1'
            } textfield theme-text-1 theme-bg-1 placeholder:theme-text-6 `}
          />
          {children}
        </div>
        {/* {helper && <p className="text-sm text-error">{helper}</p>} */}
      </div>
    );
  },
);
