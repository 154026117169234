import { queryString } from '@shared/hooks/recoil-query';
import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import {
  BugoBrand,
  BugoBrandCreate,
  BugoBrandUpdate,
  UserBugoBrand,
} from './bugoBrand.interface';

export const getBugoBrandById = async (bugoBrandId: string) => {
  const { data: bugoBrand } = await api_.get<UserBugoBrand>(
    `/public/bugo-brand/${bugoBrandId}?${queryString({
      populate: [
        {
          path: 'bugoAgency.headerImage',
        },
        {
          path: 'bugoAgency.footerImage',
        },
      ],
    })}`,
  );

  return bugoBrand;
};

export const createBugoBrand = async (
  bugoBrandCreate: BugoBrandCreate,
  bugoRole: LowerRole,
) => {
  const { data: bugoBrand_ } = await api_.post<BugoBrand>(
    `/${bugoRole}/bugo-brand`,
    bugoBrandCreate,
  );
  return bugoBrand_;
};

export const updateBugoBrand = async (
  bugoBrandUpdate: BugoBrandUpdate,
  bugoRole: LowerRole,
) => {
  const { data: bugoBrand } = await api_.patch(
    `/${bugoRole}/bugo-brand/${bugoBrandUpdate._id}`,
    bugoBrandUpdate,
  );
  return bugoBrand;
};
