import { FC, useEffect } from 'react';

import { useRecoilValue } from 'recoil';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { mainColorGenerator } from '@shared/utils/mainColorGenerator';
import { DeltaStatic, Sources } from 'quill/index';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

interface IProps {
  value: string;
  onChange: (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: ReactQuill.UnprivilegedEditor,
  ) => void;
}

const TextEditor: FC<IProps> = ({ value, onChange }: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);

  useEffect(() => {
    const mainColor = mainColorGenerator(customTheme);

    // https://stackoverflow.com/questions/37801882/how-to-change-css-root-color-variables-in-javascript
    document.documentElement.style.setProperty('--main-color', mainColor);
  }, [customTheme]);

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={onChange}
      placeholder="내용을 입력해주세요."
      className="theme-text-1 theme-bg-1"
    />
  );
};

export default TextEditor;
