import { FC, HTMLAttributes, useEffect, useState } from 'react';

import { Loading } from './Loading';
import UnClosableModal from './UnClosableModal';

interface Props extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  title: string;
  type?: string;
  progress?: number;
}

export const LoadingModal: FC<Props> = function LoadingModal({
  open,
  title,
  progress,
  id,
}: Props) {
  const [lodingOpen, setLodingOpen] = useState<boolean>(false);
  // change state after 200ms of processingFevent is true
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (open) {
      timeout = setTimeout(() => {
        setLodingOpen(true);
      }, 200);
    } else {
      setLodingOpen(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [open, setLodingOpen]);

  return (
    <UnClosableModal open={lodingOpen} size={`max-w-max`} id={id}>
      <div className="px-4 text-center font-sans">
        <p className="pb-4 text-lg font-medium">{title}</p>
        <div className="center-box p-2">
          <Loading text="" />
        </div>
      </div>
    </UnClosableModal>
  );
};
