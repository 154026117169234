import React, { useMemo, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { useNavigate } from 'react-router-dom';
import aplusTop from 'src/assets/logo/aplusTop.png';

import { ReactComponent as GoogleLogo } from '/src/assets/svg/GoogleLogo.svg';
import { ReactComponent as KaKaoLogo } from '/src/assets/svg/kakao-svgrepo-com.svg';

import { LoginForm } from '@shared/components/LoginForm';
import Tabs from '@shared/components/Tabs';

import { GuestLoginForm } from '../containers/GuestLoginForm/GuestLoginForm';

//! pseudocode
/**
 * @components
 * @shared
 *! - Input(TextField)
 *! - Button
 * @structure
 *! - Logo
 *! - Title
 *! - SubmitForm
 *! |- InputSet
 *! ||- Id
 *! ||- Password
 *! |- ButtonSet
 *! ||- PersistCheckBox
 *! ||- SubmitButton
 *! ||- FindIdButton
 *! ||- SignupButton
 */

const StoreLoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  //* 로그인후 가야할 url
  const redirectUrlAfterStoreLogin = sessionStorage.getItem('redirectUrlAfterStoreLogin');

  const [selectedTab, setSelectedTab] = useState(0);

  const userLoginForm = useMemo(() => {
    return (
      <div className="py-4 px-3">
        <div>
          {/* SubmitForm */}
          <LoginForm redirectUrl={redirectUrlAfterStoreLogin ?? '/flower-store'} />
          <div className="inline-flex w-full items-center justify-center">
            <hr className="my-6 h-px w-full border-0 bg-[#D1D5DB]" />
            <span className="absolute left-1/2 -translate-x-1/2 px-3 text-12 theme-text-8 theme-bg-JM05">
              회원이 아니세요?
            </span>
          </div>
          {/* SignupButton */}
          <BugoBasicButton
            onClick={() => {
              navigate('/flower-store/signup');
            }}
            className="rounded-lg"
            colorTheme="default"
            text="회원가입"
          />
          <div className="inline-flex w-full items-center justify-center">
            <hr className="my-6 h-px w-full border-0 bg-[#D1D5DB]" />
            <span className="absolute left-1/2 -translate-x-1/2 px-3 text-12 theme-text-8 theme-bg-JM05">
              간편로그인
            </span>
          </div>
          <BugoBasicButton
            onClick={() =>
              loginWithRedirect({
                redirectUri: `${window.location.origin}/flower-store`,
              })
            }
            className="relative rounded-lg"
            colorTheme="default"
          >
            <GoogleLogo className="absolute left-3.5 h-5 w-5" />
            <KaKaoLogo className="absolute left-10 h-5 w-5 text-kakao-brown" />
            {window.innerWidth > 320 ? (
              <span>간편로그인으로 시작하기</span>
            ) : (
              <span>간편로그인</span>
            )}
          </BugoBasicButton>
        </div>
      </div>
    );
  }, [loginWithRedirect, navigate, redirectUrlAfterStoreLogin]);

  const selectedContent = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return userLoginForm;
      case 1:
        return (
          <GuestLoginForm redirectUrl={redirectUrlAfterStoreLogin ?? '/flower-store'} />
        );
    }
  }, [redirectUrlAfterStoreLogin, selectedTab, userLoginForm]);

  return (
    <div className="pt-10">
      {/* Logo */}
      <div className="grid place-items-center">
        <img src={aplusTop} className="h-[60px]"></img>
      </div>
      {/* Title */}
      <div className="flex w-full justify-center">
        <div className="mx-6 mt-8 w-full max-w-md p-4 shadow-smd theme-bg-JM05">
          <Tabs
            items={['회원', '비회원 주문조회']}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <div className="border-t border-t-gray-200">{selectedContent}</div>
        </div>
      </div>
    </div>
  );
};

export { StoreLoginPage };
