//* 로그인, 회원가입 등 계정 관련 Page에서 사용되는 기본적인 Input
import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { api_ } from '@shared/plugIn/axios';
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

// tailwindcss classname을 inline으로 사용해 JSX 부분의 가독성이 떨어지는것 방지
const classNames = {
  signupInput:
    'relative mt-1 h-11 w-full rounded-l-lg border border-gray-300 py-3 pl-4 text-16 font-bold theme-bg-1 focus:theme-border-main sm:text-sm',
};

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputRegisterName: string;
  confirmRegisterName: string;
  text?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  inputClassName?: string;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
}

const AccountConfirmInput: React.FC<InputProps> = ({
  inputRegisterName,
  confirmRegisterName,
  text,
  type,
  placeholder = '',
  className = '',
  inputClassName,
  setValue,
  getValues,
  register,
  control,
  ...props
}: InputProps) => {
  // tailwindcss classname
  const { signupInput } = classNames;

  const [isPossible, setIsPossible] = useState<boolean>(false);
  const [possibleName, setPossibleName] = useState<string>('');

  const username = useWatch({ control, name: inputRegisterName });

  useEffect(() => {
    if (username !== possibleName) {
      setValue(confirmRegisterName, false);
      setIsPossible(false);
    }
  }, [username, possibleName, setValue, confirmRegisterName]);

  const onClickHandler: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      event.preventDefault();
      if (!username) return;

      try {
        const { data: isPossibleData } = await api_.post<boolean>(
          'auth/signup/possible',
          {
            username,
          },
        );
        setPossibleName(username);
        setIsPossible(isPossibleData);
        setValue(confirmRegisterName, isPossibleData);
      } catch (err) {
        console.error(err);
      }
    },
    [confirmRegisterName, setValue, username],
  );

  const confirmButtonRender = useMemo(() => {
    if (isPossible) {
      return (
        <div className="h-11 w-32 flex-initial py-1">
          <BugoBasicButton
            className="h-11 w-full rounded-r-lg bg-preed text-14"
            text="가입가능"
            disabled
          />
        </div>
      );
    } else {
      return (
        <div className="h-11 w-32 flex-initial py-1">
          <BugoBasicButton
            className="h-11 w-full rounded-r-lg text-14"
            colorTheme="secondary"
            text="중복확인"
            onClick={onClickHandler}
          />
        </div>
      );
    }
  }, [isPossible, onClickHandler]);

  return (
    <div className={className}>
      <div className="flex">
        <input
          type={type}
          className={`${signupInput} ${inputClassName}`}
          placeholder={placeholder}
          {...props}
          {...register(inputRegisterName)}
        />
        {confirmButtonRender}
      </div>
    </div>
  );
};

export { AccountConfirmInput };
