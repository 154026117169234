import { useMemo, useState } from 'react';

import Footer from '@shared/components/Footer';
import Tabs from '@shared/components/Tabs';
import {
  privacyPolicyText,
  purchasePolicyText,
  standardTermsText,
} from '@shared/utils/textData';
import { useSearchParams } from 'react-router-dom';

import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

export const ContentTermsPage = function ContentTermsPage() {
  useStoreTitleHook('이용약관 및 정보처리 방침');

  const [searchParams] = useSearchParams();
  const title = searchParams.get('title');

  const defaultTab = () => {
    switch (title) {
      case 'use':
        return 0;
      case 'privacy':
        return 1;
      case 'delivery':
        return 2;
      default:
        return 0;
    }
  };

  const tabItems = ['이용약관', '개인정보 처리방침', '배송 및 환불규정'];
  const [selectedTab, setSelectedTab] = useState(defaultTab());

  const tabContent = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return <div className="text-11">{standardTermsText}</div>;
      case 1:
        return <div className="text-11">{privacyPolicyText}</div>;
      case 2:
        return <div className="text-sm">{purchasePolicyText}</div>;
      default:
        return <div className="text-11">{standardTermsText}</div>;
    }
  }, [selectedTab]);

  return (
    <div className="">
      <section className="font-medium">
        <div className="space-y-2 md:space-y-4">
          <div className="p-4">
            <Tabs
              items={tabItems}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              className="bg-none"
            ></Tabs>
            <div className="h-screen-15 overflow-y-scroll whitespace-pre-line border p-4 theme-bg-1 theme-border-main ">
              {tabContent}
            </div>
          </div>
          <Footer />
        </div>
      </section>
    </div>
  );
};
