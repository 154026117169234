import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoBrandListHookUrl } from '@shared/api/bugoBrand/bugoBrand.hook';
import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { adminUpdateUser } from '@shared/api/user/user.controller';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import ConfirmModal from '@shared/components/ConfirmModal';
import DismissModal from '@shared/components/DismissModal';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { Table, TableColumnsType } from 'antd';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';
import { useMutation, useQueryClient } from 'react-query';

import { AdminAgencyWorkerUpsertModal } from './AdminAgencyWorkerUpsertModal';

interface Props {
  workerList: User[];
  bugoBrand: BugoBrandData | BugoBrand;
}

type WorkerTableData = {
  id: string;
  index: number;
  name: string;
  user: User;
  isDeleted: boolean;
  phoneNumber: string;
  teamType: string;
  teamName: string;
  region: string;
};

export const AgencyWorkerTable: FC<Props> = function AgencyWorkerTable({
  workerList,
  bugoBrand,
}: Props) {
  const editOpenId = 'agencyWorkerTable-edit-worker';
  const deleteOpenId = 'agencyWorkerTable-delete-worker';
  const recoverOpenId = 'agencyWorkerTable-recover-worker';
  const setEditOpen = useSetRecoilState(simpleOpenAtomFamily(editOpenId));
  const setDeleteOpen = useSetRecoilState(simpleOpenAtomFamily(deleteOpenId));
  const setRecoverOpen = useSetRecoilState(simpleOpenAtomFamily(recoverOpenId));
  const [selectWorker, setSelectWorker] = useState<User | undefined>(undefined);

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();
  const { mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      notificateSuccess(notifiacationInstance, '완료');
      queryClient.invalidateQueries(bugoBrandListHookUrl);
    },
    onError: () => {
      notificateError(notifiacationInstance, '실패');
    },
  });
  const [searchValue, setSearchValue] = useState<string>('');

  const columns: TableColumnsType<WorkerTableData> = [
    {
      width: '5%',
      title: <div className="break-keep">index</div>,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (index: number) => {
        return <p className="break-keep text-xs">{index}</p>;
      },
    },
    {
      title: '성함',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '휴대전화번호',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
    },
    {
      title: '팀타입',
      dataIndex: 'teamType',
      key: 'teamType',
      align: 'center',
    },
    {
      title: '소속팀',
      dataIndex: 'teamName',
      key: 'teamName',
      align: 'center',
      render: (teamName: string) => {
        return <p className="font-medium">{teamName}</p>;
      },
    },
    {
      title: '소속지역',
      dataIndex: 'region',
      key: 'region',
      align: 'center',
      render: (region: string) => {
        return <p className="font-medium">{region}</p>;
      },
    },
    {
      title: '활성화',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      align: 'center',
      render: (isDeleted: boolean) => {
        return (
          <p className={`${isDeleted ? 'font-bold theme-text-warn-03' : ''}`}>
            {isDeleted ? '삭제됨' : '활성'}
          </p>
        );
      },
    },
    {
      title: '상세기능',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (user: User) => {
        return (
          <div className="center-box gap-2">
            <Button
              className="shadow-sm"
              onClick={() => {
                setSelectWorker(user);
                setEditOpen(true);
              }}
            >
              수정
            </Button>
            <Button
              className="shadow-sm"
              onClick={() => {
                setSelectWorker(user);
                if (user.isDeleted) {
                  setRecoverOpen(true);
                } else {
                  setDeleteOpen(true);
                }
              }}
            >
              {user.isDeleted ? '복원' : '삭제'}
            </Button>
          </div>
        );
      },
    },
  ];

  const searchedValue = useMemo(() => {
    const searcher = new FuzzySearch(workerList, [
      'info.name',
      'info.phoneNumber',
      'bugoAgencyWorkerDetail.region',
      'bugoAgencyWorkerDetail.teamType',
      'bugoAgencyWorkerDetail.teamName',
    ]);
    return searcher.search(searchValue);
  }, [workerList, searchValue]);
  const tableData: WorkerTableData[] = useMemo(() => {
    return _.map(searchedValue as User[] | null, (worker, index) => {
      return {
        id: worker._id,
        index: index + 1,
        user: worker,
        name: worker.info.name ?? '',
        isDeleted: worker.isDeleted ?? false,
        phoneNumber: worker.info.phoneNumber ?? '',
        teamType: worker.bugoAgencyWorkerDetail?.teamType ?? '',
        teamName: worker.bugoAgencyWorkerDetail?.teamName ?? '',
        region: worker.bugoAgencyWorkerDetail?.region ?? '',
      };
    });
  }, [searchedValue]);

  const onDeleteWorker = async (worker: User) => {
    const deleteUser = {
      _id: worker._id,
      isDeleted: true,
    };
    await adminUpdateUser(deleteUser);
  };

  const onRecoverWorker = async (worker: User) => {
    const recoverUser = {
      _id: worker._id,
      isDeleted: false,
    };
    await adminUpdateUser(recoverUser);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };
  return (
    <div className="space-y-4">
      <TextField
        className="w-1/2 border-gray-500"
        type={'search'}
        onChange={onChangeHandler}
        placeholder="검색 상조 지도사명"
      />
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 15,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
      <AdminAgencyWorkerUpsertModal
        openId={editOpenId}
        bugoBrand={bugoBrand}
        agencyWorkerUser={selectWorker}
      />
      <DismissModal
        openId={deleteOpenId}
        onClick={() => {
          if (selectWorker) {
            mutate(onDeleteWorker(selectWorker));
          } else {
            alert('선택된 지도사님이 존재하지 않습니다.');
          }
        }}
        buttonTitle={'삭제'}
        title={'지도사 삭제'}
        subtitle={`${selectWorker?.info.name}님을 삭제합니다.`}
      ></DismissModal>
      <ConfirmModal
        openId={recoverOpenId}
        onClick={() => {
          if (selectWorker) {
            mutate(onRecoverWorker(selectWorker));
          } else {
            alert('선택된 지도사님이 존재하지 않습니다.');
          }
        }}
        title="지도사 복원"
        buttonTitle="복원"
        subtitle={`${selectWorker?.info.name}님을 복원합니다.`}
      ></ConfirmModal>
    </div>
  );
};
