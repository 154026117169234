import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { UserBugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { workerUserByBugoBrandLightHookUrl } from '@shared/api/user/user.hook';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import { Label } from '@shared/components/Label';
import { LoadingModal } from '@shared/components/LoadingModal';
import TelField from '@shared/components/TelField';
import { TextField } from '@shared/components/TextField';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import {
  BugoBrandAgencyWorkerFormInputs,
  BugoBrandAgencyWorkerFormInputsEnum,
} from './AgencyWorkerUpsertForm.interface';
import { onAgencyWorkerUpsert } from './AgencyWorkerUpsertForm.util';

interface Props {
  bugoBrand: UserBugoBrand;
  agencyWorkerUser?: User;
  editMode?: boolean;
}

export const BugoBrandAgencyWorkerForm: FC<Props> = function BugoBrandAgencyWorkerForm({
  bugoBrand,
  agencyWorkerUser,
  editMode,
}: Props) {
  const { bugoRole } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      notificateSuccess(notifiacationInstance, '저장 완료');
      queryClient.invalidateQueries(
        workerUserByBugoBrandLightHookUrl(bugoRole(), bugoBrand._id),
      );
    },
    onError: () => {
      notificateError(notifiacationInstance, '저장 실패');
    },
  });
  const { register, handleSubmit, control } = useForm<BugoBrandAgencyWorkerFormInputs>({
    defaultValues: {
      name: agencyWorkerUser?.info.name,
      phoneNumber: agencyWorkerUser?.info.phoneNumber,
      teamName: agencyWorkerUser?.bugoAgencyWorkerDetail?.teamName,
      teamType: agencyWorkerUser?.bugoAgencyWorkerDetail?.teamType,
      region: agencyWorkerUser?.bugoAgencyWorkerDetail?.region,
    },
  });

  const regionList = bugoBrand.bugoAgency?.regions ?? [''];
  const teamTypeList = bugoBrand.bugoAgency?.teamTypes ?? [''];
  const teamNameList = bugoBrand.bugoAgency?.teamNames ?? [''];

  const phoneNumber = useWatch({
    control,
    name: BugoBrandAgencyWorkerFormInputsEnum.PhoneNumber,
    defaultValue: agencyWorkerUser?.info.phoneNumber,
  });

  const onSubmit: SubmitHandler<BugoBrandAgencyWorkerFormInputs> = async (data) => {
    try {
      const ret = await onAgencyWorkerUpsert(
        data,
        bugoBrand,
        editMode ?? false,
        agencyWorkerUser?._id,
      );
      console.log(ret);
    } catch (err: any) {
      console.error(err);
    }
  };

  return (
    <>
      <form
        className="space-y-4"
        onSubmit={(e) => {
          mutate(handleSubmit(onSubmit)(e));
        }}
      >
        <div className="grid grid-cols-2 gap-4">
          {editMode ? (
            <div className="flex h-full gap-4 font-bold">
              <Label>계정</Label>
              <p>{agencyWorkerUser?.username}</p>
            </div>
          ) : (
            <TextField
              {...register(BugoBrandAgencyWorkerFormInputsEnum.UserName)}
              label="아이디"
              placeholder="아이디"
              className="w-full"
            />
          )}
          {editMode ? (
            <div></div>
          ) : (
            <TextField
              className="w-full"
              {...register(BugoBrandAgencyWorkerFormInputsEnum.Password)}
              label="비밀번호"
              placeholder="비밀번호"
            />
          )}
          <TextField
            {...register(BugoBrandAgencyWorkerFormInputsEnum.Name)}
            label="이름"
            placeholder="이름"
            className="w-full"
          />
          <div className="label-col">
            <Label>휴대전화번호</Label>
            <TelField
              value={phoneNumber}
              register={register}
              name={BugoBrandAgencyWorkerFormInputsEnum.PhoneNumber}
              className="w-full"
            />
          </div>
          <div className="label-col">
            <Label>지역</Label>
            <Select
              placeholder="지역"
              optionList={regionList}
              control={control}
              name={BugoBrandAgencyWorkerFormInputsEnum.Region}
            ></Select>
          </div>
          <div className="label-col">
            <Label>팀타입</Label>
            <Select
              placeholder="팀타입"
              optionList={teamTypeList}
              control={control}
              name={BugoBrandAgencyWorkerFormInputsEnum.TeamType}
            ></Select>
          </div>
          <div className="label-col">
            <Label>팀이름</Label>
            <Select
              placeholder="팀이름"
              optionList={teamNameList}
              control={control}
              name={BugoBrandAgencyWorkerFormInputsEnum.TeamName}
            ></Select>
          </div>
        </div>
        <div className="pt-4">
          <Button
            disabled={isLoading}
            className="button-rectangle h-10 rounded-md theme-text-3 theme-bg-main"
            type="submit"
          >
            저장
          </Button>
        </div>
      </form>
      <LoadingModal open={isLoading} title={'저장중'} />
    </>
  );
};
