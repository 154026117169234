import { FC } from 'react';

import { Image } from '@shared/api/media/media.interface';
import { ShopItem } from '@shared/api/shopItem/shopItem.interface';
import { ShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.interface';
import { Button } from '@shared/components/Button';
import { useNavigate } from 'react-router-dom';

interface Props {
  shopItem: ShopItem<ShopItemInfo<string, Image>, string, string, Image>;
  bugoId: string;
}

export const EasyBuyShopItemCard: FC<Props> = function ({ shopItem, bugoId }: Props) {
  const navigate = useNavigate();

  return (
    <div
      className="flex w-full cursor-pointer flex-col items-center justify-end"
      onClick={() => {
        navigate(`/flower-store/item/${shopItem._id}/checkout?bugoId=${bugoId}`);
      }}
    >
      <div className="aspect-w-10 aspect-h-10 w-full overflow-hidden">
        <img
          src={`${process.env.REACT_APP_BUCKET_URL}/w400/${
            shopItem.image?.url ?? shopItem.shopItemInfo?.image?.url
          }`}
          alt="상품이미지"
          className="object-cover"
        />
      </div>
      <div className="text-center">
        <div className="">
          <p className="break-keep text-14 font-bold ">
            {(shopItem.name ?? shopItem.shopItemInfo.name).indexOf('[프리미엄]') !== -1
              ? (shopItem.name ?? shopItem.shopItemInfo.name)
                  .split(']')
                  .reduce((accu, prev) => {
                    if (accu === '') {
                      return prev + ']';
                    } else {
                      return accu + ' ' + prev;
                    }
                  }, '')
              : shopItem.name ?? shopItem.shopItemInfo.name}
          </p>
        </div>
        <div>
          <p className="text-16 font-bold text-black">
            {shopItem.priceRetail.toLocaleString()}원
          </p>
        </div>
      </div>
      <div className="pt-2">
        <Button className="h-7 rounded-none border-0 px-3.5 text-white theme-bg-main">
          주문하기
        </Button>
      </div>
    </div>
  );
};
