import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import { ShopCreate, ShopUpdate } from './shop.interface';

export const createShop = async (shop: ShopCreate, isAdmin: boolean) => {
  try {
    const role = isAdmin ? LowerRole.Admin : LowerRole.StoreSeller;
    const { data: shop_ } = await api_.post(`${role}/shop`, shop);
    return shop_;
  } catch (err: any) {
    return null;
  }
};

export const closeShop = async (shopId: string, role: LowerRole) => {
  try {
    //TODO: 추후 default shop을 추가하여 default shop은 영업종료 못하게해야함
    const { data: shop_ } = await api_.patch(`${role}/shop/${shopId}`, {
      isEnabled: false,
    });
    return shop_;
  } catch (err: any) {
    console.log('close shop error', err?.response?.data);
  }
};

export const openShop = async (shopId: string, role: LowerRole) => {
  try {
    const { data: shop_ } = await api_.patch(`${role}/shop/${shopId}`, {
      isEnabled: true,
    });
    return shop_;
  } catch (err: any) {
    console.log('close shop error', err?.response?.data);
  }
};

// admin 전용
export const updateShop = async (shop: ShopUpdate, role: LowerRole) => {
  if (role !== LowerRole.Admin) return null;
  try {
    const { data: shop_ } = await api_.patch(`admin/shop`, shop);
    return shop_;
  } catch (error) {
    console.log(error);
  }
};
