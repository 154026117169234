import { FC, HTMLAttributes, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { MyTimeKeeper } from '@shared/components/TimeKeeper';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { CustomCallback } from '@shared/types';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  time: Date | null;
  onTimeChange: CustomCallback<Date, void>;
  openId: string;
}

//*Date를 timeIn string으로 변환
const getTimeInFromDate = (time: Date | null) => {
  if (time) {
    const minuteString =
      time.getMinutes() < 10 ? `0${time.getMinutes()}` : String(time.getMinutes());

    return `${time.getHours()}:${minuteString}`;
  } else {
    //undefined일때 12:30처럼 동작함
    return '12:30';
  }
};

//*TimeKeeper 를 통해 나온 timeout String을 통해 setTime함
const formatTimetoDate = (timeOut: string | undefined) => {
  if (timeOut) {
    const hourNum = Number(timeOut.split(':')[0]);
    const minutesNum = Number(timeOut.split(':')[1]);
    const date = new Date();
    date.setHours(hourNum);
    date.setMinutes(minutesNum);
    return date;
  }
};

//* FeventTimePickerModal
/**
 * time은 초기값 만들어주기 위함
 * onTimeChange (setValue들어간) 필수
 *
 * @param param0
 * @returns
 */
const TimePickerModal: FC<IProps> = ({
  time,
  openId,
  placeholder,
  onTimeChange,
}: IProps) => {
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const [timeString, setTimeString] = useState<string | undefined>(
    getTimeInFromDate(time),
  );

  const onConfirmClick = () => {
    // Set Value
    const date = formatTimetoDate(timeString);
    if (date) {
      onTimeChange(date);
    }
    // Close Modal
    setOpen(false);
    history.back();
  };

  return (
    <div className="rounded-t-2xl p-6 text-sm theme-text-1 theme-bg-1">
      {/* Top */}
      <h4 className="bugo-h1 text-center">{placeholder}</h4>
      {/* Main Content */}
      <div className="center-box py-4">
        <MyTimeKeeper time={timeString} setTime={setTimeString} />
      </div>
      {/* Confirm Button */}
      <div className="pb-4">
        <Button
          onClick={onConfirmClick}
          className="button-rectangle text-white theme-bg-main"
        >
          확인
        </Button>
      </div>
    </div>
  );
};

export default TimePickerModal;
