import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { shopOrderListForSellerByShopStateSelectorFamily } from '@shared/api/shopOrder/shopOrder.selector';
import { ErrorFallback } from '@shared/components/ErrorFallback';
import { Loading } from '@shared/components/Loading';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';
import FuzzySearch from 'fuzzy-search';
import { ErrorBoundary } from 'react-error-boundary';
import { useParams } from 'react-router-dom';

import { DeliveryManageContent } from '../containers/DeliveryManageContent/DeliveryManageContent';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

const SEARCH_TEXT_ID = 'sellerDeliveryManagePage';

export const SellerDeliveryManagePage: FC = function SellerDeliveryManagePage() {
  const { shopId } = useParams();
  useSellerTopNavHook('주문배송관리', SEARCH_TEXT_ID);

  const searchText = useRecoilValue(searchTextAtomFamily(SEARCH_TEXT_ID));

  const shopOrderListState = useRecoilValue(
    shopOrderListForSellerByShopStateSelectorFamily(shopId ?? 'undefined'),
  );

  // 상품이름 주소 보내는분 받으시는 분 보내신 분
  const searchedList = useMemo(() => {
    if (shopOrderListState.status === 'success' && shopOrderListState.data) {
      const searcher = new FuzzySearch(shopOrderListState.data, [
        'orderDetail.shopItem.name',
        'orderDetail.shopItem.shopItemInfo.name',
        'deliveryDetail.receiverAddress',
        'deliveryDetail.senderName',
        'deliveryDetail.receiverName',
        'bugoDetail.senderPhrase',
      ]);
      return searcher.search(searchText);
    }
  }, [searchText, shopOrderListState.data, shopOrderListState.status]);

  const deliveryManageBoxRender = useMemo(() => {
    if (searchedList) {
      return <DeliveryManageContent shopOrderList={searchedList} />;
    } else return <Loading></Loading>;
  }, [searchedList]);

  return (
    <div className="mx-auto max-w-6xl px-4 pb-8 sm:px-6 lg:px-8">
      <div className="mt-8 space-y-4">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {deliveryManageBoxRender}
        </ErrorBoundary>
      </div>
    </div>
  );
};
