/**
 * ShopItem을 Insert(생성) 또는 Update를 하기 위한 Modal
 */

import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Image } from '@shared/api/media/media.interface';
import { Rebate } from '@shared/api/rebate/rebate.interface';
import { createShopItem, updateShopItem } from '@shared/api/shopItem/shopItem.controller';
import { sellerShopItemListByShopUrl } from '@shared/api/shopItem/shopItem.hook';
import { ShopItem } from '@shared/api/shopItem/shopItem.interface';
import { ShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.interface';
import { shopItemInfoListByShopStateSelectorFamily } from '@shared/api/shopItemInfo/shopItemInfo.selector';
import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import { TextField } from '@shared/components/TextField';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { api_ } from '@shared/plugIn/axios';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UpsertMode } from '@shared/types';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';

interface Props {
  openId: string;
  mode: UpsertMode;
  shopItemInfo?: ShopItemInfo<string, Image, Image>;
  shopItem?: ShopItem<ShopItemInfo, string, Rebate>;
}
export const ShopItemUpsertModal: FC<Props> = function ShopItemUpsertModal({
  openId,
  mode,
  shopItem,
  shopItemInfo,
}: Props) {
  const { shopId } = useParams();
  const { sellerRole } = useAuth();

  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const shopItemInfoState = useRecoilValue(
    shopItemInfoListByShopStateSelectorFamily(`${shopId}`),
  );
  const [rebateList, setRebateList] = useState<Rebate[]>([]);

  const shopItemInfoList = useMemo(() => {
    if (shopItemInfoState.status === 'success' && shopItemInfoState.data) {
      const shopItemInfoList = shopItemInfoState.data;
      return shopItemInfoList;
    }
  }, [shopItemInfoState.data, shopItemInfoState.status]);

  useAsync(async () => {
    const { data: rebateList } = await api_.get<Rebate[]>('admin/rebate');
    if (rebateList) {
      setRebateList(rebateList);
    }
  }, []);

  const { register, control, handleSubmit } = useForm({
    defaultValues: {
      _id: shopItem?._id,
      shop: shopItem?.shop ?? shopItemInfo?.shop ?? shopId,
      shopItemInfo: shopItem?.shopItemInfo._id ?? shopItemInfo?._id,
      rebate: shopItem?.rebate?._id,
      priceRetailShow: shopItem?.priceRetailShow,
      priceRetail: shopItem?.priceRetail,
      priceRebate: shopItem?.priceRebate,
      priceRebateAgency: shopItem?.priceRebateAgency,
      priceRebateWorker: shopItem?.priceRebateWorker,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      if (mode === UpsertMode.Insert) {
        const shopItemCreate = await createShopItem(data, sellerRole());
        queryClient.invalidateQueries(
          sellerShopItemListByShopUrl(`${shopId}`, sellerRole()),
        );
        console.log('소매상품등록 성공', shopItemCreate);
        notificateSuccess(notifiacationInstance, '상품추가 성공!');
        setOpen(false);
      } else {
        const shopItemCreate = await updateShopItem(data, sellerRole());
        queryClient.invalidateQueries(
          sellerShopItemListByShopUrl(`${shopId}`, sellerRole()),
        );
        console.log('소매상품수정 성공', shopItemCreate);
        notificateSuccess(notifiacationInstance, '상품수정 성공!');
        setOpen(false);
      }
    } catch (err: any) {
      notificateError(notifiacationInstance, '상품추가 실패!');
      console.error(err);
    }
  };

  return (
    <Modal openId={openId} className="space-y-2 font-sans">
      <h1 className="bugo-h1">
        {mode === UpsertMode.Insert ? '소매상품등록' : '소매상품수정'}
      </h1>
      <div className="label-col">
        <label>도매상품선택</label>
        <Select
          className="textfield-sm w-full"
          optionList={
            shopItemInfoList?.map((shopItemInfo) => {
              return {
                value: shopItemInfo._id,
                label: shopItemInfo.name,
              };
            }) ?? []
          }
          control={control}
          name="shopItemInfo"
          placeholder="도매상품"
        ></Select>
      </div>
      <div className="label-col">
        <label>리베이트선택</label>
        <Select
          className="textfield-sm w-full"
          optionList={
            rebateList?.map((rebate) => {
              return {
                value: rebate._id,
                label: rebate.name,
              };
            }) ?? []
          }
          control={control}
          name="rebate"
          placeholder="리베이트"
        ></Select>
      </div>
      <TextField
        className="textfield-sm w-full"
        labelClassname="label-sm"
        label="정가"
        {...register('priceRetailShow')}
      />
      <TextField
        className="textfield-sm w-full"
        labelClassname="label-sm"
        label="할인판매가"
        {...register('priceRetail')}
      />
      <div className="label-col">
        <p className="label-sm">도매가</p>
        <p>{shopItemInfo?.priceWhole?.toLocaleString()}원</p>
      </div>
      <TextField
        className="textfield-sm w-full"
        labelClassname="label-sm"
        label="리베이트"
        {...register('priceRebate')}
      />
      <TextField
        className="textfield-sm w-full"
        labelClassname="label-sm"
        label="리베이트상조"
        {...register('priceRebateAgency')}
      />
      <TextField
        className="textfield-sm w-full"
        labelClassname="label-sm"
        label="리베이트지도사"
        {...register('priceRebateWorker')}
      />

      <div className="pt-4">
        <Button
          className="button-rectangle h-10 rounded-md theme-text-3 theme-bg-main"
          onClick={handleSubmit(onSubmit)}
        >
          {mode === UpsertMode.Insert ? '등록' : '수정'}
        </Button>
      </div>
    </Modal>
  );
};
