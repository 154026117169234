import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { cancelShopOrder } from '@shared/api/shopOrder/shopOrder.controller';
import { shopOrderListForSellerByShopUrl } from '@shared/api/shopOrder/shopOrder.hook';
import {
  ShopOrderCancel,
  ShopOrderForSeller,
} from '@shared/api/shopOrder/shopOrder.interface';
import Modal from '@shared/components/Modal';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

interface Props {
  openId: string;
  shopOrder: ShopOrderForSeller;
}

export const OrderCanceldModal: FC<Props> = function OrderCanceldModal({
  openId,
  shopOrder,
}: Props) {
  const { sellerRole } = useAuth();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const onCloseModal = () => {
    setOpen(false);
    history.back();
  };

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const onCancelClick = async (data: any) => {
    const deliveredShopOrder: ShopOrderCancel = {
      _id: shopOrder._id,
      cancelReason: data.cancelReason ?? '',
    };
    try {
      await cancelShopOrder(deliveredShopOrder, sellerRole());
      await queryClient.invalidateQueries(
        shopOrderListForSellerByShopUrl(shopOrder.shop, sellerRole()),
      );
      notificateSuccess(notifiacationInstance, '상태변경에 성공하였습니다.');
      onCloseModal();
    } catch (err: any) {
      notificateError(notifiacationInstance, '실패 ');
    }
  };

  return (
    <Modal openId={openId}>
      <div>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-1.5 sm:ml-4 sm:text-left">
            <Dialog.Title as="h3" className="text-xl font-medium leading-6 text-gray-900">
              주문 반려(취소)
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-base font-medium text-gray-500">주문을 취소합니다</p>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <TextField
            className="textfield-sm"
            label="반려사유"
            placeholder="주문 반려 사유"
            {...register('cancelReason', {
              required: { value: true, message: '반려 사유를 작성해주세요' },
            })}
          ></TextField>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit(onCancelClick)}
          >
            주문 반려
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={onCloseModal}
          >
            취소
          </button>
        </div>
      </div>
    </Modal>
  );
};
