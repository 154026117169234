import { HTMLAttributes } from 'react';

import { useRecoilValue } from 'recoil';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { Modal } from 'antd';

interface Props extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  size?: string;
  noPadding?: boolean;
  position?: string;
}

export default function UnClosableModal({ open, children, size }: Props) {
  const customTheme = useRecoilValue(customThemeAtom);

  return (
    <Modal
      open={open}
      centered
      closable={false}
      footer={null}
      className={`${customTheme} ${size}`}
      maskStyle={{
        height: '100vh',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transitionProperty: 'opacity',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
        transitionDuration: '150ms',
        backgroundColor: 'rgb(107 114 128)',
        opacity: 0.75,
      }}
      maskTransitionName=""
      transitionName=""
    >
      {children}
    </Modal>
  );
}
