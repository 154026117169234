import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { deleteFevent } from '@shared/api/fevent/fevent.controller';
import { activeFeventListForWorkerHookUrl } from '@shared/api/fevent/fevent.hook';
import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { Button } from '@shared/components/Button';
import DismissModal from '@shared/components/DismissModal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { BugoBrandAgencyInfo } from '../containers/AdminFeventInfo/BugoBrandAgencyInfo';
import { DeceasedInfo } from '../containers/AdminFeventInfo/DeceasedInfo';
import { useTitleHook } from '../hooks/useTitleHook';

export const FeventAdminInfoPage: FC = function FeventAdminInfoPage() {
  useTitleHook('관리자용 부고정보');
  const { bugoRole, isAdmin } = useAuth();
  const { feventId } = useParams();
  const feventId_ = `${feventId}`;
  const feventState = useRecoilValue(feventStateSelectorFamily(feventId ?? 'undefined'));
  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const navigate = useNavigate();

  //! 표시해야할 것들
  // 상조회사, 지도사성함 o.k
  // 고인 정보 및 상주 정보 o.k
  // 전화번호 및 발송 정보 o.k

  const deleteOpenId = `feventAdminInfoPage-${feventId}`;
  const setDeleteOpen = useSetRecoilState(simpleOpenAtomFamily(deleteOpenId));

  const render = useMemo(() => {
    if (feventState.status === 'success' && feventState.data) {
      const fevent = feventState.data;
      return (
        <div className="space-y-1">
          <BugoBrandAgencyInfo fevent={fevent} />
          <div className="grid grid-cols-auto-1fr gap-x-4 gap-y-1">
            <DeceasedInfo fevent={fevent} />
          </div>

          <div className="pt-4">
            <Button
              className="button-rectangle h-10 bg-warn theme-text-4"
              onClick={() => {
                setDeleteOpen(true);
              }}
            >
              삭제
            </Button>
          </div>
        </div>
      );
    }
  }, [feventState.data, feventState.status, setDeleteOpen]);

  const deleteFeventFunc = async () => {
    try {
      await deleteFevent(feventId_, bugoRole());
      queryClient.invalidateQueries(
        activeFeventListForWorkerHookUrl(bugoRole(), isAdmin()),
      );
      notificateSuccess(notifiacationInstance, '삭제 완료');
      navigate('/fevent/manage');
    } catch (error) {
      notificateError(notifiacationInstance, '에러발생');
      console.error(error);
    }
  };

  return (
    <div className="relative max-w-inherit">
      <div className="p-4">{render}</div>
      <DismissModal
        openId={deleteOpenId}
        onClick={deleteFeventFunc}
        buttonTitle={'삭제'}
        title={'부고 삭제'}
      />
    </div>
  );
};
