import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useShopItemInfoListByShopHook } from '@shared/api/shopItemInfo/shopItemInfo.hook';
import { shopItemInfoListByShopStateSelectorFamily } from '@shared/api/shopItemInfo/shopItemInfo.selector';
import { Loading } from '@shared/components/Loading';
import { useParams } from 'react-router-dom';

import { ShopItemManageLinkCard } from '../components/ShopItemManageLinkCard';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

/**
 * ShopItem을 관리하기위한 메인 페이지로 ShopItemInfo들을 그리드형태로 보여줘서 해당 ShopItemInfo 클릭 시 해당
 * ShopItemInfo와 관련된 ShopItem을 생성 및 수정할 수 있는 페이지로 이동
 */

const SellerShopItemManageMainPage: FC = function SellerShopItemManageMainPage() {
  const { shopId } = useParams();
  useSellerTopNavHook('소매상품관리');

  //! (1) 해당 shop에서 관리할 수 있는 shopItemInfo를 모두 불러옴
  useShopItemInfoListByShopHook(`${shopId}`);

  const shopItemInfoListState = useRecoilValue(
    shopItemInfoListByShopStateSelectorFamily(`${shopId}`),
  );

  const shopItemInfoLinkGrid = useMemo(() => {
    if (shopItemInfoListState.status === 'success' && shopItemInfoListState.data) {
      const shopItemInfoList = shopItemInfoListState.data;

      return (
        <div className="flex flex-wrap gap-4">
          {shopItemInfoList.map((shopItemInfo) => {
            return (
              <ShopItemManageLinkCard
                shopItemInfo={shopItemInfo}
                key={shopItemInfo._id}
              />
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading></Loading>
        </div>
      );
    }
  }, [shopItemInfoListState.data, shopItemInfoListState.status]);

  return (
    <div className="mx-auto max-w-6xl px-4 pb-8 sm:px-6 lg:px-8">
      <div className="mt-8 space-y-4">{shopItemInfoLinkGrid}</div>
    </div>
  );
};

export default SellerShopItemManageMainPage;
