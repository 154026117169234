//* shopOrder를 input으로 받아서 결제정보를 출력함
import { FC } from 'react';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { PopUpLink } from '@shared/components/PopUpLink';
import { formatDate } from '@shared/utils/formatDate';

interface Props {
  shopOrder: ShopOrderForStore;
}

export const PaymentInfo: FC<Props> = function PaymentInfo({ shopOrder }) {
  const { Receipt } = Icon();
  return (
    <div className="grid auto-rows-auto border-t border-b text-sm font-normal theme-bg-1 theme-border-1 sm:text-base">
      <div className="grid grid-cols-6  border-b theme-border-1">
        <p className="p-2.5 font-medium theme-bg-6">주문금액</p>
        <div className="col-span-5 flex items-center p-2.5">
          {(
            shopOrder.paymentDetail.paymentByPoint + shopOrder.paymentDetail.paymentByCash
          ).toLocaleString()}
          원
        </div>
      </div>
      <div className="grid grid-cols-6  border-b theme-border-1">
        <div className="flex items-center p-2.5 font-medium theme-bg-6">
          <p>결제금액</p>
        </div>
        <div className="col-span-2 flex items-center p-2.5">
          <span className="font-bold text-warn">
            {shopOrder.paymentDetail.paymentByCash.toLocaleString() + '원'}
          </span>
        </div>
        <p className="flex items-center break-keep p-2.5 font-medium theme-bg-6">
          포인트
        </p>
        <div className="col-span-2 flex items-center p-2.5">
          <span className="font-bold text-myTeal">
            {shopOrder.paymentDetail.paymentByPoint.toLocaleString() + 'P'}
          </span>
        </div>
      </div>
      <div className="grid grid-cols-6 border-b theme-border-1">
        <p className="p-2.5 font-medium theme-bg-6">결제방법</p>
        <div className="col-span-2 flex items-center p-2.5">
          <p>{shopOrder.paymentDetail.method}</p>
        </div>
        <p className="flex items-center break-keep p-2.5 font-medium theme-bg-6">
          결제일
        </p>
        <div className="col-span-2 flex items-center p-2.5">
          <p>
            {formatDate(shopOrder.paymentDetail.purchasedAt, {
              dateSeparater: '.',
              contains: {
                year: false,
                day: true,
                hours: true,
                minutes: true,
              },
            })}
          </p>
        </div>
      </div>
      {/* {shopOrder.paymentDetail.paymentByCash !== 0 && (
        <div className="grid grid-cols-6 border-b theme-border-1">
          <p className="p-2.5 font-medium theme-bg-6">적립혜택</p>
          <div className="col-span-3 p-2.5">
            <p>
              구매 확정시 적립포인트 &nbsp;
              <span className="font-bold text-myTeal">
                {(shopOrder.paymentDetail.paymentByCash * 0.05).toLocaleString()}P
              </span>
              &nbsp; 적립
            </p>
          </div>
        </div>
      )} */}
      <div className="grid grid-cols-6 break-keep">
        <p className="flex items-center p-2.5 font-medium theme-bg-6">결제 영수증</p>
        <div className="col-span-5 p-2.5">
          {shopOrder.paymentDetail.receiptUrl ? (
            <PopUpLink url={shopOrder.paymentDetail.receiptUrl}>
              <Button className="button-rectangle h-10 w-fit gap-1 px-3.5 font-medium text-white theme-bg-14">
                <Receipt className="wh-4 fill-white" />
                <p>영수증 출력</p>
              </Button>
            </PopUpLink>
          ) : (
            //TODO: 추후 영수증출력이라 합치고 현금영수증 발행시 해당 shopOrder에 현금영수증처리된 내역 볼수있는 페이지처리해야함
            <div>현금 영수증발행 완료</div>
          )}
          {/* </PopUpLink> */}
        </div>
      </div>
    </div>
  );
};
