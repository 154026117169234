import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { BugoBrand, BugoBrandData } from './bugoBrand.interface';

//* admin bugoBrand atom
export const bugoBrandListStatusAtom = atom<QueryStatus>({
  key: 'bugoBrandListStatusAtom',
  default: 'idle',
});

export const bugoBrandListDataAtom = atom<BugoBrandData[] | null>({
  key: 'bugoBrandListDataAtom',
  default: null,
});

//* admin simpleBugoBrand atom
export const simpleBugoBrandListStatusAtom = atom<QueryStatus>({
  key: 'simpleBugoBrandListStatusAtom',
  default: 'idle',
});

export const simpleBugoBrandListDataAtom = atom<BugoBrand[] | null>({
  key: 'simpleBugoBrandListDataAtom',
  default: null,
});
