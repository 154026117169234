import {
  BugoBrandAnnouncement,
  BugoBrandAnnouncementUpdate,
} from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import { BugoBrandAnnouncementCreate } from './bugoBrandAnnouncement.interface';

export const createBugoBrandAnnouncementUrl = (bugoRole: LowerRole) => {
  return `/${bugoRole}/bugo-brand-announcement`;
};

export const createBugoBrandAnnouncement = async (
  announcementDto: BugoBrandAnnouncementCreate,
  bugoRole: LowerRole,
) => {
  const { data: announcement } = await api_.post<BugoBrandAnnouncement>(
    createBugoBrandAnnouncementUrl(bugoRole),
    announcementDto,
  );
  return announcement;
};

export const updateBugoBrandAnnouncement = async (
  announcementDto: BugoBrandAnnouncementUpdate,
  bugoRole: LowerRole,
) => {
  const { data: announcement } = await api_.patch<BugoBrandAnnouncement>(
    `/${bugoRole}/bugo-brand-announcement/${announcementDto._id}`,
    announcementDto,
  );
  return announcement;
};

export const deleteBugoBrandAnnouncement = async (id: string, bugoRole: LowerRole) => {
  return await api_.delete<BugoBrandAnnouncement>(
    `/${bugoRole}/bugo-brand-announcement/${id}`,
  );
};
