import { atom, atomFamily } from 'recoil';

import {
  ShopItem,
  ShopItemForStoreData,
  ShopItemListByBugoForStoreData,
} from '@shared/api/shopItem/shopItem.interface';
import { QueryStatus } from 'react-query';

import { Rebate } from '../rebate/rebate.interface';
import { ShopItemInfo } from '../shopItemInfo/shopItemInfo.interface';

export const storeShopItemStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'storeShopItemStatusAtomFamily',
  default: 'idle',
});

export const storeShopItemDataAtomFamily = atomFamily<
  ShopItemForStoreData | null,
  string
>({
  key: 'storeShopItemDataAtomFamily',
  default: null,
});

export const shopItemListByBugoForStoreStatusAtom = atom<QueryStatus>({
  key: 'shopItemListByBugoForStoreStatusAtom',
  default: 'idle',
});

export const shopItemListByBugoForStoreDataAtom =
  atom<ShopItemListByBugoForStoreData | null>({
    key: 'shopItemListByBugoForStoreDataAtom',
    default: null,
  });

export const shopItemForStoreStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'shopItemForStoreStatusAtomFamily',
  default: 'idle',
});

export const shopItemForStoreDataAtomFamily = atomFamily<
  ShopItemForStoreData | null,
  string
>({
  key: 'shopItemForStoreDataAtomFamily',
  default: null,
});

export const testShopItemListForBugoStatusAtom = atom<QueryStatus>({
  key: 'testShopItemForBugoStatusAtom',
  default: 'idle',
});

export const testShopItemListForBugoDataAtom =
  atom<ShopItemListByBugoForStoreData | null>({
    key: 'testShopItemListForBugoDataAtom',
    default: null,
  });

export const sellerShopItemListByShopStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'sellerShopItemListByShopStatusAtomFamily',
  default: 'idle',
});

export const sellerShopItemListByShopDataAtomFamily = atomFamily<
  ShopItem<ShopItemInfo, string, Rebate>[] | null,
  string
>({
  key: 'sellerShopItemListByShopDataAtomFamily',
  default: null,
});
