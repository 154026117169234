import { FC, useMemo } from 'react';

import TelField from '@shared/components/TelField';
import { TextField } from '@shared/components/TextField';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';
import { Control, UseFormRegister, useWatch } from 'react-hook-form';

interface IProps {
  register: UseFormRegister<any>;
  control: Control<any, any>;
  names: {
    senderName: string;
    senderPassword: string;
    senderTel: string;
  };
}

const SenderInfoInputs: FC<IProps> = ({
  register,
  control,
  names: { senderName, senderTel },
}: IProps) => {
  const { userProfile, storeRole } = useAuth();
  const telValue = useWatch({ control, name: senderTel, defaultValue: '' });

  const infoInput = useMemo(() => {
    if (userProfile && storeRole() !== LowerRole.StoreGuest) {
      return (
        <div className="grid grid-cols-1 grid-rows-2 gap-y-3 pt-3">
          <TextField
            scrollOnFocus={true}
            className="w-full"
            placeholder="성함"
            {...register(senderName, { required: true })}
          />
          <div className="col-span-full">
            <TelField
              scrollOnFocus={true}
              value={String(telValue)}
              register={register}
              name={senderTel}
              className="w-full"
              required={true}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="grid grid-cols-2 grid-rows-2 gap-x-2 gap-y-3 pt-3">
          <div className="col-span-full">
            <TextField
              scrollOnFocus={true}
              className="w-full"
              placeholder="성함"
              {...register(senderName, { required: true })}
            />
          </div>
          <div className="col-span-full">
            <TelField
              scrollOnFocus={true}
              value={String(telValue)}
              register={register}
              name={senderTel}
              className="w-full"
              required={true}
            />
          </div>
        </div>
      );
    }
  }, [register, senderName, senderTel, storeRole, telValue, userProfile]);

  return (
    <div className="font-bold">
      <h4>주문자 정보</h4>
      {infoInput}
    </div>
  );
};

export default SenderInfoInputs;
