import { FC } from 'react';

import { SellerLoginPage } from '@service/seller/page/SellerLoginPage';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import SellerAuthRouter from './SellerAuthRouter';

export const SellerRouter: FC = function SellerRouter() {
  const { isStoreSeller, isAdmin } = useAuth();
  const { pathname, search } = useLocation();
  const isSellerAuthenticated = isStoreSeller() || isAdmin();

  if (
    pathname !== '/flower-seller/login' &&
    pathname !== '/flower-seller' &&
    pathname !== '/signup/init' &&
    !isSellerAuthenticated
  ) {
    sessionStorage.setItem('redirectUrlAfterSellerLogin', pathname + search);
  }

  return (
    <div className="bg-white">
      <Routes>
        <Route path="signup" element={<div>SellerSignupPage</div>}></Route>

        {/* logout required */}
        <Route
          path="login"
          element={
            !isSellerAuthenticated ? (
              <SellerLoginPage />
            ) : (
              <Navigate to="/flower-seller" />
            )
          }
        ></Route>
        <Route
          path="signup"
          element={
            !isSellerAuthenticated ? (
              <div>SellerSignupPage</div>
            ) : (
              <Navigate to="/flower-seller" />
            )
          }
        ></Route>

        {/* login required */}
        <Route path="*" element={<SellerAuthRouter />} />
      </Routes>
    </div>
  );
};
