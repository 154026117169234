import { FC } from 'react';

import { CustomCallback } from '@shared/types';
import { motion } from 'framer-motion';
import { useId } from 'react-id-generator';

interface IProps {
  toggle: boolean;
  onChagne: CustomCallback<boolean, void>;
  toggleValue: {
    on: string;
    off: string;
  };
}

/**
 * @param toggle 기존 값을 넣기 위한 용도
 * @param toggleValue on, off 시 보이는 값(string)
 * 왼쪽이 on 오른쪽이 off
 * @returns
 */

const Toggle: FC<IProps> = ({ toggle, toggleValue, onChagne }: IProps) => {
  const [id] = useId(1, 'toggle');

  const onToggleOnClick = () => onChagne(true);

  const onToggleOffClick = () => onChagne(false);

  return (
    <div className="input-box my-auto grid grid-cols-2 border p-[1px] text-[14px] theme-bg-1 theme-border-1">
      <div className="relative">
        {/* 배경색 */}
        {toggle && (
          <motion.div
            layoutId={`toggle-background-${id}`}
            className="absolute h-full w-full theme-bg-13"
          ></motion.div>
        )}
        <div
          className={`relative z-10 cursor-pointer bg-transparent py-2.5 px-3.5 text-center font-bold ${
            toggle ? 'theme-text-4' : 'text-gray-500'
          }`}
          onClick={onToggleOnClick}
        >
          {toggleValue.on}
        </div>
      </div>
      <div className="relative">
        {/* 배경색 */}
        {!toggle && (
          <motion.div
            layoutId={`toggle-background-${id}`}
            className="absolute h-full w-full theme-bg-13"
          ></motion.div>
        )}
        <div
          className={`relative z-10 cursor-pointer bg-transparent py-2.5 px-3.5 text-center font-bold ${
            !toggle ? 'theme-text-4' : 'text-gray-500'
          }`}
          onClick={onToggleOffClick}
        >
          {toggleValue.off}
        </div>
      </div>
    </div>
  );
};

export default Toggle;
