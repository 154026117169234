import { FC, useState } from 'react';

import { CheckBox } from './CheckBox';
import ResizableBox from './ResizableBox';
import { Icon } from './icons';

interface IProps {
  title: string;
  description: string;
  isChecked: boolean;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const DURATION = 0.3;

const AgreementContentBlock: FC<IProps> = ({
  title,
  description,
  isChecked,
  setIsChecked,
}: IProps) => {
  const { ChevronDown, ChevronUp } = Icon();
  const [fold, setFold] = useState(true);

  const onCheckChange = (checked: boolean) => {
    setIsChecked(checked);
  };

  return (
    <div className="space-y-2text-start">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <p className="font-bold theme-text-main">필수</p>
          <p className="font-medium">{title}</p>
          <div
            onClick={() => {
              setFold(!fold);
            }}
          >
            {fold ? <ChevronDown className="wh-6" /> : <ChevronUp className="wh-6" />}
          </div>
        </div>
        <div className="pr-2">
          <CheckBox checked={isChecked} onCheckedChange={onCheckChange} />
        </div>
      </div>
      <ResizableBox duration={DURATION}>
        {!fold && (
          <div className={`px-4 py-2 theme-bg-6`}>
            <h4 className="text-12 font-bold underline">{title}</h4>
            <p className="whitespace-pre-line break-keep text-xs leading-[13px]">
              {description}
            </p>
          </div>
        )}
      </ResizableBox>
      {/* CheckBox */}
    </div>
  );
};

export default AgreementContentBlock;
