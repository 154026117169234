import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoBrandListStateSelector } from '@shared/api/bugoBrand/bugoBrand.selector';
import { Loading } from '@shared/components/Loading';
import { useParams } from 'react-router-dom';

import { AdminBugoBrandForm } from '../containers/AdminBugoBrandForm/AdminBugoBrandForm';
import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const AdminBugoBranEditPage: FC = function AdminBugoBranEditPage() {
  useAdminTitleHook('상조 수정');

  const { bugoBrandId } = useParams();

  const bugoBrandListState = useRecoilValue(bugoBrandListStateSelector);

  const bugoBrand = useMemo(() => {
    if (bugoBrandListState.status === 'success' && bugoBrandListState.data) {
      const bugoBrandList = bugoBrandListState.data;
      return bugoBrandList.find((item) => {
        return item._id === bugoBrandId;
      });
    }
  }, [bugoBrandId, bugoBrandListState.data, bugoBrandListState.status]);

  const bugoBrandForm = useMemo(() => {
    if (bugoBrand) {
      return <AdminBugoBrandForm bugoBrand={bugoBrand} />;
    } else {
      return (
        <div className="center-box">
          <Loading />
        </div>
      );
    }
  }, [bugoBrand]);

  return <div className="p-4">{bugoBrandForm}</div>;
};

export default AdminBugoBranEditPage;
