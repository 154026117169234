export enum ShopItemFormData {
  ItemCount = 'itemCount',
  SenderName = 'senderName',
  SenderPassword = 'senderPassword',
  SenderTel = 'senderTel',
  ReceiverName = 'receiverName',
  ReceiverAddress = 'receiverAddress',
  PhraseSender = 'phraseSender',
  PhraseCondolence = 'phraseCondolence',
  UsedPoint = 'usedPoint',
  IsAgreed = 'isAgreed',
}

export interface IShopItemFormData {
  [ShopItemFormData.ItemCount]: number;
  [ShopItemFormData.SenderName]: string;
  [ShopItemFormData.SenderPassword]: string;
  [ShopItemFormData.SenderTel]: string;
  [ShopItemFormData.ReceiverName]: string;
  [ShopItemFormData.ReceiverAddress]: string;
  [ShopItemFormData.PhraseSender]: string;
  [ShopItemFormData.PhraseCondolence]: string;
  [ShopItemFormData.UsedPoint]: number;
  [ShopItemFormData.IsAgreed]: boolean;
}
