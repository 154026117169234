import { FC } from 'react';

import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { User } from '@shared/api/user/user.interface';
import Modal from '@shared/components/Modal';

import { AdminBugoBrandAgencyAdminForm } from '../containers/AdminBugoBrandAgencyAdminForm/AdminBugoBrandAgencyAdminForm';

//*관리자가 상조 관리자를 수정 및 생성할때 사용하는 모달
interface Props {
  openId: string;
  bugoBrand: BugoBrand | BugoBrandData;
  agencyAdminUser?: User;
}

export const AdminAgencyAdminUpsertModal: FC<Props> =
  function AdminAgencyAdminUpsertModal({ openId, bugoBrand, agencyAdminUser }: Props) {
    return (
      <Modal openId={openId}>
        <AdminBugoBrandAgencyAdminForm
          bugoBrand={bugoBrand}
          agencyAdminUser={agencyAdminUser}
          editMode={agencyAdminUser ? true : false}
        />
      </Modal>
    );
  };
