import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useShopOrderListForStoreHook } from '@shared/api/shopOrder/shopOrder.hook';
import { shopOrderListForStoreStateSelector } from '@shared/api/shopOrder/shopOrder.selector';
import { Loading } from '@shared/components/Loading';
import { useAuth } from '@shared/state/hooks/useAuth';

import OrderListFilter from '../containers/OrderListFilter/OrderListFilter';
import { orderListFilterAtom } from '../containers/OrderListFilter/orderListFilter.atom';
import StoreCard from '../containers/StoreCard/StoreCard';
import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

const OrderListPage: FC = () => {
  useStoreTitleHook('주문 배송 내역');
  const { storeRole } = useAuth();

  useShopOrderListForStoreHook(storeRole());

  const shopOrderListForStoreState = useRecoilValue(shopOrderListForStoreStateSelector);
  const orderListFilter = useRecoilValue(orderListFilterAtom);

  const renderStoreCardList = useMemo(() => {
    if (
      shopOrderListForStoreState.status === 'success' &&
      shopOrderListForStoreState.data
    ) {
      let filteredList = shopOrderListForStoreState.data;

      if (orderListFilter.isActive) {
        filteredList = filteredList.filter(
          (shopOrder) => orderListFilter.contains[shopOrder.deliveryDetail.status],
        );
      }

      // TODO: 임시로 20개만 렌더링 -> Pagination 필요!
      filteredList = filteredList.slice(20);

      return filteredList.map((shopOrder) => (
        <StoreCard key={`shopOrder-${shopOrder._id}`} shopOrder={shopOrder} />
      ));
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [shopOrderListForStoreState, orderListFilter]);

  return (
    <div className="space-y-3 p-4">
      {/* Filter */}
      {shopOrderListForStoreState.status === 'success' &&
        shopOrderListForStoreState.data && (
          <OrderListFilter
            shopOrderDeliveryStatusList={shopOrderListForStoreState.data.map(
              (shopOrder) => shopOrder.deliveryDetail.status,
            )}
          />
        )}
      {/* Store Card List */}
      <div className="w-full space-y-3">{renderStoreCardList}</div>
    </div>
  );
};

export default OrderListPage;
