import { selector } from 'recoil';

import { QueryDataState } from '@shared/interfaces';

import {
  courtesyAssetListByBugoBrandDataAtom,
  courtesyAssetListByBugoBrandStatusAtom,
  courtesyAssetListByMemberDataAtom,
  courtesyAssetListByMemberStatusAtom,
  courtesyContentListByBugoBrandDataAtom,
  courtesyContentListByBugoBrandStatusAtom,
  courtesyContentListByMemberDataAtom,
  courtesyContentListByMemberStatusAtom,
} from './courtesyAsset.atom';
import { CourtesyAssetData, CourtesyContent } from './courtesyAsset.interface';

export const courtesyAssetListByBugoBrandSelector = selector<
  QueryDataState<CourtesyAssetData[]>
>({
  key: 'courtesyAssetListByBugoBrandSelector',
  get: ({ get }) => {
    return {
      status: get(courtesyAssetListByBugoBrandStatusAtom),
      data: get(courtesyAssetListByBugoBrandDataAtom),
    };
  },
});

export const courtesyContentListByBugoBrandSelector = selector<
  QueryDataState<CourtesyContent[]>
>({
  key: 'courtesyContentListByBugoBrandSelector',
  get: ({ get }) => {
    return {
      status: get(courtesyContentListByBugoBrandStatusAtom),
      data: get(courtesyContentListByBugoBrandDataAtom),
    };
  },
});

export const courtesyAssetListByMemberSelector = selector<
  QueryDataState<CourtesyAssetData[]>
>({
  key: 'courtesyAssetListByMemberSelector',
  get: ({ get }) => {
    return {
      status: get(courtesyAssetListByMemberStatusAtom),
      data: get(courtesyAssetListByMemberDataAtom),
    };
  },
});

export const courtesyContentListByMemberSelector = selector<
  QueryDataState<CourtesyContent[]>
>({
  key: 'courtesyContentListByMemberSelector',
  get: ({ get }) => {
    return {
      status: get(courtesyContentListByMemberStatusAtom),
      data: get(courtesyContentListByMemberDataAtom),
    };
  },
});
