import { FC } from 'react';

import { Bugo } from '@shared/api/bugo/bugo.interface';
import { FeventByShop } from '@shared/api/fevent/fevent.interface';
import { Member } from '@shared/api/member/member.interface';
import { Button } from '@shared/components/Button';
import { CustomCallback } from '@shared/types';

interface Props {
  fevent: FeventByShop;
  onSelectMember: CustomCallback<Member<string, Bugo>, void>;
}

export const SelectMemberForm: FC<Props> = function SelectMemberForm({
  fevent,
  onSelectMember,
}: Props) {
  const memberOrderList = fevent?.memberOrderList;

  const memberSelectOption = memberOrderList?.map((member) => {
    return (
      <Button
        type="button"
        key={member._id}
        className="w-full"
        onClick={() => {
          onSelectMember(member);
        }}
      >
        <p className="text-base font-medium">
          {member.relationType}&nbsp;&nbsp;
          {member.fullName}
        </p>
      </Button>
    );
  });

  return (
    <div className="space-y-4 text-center">
      <p className="bugo-h1 text-18">받으실 상주님 선택</p>
      {memberSelectOption}
    </div>
  );
};
