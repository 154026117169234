import { handleAxiosDates } from '@shared/utils/dateUtil';
import axios, { AxiosRequestConfig } from 'axios';

// // console.log('process.env.REACT_APP_BASE_URL', process.env.REACT_APP_BASE_URL);
export const api_ = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api_.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api_.interceptors.response.use((originalResponse) => {
  handleAxiosDates(originalResponse.data);
  return originalResponse;
});

export const api_session = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api_session.interceptors.request.use((config: AxiosRequestConfig) => {
  const sessionToken = sessionStorage.getItem('token');
  if (sessionToken && config.headers)
    config.headers.Authorization = `Bearer ${sessionToken}`;
  return config;
});

api_session.interceptors.response.use((originalResponse) => {
  handleAxiosDates(originalResponse.data);
  return originalResponse;
});
