import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useWorkerUserByBugoBrandLightHook } from '@shared/api/user/user.hook';
import { workerUserByBugoBrandLightStateSelector } from '@shared/api/user/user.selector';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useParams } from 'react-router-dom';

import { AgencyWorkerUpsertModal } from '../components/AgencyWorkerUpsertModal';
import { AgencyWorkerTable } from '../containers/AgencyWorkerTable/AgencyWorkerTable';
import { useTitleHook } from '../hooks/useTitleHook';

//* 관리자용 팀장 관리페이지
const AgencyAdminWorkerPage: FC = function AgencyAdminWorkerPage() {
  useTitleHook('지도사 등록현황');
  const { PlusSolid } = Icon();
  const { bugoBrandId } = useParams();
  const { userBugoBrand } = useAuth();

  const agencyAdminWorkerCreateOpenId = `bugoBrandAgencyWorker-create-${bugoBrandId}`;
  const setCreateModalOpen = useSetRecoilState(
    simpleOpenAtomFamily(agencyAdminWorkerCreateOpenId),
  );

  useWorkerUserByBugoBrandLightHook(`${bugoBrandId}`);

  const workerListState = useRecoilValue(
    workerUserByBugoBrandLightStateSelector(`${bugoBrandId}`),
  );

  //* 팀장 추가
  const agencyWorkerCreateButton = useMemo(() => {
    if (bugoBrandId && userBugoBrand) {
      return (
        <>
          <Button
            className="center-box gap-1 shadow-sm theme-bg-1"
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            <PlusSolid className="wh-4" />
            <p>지도사 계정 생성</p>
          </Button>
          <AgencyWorkerUpsertModal
            openId={agencyAdminWorkerCreateOpenId}
            bugoBrand={userBugoBrand}
          />
        </>
      );
    }
  }, [
    PlusSolid,
    agencyAdminWorkerCreateOpenId,
    bugoBrandId,
    setCreateModalOpen,
    userBugoBrand,
  ]);

  const agencyWorkerTable = useMemo(() => {
    if (workerListState.status === 'success' && workerListState.data && userBugoBrand) {
      const workerList = workerListState.data;
      return <AgencyWorkerTable workerList={workerList} bugoBrand={userBugoBrand} />;
    }
  }, [userBugoBrand, workerListState.data, workerListState.status]);

  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between">
        <p className="pl-2 text-16 font-bold">{userBugoBrand?.bugoAgency?.corpName}</p>
        {agencyWorkerCreateButton}
      </div>
      <div>{agencyWorkerTable}</div>
    </div>
  );
};

export default AgencyAdminWorkerPage;
