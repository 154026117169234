import { CheckBoxFilter } from '@shared/components/CheckboxFilter';

export enum BugoBrandAnnouncementFormInputsEnum {
  Type = 'type',
  Title = 'title',
  Content = 'content',
  IsReadRequired = 'isReadRequired',
  TeamTypes = 'teamTypes',
}

export interface BugoBrandAnnouncementFormInputs {
  [BugoBrandAnnouncementFormInputsEnum.Type]: string;
  [BugoBrandAnnouncementFormInputsEnum.Title]: string;
  [BugoBrandAnnouncementFormInputsEnum.Content]: string;
  [BugoBrandAnnouncementFormInputsEnum.IsReadRequired]: boolean;
  [BugoBrandAnnouncementFormInputsEnum.TeamTypes]: CheckBoxFilter;
}
