import { forwardRef, InputHTMLAttributes } from 'react';

import { useRecoilValue } from 'recoil';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';
import { useId } from 'react-id-generator';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelClassName?: string;
  frameClassName?: string;
}

const mainCheckedBorderColorGenerator = (customTheme: UserCustomTheme) => {
  switch (customTheme) {
    case UserCustomTheme.Preed:
      return 'checked:border-preed';
    case UserCustomTheme.DaeMyung:
      return 'checked:border-daeMyung';
    case UserCustomTheme.Default:
    case UserCustomTheme.Dark:
    default:
      return 'checked:border-myApricot';
  }
};

const RadioInput = forwardRef<HTMLInputElement, IProps>(
  ({ label, labelClassName, frameClassName, className, children, ...props }, ref) => {
    const [id] = useId(1, 'radioInput');
    const customTheme = useRecoilValue(customThemeAtom);

    return (
      <label
        htmlFor={id}
        className={`relative flex items-center space-x-3 px-2 py-3 ${frameClassName}`}
      >
        <input
          ref={ref}
          id={id}
          type="radio"
          className={`wh-4 appearance-none rounded-full border theme-border-1 checked:border-[4px] ${mainCheckedBorderColorGenerator(
            customTheme,
          )} ${className}`}
          {...props}
        />
        {label && <p className={`${labelClassName ?? 'theme-text-8'}`}>{label}</p>}
        {children}
      </label>
    );
  },
);

export default RadioInput;
