//* funeral-home-info hook 사용 라우터(관리자용)
import { FC } from 'react';

import AdminFuneralHomeInfoEditPage from '@service/admin/page/AdminFuneralHomeInfoEditPage';
import AdminFuneralHomeInfoManagePage from '@service/admin/page/AdminFuneralHomeInfoManagePage';
import { useFuneralHomeInfoListHook } from '@shared/api/funeralHomeInfo/funeralHomeInfo.hook';
import { Route, Routes } from 'react-router-dom';

const AdminFuneralHomeInfoRouter: FC = function AdminFuneralHomeInfoRouter() {
  useFuneralHomeInfoListHook();

  return (
    <Routes>
      <Route path="manage" element={<AdminFuneralHomeInfoManagePage />} />
      <Route path=":funeralHomeInfoId/edit" element={<AdminFuneralHomeInfoEditPage />} />
    </Routes>
  );
};

export default AdminFuneralHomeInfoRouter;
