import { updateBugoBrand } from '@shared/api/bugoBrand/bugoBrand.controller';
import { BugoMultiAgency } from '@shared/api/bugoBrand/bugoBrand.interface';
import { LowerRole } from '@shared/types';
import _ from 'lodash';

import {
  BugoBrandMultiAgencyFormInputs,
  BugoBrandMultiAgencyFormInputsEnum,
} from './BugoBrandMultiAgencyForm.interface';

export const onBugoBrandMultiAgencyUpsert = async (
  data: BugoBrandMultiAgencyFormInputs,
  bugoBrandId: string,
  bugoBrandMultiAgencyList: BugoMultiAgency<string, string>[],
  index?: number,
) => {
  const newBugoBrandMultiAgency = {
    ...data,
    headerImage: data[BugoBrandMultiAgencyFormInputsEnum.HeaderImage]?._id,
    footerImage: data[BugoBrandMultiAgencyFormInputsEnum.FooterImage]?._id,
  };

  //수정
  if (!_.isNil(index)) {
    bugoBrandMultiAgencyList.splice(index, 1, newBugoBrandMultiAgency);
    const dto = {
      _id: bugoBrandId,
      bugoAgencyList: bugoBrandMultiAgencyList,
    };

    const bugoBrandUpdated = await updateBugoBrand(dto, LowerRole.Admin);
    return bugoBrandUpdated;
  }
  // 추가
  else {
    const ret = [...bugoBrandMultiAgencyList, newBugoBrandMultiAgency];

    const dto = {
      _id: bugoBrandId,
      bugoAgencyList: ret,
    };
    const bugoBrandUpdated = await updateBugoBrand(dto, LowerRole.Admin);
    return bugoBrandUpdated;
  }
};

export const onBugoBrandMultiAgencyDelete = async (
  bugoBrandId: string,
  bugoBrandMultiAgencyList: BugoMultiAgency<string, string>[],
  multiAgencyIndex: number,
) => {
  bugoBrandMultiAgencyList.splice(multiAgencyIndex, 1);
  const dto = {
    _id: bugoBrandId,
    bugoAgencyList: bugoBrandMultiAgencyList,
  };
  const bugoBrandUpdated = await updateBugoBrand(dto, LowerRole.Admin);
  return bugoBrandUpdated;
};
