import { forwardRef, InputHTMLAttributes } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import {
  feventDpImageDeleteAtom,
  feventDpImageFileNameAtom,
  feventUploadImageAtom,
} from '@service/bugo/containers/FeventDeceasedInfoForm/feventDeceasedInfo.atom';
import { useId } from 'react-id-generator';

import { Button } from './Button';
import { Icon } from './icons';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
}

export const FileUploader = forwardRef<HTMLInputElement, Props>(
  ({ placeholder, className, accept, ...props }, ref) => {
    const { X } = Icon();
    const [id] = useId(1, 'fileuploader');
    const [feventUploadImage, setFeventUploadImage] =
      useRecoilState(feventUploadImageAtom);
    const onFileHandler = async (e: any) => {
      const _file = e.target.files[0];

      if (!_file.type.startsWith('image/')) {
        alert(
          `파일 "${_file.name}"은(는) 허용되지 않는 파일 형식입니다. 이미지 파일만 업로드하세요.`,
        );
        e.target.value = ''; // input 요소 초기화
        return;
      }
      if (_file) setFeventUploadImage(_file);
    };

    const [feventDpFileName, setFeventDpFileName] = useRecoilState(
      feventDpImageFileNameAtom,
    );

    const setFeventDpDelete = useSetRecoilState(feventDpImageDeleteAtom);

    return (
      <label
        htmlFor={id}
        className={`${className} input-box relative flex items-center justify-between pr-[1px] theme-bg-1 theme-border-1`}
      >
        <input
          {...props}
          ref={ref}
          id={id}
          onChange={(e) => {
            onFileHandler(e);
          }}
          accept={accept}
          className={`absolute left-0 h-full w-full cursor-pointer opacity-0`}
          type="file"
        />
        <div className="w-full flex-1">
          {feventUploadImage ? (
            <p className="block max-w-[250px] overflow-x-hidden text-ellipsis whitespace-nowrap sm:max-w-none">
              {feventUploadImage.name}
            </p>
          ) : feventDpFileName ? (
            feventDpFileName
          ) : (
            <p className="theme-text-6">{placeholder ?? '업로드(파일)'}</p>
          )}
        </div>
        <div className="flex h-full items-center py-[1px]">
          {feventUploadImage ? (
            <Button
              className="button-no-border relative px-1"
              onClick={(event) => {
                event.preventDefault();
                setFeventUploadImage(null);
                setFeventDpFileName(null);
              }}
            >
              <X />
            </Button>
          ) : feventDpFileName ? (
            <Button
              className="button-rectangle z-10 h-full px-[18px] theme-text-4 theme-bg-13"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setFeventDpFileName('');
                setFeventDpDelete(true);
              }}
            >
              삭제
            </Button>
          ) : (
            <Button className="button-rectangle h-full px-[18px] theme-text-4 theme-bg-13">
              업로드
            </Button>
          )}
        </div>
      </label>
    );
  },
);
