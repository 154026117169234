//* 장례식장정보 수정 페이지
import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { FuneralHomeInfoListStateSelector } from '@shared/api/funeralHomeInfo/funeralHomeInfo.selector';
import { FuneralHomeInfoEditor } from '@shared/containers/FuneralHomeInfoEditor/FuneralHomeInfoEditor';
import { useParams } from 'react-router-dom';

import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const AdminFuneralHomeInfoEditPage: FC = function AdminFuneralHomeInfoEditPage() {
  useAdminTitleHook('장례식장정보 수정');

  const { funeralHomeInfoId } = useParams();

  const funeralHomeInfoState = useRecoilValue(FuneralHomeInfoListStateSelector);

  const render = useMemo(() => {
    if (funeralHomeInfoState.status === 'success' && funeralHomeInfoState.data) {
      const funeralHomeInfo = funeralHomeInfoState.data.find((item) => {
        return item._id === funeralHomeInfoId;
      });

      if (funeralHomeInfo) {
        return (
          <div className="space-y-4">
            <h3 className="bugo-h1">{funeralHomeInfo.name}</h3>
            <FuneralHomeInfoEditor funeralHomeInfo={funeralHomeInfo} />
          </div>
        );
      }
    }
  }, [funeralHomeInfoId, funeralHomeInfoState.data, funeralHomeInfoState.status]);

  return <div className="p-4">{render}</div>;
};

export default AdminFuneralHomeInfoEditPage;
