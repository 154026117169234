import { atom } from 'recoil';

import { DeliveryState } from '@shared/types';

export interface IOrderListFilter {
  isActive: boolean;
  contains: {
    [DeliveryState.BEFORE_PREPARING]: boolean;
    [DeliveryState.PREPARING]: boolean;
    [DeliveryState.DELIVERYING]: boolean;
    [DeliveryState.DELIVERED]: boolean;
  };
}

export const orderListFilterAtom = atom<IOrderListFilter>({
  key: 'orderListFilterAtom',
  default: {
    isActive: false,
    contains: {
      [DeliveryState.BEFORE_PREPARING]: false,
      [DeliveryState.PREPARING]: false,
      [DeliveryState.DELIVERYING]: false,
      [DeliveryState.DELIVERED]: false,
    },
  },
});
