import { BugoStatRangeQueryEnum } from '@service/bugo/page/AgencyAdminBugoStatMobilePage';

export const getDefaultDateFrom = (range: BugoStatRangeQueryEnum) => {
  const now = new Date();
  switch (range) {
    case BugoStatRangeQueryEnum.Day:
      return getTodayFrom(now);
    case BugoStatRangeQueryEnum.Week:
      return getThisWeekFrom(now);
    case BugoStatRangeQueryEnum.Month:
      return getThisMonthFrom(now);
    case BugoStatRangeQueryEnum.Year:
      return getThisYearFrom(now);
    default:
      return null;
  }
};

export const getTodayFrom = (now: Date): Date => {
  const result = new Date(now);

  result.setHours(0);
  result.setMinutes(0);
  result.setSeconds(0);
  result.setMilliseconds(0);

  return result;
};

export const getThisWeekFrom = (now: Date): Date => {
  const result = new Date(now);

  const day = result.getDay();
  const diff = result.getDate() - day;

  result.setDate(diff);

  return getTodayFrom(result);
};

export const getThisMonthFrom = (now: Date): Date => {
  const result = new Date(now);

  result.setDate(1);

  return getTodayFrom(result);
};

export const getThisYearFrom = (now: Date): Date => {
  const result = new Date(now);

  result.setMonth(0);
  result.setDate(1);

  return getTodayFrom(result);
};

export const getThirtyDayBefore = (now: Date): Date => {
  const result = new Date(now);
  result.setDate(-30);
  return getTodayFrom(result);
};

export const getTodayTo = (now: Date): Date => {
  const result = new Date(now);

  result.setHours(23);
  result.setMinutes(59);
  result.setSeconds(59);
  result.setMilliseconds(999);

  return result;
};
