import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import { callOrderListDataAtom, callOrderListStatusAtom } from './callOrder.atom';
import { CallOrderStatus } from './callOrder.interface';

export const callOrderListHookUrl = (role: LowerRole) => {
  return `/${role}/call-order/?${queryString({
    filter: {
      status: CallOrderStatus.Todo,
    },
    options: {
      sort: {
        createdAt: -1,
      },
    },
    populate: [
      {
        path: 'bugo',
        populate: [
          {
            path: 'fevent',
            populate: [
              {
                path: 'funeralHomeInfo',
              },
              {
                path: 'roomInfo',
              },
            ],
          },
          {
            path: 'member',
          },
        ],
      },
      {
        path: 'shop',
      },
    ],
  })}`;
};

export const useCallOrderListHook = () => {
  const { isAdmin, isStoreSeller, sellerRole } = useAuth();
  useRecoilQuery(
    callOrderListStatusAtom,
    callOrderListDataAtom,
    callOrderListHookUrl(sellerRole()),
    fetcher,
    !(isAdmin() || isStoreSeller()),
  );
};
