import { UserCustomTheme } from '@shared/types';

export const mainColorGenerator = (theme: UserCustomTheme) => {
  switch (theme) {
    case UserCustomTheme.Default:
      return '#C9906A';
    case UserCustomTheme.Dark:
      return '#C9906A';
    case UserCustomTheme.Preed:
      return '#0186B5';
    case UserCustomTheme.DaeMyung:
      return '#252E63';
    default:
      return '#C9906A';
  }
};

export const mainBgColorGenerator = (customTheme: UserCustomTheme) => {
  switch (customTheme) {
    case UserCustomTheme.Preed:
      return 'bg-preed';
    case UserCustomTheme.DaeMyung:
      return 'bg-daeMyung';
    case UserCustomTheme.Default:
    case UserCustomTheme.Dark:
    default:
      return 'bg-myApricot';
  }
};
