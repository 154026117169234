import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import { FuneralHomeInfoUpdate } from './funeralHomeInfo.interface';

export const updateFuneralHomeInfo = async (
  updateFuneralHomeInfo: FuneralHomeInfoUpdate,
  role: LowerRole,
) => {
  const { data } = await api_.patch(
    `/${role}/funeral-home-info/${updateFuneralHomeInfo._id}`,
    updateFuneralHomeInfo,
  );
  return { data };
};
