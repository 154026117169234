//* 상품 간단 구매 모달

import { FC, useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useTestShopItemListForBugoHook } from '@shared/api/shopItem/shopItem.hook';
import { testShopItemListForBugoStateSelector } from '@shared/api/shopItem/shotItem.selector';
import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useId } from 'react-id-generator';

import { EasyBuyShopItemCard } from './EasyBuyShopItemCard';

interface Props {
  bugoId: string;
}

export const FlowerEasyBuyModalButton: FC<Props> = function FlowerEasyBuyModalButton({
  bugoId,
}: Props) {
  const [id] = useId(1, 'flower-easy-buy-modal-button');
  const openId = `open-${id}`;
  const [open, setOpen] = useRecoilState(simpleOpenAtomFamily(openId));
  useTestShopItemListForBugoHook(bugoId);
  const shopItemListState = useRecoilValue(testShopItemListForBugoStateSelector);

  const dispalyEasyBuyShopItemBox = useMemo(() => {
    if (shopItemListState.status === 'success' && shopItemListState.data) {
      const shopItemList = shopItemListState.data;
      const shopItemShowList = shopItemList.slice(0, 3);
      return (
        <div className={`grid grid-cols-${shopItemShowList.length}`}>
          {shopItemShowList.map((shopItem) => {
            return (
              <EasyBuyShopItemCard
                shopItem={shopItem}
                bugoId={bugoId}
                key={shopItem._id}
              />
            );
          })}
        </div>
      );
    }
  }, [bugoId, shopItemListState.data, shopItemListState.status]);

  return (
    <>
      <Button
        className="h-10 px-3.5 text-white theme-bg-main"
        onClick={() => {
          setOpen(true);
        }}
      >
        간편구매
      </Button>
      <Modal openId={openId} position="center">
        <div className="space-y-2">{dispalyEasyBuyShopItemBox}</div>
      </Modal>
    </>
  );
};
