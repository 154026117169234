import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { TextField } from '@shared/components/TextField';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface Props {
  redirectUrl: string;
}

export const GuestLoginForm: FC<Props> = function GuestLoginForm({ redirectUrl }: Props) {
  const { register, handleSubmit } = useForm<{
    name: string;
    password: string;
    phoneNumber: string;
  }>();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const navigate = useNavigate();

  const { loginStoreGuest } = useAuth();

  const onSubmit: SubmitHandler<{
    name: string;
    password: string;
    phoneNumber: string;
  }> = async (data) => {
    const { name, phoneNumber, password } = data;
    try {
      await loginStoreGuest({
        name: name,
        phoneNumber: phoneNumber,
        password: password,
      });
      notificateSuccess(notifiacationInstance, '로그인 되었습니다');
      navigate(redirectUrl);
    } catch (error) {
      // id 혹은 password 틀림.
      notificateError(
        notifiacationInstance,
        '주문자 정보 혹은 비밀번호가 일치하지 않습니다',
      );
      console.log(error);
    }
  };

  const onError: SubmitErrorHandler<{
    name: string;
    password: string;
    phoneNumber: string;
  }> = (errors, e) => {
    console.log(errors, e);
  };

  return (
    <div className="space-y-4 bg-white px-3 py-4">
      <form className="space-y-2">
        <TextField
          className="textfield-sm h-10 w-full rounded-lg text-15"
          label="주문자명"
          type="text"
          autoComplete="name"
          labelClassname="block text-15 font-medium text-gray-700"
          placeholder="주문자명"
          {...register('name', { required: true })}
        />
        <TextField
          className="textfield-sm h-10 w-full rounded-lg text-15"
          type="tel"
          autoComplete="tel"
          placeholder="휴대폰 번호 ( - 없이 번호만 입력) "
          label="휴대폰 번호"
          labelClassname="block text-15 font-medium text-gray-700"
          {...register('phoneNumber', { required: true })}
        />
        <TextField
          className="textfield-sm input-pw h-10 w-full rounded-lg text-15"
          type="text"
          autoComplete="new-password"
          label="주문 비밀번호"
          labelClassname="block text-15 font-medium text-gray-700"
          placeholder="주문 비밀번호"
          {...register('password', { required: true })}
        />
        <div className="pt-4">
          <BugoBasicButton
            className="rounded-lg theme-bg-main"
            onClick={handleSubmit(onSubmit, onError)}
          >
            <p>주문내역조회</p>
          </BugoBasicButton>
        </div>
      </form>
    </div>
  );
};
