import { selector, selectorFamily } from 'recoil';

import { QueryDataState } from '@shared/interfaces';

import {
  activeFeventListForWorkerDataAtom,
  activeFeventListForWorkerStatusAtom,
  feventByShopDataAtomFamily,
  feventByShopStatusAtomFamily,
  feventCountByBugoBrandDataAtomFamily,
  feventCountByBugoBrandStatusAtomFamily,
  feventDataAtomFamily,
  feventDoingListDataAtom,
  feventDoingListStatusAtom,
  feventListAgencyAdminDataAtomFamily,
  feventListAgencyAdminStatusAtomFamily,
  feventListByBugoBrandDataAtomFamily,
  feventListByBugoBrandStatusAtomFamily,
  feventListDataAtom,
  feventListStatusAtom,
  feventStatusAtomFamily,
} from './fevent.atom';
import {
  FeventByShop,
  FeventCountByBugoBrandState,
  FeventDoingListState,
  FeventListByBugoBrandState,
  FeventListState,
  FeventState,
} from './fevent.interface';

export const feventStateSelectorFamily = selectorFamily<FeventState, string>({
  key: 'feventStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(feventStatusAtomFamily(id)),
        data: get(feventDataAtomFamily(id)),
      };
    },
});

export const feventListStateSelector = selector<FeventListState>({
  key: 'feventListStateSelector',
  get: ({ get }) => {
    return {
      status: get(feventListStatusAtom),
      data: get(feventListDataAtom),
    };
  },
});

export const activeFeventListForWorkerStateSelector = selector<FeventListState>({
  key: 'activeFeventListForWorkerStateSelector',
  get: ({ get }) => {
    return {
      status: get(activeFeventListForWorkerStatusAtom),
      data: get(activeFeventListForWorkerDataAtom),
    };
  },
});

export const feventDoingListStateSelector = selector<FeventDoingListState>({
  key: 'feventDoingListStateSelector',
  get: ({ get }) => {
    return {
      status: get(feventDoingListStatusAtom),
      data: get(feventDoingListDataAtom),
    };
  },
});

//*상조관리자가 볼 fevent
export const feventListByBugoBrandStateSelectorFamily = selectorFamily<
  FeventListByBugoBrandState,
  string
>({
  key: 'feventListByBugoBrandStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(feventListByBugoBrandStatusAtomFamily(id)),
        data: get(feventListByBugoBrandDataAtomFamily(id)),
      };
    },
});

//*상조관리자가 볼 fevent(refactoring)
export const feventListAgencyAdminStateSelectorFamily = selectorFamily<
  FeventListByBugoBrandState,
  string
>({
  key: 'feventListAgencyAdminStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(feventListAgencyAdminStatusAtomFamily(id)),
        data: get(feventListAgencyAdminDataAtomFamily(id)),
      };
    },
});

export const feventCountByBugoBrandStateSelectorFamily = selectorFamily<
  FeventCountByBugoBrandState,
  string
>({
  key: 'feventCountByBugoBrandStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(feventCountByBugoBrandStatusAtomFamily(id)),
        data: get(feventCountByBugoBrandDataAtomFamily(id)),
      };
    },
});

export const feventByShopStateSelectorFamily = selectorFamily<
  QueryDataState<FeventByShop[]>,
  string
>({
  key: 'feventByShopStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(feventByShopStatusAtomFamily(id)),
        data: get(feventByShopDataAtomFamily(id)),
      };
    },
});
