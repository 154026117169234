import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { UseFormSetValue } from 'react-hook-form';
import { useId } from 'react-id-generator';

import { cashAccountListByFeventAtom } from '../containers/MemberListEditor/memberListEditor.atom';
import VerifiedCashAccountListTable from './VerifiedCashAccountListTable';

interface Props {
  openId: string;
  index: number;
  setValue: UseFormSetValue<any>;
}

export const CashAccountSelectModal: FC<Props> = function CashAccountSelectModal({
  openId,
  index,
  setValue,
}: Props) {
  const [id] = useId(1, 'cashAccountSelect');
  const cashAccountListByFevent = useRecoilValue(cashAccountListByFeventAtom);

  const [selectedItem, setSelectedItem] = useState<any>();

  const verifiedCashAccountListByFevent = cashAccountListByFevent.filter((item) => {
    return item.isVerified;
  });

  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const selectedUpdateButton = useMemo(() => {
    return (
      <Button
        className="filled-gray-800 w-full py-1 text-base font-medium"
        text="확인"
        onClick={async () => {
          try {
            if (selectedItem) {
              setValue(`member.${index}.cashAccountId`, selectedItem._id);
              setValue(`member.${index}.cashAccount`, selectedItem.account);
              setValue(`member.${index}.bank`, selectedItem.bank);
              setValue(`member.${index}.accountHolder`, selectedItem.name);
              setValue(`member.${index}.isVerified`, selectedItem.isVerified);
              setValue(`member.${index}.cashAccountModalConfirmed`, true);
            }
            setOpen(false);
          } catch (err: any) {
            // console.log(err);
          }
        }}
        disabled={!selectedItem}
      />
    );
  }, [index, selectedItem, setOpen, setValue]);

  return (
    <Modal id={id} openId={openId}>
      <div className="space-y-4">
        <h2 className="mb-4 w-full border-b-2 border-gray-500 pb-2 text-lg font-bold">
          인증된 계좌 선택
          <p className="pt-2 text-sm font-medium text-gray-500">
            인증된 계좌를 선택 사용하실 수 있습니다.
          </p>
        </h2>
        <div className="space-y-4">
          <VerifiedCashAccountListTable
            cashAccountList={verifiedCashAccountListByFevent}
            radioName="cash-account-select-modal-check"
            onChange={(cashAccount) => setSelectedItem(cashAccount)}
          />
          <div className="flex justify-center pt-2">
            <div className="flex w-1/2 gap-3">
              <Button
                className="filled-gray-200 w-full py-1 text-base font-medium text-gray-800"
                text="취소"
                onClick={() => setOpen(false)}
              />
              {selectedUpdateButton}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
