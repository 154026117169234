import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoBrandListStateSelector } from '@shared/api/bugoBrand/bugoBrand.selector';
import { Loading } from '@shared/components/Loading';
import { useParams } from 'react-router-dom';

import { AdminBUgoBrandMultiAGencyDeleteModal } from '../containers/AdminBugoBrandMultiAgency/AdminBUgoBrandMultiAGencyDeleteModal';
import {
  adminBugoBrandMultiAgencyDeleteIndexAtom,
  adminBugoBrandMultiAgencyDeleteModalOpenId,
  adminBugoBrandMultiAgencyIndexAtom,
  adminBugoBrandMultiAgencyModalOpenId,
} from '../containers/AdminBugoBrandMultiAgency/AdminBugoBrandMultiAgency.atom';
import { AdminBugoBrandMultiAgencyFormModal } from '../containers/AdminBugoBrandMultiAgency/AdminBugoBrandMultiAgencyFormModal';
import AdminBugoBrandMultiAgencyTable from '../containers/AdminBugoBrandMultiAgency/AdminBugoBrandMultiAgencyTable';
import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const AdminBugoBrandMultiAgencyEditPage: FC = function () {
  useAdminTitleHook('멀티 상조 수정');

  const { bugoBrandId } = useParams();

  const bugoBrandListState = useRecoilValue(bugoBrandListStateSelector);

  const index = useRecoilValue(adminBugoBrandMultiAgencyIndexAtom(`${bugoBrandId}`));

  const deleteIndex = useRecoilValue(
    adminBugoBrandMultiAgencyDeleteIndexAtom(`${bugoBrandId}`),
  );

  const modalOpenId = adminBugoBrandMultiAgencyModalOpenId(`${bugoBrandId}`);

  const deleteModalOpenId = adminBugoBrandMultiAgencyDeleteModalOpenId(`${bugoBrandId}`);

  const bugoBrand = useMemo(() => {
    if (bugoBrandListState.status === 'success' && bugoBrandListState.data) {
      const bugoBrandList = bugoBrandListState.data;
      return bugoBrandList.find((item) => {
        return item._id === bugoBrandId;
      });
    }
  }, [bugoBrandId, bugoBrandListState.data, bugoBrandListState.status]);

  const mutliAgencyTable = useMemo(() => {
    if (bugoBrand) {
      return (
        <>
          <AdminBugoBrandMultiAgencyTable bugoBrand={bugoBrand} />
          <AdminBugoBrandMultiAgencyFormModal
            openId={modalOpenId}
            bugoBrand={bugoBrand}
            multiAgencyIndex={index}
          />
          <AdminBUgoBrandMultiAGencyDeleteModal
            openId={deleteModalOpenId}
            bugoBrand={bugoBrand}
            multiAgencyIndex={deleteIndex ?? 9999}
          />
        </>
      );
    } else {
      return <Loading />;
    }
  }, [bugoBrand, deleteIndex, deleteModalOpenId, index, modalOpenId]);

  return <div className="p-4">{mutliAgencyTable}</div>;
};

export default AdminBugoBrandMultiAgencyEditPage;
