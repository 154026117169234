import { FC, useMemo } from 'react';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { Icon } from '@shared/components/icons';
import { Link } from 'react-router-dom';
import wreathImg from 'src/assets/image/wreathImage.png';

interface Props {
  bugo: BugoData;
}

export const FlowerBottomBanner: FC<Props> = function FlowerBottomBanner({
  bugo,
}: Props) {
  const { CaretDown } = Icon();
  const shopItemImage = useMemo(() => {
    return <img src={wreathImg} alt="상품이미지" className="object-cover" />;
  }, []);

  return (
    <Link
      to={`/flower-store/?bugoId=${bugo._id}`}
      className="grid items-center rounded-lg font-sans theme-bg-5"
      style={{
        gridTemplateColumns: '0.6fr 0.4fr',
      }}
    >
      <div className="space-y-4 break-keep py-6 pl-6 font-medium">
        <div className="text-15">
          <p>쉽고 간편하게</p>
          <p> 위로의 마음을 전해주세요</p>
        </div>
        <div className="flex items-center gap-1 text-20 font-bold">
          <p>근조화환 보내기</p>
          <CaretDown className="wh-5 -rotate-90 fill-gray-700" />
        </div>
        <div className="text-13 font-medium">
          <p>번거로운 주소 입력 없이</p>
          <p>해당 장례식장으로</p>
          <p>당일배송 해드립니다.</p>
          <p>{`(19시이전 주문 시)`}</p>
        </div>
      </div>
      <div className="aspect-w-10 aspect-h-14 w-full">{shopItemImage}</div>
    </Link>
  );
};
