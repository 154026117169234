import { atom } from 'recoil';

import { QueryStatus } from 'react-query';

import { CourtesyAssetData, CourtesyContent } from './courtesyAsset.interface';

//* bugoBrand owner가 본인의 답례글 asset을 볼때 사용함
export const courtesyAssetListByBugoBrandStatusAtom = atom<QueryStatus>({
  key: 'courtesyAssetListByBugoBrandStatusAtom',
  default: 'idle',
});

export const courtesyAssetListByBugoBrandDataAtom = atom<CourtesyAssetData[] | null>({
  key: 'courtesyAssetListByBugoBrandDataAtom',
  default: null,
});

//* bugoBrand owner가 본인의 답례글 content를 볼때 사용함
export const courtesyContentListByBugoBrandStatusAtom = atom<QueryStatus>({
  key: 'courtesyContentListByBugoBrandStatusAtom',
  default: 'idle',
});

export const courtesyContentListByBugoBrandDataAtom = atom<CourtesyContent[] | null>({
  key: 'courtesyContentListByBugoBrandDataAtom',
  default: null,
});

//* member가 접근가능한 courtesy-asset
export const courtesyAssetListByMemberStatusAtom = atom<QueryStatus>({
  key: 'courtesyAssetListByMemberStatusAtom',
  default: 'idle',
});

export const courtesyAssetListByMemberDataAtom = atom<CourtesyAssetData[] | null>({
  key: 'courtesyAssetListByMemberDataAtom',
  default: null,
});

//* bugoBrand owner가 본인의 답례글 content를 볼때 사용함
export const courtesyContentListByMemberStatusAtom = atom<QueryStatus>({
  key: 'courtesyContentListByMemberStatusAtom',
  default: 'idle',
});

export const courtesyContentListByMemberDataAtom = atom<CourtesyContent[] | null>({
  key: 'courtesyContentListByMemberDataAtom',
  default: null,
});
