import { atom } from 'recoil';

export const redirectLoginPath = atom<string>({
  key: 'redirectLoginPath',
  default: '/',
});

export const redirectErrorPath = atom<string>({
  key: 'redirectErrorPath',
  default: '/',
});
