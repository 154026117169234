import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { phoneNumberStringFormator } from '@shared/utils/phoneNumberStringFormator';

interface FlowerOrderInfoSummaryCardProps {
  shopOrder: ShopOrderForSeller;
}

export const ShopOrderDetailInfoCard = function ShopOrderDetailInfoCard({
  shopOrder,
}: FlowerOrderInfoSummaryCardProps) {
  return (
    <div className="space-y-2 rounded-md border bg-white px-4 py-2 shadow-sm">
      <div className="flex w-full justify-between gap-4 border-b pb-2">
        <div className="w-1/2 space-y-2">
          <p className="min-w-0 flex-1 text-sm font-bold">받으시는 분</p>
          <div>
            <p className="min-w-0 flex-1 text-sm">
              {shopOrder.deliveryDetail.receiverName}
            </p>
            <p className="text-sm font-medium">
              {phoneNumberStringFormator(shopOrder.deliveryDetail.receiverPhoneNumber)}
            </p>
          </div>
        </div>
        <div className="w-1/2 space-y-2">
          <p className="min-w-0 flex-1 text-sm font-bold">주문자명</p>
          <div>
            <p className="min-w-0 flex-1 text-sm">
              {shopOrder.deliveryDetail.senderName}
            </p>
            <a href={`tel:${shopOrder.deliveryDetail.senderPhoneNumber}`}>
              <p className="text-sm font-medium">
                {phoneNumberStringFormator(shopOrder.deliveryDetail.senderPhoneNumber)}
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <p className="text-sm font-bold">문구정보</p>
        <div className={`flex w-full justify-between gap-4 `}>
          <div className="w-1/2">
            <p className="pb-0.5 text-12 font-medium">보내는분(왼쪽)</p>
            <p className="text-sm">{shopOrder.bugoDetail.senderPhrase}</p>
          </div>
          <div className="w-1/2">
            <p className="pb-0.5 text-12 font-medium">조의문구(오른쪽)</p>
            <p className="text-sm">{shopOrder.bugoDetail.condolencePhrase}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
