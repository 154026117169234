import { FC, useRef } from 'react';

import { Button } from '@shared/components/Button';
import { TextField } from '@shared/components/TextField';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';

interface ChangePasswordForm {
  presentPassword: string;
  password: string;
  passwordValidataion: string;
}

const UserChangePasswordPage: FC = function UserChangePasswordPage() {
  useTitleHook('비밀번호 변경');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ChangePasswordForm>();
  const { patchMePassword } = useAuth();
  const navigate = useNavigate();

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit: SubmitHandler<ChangePasswordForm> = async (data) => {
    await patchMePassword(data.password);
    navigate('/');
  };

  const showAlert = () => {
    if (Object.keys(errors).length > 0) {
      alert(Object.values(errors)[0]?.message);
    }
  };

  return (
    <div className="space-y-3 py-3 text-sm">
      <div className="space-y-4 p-8 theme-bg-1">
        <h5 className="font-bold theme-text-1">비밀번호 변경</h5>
        {/* <TextField
          placeholder="현재 비밀번호"
          className="w-full"
          type="password"
          {...register('presentPassword')}
        ></TextField> */}
        <div className="space-y-1">
          <TextField
            type="password"
            placeholder="새 비밀번호"
            className="w-full"
            {...register('password', {
              required: {
                value: true,
                message: '비밀번호를 입력해주세요.',
              },
              minLength: {
                value: 4,
                message: '4~16자리의 비밀번호를 입력해주세요.',
              },
              maxLength: {
                value: 16,
                message: '4~16자리의 비밀번호를 입력해주세요.',
              },
            })}
          ></TextField>
          <TextField
            type="password"
            placeholder="새 비밀번호 확인"
            className="w-full"
            {...register('passwordValidataion', {
              validate: (value) =>
                value === password.current ||
                '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
            })}
          ></TextField>
        </div>

        <div className="space-y-2 pt-4">
          <Button
            className="button-rectangle theme-text-3 theme-bg-main"
            onClick={handleSubmit(onSubmit, showAlert)}
          >
            확인
          </Button>
          <Button
            type="button"
            className="button-rectangle border theme-border-3"
            onClick={() => {
              navigate(-1);
            }}
          >
            취소
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserChangePasswordPage;
