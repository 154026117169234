//* 상조관리자의 정산 내용 페이지

import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useShopOrderListAgencyAdminHookHook } from '@shared/api/shopOrder/shopOrder.hook';
import { shopOrderListAgencyAdminStateSeletor } from '@shared/api/shopOrder/shopOrder.selector';
import { Loading } from '@shared/components/Loading';
import { ReloadButton } from '@shared/components/ReloadButton';
import StatPageSideBar from '@shared/components/StatPageSideBar';
import { useMediaQuery } from 'react-responsive';

import { AgencyAdminAccountContent } from '../containers/AgencyAdminAccount/AgencyAdminAccountContent';
import { useTitleHook } from '../hooks/useTitleHook';
import ErrorPage from './ErrorPage';

const AgencyAdminAccountPage: FC = function AgencyAdminAccountPage() {
  useTitleHook('정산내역');
  /*
  TODO useShopOrderListByBugoBrandHook -> useShopOrderListAgencyAdminHook
    ? 사용되는 shopOrder의 fields
    - _id
    - paymentDetail
    - orderDetail
    - fevent(pop)
      - _id
      - user(pop)
        - _id
        - name
  */
  useShopOrderListAgencyAdminHookHook();
  const shopOrderListState = useRecoilValue(shopOrderListAgencyAdminStateSeletor);

  //*mobile 기준
  const isMobile = !useMediaQuery({
    query: '(min-width: 800px)',
  });
  const pcTable = useMemo(() => {
    if (shopOrderListState.status === 'success' && shopOrderListState.data) {
      const shopOrderList = shopOrderListState.data;
      return (
        <AgencyAdminAccountContent
          shopOrderList={shopOrderList}
        ></AgencyAdminAccountContent>
      );
    } else {
      return (
        <div className="center-box">
          <Loading></Loading>
        </div>
      );
    }
  }, [shopOrderListState.data, shopOrderListState.status]);

  if (isMobile) {
    return (
      <div>
        <ErrorPage
          text="정산 페이지는 모바일 환경에서 지원하지 않습니다."
          subText="PC환경에서 접속해주시거나 화면의 가로길이를 늘려주세요."
        ></ErrorPage>
      </div>
    );
  } else {
    return (
      <div className="flex">
        {/* Sidebar */}
        <div className="flex flex-col">
          <StatPageSideBar />
        </div>
        <div className="mx-auto max-w-screen-2xl flex-1">
          <div className="py-8 px-10">
            <div className="flex justify-between space-x-10">
              <div>
                <p className="text-20 font-bold">연간 정산내역</p>
              </div>
              <div className="center-box h-full gap-4">
                <ReloadButton />
              </div>
            </div>
            <div className="pt-4">{pcTable}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default AgencyAdminAccountPage;
