import { FC } from 'react';

import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { useNavigate } from 'react-router-dom';

interface Props {
  announceString: string;
}

const ErrorNotFound: FC<Props> = ({ announceString }: Props) => {
  //   const redirectPath = useRecoilValue(redirectErrorPath);
  const { Error } = Icon();

  const navigate = useNavigate();
  return (
    <div className="flex h-screen flex-col items-center justify-between bg-gray-800 px-6 pb-10 text-white">
      {/* 배치를 위한 empty div */}
      <div></div>
      {/* 오류 문구 */}
      <div className="flex flex-col items-center bg-transparent">
        {/* 체크 | 느낌표 아이콘 */}
        <Error />
        <span className="mt-5 text-base font-bold leading-[22px]">
          해당 페이지를 찾을 수 없습니다.
        </span>
        {/* 주문 정보 */}
        <p className="mt-1.5 text-center text-sm text-gray-100">{announceString}</p>
      </div>
      {/* redirect button */}
      <Button
        className="button-rectangle theme-bg-main"
        onClick={() => {
          navigate(-1);
        }}
      >
        돌아가기
      </Button>
    </div>
  );
};

export default ErrorNotFound;
