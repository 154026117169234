import { FC } from 'react';

import { SellerDeliveryManagePage } from '@service/seller/page/SellerDeliveryManagePage';
import { ShopHomePage } from '@service/seller/page/SellerShopHomePage';
import { useShopOrderListForSellerByShopHook } from '@shared/api/shopOrder/shopOrder.hook';
import { Route, Routes, useParams } from 'react-router-dom';

export const SellerShopUseShopOrderRouter: FC = function SellerShopUseShopOrderRouter() {
  const { shopId } = useParams();
  const shopId_ = shopId ?? 'undefined';

  //* 배송관리용
  useShopOrderListForSellerByShopHook(shopId_);

  return (
    <Routes>
      <Route path="" element={<ShopHomePage />} />
      <Route path="delivery-manage" element={<SellerDeliveryManagePage />} />
      {/* <Route path="account" element={<AccountPage />} />
      <Route path="delivered-image" element={<DeliveredImageListPage />} /> */}
    </Routes>
  );
};
