import { FC, Fragment, useMemo } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, SearchIcon } from '@heroicons/react/solid';
import { sellerNavSearchBarIdAtom, sellerNavTitleAtom } from '@service/bugo/state';
import { Icon } from '@shared/components/icons';
import { searchTextAtomFamily } from '@shared/containers/SearchBar/state/searchBar.atom';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { classNames } from '@shared/utils/joinClassNamesUtils';
import { Link, useParams } from 'react-router-dom';

export const SellerTopNavBar: FC = function SellerTopNavBar() {
  const { shopId } = useParams();
  const shopId_ = shopId ?? 'undefined';

  const sideOpenId = `AdvancedSidebar-flower-seller-shop`;
  const setSidebarOpen = useSetRecoilState(simpleOpenAtomFamily(sideOpenId));
  const title = useRecoilValue(sellerNavTitleAtom);
  const searchBarId = useRecoilValue(sellerNavSearchBarIdAtom);
  const [searchText, setSearchText] = useRecoilState(searchTextAtomFamily(searchBarId));

  const { Menu: MenuIcon } = Icon();
  const { logout, userProfile } = useAuth();

  const searchBarRender = useMemo(() => {
    return (
      <div className="flex flex-1">
        {searchBarId !== '' ? (
          <form className="flex w-full sm:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div
                className="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                aria-hidden="true"
              >
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <input
                id="search-field"
                name="search-field"
                className="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                placeholder="배송 간편 검색"
                type="search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.currentTarget.value);
                }}
              />
            </div>
          </form>
        ) : (
          <div className="flex flex-1 items-center">
            <p className="text-18 font-bold">{title}</p>
          </div>
        )}
      </div>
    );
  }, [searchBarId, searchText, title, setSearchText]);

  return (
    <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white lg:border-none ">
      <button
        type="button"
        className="border-r border-gray-200 px-4 text-gray-400 lg:hidden"
        onClick={() => {
          setSidebarOpen(true);
        }}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
        <div className="flex h-full justify-between lg:border-b">
          {/* Search bar */}
          {searchBarRender}
          <div className="z-20 ml-4 flex items-center sm:ml-6">
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                    <span className="sr-only">Open user menu for </span>
                    {userProfile?.info.name}
                  </span>
                  <ChevronDownIcon
                    className="ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={`/flower-seller/shop/${shopId_}/info`}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        판매자정보
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={`/flower-seller/shop/${shopId_}/settings`}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        설정
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block w-full px-4 py-2 text-left text-sm text-gray-700',
                        )}
                        onClick={() => {
                          logout();
                        }}
                      >
                        로그아웃
                      </button>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};
