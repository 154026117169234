//* 관리자 유저 수정 및 생성을 위한 Form interface

import { UserCustomTheme } from '@shared/types';

export enum BugoBrandAgencyAdminFormInputsEnum {
  UserName = 'username',
  Password = 'password',
  Name = 'name',
  Theme = 'theme',
}

export interface BugoBrandAgencyAdminFormInputs {
  [BugoBrandAgencyAdminFormInputsEnum.UserName]?: string;
  [BugoBrandAgencyAdminFormInputsEnum.Password]?: string;
  [BugoBrandAgencyAdminFormInputsEnum.Name]: string;
  [BugoBrandAgencyAdminFormInputsEnum.Theme]: UserCustomTheme;
}
