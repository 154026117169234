import { atom } from 'recoil';

import { Bugo } from '@shared/api/bugo/bugo.interface';
import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { RoomInfo } from '@shared/api/roomInfo/roomInfo.interface';
import { Shop } from '@shared/api/shop/shop.interface';
import { QueryStatus } from 'react-query';

import { Member } from '../member/member.interface';
import { CallOrder } from './callOrder.interface';

export const callOrderListStatusAtom = atom<QueryStatus>({
  key: 'callOrderListStatusAtom',
  default: 'idle',
});

export const callOrderListDataAtom = atom<
  | CallOrder<
      Bugo<string, Fevent<string, FuneralHomeInfo, RoomInfo>, string, Member>,
      Shop
    >[]
  | null
>({
  key: 'callOrderDataAtom',
  default: null,
});

export const callOrderBugoIdAtom = atom<string | undefined>({
  key: 'callOrderBugoIdAtom',
  default: undefined,
});

export const callOrderShopIdAtom = atom<string | undefined>({
  key: 'callOrderShopIdAtom',
  default: undefined,
});
