//* fevent가 주어질때 판매가능한 상품을 선택할수 있도록함
import { FC, useMemo, useState } from 'react';

import { FeventByShop } from '@shared/api/fevent/fevent.interface';
import { Image } from '@shared/api/media/media.interface';
import { sellerShopItemListByFeventUrl } from '@shared/api/shopItem/shopItem.hook';
import {
  ShopItem,
  ShopItemListByBugoForStoreData,
} from '@shared/api/shopItem/shopItem.interface';
import { getShopItemImageUrl } from '@shared/api/shopItem/shopItem.utils';
import { ShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.interface';
import { Button } from '@shared/components/Button';
import { Loading } from '@shared/components/Loading';
import { api_ } from '@shared/plugIn/axios';
import { CustomCallback } from '@shared/types';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { useAsync } from 'react-use';

interface Props {
  fevent: FeventByShop | null;
  onSelectShopItem: CustomCallback<
    ShopItem<ShopItemInfo<string, Image>, string, string, Image>,
    void
  >;
}

export const SelectShopItemForm: FC<Props> = function SelectShopItemForm({
  fevent,
  onSelectShopItem,
}) {
  const [shopItemList, setShopItemList] = useState<
    ShopItemListByBugoForStoreData | undefined
  >(undefined);

  useAsync(async () => {
    const { data: shopItemList_ } = await api_.get<ShopItemListByBugoForStoreData>(
      sellerShopItemListByFeventUrl(`${fevent?._id}`),
    );
    setShopItemList(shopItemList_);
  }, []);

  const shopItemSelect = useMemo(() => {
    if (shopItemList) {
      return (
        <div className="grid grid-cols-3 gap-2">
          {shopItemList.map((shopItem) => {
            return (
              <Button
                type="button"
                key={shopItem._id}
                className="flex flex-col items-center justify-center"
                onClick={() => {
                  onSelectShopItem(shopItem);
                }}
              >
                <img src={fileBucketUrl(getShopItemImageUrl(shopItem), 'w400')}></img>
                <p>{shopItem.name ?? shopItem.shopItemInfo?.name}</p>
                <p>{shopItem.priceRetail.toLocaleString()}원</p>
              </Button>
            );
          })}
        </div>
      );
    } else {
      <div className="center-box">
        <Loading></Loading>
      </div>;
    }
  }, [onSelectShopItem, shopItemList]);

  return (
    <div>
      <div className="space-y-4 text-center">
        <p className="bugo-h1 text-18">상품 선택</p>
        {shopItemSelect}
      </div>
    </div>
  );
};
