import { selector, selectorFamily } from 'recoil';

import { QueryDataState } from '@shared/interfaces';

import {
  sellerShopDataAtomFamily,
  sellerShopListDataAtom,
  sellerShopListStatusAtom,
  sellerShopStatusAtomFamily,
} from './shop.atom';
import { Shop, ShopData } from './shop.interface';

export const sellerShopListStateSelector = selector<QueryDataState<Shop[]>>({
  key: 'sellerShopListStateSelector',
  get: ({ get }) => {
    return {
      status: get(sellerShopListStatusAtom),
      data: get(sellerShopListDataAtom),
    };
  },
});

export const sellerShopStateSelectorFamily = selectorFamily<
  QueryDataState<ShopData>,
  string
>({
  key: 'sellerShopStateSelectorFamily',
  get:
    (shopId) =>
    ({ get }) => {
      return {
        status: get(sellerShopStatusAtomFamily(shopId)),
        data: get(sellerShopDataAtomFamily(shopId)),
      };
    },
});
