import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import {
  useShopOrderNotPaidListForSellerByShopHook,
  useShopOrderWaitingListForSellerByShopHook,
} from '@shared/api/shopOrder/shopOrder.hook';
import {
  shopOrderNotPaidListForSellerByShopStateSelectorFamily,
  shopOrderWaitingListForSellerByShopStateSelectorFamily,
} from '@shared/api/shopOrder/shopOrder.selector';
import { useParams } from 'react-router-dom';

import { NotPaidOrderCard } from '../components/NotPaidOrderCard';
import { WaitingShopOrderCard } from '../components/WaitingShopOrderCard';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

export const SellerWaitingListPage: FC = function SellerWaitingListPage() {
  const { shopId } = useParams();

  useSellerTopNavHook('입금대기내역');

  useShopOrderWaitingListForSellerByShopHook(`${shopId}`);
  useShopOrderNotPaidListForSellerByShopHook(`${shopId}`);

  const shopOrderWaitingListState = useRecoilValue(
    shopOrderWaitingListForSellerByShopStateSelectorFamily(`${shopId}`),
  );

  const shopOrderNotPaidListState = useRecoilValue(
    shopOrderNotPaidListForSellerByShopStateSelectorFamily(`${shopId}`),
  );

  const waitingOrderGrid = useMemo(() => {
    if (
      shopOrderWaitingListState.status === 'success' &&
      shopOrderWaitingListState.data
    ) {
      const shopOrderList = shopOrderWaitingListState.data;
      return (
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {shopOrderList.map((shopOrder) => {
            return <WaitingShopOrderCard shopOrder={shopOrder} key={shopOrder._id} />;
          })}
        </div>
      );
    }
  }, [shopOrderWaitingListState.data, shopOrderWaitingListState.status]);

  const noPaidVBankGrid = useMemo(() => {
    if (
      shopOrderNotPaidListState.status === 'success' &&
      shopOrderNotPaidListState.data
    ) {
      const shopOrderList = shopOrderNotPaidListState.data;
      return (
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {shopOrderList.map((shopOrder) => {
            return <NotPaidOrderCard order={shopOrder} key={shopOrder._id} />;
          })}
        </div>
      );
    }
  }, [shopOrderNotPaidListState.data, shopOrderNotPaidListState.status]);

  return (
    <div className="mx-auto max-w-6xl px-4 pb-8 sm:px-6 lg:px-8">
      <div className="mt-8 space-y-4">
        <p className="w-full font-bold">계좌 입금</p>
        {waitingOrderGrid}
      </div>
      <div className="mt-8 space-y-4">
        <p className="w-full font-bold">
          가상 계좌 입금
          <span className="font-normal">
            (가상 계좌 입금 대기목록들 자동으로 처리되나 오랫동안 입금되지 않을경우
            전화해보기)
          </span>
        </p>
        {noPaidVBankGrid}
      </div>
    </div>
  );
};
