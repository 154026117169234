import { StoreItemTagEnum } from '@shared/interfaces/storeItemTag.enum';

export const getStoreItemTagColorStyle = (tagName: StoreItemTagEnum): string => {
  let style = 'text-white';

  switch (tagName) {
    case StoreItemTagEnum.Best:
      style += ' bg-black';
      break;
    case StoreItemTagEnum.Sale:
      style += ' bg-myTeal';
      break;
    case StoreItemTagEnum.Popular:
      style += ' bg-myApricot';
      break;
    case StoreItemTagEnum.SpecialPrice:
      style += ' bg-[#ED6335]';
      break;
    case StoreItemTagEnum.FreeDelivery:
      style += ' bg-gray-500';
      break;
    case StoreItemTagEnum.Primium:
      style += ' bg-black';
      break;
    default:
      break;
  }

  return style;
};
