import { FC } from 'react';

import { RoomInfo } from '@shared/api/roomInfo/roomInfo.interface';
import { TextField } from '@shared/components/TextField';
import { queryString } from '@shared/hooks/recoil-query';
import { api_ } from '@shared/plugIn/axios';
import { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useAsync } from 'react-use';

import {
  DeceasedInfoFormInputsEnum,
  DeceasedInfoIFormInputs,
} from '../../../@service/bugo/interfaces/feventAddEdit.interface';
import RoomInfoAutoComplete from './RoomInfoAutoComplete';

interface IProps {
  funeralHomeInfoId: string;
  roomInfoId: string;
  roomInfoEmebedName?: string;
  setValue: UseFormSetValue<DeceasedInfoIFormInputs>;
  control: Control<DeceasedInfoIFormInputs, any>;
  register: UseFormRegister<DeceasedInfoIFormInputs>;
}

const RoomInfoSearcher: FC<IProps> = ({
  funeralHomeInfoId,
  roomInfoId,
  roomInfoEmebedName,
  setValue,
  control,
  register,
}: IProps) => {
  const roomInfoListState = useAsync(async () => {
    if (funeralHomeInfoId) {
      const { data: roomInfoData } = await api_.get<RoomInfo[]>(
        `public/room-info/?${queryString({
          filter: {
            funeralHomeInfo: funeralHomeInfoId,
          },
        })}`,
      );
      const roomInfoMatched = roomInfoData.find((item) => {
        return item._id === roomInfoId;
      });
      if (!roomInfoMatched) {
        setValue(DeceasedInfoFormInputsEnum.RoomInfo, '');
      }
      return roomInfoData;
    } else return [];
  }, [funeralHomeInfoId]);

  const roomOptions = roomInfoListState.value?.map((roomInfo) => {
    return {
      value: roomInfo._id,
      label: roomInfo.name,
    };
  });

  return roomOptions ? (
    <RoomInfoAutoComplete
      placeholder={'호실 선택'}
      optionList={roomOptions}
      control={control}
      name={DeceasedInfoFormInputsEnum.RoomInfo}
      roomInfoEmebedName={roomInfoEmebedName}
      directInputName={DeceasedInfoFormInputsEnum.RoomInfoEmbedName}
    />
  ) : (
    <TextField
      className="w-full"
      placeholder="호실 선택"
      {...register(DeceasedInfoFormInputsEnum.RoomInfoEmbedName)}
      required={true}
    ></TextField>
  );
};

export default RoomInfoSearcher;
