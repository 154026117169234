import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoBrandListStateSelector } from '@shared/api/bugoBrand/bugoBrand.selector';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { queryString } from '@shared/hooks/recoil-query';
import { api_ } from '@shared/plugIn/axios';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';

import { AdminAgencyAdminUpsertModal } from '../components/AdminAgencyAdminUpsertModal';
import { AgencyAdminCard } from '../components/AgencyAdminCard';
import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

//* 관리자용, 상조관리자 계정 생성 및 수정 페이지
const AdminBugoBrandAgencyAdminPage: FC = function AdminBugoBrandAgencyAdminPage() {
  const { PlusSolid } = Icon();
  const { bugoBrandId } = useParams();
  const bugoBrandListState = useRecoilValue(bugoBrandListStateSelector);
  const adminAgencyCreateOpenId = `adminBugoBrandAgencyAdmin-create-${bugoBrandId}`;
  const setCreateModalOpen = useSetRecoilState(
    simpleOpenAtomFamily(adminAgencyCreateOpenId),
  );
  const [agencyAdminUserList, setAgencyAdminUserList] = useState<User[]>([]);

  const bugoBrand = useMemo(() => {
    if (bugoBrandListState.status === 'success' && bugoBrandListState.data) {
      const bugoBrandList = bugoBrandListState.data;
      return bugoBrandList.find((item) => {
        return item._id === bugoBrandId;
      });
    }
  }, [bugoBrandId, bugoBrandListState.data, bugoBrandListState.status]);

  useAsync(async () => {
    if (bugoBrand) {
      const { data: agencyAdminList } = await api_.get<User[]>(
        `/admin/user/?${queryString({
          filter: {
            _id: { $in: bugoBrand.users },
          },
        })}`,
      );
      if (agencyAdminList) {
        setAgencyAdminUserList(agencyAdminList);
      }
    }
  }, [bugoBrand]);

  //* 관리자 추가
  const adminAgencyCreateButton = useMemo(() => {
    if (bugoBrand) {
      return (
        <>
          <Button
            className="center-box gap-1 shadow-sm theme-bg-1"
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            <PlusSolid className="wh-4" />
            <p>관리자계정 생성</p>
          </Button>
          <AdminAgencyAdminUpsertModal
            openId={adminAgencyCreateOpenId}
            bugoBrand={bugoBrand}
          />
        </>
      );
    }
  }, [PlusSolid, adminAgencyCreateOpenId, bugoBrand, setCreateModalOpen]);

  //* 관리자 수정
  const adminAgencyCardGrid = useMemo(() => {
    if (agencyAdminUserList && bugoBrand) {
      return agencyAdminUserList.map((adminUser) => {
        return (
          <AgencyAdminCard key={adminUser._id} user={adminUser} bugoBrand={bugoBrand} />
        );
      });
    }
  }, [agencyAdminUserList, bugoBrand]);

  useAdminTitleHook('상조 관리자계정 관리');
  return (
    <div className="space-y-4 p-4">
      <div className="flex items-center justify-between">
        <p className="pl-2 text-16 font-bold">{bugoBrand?.bugoAgency?.corpName}</p>
        {adminAgencyCreateButton}
      </div>
      <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">{adminAgencyCardGrid}</div>
    </div>
  );
};

export default AdminBugoBrandAgencyAdminPage;
