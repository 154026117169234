import { Bugo } from '@shared/api/bugo/bugo.interface';

//* 실제 입력으로 사용하는 것
export enum MemberBugoSendFormInputsEnum {
  MournerText = 'mournerText',
  CoffinInShow = 'config.coffinInShow',
  CashAccountShow = 'cofing.cashAccountShow',
  PhoneNumber = 'phoneNumber',
}

export interface MemberBugoIFormInput {
  _id: string;
  bugo?: Bugo;
  bugoId?: string;
  mournerText?: string;
  memberId?: string;
  phoneNumber?: string;
}

export interface MemberBugoIFormInputs {
  memberBugo: MemberBugoIFormInput[];
}

export const vacantMemberBugoInputForm = [
  {
    _id: '',
    [MemberBugoSendFormInputsEnum.MournerText]: '',
    [MemberBugoSendFormInputsEnum.PhoneNumber]: '',
  },
];
