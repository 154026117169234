import { FC } from 'react';

import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

const SellerAccountDetailPage: FC = function SellerAccountDetailPage() {
  useSellerTopNavHook('세부정산내역');

  return <div></div>;
};

export default SellerAccountDetailPage;
