import React, { FC, Suspense } from 'react';

import { IMapProps } from '@shared/interfaces/map.interface';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from './ErrorFallback';

// import { Loading } from './Loading';

const MapSourceNaver = React.lazy(() => import('@shared/components/MapSourceNaver'));

const Map: FC<IMapProps> = function ({ coord, name }: IMapProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={null}>
        <div className="relative z-0 h-full w-full">
          <MapSourceNaver coord={coord} name={name} />
        </div>
      </Suspense>
    </ErrorBoundary>
  );
};

export default Map;
