import { ButtonHTMLAttributes, FC } from 'react';

import { useNavigate } from 'react-router-dom';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  to?: string;
}

export const Button: FC<ButtonProps> = function Button({
  text,
  to,
  children,
  className = '',
  onClick,
  ...props
}: ButtonProps) {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className={`button ${className}`}
      onClick={to ? () => navigate(to) : onClick}
      {...props}
    >
      {text ?? children}
    </button>
  );
};
