import { FC } from 'react';

import { Icon } from '@shared/components/icons';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Link } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';

const AgencyAdminBugoBrandAssetManagePage: FC =
  function AgencyAdminBugoBrandAssetManagePage() {
    useTitleHook('어셋관리');
    const { bugoBrandId } = useAuth();
    const { ChevronRight, Courtesy } = Icon();

    return (
      <div className="space-y-6 p-4 py-6 pb-0">
        <div className="flex flex-col gap-3">
          <Link
            to={`/bugo-brand/${bugoBrandId}/asset/courtesy`}
            className="px-3 py-2 pb-4 shadow-smd theme-bg-1"
          >
            <div className="grid w-full grid-cols-auto-1fr-auto items-center justify-between">
              <div className="pl-2 pr-4 ">
                <div className="center-box wh-10 rounded-full theme-bg-18">
                  <Courtesy />
                </div>
              </div>
              <p className="bugo-h1 w-full text-start">답례글</p>
              <div className="center-box wh-10">
                <ChevronRight />
              </div>
              <div></div>
              <p className="noto-sans-kor text-start text-12 font-normal leading-5 theme-text-8">
                답례글 배경이미지, 답례글 내용 관리
              </p>
            </div>
          </Link>
        </div>
      </div>
    );
  };

export default AgencyAdminBugoBrandAssetManagePage;
