import { selector } from 'recoil';

import { QueryDataState } from '@shared/interfaces';

import {
  bugoBrandListDataAtom,
  bugoBrandListStatusAtom,
  simpleBugoBrandListDataAtom,
  simpleBugoBrandListStatusAtom,
} from './bugoBrand.atom';
import { BugoBrand, BugoBrandData } from './bugoBrand.interface';
export const bugoBrandListStateSelector = selector<QueryDataState<BugoBrandData[]>>({
  key: 'bugoBrandListStateSelector',
  get: ({ get }) => {
    return {
      status: get(bugoBrandListStatusAtom),
      data: get(bugoBrandListDataAtom),
    };
  },
});

export const simpleBugoBrandListStateSelector = selector<QueryDataState<BugoBrand[]>>({
  key: 'simpleBugoBrandListStateSelector',
  get: ({ get }) => {
    return {
      status: get(simpleBugoBrandListStatusAtom),
      data: get(simpleBugoBrandListDataAtom),
    };
  },
});
