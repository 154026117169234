import { FC } from 'react';

import { Image } from '@shared/api/media/media.interface';
import { ShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.interface';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { useNavigate } from 'react-router-dom';

/**
 * ShopItem들을 Manage를 하기 위해서 ShopItemInfo를 보여주는 카드
 */

interface Props {
  shopItemInfo: ShopItemInfo<string, Image, Image>;
}

const ShopItemManageLinkCard: FC<Props> = function ShopItemManageLinkCard({
  shopItemInfo,
}: Props) {
  const navigate = useNavigate();
  return (
    <div
      className="min-w-[320px] cursor-pointer rounded-lg border-[1.5px] p-5 font-sans font-medium shadow-smd"
      onClick={() => {
        navigate(`shop-item-info/${shopItemInfo._id}`);
      }}
    >
      <div className="grid grid-cols-2">
        <div>
          <p>{shopItemInfo.name}</p>
          <p>도매가: {shopItemInfo.priceWhole.toLocaleString()}원</p>
        </div>
        <div>
          <img
            src={fileBucketUrl(shopItemInfo.image.url, 'w400')}
            className="max-h-[140px]"
          ></img>
        </div>
      </div>
    </div>
  );
};

export { ShopItemManageLinkCard };
