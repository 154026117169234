import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useSimpleBugoBrandListHook } from '@shared/api/bugoBrand/bugoBrand.hook';
import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { simpleBugoBrandListStateSelector } from '@shared/api/bugoBrand/bugoBrand.selector';
import { DistributionNetwork } from '@shared/api/distributionNetwork/distributionNetwork.interface';
import { Shop } from '@shared/api/shop/shop.interface';
import { Button } from '@shared/components/Button';
import { Label } from '@shared/components/Label';
import { Icon } from '@shared/components/icons';
import FuneralHomeInfoSearcher from '@shared/containers/FuneralHomeInfoSearcher/FuneralHomeInfoSearcher';
import Select from '@shared/containers/Select/Select';
import { queryString } from '@shared/hooks/recoil-query';
import { api_ } from '@shared/plugIn/axios';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useForm } from 'react-hook-form';

import { AdminDistributionNetworkTable } from '../components/AdminDistributionNetworkTable';
import { AdminDistributionNetworkUpsertModal } from '../components/AdminDistributionNetworkUpsertModal';
import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const AdminDistributionNetworkPage: FC = function AdminDistributionNetworkPage() {
  useAdminTitleHook('DN 관리');
  useSimpleBugoBrandListHook();
  const { control, setValue, handleSubmit } = useForm<{
    bugoBrand: string;
    funeralHomeInfo: string;
  }>();

  const { MagnifyingGlassRegular, PlusSolid } = Icon();

  const simpleBugoBrandState = useRecoilValue(simpleBugoBrandListStateSelector);

  const onFuneralHomeSelect = (funeralHomeId: string) => {
    setValue('funeralHomeInfo', funeralHomeId);
  };

  const [editDistributionNetwork, setEditDistributionNetwork] =
    useState<DistributionNetwork<BugoBrand, string, Shop> | null>(null);

  const upsertOpenId = 'adminDistributionNetwork-upsert-modal';

  const setUpsertModalOpen = useSetRecoilState(simpleOpenAtomFamily(upsertOpenId));

  const [distributionNetworkListResult, setDistributionNetworkListResult] = useState<
    DistributionNetwork<string, string, Shop>[] | null
  >(null);

  const onDNSearchSubmit = async (data: {
    funeralHomeInfo: string;
    bugoBrand: string;
  }) => {
    if (!data.bugoBrand || !data.bugoBrand) {
      alert('장례식장, 부고브랜드를 선택해주세요');
    }
    const { data: distributionNetworkList } = await api_.get<
      DistributionNetwork<string, string, Shop>[]
    >(
      `admin/distribution-network/?${queryString({
        filter: {
          bugoBrand: data.bugoBrand,
          funeralHomeInfo: data.funeralHomeInfo,
        },
        populate: {
          path: 'shop',
        },
      })}`,
    );
    setDistributionNetworkListResult(distributionNetworkList);
  };

  const bugoBrandSelect = useMemo(() => {
    if (simpleBugoBrandState.status === 'success' && simpleBugoBrandState.data) {
      const bugoBrandList = simpleBugoBrandState.data;
      const optionList = bugoBrandList.map((item) => {
        return {
          label: item.bugoAgency?.corpName ?? item._id,
          value: item._id,
        };
      });
      return (
        <Select
          optionList={optionList}
          placeholder="부고브랜드"
          control={control}
          name={'bugoBrand'}
        ></Select>
      );
    }
  }, [control, simpleBugoBrandState.data, simpleBugoBrandState.status]);

  const distributionNetworkResultContent = useMemo(() => {
    if (distributionNetworkListResult) {
      const inputs = distributionNetworkListResult.map((item) => {
        return {
          _id: item._id,
          bugoBrand: simpleBugoBrandState?.data?.find((bugoBrand) => {
            return bugoBrand._id === item.bugoBrand;
          }) as BugoBrand,
          funeralHomeInfo: item.funeralHomeInfo,
          shop: item.shop,
        };
      });

      return (
        <AdminDistributionNetworkTable
          distributionNetworkList={inputs}
          setEditDistributionNetwork={setEditDistributionNetwork}
          upsertOpenId={upsertOpenId}
        />
      );
    } else {
      return <p>장례식장과 부고브랜드를 선택 후 검색해주세요</p>;
    }
  }, [distributionNetworkListResult, simpleBugoBrandState?.data]);

  return (
    <div className="space-y-4 p-4">
      <div className="grid grid-cols-3 gap-4">
        <div className="label-col">
          <Label>장례식장</Label>
          <FuneralHomeInfoSearcher
            value={''}
            onValueChange={onFuneralHomeSelect}
            onEmbedSelect={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        </div>
        <div className="label-col">
          <Label>부고브랜드</Label>
          {bugoBrandSelect}
        </div>
        <div className="flex flex-col items-start justify-end">
          <Button
            className="center-box h-11 gap-2 px-4 text-white theme-bg-main"
            onClick={handleSubmit(onDNSearchSubmit)}
          >
            <MagnifyingGlassRegular className="wh-4 fill-white" />
            검색
          </Button>
        </div>
      </div>
      <div>{distributionNetworkResultContent}</div>
      <div>
        <Button
          className="center-box gap-2 bg-white px-4"
          onClick={() => {
            setEditDistributionNetwork(null);
            setUpsertModalOpen(true);
          }}
        >
          <PlusSolid className="wh-4" />
          등록
        </Button>
      </div>
      <AdminDistributionNetworkUpsertModal
        openId={upsertOpenId}
        distributionNetwork={
          editDistributionNetwork ? editDistributionNetwork : undefined
        }
      />
    </div>
  );
};

export default AdminDistributionNetworkPage;
