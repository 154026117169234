import { api_ } from '@shared/plugIn/axios';

import { Enquiry, EnquiryCreate, EnquiryUpdate } from './enquiry.interface';

export const createEnquiry = async (enquiryCreateDto: EnquiryCreate) => {
  const { data: enquiry } = await api_.post<Enquiry>('/public/enquiry', enquiryCreateDto);
  return enquiry;
};

export const updateEnquiry = async (enquiryUpdate: EnquiryUpdate, role: string) => {
  const { data: enquiry } = await api_.patch(
    `/${role}/enquiry/${enquiryUpdate._id}`,
    enquiryUpdate,
  );
  return enquiry;
};
