import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import {
  shopItemInfoListByShopDataAtomFamily,
  shopItemInfoListByShopStatusAtomFamily,
} from './shopItemInfo.atom';

export const shopItemInfoListByShopUrl = (shopId: string, role: LowerRole) => {
  return `/${role}/shop-item-info/?${queryString({
    populate: [
      {
        path: 'image',
      },
      {
        path: 'detailImages',
      },
    ],
    filter: {
      shop: shopId,
    },
  })}`;
};

export const useShopItemInfoListByShopHook = (shopId: string) => {
  const { sellerRole } = useAuth();
  useRecoilQuery(
    shopItemInfoListByShopStatusAtomFamily(shopId),
    shopItemInfoListByShopDataAtomFamily(shopId),
    shopItemInfoListByShopUrl(shopId, sellerRole()),
    fetcher,
  );
};
