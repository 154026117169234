import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoBrandListStateSelector } from '@shared/api/bugoBrand/bugoBrand.selector';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { AdminAgencyBranchAdminUpsertModal } from '../components/AdminAgencyBranchAdminUpsertModal';
import { AgencyBranchAdminCard } from '../components/AgencyBranchAdminCard';
import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const AdminBugoBrandAgencyBranchAdminPage: FC =
  function AdminBugoBrandAgencyBranchAdminPage() {
    useAdminTitleHook('상조 지사 관리자 계정 관리');
    const { PlusSolid } = Icon();
    const { bugoBrandId } = useParams();
    const bugoBrandListState = useRecoilValue(bugoBrandListStateSelector);

    const branchAdminCreateOpenId = `adminBugoBrandAgencyBranchAdmin-create-${bugoBrandId}`;

    const setCreateModalOpen = useSetRecoilState(
      simpleOpenAtomFamily(branchAdminCreateOpenId),
    );

    const bugoBrand = useMemo(() => {
      if (bugoBrandListState.status === 'success' && bugoBrandListState.data) {
        const bugoBrandList = bugoBrandListState.data;
        return bugoBrandList.find((item) => {
          return item._id === bugoBrandId;
        });
      }
    }, [bugoBrandId, bugoBrandListState.data, bugoBrandListState.status]);

    const agencyBranchAdminUserList = bugoBrand?.workers.filter((user) => {
      return !_.isNil(user.bugoAgencyBranchAdminDetail?.teamName);
    });

    //* 관리자 추가
    const agencyBranchAdminCreateButton = useMemo(() => {
      if (bugoBrand) {
        return (
          <>
            <Button
              className="center-box gap-1 shadow-sm theme-bg-1"
              onClick={() => {
                setCreateModalOpen(true);
              }}
            >
              <PlusSolid className="wh-4" />
              <p>지사관리자계정 생성</p>
            </Button>
            <AdminAgencyBranchAdminUpsertModal
              openId={branchAdminCreateOpenId}
              bugoBrand={bugoBrand}
            />
          </>
        );
      }
    }, [PlusSolid, branchAdminCreateOpenId, bugoBrand, setCreateModalOpen]);

    //* 관리자 수정
    const agencyBranchAdminCardGrid = useMemo(() => {
      if (agencyBranchAdminUserList && bugoBrand) {
        return agencyBranchAdminUserList.map((branchAdminUser) => {
          return (
            <AgencyBranchAdminCard
              key={branchAdminUser._id}
              user={branchAdminUser}
              bugoBrand={bugoBrand}
            />
          );
        });
      }
    }, [agencyBranchAdminUserList, bugoBrand]);
    return (
      <div className="space-y-4 p-4">
        <div className="flex items-center justify-between">
          <p className="pl-2 text-16 font-bold">{bugoBrand?.bugoAgency?.corpName}</p>
          {agencyBranchAdminCreateButton}
        </div>
        <div className="grid grid-cols-2 gap-4 sm:grid-cols-2">
          {agencyBranchAdminCardGrid}
        </div>
      </div>
    );
  };

export { AdminBugoBrandAgencyBranchAdminPage };
