//* 상주정보에서 등록한 상주들에게 부고를 보낼 수 있는 센더
//* 지원기능
//* 멤버 보여주기(번호 있는지 없는지)
//* 부고 전송
import { FC } from 'react';

import { MemberBugoIFormInputs } from '@service/bugo/interfaces/memberBugoSend.interface';
import { Bugo } from '@shared/api/bugo/bugo.interface';
import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { Member } from '@shared/api/member/member.interface';
import { Control, UseFormRegister } from 'react-hook-form';

import { MemberBugoSendCard } from './MemberBugoSendCard';

interface Props {
  members?: Member<string, Bugo, CashAccount>[];
  register: UseFormRegister<MemberBugoIFormInputs>;
  control: Control<MemberBugoIFormInputs, any>;
}

export const MemberBugoSender: FC<Props> = function MemberBugoSender({
  members,
  register,
  control,
}: Props) {
  return (
    <div className="space-y-3">
      {members?.map((member, index) => {
        if (member?.phoneNumber) {
          return (
            <MemberBugoSendCard
              key={member._id}
              member={member}
              index={index}
              register={register}
              control={control}
            ></MemberBugoSendCard>
          );
        } else return null;
      })}
    </div>
  );
};
