import { Fragment } from 'react';

import { useRecoilState } from 'recoil';

import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/outline';
import { useModalHook } from '@shared/hooks/useModalHook';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { CustomCallback } from '@shared/types';

interface Props {
  title: string;
  subtitle?: string;
  openId: string;
  buttonTitle: string;
  onClick: CustomCallback<any, void>;
  disabled?: boolean;
}

/**
 * onClick : promise event
 * onClickEvent: void Event
 */
export default function ConfirmModal({
  title,
  subtitle,
  openId,
  buttonTitle,
  onClick,
  disabled,
}: Props) {
  const [open, setOpen] = useRecoilState(simpleOpenAtomFamily(openId));
  const onCloseModal = () => {
    setOpen(false);
    history.back();
  };
  useModalHook(openId);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto"
        onClose={onCloseModal}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="mb-8 inline-block transform overflow-hidden rounded-lg bg-white p-6 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:align-middle">
              <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <p className="text-xl font-medium leading-6 text-gray-900">{title}</p>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{subtitle}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  className={`inline-flex w-full justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none sm:col-start-2`}
                  disabled={disabled}
                  onClick={(e) => {
                    onClick(e);
                    onCloseModal();
                  }}
                >
                  {buttonTitle}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0"
                  onClick={onCloseModal}
                >
                  취소
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
