import { FC, useCallback, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Image } from '@shared/api/media/media.interface';
import { updateShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.controller';
import { shopItemInfoListByShopUrl } from '@shared/api/shopItemInfo/shopItemInfo.hook';
import { ShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.interface';
import { postImage, postImages } from '@shared/api/uploadImage';
import { Button } from '@shared/components/Button';
import { Label } from '@shared/components/Label';
import { TextField } from '@shared/components/TextField';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { Tag } from 'antd';
import { useForm, useWatch } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import { DetailImageDisplayModal } from './DetailImageDisplayModal';
import { EditableTagGroup } from './EditableTagGroup';
import { ImageUploadBox, ImageUploader } from './ImageUploadBox';

interface Props {
  shopItemInfo: ShopItemInfo<string, Image, Image>;
}

export const ShopItemInfoCard: FC<Props> = function ShopItemCard({
  shopItemInfo,
}: Props) {
  const { sellerRole, isAdmin } = useAuth();
  const [edit, setEdit] = useState(false);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isEnabled: shopItemInfo.isEnabled,
      name: shopItemInfo.name,
      // priceRetailShow: shopItem.priceRetailShow,
      // priceRetail: shopItem.priceRetail,
      priceWhole: shopItemInfo.priceWhole,
      detailInfo: shopItemInfo.detailInfo,
    },
  });

  const openId = `shopItemCard-${shopItemInfo._id}`;
  const setDetailImageUploaderOpen = useSetRecoilState(
    simpleOpenAtomFamily(`imageUploader-${openId}`),
  );
  const setDetailImageShowOpen = useSetRecoilState(
    simpleOpenAtomFamily(`detailImageDisplay-${openId}`),
  );

  const [imageFile, setImageFile] = useState<any>(null);
  const [detailImageFiles, setDetailImageFiles] = useState<any>(null);
  const [tagList, setTagList] = useState<string[]>(shopItemInfo.tags);
  const isEnabled = useWatch({ control, name: 'isEnabled' });
  const onSubmit = useCallback(
    async (data: any) => {
      let image;
      let detailImages;
      if (imageFile) {
        const image_ = await postImage(imageFile, sellerRole());
        image = image_._id;
      }
      if (detailImageFiles) {
        const imageList = await postImages(detailImageFiles, sellerRole());
        detailImages = imageList;
      }
      const shopItemChanged = {
        ...shopItemInfo,
        ...data,
        image: image ? image : shopItemInfo.image,
        detailImages: detailImages ? detailImages : shopItemInfo.detailImages,
      };
      if (JSON.stringify(shopItemInfo) !== JSON.stringify(shopItemChanged)) {
        try {
          await updateShopItemInfo(
            {
              ...data,
              _id: shopItemInfo._id,
              image: image ? image : shopItemInfo.image,
              detailImages: detailImages ? detailImages : shopItemInfo.detailImages,
            },
            sellerRole(),
          );
          await queryClient.invalidateQueries(
            shopItemInfoListByShopUrl(shopItemInfo.shop, sellerRole()),
          );
          notificateSuccess(notifiacationInstance, '수정 성공!');
        } catch (err: any) {
          notificateError(notifiacationInstance, err.message);
        }
      }
      setEdit(false);
    },
    [
      detailImageFiles,
      imageFile,
      notifiacationInstance,
      queryClient,
      shopItemInfo,
      sellerRole,
    ],
  );

  const onDetailImageUploaderFunc = useCallback(
    async (data: any, files: any) => {
      setDetailImageFiles(files);
      setDetailImageUploaderOpen(false);
    },
    [setDetailImageUploaderOpen],
  );

  const editSubmitButton = useMemo(() => {
    if (edit) {
      return (
        <Button
          className="filled-myTeal h-8 px-3 sm:h-10 sm:px-5"
          onClick={handleSubmit(onSubmit)}
        >
          저장
        </Button>
      );
    } else {
      return (
        <Button
          className="filled-myTeal h-8 px-3 sm:h-10 sm:px-5"
          onClick={() => {
            setEdit(true);
          }}
        >
          수정
        </Button>
      );
    }
  }, [edit, handleSubmit, onSubmit]);

  return (
    <div className="flex flex-col gap-4 overflow-hidden rounded-lg border bg-white p-4 shadow">
      <div className="label-col">
        <Label text="기본정보" />
        <div className={`flex ${edit ? 'flex-col' : 'flex-row'} gap-2`}>
          {edit ? (
            <ImageUploadBox
              title="대표이미지"
              setFile={setImageFile}
              defaultImageUrl={shopItemInfo.image.url}
            />
          ) : (
            <div className="wh-32 shrink-0 overflow-hidden">
              <img src={fileBucketUrl(shopItemInfo.image.url, 'w200')} alt="대표이미지" />
            </div>
          )}
          <div className="space-y-0.5 text-base font-medium">
            {edit ? (
              <TextField
                className="textfield-sm w-full"
                labelClassname="label-sm"
                label="상품명"
                {...register('name', {
                  required: {
                    value: true,
                    message: '상품명을 입력해주세요',
                  },
                })}
                // error={errors?.name}
                // errorMessage={errors?.name?.message}
              />
            ) : (
              <p className="flex gap-2">
                <span className="shrink-0">이름:</span>
                {shopItemInfo.name}
              </p>
            )}
            {/* {isAdmin() &&
              (edit ? (
                <TextField
                  className="textfield-sm w-full"
                  labelClassname="label-sm"
                  type="number"
                  label="정가"
                  {...register('priceRetailShow')}
                />
              ) : (
                <p className="flex gap-2">
                  <span className="shrink-0">정가:</span>
                  {shopItem.priceRetailShow.toLocaleString()}원
                </p>
              ))}
            {isAdmin() &&
              (edit ? (
                <TextField
                  className="textfield-sm w-full"
                  labelClassname="label-sm"
                  type="number"
                  label="판매가"
                  {...register('priceRetail')}
                />
              ) : (
                <p className="flex gap-2">
                  <span className="shrink-0">판매가:</span>
                  {shopItem.priceRetail.toLocaleString()}원
                </p>
              ))} */}
            {isAdmin() ? (
              edit ? (
                <TextField
                  className="textfield-sm w-full"
                  labelClassname="label-sm"
                  type="number"
                  label="도매가"
                  {...register('priceWhole')}
                />
              ) : (
                <p className="flex gap-2">
                  <span className="shrink-0">도매가:</span>
                  {shopItemInfo.priceWhole.toLocaleString()}원
                </p>
              )
            ) : (
              <p className="flex gap-2">
                <span className="shrink-0">도매가:</span>
                {shopItemInfo.priceWhole.toLocaleString()}원
              </p>
            )}
            {edit ? (
              <>
                <label className="label-sm">판매여부</label>
                <Select
                  className="textfield-sm w-full"
                  // label="판매여부"
                  // labelClassname="label-sm"
                  optionList={[
                    { value: true, label: '가능' },
                    { value: false, label: '불가능' },
                  ]}
                  defaultValue={isEnabled ? '가능' : '불가능'}
                  control={control}
                  name={'isEnabled'}
                  placeholder={isEnabled ? '가능' : '불가능'}
                />
              </>
            ) : (
              <p className="flex gap-2">
                <span className="shrink-0">판매여부:</span>
                {shopItemInfo.isEnabled ? '가능' : '불가능'}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="label-col">
        <Label>상세 정보</Label>
        <div className="space-y-2">
          <div className="label-col">
            <Label className="label-sm">상세이미지(여러장)</Label>
            {edit ? (
              <Button
                className="button-sm filled-indigo-500"
                onClick={() => {
                  setDetailImageUploaderOpen(true);
                }}
              >
                이미지(여러장) 업로드
              </Button>
            ) : (
              <Button
                className="button-sm filled-indigo-600 text-white"
                onClick={() => {
                  setDetailImageShowOpen(true);
                }}
              >
                <span>자세히 보기</span>
              </Button>
            )}
          </div>

          <div className="label-col">
            <Label className="label-sm">상세설명</Label>
            {edit ? (
              <textarea
                className="expandable-textarea w-full overflow-y-scroll"
                {...register('detailInfo')}
              ></textarea>
            ) : (
              <p className="whitespace-pre">{shopItemInfo.detailInfo}</p>
            )}
          </div>

          <div className="label-col">
            <Label className="label-sm">태그</Label>
            {edit ? (
              <EditableTagGroup
                tags={tagList}
                onAdd={(value: string) => {
                  setTagList([...tagList, value]);
                }}
                onClose={(removeTag: string) => {
                  const newTagList = tagList.filter((tag) => tag !== removeTag);
                  setTagList(newTagList);
                }}
              />
            ) : (
              <p>
                {shopItemInfo.tags.map((tag, idx) => {
                  return <Tag key={idx}>{tag}</Tag>;
                })}
              </p>
            )}
          </div>
        </div>
      </div>
      <DetailImageDisplayModal
        openId={openId}
        detailImageList={shopItemInfo.detailImages}
      />
      <ImageUploader
        openId={openId}
        title="상세이미지 업로드"
        onSubmit={onDetailImageUploaderFunc}
        useName={false}
      />
      {isAdmin() && (
        <Link to={`${shopItemInfo._id}/delivered-image`} className="w-full">
          <Button className="button-sm filled-gray-500 w-full">배송사진관리</Button>
        </Link>
      )}
      {editSubmitButton}
    </div>
  );
};
