// StoreMyPage / OrderListPage에서 사용되는 필터 컨테이너.
import { FC, useMemo } from 'react';

import { useRecoilState } from 'recoil';

import { DeliveryState } from '@shared/types';

import { orderListFilterAtom } from './orderListFilter.atom';

const bgColorGenerator = (state: boolean): string =>
  state ? 'theme-bg-main' : 'theme-bg-10';

const fontWeightGenerator = (state: boolean): string =>
  state ? 'font-bold' : 'font-normal';

const textColorGenerator = (state: boolean) => (state ? 'theme-text-1' : 'theme-text-8');

interface IProps {
  shopOrderDeliveryStatusList: DeliveryState[];
}

interface ICircleProps {
  number: number;
  text: string;
  isActive: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

type NumOfStatus = {
  [DeliveryState.BEFORE_PREPARING]: number;
  [DeliveryState.PREPARING]: number;
  [DeliveryState.DELIVERYING]: number;
  [DeliveryState.DELIVERED]: number;
};

const OrderListFilterCircle: FC<ICircleProps> = ({
  number,
  text,
  isActive,
  onClick,
}: ICircleProps) => {
  return (
    <div className="relative font-bold leading-5">
      <button
        onClick={onClick}
        className={`flex aspect-square w-10 items-center justify-center rounded-full text-sm leading-10 text-white ${bgColorGenerator(
          isActive,
        )}`}
      >
        {number}
      </button>
      <h5
        className={`absolute top-[calc(100%+4px)] left-1/2 -translate-x-1/2 whitespace-nowrap text-center text-xs ${textColorGenerator(
          isActive,
        )} ${fontWeightGenerator(isActive)}`}
      >
        {text}
      </h5>
    </div>
  );
};

const OrderListFilter: FC<IProps> = ({ shopOrderDeliveryStatusList }: IProps) => {
  const [filter, setFilter] = useRecoilState(orderListFilterAtom);

  const numOfStatus = useMemo(() => {
    const numOfStatus: NumOfStatus = {
      [DeliveryState.BEFORE_PREPARING]: 0,
      [DeliveryState.PREPARING]: 0,
      [DeliveryState.DELIVERYING]: 0,
      [DeliveryState.DELIVERED]: 0,
    };

    shopOrderDeliveryStatusList.forEach(
      (deliveryStatus) => ++numOfStatus[deliveryStatus],
    );

    return numOfStatus;
  }, [shopOrderDeliveryStatusList]);

  const updateFilter = (status: DeliveryState) =>
    setFilter((curr) => {
      const newContains = {
        ...curr.contains,
        [status]: !curr.contains[status],
      };

      let newActive = false;

      Object.values(newContains).forEach((value) => (newActive = value || newActive));

      return {
        isActive: newActive,
        contains: newContains,
      };
    });

  const renderVerticalBar = () => <div className="h-[1px] w-10 theme-bg-10"></div>;

  return (
    <div className="flex items-center justify-center pb-6">
      <OrderListFilterCircle
        number={numOfStatus[DeliveryState.BEFORE_PREPARING]}
        text="결제완료"
        isActive={filter.contains[DeliveryState.BEFORE_PREPARING]}
        onClick={() => updateFilter(DeliveryState.BEFORE_PREPARING)}
      />
      {renderVerticalBar()}
      <OrderListFilterCircle
        number={numOfStatus[DeliveryState.PREPARING]}
        text="상품준비중"
        isActive={filter.contains[DeliveryState.PREPARING]}
        onClick={() => updateFilter(DeliveryState.PREPARING)}
      />
      {renderVerticalBar()}
      <OrderListFilterCircle
        number={numOfStatus[DeliveryState.DELIVERYING]}
        text="배송중"
        isActive={filter.contains[DeliveryState.DELIVERYING]}
        onClick={() => updateFilter(DeliveryState.DELIVERYING)}
      />
      {renderVerticalBar()}
      <OrderListFilterCircle
        number={numOfStatus[DeliveryState.DELIVERED]}
        text="배송완료"
        isActive={filter.contains[DeliveryState.DELIVERED]}
        onClick={() => updateFilter(DeliveryState.DELIVERED)}
      />
    </div>
  );
};

export default OrderListFilter;
