import { FC } from 'react';

import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';

interface Props {
  shopOrder: ShopOrderForSeller;
  short?: boolean;
}

export const DeliveryContentCard: FC<Props> = function DeliveryContentCard({
  shopOrder,
  short = false,
}: Props) {
  console.log(shopOrder);
  return (
    <div className="space-y-1 rounded-md border bg-white px-4 py-2 shadow-sm">
      <p className="border-b pb-1">
        <strong>주소</strong>
        &nbsp; {shopOrder.deliveryDetail.receiverAddress}
      </p>
      <div className="border-b pb-1">
        <strong>
          {'<'}문구{'>'}
        </strong>
        <p>
          <strong>보내는분</strong>&nbsp;{shopOrder.bugoDetail.senderPhrase}
        </p>
        <p>
          <strong>조의문구</strong>&nbsp;{shopOrder.bugoDetail.condolencePhrase}
        </p>
      </div>
      <p className={`${short ? '' : 'border-b'} pb-1`}>
        <strong>받으시는분</strong>&nbsp;{shopOrder.deliveryDetail.receiverName}
      </p>
      {!short && (
        <p>
          <strong>도매가</strong>&nbsp;
          {shopOrder.orderDetail.shopItemEmbed.priceWhole.toLocaleString()}원
        </p>
      )}
    </div>
  );
};

export const orderContentString = (shopOrder: ShopOrderForSeller) => {
  return (
    `주소: ${shopOrder.deliveryDetail.receiverAddress}\n` +
    `문구(보내는분): ${shopOrder.bugoDetail.senderPhrase}\n` +
    `조의문구: ${shopOrder.bugoDetail.condolencePhrase}\n` +
    `받으시는분: ${shopOrder.deliveryDetail.receiverName} 상주님\n` +
    `도매가: ${shopOrder.orderDetail.shopItemEmbed.priceWhole.toLocaleString()}원`
  );
};
