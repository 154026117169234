import { FC, useEffect, useMemo } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import { agencyAdminAccountTeamNameFilterAtom } from './AgencyAdminAccount.atom';
import { AnnualAccountedTable } from './AnnualAccountedTable';
import { NeedAccountTable } from './NeedAccountTable';

interface Props {
  shopOrderList: ShopOrderAgencyAdmin[];
}

export const AgencyAdminAccountContent: FC<Props> = function AgencyAdminAccountContent({
  shopOrderList,
}: Props) {
  const setAgencyAdminAccountTeamNameFilter = useSetRecoilState(
    agencyAdminAccountTeamNameFilterAtom,
  );

  const { userProfile, bugoRole } = useAuth();

  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];

  useEffect(() => {
    if (bugoRole() === LowerRole.BugoAgencyBranchAdmin) {
      setAgencyAdminAccountTeamNameFilter(null);
    }
    if (bugoBrand) {
      const teamNames = bugoBrand?.bugoAgency?.teamNames ?? [];
      const result: CheckBoxFilter = {};
      teamNames.forEach((teamName) => (result[teamName] = true));
      result['etc'] = true;
      setAgencyAdminAccountTeamNameFilter(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 정산이 필요한 녀석들만 고름
  const needAccountOrder = shopOrderList.filter((order) => {
    return !order.paymentDetail.isRebated;
  });

  const needAccountTable = useMemo(() => {
    return (
      <div>
        <NeedAccountTable shopOrderList={needAccountOrder}></NeedAccountTable>{' '}
      </div>
    );
  }, [needAccountOrder]);

  return (
    <div className="space-y-4 py-4">
      {needAccountTable}
      <AnnualAccountedTable shopOrderList={shopOrderList} />
    </div>
  );
};
