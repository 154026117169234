//* funeralHomeInfoRequest api

import { api_ } from '@shared/plugIn/axios';

import { FuneralHomeInfoRequest } from './funeralHomeInfoRequest.interface';

export const createFuneralHomeInfoRequest = async (
  createFuneralHomeInfoRequest: FuneralHomeInfoRequest,
) => {
  const { data } = await api_.post(
    `/public/funeral-home-info-request`,
    createFuneralHomeInfoRequest,
  );
  return { data };
};
