import { useRecoilValue } from 'recoil';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';
import { Icon } from '@shared/components/icons';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { unsecuredCopyToClipboard } from '@shared/utils/clipboard.util';
interface Props {
  shopOrder: ShopOrderForStore;
}
export const VBankInfoCard = function ({ shopOrder }: Props) {
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const { Copy } = Icon();
  return (
    <div className="bg-white">
      <div className="space-y-2 rounded-md border px-4 py-3 shadow-sm">
        <p className="text-17 font-medium">가상입금계좌</p>
        <p>{shopOrder.paymentDetail.vBank}</p>
        <div
          className="button-sm center-box filled-gray-200 h-7 cursor-pointer gap-1 text-sm text-gray-600 sm:h-10"
          onClick={async (): Promise<void> => {
            window.navigator.clipboard
              .writeText(`${shopOrder.paymentDetail.vBank}`)
              .then(() => {
                alert('계좌복사 완료');
              })
              .catch(() => {
                unsecuredCopyToClipboard(
                  `${shopOrder.paymentDetail.vBank}`,
                  '계좌복사완료',
                  notifiacationInstance,
                );
              });
          }}
        >
          <Copy className="wh-4" />
          <p style={{ whiteSpace: 'pre' }}>계좌복사</p>
        </div>
      </div>
    </div>
  );
};
