import { MemberIFormInputs } from '@service/bugo/interfaces/memberAddEdit.interface';
import { updateFevent } from '@shared/api/fevent/fevent.controller';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { getMemberOrderListByFormInputs } from '@shared/api/member/member.controller';
import { LowerRole } from '@shared/types';

//* 등록 함수
export const mournerInfoFormSubmit = async (
  data: MemberIFormInputs,
  fevent: FeventOne,
  bugoRole: LowerRole,
) => {
  // 1. member를 생성한다, 수정한다, 삭제한다
  try {
    const memberIFormInputs = data.member;
    const memberOrderListOld = fevent.memberOrderList;

    const memberOrderList = await getMemberOrderListByFormInputs(
      memberIFormInputs,
      memberOrderListOld,
      fevent._id,
      fevent.deceasedInfo.sex,
      bugoRole,
    );
    // 2. memberOrderList를 fevent에 업데이트해준다.
    const feventUpdate = {
      _id: fevent._id,
      memberOrderList: memberOrderList,
    };
    await updateFevent(feventUpdate, bugoRole);
  } catch (err) {
    console.error(err);
  }
};
