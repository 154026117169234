import React, { FC } from 'react';

import { StoreItemTagEnum } from '@shared/interfaces/storeItemTag.enum';
import { formatDate } from '@shared/utils/formatDate';
import { formatPrice } from '@shared/utils/formatPrice';
import { getStoreItemTagColorStyle } from '@shared/utils/getStoreItemTagColorStyle';

import { getDiscountRate } from '../utils/getDiscountRate';
import { getEstimateDeliveredAt } from '../utils/getEstimateDeliveredAt';

interface IProps {
  image: string;
  name: string;
  priceRetailShow: number;
  priceRetail: number;
  tags: StoreItemTagEnum[];
}

const StoreItemPreview: FC<IProps> = ({
  image,
  name,
  priceRetail,
  priceRetailShow,
  tags,
}: IProps) => {
  return (
    <div className="relative flex flex-col text-sm leading-4 theme-bg-1">
      {/* 상품 사진 */}
      <img
        src={image}
        alt="Flower Image"
        className="aspect-square w-full object-cover object-center"
      />
      {/* 상품 정보 */}
      <div className="flex flex-col px-2 py-1">
        {/* 배송 예정 시각 */}
        <span className="text-xs leading-6 theme-text-8">
          {formatDate(getEstimateDeliveredAt(), {
            dateSeparater: '/',
            contains: {
              year: false,
              day: true,
            },
          })}{' '}
          도착 보장
        </span>
        {/* 상품명 */}
        <h4 className="pt-1 font-bold">{name}</h4>
        {/* 상품 가격 */}
        <div className="flex justify-between pt-1">
          {/* 할인율 */}
          {priceRetailShow !== priceRetail ? (
            <div className="flex text-[#00A3B0]">
              <div className="font-bold">
                {getDiscountRate(priceRetailShow, priceRetail)}
              </div>
              <span className="text-[10px]">%&#9660;</span>
            </div>
          ) : (
            <div></div>
          )}
          {/* 상품 가격 */}
          <div className="flex items-end justify-end space-x-1">
            {/* 원래 가격 */}
            {priceRetail !== priceRetailShow && (
              <div className="text-[10px] leading-3 line-through theme-text-8">
                {formatPrice(priceRetailShow)}원
              </div>
            )}
            {/* 할인 적용 가격 */}
            <div className="flex items-end">
              <h3 className="font-bold">{formatPrice(priceRetail)}</h3>
              <span className="text-xs">원</span>
            </div>
          </div>
        </div>
      </div>
      {/* 태그[] */}
      <div className="absolute top-0 left-0 flex flex-col space-y-0.5">
        {tags.map((tag, i) => (
          <div
            key={`${tag}-${i}`}
            className={`flex h-5 w-[40px] items-center justify-center text-[10px] font-bold leading-3 ${getStoreItemTagColorStyle(
              tag,
            )}`}
          >
            {tag === StoreItemTagEnum.FreeDelivery ? '무배' : tag}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(StoreItemPreview);
