import { FC, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { updateEnquiry } from '@shared/api/enquiry/enquiry.controller';
import {
  Enquiry,
  EnquiryStatus,
  EnquiryUpdate,
} from '@shared/api/enquiry/enquiry.interface';
import { Image } from '@shared/api/media/media.interface';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';

interface Props {
  openId: string;
  enquiry: Enquiry<User, Image>;
}

const EnquiryDetailModal: FC<Props> = ({ openId, enquiry }: Props) => {
  const [answer, setAnswer] = useState<string>(enquiry.answer ?? '');
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { assetRole } = useAuth();

  const onConfirm = async (status: EnquiryStatus, notiString: string) => {
    const enquiryUpdateDto: EnquiryUpdate = {
      _id: enquiry._id,
      status,
      answer,
    };

    try {
      const updatedEnquiry = await updateEnquiry(enquiryUpdateDto, assetRole());

      if (updatedEnquiry)
        notificateSuccess(
          notifiacationInstance,
          `답변이 정상적으로 ${notiString}되었습니다.`,
        );
      else
        notificateError(
          notifiacationInstance,
          `답변이 ${notiString}되는 과정에서 오류가 발생했습니다.`,
        );
    } catch (error) {
      notificateError(
        notifiacationInstance,
        `답변이 ${notiString}되는 과정에서 오류가 발생했습니다.`,
      );
      console.error(error);
    }
  };

  return (
    <Modal id={openId} openId={openId} className="w-full" position="center">
      <div className="space-y-4 text-xs">
        <h4 className="text-base font-bold">1:1문의 내역 상세보기</h4>
        {/* enquiry contents */}
        <div className="space-y-3">
          <div className="flex">
            <span className="flex-[1] font-bold">성함</span>
            <span className="flex-[2]">{enquiry.user.info.name}</span>
          </div>
          <div className="flex">
            <span className="flex-[1] font-bold">연락처</span>
            <span className="flex-[2]">{enquiry.user.info.phoneNumber}</span>
          </div>
          <div>
            <span className="font-bold">문의 내용</span>
            <p className="whitespace-pre-line break-keep px-2 py-4">{enquiry.content}</p>
          </div>
          <div className="flex items-center font-bold">
            <span className="flex-[1]">첨부파일</span>
            <a
              className="flex-[2] py-1"
              href={fileBucketUrl(enquiry.image.url, 'w400')}
              download
            >
              {enquiry.image.name}
            </a>
          </div>
        </div>
        {/* 답변 */}
        <div className="space-y-3">
          <span className="font-bold">문의 답변</span>
          <textarea
            value={answer}
            onChange={(event) => setAnswer(event.currentTarget.value)}
            placeholder="답변을 입력해주세요."
            className="textfield h-36 w-full py-3 text-sm"
          />
        </div>
        <div className="space-y-3">
          {/* TODO: onClick 만들어야 함. */}
          <Button
            className="button-rectangle text-white theme-bg-main"
            onClick={() => onConfirm(EnquiryStatus.Done, '제출')}
          >
            답변 제출
          </Button>
          <Button
            className="button-rectangle text-white theme-bg-main"
            onClick={() => onConfirm(EnquiryStatus.Doing, '임시 저장')}
          >
            임시 저장
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EnquiryDetailModal;
