export enum CourtesyContentUpsertFormEnum {
  Name = 'name',
  Order = 'order',
  Content = 'content',
  BugoBrand = 'bugoBrand',
  Id = '_id',
}

export interface CourtesyContentUpsertFormInputs {
  [CourtesyContentUpsertFormEnum.Id]?: string;
  [CourtesyContentUpsertFormEnum.Name]: string;
  [CourtesyContentUpsertFormEnum.Order]?: number;
  [CourtesyContentUpsertFormEnum.Content]: string;
  [CourtesyContentUpsertFormEnum.BugoBrand]: string;
}
