import { FC, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopItemInBugo } from '@shared/api/shopItem/shopItem.interface';
import { ShopOrderInBugo } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import flowerIcon from 'src/assets/image/flowerIcon.png';

import { SameItemBuyModal } from './SameItemBuyModal';

interface Props {
  shopOrders: ShopOrderInBugo[];
  bugoId: string;
}

export const FlowerOrderListTable: FC<Props> = function FlowerOrderListTable({
  shopOrders,
  bugoId,
}: Props) {
  const [sameShopItem, setSameShopItem] = useState<ShopItemInBugo | undefined>(undefined);
  const [viewAllList, setViewAllList] = useState(false);
  const sameShopItemOpenId = `flowerOrderListTable-${bugoId}`;
  const setSameShopItemModalOpen = useSetRecoilState(
    simpleOpenAtomFamily(sameShopItemOpenId),
  );
  const phraseBox = (shopOrder: ShopOrderInBugo) => {
    return (
      <div>
        <p className="font-bold">{shopOrder?.bugoDetail?.senderPhrase}</p>
        <p className="font-medium">{shopOrder?.bugoDetail?.condolencePhrase}</p>
      </div>
    );
  };

  const shopOrderLimit10 = shopOrders.slice(0, 5);

  return (
    <div className="space-y-2.5">
      <div className="w-full break-keep border border-gray-300 bg-white">
        {(viewAllList ? shopOrders : shopOrderLimit10).map((order, index) => {
          const shopItem = order.orderDetail.shopItem;
          const shopItemImageUrl =
            shopItem?.image?.url ?? shopItem?.shopItemInfo?.image?.url;
          return (
            <div
              key={index}
              onClick={() => {
                setSameShopItem(shopItem);
                setSameShopItemModalOpen(true);
              }}
              className="flex cursor-pointer items-center gap-2 border-b border-gray-300 py-1 pl-1 text-15 leading-5 last:border-b-0"
            >
              {shopItemImageUrl ? (
                <img
                  className={`${
                    order.orderDetail.shopItemEmbed.name.indexOf('바구니') !== -1
                      ? 'my-1.5 h-[48px] px-1.5'
                      : 'h-[60px]'
                  }`}
                  src={fileBucketUrl(shopItemImageUrl ?? '', 'w400')}
                ></img>
              ) : (
                <img className="py-1.5 pl-3 pr-4" src={flowerIcon}></img>
              )}
              {phraseBox(order)}
            </div>
          );
        })}
      </div>
      {shopOrders?.length > 5 && (
        <div className="center-box">
          <Button onClick={() => setViewAllList((curr) => !curr)} className="button-fold">
            <p>{viewAllList ? '접기' : '전체보기'}</p>
          </Button>
        </div>
      )}
      <SameItemBuyModal
        shopItem={sameShopItem}
        bugoId={bugoId}
        openId={sameShopItemOpenId}
      />
    </div>
  );
};
