import React, { FC, HTMLAttributes } from 'react';

interface Props extends HTMLAttributes<HTMLDivElement> {
  url: string;
  width?: number;
  height?: number;
}

export const PopUpLink: FC<Props> = function PopUpLink({
  url,
  width,
  height,
  children,
}: Props) {
  return (
    <div
      className="cursor-pointer"
      onClick={() => {
        window.open(
          url,
          '_blank',
          `location=1, status=1, scrollbars=0, width=${width ?? 506}, height=${
            height ?? 900
          }`,
        );
      }}
    >
      {children}
    </div>
  );
};
