import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { closeShop, openShop } from '@shared/api/shop/shop.controller';
import { sellerShopHookUrl } from '@shared/api/shop/shop.hook';
import { ShopData } from '@shared/api/shop/shop.interface';
import { Button } from '@shared/components/Button';
import ConfirmModal from '@shared/components/ConfirmModal';
import DismissModal from '@shared/components/DismissModal';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { useQueryClient } from 'react-query';

interface IProps {
  shop: ShopData;
}

const ShopCloseButton: FC<IProps> = ({ shop }: IProps) => {
  const { sellerRole } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();

  const openId = 'shopHome-confirm';
  const setConfirmOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const dismidssOpenId = 'shopHome-dismiss';
  const setDismissOpen = useSetRecoilState(simpleOpenAtomFamily(dismidssOpenId));

  const stateBox = useMemo(
    () => (
      <div>
        <p className="font-medium">{shop.name}</p>
        <p>
          현재{' '}
          <span className={`font-bold ${!shop.isEnabled ? 'text-warn' : 'text-myTeal'}`}>
            {!shop.isEnabled ? '영업종료' : '영업중'}
          </span>
          상태입니다.
        </p>
      </div>
    ),
    [shop.isEnabled, shop.name],
  );

  return !shop.isEnabled ? (
    <div className="center-box gap-6 border p-4 shadow-md">
      {stateBox}
      <Button
        className="filled-green-500"
        onClick={() => {
          setConfirmOpen(true);
        }}
      >
        영업재개
      </Button>
      <ConfirmModal
        openId={openId}
        onClick={async () => {
          await openShop(shop._id, sellerRole());
          notificateSuccess(notifiacationInstance, '영업재개하였습니다.');
          queryClient.invalidateQueries(sellerShopHookUrl(shop._id, sellerRole()));
        }}
        title="영업재개"
        subtitle="영업재개하여 해당 상품들 손님들이 접근가능하게합니다."
        buttonTitle="확인"
      ></ConfirmModal>
    </div>
  ) : (
    <div className="center-box gap-6 border p-4 shadow-md">
      {stateBox}
      <Button
        className="filled-warn"
        onClick={() => {
          setDismissOpen(true);
        }}
      >
        영업종료
      </Button>
      <DismissModal
        openId={dismidssOpenId}
        onClick={async () => {
          await closeShop(shop._id, sellerRole());
          notificateSuccess(notifiacationInstance, '영업종료하였습니다.');
          queryClient.invalidateQueries(sellerShopHookUrl(shop._id, sellerRole()));
        }}
        title="영업종료"
        subtitle="영업종료하여 손님들이 해당 가게 상품들에 접근 불가능하게합니다."
        buttonTitle="확인"
      ></DismissModal>
    </div>
  );
};

export default ShopCloseButton;
