import { RecoilRoot } from 'recoil';

import { TokenProvider } from '@shared/state/hooks/tokenProvider';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const client = new QueryClient();
root.render(
  // <Auth0Provider
  //   domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ''}
  //   clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}
  // >
  <QueryClientProvider client={client}>
    <RecoilRoot>
      <TokenProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </TokenProvider>
    </RecoilRoot>
  </QueryClientProvider>,
  // </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
