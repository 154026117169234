import React, { useEffect } from 'react';

import { BugoBasicButton } from '@service/bugo/components/BugoBasicButton';
import { AccountInput } from '@shared/components/AccountInput';
import { CheckBox } from '@shared/containers';
import { useAuth } from '@shared/state/hooks/useAuth';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

interface LoginFormProps {
  redirectUrl: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ redirectUrl }) => {
  const { register, handleSubmit, control, setValue } = useForm();
  const { loginByUsernamePassword } = useAuth();
  const navigate = useNavigate();

  const saveCheckBoxHandler = (checkState: boolean) => {
    setValue('saveLoginDataChecked', checkState);
  };

  const saveLoginDataChecked = useWatch({
    control,
    name: 'saveLoginDataChecked',
    defaultValue: false,
  });

  useEffect(() => {
    const checked = localStorage.getItem('saveLoginDataChecked');
    if (checked === 'true') {
      const username = localStorage.getItem('username');
      const password = localStorage.getItem('password');

      setValue('saveLoginDataChecked', true);
      setValue('username', username);
      setValue('password', password);
    }
  }, [setValue]);

  const onSubmit: SubmitHandler<FieldValues> = async (data, e) => {
    const { username, password, saveLoginDataChecked } = data;

    if (saveLoginDataChecked) {
      localStorage.setItem('saveLoginDataChecked', saveLoginDataChecked);
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
    } else {
      localStorage.removeItem('saveLoginDataChecked');
      localStorage.removeItem('username');
      localStorage.removeItem('password');
    }

    try {
      await loginByUsernamePassword({ username: username, password: password });

      navigate(redirectUrl);
    } catch (error) {
      // id 혹은 password 틀림.
      // notificateError(notifiacationInstance, error);
      // console.log(error);
    }
  };

  const onError: SubmitErrorHandler<FieldValues> = (errors, e) => {
    console.log(errors, e);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      {/* Id */}
      <AccountInput
        register={register}
        registerName={'username'}
        type="text"
        text="아이디"
        placeholder="아이디를 입력해주세요"
        className="text-15"
      />
      {/* Password */}
      <AccountInput
        register={register}
        registerName={'password'}
        type="password"
        text="비밀번호"
        placeholder="비밀번호를 입력해주세요"
        className="text-15"
      />
      {/* ButtonSet */}
      {/* OptionButtonSet */}
      <div className="my-4 flex justify-between">
        {/* PersistCheckBox */}
        <label className="flex justify-start">
          <CheckBox
            checked={saveLoginDataChecked}
            onCheckedChange={saveCheckBoxHandler}
          />
          <div className="ml-2 h-full">로그인 정보 저장</div>
        </label>
        {/* FindIdButton */}
        {/* <div className="h-full">
          <Button className="h-5">아이디/비밀번호 찾기</Button>
        </div> */}
      </div>
      {/* SubmitButton */}
      <BugoBasicButton type="submit" className="rounded-lg theme-bg-main" text="로그인" />
    </form>
  );
};

export { LoginForm };
