import { FC, useMemo } from 'react';

import { useSetRecoilState } from 'recoil';

import { BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

import {
  adminBugoBrandMultiAgencyDeleteIndexAtom,
  adminBugoBrandMultiAgencyDeleteModalOpenId,
  adminBugoBrandMultiAgencyIndexAtom,
  adminBugoBrandMultiAgencyModalOpenId,
} from './AdminBugoBrandMultiAgency.atom';

interface Props {
  bugoBrand: BugoBrandData;
}

type MultiAgencyTableData = {
  name: string;
  index: number;
};

const AdminBugoBrandMultiAgencyTable: FC<Props> = function ({ bugoBrand }: Props) {
  const bugoAgencyList = bugoBrand.bugoAgencyList;
  const tableData: MultiAgencyTableData[] = useMemo(() => {
    return _.map(bugoAgencyList, (bugoMultiAgency, index) => {
      return {
        name: bugoMultiAgency.corpName ?? '',
        index,
      };
    });
  }, [bugoAgencyList]);

  const setMultiAgencyIndex = useSetRecoilState(
    adminBugoBrandMultiAgencyIndexAtom(bugoBrand._id),
  );

  const setDeleteMultiAgencyIndex = useSetRecoilState(
    adminBugoBrandMultiAgencyDeleteIndexAtom(bugoBrand._id),
  );

  const modalOpenId = adminBugoBrandMultiAgencyModalOpenId(bugoBrand._id);

  const onModalOpen = useSetRecoilState(simpleOpenAtomFamily(modalOpenId));

  const deleteModalOpenId = adminBugoBrandMultiAgencyDeleteModalOpenId(bugoBrand._id);

  const onDeleteModalOpen = useSetRecoilState(simpleOpenAtomFamily(deleteModalOpenId));

  const columns: TableColumnsType<MultiAgencyTableData> = [
    {
      title: '행사 상조',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '설정',
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (index) => {
        return (
          <div className="center-box gap-2">
            <Button
              className="shadow-sm"
              onClick={() => {
                setMultiAgencyIndex(index);
                onModalOpen(true);
              }}
            >
              수정
            </Button>
            <Button
              className="shadow-sm"
              onClick={() => {
                setDeleteMultiAgencyIndex(index);
                onDeleteModalOpen(true);
              }}
            >
              삭제
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="space-y-4">
      <div className="flex justify-end">
        <Button
          className="bg-white"
          onClick={() => {
            setMultiAgencyIndex(undefined);
            onModalOpen(true);
          }}
        >
          + 행사상조 추가
        </Button>
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'index'}
        bordered
        size={'small'}
      />
    </div>
  );
};

export default AdminBugoBrandMultiAgencyTable;
