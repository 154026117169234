import { FC } from 'react';

import Modal from '@shared/components/Modal';

interface Props {
  openId: string;
  numFevent: number;
  numShopOrder: number;
}

export const AgencyTotalStatModal: FC<Props> = function AgencyTotalStatModal({
  openId,
  numFevent,
  numShopOrder,
}: Props) {
  return (
    <Modal openId={openId} position={'center'}>
      <div className="space-y-3">
        <h4 className="text-base font-bold">{'상세보기'}</h4>
        <div className="flex items-center justify-around gap-4 font-medium">
          <div className="flex flex-col items-center">
            <p>부고등록</p>
            <p>{numFevent}회</p>
          </div>
          <div className="flex flex-col items-center">
            <p>화환판매</p>
            <p>{numShopOrder}회</p>
          </div>
          <div>
            <div className="flex flex-col items-center">
              <p>FPB</p>
              <p>{numFevent === 0 ? 0 : (numShopOrder / numFevent).toFixed(2)}</p>
            </div>
          </div>
        </div>
        {/* //TODO
        <p className="font-medium">**지역별, 의전별 지표 추후 업데이트 예정</p> */}
      </div>
    </Modal>
  );
};
