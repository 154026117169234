import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import RegisteredBugoStatusBar from '@service/bugo/components/RegisteredBugoStatusBar';
import { useFeventCountByBugoBrandhook } from '@shared/api/fevent/fevent.hook';
import { feventCountByBugoBrandStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { Button } from '@shared/components/Button';
import { queryString } from '@shared/hooks/recoil-query';
import { api_ } from '@shared/plugIn/axios';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import {
  getThirtyDayBefore,
  getThisMonthFrom,
  getThisWeekFrom,
  getTodayFrom,
} from '@shared/utils/dateUtils';
import _ from 'lodash';
import { useAsync } from 'react-use';

import BugoMainAnouncementList from '../BugoMainAnnouncementList/BugoMainAnnouncementList';
import { AgencyAnnouncement } from './AgencyAnnouncement';
import { AgencyMainLinkList } from './AgencyMainLinkList';
import { AgencyTotalStatModal } from './AgencyTotalStatModal';

export const AgencyAdminMain: FC = function AgencyAdminMain() {
  const { bugoRole, bugoBrandId, isBugoAgencyBranchAdmin } = useAuth();

  const now = useMemo(() => new Date(), []);
  const totalStatOpenId = 'totalStat-agencyAdmin';
  const setTotalStatOpen = useSetRecoilState(simpleOpenAtomFamily(totalStatOpenId));
  const [FPBPoint, setFPBPoint] = useState<number>(0);
  const [feventNumber, setFeventNumber] = useState<number>(0);
  const [shopOrderNumber, setShopOrderNumber] = useState<number>(0);
  //* bugoBrand로 가져올 수 있는 부고(fevent)의 개수를 가져오는 hook
  useFeventCountByBugoBrandhook(
    bugoBrandId,
    bugoBrandId + '-today',
    getTodayFrom(now),
    now,
  );
  useFeventCountByBugoBrandhook(
    bugoBrandId,
    bugoBrandId + '-this-week',
    getThisWeekFrom(now),
    now,
  );
  useFeventCountByBugoBrandhook(
    bugoBrandId,
    bugoBrandId + '-this-month',
    getThisMonthFrom(now),
    now,
  );

  const todayCountState = useRecoilValue(
    feventCountByBugoBrandStateSelectorFamily(bugoBrandId + '-today'),
  );
  const thisWeekCountState = useRecoilValue(
    feventCountByBugoBrandStateSelectorFamily(bugoBrandId + '-this-week'),
  );
  const thisMonthCountState = useRecoilValue(
    feventCountByBugoBrandStateSelectorFamily(bugoBrandId + '-this-month'),
  );

  const bugoLenList = useMemo(
    () => [
      todayCountState.status === 'success' && !_.isNil(todayCountState.data)
        ? todayCountState.data
        : null,
      thisWeekCountState.status === 'success' && !_.isNil(thisWeekCountState.data)
        ? thisWeekCountState.data
        : null,
      thisMonthCountState.status === 'success' && !_.isNil(thisMonthCountState.data)
        ? thisMonthCountState.data
        : null,
    ],
    [thisMonthCountState, thisWeekCountState, todayCountState],
  );

  useAsync(async () => {
    const { data } = await api_.get<{ numFevent: number; numShopOrder: number }>(
      `/${bugoRole()}/fevent/stats/shop-order?${queryString({
        filter: {
          createdAt: {
            $gte: getThirtyDayBefore(now),
          },
        },
      })}`,
    );

    const FPBPoint = data.numFevent === 0 ? 0 : data.numShopOrder / data.numFevent;
    setFPBPoint(FPBPoint);
    setFeventNumber(data.numFevent);
    setShopOrderNumber(data.numShopOrder);
  }, []);

  return (
    <>
      <div className="space-y-3">
        <p className="text-14 font-bold">부고 등록 상황</p>
        <div className="shadow-smd">
          <RegisteredBugoStatusBar bugoLenList={bugoLenList} bugoBrandId={bugoBrandId} />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-14 font-bold">금월 부고등록 대비 화환 판매 지표</p>
        <div className="center-box flex-1">
          <Button className="button-fold px-4" onClick={() => setTotalStatOpen(true)}>
            {FPBPoint.toFixed(2)}
          </Button>
        </div>
      </div>
      <AgencyMainLinkList />

      {isBugoAgencyBranchAdmin() ? <BugoMainAnouncementList /> : <AgencyAnnouncement />}

      <AgencyTotalStatModal
        openId={totalStatOpenId}
        numFevent={feventNumber}
        numShopOrder={shopOrderNumber}
      />
    </>
  );
};
