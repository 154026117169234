import { FeventStatus } from '@shared/api/fevent/fevent.interface';
import {
  fetcher,
  queryString,
  useRecoilQuery,
  useRecoilQueryInterVal,
} from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole, PaymentState } from '@shared/types';

import {
  activeFeventListForWorkerDataAtom,
  activeFeventListForWorkerStatusAtom,
  feventByShopDataAtomFamily,
  feventByShopStatusAtomFamily,
  feventCountByBugoBrandDataAtomFamily,
  feventCountByBugoBrandStatusAtomFamily,
  feventDataAtomFamily,
  feventDoingListDataAtom,
  feventDoingListStatusAtom,
  feventListAgencyAdminDataAtomFamily,
  feventListAgencyAdminStatusAtomFamily,
  feventListByBugoBrandDataAtomFamily,
  feventListByBugoBrandStatusAtomFamily,
  feventListDataAtom,
  feventListStatusAtom,
  feventStatusAtomFamily,
} from './fevent.atom';

export const feventHookUrl = (feventId: string, bugoRole: string) =>
  `${bugoRole}/fevent/${feventId}/?${queryString({
    populate: [
      {
        path: 'user',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'roomInfo',
      },
      {
        path: 'deceasedInfo.dpImage',
      },
      {
        path: 'memberOrderList',
        populate: [
          {
            path: 'cashAccount',
          },
          {
            path: 'bugo',
          },
        ],
      },
      {
        path: 'cashAccounts',
      },
    ],
  })}`;

//* FeventIdRouter 부고수정 부고전송 답례글 전송
export const useFeventHook = function (id: string, disabled?: boolean) {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    feventStatusAtomFamily(id),
    feventDataAtomFamily(id),
    feventHookUrl(id, bugoRole()),
    fetcher,
    disabled,
  );
};

export const feventListHookUrl = (page: number, step: number, bugoRole: LowerRole) => {
  return `${bugoRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
      limit: step,
      skip: step * page,
    },
    populate: [
      {
        path: 'roomInfo',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'memberOrderList',
      },
    ],
  })}`;
};

export const useFeventListhook = () => {
  const { bugoRole, isBugoAgencyWorker } = useAuth();
  const urlFunc = (page: number) => {
    return feventListHookUrl(page, 100, bugoRole());
  };

  useRecoilQueryInterVal(
    feventListStatusAtom,
    feventListDataAtom,
    urlFunc,
    fetcher,
    2000,
    isBugoAgencyWorker(),
  );
};

//* bugoManagPage에서 사용중
export const activeFeventListForWorkerHookUrl = (
  bugoRole: LowerRole,
  isAdmin: boolean,
) => {
  if (isAdmin) {
    return `${bugoRole}/fevent/?${queryString({
      options: {
        sort: {
          createdAt: -1,
        },
      },
      filter: {
        'meta.isDeleted': { $ne: true },
      },
      populate: isAdmin
        ? [
            {
              path: 'roomInfo',
            },
            {
              path: 'funeralHomeInfo',
            },
            {
              path: 'memberOrderList',
            },
            {
              path: 'user',
            },
            {
              path: 'bugoBrand',
            },
          ]
        : [
            {
              path: 'roomInfo',
            },
            {
              path: 'funeralHomeInfo',
            },
            {
              path: 'memberOrderList',
            },
          ],
    })}`;
  } else {
    return `${bugoRole}/fevent/?${queryString({
      options: {
        sort: {
          createdAt: -1,
        },
      },
      filter: {
        'meta.isDeleted': { $ne: true },
      },
      populate: isAdmin
        ? [
            {
              path: 'roomInfo',
            },
            {
              path: 'funeralHomeInfo',
            },
            {
              path: 'memberOrderList',
            },
            {
              path: 'user',
            },
            {
              path: 'bugoBrand',
            },
          ]
        : [
            {
              path: 'roomInfo',
            },
            {
              path: 'funeralHomeInfo',
            },
            {
              path: 'memberOrderList',
            },
          ],
    })}`;
  }
};

export const useActiveFeventListForWorkerHook = () => {
  const { bugoRole, isAdmin } = useAuth();

  useRecoilQuery(
    activeFeventListForWorkerStatusAtom,
    activeFeventListForWorkerDataAtom,
    activeFeventListForWorkerHookUrl(bugoRole(), isAdmin()),
    fetcher,
  );
};

export const feventDoingListHookUrl = (page: number, bugoRole: LowerRole) => {
  return `${bugoRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
      limit: 10,
      skip: 10 * page,
    },
    filter: { status: FeventStatus.Doing },
    populate: [
      { path: 'user' },
      {
        path: 'roomInfo',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'memberOrderList',
      },
    ],
  })}`;
};

export const useFeventDoingListhook = (page = 0) => {
  const { isAuthenticated, bugoRole } = useAuth();
  useRecoilQuery(
    feventDoingListStatusAtom,
    feventDoingListDataAtom,
    feventDoingListHookUrl(page, bugoRole()),
    fetcher,
    !isAuthenticated,
  );
};

//*상조관리자가 볼 fevent
//TODO:paginateion해놓으삼@김정민
export const feventListByBugoBrandHookUrl = (
  bugoBrandId: string,
  bugoRole: LowerRole,
) => {
  return `${bugoRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    filter: {
      bugoBrand: bugoBrandId,
    },
    populate: [
      {
        path: 'user',
      },
      {
        path: 'shopOrders',
      },
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'memberOrderList',
        populate: {
          path: 'bugo',
        },
      },
    ],
  })}`;
};

export const useFeventListByBugoBrandHook = (bugoBrandId: string) => {
  const { bugoRole, isAdmin, isBugoAgencyAdmin, isBugoAgencyBranchAdmin } = useAuth();
  useRecoilQuery(
    feventListByBugoBrandStatusAtomFamily(bugoBrandId),
    feventListByBugoBrandDataAtomFamily(bugoBrandId),
    feventListByBugoBrandHookUrl(bugoBrandId, bugoRole()),
    fetcher,
    !(isAdmin() || isBugoAgencyAdmin() || isBugoAgencyBranchAdmin()),
  );
};

//*상조관리자가 볼 fevent(refactoring)
export const feventListAgencyAdminHookUrl = (
  bugoBrandId: string,
  bugoRole: LowerRole,
) => {
  return `${bugoRole}/fevent/?${queryString({
    select: [
      '_id',
      'createdAt',
      'user',
      'funeralHomeInfo',
      'funeralHomeInfoEmbed.address',
      'funeralHomeInfoEmbed.name',
      'meta.isDeleted',
      'registerNumber',
      'memberOrderList',
      'deceasedInfo.coffinOut',
      'deceasedInfo.name',
      'shopOrders',
      'bugos',
    ],
    options: {
      sort: { createdAt: -1 },
    },
    filter: {
      bugoBrand: bugoBrandId,
    },
    populate: [
      {
        path: 'user',
        select: [
          '_id',
          'bugoAgencyWorkerDetail.teamName',
          'bugoAgencyWorkerDetail.teamType',
          'bugoAgencyWorkerDetail.region',
          'info.name',
        ],
      },
      {
        path: 'shopOrders',
        match: {
          'paymentDetail.status': { $in: [PaymentState.PAID, PaymentState.CONFIRMED] },
        },
        select: [
          '_id',
          'orderDetail.shopItemEmbed.name',
          'paymentDetail.paymentByCash',
          'paymentDetail.purchasedAt',
        ],
      },
      {
        path: 'funeralHomeInfo',
        select: ['_id', 'address', 'name'],
      },
      {
        path: 'bugos',
        select: ['_id', 'lastSentPhoneNumber'],
      },
      // {
      //   path: 'memberOrderList',
      //   select: ['_id', 'fullName', 'phoneNumber'],
      //   populate: {
      //     path: 'bugo',
      //     select: ['_id', 'lastSentPhoneNumber'],
      //   },
      // },
    ],
  })}`;
};

export const useFeventListAgencyAdminHook = (bugoBrandId: string, disabled?: boolean) => {
  const { bugoRole, isAdmin, isBugoAgencyAdmin, isBugoAgencyBranchAdmin } = useAuth();
  useRecoilQuery(
    feventListAgencyAdminStatusAtomFamily(bugoBrandId),
    feventListAgencyAdminDataAtomFamily(bugoBrandId),
    feventListAgencyAdminHookUrl(bugoBrandId, bugoRole()),
    fetcher,
    !(isAdmin() || isBugoAgencyAdmin() || isBugoAgencyBranchAdmin() || !disabled),
  );
};

export const feventCountByBugoBrandHookUrl = (
  bugoBrandId: string,
  bugoRole: LowerRole,
  dateFrom?: Date,
  dateTo?: Date,
) => {
  const dateFilterOn = dateFrom || dateTo;

  return `${bugoRole}/fevent/count/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    filter: {
      bugoBrand: bugoBrandId,
      createdAt: dateFilterOn && {
        $gte: dateFrom,
        $lt: dateTo,
      },
    },
  })}`;
};

export const useFeventCountByBugoBrandhook = (
  bugoBrandId: string,
  atomId: string,
  dateFrom?: Date,
  dateTo?: Date,
) => {
  const { bugoRole, isAdmin, isBugoAgencyAdmin, isBugoAgencyBranchAdmin } = useAuth();
  useRecoilQuery(
    feventCountByBugoBrandStatusAtomFamily(atomId),
    feventCountByBugoBrandDataAtomFamily(atomId),
    feventCountByBugoBrandHookUrl(bugoBrandId, bugoRole(), dateFrom, dateTo),
    fetcher,
    !(isAdmin() || isBugoAgencyAdmin() || isBugoAgencyBranchAdmin()),
  );
};

export const feventByShopHookUrl = (shopId: string, sellerRole: LowerRole) => {
  return `${sellerRole}/fevent/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    populate: [
      {
        path: 'funeralHomeInfo',
      },
      {
        path: 'roomInfo',
      },

      {
        path: 'memberOrderList',
        populate: {
          path: 'bugo',
        },
      },
    ],
    filter: {
      status: { $in: [FeventStatus.Doing, FeventStatus.Todo] },
    },
  })}`;
};

export const useFeventByShopHook = (shopId: string) => {
  const { sellerRole } = useAuth();
  useRecoilQuery(
    feventByShopStatusAtomFamily(shopId),
    feventByShopDataAtomFamily(shopId),
    feventByShopHookUrl(shopId, sellerRole()),
    fetcher,
  );
};
