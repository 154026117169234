import { FC } from 'react';

import { AdminRouter } from '@route/AdminRouter';
import AgencyWorkerMyPage from '@service/bugo/page/AgencyWorkerMyPage';
import BugoBrandAnnouncementAddPage from '@service/bugo/page/BugoBrandAnnouncementAddPage';
import BugoBrandAnnouncementEditPage from '@service/bugo/page/BugoBrandAnnouncementEditPage';
import BugoBrandAnnouncementManagePage from '@service/bugo/page/BugoBrandAnnouncementManagePage';
import BugoMainPage from '@service/bugo/page/BugoMainPage';
import BugoMyEnquiriesPage from '@service/bugo/page/BugoMyEnquiriesPage';
import { BugoUpgradePage } from '@service/bugo/page/BugoUpgradePage';
import FeventAddPage from '@service/bugo/page/FeventAddPage';
import FeventManagePage from '@service/bugo/page/FeventManagePage';
import UserChangePasswordPage from '@service/bugo/page/UserChangePasswordPage';
import WorkerAccouintPointPage from '@service/bugo/page/WorkerAccountPointPage';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes } from 'react-router-dom';

import BugoBrandIdRouter from './BugoBrandIdRouter';
import FeventIdRouter from './FeventIdRouter';

export const BugoAuthRouter: FC = () => {
  const { isAdmin } = useAuth();

  sessionStorage.removeItem('redirectUrlAfterBugoLogin');

  return (
    <Routes>
      <Route path="" element={<BugoMainPage />} />
      <Route path="fevent">
        <Route path="add" element={<FeventAddPage />} />
        <Route path="manage" element={<FeventManagePage />} />
        <Route path=":feventId/*" element={<FeventIdRouter />} />
      </Route>
      <Route path="bugo-brand/*">
        <Route path="announcement/manage" element={<BugoBrandAnnouncementManagePage />} />
        <Route path="announcement/add" element={<BugoBrandAnnouncementAddPage />} />
        <Route
          path="announcement/:announcementId/edit"
          element={<BugoBrandAnnouncementEditPage />}
        />
        <Route path=":bugoBrandId/*" element={<BugoBrandIdRouter />} />
      </Route>
      <Route path="upgrade/select-type" element={<BugoUpgradePage />} />
      <Route path="my-enquiries" element={<BugoMyEnquiriesPage />} />
      <Route path="user">
        <Route path="mypage" element={<AgencyWorkerMyPage />} />
        <Route path="account-point" element={<WorkerAccouintPointPage />} />
        <Route path="change-password" element={<UserChangePasswordPage />} />
      </Route>
      <Route
        path="admin/*"
        element={isAdmin() ? <AdminRouter /> : <Navigate to="/login" />}
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default BugoAuthRouter;
