import { FC, useRef, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import { Icon } from '@shared/components/icons';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import html2canvas from 'html2canvas';
import { useAsync } from 'react-use';

import { courtesyAssetAtom, courtesyContentStringAtom } from './courtesy.atom';

interface Props {
  openId: string;
}

const saveImageToLocal = async (imageUrl: string) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();

  const file = new File([blob], 'image.jpeg', { type: 'image/jpeg' });
  const dataURL = URL.createObjectURL(file);

  return dataURL;
};

export const MemberCourtesyPreviewModal: FC<Props> = function MemberCourtesyPreviewModal({
  openId,
}: Props) {
  const { Download } = Icon();
  const courtesyAsset = useRecoilValue(courtesyAssetAtom);
  const imageUrl = courtesyAsset?.backgroundImage.url;
  const courtesyContentString = useRecoilValue(courtesyContentStringAtom);
  const divRef = useRef(null);
  const [localImageUrl, setLocalImageUrl] = useState<string>();

  useAsync(async () => {
    if (!imageUrl) return;

    const corsImageModified = new Image();
    corsImageModified.crossOrigin = 'Anonymous';
    const s3Url = fileBucketUrl(`${imageUrl}`, 'raw');

    corsImageModified.src = s3Url + '?not-from-cache-please';

    const localUrl = await saveImageToLocal(corsImageModified.src);

    setLocalImageUrl(localUrl);
  }, [imageUrl]);

  function handleDownloadClick() {
    const div_ = divRef.current;
    if (div_)
      html2canvas(div_, { useCORS: true, allowTaint: true }).then((canvas) => {
        const dataUrl = canvas.toDataURL('image/jpeg');
        const link = document.createElement('a');
        link.download = '답례인사.jpg';
        link.href = dataUrl;
        link.click();
      });
  }

  return (
    <Modal openId={openId}>
      <div className="relative" ref={divRef}>
        <img src={localImageUrl} />
        <div
          className={`noto-serif-kr absolute z-10 w-full whitespace-pre-wrap break-keep font-medium`}
          style={{
            fontSize: `${courtesyAsset?.textBox?.y1 ?? 12}px`,
            top: `${courtesyAsset?.textBox?.y0 ?? 30}px`,
            paddingLeft: `${courtesyAsset?.textBox?.x0 ?? 24}px`,
            paddingRight: `${courtesyAsset?.textBox?.x1 ?? 24}px`,
          }}
        >
          <p>{courtesyContentString}</p>
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          onClick={handleDownloadClick}
          className="button center-box gap-2 font-medium text-black"
        >
          <Download className="wh-6 fill-gray-700" />
          <p>다운로드</p>
        </Button>
      </div>
    </Modal>
  );
};
