import { FC } from 'react';

import { AdminBugoBrandForm } from '../containers/AdminBugoBrandForm/AdminBugoBrandForm';
import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

const AdminBugoBrandAddPage: FC = function AdminBugoBrandAddPage() {
  useAdminTitleHook('상조 등록');
  return (
    <div className="p-4">
      <AdminBugoBrandForm />
    </div>
  );
};

export default AdminBugoBrandAddPage;
