import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import BugoBrandAnnouncementForm from '@service/store/components/BugoBrandAnnouncementForm';
import { updateBugoBrandAnnouncement } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.controller';
import {
  bugoBrandAnnouncementListHookUrl,
  useBugoBrandAnnouncementHook,
} from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.hook';
import { BugoBrandAnnouncementUpdate } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.interface';
import { bugoBrandAnnouncementStateSelectorFamily } from '@shared/api/bugoBrandAnnouncement/bugoBrandAnnouncement.selector';
import { LoadingModal } from '@shared/components/LoadingModal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useTitleHook } from '../hooks/useTitleHook';
import { BugoBrandAnnouncementFormInputs } from '../interfaces/bugoBrandAnnouncementForm.interface';

const BugoBrandAnnouncementEditPage: FC = () => {
  useTitleHook('공지사항 수정');

  const { announcementId } = useParams<{ announcementId?: string }>();
  const { userProfile, bugoRole } = useAuth();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const navigate = useNavigate();

  useBugoBrandAnnouncementHook(announcementId ?? 'undefined');

  const bugoBrandAnnouncementState = useRecoilValue(
    bugoBrandAnnouncementStateSelectorFamily(announcementId ?? 'undefined'),
  );

  // TODO: 수정을 하고 다시 수정하러 들어왔을 때 useForm의 default value가 업데이트 되지 않음.

  // loding modal open state
  const queryClient = useQueryClient();
  const { isLoading: processingAnnouncement, mutateAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(bugoBrandAnnouncementListHookUrl(bugoRole()));
      },
    },
  );

  const onSubmit: SubmitHandler<BugoBrandAnnouncementFormInputs> = async (data) => {
    if (!userProfile?._id) return;

    if (!announcementId || !bugoBrandAnnouncementState.data)
      return notificateError(notifiacationInstance, '해당 공지가 존재하지 않습니다!');

    const { type, title, content, isReadRequired, teamTypes } = data;

    const announcementUpdateDto: BugoBrandAnnouncementUpdate = {
      _id: announcementId,
      type,
      title,
      content,
      isReadRequired,
      teamTypes: Object.keys(teamTypes).filter((teamType) => teamTypes[teamType]),
    };

    const onAnnouncementUpdate = async () => {
      const createdAnnouncement = await updateBugoBrandAnnouncement(
        announcementUpdateDto,
        bugoRole(),
      );

      if (createdAnnouncement) {
        notificateSuccess(notifiacationInstance, '공지가 정상적으로 수정되었습니다.');
        navigate('/');
      } else {
        notificateError(notifiacationInstance, '공지가 정상적으로 수정되지 않았습니다.');
      }
    };

    mutateAsync(onAnnouncementUpdate());
  };

  return (
    <>
      <div className="p-4">
        {/* Title */}
        <h1 className="py-8 text-center text-xl font-bold">공지사항</h1>
        {/* Form */}
        {bugoBrandAnnouncementState.status === 'success' &&
          bugoBrandAnnouncementState.data && (
            <BugoBrandAnnouncementForm
              onSubmit={onSubmit}
              defaultAnnouncement={bugoBrandAnnouncementState.data}
            />
          )}
      </div>
      <LoadingModal open={processingAnnouncement} title={'공지 수정중'} />
    </>
  );
};

export default BugoBrandAnnouncementEditPage;
