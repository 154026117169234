import { FC, HTMLAttributes } from 'react';

import { FeventDatePicker } from '@service/bugo/containers/FeventDatePicker/FeventDatePicker';
import { FeventTimePicker } from '@shared/containers/TimePicker/FeventTimePicker';
import { FeventDate } from '@shared/types';
import { UseFormSetValue } from 'react-hook-form';
import { useId } from 'react-id-generator';

/**
 * @param placeholder 입력된 value + 일/시각(Date/Time Picker)로 사용.
 * @param name {...register()}
 * @param onBlur {...register()}
 * @param onChange {...register()}
 * @param ref {...register()}
 * @returns DatePicker + TimePicker
 */

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string;
  fDate: FeventDate;
  setValue: UseFormSetValue<any>;
}

export const FeventDateTimePicker: FC<Props> = ({
  name,
  fDate,
  setValue,
  placeholder,
  className = '',
}: Props) => {
  const [id] = useId(1, 'feventDateTimePicker');

  return (
    <div className={`grid w-full grid-cols-2 gap-x-2 ${className}`}>
      <FeventDatePicker
        placeholder={`${placeholder}일`}
        fDate={fDate}
        name={name}
        setValue={setValue}
        pickerId={`${id}`}
      />
      <FeventTimePicker
        placeholder={`${placeholder}시간`}
        fDate={fDate}
        name={name}
        setValue={setValue}
        pickerId={`${id}`}
      />
    </div>
  );
};
