import { ReactNode, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import _ from 'lodash';

import PaginationTableRow from './PaginationTableRow';
import TablePagination from './TablePagination';

type RenderParam<T> = {
  data: T[keyof T];
  record: T;
  index: number;
};

export type PaginationTableRender<T> = {
  [key in keyof T]?: ((param: RenderParam<T>) => ReactNode) | null;
};

interface IProps<T> {
  columns: T[];
  keyListForOrder: (keyof T)[];
  columnNames: { [key in keyof T]: string };
  numOfRows?: number;
  render?: PaginationTableRender<T>;
}

const PaginationTable = <T,>({
  columns,
  keyListForOrder,
  columnNames,
  render,
  numOfRows = 3,
}: IProps<T>) => {
  // const { ChevronRight } = Icon();
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const customTheme = useRecoilValue(customThemeAtom);

  // chunkedList
  const chunkedList = useMemo<T[][]>(() => {
    return _.chunk(columns, numOfRows);
  }, [columns, numOfRows]);

  // renderItemList
  const renderItemList = useMemo(() => {
    const result = chunkedList[selectedPage - 1];
    return result ?? ([] as T[]);
  }, [chunkedList, selectedPage]);

  // Page Buttons
  const pageArray = Array.from({ length: chunkedList.length }, (_, i) => i + 1);
  const chunkedPage = _.chunk(pageArray, 5);
  const pageCursor = Math.floor((selectedPage - 1) / 5);
  const result = chunkedPage[pageCursor];

  return (
    <div>
      {/* Column Names */}
      <div>
        <PaginationTableRow<typeof columnNames>
          data={columnNames}
          keyListForOrder={keyListForOrder}
          rowIndex={-1}
          containerClassName={`text-xs font-bold bg-opacity-40 ${mainBgColorGenerator(
            customTheme,
          )}`}
        />
      </div>
      {/* Contents */}
      <div className="space-y-2">
        {renderItemList && renderItemList.length > 0 && (
          <div>
            {renderItemList.map((item, index) => (
              <div key={`pagination-table-row-${index}`}>
                <PaginationTableRow
                  data={item}
                  keyListForOrder={keyListForOrder}
                  rowIndex={index}
                  containerClassName="bg-gray-50"
                  render={render}
                />
              </div>
            ))}
          </div>
        )}
        {/* Page Buttons */}
        <TablePagination
          pageLength={chunkedList.length}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </div>
  );
};

export default PaginationTable;
