export enum CourtesyAssetUpsertFormEnum {
  Name = 'name',
  Order = 'order',
  BugoBrand = 'bugoBrand',
  BackgroundImage = 'backgroundImage',
  TextBox_X0 = 'textBox.x0',
  TextBox_X1 = 'textBox.x1',
  TextBox_Y0 = 'textBox.y0',
  TextBox_Y1 = 'textBox.y1',
  Id = '_id',
}

export interface CourtesyAssetUpsertFormInputs {
  [CourtesyAssetUpsertFormEnum.Id]?: string;
  [CourtesyAssetUpsertFormEnum.Name]: string;
  [CourtesyAssetUpsertFormEnum.Order]?: number;
  [CourtesyAssetUpsertFormEnum.BugoBrand]: string;
  [CourtesyAssetUpsertFormEnum.BackgroundImage]: string;
  [CourtesyAssetUpsertFormEnum.TextBox_X0]?: number;
  [CourtesyAssetUpsertFormEnum.TextBox_X1]?: number;
  [CourtesyAssetUpsertFormEnum.TextBox_Y0]?: number;
  [CourtesyAssetUpsertFormEnum.TextBox_Y1]?: number;
}
