import { ShopOrder } from '@shared/api/shopOrder/shopOrder.interface';
import { PaymentState } from '@shared/types';

export const filterPaidShopOrder = (shopOrderList: ShopOrder[]) => {
  // payment status가 paid거나 confirmed인 애들의 개수
  return shopOrderList.filter(
    (shopOrder) =>
      shopOrder.paymentDetail.status === PaymentState.CONFIRMED ||
      shopOrder.paymentDetail.status === PaymentState.PAID,
  );
};
