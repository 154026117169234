import { ShopItem, ShopItemForStoreData } from '@shared/api/shopItem/shopItem.interface';

import { Image } from '../media/media.interface';
import { ShopItemInfo } from '../shopItemInfo/shopItemInfo.interface';
export const getShopItemImageUrl = (
  shopItem:
    | ShopItem<ShopItemInfo<string, Image>, string, string, Image>
    | ShopItemForStoreData,
) => {
  return shopItem.image?.url ?? shopItem.shopItemInfo.image?.url;
};
