//* 주문상세내역 페이지 (결제 정보 및 해당 주문내)
import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { shopOrderForStoreStateSelectorFamily } from '@shared/api/shopOrder/shopOrder.selector';
import { Loading } from '@shared/components/Loading';
import { useParams } from 'react-router-dom';

import { PhraseInfoCard } from '../components/PhraseInfoCard';
import { ReceiverInfoCard } from '../components/ReceiverInfoCard';
import { OrderItemInfo } from '../containers/OrderDetail/OrderItemInfo';
import { PaymentInfo } from '../containers/OrderDetail/PaymentInfo';
import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

const OrderDetailPage: FC = function OrderDetailPage() {
  const { orderId } = useParams();

  useStoreTitleHook('주문 상세 내역');

  const ShopOrderForStoreState = useRecoilValue(
    shopOrderForStoreStateSelectorFamily(orderId ?? 'undefined'),
  );
  const data = ShopOrderForStoreState.data;

  return (
    <div className="p-4">
      {ShopOrderForStoreState.status === 'success' && data ? (
        <div className="space-y-6">
          {/* //*결제정보  */}
          <div className="space-y-3">
            <p className="bugo-h1">결제정보</p>
            <PaymentInfo shopOrder={data} />
          </div>
          {/*//*주문상품정보 */}
          <div className="space-y-3">
            <p className="bugo-h1">주문 상품 정보</p>
            <OrderItemInfo shopOrder={data} />
          </div>
          {/*//*배송지정보 */}
          <div className="space-y-3">
            <p className="bugo-h1">배송지 정보</p>
            <div>
              <ReceiverInfoCard shopOrder={data} />
              <PhraseInfoCard shopOrder={data} />
            </div>
          </div>
        </div>
      ) : (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default OrderDetailPage;
