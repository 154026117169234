import { FC } from 'react';

import { Icon } from '@shared/components/icons';

export const CustomerDepositInfoBox: FC = function CustomerDepositInfoBox() {
  const { CashAccount } = Icon();

  return (
    <div className="p-4 py-2 pb-4 theme-bg-1">
      <div className="center-box gap-6 font-bold">
        <div className="center-box flex-col items-center break-keep">
          <CashAccount />
          <p className="">입금계좌</p>
          <p className="text-14 theme-text-main">(주)가온프라임</p>
        </div>
        <div className="break-keep text-base">
          <p className="theme-text-main">기업은행</p>
          <p>137-115848-04-019</p>
        </div>
      </div>
      <div className="center-box pt-4">
        <div className="text-center font-medium">
          <p>위 계좌는 화환구매를 위한 입금계좌입니다</p>
          <p className="font-bold text-warn">※부의금을 보내시면 안됩니다※</p>
        </div>
      </div>
    </div>
  );
};
