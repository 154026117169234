//* 모바일용 부고 등록 내역 조회에서 부고 디테일을 보여주는 모달
import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import RegisteredBugoStatusBar from '@service/bugo/components/RegisteredBugoStatusBar';
import { FeventListByWorkerId } from '@service/bugo/containers/WorkerDetailModalOpener/state/workerDetailModal.atom';
import { useFeventListByWorkerId } from '@service/bugo/containers/WorkerDetailModalOpener/state/workerDetailModal.hook';
import { feventListByWorkerIdStateSelectoramily } from '@service/bugo/containers/WorkerDetailModalOpener/state/workerDetailModal.selector';
import Modal from '@shared/components/Modal';
import PaginationTable from '@shared/containers/PaginationTable/PaginationTable';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { getThisMonthFrom, getThisWeekFrom, getTodayFrom } from '@shared/utils/dateUtils';
import { formatDate } from '@shared/utils/formatDate';
import {
  mainBgColorGenerator,
  mainColorGenerator,
} from '@shared/utils/mainColorGenerator';

interface Props {
  open: boolean;
  openId: string;
  workerId: string;
  workerName: string;
}

//TODO 1-1 worker 받지않고 fevent[]로 받도록 변경
const getAllBugosOverFlowers = (fevents: FeventListByWorkerId | null): string => {
  if (!fevents) return '-';

  let bugos = 0;
  let flowers = 0;

  fevents.forEach((fevent) => {
    flowers += fevent.shopOrders.length;
    bugos += 1;
  });

  return bugos === 0 ? '0' : (flowers / bugos).toFixed(2);
};

const getFeventsLenInDateRange = (
  createdAtList: Date[],
  dateFrom: Date,
  dateTo?: Date,
) => {
  const to = dateTo ?? new Date();
  let result = 0;

  createdAtList.forEach((createdAt) => {
    if (dateFrom.getTime() <= createdAt.getTime() && createdAt.getTime() <= to.getTime())
      ++result;
  });

  return result;
};

const WorkerDetailModal: FC<Props> = ({ open, openId, workerId, workerName }: Props) => {
  const customTheme = useRecoilValue(customThemeAtom);

  useFeventListByWorkerId(workerId, !open);

  const feventListByWorkerIdState = useRecoilValue(
    feventListByWorkerIdStateSelectoramily(workerId),
  );

  /*
  TODO 0 사용하지 않고 workerId로 user 가져오도록
  ? 사용되는 user의 fields
    - info.name
  ! 상위 component에서 workerName을 가지고있어서 필요없음 
  */

  //TODO 1-2 worker가 아닌 workerId로 fevent[] 가져와서 사용하도록 변경

  /*
  TODO 1-3 worker?.bugoAgencyWorkerDetail?.fevents -> feventListByWorkerId(workerId)
  ? 사용되는 fevent의 fields
    - createdAt
    - funeralhomeInfoEmbed
    - funeralHomeInfo(pop)
      - name
    - shopOrders(pop)
      - paymentDetail.status
  */
  const bugoLenList = useMemo(() => {
    if (!feventListByWorkerIdState.data) return [null, null, null];

    const createdAtList = feventListByWorkerIdState.data.map(
      (fevent) => fevent.createdAt,
    );
    const now = new Date();

    return [
      getFeventsLenInDateRange(createdAtList, getTodayFrom(now)),
      getFeventsLenInDateRange(createdAtList, getThisWeekFrom(now)),
      getFeventsLenInDateRange(createdAtList, getThisMonthFrom(now)),
    ];
  }, [feventListByWorkerIdState.data]);

  return (
    <Modal id={openId} openId={openId} className="w-full" position="center">
      <div className="space-y-3 text-xs">
        <h4 className="text-base font-bold">
          {workerName ? `${workerName} 지도사` : '지도사상세정보'}
        </h4>
        {/* Status Bar */}
        <RegisteredBugoStatusBar bugoLenList={bugoLenList} navigateDisabled />
        {/* 지표 */}
        <div className="flex items-center justify-between">
          <h5 className="font-bold">부고등록건수 대비 화환 판매 지표</h5>
          <p
            style={{
              color: mainColorGenerator(customTheme),
            }}
            className={`rounded-xl bg-opacity-10 px-4 py-2 font-bold ${mainBgColorGenerator(
              customTheme,
            )}`}
          >
            {feventListByWorkerIdState.data
              ? getAllBugosOverFlowers(feventListByWorkerIdState.data)
              : ''}
          </p>
        </div>
        {/* Pagination Table */}
        {feventListByWorkerIdState.data && (
          <div className="space-y-2">
            <h5 className="font-bold">부고등록현황</h5>
            <PaginationTable
              columns={feventListByWorkerIdState.data.map((fevent) => ({
                createdAt: fevent.createdAt,
                funeralHomeInfoName: (
                  fevent.funeralHomeInfoEmbed ?? fevent.funeralHomeInfo
                )?.name,
                flowersLen: fevent.shopOrders.length,
              }))}
              keyListForOrder={['createdAt', 'funeralHomeInfoName', 'flowersLen']}
              columnNames={{
                createdAt: '등록일자',
                funeralHomeInfoName: '장례식장',
                flowersLen: '화환판매',
              }}
              render={{
                createdAt: ({ data: createdAt }) => (
                  <p>
                    {formatDate(new Date(createdAt), {
                      dateSeparater: '.',
                      timeSeparater: ':',
                      contains: {
                        hours: true,
                        minutes: true,
                      },
                    })}
                  </p>
                ),
              }}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WorkerDetailModal;
