import React, { useCallback } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { SubmitConfirmButton } from '@service/bugo/components/SubmitConfirmButton';
import { SignupAgreementForm } from '@service/bugo/containers/SignupAgreementForm/SignupAgreementForm';
import { signupValidAtom } from '@service/bugo/containers/SignupAgreementForm/state/SignupValidate.atom';
import { useTitleHook } from '@service/bugo/hooks/useTitleHook';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';

//! pseudocode
/**
 * @components
 * @shared
 *! - CheckBox
 *! - SubmitConfirmButton
 * @structure
 *! - NavBar
 *! - AgreementForm
 *! |- GuideTitle
 *! |- AgreementList
 *! |- Precautions
 *! - UserRoleSelectForm
 *! |- GuideTitle
 *! |- RoleRadioList
 *! - SubmitConfirmButton
 */

const TermsAgreementPage: React.FC = () => {
  useTitleHook('회원가입');
  //TODO: text용도, 나중에 지워야 함
  const [theme, setTheme] = useRecoilState(customThemeAtom);

  const themeToggle = useCallback(() => {
    switch (theme) {
      case UserCustomTheme.Default:
        setTheme(UserCustomTheme.Preed);
        break;
      case UserCustomTheme.Preed:
        setTheme(UserCustomTheme.Dark);
        break;
      case UserCustomTheme.Dark:
        setTheme(UserCustomTheme.Default);
        break;
      default:
        setTheme(UserCustomTheme.Dark);
    }
  }, [theme, setTheme]);
  //TODO: 여기까지
  const isValid = useRecoilValue(signupValidAtom);

  return (
    <div onClick={themeToggle} className="max-w-inherit">
      <SignupAgreementForm purpose="signup" />
      <SubmitConfirmButton text="다음" disabled={!isValid} />
    </div>
  );
};

export { TermsAgreementPage };
