import { FC, useMemo, useState } from 'react';

import { ShopOrderForWorker } from '@shared/api/shopOrder/shopOrder.interface';
import { getRebateDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

import { AgencyWorkerAccountDetailModalButton } from '../AgencyWorkerAccountDetail/AgencyWorkerAccountDetailModalButton';

//* 연간 정산 완료 및 예정 내역
interface Props {
  shopOrderList: ShopOrderForWorker[];
}
type AccountData = {
  id: string;
  month: number;
  rebatedDate?: Date;
  rebatePrice: number;
  rebateWorkerPrice: number;
  rebateCount: number;
  willRebateCount: number;
  willRebatePrice: number;
  willRebateWorkerPrice: number;
  rebatedOrderList: ShopOrderForWorker[];
  willRebatedOrderList?: ShopOrderForWorker[];
};

const columns: TableColumnsType<AccountData> = [
  {
    title: '기간',
    dataIndex: 'month',
    key: 'month',
    align: 'center',
    render: (month: number) => {
      return <p className="py-1 text-base font-medium">{month}월</p>;
    },
  },
  {
    title: '정산일',
    dataIndex: 'rebatedDate',
    key: 'rebatedDate',
    align: 'center',
    render: (_, record) => {
      if (record.rebatedDate) {
        return (
          <p className="py-1 text-base font-medium text-green-600">
            {formatDate(record.rebatedDate, {
              contains: {
                year: false,
              },
            })}{' '}
            완료
          </p>
        );
      } else {
        const rebatedDate = getRebateDate(record.month);
        const now = new Date();
        if (now.getTime() > rebatedDate.getTime()) {
          return (
            <p className="py-1 text-base font-medium theme-text-warn-03">
              {formatDate(getRebateDate(record.month), {
                contains: {
                  year: false,
                },
              })}{' '}
              미지급
            </p>
          );
        } else {
          return (
            <p
              className={`py-1 text-base font-medium ${
                record.willRebateCount > 0 ? 'theme-text-main' : ''
              }`}
            >
              {formatDate(getRebateDate(record.month), {
                contains: {
                  year: false,
                },
              })}{' '}
              예정
            </p>
          );
        }
      }
    },
  },
  {
    title: '개수',
    dataIndex: 'rebateCount',
    key: 'rebateCount',
    align: 'center',
    render: (rebateCount: number, record) => {
      const now = new Date();
      if (rebateCount === 0 && record.willRebateCount) {
        return (
          <p
            className={`py-1 text-base font-medium ${
              now.getTime() > getRebateDate(record.month).getTime()
                ? 'theme-text-warn-03'
                : 'theme-text-main'
            }`}
          >
            {record.willRebateCount.toLocaleString()}개
          </p>
        );
      } else {
        return (
          <p className="py-1 text-base">
            {rebateCount === 0 ? '-' : rebateCount.toLocaleString() + '개'}
          </p>
        );
      }
    },
  },

  {
    title: '정산금',
    dataIndex: 'rebateWorkerPrice',
    key: 'rebateWorkerPrice',
    align: 'center',
    render: (rebateWorkerPrice: number, record) => {
      const now = new Date();
      if (rebateWorkerPrice === 0 && record.willRebateWorkerPrice) {
        return (
          <p
            className={`py-1 text-base font-medium ${
              now.getTime() > getRebateDate(record.month).getTime()
                ? 'theme-text-warn-03'
                : 'theme-text-main'
            }`}
          >
            {record.willRebateWorkerPrice?.toLocaleString()}원
          </p>
        );
      } else {
        return (
          <p className="py-1 text-base font-medium">
            {rebateWorkerPrice === 0 ? '-' : rebateWorkerPrice.toLocaleString() + '원'}
          </p>
        );
      }
    },
  },

  {
    title: '상세',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      if (record.rebatedOrderList.length === 0 && record.willRebatedOrderList) {
        return (
          <div className="center-box">
            <AgencyWorkerAccountDetailModalButton
              shopOrderList={record.willRebatedOrderList}
            />
          </div>
        );
      } else {
        return (
          <div className="center-box">
            <AgencyWorkerAccountDetailModalButton
              shopOrderList={record.rebatedOrderList}
            />
          </div>
        );
      }
    },
  },
];

export const AgencyWorkerAnnualAccountedTable: FC<Props> =
  function AgencyWorkerAnnualAccountedTable({ shopOrderList }: Props) {
    //연도 설정

    const now = new Date();
    //TODO: year 설정
    const [selectYear, setSelectYear] = useState<number>(now.getFullYear());

    // 1. 월별로 주문을 분류할 객체를 만듭니다.
    const monthlyAccountedOrders: { [month: number]: ShopOrderForWorker[] } = {};
    for (let i = 1; i <= 12; i++) {
      monthlyAccountedOrders[i] = [];
    }

    const monthlyWillAccountOrders: { [month: number]: ShopOrderForWorker[] } = {};
    for (let i = 1; i <= 12; i++) {
      monthlyWillAccountOrders[i] = [];
    }

    // 2. order 배열을 순회하면서, 각 주문의 월을 결정합니다.
    shopOrderList.map((order) => {
      if (order.paymentDetail.isRebated && order.paymentDetail.requestedAt) {
        const month = order.paymentDetail.requestedAt.getMonth() + 1;
        monthlyAccountedOrders[month]?.push(order);
      } else {
        if (order.paymentDetail.requestedAt) {
          const month = order.paymentDetail.requestedAt.getMonth() + 1;
          monthlyWillAccountOrders[month]?.push(order);
        }
      }
    });

    const resultArray = _.map(monthlyAccountedOrders, (orderList, index) => {
      return {
        rebatedOrderList: orderList,
        willRebatedOrderList: monthlyWillAccountOrders[Number(index)],
      };
    });

    const tableData: AccountData[] = useMemo(() => {
      const now = new Date();
      const annualData = _.map(resultArray, (item, index) => {
        return {
          id: `accountTable-${index}`,
          month: index + 1,
          rebatedDate:
            !(item.willRebatedOrderList && item.willRebatedOrderList.length > 0) &&
            item.rebatedOrderList.length === 0 &&
            now.getTime() > getRebateDate(index + 1).getTime()
              ? getRebateDate(index + 1)
              : item.rebatedOrderList[0]?.paymentDetail.accountedDate,
          rebateCount: item.rebatedOrderList.length,
          willRebateCount: item.willRebatedOrderList?.length ?? 0,
          rebatePrice:
            item.rebatedOrderList.reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceRebate;
            }, 0) ?? 0,
          rebateWorkerPrice:
            item.rebatedOrderList.reduce((accu, prev) => {
              return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
            }, 0) ?? 0,
          willRebatePrice:
            item.willRebatedOrderList?.reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceRebate;
            }, 0) ?? 0,
          willRebateWorkerPrice:
            item.willRebatedOrderList?.reduce((accu, prev) => {
              return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
            }, 0) ?? 0,
          rebatedOrderList: item.rebatedOrderList,
          willRebatedOrderList: item.willRebatedOrderList,
        };
      });
      return annualData;
    }, [resultArray]);

    return (
      <div className="space-y-4">
        <p className="text-base font-medium">{selectYear}년 정산완료 내역</p>
        <Table
          className="w-full"
          columns={columns}
          dataSource={_.flatMapDeep([tableData])}
          pagination={{
            defaultPageSize: 13,
            hideOnSinglePage: true,
          }}
          rowKey={'id'}
          bordered
          size={'small'}
        />
        <div className="button-rectangle flex cursor-default items-center justify-evenly gap-4 rounded-lg text-base theme-text-4 theme-bg-12">
          <p>합산</p>
          <p>{shopOrderList.length}건</p>
          <p>
            {shopOrderList
              .reduce((accu, prev) => {
                return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
              }, 0)
              .toLocaleString()}
            원
          </p>
        </div>
      </div>
    );
  };
