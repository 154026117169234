import { atom } from 'recoil';

/**
 * open, setOpen을 openId하나로 관리
 */
export const storeNavBarTitleAtom = atom({
  key: 'storeNavBarTitleAtom',
  default: '',
});

export const storeNavRightTextAtom = atom({
  key: 'storeNavRightTextAtom',
  default: '',
});

export const storeNavBarUseAtom = atom({
  key: 'storeNavBarUseAtom',
  default: true,
});

export const storeNavBackUrlAtom = atom<string | null>({
  key: 'storeNavBackUrlAtom',
  default: null,
});
