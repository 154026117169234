import { FC } from 'react';

import SellerAccountDetailPage from '@service/seller/page/SellerAccountDetailPage';
import SellerAccountPage from '@service/seller/page/SellerAccountPage';
import { useShopOrderListForSellerByShopAccountHook } from '@shared/api/shopOrder/shopOrder.hook';
import { Route, Routes, useParams } from 'react-router-dom';

export const SellerAccountRouter: FC = function SellerAccountRouter() {
  const { shopId } = useParams();

  useShopOrderListForSellerByShopAccountHook(`${shopId}`);

  return (
    <Routes>
      <Route path="" element={<SellerAccountPage />} />
      <Route path="detail" element={<SellerAccountDetailPage />} />
    </Routes>
  );
};
