import { FC } from 'react';

import { Icon } from '@shared/components/icons';
import _ from 'lodash';

interface IProps {
  left: string;
  right: string;
  isChecked?: boolean;
}

const StatPageTableHeaderBox: FC<IProps> = ({ left, right, isChecked }: IProps) => {
  const { BigCheck } = Icon();

  return (
    <div className="flex w-full items-center justify-between gap-2 rounded-lg bg-white p-4 px-6 text-lg">
      <div className="center-box gap-4">
        <div className="rounded-md theme-bg-5">
          {_.isUndefined(isChecked) ? (
            <div className="wh-3 rounded-full theme-bg-main"></div>
          ) : isChecked ? (
            <BigCheck className="wh-8" />
          ) : (
            <div className="wh-8 rounded-md theme-bg-9"></div>
          )}
        </div>
        <p className="break-keep font-medium">{left}</p>
      </div>
      <p className="break-keep font-bold">{right}</p>
    </div>
  );
};

export default StatPageTableHeaderBox;
