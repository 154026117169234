// 스토어 유저 마이페이지
import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useShopOrderListForStoreHook } from '@shared/api/shopOrder/shopOrder.hook';
import { shopOrderListForStoreStateSelector } from '@shared/api/shopOrder/shopOrder.selector';
import { Icon } from '@shared/components/icons';
import { Loading } from '@shared/components/Loading';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

import OrderListFilter from '../containers/OrderListFilter/OrderListFilter';
import { orderListFilterAtom } from '../containers/OrderListFilter/orderListFilter.atom';
import StoreCard from '../containers/StoreCard/StoreCard';
import StoreMyPageHeader from '../containers/StoreMyPageHeader/StoreMyPageHeader';
import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

const StoreMyPage: FC = () => {
  const { Delivery, ChevronRight, CS } = Icon();
  useStoreTitleHook('마이페이지');
  const { storeRole, userProfile } = useAuth();
  useShopOrderListForStoreHook(storeRole());

  const shopOrderListForStoreState = useRecoilValue(shopOrderListForStoreStateSelector);
  const orderListFilter = useRecoilValue(orderListFilterAtom);

  const [viewAllList, setViewAllList] = useState<boolean>(false);
  const [listLen, setListLen] = useState<number>(0);

  const renderStoreCardList = useMemo(() => {
    if (
      shopOrderListForStoreState.status === 'success' &&
      shopOrderListForStoreState.data
    ) {
      let filteredList = shopOrderListForStoreState.data;

      if (orderListFilter.isActive) {
        filteredList = filteredList.filter(
          (shopOrder) => orderListFilter.contains[shopOrder.deliveryDetail.status],
        );
      }

      // TODO: Pagination 필요!
      const slicedList = viewAllList ? filteredList : filteredList.slice(0, 3);
      setListLen(filteredList.length);

      return slicedList.map((shopOrder) => (
        <StoreCard key={`shopOrder-${shopOrder._id}`} shopOrder={shopOrder} />
      ));
    } else {
      return (
        <div className="center-box h-screen-15">
          <Loading />
        </div>
      );
    }
  }, [shopOrderListForStoreState, orderListFilter, viewAllList]);

  return (
    <div>
      {/* Header */}
      {/* TODO: userId를 username이나 name으로 바꿔줘야 함. */}
      <StoreMyPageHeader name={userProfile?.info.name ?? ''} />
      {/* Content */}
      <div className="space-y-3 px-4 py-3">
        {/* Links */}
        <div className="flex flex-col gap-3">
          <Link to="/flower-store/order" className="px-3 py-2 pb-4 shadow-smd theme-bg-1">
            <div className="grid w-full grid-cols-auto-1fr-auto items-center justify-between">
              <div className="pl-2 pr-4 ">
                <div className="center-box wh-10 rounded-full p-1.5 theme-bg-18">
                  <Delivery />
                </div>
              </div>
              <p className="bugo-h1 w-full text-start">주문 배송 내역</p>
              <div className="center-box wh-10">
                {/* TODO: Theme에 맞는 아이콘으로 변경. */}
                <ChevronRight />
              </div>
              <div></div>
              <p className="noto-sans-kor text-start text-12 font-normal leading-5 theme-text-8">
                주문 배송 내역을 확인합니다
              </p>
            </div>
          </Link>
          <Link to="/flower-store/faq" className="px-3 py-2 pb-4 shadow-smd theme-bg-1">
            <div className="grid w-full grid-cols-auto-1fr-auto items-center justify-between">
              <div className="pl-2 pr-4 ">
                <div className="center-box wh-10 rounded-full p-1 theme-bg-18">
                  <CS />
                </div>
              </div>
              <p className="bugo-h1 w-full text-start">부고 등록</p>
              <div className="center-box wh-10">
                {/* TODO: Theme에 맞는 아이콘으로 변경. */}
                <ChevronRight />
              </div>
              <div></div>
              <p className="noto-sans-kor text-start text-12 font-normal leading-5 theme-text-8">
                고객 센터 문의하기
              </p>
            </div>
          </Link>
        </div>
        {/* 최근주문내역 */}
        <div className="space-y-3 pt-3">
          <h3 className="text-14 font-bold">최근 주문 내역</h3>
          {/* 배달 상태 표시 바 */}
          {shopOrderListForStoreState.status === 'success' &&
            shopOrderListForStoreState.data && (
              <OrderListFilter
                shopOrderDeliveryStatusList={shopOrderListForStoreState.data.map(
                  (shopOrder) => shopOrder.deliveryDetail.status,
                )}
              />
            )}
          {/* Cards */}
          <div className="flex flex-col items-center space-y-3">
            <div className="w-full space-y-3">{renderStoreCardList}</div>
            {listLen > 2 && (
              <Button
                onClick={() => setViewAllList((curr) => !curr)}
                className="button-fold"
              >
                <p>{viewAllList ? '접기' : '더보기'}</p>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreMyPage;
