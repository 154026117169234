import { DefaultValue, atom, selector } from 'recoil';
export const useSplashAtom = atom<boolean>({
  key: 'useSplashAtom',
  default: true,
});

export const useSplashSelector = selector<boolean | null>({
  key: 'useSplashSelector',
  get: ({ get }) => {
    const useSplash = get(useSplashAtom);
    return useSplash;
  },
  set: ({ set }, val: boolean | null | DefaultValue) => {
    if (val instanceof DefaultValue) {
      sessionStorage.removeItem('userProfile');
    } else if (val) {
      sessionStorage.setItem('userProfile', JSON.stringify(val));
      set(useSplashAtom, val);
    } else {
      sessionStorage.removeItem('userProfile');
    }
  },
});
