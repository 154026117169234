import React, { useCallback } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { SubmitConfirmButton } from '@service/bugo/components/SubmitConfirmButton';
import { SignupAgreementForm } from '@service/bugo/containers/SignupAgreementForm/SignupAgreementForm';
import { signupValidAtom } from '@service/bugo/containers/SignupAgreementForm/state/SignupValidate.atom';
import { useTitleHook } from '@service/bugo/hooks/useTitleHook';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { UserCustomTheme } from '@shared/types';
import { NotificationInstance } from 'antd/es/notification/interface';
const props = ['name', 'email', 'tel', 'address', 'icon'];
const opts = { multiple: true };

const getContacts = async (notifiacationInstance: NotificationInstance | null) => {
  try {
    // if ('contacts' in navigator) {
    const contacts = await ((navigator as any).contacts as any).select(props, opts);
    // console.log(contacts);
    // } else {
    //   notificateError(notifiacationInstance, '지원되지 않는 브라우저');
    // }
  } catch (ex) {
    // Handle any errors here.
  }
};

//! pseudocode
/**
 * @components
 * @shared
 *! - CheckBox
 *! - SubmitConfirmButton
 * @structure
 *! - NavBar
 *! - AgreementForm
 *! |- GuideTitle
 *! |- AgreementList
 *! |- Precautions
 *! - UserRoleSelectForm
 *! |- GuideTitle
 *! |- RoleRadioList
 *! - SubmitConfirmButton
 */

const BugoUpgradePage: React.FC = () => {
  useTitleHook('계정 업그레이드');
  //TODO: text용도, 나중에 지워야 함
  const [theme, setTheme] = useRecoilState(customThemeAtom);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const themeToggle = useCallback(() => {
    switch (theme) {
      case UserCustomTheme.Default:
        setTheme(UserCustomTheme.Preed);
        break;
      case UserCustomTheme.Preed:
        setTheme(UserCustomTheme.Dark);
        break;
      case UserCustomTheme.Dark:
        setTheme(UserCustomTheme.Default);
        break;
      default:
        setTheme(UserCustomTheme.Dark);
    }
  }, [theme, setTheme]);
  //TODO: 여기까지
  const isValid = useRecoilValue(signupValidAtom);

  return (
    <React.Fragment>
      <div onClick={() => getContacts(notifiacationInstance)}>
        <SignupAgreementForm purpose="upgrade" />
        <SubmitConfirmButton text="다음" disabled={!isValid} />
      </div>
    </React.Fragment>
  );
};

export { BugoUpgradePage };
