import { api_ } from '@shared/plugIn/axios';
import { AlertMethod, LowerRole } from '@shared/types';
import qs from 'qs';

import { Bugo, BugoCreate, BugoUpdate } from './bugo.interface';

const createBugo = async (bugoCreate: BugoCreate, bugoRole: LowerRole) => {
  const { data: bugo } = await api_.post<Bugo>(`/${bugoRole}/bugo`, bugoCreate);
  return bugo;
};

const updateBugo = async (bugoUpdate: BugoUpdate, bugoRole: LowerRole) => {
  const { data: bugo } = await api_.patch<Bugo>(
    `/${bugoRole}/bugo/${bugoUpdate._id}`,
    bugoUpdate,
  );
  return bugo;
};

const sendBugo = async (
  bugoIdList: string[],
  bugoRole: LowerRole,
  alertMethod?: AlertMethod,
) => {
  const { data: result } = await api_.get(
    `/${bugoRole}/alert/bugo?${qs.stringify(
      {
        bugos: JSON.stringify(bugoIdList),
        method: alertMethod,
      },
      {
        encode: false,
      },
    )}`,
  );
  return result;
};

const sendCourtesy = async (
  bugoIdList: string[],
  bugoRole: LowerRole,
  alertMethod?: AlertMethod,
) => {
  const { data: result } = await api_.get(
    `/${bugoRole}/alert/courtesy?${qs.stringify(
      {
        bugos: JSON.stringify(bugoIdList),
        method: alertMethod,
      },
      {
        encode: false,
      },
    )}`,
  );
  return result;
};

export { createBugo, updateBugo, sendBugo, sendCourtesy };
