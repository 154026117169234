import { FC, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CheckIcon } from '@heroicons/react/outline';
import { updateShopOrder } from '@shared/api/shopOrder/shopOrder.controller';
import { shopOrderListForSellerByShopUrl } from '@shared/api/shopOrder/shopOrder.hook';
import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { Label } from '@shared/components/Label';
import Modal from '@shared/components/Modal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { DeliveryState } from '@shared/types';
import { TimePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { useQueryClient } from 'react-query';

interface Props {
  openId: string;
  shopOrder: ShopOrderForSeller;
}

export const DeliveredModal: FC<Props> = function DeliveredModal({
  openId,
  shopOrder,
}: Props) {
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const onCloseModal = () => {
    setOpen(false);
    history.back();
  };

  const { sellerRole } = useAuth();
  const queryClient = useQueryClient();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const [timePickerOpen, setTimePickerOpen] = useState<boolean>(false);
  const [deliveredAt, setDeliveredAt] = useState<Date>(new Date());

  const onDeliveredClick = async () => {
    const deliveredShopOrder = {
      _id: shopOrder._id,
      'deliveryDetail.status': DeliveryState.DELIVERED,
      'deliveryDetail.deliveredAt': deliveredAt,
    };
    try {
      await updateShopOrder(deliveredShopOrder, sellerRole());
      await queryClient.invalidateQueries(
        shopOrderListForSellerByShopUrl(shopOrder.shop, sellerRole()),
      );
      notificateSuccess(notifiacationInstance, '상태변경에 성공하였습니다.');
      onCloseModal();
    } catch (err: any) {
      notificateError(notifiacationInstance, '실패');
    }
  };

  return (
    <Modal openId={openId}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <p className="text-xl font-medium leading-6 text-gray-900">배송 완료</p>
          <div className="mt-2">
            <p className="text-sm text-gray-500">배송완료시간 입력 후 확인</p>
          </div>
        </div>
      </div>
      <div className="center-box gap-4 pt-4">
        <Label>배송완료시각</Label>
        <TimePicker
          placeholder="배송완료시간"
          size="large"
          className="datepicker w-full text-base sm:w-32"
          locale={locale}
          open={timePickerOpen}
          onClick={() => setTimePickerOpen(true)}
          onBlur={() => setTimePickerOpen(false)}
          onSelect={(date: any) => {
            if (date) setDeliveredAt(date?.toDate());
          }}
          onOk={() => setTimePickerOpen(false)}
          format={'HH:mm'}
          minuteStep={5}
          showNow={false}
          popupClassName="text-base"
        />
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <button
          type="button"
          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-900 focus:outline-none sm:col-start-2`}
          onClick={async () => {
            await onDeliveredClick();
          }}
        >
          상태 업데이트
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none sm:col-start-1 sm:mt-0"
          onClick={onCloseModal}
        >
          취소
        </button>
      </div>
    </Modal>
  );
};
