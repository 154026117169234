import { SetterOrUpdater } from 'recoil';

import _ from 'lodash';
import { QueryStatus } from 'react-query';

interface WithId {
  _id: string;
}

async function deleteOneByIdAndSetListState<T extends WithId = WithId, RT = any>(
  // 0. args: id, data, setData, setStatus, api
  id: string,
  setData: SetterOrUpdater<T[] | null>,
  setStatus: SetterOrUpdater<QueryStatus>,
  // id를 넣은 api 완전체
  apiRequest: () => void,
) {
  // 1. queryStatus -> 'loading'
  setStatus('loading');

  try {
    // 2. backend api 투척 (delete)
    await apiRequest();

    // 3. 성공 시 dataAtom에서 data 조정 후 set
    setData((currArray) => {
      return _.dropWhile(currArray, (item) => {
        return item['_id'] === id;
      });
    });

    // 4. queryStatus -> 'success'
    setStatus('success');
  } catch (err) {
    // 5. api try catch에서 catch(err) 발생 시 -> queryStatus -> 'error'
    setStatus('error');
  }
}

export { deleteOneByIdAndSetListState };
