import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { ShopOrderAgencyAdmin } from '@shared/api/shopOrder/shopOrder.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { getRebateDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { DatePicker, Table, TableColumnsType } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import _ from 'lodash';

import { AgencyAdminAccountDetailModalButton } from '../AgencyAdminAccountDetail/AgencyAdminAccountDetailModalButton';
import { agencyAdminAccountTeamNameFilterAtom } from './AgencyAdminAccount.atom';

//* 연간 정산 완료 및 예정 내역
interface Props {
  shopOrderList: ShopOrderAgencyAdmin[];
}
type AccountData = {
  id: string;
  month: number;
  rebatedDate?: Date;
  rebateAgencyPrice: number;
  rebateWorkerPrice: number;
  rebateCount: number;
  rebatedOrderList: ShopOrderAgencyAdmin[];
};

const columns: (bugoBrand?: BugoBrand) => TableColumnsType<AccountData> = (
  bugoBrand?: BugoBrand,
) => {
  if (bugoBrand?.bugoAgency?.shareRebateWithWorker) {
    return [
      {
        title: '정산기간',
        dataIndex: 'month',
        key: 'month',
        align: 'center',
        render: (month: number) => {
          return <p className="px-6 py-1 text-base font-medium">{month}월</p>;
        },
      },
      {
        title: '정산일',
        dataIndex: 'rebatedDate',
        key: 'rebatedDate',
        align: 'center',
        render: (_, record) => {
          if (record.rebatedDate) {
            return (
              <p className="px-6 py-1 text-base font-medium text-green-600">
                {formatDate(record.rebatedDate)} 완료
              </p>
            );
          } else {
            const rebatedDate = getRebateDate(record.month);
            const now = new Date();
            if (now.getTime() > rebatedDate.getTime()) {
              return (
                <p className="px-6 py-1 text-base font-medium theme-text-warn-03">
                  {formatDate(getRebateDate(record.month))} 미지급
                </p>
              );
            } else {
              return (
                <p className={`px-6 py-1 text-base font-medium`}>
                  {formatDate(getRebateDate(record.month))} 예정
                </p>
              );
            }
          }
        },
      },
      {
        title: '총 판매건수',
        dataIndex: 'rebateCount',
        key: 'rebateCount',
        align: 'center',
        render: (rebateCount: number) => {
          return (
            <p className="px-6 py-1 text-base">
              {rebateCount === 0 ? '-' : rebateCount.toLocaleString() + '개'}
            </p>
          );
        },
      },
      {
        title: bugoBrand.bugoAgency.nickName + ' 정산금',
        dataIndex: 'rebateAgencyPrice',
        key: 'rebateAgencyPrice',
        align: 'center',
        render: (rebateAgencyPrice: number) => {
          return (
            <p className="px-6 py-1 text-base font-medium">
              {rebateAgencyPrice === 0 ? '-' : rebateAgencyPrice.toLocaleString() + '원'}
            </p>
          );
        },
      },
      {
        title: '지도사 정산금',
        dataIndex: 'rebateWorkerPrice',
        key: 'rebateWorkerPrice',
        align: 'center',
        render: (rebateWorkerPrice: number) => {
          return (
            <p className="px-6 py-1 text-base font-medium">
              {rebateWorkerPrice === 0 ? '-' : rebateWorkerPrice.toLocaleString() + '원'}
            </p>
          );
        },
      },
      {
        title: '합계',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          return (
            <p className="px-6 py-1 text-base font-medium">
              {record.rebateAgencyPrice + record.rebateWorkerPrice === 0
                ? '-'
                : (record.rebateAgencyPrice + record.rebateWorkerPrice).toLocaleString() +
                  '원'}
            </p>
          );
        },
      },
      {
        title: '세부내역보기',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="center-box">
              <AgencyAdminAccountDetailModalButton
                shopOrderList={record.rebatedOrderList}
              />
            </div>
          );
        },
      },
    ];
  } else {
    return [
      {
        title: '정산기간',
        dataIndex: 'month',
        key: 'month',
        align: 'center',
        render: (month: number) => {
          return <p className="px-6 py-1 text-base font-medium">{month}월</p>;
        },
      },
      {
        title: '정산일',
        dataIndex: 'rebatedDate',
        key: 'rebatedDate',
        align: 'center',
        render: (_, record) => {
          if (record.rebatedDate) {
            return (
              <p className="px-6 py-1 text-base font-medium text-green-600">
                {formatDate(record.rebatedDate)} 완료
              </p>
            );
          } else {
            const rebatedDate = getRebateDate(record.month);
            const now = new Date();
            if (now.getTime() > rebatedDate.getTime()) {
              return (
                <p className="px-6 py-1 text-base font-medium theme-text-warn-03">
                  {formatDate(getRebateDate(record.month))} 미지급
                </p>
              );
            } else {
              return (
                <p className={`px-6 py-1 text-base font-medium`}>
                  {formatDate(getRebateDate(record.month))} 예정
                </p>
              );
            }
          }
        },
      },
      {
        title: '총 판매건수',
        dataIndex: 'rebateCount',
        key: 'rebateCount',
        align: 'center',
        render: (rebateCount: number) => {
          return (
            <p className="px-6 py-1 text-base">
              {rebateCount === 0 ? '-' : rebateCount.toLocaleString() + '개'}
            </p>
          );
        },
      },
      {
        title: bugoBrand?.bugoAgency?.nickName ?? '' + ' 정산금',
        dataIndex: 'rebateAgencyPrice',
        key: 'rebateAgencyPrice',
        align: 'center',
        render: (rebateAgencyPrice: number) => {
          return (
            <p className="px-6 py-1 text-base font-medium">
              {rebateAgencyPrice === 0 ? '-' : rebateAgencyPrice.toLocaleString() + '원'}
            </p>
          );
        },
      },

      {
        title: '세부내역보기',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="center-box">
              <AgencyAdminAccountDetailModalButton
                shopOrderList={record.rebatedOrderList}
              />
            </div>
          );
        },
      },
    ];
  }
};

export const AnnualAccountedTable: FC<Props> = function AnnualAccountedTable({
  shopOrderList,
}: Props) {
  //연도 설정
  const { userProfile } = useAuth();
  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];

  const now = new Date();
  const [selectYear, setSelectYear] = useState<dayjs.Dayjs | null>(dayjs(now));

  const selectedYearDate = dayjs(selectYear).toDate();
  const selectedYear = selectedYearDate.getFullYear();

  // 1. 월별로 주문을 분류할 객체를 만듭니다.
  const monthlyAccountedOrders: { [month: number]: ShopOrderAgencyAdmin[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyAccountedOrders[i] = [];
  }

  const monthlyWillAccountOrders: { [month: number]: ShopOrderAgencyAdmin[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyWillAccountOrders[i] = [];
  }

  //shopOrderList를 연간데이터화
  const shopOrderYearList = shopOrderList.filter((order) => {
    const rebatedDate = order.paymentDetail.rebatedDate;
    if (rebatedDate) {
      // rebatedDate가 2023년 2월 1일 이상인지, 2024년 1월 31일 이하인지 확인
      if (
        rebatedDate >= new Date(`${selectedYear}-02-01`) &&
        rebatedDate <= new Date(`${selectedYear + 1}-01-31`)
      ) {
        return true;
      }
    }
    return false;
  });

  // 2. order 배열을 순회하면서, 각 주문의 월을 결정합니다.
  shopOrderYearList.map((order) => {
    if (order.paymentDetail.isRebated && order.paymentDetail.rebatedDate) {
      const month = order.paymentDetail.rebatedDate?.getMonth();
      if (month === 0) {
        monthlyAccountedOrders[12]?.push(order);
      } else {
        monthlyAccountedOrders[month]?.push(order);
      }
    }
  });

  const resultArray = _.map(monthlyAccountedOrders, (orderList) => {
    return {
      rebatedOrderList: orderList,
    };
  });

  // TeamName Filtering
  const selectedTeamNames = useRecoilValue(agencyAdminAccountTeamNameFilterAtom);

  const filteredResultArray = resultArray.map((item) => {
    if (selectedTeamNames) {
      return {
        rebatedOrderList: item.rebatedOrderList.filter((order) => {
          return selectedTeamNames[
            order?.fevent?.user?.bugoAgencyWorkerDetail?.teamName ?? 'etc'
          ];
        }),
      };
    } else {
      return {
        rebatedOrderList: item.rebatedOrderList,
      };
    }
  });

  const tableData: AccountData[] = useMemo(() => {
    const now = new Date();
    const annualData = _.map(filteredResultArray, (item, index) => {
      return {
        id: `accountTable-${index}`,
        month: index + 1,
        rebatedDate:
          item.rebatedOrderList.length === 0 &&
          now.getTime() > getRebateDate(index + 1).getTime()
            ? getRebateDate(index + 1)
            : item.rebatedOrderList[0]?.paymentDetail.rebatedDate,
        rebateCount: item.rebatedOrderList.length,
        rebateAgencyPrice:
          item.rebatedOrderList.reduce((accu, prev) => {
            return (
              accu +
              (prev.orderDetail.shopItemEmbed.priceRebateAgency ??
                prev.orderDetail.shopItemEmbed.priceRebate)
            );
          }, 0) ?? 0,
        rebateWorkerPrice:
          item.rebatedOrderList.reduce((accu, prev) => {
            return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
          }, 0) ?? 0,
        rebatedOrderList: item.rebatedOrderList,
      };
    });
    return annualData;
  }, [filteredResultArray]);

  const filteredShopOrder = shopOrderYearList.filter((order) => {
    if (selectedTeamNames) {
      return selectedTeamNames[
        order?.fevent?.user?.bugoAgencyWorkerDetail?.teamName ?? 'etc'
      ];
    } else return true;
  });

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between pr-4">
        <p className="pl-4 text-base font-medium">
          {selectYear?.format('YYYY년')} 연간 정산완료 내역
        </p>
        <DatePicker
          picker="year"
          locale={locale}
          size="large"
          defaultValue={selectYear ?? undefined}
          onChange={(date) => {
            setSelectYear(date);
          }}
        />
      </div>
      <Table
        className="w-full"
        columns={columns(bugoBrand)}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          defaultPageSize: 13,
          hideOnSinglePage: true,
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
      <div className="button-rectangle flex cursor-default items-center justify-evenly gap-4 rounded-lg text-base theme-text-4 theme-bg-12">
        <p>합산</p>
        <p>{filteredShopOrder.length}건</p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceRebateAgency;
            }, 0)
            .toLocaleString()}
          원
        </p>
        {bugoBrand?.bugoAgency?.shareRebateWithWorker && (
          <>
            <p>
              {filteredShopOrder
                .reduce((accu, prev) => {
                  return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
                }, 0)
                .toLocaleString()}
              원
            </p>
            <p>
              {filteredShopOrder
                .reduce((accu, prev) => {
                  return (
                    accu +
                    prev.orderDetail.shopItemEmbed.priceRebateAgency +
                    (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0)
                  );
                }, 0)
                .toLocaleString()}
              원
            </p>
          </>
        )}
      </div>
    </div>
  );
};
