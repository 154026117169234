//* 주문의 bugoDetail을 변경하기 위한 모달

import { FC, useCallback } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import PhraseInfoInputs from '@service/store/components/PhraseInfoInputs';
import { updateShopOrder } from '@shared/api/shopOrder/shopOrder.controller';
import { shopOrderListForSellerByShopUrl } from '@shared/api/shopOrder/shopOrder.hook';
import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

interface Props {
  openId: string;
  order: ShopOrderForSeller;
}

export const OrderBugoDetailEditModal: FC<Props> = function OrderBugoDetailEditModal({
  openId,
  order,
}: Props) {
  const { sellerRole } = useAuth();
  const { shopId } = useParams();

  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const onCloseModal = useCallback(() => {
    setOpen(false);
    history.back();
  }, [setOpen]);

  const { register, control, handleSubmit } = useForm<{
    phraseSender: string;
    phraseCondolence: string;
  }>({
    defaultValues: {
      phraseSender: order.bugoDetail.senderPhrase,
      phraseCondolence: order.bugoDetail.condolencePhrase,
    },
  });
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<{
    phraseSender: string;
    phraseCondolence: string;
  }> = useCallback(
    async (data) => {
      const updateOrder = {
        _id: order._id,
        bugoDetail: {
          senderPhrase: data.phraseSender,
          condolencePhrase: data.phraseCondolence,
        },
      };
      try {
        await updateShopOrder(updateOrder, sellerRole());
        queryClient.invalidateQueries(
          shopOrderListForSellerByShopUrl(`${shopId}`, sellerRole()),
        );
        notificateSuccess(notifiacationInstance, '수정 성공');
        onCloseModal();
      } catch (err: any) {
        console.error(err);
        notificateError(notifiacationInstance, '수정 실패');
      }
    },
    [notifiacationInstance, onCloseModal, order._id, queryClient, sellerRole, shopId],
  );
  return (
    <Modal openId={openId}>
      <div className="space-y-4">
        <PhraseInfoInputs
          register={register}
          control={control}
          names={{
            phraseSender: 'phraseSender',
            phraseCondolence: 'phraseCondolence',
          }}
        />
        <Button
          className="button-rectangle h-10 theme-text-4 theme-bg-main"
          onClick={handleSubmit(onSubmit)}
        >
          수정
        </Button>
      </div>
    </Modal>
  );
};
