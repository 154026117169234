import React, { InputHTMLAttributes } from 'react';

import { useRecoilState } from 'recoil';

import { CheckBox } from '@shared/components/CheckBox';
import { checkBoxStatusAtomFamily } from '@shared/containers/StatefulCheckbox/state/CheckBox.atom';

interface CheckBoxProps extends InputHTMLAttributes<HTMLInputElement> {
  atomName: string;
}

// TODO: CheckBox 컴포넌트와 맞지 않음.
const StatefulCheckBox: React.FC<CheckBoxProps> = ({
  atomName,
  className = '',
  onClick,
  ...props
}) => {
  const [checked, setChecked] = useRecoilState(checkBoxStatusAtomFamily(atomName));

  const onChangeHandler = (checked: boolean) => {
    setChecked(checked);
  };

  //TODO: tailwind config color 적용해야함
  return (
    <div>
      <CheckBox
        checked={checked}
        onClick={onClick}
        onCheckedChange={onChangeHandler}
        className={className}
        {...props}
      />
    </div>
  );
};

export { StatefulCheckBox };
