/** custom theme atom: 유저가 front페이지의 적용할 custom theme */
import { atom } from 'recoil';

import { UserCustomTheme } from '@shared/types';

/** custom theme atom: 유저가 front페이지의 적용할 custom theme */
export const customThemeAtom = atom<UserCustomTheme>({
  key: 'customThemeAtom',
  default: UserCustomTheme.Default,
});
