import { DeliveryState, PaymentState } from '@shared/types';

export const deliveryStateToString = function deliveryStateToString(deliveryState: DeliveryState) {
  switch (deliveryState) {
    case DeliveryState.BEFORE_PREPARING:
      return '결제완료';
    case DeliveryState.PREPARING:
      return '상품준비중';
    case DeliveryState.DELIVERYING:
      return '배송중';
    case DeliveryState.DELIVERED:
      return '배송완료';
  }
};

export const paymentStateToString = (paymentState: PaymentState) => {
  switch (paymentState) {
    case PaymentState.CANCELED:
      return '취소완료';
    case PaymentState.CONFIRMED:
      return '구매확정';
    case PaymentState.NOT_PAID:
      return '결제대기';
    case PaymentState.PAID:
      return '결제완료';
    case PaymentState.WAITING:
      return '입금 대기중';
    case PaymentState.CANCEL_REQUESTED:
      return '취소 요청됨';
  }
};

export const deliveryPaymentStateToString = (paymentState: PaymentState, deliveryState: DeliveryState) => {
  switch (paymentState) {
    case PaymentState.CANCELED:
      return '취소완료';
    case PaymentState.CONFIRMED:
      return '구매확정';
    case PaymentState.NOT_PAID:
      return '결제대기';
    case PaymentState.CANCEL_REQUESTED:
      return '취소 요청됨';
    case PaymentState.PAID:
      return deliveryStateToString(deliveryState);
    case PaymentState.WAITING:
      return '입금 대기중';
  }
};
