import { FC, useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { shopOrderForStoreStateSelectorFamily } from '@shared/api/shopOrder/shopOrder.selector';
import { Button } from '@shared/components/Button';
import RadioInput from '@shared/components/RadioInput';
import ResizableBox from '@shared/components/ResizableBox';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatDate } from '@shared/utils/formatDate';
import { formatPrice } from '@shared/utils/formatPrice';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

const DURATION = 0.3;

enum FormInputsEnum {
  CancelReason = 'cancelReason',
  ExtraReason = 'extraReason',
}

interface IFormInputs {
  [FormInputsEnum.CancelReason]: string;
  [FormInputsEnum.ExtraReason]: string;
}

const OrderCancleRequestPage: FC = () => {
  useStoreTitleHook('취소 신청');

  const { orderId } = useParams();
  const [isExtraSelected, setIsExtraSelected] = useState<boolean>(false);
  const { register, handleSubmit, control } = useForm<IFormInputs>();

  const cancelReasonValue = useWatch({ control, name: FormInputsEnum.CancelReason });

  const ShopOrderForStoreState = useRecoilValue(
    shopOrderForStoreStateSelectorFamily(orderId ?? 'undefined'),
  );
  const data = ShopOrderForStoreState.data;

  // 취소사유로 "기타"가 선택되면 TextField를 생성.
  useEffect(() => {
    if (cancelReasonValue === '기타') setIsExtraSelected(true);
    else setIsExtraSelected(false);
  }, [cancelReasonValue]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    // console.log(data);
  };

  const requestCancel = () => {
    // 취소 신청하기
  };

  return (
    <div className="space-y-3 px-4 py-3 text-sm">
      {ShopOrderForStoreState.status === 'success' && data && (
        <>
          {/* 상품 정보 */}
          <div className="flex justify-between border-b p-2 pt-0 theme-border-1">
            {/* 사진 / 도착 예정일 / 개수 */}
            <div className="flex">
              {/* 상품 사진 */}
              <img
                src={fileBucketUrl(
                  data.orderDetail.shopItem.shopItemInfo.image.url,
                  'w400',
                )}
                alt="Flower Image"
                className="aspect-square w-20 object-cover object-center"
              />
              <div className="pl-2">
                {/* 상품명 */}
                <h4 className="font-bold">{data.orderDetail.shopItemEmbed.name}</h4>
                {/* 도착 예정일 */}
                <div className="pt-1 text-xs theme-text-main">
                  {formatDate(data.deliveryDetail.estimatedDeliveredAt, {
                    dateSeparater: '/',
                    contains: {
                      year: false,
                      day: true,
                    },
                  })}{' '}
                  도착 보장
                </div>
                {/* 상품 개수 */}
                <div className="pt-1 pl-1 text-xs font-bold theme-text-8">
                  {data.orderDetail.quantity} 개
                </div>
              </div>
            </div>
            {/* 가격 정보 */}
            <div className="mt-auto text-right">
              {/* 원래 가격 */}
              <div className="text-sm leading-4 line-through theme-text-8">
                {formatPrice(data.orderDetail.shopItemEmbed.priceRetailShow)}원
              </div>
              {/* 할인 적용 가격 */}
              <div className="flex items-end">
                <h3 className="text-xl font-bold leading-6">
                  {formatPrice(data.orderDetail.shopItemEmbed.priceRetail)}
                </h3>
                <span className="text-sm leading-6">원</span>
              </div>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
            {/* 취소사유선택 */}
            <div>
              <h3 className="pl-2 pb-3 font-bold">취소사유선택</h3>
              <div className="border p-2 text-xs theme-bg-1 theme-border-1">
                {/* TODO: react-hook-form을 radio input에 적용할 때 버그가 생길 수 있음 다시 체크해야 함. */}
                <RadioInput
                  label="색상/사이즈/주문정보 변경"
                  value="색상/사이즈/주문정보 변경"
                  frameClassName="border-t theme-border-1 first:border-none"
                  {...register(FormInputsEnum.CancelReason, { required: true })}
                />
                <RadioInput
                  label="다른 상품 잘못 주문"
                  value="다른 상품 잘못 주문"
                  frameClassName="border-t theme-border-1 first:border-none"
                  {...register(FormInputsEnum.CancelReason, { required: true })}
                />
                <RadioInput
                  label="구매의사 없어짐"
                  value="구매의사 없어짐"
                  frameClassName="border-t theme-border-1 first:border-none"
                  {...register(FormInputsEnum.CancelReason, { required: true })}
                />
                <RadioInput
                  label="기타"
                  value="기타"
                  frameClassName="border-t theme-border-1 first:border-none"
                  {...register(FormInputsEnum.CancelReason, { required: true })}
                />
                <ResizableBox duration={DURATION}>
                  {isExtraSelected && (
                    <textarea
                      className="min-h-16 w-full border px-3 py-2 theme-text-1 theme-bg-1 theme-border-1 placeholder:theme-text-6"
                      {...(register(FormInputsEnum.ExtraReason),
                      { required: isExtraSelected ? true : false })}
                    />
                  )}
                </ResizableBox>
              </div>
            </div>
            {/* 환불예상금액 */}
            <div>
              <h3 className="pl-2 pb-3 font-bold">환불예상금액</h3>
              <div className="space-y-3 border px-2 py-3 theme-bg-1 theme-border-1">
                {/* 결제금액 */}
                <div className="flex items-center justify-between">
                  <h6 className="theme-text-8">결제금액</h6>
                  <span className="text-right">
                    {formatPrice(data.paymentDetail.paymentByCash)}원
                  </span>
                </div>
                {/* 포인트 결제금액 */}
                <div className="flex items-center justify-between">
                  <h6 className="theme-text-8">포인트 결제금액</h6>
                  <span className="text-right text-myTeal">
                    {formatPrice(data.paymentDetail.paymentByPoint)}원
                  </span>
                </div>
                {/* 환불예상금액 */}
                <div className="flex items-center justify-between">
                  <h6 className="theme-text-8">환불예상금액</h6>
                  <p className="text-right">
                    <span className="text-myTeal">
                      {formatPrice(data.paymentDetail.paymentByPoint)}원
                    </span>{' '}
                    + {formatPrice(data.paymentDetail.paymentByCash)}원
                  </p>
                </div>
              </div>
            </div>
            {/* 취소신청버튼 */}
            <div className="px-2 pt-3 pb-10">
              <Button
                onClick={requestCancel}
                className="button-rectangle text-white theme-bg-main"
              >
                취소신청하기
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default OrderCancleRequestPage;
