import { FC } from 'react';

import { UserBugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { User } from '@shared/api/user/user.interface';
import Modal from '@shared/components/Modal';

import { BugoBrandAgencyWorkerForm } from '../containers/AgencyWorkerUpsertForm/AgencyWorkerUpsertForm';

//*상조 관리자가 상조 팀장을 수정 및 생성할때 사용하는 모달
interface Props {
  openId: string;
  bugoBrand: UserBugoBrand;
  agencyWorkerUser?: User;
}

export const AgencyWorkerUpsertModal: FC<Props> = function AgencyWorkerUpsertModal({
  openId,
  bugoBrand,
  agencyWorkerUser,
}: Props) {
  return (
    <Modal openId={openId}>
      <BugoBrandAgencyWorkerForm
        bugoBrand={bugoBrand}
        agencyWorkerUser={agencyWorkerUser}
        editMode={agencyWorkerUser ? true : false}
      />
    </Modal>
  );
};
