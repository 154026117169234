import { FC, HTMLAttributes, useEffect } from 'react';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { BottomPopup } from '@shared/components/BottomPopup';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { FeventDate } from '@shared/types';
import { formatDate } from '@shared/utils/formatDate';
import { UseFormSetValue } from 'react-hook-form';
import { useId } from 'react-id-generator';

import TimePickerModal from './TimePickerModal';
import { feventTimeAtomFamily } from './time.atom';

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: string;
  fDate: FeventDate;
  setValue: UseFormSetValue<any>;
  pickerId: string;
}

export const FeventTimePicker: FC<Props> = ({
  name,
  fDate,
  setValue,
  placeholder,
  pickerId,
  className = '',
  ...props
}: Props) => {
  const { TimePlaceholder, Time, X } = Icon();

  //* fDate가 있고, timeDefined true일때 초기값을 세팅함(editPage 전용), 없다면 null
  //* 이 time이 feventTimePikcer의 시간 저장 값
  //! DatePicker에서 date를 날려도 이녀석은 저장하고 있음
  const [time, setTime] = useRecoilState(feventTimeAtomFamily(pickerId));

  useEffect(() => {
    if (fDate && fDate.timeDefined && fDate.date) {
      setTime(fDate.date);
    }
  }, [fDate, setTime]);

  const [id] = useId(1, 'timepicker');

  const openId = `timepicker-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const onClear = () => {
    setTime(null);
    setValue(name, {
      date: fDate.date,
      timeDefined: false,
    });
  };

  //* timePickerModal time 선택시 함수
  const onTimeChange = (timeDate: Date) => {
    if (!fDate.date) return;
    const tempDate = new Date(fDate.date);
    tempDate.setHours(timeDate.getHours());
    tempDate.setMinutes(timeDate.getMinutes());
    setTime(tempDate);
    setValue(name, {
      date: tempDate,
      timeDefined: true,
    });
  };

  return (
    <>
      <div
        className={`date-picker flex cursor-pointer justify-between theme-bg-1 theme-border-1 ${className}`}
        onClick={() => {
          //* date가 없다면 변경
          if (fDate.date) {
            setOpen(true);
          } else {
            alert(`${placeholder?.split('시간')[0]}일을 먼저 선택해주세요`);
          }
        }}
        {...props}
      >
        <div className="flex flex-1 items-center">
          {time ? (
            <Time className="stroke-none" />
          ) : (
            <TimePlaceholder className="stroke-none" />
          )}
          <p className={`${!time ? 'theme-text-6' : 'theme-text-1'}`}>
            {time
              ? formatDate(time, {
                  timeSeparater: ' : ',
                  contains: {
                    year: false,
                    month: false,
                    date: false,
                    amPm: true,
                    hours: true,
                    minutes: true,
                  },
                })
              : placeholder}
          </p>
        </div>
        {time && (
          <X
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
            className="hidden sm:block"
          />
        )}
      </div>
      <BottomPopup openId={openId}>
        <div className="w-screen bg-transparent">
          <TimePickerModal
            time={time}
            onTimeChange={onTimeChange}
            openId={openId}
            placeholder={placeholder}
          />
        </div>
      </BottomPopup>
    </>
  );
};
