import { FC, useMemo } from 'react';

import { ShopOrderForWorker } from '@shared/api/shopOrder/shopOrder.interface';
import { getRebateDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

import { AgencyWorkerAccountDetailModalButton } from '../AgencyWorkerAccountDetail/AgencyWorkerAccountDetailModalButton';

//* 이번달 및 다음달 정산 받아야하는 내용 표시

interface Props {
  shopOrderList: ShopOrderForWorker[];
}

type AccountData = {
  id: string;
  month: number;
  rebateDate: Date;
  rebateCount: number;
  rebateWorkerPrice: number;
  shopOrderList: ShopOrderForWorker[];
};

const columns: TableColumnsType<AccountData> = [
  {
    title: '기간',
    dataIndex: 'month',
    key: 'month',
    align: 'center',
    render: (month: number) => {
      const now = new Date();
      return (
        <p
          className={`break-keep  py-1 text-base font-medium ${
            now.getTime() > getRebateDate(month).getTime() ? 'theme-text-warn-03' : ''
          }`}
        >
          {month}월
        </p>
      );
    },
  },
  {
    title: '정산일',
    dataIndex: 'rebateDate',
    key: 'rebateDate',
    align: 'center',
    render: (rebateDate: Date) => {
      const now = new Date();
      if (rebateDate.getTime() < now.getTime()) {
        return (
          <p className="break-keep  py-1 text-base font-medium theme-text-warn-03">
            {formatDate(rebateDate, {
              contains: {
                year: false,
              },
            })}{' '}
            (미지급)
          </p>
        );
      } else {
        return (
          <p className="break-keep py-1 text-base font-medium">
            {formatDate(rebateDate, {
              contains: {
                year: false,
              },
            })}
          </p>
        );
      }
    },
  },
  {
    title: '개수',
    dataIndex: 'rebateCount',
    key: 'rebateCount',
    align: 'center',
    render: (accountCount: number, record) => {
      const now = new Date();
      return (
        <p
          className={`py-1 text-base font-medium ${
            now.getTime() > getRebateDate(record.month).getTime()
              ? 'theme-text-warn-03'
              : ''
          }`}
        >
          {accountCount.toLocaleString()}개
        </p>
      );
    },
  },
  {
    title: '정산금',
    dataIndex: 'rebateWorkerPrice',
    key: 'rebateWorkerPrice',
    align: 'center',
    render: (rebateWorkerPrice: number, record) => {
      const now = new Date();
      return (
        <p
          className={`py-1 text-base font-medium ${
            now.getTime() > getRebateDate(record.month).getTime()
              ? 'theme-text-warn-03'
              : ''
          }`}
        >
          {rebateWorkerPrice.toLocaleString()}원
        </p>
      );
    },
  },

  {
    title: '상세',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      return (
        <div className="center-box">
          <AgencyWorkerAccountDetailModalButton shopOrderList={record.shopOrderList} />
        </div>
      );
    },
  },
];

export const NeedAccountTable: FC<Props> = function NeedAccountTable({
  shopOrderList: needAccountOrderList,
}: Props) {
  // 정산 필요내역들

  const monthlyNeedAccountOrders: { [month: number]: ShopOrderForWorker[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyNeedAccountOrders[i] = [];
  }

  needAccountOrderList.map((order) => {
    if (order.paymentDetail.requestedAt) {
      const month = order.paymentDetail.requestedAt.getMonth() + 1;
      monthlyNeedAccountOrders[month]?.push(order);
    }
  });

  const accountOrderList = _.map(monthlyNeedAccountOrders, (orderList, index) => {
    if (orderList.length > 0) {
      return {
        month: Number(index),
        orderList: orderList,
      };
    } else return null;
  }).filter((item) => {
    return item;
  }) as { month: number; orderList: ShopOrderForWorker[] }[];

  const tableData: AccountData[] = useMemo(() => {
    return _.map(accountOrderList, (orderObj, index) => {
      return {
        id: String(index),
        month: orderObj.month,
        rebateDate: getRebateDate(orderObj.month),
        rebateCount: orderObj.orderList?.length ?? 0,
        rebateWorkerPrice:
          orderObj.orderList?.reduce((accu, prev) => {
            return accu + (prev.orderDetail.shopItemEmbed.priceRebateWorker ?? 0);
          }, 0) ?? 0,
        shopOrderList: orderObj.orderList,
      };
    });
  }, [accountOrderList]);

  const presentMonthAccount = useMemo(() => {
    return (
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          hideOnSinglePage: true,
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    );
  }, [tableData]);

  return (
    <div className="space-y-4">
      <p className="text-base font-medium">정산예정내역</p>
      {presentMonthAccount}
    </div>
  );
};
