import { selector, selectorFamily } from 'recoil';

import {
  bugoDataAtomFamily,
  bugoForStoreDataAtom,
  bugoForStoreStatusAtom,
  bugoStatusAtomFamily,
} from '@shared/api/bugo/bugo.atom';
import { BugoForStoreState, BugoState } from '@shared/api/bugo/bugo.interface';

import {
  bugoByMemberIdDataAtomFamily,
  bugoByMemberIdStatusAtomFamily,
} from './bugo.atom';

export const bugoStateSelectorFamily = selectorFamily<BugoState, string>({
  key: 'bugoStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(bugoStatusAtomFamily(id)),
        data: get(bugoDataAtomFamily(id)),
      };
    },
});

export const bugoForStoreSelector = selector<BugoForStoreState>({
  key: 'bugoForStoreSelector',
  get: ({ get }) => {
    return {
      status: get(bugoForStoreStatusAtom),
      data: get(bugoForStoreDataAtom),
    };
  },
});

export const bugoByMemberIdStateSelectorFamily = selectorFamily<BugoState, string>({
  key: 'bugoByMemberIdStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(bugoByMemberIdStatusAtomFamily(id)),
        data: get(bugoByMemberIdDataAtomFamily(id)),
      };
    },
});
