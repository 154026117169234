import { FC } from 'react';

import { useCssVariables } from '@shared/hooks/useCssVariables';

interface IProps {
  width?: '5rem' | '4rem' | '3rem' | '2rem';
}

const ClockLoader: FC<IProps> = ({ width = '3rem' }: IProps) => {
  useCssVariables({
    property: '--custom-clock-width',
    value: width,
  });

  return <div className={`clock-loader`}></div>;
};

export default ClockLoader;
