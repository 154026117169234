import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Button } from '@shared/components/Button';
import { phoneCertification } from '@shared/plugIn/imp/imp.certification';
import { ciAuthUidAtom } from '@shared/state/atom/ciAuth.atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';

interface Props {
  redirectUrl: string;
}
export const CiButton: FC<Props> = function CiButton({ redirectUrl }: Props) {
  const setImpUid = useSetRecoilState(ciAuthUidAtom);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);
  return (
    <Button
      className="button-rectangle filled-myTeal"
      onClick={() => {
        phoneCertification(redirectUrl, setImpUid, notifiacationInstance);
      }}
    >
      휴대폰 본인인증
    </Button>
  );
};
