import { FC, useEffect, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { BottomPopup } from '@shared/components/BottomPopup';
import { TextField } from '@shared/components/TextField';
import { Icon } from '@shared/components/icons';
import { makeOptionList } from '@shared/containers/Select/Select';
import { IAutoCompleteProps } from '@shared/interfaces/autoComplete.interface';
import { ISelectOption } from '@shared/interfaces/select.interface';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { Controller, useWatch } from 'react-hook-form';
import { useId } from 'react-id-generator';

// eslint-disable-next-line import/order

const isTextFieldOption = {
  value: String(Date.now()),
  label: '직접입력',
};

interface RoomInfoAutoCompleteProps extends IAutoCompleteProps {
  directInputName?: string;
  roomInfoEmebedName?: string;
}

const RoomInfoAutoComplete: FC<RoomInfoAutoCompleteProps> = ({
  placeholder,
  optionList,
  control,
  name,
  directInputName,
  roomInfoEmebedName,
  className = '',
  defaultValue = '',
}: RoomInfoAutoCompleteProps) => {
  const { ChevronDown } = Icon();
  const [id] = useId(1, 'mobileSelect');
  const openId = `mobileAutoComplete-${id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));
  const options = [isTextFieldOption, ...makeOptionList(optionList)];
  const roomInfo = useWatch({ control, name: 'roomInfo' });
  const [isTextField, setIsTextField] = useState<boolean>(true);
  useEffect(() => {
    if (roomInfoEmebedName && !roomInfo) {
      setIsTextField(true);
    }
  }, [roomInfo, roomInfoEmebedName]);

  const onConfirm = (option: ISelectOption, onChange: (...event: any[]) => void) => {
    if (option.value === isTextFieldOption.value) {
      onChange('');
      setIsTextField(true);
    } else {
      setIsTextField(false);
      // set value in react hook form
      onChange(option.value);
    }
    // close modal
    setOpen(false);
  };

  return (
    <>
      {isTextField && (
        <Controller
          control={control}
          defaultValue={defaultValue}
          name={directInputName ? directInputName : name}
          render={(
            { field: { onChange, value, onBlur, ref } }, // value: string;
          ) => (
            <div className={`${className}`}>
              <TextField
                onChange={(event) => onChange(event.currentTarget.value)}
                value={value}
                ref={ref}
                onBlur={onBlur}
                placeholder={placeholder}
                className={`${className} w-full pr-10`}
                required={true}
              >
                <button
                  type="button"
                  onClick={() => setOpen(true)}
                  className="absolute top-0 bottom-0 right-0 my-auto pr-1"
                >
                  <ChevronDown />
                </button>
              </TextField>
            </div>
          )}
        />
      )}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={(
          { field: { onChange, value, onBlur, ref } }, // value: string;
        ) => (
          <>
            {!isTextField && (
              <div
                className={`${className} select relative flex cursor-pointer items-center justify-between border-warn pr-1 text-sm font-bold theme-bg-1`}
                onClick={() => {
                  setOpen(true);
                }}
                ref={ref}
                onBlur={onBlur}
              >
                {value === '' ? (
                  <p className="theme-text-6">{placeholder}</p>
                ) : (
                  <p>
                    {options.find((option) => option.value === value)?.label ?? value}
                  </p>
                )}
                <div>
                  <ChevronDown />
                </div>
              </div>
            )}

            <BottomPopup openId={openId}>
              <div className="w-screen rounded-t-2xl p-6 theme-text-1 theme-bg-1">
                {/* Title */}
                <h4 className="text-center text-sm font-bold">{placeholder}</h4>
                {/* Item List */}
                <ul className="max-h-64 overflow-y-auto pt-3 pb-4">
                  {options.map((option) => (
                    <li
                      key={`${option.value}-${option.label}`}
                      onClick={() => onConfirm(option, onChange)}
                      className={`cursor-pointer border-b py-3 text-center leading-5 theme-border-1 ${
                        option.value === value ? 'text-white theme-bg-main' : ''
                      }`}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              </div>
            </BottomPopup>
          </>
        )}
      />
    </>
  );
};

export default RoomInfoAutoComplete;
