import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { Button } from '@shared/components/Button';
import ResizableBox from '@shared/components/ResizableBox';
import { Icon } from '@shared/components/icons';
import { notificateSuccess } from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { unsecuredCopyToClipboard } from '@shared/utils/clipboard.util';

const DURATION = 0.3;

interface Props {
  cashAccount?: CashAccount;
  forMember?: boolean;
  cashAccountEditOpenId?: string;
}

export const MemberMoney: FC<Props> = function MemberMoney({
  cashAccount,
  forMember,
  cashAccountEditOpenId,
}: Props) {
  const { ChevronDown, ChevronUp, MoneyBag } = Icon();
  const [fold, setFold] = useState(true);
  const setCashAccountEditOpen = useSetRecoilState(
    simpleOpenAtomFamily(cashAccountEditOpenId),
  );
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const cashAccountContent = useMemo(() => {
    if (cashAccount && cashAccount.isVerified) {
      return (
        <div className="flex flex-col gap-1 text-14">
          <div className="flex gap-1">
            <p>예금주</p>
            <p>:</p>
            <p>{cashAccount.name}</p>
          </div>
          <div className="flex gap-3">
            <p>{cashAccount.bank}</p>
            <p>|</p>
            <p>{cashAccount.account}</p>
            <p
              className="cursor-pointer font-bold underline theme-text-main"
              onClick={async (): Promise<void> => {
                navigator.clipboard
                  .writeText(`${cashAccount.bank} ${cashAccount.account}`)
                  .then(() => {
                    notificateSuccess(notifiacationInstance, '계좌복사 완료');
                  })
                  .catch(() => {
                    unsecuredCopyToClipboard(
                      `${cashAccount.bank} ${cashAccount.account}`,
                      '계좌복사 완료',
                      notifiacationInstance,
                    );
                  });
              }}
            >
              계좌복사
            </p>
          </div>
        </div>
      );
    } else {
      return <div>인증된 계좌정보가 존재하지 않습니다.</div>;
    }
  }, [cashAccount, notifiacationInstance]);

  const memberCashAccountContent = useMemo(() => {
    if (forMember) {
      return (
        <Button
          className="button-no-border p-2 theme-text-3 theme-bg-main"
          onClick={() => {
            setCashAccountEditOpen(true);
          }}
        >
          부의금정보수정
        </Button>
      );
    }
  }, [forMember, setCashAccountEditOpen]);

  return (
    <div className="bg-white pl-2">
      <div
        className={`flex items-center justify-between py-2 ${
          fold ? 'cursor-pointer' : ''
        }`}
        onClick={() => {
          if (fold) {
            setFold(!fold);
          }
        }}
      >
        <div className="center-box">
          <MoneyBag className="wh-10" />
          <p className="font-bold text-gray-500">부의금 안내</p>
        </div>
        <div
          className="cursor-pointer"
          onClick={() => {
            setFold(!fold);
          }}
        >
          {fold ? <ChevronDown /> : <ChevronUp />}
        </div>
      </div>
      <ResizableBox duration={DURATION}>
        {!fold && (
          <div className="space-y-2 px-4 pb-3">
            {cashAccountContent}
            <div className="center-box">{memberCashAccountContent}</div>
          </div>
        )}
      </ResizableBox>
    </div>
  );
};
