import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { ShopOrderForSellerAccount } from '@shared/api/shopOrder/shopOrder.interface';
import { getAccountDate } from '@shared/utils/dateUtil';
import { formatDate } from '@shared/utils/formatDate';
import { DatePicker, Table, TableColumnsType } from 'antd';
import locale from 'antd/es/date-picker/locale/ko_KR';
import dayjs from 'dayjs';
import _ from 'lodash';

import { SellerAccountDetaiModalButton } from '../SellerAccountDetailModal/SellerAccountDetailModalButton';
import { sellerAccountBugoBrandFilterAtom } from './accountContent.atom';
import { RebateButton } from './RebateButton';

//* 연간 정산 완료 및 예정 내역
interface Props {
  shopOrderList: ShopOrderForSellerAccount[];
}
type AccountData = {
  id: string;
  month: number;
  accountDate?: Date;
  accountPrice: number;
  accountCount: number;
  rebatePrice: number;
  accountedOrderList: ShopOrderForSellerAccount[];
};

const columns: TableColumnsType<AccountData> = [
  {
    title: '정산기간',
    dataIndex: 'month',
    key: 'month',
    align: 'center',
    render: (month: number) => {
      return <p className="px-6 py-1 text-base font-medium">{month}월</p>;
    },
  },
  {
    title: '정산일',
    dataIndex: 'accountDate',
    key: 'accountDate',
    align: 'center',
    render: (_, record) => {
      if (record.accountDate) {
        return (
          <p className="px-6 py-1 text-base font-medium text-green-600">
            {formatDate(record.accountDate, {
              contains: {
                year: false,
              },
            })}{' '}
            완료
          </p>
        );
      } else {
        const accountDate = getAccountDate(record.month);
        const now = new Date();
        if (now.getTime() > accountDate.getTime()) {
          return (
            <p className="px-6 py-1 text-base font-medium theme-text-warn-03">
              {formatDate(getAccountDate(record.month), {
                contains: {
                  year: false,
                },
              })}{' '}
              미지급
            </p>
          );
        } else {
          return (
            <p className={`px-6 py-1 text-base font-medium`}>
              {formatDate(getAccountDate(record.month), {
                contains: {
                  year: false,
                },
              })}{' '}
              예정
            </p>
          );
        }
      }
    },
  },
  {
    title: '총 판매건수',
    dataIndex: 'accountCount',
    key: 'accountCount',
    align: 'center',
    render: (accountCount: number) => {
      return (
        <p className="px-6 py-1 text-base">
          {accountCount === 0 ? '-' : accountCount.toLocaleString() + '개'}
        </p>
      );
    },
  },
  {
    title: '화환 정산금',
    dataIndex: 'accountPrice',
    key: 'accountPrice',
    align: 'center',
    render: (accountPrice: number) => {
      return (
        <p className="px-6 py-1 text-base font-medium">
          {accountPrice === 0 ? '-' : accountPrice.toLocaleString() + '원'}
        </p>
      );
    },
  },
  {
    title: '상조 정산금',
    dataIndex: 'rebatePrice',
    key: 'rebatePrice',
    align: 'center',
    render: (rebatePrice: number) => {
      return (
        <p className="px-6 py-1 text-base font-medium">
          {rebatePrice === 0 ? '-' : rebatePrice.toLocaleString() + '원'}
        </p>
      );
    },
  },
  {
    title: '합계',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      return (
        <p className="px-6 py-1 text-base font-medium">
          {record.accountPrice + record.rebatePrice === 0
            ? '-'
            : (record.accountPrice + record.rebatePrice).toLocaleString() + '원'}
        </p>
      );
    },
  },
  {
    title: '세부내역보기',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      return (
        <div className="center-box">
          <SellerAccountDetaiModalButton shopOrderList={record.accountedOrderList} />
        </div>
      );
    },
  },
  {
    title: '상조정산',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (_, record) => {
      return <RebateButton shopOrderList={record.accountedOrderList}></RebateButton>;
    },
  },
];

export const AnnualAccountedContent: FC<Props> = function AnnualAccountedContent({
  shopOrderList,
}: Props) {
  //연도 설정

  const now = new Date();
  const [selectYear, setSelectYear] = useState<dayjs.Dayjs | null>(dayjs(now));

  const selectedYearDate = dayjs(selectYear).toDate();
  const selectedYear = selectedYearDate.getFullYear();

  // 1. 월별로 주문을 분류할 객체를 만듭니다.
  const monthlyAccountedOrders: { [month: number]: ShopOrderForSellerAccount[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyAccountedOrders[i] = [];
  }

  const monthlyWillAccountOrders: { [month: number]: ShopOrderForSellerAccount[] } = {};
  for (let i = 1; i <= 12; i++) {
    monthlyWillAccountOrders[i] = [];
  }

  //shopOrderList를 연간데이터화
  const shopOrderYearList = shopOrderList.filter((order) => {
    const accountedDate = order.paymentDetail.accountedDate;
    if (accountedDate) {
      // rebatedDate가 2023년 2월 1일 이상인지, 2024년 1월 31일 이하인지 확인
      if (
        accountedDate >= new Date(`${selectedYear}-02-01`) &&
        accountedDate <= new Date(`${selectedYear + 1}-01-31`)
      ) {
        return true;
      }
    }
    return false;
  });

  // 2. order 배열을 순회하면서, 각 주문의 월을 결정합니다.
  shopOrderYearList.map((order) => {
    if (order.bugoBrand === '63fcadf6db275b584f7ee9c8') {
      if (order.paymentDetail.isAccounted && order.paymentDetail.accountedDate) {
        const month = order.paymentDetail.accountedDate.getMonth();
        if (month === 0) {
          monthlyAccountedOrders[12]?.push(order);
        } else {
          monthlyAccountedOrders[month]?.push(order);
        }
      }
    } else {
      if (order.paymentDetail.isAccounted && order.paymentDetail.requestedAt) {
        const month = order.paymentDetail.requestedAt.getMonth() + 1;
        monthlyAccountedOrders[month]?.push(order);
      }
    }
  });

  const resultArray = _.map(monthlyAccountedOrders, (orderList) => {
    return {
      accountedOrderList: orderList,
    };
  });

  // BugoBrand Filtering
  const selectedBugoBrandsTypes = useRecoilValue(sellerAccountBugoBrandFilterAtom);

  const filteredResultArray = resultArray.map((item) => {
    return {
      accountedOrderList: item.accountedOrderList.filter((order) => {
        return selectedBugoBrandsTypes[
          order.bugo?.bugoBrand?.bugoAgency?.nickName ?? 'etc'
        ];
      }),
    };
  });

  const tableData: AccountData[] = useMemo(() => {
    const now = new Date();
    const annualData = _.map(filteredResultArray, (item, index) => {
      return {
        id: `accountTable-${index}`,
        month: index + 1,
        accountDate:
          item.accountedOrderList.length === 0 &&
          now.getTime() > getAccountDate(index + 1).getTime()
            ? getAccountDate(index + 1)
            : item.accountedOrderList[0]?.paymentDetail.accountedDate,
        accountCount: item.accountedOrderList.length,
        accountPrice:
          item.accountedOrderList.reduce((accu, prev) => {
            return accu + prev.orderDetail.shopItemEmbed.priceWhole;
          }, 0) ?? 0,
        rebatePrice: item.accountedOrderList?.reduce((accu, prev) => {
          return accu + prev.orderDetail.shopItemEmbed.priceRebate;
        }, 0),
        accountedOrderList: item.accountedOrderList,
      };
    });
    return annualData;
  }, [filteredResultArray]);

  const filteredShopOrder = shopOrderList.filter((order) => {
    return selectedBugoBrandsTypes[order.bugo?.bugoBrand?.bugoAgency?.nickName ?? 'etc'];
  });

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between pr-4">
        <p className="pl-4 text-base font-medium">
          {selectYear?.format('YYYY년')} 연간 정산완료 내역
        </p>
        <DatePicker
          picker="year"
          locale={locale}
          size="large"
          defaultValue={selectYear ?? undefined}
          onChange={(date) => {
            setSelectYear(date);
          }}
        />
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          defaultPageSize: 13,
          hideOnSinglePage: true,
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
      <div className="button-rectangle flex cursor-default items-center justify-evenly gap-4 text-base theme-bg-9">
        <p>합산</p>
        <p>{filteredShopOrder.length}건</p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceWhole;
            }, 0)
            .toLocaleString()}
          원
        </p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return accu + prev.orderDetail.shopItemEmbed.priceRebate;
            }, 0)
            .toLocaleString()}
          원
        </p>
        <p>
          {filteredShopOrder
            .reduce((accu, prev) => {
              return (
                accu +
                prev.orderDetail.shopItemEmbed.priceRebate +
                prev.orderDetail.shopItemEmbed.priceWhole
              );
            }, 0)
            .toLocaleString()}
          원
        </p>
      </div>
    </div>
  );
};
