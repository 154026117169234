import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import { DistributionNetwork } from '@shared/api/distributionNetwork/distributionNetwork.interface';
import { Shop } from '@shared/api/shop/shop.interface';
import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';

interface Props {
  distributionNetworkList: DistributionNetwork<BugoBrand, string, Shop>[];
  setEditDistributionNetwork: React.Dispatch<
    React.SetStateAction<DistributionNetwork<BugoBrand, string, Shop> | null>
  >;
  upsertOpenId: string;
}

type tableData = {
  id: string;
  bugoBrand: BugoBrand;
  funeralHomeInfo: string;
  shop: Shop;
  distributionNetwork: DistributionNetwork<BugoBrand, string, Shop>;
};

export const AdminDistributionNetworkTable: FC<Props> =
  function AdminDistributionNetworkTable({
    distributionNetworkList,
    setEditDistributionNetwork,
    upsertOpenId,
  }: Props) {
    const setOpen = useSetRecoilState(simpleOpenAtomFamily(upsertOpenId));
    const columns: TableColumnsType<tableData> = [
      {
        title: '_id',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
      },
      {
        title: '장례식장',
        dataIndex: 'funeralHomeInfo',
        key: 'funeralHomeInfo',
        align: 'center',
      },
      {
        title: '부고브랜드',
        dataIndex: 'bugoBrand',
        key: 'bugoBrand',
        align: 'center',
        render: (bugoBrand: BugoBrand) => {
          return (
            <div className="center-box">
              <p>{bugoBrand.bugoAgency?.corpName}</p>
            </div>
          );
        },
      },
      {
        title: 'Shop',
        dataIndex: 'shop',
        key: 'shop',
        align: 'center',
        render: (shop: Shop) => {
          return (
            <div className="center-box">
              <p>{shop.name}</p>
            </div>
          );
        },
      },
      {
        title: '수정',
        dataIndex: 'id',
        key: 'id-modify',
        align: 'center',
        render: (_, record) => {
          return (
            <div className="center-box">
              <Button
                onClick={() => {
                  setEditDistributionNetwork(record.distributionNetwork);
                  setOpen(true);
                }}
              >
                수정
              </Button>
            </div>
          );
        },
      },
    ];

    const tableData = _.map(distributionNetworkList, (obj) => {
      return {
        id: String(obj._id),
        bugoBrand: obj.bugoBrand,
        funeralHomeInfo: obj.funeralHomeInfo,
        shop: obj.shop,
        distributionNetwork: obj,
      };
    });

    return (
      <div>
        <Table
          className="w-full"
          columns={columns}
          dataSource={_.flatMapDeep([tableData])}
          pagination={{
            hideOnSinglePage: true,
          }}
          rowKey={'id'}
          bordered
          size={'small'}
        ></Table>
      </div>
    );
  };
