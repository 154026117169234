import { FC, useCallback, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoByMemberIdUrl } from '@shared/api/bugo/bugo.hook';
import { verifyCashAccount } from '@shared/api/cashAccount/cashAccount.controller';
import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import BankSelect from '@shared/containers/BankSelect/BankSelect';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { CustomCallback, LowerRole } from '@shared/types';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { Button } from './Button';
import { Icon } from './icons';
import { LoadingModal } from './LoadingModal';
import { TextField } from './TextField';

interface IProps {
  feventId: string;
  defaultCashAccount: CashAccount;
  onVerified: CustomCallback<string, any>;
}

interface ICashAccountForm {
  name: string;
  bank: string;
  cashAccount: string;
  isVerified: boolean;
  cashAccountId: string | undefined;
}

const CashAccountForm: FC<IProps> = ({
  feventId,
  defaultCashAccount,
  onVerified,
}: IProps) => {
  const { memberId } = useParams();
  const { Check } = Icon();
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const queryClient = useQueryClient();
  const { isLoading: verifyingCashAccount, mutateAsync } = useMutation(
    defaultApiMutationOptions,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(bugoByMemberIdUrl(memberId ?? 'undefined'));
      },
    },
  );

  const { register, control, setValue, handleSubmit } = useForm<ICashAccountForm>({
    defaultValues: {
      name: defaultCashAccount?.name ?? '',
      bank: defaultCashAccount?.bank ?? '',
      cashAccount: defaultCashAccount?.account ?? '',
      isVerified: defaultCashAccount?.isVerified ?? false,
      cashAccountId: defaultCashAccount?._id ?? undefined,
    },
  });

  const nameValue = useWatch({ name: 'name', control });
  const bankValue = useWatch({ name: 'bank', control });
  const cashAccountValue = useWatch({ name: 'cashAccount', control });
  const isVerifiedValue = useWatch({ name: 'isVerified', control });
  const isConfirmButtonDisabled = useMemo(() => {
    if (isVerifiedValue) return true;
    if (nameValue && cashAccountValue && bankValue) return false;
    return true;
  }, [bankValue, cashAccountValue, isVerifiedValue, nameValue]);

  const onVerifyCashAccountHandler: SubmitHandler<ICashAccountForm> = useCallback(
    async (data) => {
      const verfiyFunc = async () => {
        const { name, bank, cashAccount, cashAccountId } = data;

        const cashAccountVerify = {
          _id: cashAccountId,
          account: cashAccount,
          bank,
          name,
          fevent: feventId,
        };

        try {
          const { _id, isVerified, message } = await verifyCashAccount(
            cashAccountVerify,
            LowerRole.Public,
          );

          setValue('cashAccountId', _id);
          setValue('isVerified', isVerified);
          if (isVerified) {
            notificateSuccess(notifiacationInstance, '계좌인증완료');
            onVerified(_id);
          } else {
            notificateError(notifiacationInstance, '계좌인증 실패', message, 'top', 4);
          }
        } catch (err) {
          console.error(err);
        }
      };
      await mutateAsync(verfiyFunc());
    },
    [feventId, mutateAsync, notifiacationInstance, onVerified, setValue],
  );

  return (
    <div className="grid grid-cols-2 gap-2">
      {/* 예금주명 */}
      <TextField
        disabled={isVerifiedValue}
        placeholder="예금주명"
        className="w-full"
        {...register('name')}
      />
      {/* 은행 선택 */}
      <div>
        <BankSelect
          control={control}
          name="bank"
          placeholder="은행 선택"
          disabled={isVerifiedValue}
        />
      </div>
      {/* 계좌번호 */}
      <div className="col-span-2 flex">
        <div className="flex-1">
          <TextField
            type="tel"
            placeholder="계좌번호"
            className="w-full"
            disabled={isVerifiedValue}
            {...register('cashAccount')}
          />
        </div>
        <Button
          disabled={isConfirmButtonDisabled}
          onClick={handleSubmit(onVerifyCashAccountHandler)}
          type="button"
          className={`button-no-border h-full w-[92px] ${
            isVerifiedValue || !isConfirmButtonDisabled
              ? 'text-white theme-bg-main'
              : 'theme-text-3 theme-bg-10'
          }`}
        >
          {isVerifiedValue ? (
            <div className="center-box">
              <p>확인완료</p>
              <Check />
            </div>
          ) : (
            '계좌확인'
          )}
        </Button>
      </div>
      <LoadingModal open={verifyingCashAccount} title={'계좌 인증 중'} />
    </div>
  );
};

export default CashAccountForm;
