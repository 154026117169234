import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { EnquiryList } from '@shared/api/enquiry/enquiry.interface';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { mainBgColorGenerator } from '@shared/utils/mainColorGenerator';
import { Table, TableColumnsType } from 'antd';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';

import EnquiryDetailModalOpener from '../EnquiryDetailModal/EnquiryDetailModalOpener';

interface IProps {
  enquiryList: EnquiryList;
  searchValue: string;
}

type EnquiryTableData = {
  id: string;
  user: string;
  phoneNumber: string;
  status: string;
};

const EnquiryListTable: FC<IProps> = ({ enquiryList, searchValue }: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);

  const searchedEnquiryList = useMemo(() => {
    const searcher = new FuzzySearch(enquiryList, ['user.info.name', 'name']);
    return searcher.search(searchValue);
  }, [enquiryList, searchValue]);

  const tableData: EnquiryTableData[] = useMemo(() => {
    return _.map(
      searchedEnquiryList as EnquiryList | null,
      (enquiry): EnquiryTableData => {
        return {
          id: enquiry._id,
          user: enquiry.user.info.name,
          phoneNumber: enquiry.user.info.phoneNumber,
          status: enquiry.status,
        };
      },
    );
  }, [searchedEnquiryList]);

  const columns: TableColumnsType<EnquiryTableData> = [
    {
      width: '30%',
      title: '성함',
      dataIndex: 'user',
      key: 'user',
      align: 'center',
      render: (name) => {
        return <p className="whitespace-pre-line break-keep text-xs">{name}</p>;
      },
    },
    {
      width: '32%',
      title: '연락처',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      render: (phoneNumber) => {
        return <p className="whitespace-pre-line break-keep text-xs">{phoneNumber}</p>;
      },
    },
    {
      width: '20%',
      title: '처리여부',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status) => {
        return <p className="whitespace-pre-line break-keep text-xs">{status}</p>;
      },
    },
    {
      width: '13%',
      key: 'enquiry-detail-modal-button',
      align: 'center',
      render: (value: EnquiryTableData) => {
        return (
          <div className="center-box">
            <EnquiryDetailModalOpener
              enquiry={enquiryList.find((enquiry) => enquiry._id === value.id) ?? null}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{ position: ['bottomCenter'], pageSize: 15 }}
        rowKey={'id'}
        size={'small'}
        onHeaderRow={() => ({
          className: `text-xs font-bold bg-opacity-40 ${mainBgColorGenerator(
            customTheme,
          )}`,
        })}
      />
    </div>
  );
};

export default EnquiryListTable;
