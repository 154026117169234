import { selector, selectorFamily } from 'recoil';

import {
  bugoBrandAnnouncementDataAtomFamily,
  bugoBrandAnnouncementListDataAtom,
  bugoBrandAnnouncementListForWorkerDataAtom,
  bugoBrandAnnouncementListForWorkerStatusAtom,
  bugoBrandAnnouncementListStatusAtom,
  bugoBrandAnnouncementStatusAtomFamily,
} from './bugoBrandAnnouncement.atom';
import {
  BugoBrandAnnouncementListState,
  BugoBrandAnnouncementState,
} from './bugoBrandAnnouncement.interface';

export const bugoBrandAnnouncementStateSelectorFamily = selectorFamily<
  BugoBrandAnnouncementState,
  string
>({
  key: 'bugoBrandAnnouncementStateSelectorFamily',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(bugoBrandAnnouncementStatusAtomFamily(id)),
        data: get(bugoBrandAnnouncementDataAtomFamily(id)),
      };
    },
});

export const bugoBrandAnnouncementListStateSelector =
  selector<BugoBrandAnnouncementListState>({
    key: 'bugoBrandAnnouncementListStateSelector',
    get: ({ get }) => {
      return {
        status: get(bugoBrandAnnouncementListStatusAtom),
        data: get(bugoBrandAnnouncementListDataAtom),
      };
    },
  });

export const bugoBrandAnnouncementListForWorkerStateSelector =
  selector<BugoBrandAnnouncementListState>({
    key: 'bugoBrandAnnouncementListForWorkerStateSelector',
    get: ({ get }) => {
      return {
        status: get(bugoBrandAnnouncementListForWorkerStatusAtom),
        data: get(bugoBrandAnnouncementListForWorkerDataAtom),
      };
    },
  });
