import { FC } from 'react';

import BugoMainLinkList from '@service/bugo/components/BugoMainLinkList';
import { ReactComponent as DecoFlower } from 'src/assets/svg/deco-flower.svg';

import BugoMainAnouncementList from '../BugoMainAnnouncementList/BugoMainAnnouncementList';

//* worker용 main page
export const WorkerMain: FC = function WorkerMain() {
  return (
    <>
      <div className="flex items-center justify-between px-2">
        <div className="noto-serif-kr text-base font-medium leading-7">
          <p>어떠한 말로도 위로할 길 없지만</p>
          <p>삼가 위로의 말씀을 드립니다.</p>
        </div>
        <DecoFlower className="w-12" />
      </div>
      <BugoMainLinkList />
      <BugoMainAnouncementList />
    </>
  );
};
