import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';

import {
  bugoBrandListDataAtom,
  bugoBrandListStatusAtom,
  simpleBugoBrandListDataAtom,
  simpleBugoBrandListStatusAtom,
} from './bugoBrand.atom';
export const bugoBrandListHookUrl = `admin/bugo-brand?${queryString({
  populate: [
    {
      path: 'workers',
    },
    {
      path: 'bugoAgency.headerImage',
    },
    {
      path: 'bugoAgency.footerImage',
    },
    { path: 'bugoAgency.rebate' },
    {
      path: 'bugoAgencyList.headerImage',
    },
    {
      path: 'bugoAgencyList.footerImage',
    },
  ],
})}`;

export const useBugoBrandListHook = () => {
  useRecoilQuery(
    bugoBrandListStatusAtom,
    bugoBrandListDataAtom,
    bugoBrandListHookUrl,
    fetcher,
  );
};

export const simpleBugoBrandListHookUrl = `admin/bugo-brand`;

export const useSimpleBugoBrandListHook = () => {
  useRecoilQuery(
    simpleBugoBrandListStatusAtom,
    simpleBugoBrandListDataAtom,
    simpleBugoBrandListHookUrl,
    fetcher,
  );
};
