//* 팀장유저 수정 및 생성을 위한 form interface

import { UserCustomTheme } from '@shared/types';

export enum BugoBrandAgencyWorkerFormInputsEnum {
  UserName = 'username',
  Password = 'password',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  Theme = 'theme',
  TeamType = 'teamType',
  TeamName = 'teamName',
  Region = 'region',
}

export interface BugoBrandAgencyWorkerFormInputs {
  [BugoBrandAgencyWorkerFormInputsEnum.UserName]?: string;
  [BugoBrandAgencyWorkerFormInputsEnum.Password]?: string;
  [BugoBrandAgencyWorkerFormInputsEnum.Name]: string;
  [BugoBrandAgencyWorkerFormInputsEnum.PhoneNumber]: string;
  [BugoBrandAgencyWorkerFormInputsEnum.Theme]: UserCustomTheme;
  [BugoBrandAgencyWorkerFormInputsEnum.TeamType]: string;
  [BugoBrandAgencyWorkerFormInputsEnum.TeamName]: string;
  [BugoBrandAgencyWorkerFormInputsEnum.Region]: string;
}
