import { FC, useMemo, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { updateShopOrder } from '@shared/api/shopOrder/shopOrder.controller';
import { shopOrderListForSellerByShopUrl } from '@shared/api/shopOrder/shopOrder.hook';
import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { Button } from '@shared/components/Button';
import ConfirmModal from '@shared/components/ConfirmModal';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { DeliveryState, PaymentState } from '@shared/types';
import { formatDate } from '@shared/utils/formatDate';
import { deliveryStateToString } from '@shared/utils/stateUtils';
import { useQueryClient } from 'react-query';

import { DeliveredModal } from './DeliveredModal';
import { OrderCanceldModal } from './ShopOrderCancelModal';

interface Props {
  shopOrder: ShopOrderForSeller;
}

export const DeliveryStatusManageButton: FC<Props> = function DeliveryStatusManageButton({
  shopOrder,
}: Props) {
  const confirmOpenId = `deliveryStatusManageButton-${shopOrder._id}`;
  const deliveredOpenId = `deliveryStatusManageButton-delivered-${shopOrder._id}`;
  const cancelOpenId = `deliveryStatusManageButton-cancel-${shopOrder._id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(confirmOpenId));
  const setDeliveredOpen = useSetRecoilState(simpleOpenAtomFamily(deliveredOpenId));
  const setCancelOpen = useSetRecoilState(simpleOpenAtomFamily(cancelOpenId));
  const [updateStatus, setUpdateState] = useState(DeliveryState.BEFORE_PREPARING);
  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const { sellerRole } = useAuth();
  const queryClient = useQueryClient();

  const onStatusUpdateClick = async (status: DeliveryState) => {
    const updateShopOrder_ = {
      _id: shopOrder._id,
      'deliveryDetail.status': status,
    };
    try {
      await updateShopOrder(updateShopOrder_, sellerRole());
      await queryClient.invalidateQueries(
        shopOrderListForSellerByShopUrl(shopOrder.shop, sellerRole()),
      );
      notificateSuccess(notifiacationInstance, '상태변경에 성공하였습니다.');
    } catch (err: any) {
      notificateError(notifiacationInstance, '실패');
    }
  };

  const buttonContent = useMemo(() => {
    if (shopOrder.deliveryDetail.status === DeliveryState.BEFORE_PREPARING) {
      return (
        <Button
          className="button-sm border-none bg-cyan-600 px-2 text-white hover:bg-cyan-700"
          onClick={() => {
            setUpdateState(DeliveryState.PREPARING);
            setOpen(true);
          }}
        >
          상품 준비 시작
        </Button>
      );
    } else if (shopOrder.deliveryDetail.status === DeliveryState.PREPARING) {
      return (
        <Button
          className="button-sm border-none bg-cyan-600 px-2 text-white hover:bg-cyan-700"
          onClick={async () => {
            setUpdateState(DeliveryState.DELIVERYING);
            setOpen(true);
          }}
        >
          배송 시작
        </Button>
      );
    } else if (shopOrder.deliveryDetail.status === DeliveryState.DELIVERYING) {
      return (
        <Button
          className="button-sm border-none bg-green-500 px-2 text-white hover:bg-green-600"
          onClick={async () => {
            setDeliveredOpen(true);
          }}
        >
          배송 완료
        </Button>
      );
    } else if (shopOrder.deliveryDetail.status === DeliveryState.DELIVERED) {
      return (
        <div className="button-sm center-box filled-gray-50 border border-gray-300 px-2 text-gray-700">
          <div className="text-center">
            <p>
              {formatDate(shopOrder.deliveryDetail.deliveredAt, {
                dateSeparater: '.',
                contains: {
                  year: false,
                  day: true,
                  hours: true,
                  minutes: true,
                },
              })}
            </p>
            <p>배송완료 됨</p>
          </div>
        </div>
      );
    } else return null;
  }, [
    setDeliveredOpen,
    setOpen,
    shopOrder.deliveryDetail.deliveredAt,
    shopOrder.deliveryDetail.status,
  ]);

  const cancelButton = useMemo(() => {
    if (shopOrder.paymentDetail.status === PaymentState.CONFIRMED) {
      return (
        <div className="button-sm center-box filled-gray-50 border border-gray-300 px-2 text-gray-700">
          구매확정 완료됨
        </div>
      );
    } else if (
      [PaymentState.CANCELED, PaymentState.CANCEL_REQUESTED].includes(
        shopOrder.paymentDetail.status,
      )
    ) {
      return (
        <div className="button-sm center-box filled-gray-50 border border-gray-300 px-2 text-gray-700">
          {shopOrder.paymentDetail.status === PaymentState.CANCELED
            ? '취소됨'
            : '취소 요청 중'}
        </div>
      );
    } else {
      return (
        <Button
          className="button-sm border-none bg-warn px-2 text-white hover:bg-warn"
          onClick={async () => {
            setCancelOpen(true);
          }}
        >
          주문 반려(취소)
        </Button>
      );
    }
  }, [setCancelOpen, shopOrder.paymentDetail.status]);

  return (
    <>
      <div className="flex w-full justify-between">
        {cancelButton}
        {buttonContent}
      </div>
      <ConfirmModal
        openId={confirmOpenId}
        title={deliveryStateToString(updateStatus) + '로 변경'}
        buttonTitle={'상태 업데이트'}
        onClick={async () => {
          await onStatusUpdateClick(updateStatus);
        }}
      />
      <DeliveredModal openId={deliveredOpenId} shopOrder={shopOrder}></DeliveredModal>
      <OrderCanceldModal openId={cancelOpenId} shopOrder={shopOrder}></OrderCanceldModal>
    </>
  );
};
