import { FC, useMemo } from 'react';

import { FeventByBugoBrand } from '@shared/api/fevent/fevent.interface';

interface Props {
  feventList: FeventByBugoBrand[];
}

export const AgencyAdminBugoStatusBar: FC<Props> = function AgencyAdminBugoStatusBar({
  feventList,
}: Props) {
  const shopOrderNum = useMemo(() => {
    return feventList.reduce((accu, prev) => {
      return accu + prev.shopOrders.length;
    }, 0);
  }, [feventList]);

  return (
    <div className="flex items-center py-2 font-bold theme-bg-1">
      <div className="flex-1 border-r pb-1">
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">부고</h6>
          <p className="w-full text-center">{feventList.length.toLocaleString()}건</p>
        </div>
      </div>
      <div className="flex-1 border-r pb-1">
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">화환</h6>
          <p className="w-full text-center">{shopOrderNum.toLocaleString()}건</p>
        </div>
      </div>
      <div className="flex-1 pb-1">
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">FPB</h6>
          <p className="w-full text-center">
            {feventList.length === 0 ? 0 : (shopOrderNum / feventList.length).toFixed(2)}
          </p>
        </div>
      </div>
    </div>
  );
};
