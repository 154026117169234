import React, { Children, HTMLAttributes, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { stepperStatusAtomFamily } from '@shared/containers/Stepper/state/Stepper.atom';
import _ from 'lodash';

interface StepperProps extends HTMLAttributes<HTMLDivElement> {
  atomName: string;
  stepNames: string[];
}

const bgColorGenerator = (predicate: boolean) => {
  if (predicate) return 'theme-bg-main';
  return 'bg-gray-300';
};

const textColorGenerator = (predicate: boolean) => {
  if (predicate) return 'theme-text-1';
  return 'text-gray-500';
};

const Stepper: React.FC<StepperProps> = (props) => {
  const { atomName, stepNames, children } = props;

  const stepState = useRecoilValue(stepperStatusAtomFamily(atomName));

  const isValid = useMemo(() => {
    return stepNames.length === Children.count(children);
  }, [stepNames, children]);

  const stepStatusRender = useMemo(() => {
    if (!isValid) return <div>invalid contents, stepNumber</div>;
    return (
      <React.Fragment>
        {_.map(stepNames, (name, idx) => {
          return (
            <div className="grid h-full w-[88px] place-items-center">
              <div
                className={`h-10 w-10 ${bgColorGenerator(
                  stepState >= idx,
                )} z-10 grid place-items-center rounded-full pt-[2px] text-16 text-white`}
              >
                <span>{idx + 1}</span>
              </div>
              <div
                className={`mt-[5px] h-5 w-full text-center text-12 ${textColorGenerator(
                  stepState >= idx,
                )}`}
              >
                <span className="leading-5">{name}</span>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }, [isValid, stepState, stepNames]);

  const stepContentsRender = useMemo(() => {
    if (!isValid) return <div></div>;
    return Children.toArray(children)[stepState];
  }, [stepState, children, isValid]);

  const stepBarsRender = useMemo(() => {
    if (!isValid) return;

    switch (stepNames.length) {
      case 2:
        return (
          <div
            className={`h-[1px] ${bgColorGenerator(
              stepState >= 1,
            )} position: absolute top-5 left-[50px] w-[calc(100%-100px)]`}
          />
        );
      case 3:
        return (
          <React.Fragment>
            <div
              className={`h-[1px] ${bgColorGenerator(
                stepState >= 1,
              )} position: absolute top-5 left-[50px] w-[calc(50%-40px)]`}
            />
            <div
              className={`h-[1px] ${bgColorGenerator(
                stepState >= 2,
              )} position: absolute top-5 left-[50%] w-[calc(50%-40px)]`}
            />
          </React.Fragment>
        );
      default:
        return <></>;
    }
  }, [isValid, stepState, stepNames]);

  return (
    // stepper box
    <React.Fragment>
      <div className="mt-8 pl-4 pr-4">
        <div className="relative flex h-[65px] w-full justify-between px-2">
          {stepStatusRender}
          {stepBarsRender}
        </div>
        {stepContentsRender}
      </div>
    </React.Fragment>
  );
};

export { Stepper };
