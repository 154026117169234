import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import { ShopItemInfoCreate, ShopItemInfoUpdate } from './shopItemInfo.interface';

export const createShopItemInfo = async (
  shopItemInfo: ShopItemInfoCreate,
  role: LowerRole,
) => {
  await api_.post(`${role}/shop-item-info`, shopItemInfo);
};

export const updateShopItemInfo = async (
  shopItemInfo: ShopItemInfoUpdate,
  role: LowerRole,
) => {
  await api_.patch(`${role}/shop-item-info/${shopItemInfo._id}`, shopItemInfo);
};
