import { FC, useState } from 'react';

import { Icon } from '@shared/components/icons';
import { TextField } from '@shared/components/TextField';
import { Tag } from 'antd';

interface Props {
  tags: string[]; // 태그 리스트
  onAdd: (value: string) => void; // 태그 추가 발생 콜백함수
  onClose: (e: any) => void;
}

export const EditableTagGroup: FC<Props> = function EditableTagGruop({
  tags,
  onAdd,
  onClose,
}: Props) {
  const [addMode, setAddMode] = useState(false);
  const { X, PlusSolid } = Icon();

  return (
    <div className="flex flex-wrap gap-y-2.5 gap-x-0">
      {tags.map((tag, index) => {
        return (
          <Tag
            className="edit-tag relative pr-8"
            key={`${tag}-${index}`}
            closable={true}
            closeIcon={<X className="wh-5 absolute top-1/2 right-1.5 -translate-y-1/2" />}
            onClose={() => onClose(tag)}
          >
            {tag}
          </Tag>
        );
      })}
      {addMode ? (
        <TextField
          className="h-10 w-36 border border-gray-300"
          autoFocus
          onBlur={(e: any) => {
            if (e.target.value !== '') {
              if (tags.indexOf(e.target.value) === -1) {
                onAdd(e.target.value);
              }
              setAddMode(false);
            } else {
              setAddMode(false);
            }
          }}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') {
              if (e.target.value !== '') {
                if (tags.indexOf(e.target.value) === -1) {
                  onAdd(e.target.value);
                }
                setAddMode(false);
              } else {
                setAddMode(false);
              }
            }
          }}
        />
      ) : (
        <Tag
          className="edit-tag flex gap-2 border-2 border-dashed "
          onClick={() => {
            setAddMode(true);
          }}
        >
          <PlusSolid className="wh-5" /> 추가
        </Tag>
      )}
    </div>
  );
};
