const fileSelectHandler = async (e: any, setFiles: React.Dispatch<any>) => {
  const _files = e.target.files;
  setFiles(_files);
};

const singleImageSelectHandlerWithPreview = async (
  e: any,
  setFile: React.Dispatch<any>,
  setPreview: React.Dispatch<any>,
) => {
  const _file = e.target.files[0];

  if (!_file.type.startsWith('image/')) {
    alert(
      `파일 "${_file.name}"은(는) 허용되지 않는 파일 형식입니다. 이미지 파일만 업로드하세요.`,
    );
    e.target.value = ''; // input 요소 초기화
    return;
  }
  setFile(_file);
  const _preview = await new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(_file);
      fileReader.onload = (e) => resolve(e?.target?.result);
    } catch (error) {
      reject(error);
    }
  });
  setPreview(_preview);
};

const imageSelectHandlerWithPreview = async (
  e: any,
  setFiles: React.Dispatch<any>,
  setPreviews: React.Dispatch<any>,
) => {
  const _files = e.currentTarget.files;
  setFiles(Array.from(_files));
  const val = await Promise.all(
    [...e.currentTarget.files].map(async (file) => {
      return new Promise<any>((resolve, reject) => {
        try {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = (e) => resolve({ url: e?.target?.result, file } as any);
        } catch (error) {
          reject(error);
        }
      });
    }),
  );
  setPreviews(val);
};

const videoSelectHandlerWithPreview = async (
  e: any,
  setFiles: React.Dispatch<any>,
  setPreviews: React.Dispatch<any>,
) => {
  const _files = e.currentTarget.files;
  setFiles(Array.from(_files));
  setPreviews(
    Array.from(_files).map((file: any) => {
      return file.name;
    }),
  );
};

const audioSelectHandlerWithPreview = async (
  e: any,
  setFiles: React.Dispatch<any>,
  setPreviews: React.Dispatch<any>,
) => {
  const _files = e.currentTarget.files;
  setFiles(Array.from(_files));
  setPreviews(
    Array.from(_files).map((file: any) => {
      return file.name;
    }),
  );
};

const fileBucketUrl = (url: string, type: string) => {
  return `${process.env.REACT_APP_BUCKET_URL}/${type}/${url}`;
};

export {
  fileSelectHandler,
  imageSelectHandlerWithPreview,
  fileBucketUrl,
  videoSelectHandlerWithPreview,
  audioSelectHandlerWithPreview,
  singleImageSelectHandlerWithPreview,
};
