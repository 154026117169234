import { useEffect, useState } from 'react';

import { RecoilState, useSetRecoilState } from 'recoil';

import { api_, api_session } from '@shared/plugIn/axios';
import _ from 'lodash';
import * as qs from 'qs';
import { QueryStatus, useQuery } from 'react-query';

interface QueryString {
  filter?: any;
  populate?: any;
  options?: any;
  [x: string | number | symbol]: unknown;
}
export const queryString = ({
  filter,
  populate,
  options,
  ...query
}: QueryString = {}) => {
  return qs.stringify(
    {
      ...query,
      filter: JSON.stringify(filter),
      populate: JSON.stringify(populate),
      options: JSON.stringify(options),
    },
    { encode: false },
  );
};

export const fetcher = async ({ queryKey }: any) => {
  const { data } = await api_.get(queryKey[0]);
  return data;
};

export const fetcherSession = async ({ queryKey }: any) => {
  const { data } = await api_session.get(queryKey[0]);
  return data;
};

export function useRecoilQuery<T>(
  statusAtom: RecoilState<QueryStatus>,
  dataAtom: RecoilState<T>,
  url: string,
  fetcher: any,
  disabled?: boolean,
) {
  const setStatus = useSetRecoilState(statusAtom);
  const setData = useSetRecoilState(dataAtom);
  const { status, data } = useQuery(url, fetcher, {
    enabled: disabled ? false : true,
  });

  useEffect(() => {
    setStatus(status);
  }, [status, setStatus]);

  useEffect(() => {
    if (status === 'success') {
      setData(data as T);
    }
  }, [data, setData, status]);

  return { status, data };
}

type WithId = {
  _id: string;
};

export function useRecoilQueryInterVal<T extends WithId>(
  statusAtom: RecoilState<QueryStatus>,
  dataAtom: RecoilState<T[] | null>,
  urlGenerator: (page: number) => string,
  fetcher: any,
  ms: number,
  disabled?: boolean,
) {
  const [page, setPage] = useState<number>(0);
  const [stopState, setStopState] = useState<boolean>(false);

  const setStatus = useSetRecoilState(statusAtom);
  const setData = useSetRecoilState(dataAtom);

  const { status, data } = useQuery<T[]>(urlGenerator(page), fetcher, {
    enabled: disabled ? false : true,
  });

  useEffect(() => {
    // console.log('page: ', page);
    // console.log('status: ', status);
    // console.log('data: ', data);
  }, [data, page, status]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!stopState) {
      interval = setInterval(() => setPage((curr) => curr + 1), ms);
    }

    return () => clearInterval(interval);
  }, [ms, stopState]);

  useEffect(() => {
    setStatus((curr) => {
      if (curr === 'success' && status !== 'error') {
        return curr;
      } else {
        return status;
      }
    });
  }, [status, setStatus]);

  useEffect(() => {
    if (data?.length === 0) {
      setStopState(true);
    }
    if (status === 'success' && data) {
      setData((curr) =>
        _.unionWith(curr, data, (a, b) => {
          return a._id === b._id;
        }),
      );
    }
  }, [data, setData, status]);

  return { status, data };
}

/* ---------------------------- useQuery example ---------------------------- */
// const { status, data: funeralHomes } = useQuery(
//   `/admin/funeral-home?${useQueryString({
//     filter,
//     populate: { path: 'rooms', populate: { path: 'currentFevent' } },
//   })}`,
//   fetcher
// );

/* ---------------------------- useRecoilQuery example ---------------------------- */
// const { status, data: funeralHomes } = useRecoilQuery(
//   funeralHomesAtom,
//   `/admin/funeral-home?${useQueryString({
//     filter,
//     populate: { path: 'rooms', populate: { path: 'currentFevent' } },
//   })}`,
//   fetcher
// );
