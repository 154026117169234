export const isWeekend = (date: Date) => {
  const dayOfWeek = date.getDay();
  return dayOfWeek === 6 || dayOfWeek === 0;
};

export const getEstimateDeliveredAt = () => {
  const now = new Date();
  const nowHour = now.getHours();
  if (nowHour >= 19) {
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (isWeekend(tomorrow)) {
      tomorrow.setHours(11);
      tomorrow.setMinutes(0);
    } else {
      tomorrow.setHours(10);
      tomorrow.setMinutes(0);
    }
    return tomorrow;
  }
  //새벽인경우
  else if (nowHour <= 7) {
    const estimateDate = new Date(now);
    if (isWeekend(estimateDate)) {
      estimateDate.setHours(12);
      estimateDate.setMinutes(0);
    } else {
      estimateDate.setHours(11);
      estimateDate.setMinutes(0);
    }
    return estimateDate;
  } else {
    if (isWeekend(new Date(Date.now()))) {
      return new Date(Date.now() + 5 * 3600 * 1000);
    } else {
      return new Date(Date.now() + 4 * 3600 * 1000);
    }
  }
};
