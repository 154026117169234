import { FC, useMemo } from 'react';

import { ShopOrderForSellerAccount } from '@shared/api/shopOrder/shopOrder.interface';
import Modal from '@shared/components/Modal';
import { formatDate } from '@shared/utils/formatDate';
import { Table, TableColumnsType } from 'antd';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

interface Props {
  openId: string;
  shopOrderList: ShopOrderForSellerAccount[];
}

type AccountData = {
  id: string;
  index: number;
  shopOrder: ShopOrderForSellerAccount;
  requestedAt: Date;
  address: string;
  funeralHomeInfo: string;
  senderPhrase: string;
  itemName: string;
  priceRetail: number;
  priceWhole: number;
  priceRebateAgency: number;
  priceRebateWorker: number;
  pricePG: number;
  teamName: string;
  workerName: string;
  workerPhoneNumber: string;
};

const pgPrice = (order: ShopOrderForSellerAccount) => {
  switch (order.paymentDetail.method) {
    case '계좌 입금':
      return 0;
    case '카카오머니':
      return Math.ceil(0.0253 * order.paymentDetail.paymentByCash);
    case '카드':
      return Math.ceil(0.0209 * order.paymentDetail.paymentByCash);
    default:
      return Math.ceil(0.0209 * order.paymentDetail.paymentByCash);
  }
};

const columns: TableColumnsType<AccountData> = [
  {
    title: <p className="break-keep">순번</p>,
    dataIndex: 'index',
    key: 'index',
    align: 'center',
  },
  {
    title: <p className="break-keep">결제일</p>,
    dataIndex: 'requestedAt',
    key: 'requestedAt',
    align: 'center',
    render: (requestedAt: Date) => {
      return (
        <p>
          {formatDate(requestedAt, {
            dateSeparater: '/',
            contains: {
              year: false,
            },
          })}
        </p>
      );
    },
  },
  {
    title: '주소',
    dataIndex: 'address',
    key: 'address',
    align: 'center',
    render: (address: string) => {
      return <p className="break-keep">{address?.split('(')[0]}</p>;
    },
  },
  {
    title: '장례식장',
    dataIndex: 'funeralHomeInfo',
    key: 'funeralHomeInfo',
    align: 'center',
    render: (funeralHomeInfo: string) => {
      return <p className="break-keep">{funeralHomeInfo}</p>;
    },
  },
  {
    title: '문구',
    dataIndex: 'senderPhrase',
    key: 'senderPhrase',
    align: 'center',
    render: (senderPhrase: string) => {
      return <p className="break-keep">{senderPhrase}</p>;
    },
  },
  {
    title: '상품명',
    dataIndex: 'itemName',
    key: 'itemName',
    align: 'center',
    render: (itemName: string) => {
      return <p className="break-keep">{itemName}</p>;
    },
  },
  {
    title: '소비자가',
    dataIndex: 'priceRetail',
    key: 'priceRetail',
    align: 'center',
    render: (priceRetail: number) => {
      return <p className="break-keep">{priceRetail.toLocaleString()}원</p>;
    },
  },
  {
    title: '화환',
    dataIndex: 'priceWhole',
    key: 'priceWhole',
    align: 'center',
    render: (priceWhole: number) => {
      return <p className="break-keep">{priceWhole.toLocaleString()}원</p>;
    },
  },
  {
    title: '상조',
    dataIndex: 'priceRebateAgency',
    key: 'priceRebateAgency',
    align: 'center',
    render: (priceRebateAgency: number) => {
      return <p className="break-keep">{priceRebateAgency?.toLocaleString()}원</p>;
    },
  },
  {
    title: <p className="break-keep">지도사</p>,
    dataIndex: 'priceRebateWorker',
    key: 'priceRebateWorker',
    align: 'center',
    render: (priceRebateWorker: string) => {
      return <p className="break-keep">{priceRebateWorker.toLocaleString()}원</p>;
    },
  },
  {
    title: 'PG',
    dataIndex: 'pricePG',
    key: 'pricePG',
    align: 'center',
    render: (pricePG: number) => {
      return <p className="break-keep">{pricePG.toLocaleString()}원</p>;
    },
  },

  {
    title: <p className="break-keep">소속</p>,
    dataIndex: 'teamName',
    key: 'teamName',
    align: 'center',
    render: (teamName: string) => {
      return <p className="break-keep">{teamName}</p>;
    },
  },
  {
    title: <p className="break-keep">지도사</p>,
    dataIndex: 'workerName',
    key: 'workerName',
    align: 'center',
    render: (workerName: string) => {
      return <p>{workerName}</p>;
    },
  },
  {
    title: <p className="break-keep">휴대번호</p>,
    dataIndex: 'workerPhoneNumber',
    key: 'workerPhoneNumber',
    align: 'center',
    render: (workerPhoneNumber: string) => {
      return <p>{workerPhoneNumber}</p>;
    },
  },
  {
    title: <p className="break-keep">정산 여부</p>,
    dataIndex: 'shopOrder',
    key: 'shopOrder',
    align: 'center',
    render: (shopOrder: ShopOrderForSellerAccount) => {
      return (
        <p>
          {shopOrder.paymentDetail.isAccounted
            ? formatDate(shopOrder.paymentDetail.accountedDate, {
                contains: {
                  year: false,
                },
              })
            : 'X'}
        </p>
      );
    },
  },
  {
    title: <p className="break-keep">상조정산</p>,
    dataIndex: 'shopOrder',
    key: 'shopOrder',
    align: 'center',
    render: (shopOrder: ShopOrderForSellerAccount) => {
      return (
        <p>
          {shopOrder.paymentDetail.isRebated
            ? formatDate(shopOrder.paymentDetail.rebatedDate, {
                contains: {
                  year: false,
                },
              })
            : 'X'}
        </p>
      );
    },
  },
];

export const SellerAccountDetailModal: FC<Props> = function SellerAccountDetailModal({
  openId,
  shopOrderList,
}: Props) {
  const tableData: AccountData[] = useMemo(() => {
    return _.map(shopOrderList, (order, index) => {
      return {
        id: order._id,
        index: shopOrderList.length - index,
        shopOrder: order,
        requestedAt: order.paymentDetail.requestedAt as Date,
        address: order.funeralHomeInfo?.address,
        funeralHomeInfo: order.funeralHomeInfo?.name,
        senderPhrase: order.bugoDetail.senderPhrase,
        itemName: order.orderDetail.shopItemEmbed.name,
        priceRetail: order.orderDetail.shopItemEmbed.priceRetail,
        priceWhole: order.orderDetail.shopItemEmbed.priceWhole,
        priceRebateAgency: order.orderDetail.shopItemEmbed.priceRebateAgency,
        priceRebateWorker: order.orderDetail.shopItemEmbed.priceRebateWorker ?? 0,
        pricePG: pgPrice(order),
        teamName: order.bugo?.user.bugoAgencyWorkerDetail?.teamName ?? '',
        workerName: order.bugo?.user.info.name ?? '',
        workerPhoneNumber: order.bugo?.user.info.phoneNumber ?? '',
      };
    });
  }, [shopOrderList]);

  //내부용
  const csvDataForSeller = tableData.map((item) => {
    return {
      순번: item.index,
      접수일자: formatDate(item.requestedAt, {
        dateSeparater: '/',
        contains: {
          year: false,
        },
      }),
      주소: item?.address?.split('(')[0],
      장례식장: item.funeralHomeInfo,
      문구: item.senderPhrase,
      상품명: item.itemName,
      소비자가: item.priceRetail.toLocaleString(),
      화환가: item.priceWhole.toLocaleString(),
      상조회사: item.priceRebateAgency?.toLocaleString(),
      지도사: item.priceRebateWorker.toLocaleString(),
      PG: pgPrice(item.shopOrder),
      소속: item.teamName,
      지도사성함: item.workerName,
      휴대번호: item.workerPhoneNumber,
    };
  });

  //제출용
  const csvDataForSubmit = tableData.map((item) => {
    return {
      순번: item.index,
      접수일자: formatDate(item.requestedAt, {
        dateSeparater: '/',
        contains: {
          year: false,
        },
      }),
      주소: item?.address?.split('(')[0],
      장례식장: item.funeralHomeInfo,
      문구: item.senderPhrase,
      상품명: item.itemName,
      소비자가: item.priceRetail.toLocaleString(),
      상조회사: item.priceRebateAgency?.toLocaleString(),
      지도사: item.priceRebateWorker.toLocaleString(),
      소속: item.teamName,
      지도사성함: item.workerName,
    };
  });

  return (
    <Modal openId={openId} className="max-w-[80%] sm:max-w-[80%]">
      <div className="space-y-4">
        <div className="flex items-center justify-between pt-2 pr-2">
          <h2 className="bugo-h1 text-20">상세 세부 내역</h2>
          <div className="center-box gap-2">
            <CSVLink
              filename={'Expense_Table.csv'}
              data={_.flatMapDeep([csvDataForSubmit])}
              className="center-box rounded-md px-4 py-2 font-bold theme-bg-main"
            >
              <p className="theme-text-4">제출용 엑셀 다운로드</p>
            </CSVLink>
            <CSVLink
              filename={'Expense_Table.csv'}
              data={_.flatMapDeep([csvDataForSeller])}
              className="center-box rounded-md px-4 py-2 font-bold theme-bg-13"
            >
              <p className="theme-text-4">엑셀 다운로드</p>
            </CSVLink>
          </div>
        </div>
        <div>
          <Table
            className="w-full"
            columns={columns}
            dataSource={_.flatMapDeep([tableData])}
            pagination={{
              position: ['bottomCenter'],
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: [10, 15, 20, 50, 100],
            }}
            rowKey={'id'}
            bordered
            size={'small'}
          />
          <div className="flex items-center justify-end">
            <div className="center-box w-fit gap-2 rounded-md p-4 font-bold theme-text-4 theme-bg-main">
              <p>PG수수료 합산 :</p>
              {tableData
                .reduce((accu, prev) => {
                  return accu + prev.pricePG;
                }, 0)
                .toLocaleString()}
              원
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
