import React, { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import DateFilterSearcher from '@service/bugo/components/DateFilterSearcher';
import { BugoStatRangeQueryEnum } from '@service/bugo/page/AgencyAdminBugoStatMobilePage';
import { Bugo } from '@shared/api/bugo/bugo.interface';
import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Member } from '@shared/api/member/member.interface';
import { ShopOrder } from '@shared/api/shopOrder/shopOrder.interface';
import { WorkerUser } from '@shared/api/user/user.interface';
import CheckboxFilter, { CheckBoxFilter } from '@shared/components/CheckboxFilter';
import { TextField } from '@shared/components/TextField';
import { CheckBox } from '@shared/containers';
import { filteredListAtomFamily } from '@shared/containers/FilterSelector/filteredList.atom';
import { FilterSelctor } from '@shared/containers/FilterSelector/FilterSelector';
import { useAuth } from '@shared/state/hooks/useAuth';
import { getAgencyWorkerTeamName } from '@shared/utils/agencyWorkerUtils';
import { getDefaultDateFrom } from '@shared/utils/dateUtils';
import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import FuzzySearch from 'fuzzy-search';
import _ from 'lodash';
import { CSVLink } from 'react-csv';

import WorkerDetailModalOpener from '../WorkerDetailModalOpener/WorkerDetailModalOpener';

interface IProps {
  workerUserList: WorkerUser[];
  range: BugoStatRangeQueryEnum | null;
  teamTypesFilter: CheckBoxFilter | null;
}

type WorkerTableData = {
  id: string;
  index: number;
  user: WorkerUser;
  teamName: string;
  region: string;
  feventListLen: number;
  noSentFeventListLen: number;
  orderListLen: number;
  fpb: number;
};

const onDateRangeChange = (
  dates: [any, any] | null,
  setValue: React.Dispatch<React.SetStateAction<[any, any]>>,
) => {
  if (dates) {
    setValue(dates);
  } else {
    setValue([null, null]);
  }
};

const getOrderLenOfWorker = (worker: WorkerUser) => {
  let sum = 0;
  worker.bugoAgencyWorkerDetail?.fevents.forEach(
    (fevent) => (sum += fevent.shopOrders.length),
  );

  return sum;
};

interface AlertBoundary {
  min: number;
  max: number;
}

const defaultAlertBoundary: AlertBoundary = {
  min: 0,
  max: 10,
};

const AgencyAdminRankTable: FC<IProps> = ({
  workerUserList,
  range,
  teamTypesFilter,
}: IProps) => {
  const { userProfile } = useAuth();
  const [noSentFiltered, setNoSentFiltered] = useState<boolean>(false);
  const [alertFiltered, setAlertFiltered] = useState<boolean>(false);
  const [alertBoundary, setAlertBoundary] = useState<AlertBoundary>(defaultAlertBoundary);

  const bugoBrand = userProfile?.bugoAgencyAdminDetail?.bugoBrands[0];
  const teamTypes = bugoBrand?.bugoAgency?.teamTypes ?? [];
  const teamNames = bugoBrand?.bugoAgency?.teamNames ?? [];

  const branchNames = teamNames.filter((name) => {
    return teamTypes.indexOf(name) === -1;
  });

  const branchFilterId = `agencyAdminRankTalbe-${bugoBrand?._id}`;
  const branchFilter = useRecoilValue(filteredListAtomFamily(branchFilterId));

  const alertBoundaryFilterId = `alertBoundaryFilter`;
  const [alertBoundaryFilter, setAlertBoundaryFilter] = useRecoilState(
    filteredListAtomFamily(alertBoundaryFilterId),
  );

  useEffect(() => {
    const alertBoundaryObjects = [
      { name: '주의', min: 0, max: 1 },
      { name: '경계', min: 1, max: 2 },
      { name: '위험', min: 2, max: 100 },
    ];
    const min = _.min(
      _.map(
        _.filter(alertBoundaryFilter, { checked: true }),
        (item) => _.findLast(alertBoundaryObjects, { name: item.name })?.min,
      ),
    );
    const max = _.min(
      _.map(
        _.filter(alertBoundaryFilter, { checked: true }),
        (item) => _.findLast(alertBoundaryObjects, { name: item.name })?.max,
      ),
    );

    if (_.filter(alertBoundaryFilter, { checked: true }).length === 0) {
      setAlertFiltered(false);
      setAlertBoundary({ min: 1, max: 0 });
    } else {
      setAlertBoundary({ min: min ?? 0, max: max ?? 10 });
    }
  }, [alertBoundary.max, alertBoundary.min, alertBoundaryFilter]);

  useEffect(() => {
    if (alertFiltered) {
      setAlertBoundaryFilter([
        { name: '주의', checked: true },
        { name: '경계', checked: true },
        { name: '위험', checked: true },
      ]);
    }
  }, [alertFiltered, setAlertBoundaryFilter]);

  const [searchValue, setSearchValue] = useState<string>('');

  const [dateRange, setDateRange] = useState<[any | null, any | null]>([null, null]);

  const [dateFilteredWorkerList, setDateFilteredWorkerList] = useState<WorkerUser[]>(
    workerUserList ?? [],
  );

  const [selectedTeamTypes, setSelectedTeamTypes] = useState(teamTypesFilter);

  const meanFPB = useMemo(() => {
    let orderLength = 0;
    let feventLength = 0;
    _.map(dateFilteredWorkerList, (worker) => {
      if ((worker.bugoAgencyWorkerDetail?.fevents.length ?? 0) !== 0) {
        orderLength += getOrderLenOfWorker(worker);
        feventLength += worker.bugoAgencyWorkerDetail?.fevents.length ?? 0;
      }
    });

    return feventLength === 0 ? 0 : (orderLength / feventLength).toFixed(2);
  }, [dateFilteredWorkerList]);

  const columns: TableColumnsType<WorkerTableData> = useMemo(
    () => [
      {
        width: '5%',
        title: <div className="break-keep">index</div>,
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        render: (index: number) => {
          return <p className="break-keep text-xs">{index + 1}</p>;
        },
      },
      {
        title: '소속',
        dataIndex: 'teamName',
        key: 'teamName',
        align: 'center',
        render: (teamName) => {
          return <p className="font-medium">{teamName}</p>;
        },
      },
      // {
      //   title: '소속지역',
      //   dataIndex: 'region',
      //   key: 'region',
      //   align: 'center',
      //   render: (region) => {
      //     return <p className="font-medium">{region}</p>;
      //   },
      // },
      {
        title: '지도사',
        dataIndex: 'user',
        key: 'user',
        align: 'center',
        render: (user) => {
          return (
            <WorkerDetailModalOpener
              workerName={user.info.name}
              workerId={user._id}
              fontSize={14}
            />
          );
        },
      },
      {
        title: '부고등록',
        dataIndex: 'feventListLen',
        key: 'feventListLen',
        align: 'center',
        render: (value: number) => {
          return <p className="text-gray-600">{value.toLocaleString()}건</p>;
        },
        sorter: {
          compare: (a, b) => a.feventListLen - b.feventListLen,
          multiple: 1,
        },
      },
      // {
      //   title: () => {
      //     return (
      //       <div
      //         className="center-box gap-2"
      //         onClick={(e) => {
      //           e.stopPropagation();
      //         }}
      //       >
      //         <div className="center-box gap-1">
      //           (
      //           <CheckBox
      //             checked={noSentFiltered}
      //             onCheckedChange={(checked: boolean) => {
      //               setNoSentFiltered(checked);
      //             }}
      //           ></CheckBox>
      //           <p>미발송자</p>)
      //         </div>
      //         <p>미발송 부고</p>
      //       </div>
      //     );
      //   },
      //   dataIndex: 'noSentFeventListLen',
      //   key: 'noSentFeventListLen',
      //   align: 'center',
      //   render: (value: number) => {
      //     return <p className="text-gray-600">{value.toLocaleString()}건</p>;
      //   },
      //   sorter: {
      //     compare: (a, b) => a.noSentFeventListLen - b.noSentFeventListLen,
      //     multiple: 1,
      //   },
      // },
      {
        title: '화환판매수량',
        dataIndex: 'orderListLen',
        key: 'orderListLen',
        align: 'center',
        render: (value: number) => {
          return <p className="text-gray-600">{value.toLocaleString()}개</p>;
        },
        sorter: {
          compare: (a, b) => a.orderListLen - b.orderListLen,
          multiple: 2,
        },
      },
      {
        title: 'FPB',
        dataIndex: 'fpb',
        key: 'fpb',
        align: 'center',
        render: (fpb: number) => {
          const textColor = () => {
            const sigma = Number(meanFPB) - Number(fpb.toFixed(2));
            if (sigma > 2) {
              return 'theme-text-warn-03';
            }
            if (sigma > 1) {
              return 'theme-text-warn-02';
            }
            if (sigma > 0) {
              return 'theme-text-warn-01';
            }
            return 'text-gray-600';
          };
          return <p className={textColor()}>{fpb.toFixed(2)}</p>;
        },
        sorter: {
          compare: (a, b) => a.fpb - b.fpb,
        },
      },
    ],
    [meanFPB, noSentFiltered],
  );

  const teamTypesFilteredWorkerList = useMemo(() => {
    if (selectedTeamTypes) {
      return dateFilteredWorkerList.filter(
        (worker) => selectedTeamTypes[worker.bugoAgencyWorkerDetail?.teamType ?? ''],
      );
    } else return dateFilteredWorkerList;
  }, [dateFilteredWorkerList, selectedTeamTypes]);

  const searchedValue = useMemo(() => {
    const searcher = new FuzzySearch(teamTypesFilteredWorkerList, [
      'info.name',
      'bugoAgencyWorkerDetail.teamType',
      'bugoAgencyWorkerDetail.teamName',
    ]);
    return searcher.search(searchValue);
  }, [teamTypesFilteredWorkerList, searchValue]);

  const branchFilteredValue = useMemo(() => {
    return searchedValue.filter((item) => {
      if (item.bugoAgencyWorkerDetail?.teamType === '지사') {
        return (
          branchFilter.find((filter) => {
            return filter?.name === item.bugoAgencyWorkerDetail?.teamName;
          })?.checked ?? true
        );
      } else {
        return true;
      }
    }, []);
  }, [branchFilter, searchedValue]);

  const noSentFilteredValue = useMemo(() => {
    if (noSentFiltered) {
      return branchFilteredValue.filter((worker) => {
        const noSentFeventList = worker.bugoAgencyWorkerDetail?.fevents.filter((item) => {
          return (
            item.memberOrderList.filter((member) => {
              return member.bugo?.lastSentPhoneNumber;
            }).length <= 0
          );
        });
        return noSentFeventList?.length ?? 0 > 0;
      }, []);
    } else {
      return branchFilteredValue;
    }
  }, [branchFilteredValue, noSentFiltered]);

  const alertFilteredValue = useMemo(() => {
    if (alertFiltered) {
      return noSentFilteredValue.filter((worker) => {
        const fpb =
          (worker.bugoAgencyWorkerDetail?.fevents.length ?? 0) === 0
            ? 0
            : getOrderLenOfWorker(worker) /
              (worker.bugoAgencyWorkerDetail?.fevents.length ?? 0);

        return (
          Number(meanFPB) - Number(fpb.toFixed(2)) > alertBoundary.min &&
          Number(meanFPB) - Number(fpb.toFixed(2)) <= alertBoundary.max
        );
      }, []);
    } else {
      return noSentFilteredValue;
    }
  }, [alertBoundary, alertFiltered, noSentFilteredValue, meanFPB]);

  const filteredFPB = useMemo(() => {
    let orderLength = 0;
    let feventLength = 0;
    _.map(alertFilteredValue, (worker) => {
      if ((worker.bugoAgencyWorkerDetail?.fevents.length ?? 0) !== 0) {
        orderLength += getOrderLenOfWorker(worker);
        feventLength += worker.bugoAgencyWorkerDetail?.fevents.length ?? 0;
      }
    });

    return feventLength === 0 ? 0 : (orderLength / feventLength).toFixed(2);
  }, [alertFilteredValue]);

  const teamFPBColor = useMemo(() => {
    const sigma = Number(meanFPB) - Number(filteredFPB);
    if (sigma > 2) {
      return 'theme-text-warn-03';
    }
    if (sigma > 1) {
      return 'theme-text-warn-02';
    }
    if (sigma > 0) {
      return 'theme-text-warn-01';
    }
    return '';
  }, [meanFPB, filteredFPB]);

  const tableData: WorkerTableData[] = useMemo(() => {
    return _.map(
      alertFilteredValue.sort((a, b) => {
        return -getOrderLenOfWorker(a) + getOrderLenOfWorker(b);
      }) as WorkerUser[] | null,
      (worker, index): WorkerTableData => {
        const noSentFeventListLen = worker.bugoAgencyWorkerDetail?.fevents.filter(
          (item) => {
            return (
              item.memberOrderList.filter((member) => {
                return member.bugo?.lastSentPhoneNumber;
              }).length <= 0
            );
          },
        );
        return {
          id: worker._id,
          user: worker,
          index: index,
          teamName: getAgencyWorkerTeamName(worker),
          region: worker.bugoAgencyWorkerDetail?.region ?? '',
          feventListLen: worker.bugoAgencyWorkerDetail?.fevents.length ?? 0,
          noSentFeventListLen: noSentFeventListLen?.length ?? 0,
          orderListLen: getOrderLenOfWorker(worker),
          fpb:
            (worker.bugoAgencyWorkerDetail?.fevents.length ?? 0) === 0
              ? 0
              : getOrderLenOfWorker(worker) /
                (worker.bugoAgencyWorkerDetail?.fevents.length ?? 0),
        };
      },
    );
  }, [alertFilteredValue]);

  const csvTableData = tableData.map((item) => {
    return {
      소속: item.teamName,
      소속지역: item.region,
      지도사: item.user.info.name,
      부고등록건수: item.feventListLen,
      화환판매수량: item.orderListLen,
      FPB: item.fpb.toFixed(2),
    };
  });
  // default range 적용
  useEffect(() => {
    if (!range) return;

    const from = getDefaultDateFrom(range);
    const to = new Date();
    setDateRange([dayjs(from), dayjs(to)]);
  }, [range]);

  // dateRange 적용.
  useEffect(() => {
    if (!workerUserList || !dateRange[0] || !dateRange[1]) return;

    const newWorkerUserList: WorkerUser[] = [];

    workerUserList.forEach((worker) => {
      if (!worker.bugoAgencyWorkerDetail) return;

      const newFevents: Fevent<
        string,
        FuneralHomeInfo,
        string,
        undefined,
        ShopOrder[],
        Member<string, Bugo>[]
      >[] = [];

      worker.bugoAgencyWorkerDetail.fevents.forEach((fevent) => {
        if (!fevent.createdAt) return;
        const createdAt = new Date(fevent.createdAt);
        if (dateRange[0].toDate() <= createdAt && dateRange[1].toDate() >= createdAt) {
          newFevents.push(fevent);
        }
      });

      const newWorker: WorkerUser = {
        ...worker,
        bugoAgencyWorkerDetail: {
          ...worker.bugoAgencyWorkerDetail,
          fevents: newFevents,
        },
      };

      newWorkerUserList.push(newWorker);
    });

    setDateFilteredWorkerList(newWorkerUserList);
  }, [dateRange, workerUserList]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
  };

  return (
    <div className="rounded-lg bg-white px-4">
      <div className="grid w-full grid-cols-2 gap-x-8 gap-y-4 py-4">
        <div className="grid grid-cols-2 items-center gap-4">
          <p className="pl-4 font-medium text-gray-600">
            총{' '}
            <span className="font-bold text-black">
              {alertFilteredValue.length.toLocaleString()}
            </span>
            명
          </p>
          <TextField
            className="w-full border-gray-500"
            type={'search'}
            onChange={onChangeHandler}
            placeholder="검색 상조 지도사명"
          />
        </div>
        <div className="flex items-center justify-between gap-4">
          <DateFilterSearcher
            dateRange={dateRange}
            onChange={(dates) => onDateRangeChange(dates, setDateRange)}
            size="large"
          />
          <CSVLink
            filename={'Expense_Table.csv'}
            data={_.flatMapDeep([csvTableData])}
            className="center-box filled-gray-800 rounded-md px-4 py-2 font-bold"
          >
            <p className="text-white">엑셀 다운로드</p>
          </CSVLink>
        </div>

        {/* 소속 필터 */}
        {selectedTeamTypes && (
          <div className="flex items-center space-x-4 border text-sm theme-bg-1 theme-border-1">
            <div
              className={`center-box self-stretch bg-opacity-40 px-2 font-bold theme-bg-5`}
            >
              소속
            </div>
            <div className="flex flex-1 items-center justify-around space-x-2 py-1 pr-2">
              <CheckboxFilter
                filter={selectedTeamTypes}
                setFilter={(checked) => setSelectedTeamTypes(checked)}
              />
              <FilterSelctor
                filterNameList={branchNames}
                filteredListAtomId={branchFilterId}
              />
            </div>
          </div>
        )}
        <div className="flex items-center  border pr-2 text-sm theme-bg-1 theme-border-1">
          <div
            className={`center-box self-stretch bg-opacity-40 px-2 font-bold theme-bg-5`}
          >
            FPB
          </div>
          <div className="flex flex-1 items-center justify-around space-x-4 py-1 pr-2">
            <div className="">
              <p className="text-center font-medium text-gray-600">전체</p>
              <span className="text-justify text-16 font-bold text-black">{meanFPB}</span>
            </div>
            <div className="">
              <p className="text-center font-medium text-gray-600">선택</p>
              <span className={`text-16 font-bold text-black  ${teamFPBColor}`}>
                {filteredFPB}
              </span>
            </div>
            <div className="center-box gap-1">
              <p className="text-center font-medium text-gray-600">위험</p>
              <CheckBox
                checked={alertFiltered}
                onCheckedChange={(checked: boolean) => {
                  setAlertFiltered(checked);
                }}
              ></CheckBox>
            </div>
          </div>
        </div>
      </div>
      <Table
        className="w-full"
        columns={columns}
        dataSource={_.flatMapDeep([tableData])}
        pagination={{
          position: ['bottomCenter'],
          defaultPageSize: 20,
          showSizeChanger: true,
          pageSizeOptions: [10, 15, 20, 50, 100],
        }}
        rowKey={'id'}
        bordered
        size={'small'}
      />
    </div>
  );
};

export default AgencyAdminRankTable;
