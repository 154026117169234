export enum RoughRegionEnum {
  수도권 = '수도권',
  광역시 = '광역시',
  지방 = '지방',
}

type Regions = {
  [key: string]: RoughRegionEnum;
};

const regions: Regions = {
  // 수도권
  서울특별시: RoughRegionEnum.수도권,
  서울: RoughRegionEnum.수도권,
  인천광역시: RoughRegionEnum.수도권,
  인천: RoughRegionEnum.수도권,
  경기도: RoughRegionEnum.수도권,
  경기: RoughRegionEnum.수도권,
  // 광역시
  대구: RoughRegionEnum.광역시,
  광주: RoughRegionEnum.광역시,
  대전: RoughRegionEnum.광역시,
  부산: RoughRegionEnum.광역시,
  울산: RoughRegionEnum.광역시,
  광주광역시: RoughRegionEnum.광역시,
  대구광역시: RoughRegionEnum.광역시,
  대전광역시: RoughRegionEnum.광역시,
  부산광역시: RoughRegionEnum.광역시,
  울산광역시: RoughRegionEnum.광역시,
  // 지방
  강원도: RoughRegionEnum.지방,
  강원: RoughRegionEnum.지방,
  경상북도: RoughRegionEnum.지방,
  경북: RoughRegionEnum.지방,
  경상남도: RoughRegionEnum.지방,
  경남: RoughRegionEnum.지방,
  전라북도: RoughRegionEnum.지방,
  전북: RoughRegionEnum.지방,
  전라남도: RoughRegionEnum.지방,
  전남: RoughRegionEnum.지방,
  충청북도: RoughRegionEnum.지방,
  충북: RoughRegionEnum.지방,
  충청남도: RoughRegionEnum.지방,
  충남: RoughRegionEnum.지방,
  제주특별자치도: RoughRegionEnum.지방,
  제주: RoughRegionEnum.지방,
  세종특별시: RoughRegionEnum.지방,
  세종특별자치시: RoughRegionEnum.지방,
  세종: RoughRegionEnum.지방,
};

export const getRoughRegion = (region: string): string => regions[region] ?? '';
