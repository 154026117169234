import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { getShopItemImageUrl } from '@shared/api/shopItem/shopItem.utils';
import { shopItemForStoreStateSelectorFamily } from '@shared/api/shopItem/shotItem.selector';
import { Button } from '@shared/components/Button';
import Collapse from '@shared/components/Collapse';
import { Loading } from '@shared/components/Loading';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatDate } from '@shared/utils/formatDate';
import { formatPrice } from '@shared/utils/formatPrice';
import { getStoreItemTagColorStyle } from '@shared/utils/getStoreItemTagColorStyle';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { useStoreTitleHook } from '../hooks/useStoreTitleHook';
import { getDiscountRate } from '../utils/getDiscountRate';
import { getEstimateDeliveredAt } from '../utils/getEstimateDeliveredAt';

interface ILocationState {
  state: null | {
    name: string;
  };
}

const StoreItemDetailPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paramsString = searchParams.toString();
  const { itemId } = useParams<{ itemId?: string }>();
  const location = useLocation() as ILocationState;

  const shopItemForStoreState = useRecoilValue(
    shopItemForStoreStateSelectorFamily(itemId ?? 'undefined'),
  );
  const shopItem = useMemo(() => {
    return shopItemForStoreState.data;
  }, [shopItemForStoreState]);

  useStoreTitleHook(
    shopItem?.shopItemInfo.name ?? location.state?.name ?? '상품 상세',
    ' ',
  );

  useEffect(() => {
    if (shopItemForStoreState.status === 'error') navigate('/flower-store/login');
  }, [shopItemForStoreState, navigate]);

  return (
    <>
      {shopItemForStoreState.status === 'success' && shopItem ? (
        <div className="max-w-inherit p-4 pb-[84px]">
          <div></div>
          {/* 화환 이미지 */}
          <img
            src={fileBucketUrl(getShopItemImageUrl(shopItem), 'w1080')}
            alt="Flower Image"
            className="mx-auto aspect-square w-full bg-white object-cover object-center"
          />
          {/* 상품 정보 */}
          <div className="mt-4">
            {/* 상품명 / 배달 정보 */}
            <p className="font-bold">{shopItem.shopItemInfo.name}</p>
            <div className="flex flex-col">
              {/* 태그[] */}
              <div className="flex space-x-1 text-[10px] font-bold leading-3">
                {shopItem.shopItemInfo.tags.map((tag, i) => (
                  <div
                    key={`${tag}-${i}`}
                    className={`flex h-5 w-[49px] items-center justify-center ${getStoreItemTagColorStyle(
                      tag,
                    )}`}
                  >
                    {tag}
                  </div>
                ))}
              </div>
              {/* 도착 날짜 / 상품명 */}
              <div className="mt-2 text-sm">
                {/* 도착 예상 날짜 */}
                <div className="theme-text-main">
                  {formatDate(getEstimateDeliveredAt(), {
                    dateSeparater: '/',
                    contains: {
                      year: false,
                      day: true,
                    },
                  })}{' '}
                  도착 보장
                </div>
                {/* 상품명 */}
                <h4 className="pt-1 font-bold">{shopItem.name}</h4>
              </div>
              {/* 상품 가격 / 할인 정보 */}
              <div className="mt-1 flex items-end justify-end space-x-3">
                {/* 할인율 */}
                <div className="flex text-base leading-4 text-[#00A3B0]">
                  <div className="font-bold">
                    {getDiscountRate(shopItem.priceRetailShow, shopItem.priceRetail)}
                  </div>
                  <span className="text-sm leading-4">%&#9660;</span>
                </div>
                {/* 원래 가격 */}
                <div className="text-sm leading-4 text-gray-500 line-through">
                  {formatPrice(shopItem.priceRetailShow)}원
                </div>
                {/* 할인 적용 가격 */}
                <div className="flex items-end">
                  <h3 className="text-xl font-bold leading-6">
                    {formatPrice(shopItem.priceRetail)}
                  </h3>
                  <span className="text-sm leading-6">원</span>
                </div>
              </div>
            </div>
          </div>
          {/* Collapse */}
          <div className="mt-4 flex flex-col space-y-0 border-t pt-0 theme-border-1">
            <Collapse title="상품설명" initialFolded={false}>
              {shopItem.detailInfo ?? shopItem.shopItemInfo.detailInfo}
            </Collapse>
            <Collapse title="구매 배송 안내" initialFolded={false}>
              {/* TODO: 안내문 채워넣기 */}
              <div>
                ※ 구입금액과 지역 관계없이 무료배송 (산간, 섬지역은 예외) (지역별
                배송상품이 불가할 경우 유선안내 됩니다.) <br />
                (또한 강원, 경북산간, 섬지역 외 배송비가 추가될 경우 유선안내 됩니다. ){' '}
                <br />
                (단, 희망한 배송시간 3시간 전까지 연락주시면 주문취소가 가능합니다.){' '}
                <br />
                ※ 당일배송 가능시간 : 오후 8시까지
                <br />※ 배송소요시간 : 3시간 이내 배송가능
                <br /> 20시 이후 주문 건에 대해서는 명일(다음 날) 오전 중(12시 이내)으로
                배송완료됩니다.
                <br />
                배송 관련 궁금사항은 1800-6987로 전화주시면 친절하게 안내해드리겠습니다^^
                <br />
                <br />
                <span className="font-bold text-black">
                  ※ 명절 기간 안내 ※
                  <br />
                  명절 기간 동안은 3시간 이내로 배송이 어려운 점 양해 부탁드립니다.
                  <br />
                  명절 기간 동안은 예정시간내 배송이 어려울 수 있습니다.
                  <br />
                  그러나 당일 배송은 가능하니 망설이지 마시고 주문해 주세요. 고객님의
                  주문에 정성을 다해 신속하게 배달해 드리겠습니다.
                  {/* {testDeliveryInfo} */}
                </span>
              </div>
            </Collapse>
            <Collapse title="교환/환불 관련 안내" initialFolded={false}>
              {/* TODO: 안내문 채워넣기 */}
              교환과 환불은 주문한 상품에 대한 아래와 같은 요소가 발생시에는 100%
              가능합니다. <br />
              - 교환은 다음과 같은 경우 요인
              <br />
              ① 배송된 상품이 파손 되었거나 오염되었을 경우
              <br />
              ② 배송된 상품이 주문한 내용과 다를 경우
              <br />
              ③ 쇼핑몰이 제공한 정보와 다를 경우
              <br />
              (교환은 위과 같은 요인 발생시 3시간이내 재교환이 가능합니다.)
              <br />
              - 환불은 아래와 같은 요인 경우
              <br />
              ① 배송시간 3시간 전에 주문이 취소되었을 경우
              <br />
              ② 상품이 품절 되었거나 기타 사유로 인해 배송이 불가능한 경우
              <br />
              (환불처리는 위와같은 요인이 발생시 12시간 이내에 전액 환불처리 해드립니다)
              <br />
              <br />
              ※ 주의사항 ※
              <br />
              상기 이미지는 이해를 돕기위한 참고용 이미지이며, 지역이나 제작 업체에 따라
              사용되는 소재 및 화형에 차이가 있을 수 있습니다
              <br />
              (해당 사유로는 취소 및 환불이 불가합니다)
              <br />
              꽃은 생물이기때문에 제작/출고된 제품은 교환 및 환불이 불가능합니다
              <br />
              또한 고객님의 단순변심, 기본정보오류,배송지정보오류등인경우 환불이
              불가능합니다
              {/* {testRefundInfo} */}
            </Collapse>
            <Collapse title="원산지 표시 안내" initialFolded={false}>
              {/* TODO: 안내문 채워넣기 */}
              <span className="text-sm text-gray-700">
                장미, 국화, 카네이션, 백합, 튤립, 글라디올러스, 거베라, 아이리스
              </span>
              {/* {data.shopItemInfo.} */}
            </Collapse>
          </div>
          {/* 구매 버튼 */}
          <Link
            to={`/flower-store/item/${itemId}/checkout/${
              paramsString && '?' + paramsString
            }`}
          >
            <Button className="button-rectangle fixed bottom-0 left-0 text-white theme-bg-main">
              구매하기
            </Button>
          </Link>
        </div>
      ) : (
        <div className="center-box min-h-screen">
          <Loading />
        </div>
      )}
    </>
  );
};

export default StoreItemDetailPage;
