import { parseISO } from 'date-fns';

const isoDateFormat =
  /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;

function isIsoDateString(value: any): boolean {
  return value && typeof value === 'string' && isoDateFormat.test(value);
}

export function handleAxiosDates(body: any) {
  if (body === null || body === undefined || typeof body !== 'object') return body;

  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = parseISO(value);
    else if (typeof value === 'object') handleAxiosDates(value);
  }
}

export const getAccountDate = (month: number) => {
  const currentDate = new Date();
  const lastDayOfMonth = new Date(currentDate.getFullYear(), month, 0);
  const accountDateTime = new Date(
    lastDayOfMonth.getFullYear(),
    lastDayOfMonth.getMonth() + 1,
    9,
    24,
    0,
    0,
  );
  return accountDateTime;
};

export const getRebateDate = (month: number) => {
  const currentDate = new Date();
  const lastDayOfMonth = new Date(currentDate.getFullYear(), month, 0);
  const accountDateTime = new Date(
    lastDayOfMonth.getFullYear(),
    lastDayOfMonth.getMonth() + 1,
    14,
    24,
    0,
    0,
  );
  return accountDateTime;
};

export const getNextAccountDate = () => {
  const currentDate = new Date();
  // 해당 월의 마지막 날짜를 구합니다.
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );
  // 마지막 날짜의 24시 00분 00초를 구합니다.
  const lastDateTime = new Date(
    lastDayOfMonth.getFullYear(),
    lastDayOfMonth.getMonth(),
    9,
    24,
    0,
    0,
  );
  // 결과를 출력합니다.
  return lastDateTime;
};
