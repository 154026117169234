import { selectorFamily } from 'recoil';

import {
  FeventListByWorkerIdState,
  feventListByWorkerIdDataAtomFamily,
  feventListByWorkerIdStatusAtomFamily,
} from './workerDetailModal.atom';

export const feventListByWorkerIdStateSelectoramily = selectorFamily<
  FeventListByWorkerIdState,
  string
>({
  key: 'feventListByWorkerIdStateSelectoramily',
  get:
    (id: string) =>
    ({ get }) => ({
      status: get(feventListByWorkerIdStatusAtomFamily(id)),
      data: get(feventListByWorkerIdDataAtomFamily(id)),
    }),
});
