import { atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { Image } from '../media/media.interface';
import { ShopItemInfo } from './shopItemInfo.interface';

export const shopItemInfoListByShopStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'shopItemInfoListByShopStatusAtomFamily',
  default: 'idle',
});

export const shopItemInfoListByShopDataAtomFamily = atomFamily<
  ShopItemInfo<string, Image, Image>[] | null,
  string
>({
  key: 'shopItemInfoListByShopDataAtomFamily',
  default: null,
});
