import { FC, useMemo, useState } from 'react';

import Collapse from '@shared/components/Collapse';
import Tabs from '@shared/components/Tabs';
import { Link } from 'react-router-dom';

import { getFAQStatements } from '../../../@shared/misc/StoreFAQStatement';
import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

/**
 * 스토어 FAQ 페이지
 * @returns Page
 */

const tabItems: readonly string[] = ['주문/결제', '취소/환불', '반품/교환'];

const {
  cancelStatement,
  paymentMethodStatement,
  phraseChangeStatement,
  refundStatement,
  returnStatement,
  returnStatement2,
  withoutAccountDepositStatement,
  withoutAccountDepositStatement2,
} = getFAQStatements();

const StoreFAQPage: FC = () => {
  useStoreTitleHook('자주 묻는 질문');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const selectedContent = useMemo(() => {
    switch (selectedTab) {
      case 0:
        return (
          <>
            <Collapse title={'여러가지 결제수단으로 결제할 수 있나요?'}>
              {paymentMethodStatement}
            </Collapse>
            <Collapse title={'무통장입금으로 결제하고 싶어요.'}>
              {withoutAccountDepositStatement}
            </Collapse>
            <Collapse title={'무통장입금 계좌는 어떻게 확인하나요?'}>
              {withoutAccountDepositStatement2}
            </Collapse>
          </>
        );
      case 1:
        return (
          <>
            <Collapse title={'환불은 어떻게 처리되나요?'}>{cancelStatement}</Collapse>
            <Collapse title={'주문한 상품을 취소하려면 어떻게 하나요?'}>
              {refundStatement}
            </Collapse>
            <Collapse title={'카드결제 후 주문취소했는데, 언제 환불되나요?'}>
              {returnStatement}
            </Collapse>
          </>
        );
      case 2:
        return (
          <>
            <Collapse title={'화환, 조화의 문구를 변경하고 싶어요.'}>
              {phraseChangeStatement}
            </Collapse>
            <Collapse title={'상품을 반품하고 싶어요.'}>{returnStatement2}</Collapse>
          </>
        );
    }
  }, [selectedTab]);

  return (
    <div>
      {/* Selector */}
      <Tabs items={tabItems} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className="py-6 px-4 text-sm font-bold">
        {/* Collapse */}
        {selectedContent}
        {/* Enquiry Box */}
        <div className="mt-[63px] px-8 pb-10">
          <h5 className="whitespace-nowrap text-center theme-text-1">
            그 외 궁금하신 점은 1:1 문의를 이용해주세요.
          </h5>
          <Link
            to="/flower-store/enquiry"
            className="mx-auto mt-4 flex h-[52px] w-40 items-center justify-center bg-gray-800 text-white"
          >
            1:1 문의
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StoreFAQPage;
