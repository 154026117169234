import { FC } from 'react';

import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { Fevent } from '@shared/api/fevent/fevent.interface';
import { FuneralHomeInfo } from '@shared/api/funeralHomeInfo/funeralHomeInfo.interface';
import { Image } from '@shared/api/media/media.interface';
import { Member } from '@shared/api/member/member.interface';
import { RoomInfo } from '@shared/api/roomInfo/roomInfo.interface';
import { ShopOrder } from '@shared/api/shopOrder/shopOrder.interface';

import { deceasedNameString } from '../utils/bugoString.util';

interface IProps {
  fevent: Fevent<
    string,
    FuneralHomeInfo,
    RoomInfo,
    undefined,
    ShopOrder[],
    Member[],
    Image,
    CashAccount[]
  >;
}

const getDateString = (date: Date | null) => {
  if (!date) return;

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}년 ${month}월 ${day}일`;
};

const CourtesyTopBanner: FC<IProps> = ({ fevent }: IProps) => {
  return (
    <div className="center-box bg-gray-800 px-6 py-3 text-white">
      {/* Metadata */}
      <div className="flex flex-col items-center py-2">
        <span className="font-bold">
          {deceasedNameString(
            fevent.deceasedInfo.name,
            fevent.deceasedInfo.sex,
            fevent.deceasedInfo.age,
            fevent.deceasedInfo.nameDetail,
          )}
        </span>
        {fevent.deceasedInfo.death?.date && (
          <span className="text-[12px]">
            {getDateString(fevent.deceasedInfo.death.date)} 별세
          </span>
        )}
      </div>
    </div>
  );
};

export default CourtesyTopBanner;
