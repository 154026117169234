import { atom } from 'recoil';

export const feventUploadImageAtom = atom<File | null>({
  key: 'feventUploadImageAtom',
  default: null,
});

export const feventDpImageFileNameAtom = atom<string | null>({
  key: 'feventDpImageFileNameAtom',
  default: null,
});

export const feventDpImageDeleteAtom = atom<boolean>({
  key: 'feventDpImageDeleteAtom',
  default: false,
});
