//상조관리에서 관리자계정을 보기위한 카드
import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { BugoBrand, BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { User } from '@shared/api/user/user.interface';
import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';

import { AdminAgencyBranchAdminUpsertModal } from './AdminAgencyBranchAdminUpsertModal';

interface Props {
  user: User;
  bugoBrand: BugoBrand | BugoBrandData;
}

export const AgencyBranchAdminCard: FC<Props> = function AgencyBranchAdminCard({
  user,
  bugoBrand,
}: Props) {
  const openId = `agencyBranchAdminCard-${user._id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  return (
    <div className="space-y-4 rounded-md p-4 font-medium shadow-smd theme-bg-1">
      <div className="grid grid-cols-2 items-center gap-2 font-bold">
        <p>계정: {user.username}</p>
        <p>이름: {user.info.name}</p>
        <p>테마: {user.info.theme}</p>
        <p>지사: {user.bugoAgencyBranchAdminDetail?.teamName}</p>
      </div>
      <Button
        className="button-rectangle h-8 rounded-md theme-text-3 theme-bg-main"
        onClick={() => {
          setOpen(true);
        }}
      >
        수정
      </Button>
      <AdminAgencyBranchAdminUpsertModal
        agencyBranchAdminUser={user}
        openId={openId}
        bugoBrand={bugoBrand}
      />
    </div>
  );
};
