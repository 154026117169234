import { selector, selectorFamily } from 'recoil';

import { QueryDataState } from '@shared/interfaces';

import {
  userDataAtom,
  userStatusAtom,
  workerUserAgencyAdminDataAtomFamily,
  workerUserAgencyAdminStatusAtomFamily,
  workerUserDataAtomFamily,
  workerUserListByBugoBrandDataAtomFamily,
  workerUserListByBugoBrandLightDataAtomFamily,
  workerUserListByBugoBrandLightStatusAtomFamily,
  workerUserListByBugoBrandStatusAtomFamily,
  workerUserStatusAtomFamily,
} from './user.atom';
import { User, UserState, WorkerUserListState, WorkerUserState } from './user.interface';

export const userStateSelector = selector<UserState>({
  key: 'userStateSelector',
  get: ({ get }) => {
    return {
      status: get(userStatusAtom),
      data: get(userDataAtom),
    };
  },
});

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook

export const workerUserByBugoBrandStateSelector = selectorFamily<
  WorkerUserListState,
  string
>({
  key: 'workerUserByBugoBrandStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(workerUserListByBugoBrandStatusAtomFamily(id)),
        data: get(workerUserListByBugoBrandDataAtomFamily(id)),
      };
    },
});

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook (refactoring)

export const workerUserAgencyAdminStateSelector = selectorFamily<
  WorkerUserListState,
  string
>({
  key: 'workerUserAgencyAdminStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(workerUserAgencyAdminStatusAtomFamily(id)),
        data: get(workerUserAgencyAdminDataAtomFamily(id)),
      };
    },
});

export const workerUserStateSelector = selectorFamily<WorkerUserState, string>({
  key: 'workerUserStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(workerUserStatusAtomFamily(id)),
        data: get(workerUserDataAtomFamily(id)),
      };
    },
});

export const workerUserByBugoBrandLightStateSelector = selectorFamily<
  QueryDataState<User[]>,
  string
>({
  key: 'workerUserByBugoBrandLightStateSelector',
  get:
    (id: string) =>
    ({ get }) => {
      return {
        status: get(workerUserListByBugoBrandLightStatusAtomFamily(id)),
        data: get(workerUserListByBugoBrandLightDataAtomFamily(id)),
      };
    },
});
