import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import {
  BugoBrandAnnouncement,
  BugoBrandAnnouncementList,
} from './bugoBrandAnnouncement.interface';

export const bugoBrandAnnouncementStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'bugoBrandAnnouncementStatusAtomFamily',
  default: 'idle',
});

export const bugoBrandAnnouncementDataAtomFamily = atomFamily<
  BugoBrandAnnouncement | null,
  string
>({
  key: 'bugoBrandAnnouncementDataAtomFamily',
  default: null,
});

export const bugoBrandAnnouncementListStatusAtom = atom<QueryStatus>({
  key: 'bugoBrandAnnouncementListStatusAtom',
  default: 'idle',
});

export const bugoBrandAnnouncementListDataAtom = atom<BugoBrandAnnouncementList | null>({
  key: 'bugoBrandAnnouncementListDataAtom',
  default: null,
});

export const bugoBrandAnnouncementListForWorkerStatusAtom = atom<QueryStatus>({
  key: 'bugoBrandAnnouncementListForWorkerStatusAtom',
  default: 'idle',
});

export const bugoBrandAnnouncementListForWorkerDataAtom =
  atom<BugoBrandAnnouncementList | null>({
    key: 'bugoBrandAnnouncementListForWorkerDataAtom',
    default: null,
  });
