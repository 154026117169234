import { FC } from 'react';

import { makeOptionList } from '@shared/containers/Select/Select';
import { ISelectProps } from '@shared/interfaces/select.interface';
import { Controller } from 'react-hook-form';
import ReactSelect, { components } from 'react-select';

import { Icon } from '../icons';

/**
 *
 * @param placeholder value가 null일 때 label 값.
 * @param optionList select의 option들.
 * @param control useForm()으로부터 얻을 수 있는 값.
 * @param name register()의 첫 번째 인자로 들어가는 값.
 * @param className for tailwind css
 * @param optionClassName for tailwind css
 * @returns PCSelect using react-hook-form
 */

export interface IPCSelectProps extends ISelectProps {
  className?: string;
  optionClassName?: string;
}

const PCSelect: FC<IPCSelectProps> = ({
  placeholder,
  optionList,
  control,
  name,
  className,
  optionClassName,
  defaultValue = '',
}: IPCSelectProps) => {
  const { ChevronDown, X } = Icon();
  const options = makeOptionList(optionList);
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, onBlur, ref } }) => (
        <ReactSelect
          ref={ref}
          name={placeholder}
          options={options}
          value={options.find((option) => option.value === value)}
          placeholder={placeholder}
          onChange={(option) => onChange(option?.value)}
          onBlur={onBlur}
          menuPlacement="auto"
          unstyled
          isSearchable={false}
          isClearable={true}
          className={className}
          classNames={{
            control: (state) => {
              const currOptions = state.getValue();
              return `w-full theme-bg-1 text-sm font-bold pl-4 pr-[3px] h-11 border theme-border-1 hover:cursor-pointer ${className}`;
            },
            option: (state) => {
              const value_ = state.data.value;
              return `theme-border-1 ${
                state.isFocused && value_ !== value ? 'theme-bg-5 text-black' : ''
              } ${
                value_ === value
                  ? 'font-bold theme-bg-main text-white hover:theme-bg-main hover:text-white'
                  : 'theme-bg-1'
              } ${optionClassName} option-default`;
            },
            menu: () => {
              return `border theme-border-1 my-2 p-0 shadow-lg`;
            },
            placeholder: () => {
              return `theme-text-6`;
            },
          }}
          styles={{
            control: (styles) => ({ ...styles, fontSize: 14, lineHeight: '20px' }),
            option: (styles) => ({
              ...styles,
              fontSize: 13,
              lineHeight: '20px',
            }),
          }}
          components={{
            DropdownIndicator: (props) =>
              props.hasValue ? null : (
                <components.DropdownIndicator {...props}>
                  {/* TODO: Theme에 맞는 아이콘으로 변경 */}
                  <ChevronDown className="fill-gray-500 stroke-none" />
                </components.DropdownIndicator>
              ),
            ClearIndicator: (props) => (
              <components.ClearIndicator {...props}>
                {/* TODO: Theme에 맞는 아이콘으로 변경 */}
                <X className="fill-gray-800 text-gray-800" />
              </components.ClearIndicator>
            ),
          }}
        />
      )}
    />
  );
};

export default PCSelect;
