import { FC, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useSellerShopItemListByShopHook } from '@shared/api/shopItem/shopItem.hook';
import { sellerShopItemListBySHopStateSelectorFamily } from '@shared/api/shopItem/shotItem.selector';
import { useShopItemInfoListByShopHook } from '@shared/api/shopItemInfo/shopItemInfo.hook';
import { shopItemInfoListByShopStateSelectorFamily } from '@shared/api/shopItemInfo/shopItemInfo.selector';
import { Icon } from '@shared/components/icons';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { UpsertMode } from '@shared/types';
import { useParams } from 'react-router-dom';

import { RebateBugoBrandRelationDisplay } from '../components/RebateBugoBrandRelationDisplay';
import { ShopItemManageCard } from '../components/ShopItemManageCard';
import { ShopItemUpsertModal } from '../components/ShopItemUpsertModal';
import { useSellerTopNavHook } from '../hooks/useSellerTopNavHook';

/**
 * shopId, shopItemInfoId에 매칭되는 shopItem을 생성 및 수정(관리)하는 페이지
 * @returns
 */

const SellerShopItemManageByShopItemInfoPage: FC =
  function SellerShopItemManageByShopItemInfoPage() {
    const { shopId, shopItemInfoId } = useParams();

    const { isAdmin } = useAuth();

    useSellerTopNavHook('소매상품관리-상세');

    //! (1) shopItem갯수 어차피 그렇게 많지 않으니까 그냥 shopId로 다 들고 온후에 shopItemInfoId로 필터링
    useSellerShopItemListByShopHook(`${shopId}`);
    useShopItemInfoListByShopHook(`${shopId}`);

    const shopItemInfoListState = useRecoilValue(
      shopItemInfoListByShopStateSelectorFamily(`${shopId}`),
    );

    const shopItemInfo = useMemo(() => {
      if (shopItemInfoListState.status === 'success' && shopItemInfoListState.data) {
        const shopItemInfoList = shopItemInfoListState.data;
        const shopItemInfo = shopItemInfoList.find((shopItemInfo) => {
          return shopItemInfo._id === shopItemInfoId;
        });
        return shopItemInfo;
      }
    }, [shopItemInfoId, shopItemInfoListState.data, shopItemInfoListState.status]);

    const { CirclePlusLight } = Icon();

    const shopItemListState = useRecoilValue(
      sellerShopItemListBySHopStateSelectorFamily(`${shopId}`),
    );

    const addShopItemOpenId = `addShopItem-${shopItemInfoId}`;

    const setAddShopItemOpen = useSetRecoilState(simpleOpenAtomFamily(addShopItemOpenId));

    const shopItemList = useMemo(() => {
      if (shopItemListState.status === 'success' && shopItemListState.data) {
        const shopItemRawList = shopItemListState.data;

        return shopItemRawList.filter((shopItem) => {
          if (shopItem.shopItemInfo._id === shopItemInfoId) {
            return true;
          } else {
            return false;
          }
        });
      }
    }, [shopItemInfoId, shopItemListState.data, shopItemListState.status]);

    const addShopItemCard = useMemo(() => {
      if (shopItemInfo) {
        return (
          <>
            <button
              type="button"
              className="add-shop-item-card center-box gap-2 p-8"
              onClick={() => {
                setAddShopItemOpen(true);
              }}
            >
              <div className="flex justify-center">
                <CirclePlusLight className="inner-content wh-12 fill-gray-400" />
              </div>
              <span className="inner-content mt-2 block text-lg font-medium text-gray-900">
                새 상품 추가
              </span>
            </button>
            <ShopItemUpsertModal
              openId={addShopItemOpenId}
              mode={UpsertMode.Insert}
              shopItemInfo={shopItemInfo}
            ></ShopItemUpsertModal>
          </>
        );
      }
    }, [CirclePlusLight, addShopItemOpenId, setAddShopItemOpen, shopItemInfo]);

    const shopItemCardGrid = useMemo(() => {
      if (shopItemList && shopItemInfo) {
        return shopItemList.map((shopItem) => {
          return (
            <ShopItemManageCard
              shopItem={shopItem}
              key={shopItem._id}
              shopItemInfo={shopItemInfo}
            />
          );
        });
      }
    }, [shopItemInfo, shopItemList]);

    return (
      <div className="mx-auto max-w-6xl px-4 pb-8 sm:px-6 lg:px-8">
        <div className="mt-8 space-y-4">
          {isAdmin() && <RebateBugoBrandRelationDisplay />}
          <div className="grid grid-cols-3 gap-4">
            {shopItemCardGrid}
            {addShopItemCard}
          </div>
        </div>
      </div>
    );
  };

export default SellerShopItemManageByShopItemInfoPage;
