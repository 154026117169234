import { StoreRole } from '@shared/api/shopOrder/shopOrder.interface';
import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import {
  sellerShopItemListByShopDataAtomFamily,
  sellerShopItemListByShopStatusAtomFamily,
  shopItemForStoreDataAtomFamily,
  shopItemForStoreStatusAtomFamily,
  shopItemListByBugoForStoreDataAtom,
  shopItemListByBugoForStoreStatusAtom,
  storeShopItemDataAtomFamily,
  storeShopItemStatusAtomFamily,
  testShopItemListForBugoDataAtom,
  testShopItemListForBugoStatusAtom,
} from './shopItem.atom';

const shopItemForStorePopulate = {
  populate: [
    {
      path: 'shopItemInfo',
      populate: [
        {
          path: 'image',
        },
        {
          path: 'detailImages',
        },
      ],
    },
    {
      path: 'image',
    },
    {
      path: 'detailImages',
    },
    {
      path: 'deliveredImageList',
    },
  ],
};

export const storeShopItemUrl = `store/`;

export const useStoreShopItemHook = function (id: string) {
  useRecoilQuery(
    storeShopItemStatusAtomFamily(id),
    storeShopItemDataAtomFamily(id),
    `${storeShopItemUrl}/${id}`,
    fetcher,
  );
};

export const storeShopItemListByBugoUrl = (bugoId: string, storeRole: StoreRole) => {
  const route = storeRole === 'admin' ? 'admin' : 'public';
  return `${route}/shop-item/by-bugo?${queryString({
    bugo: bugoId,
    ...shopItemForStorePopulate,
  })}`;
};

export const useStoreShopItemListByBugoHook = (bugoId: string, storeRole: StoreRole) => {
  useRecoilQuery(
    shopItemListByBugoForStoreStatusAtom,
    shopItemListByBugoForStoreDataAtom,
    storeShopItemListByBugoUrl(bugoId, storeRole),
    fetcher,
  );
};

export const shopItemForStoreUrl = (shopItemId: string, storeRole: StoreRole) => {
  const route = storeRole === 'admin' ? 'admin' : 'public';

  return `${route}/shop-item/${shopItemId}?${queryString(shopItemForStorePopulate)}`;
};

export const useShopItemForStoreHook = (shopItemId: string, storeRole: StoreRole) => {
  useRecoilQuery(
    shopItemForStoreStatusAtomFamily(shopItemId),
    shopItemForStoreDataAtomFamily(shopItemId),
    shopItemForStoreUrl(shopItemId, storeRole),
    fetcher,
  );
};

export const testShopItemForBugoUrl = (bugoId: string) =>
  `/public/shop-item/by-bugo?${queryString({
    bugo: bugoId,
    populate: [
      {
        path: 'shopItemInfo',
        populate: {
          path: 'image',
        },
      },
      {
        path: 'image',
      },
    ],
  })}`;

export const useTestShopItemListForBugoHook = (bugoId: string) => {
  useRecoilQuery(
    testShopItemListForBugoStatusAtom,
    testShopItemListForBugoDataAtom,
    testShopItemForBugoUrl(bugoId),
    fetcher,
  );
};

export const sellerShopItemListByFeventUrl = (feventId: string) => {
  return `public/shop-item/by-fevent?${queryString({
    fevent: feventId,
    ...shopItemForStorePopulate,
  })}`;
};

export const sellerShopItemListByShopUrl = (shopId: string, role: LowerRole) => {
  return `${role}/shop-item/?${queryString({
    filter: {
      shop: shopId,
    },
    populate: [
      { path: 'shopItemInfo' },
      {
        path: 'rebate',
      },
    ],
  })}`;
};

export const useSellerShopItemListByShopHook = (shopId: string) => {
  const { sellerRole } = useAuth();
  useRecoilQuery(
    sellerShopItemListByShopStatusAtomFamily(shopId),
    sellerShopItemListByShopDataAtomFamily(shopId),
    sellerShopItemListByShopUrl(shopId, sellerRole()),
    fetcher,
  );
};
