import { FC } from 'react';

import OrderCancleRequestPage from '@service/store/page/OrderCancleRequestPage';
import { useShopOrderForStoreHook } from '@shared/api/shopOrder/shopOrder.hook';
import { useAuth } from '@shared/state/hooks/useAuth';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

const OrderIdRouter: FC = () => {
  const { storeRole } = useAuth();
  const { orderId } = useParams();

  useShopOrderForStoreHook(orderId ?? 'undefined', storeRole(), Boolean(orderId));

  return (
    <Routes>
      <Route path="cancel" element={<OrderCancleRequestPage />} />
      <Route path="*" element={<Navigate to="" />} />
    </Routes>
  );
};

export default OrderIdRouter;
