import { atom, atomFamily } from 'recoil';

import { BugoData, BugoForStoreData } from '@shared/api/bugo/bugo.interface';
import { QueryStatus } from 'react-query';

export const bugoStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'bugoStatusAtomFamily',
  default: 'idle',
});

export const bugoDataAtomFamily = atomFamily<BugoData | null, string>({
  key: 'bugoDataAtomFamily',
  default: null,
});

export const bugoForStoreStatusAtom = atom<QueryStatus>({
  key: 'bugoForStoreStatusAtom',
  default: 'idle',
});

export const bugoForStoreDataAtom = atom<BugoForStoreData | null>({
  key: 'bugoForStoreDataAtom',
  default: null,
});

export const bugoByMemberIdStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'bugoByMemberIdStatusAtomFamily',
  default: 'idle',
});

export const bugoByMemberIdDataAtomFamily = atomFamily<BugoData | null, string>({
  key: 'bugoByMemberIdDataAtomFamily',
  default: null,
});
