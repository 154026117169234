//* 주문 상품 정보 in OrderDetailPage

import { FC } from 'react';

import { ShopOrderForStore } from '@shared/api/shopOrder/shopOrder.interface';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { deliveryPaymentStateToString } from '@shared/utils/stateUtils';

interface Props {
  shopOrder: ShopOrderForStore;
}

export const OrderItemInfo: FC<Props> = function OrderItemInfo({ shopOrder }: Props) {
  const orderDetail = shopOrder.orderDetail;
  return (
    <div className="flex border-t border-b text-sm font-normal theme-bg-1 theme-border-1 sm:text-base">
      <div className={`wh-32 hidden shrink-0 overflow-hidden sm:block`}>
        <img
          src={fileBucketUrl(
            shopOrder.orderDetail.shopItem.shopItemInfo.image.url,
            'w400',
          )}
          alt="productImg"
        />
      </div>
      <div className="grid flex-1 grid-cols-2">
        <div className="grid h-full grid-rows-3">
          <div className="grid grid-cols-3 border-b theme-border-1">
            <div className="center-box p-2 font-medium theme-bg-6">이름</div>
            <div className="col-span-2 flex items-center p-2">
              <span>{orderDetail.shopItemEmbed.name}</span>
            </div>
          </div>
          <div className="grid grid-cols-3 border-b theme-border-1">
            <div className="center-box p-2 font-medium theme-bg-6">금액</div>
            <div className="col-span-2 flex items-center p-2">
              <span>{orderDetail.shopItemEmbed.priceRetail.toLocaleString()}원</span>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="center-box p-2 font-medium theme-bg-6">수량</div>
            <div className="col-span-2 flex items-center p-2">
              <span>{orderDetail.quantity}개</span>
            </div>
          </div>
        </div>
        <div className="grid h-full grid-cols-3">
          <div className="center-box p-2 font-medium theme-bg-6">
            <p>배송 상태</p>
          </div>
          <div className="center-box col-span-2 flex-col gap-4 p-2 font-medium">
            {deliveryPaymentStateToString(
              shopOrder.paymentDetail.status,
              shopOrder.deliveryDetail.status,
            )}
            {/* 구매확정은 결제완료 인경우에만 보이는게맞음 */}
            {/* {shopOrder.paymentDetail.status === PaymentState.PAID && (
              <>
                <Button
                  className="button-rectangle filled-indigo-400 h-10"
                  // onClick={() => setConfirmOpen(true)}
                >
                  구매확정
                </Button>
              </>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
