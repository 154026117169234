import { fetcher, useRecoilQuery } from '@shared/hooks/recoil-query';

import {
  funeralHomeInfoListDataAtom,
  funeralHomeInfoListStatusAtom,
} from './funeralHomeInfo.atom';

export const funeralHomeInfoListHookUrl = `public/funeral-home-info`;

export const useFuneralHomeInfoListHook = () => {
  useRecoilQuery(
    funeralHomeInfoListStatusAtom,
    funeralHomeInfoListDataAtom,
    funeralHomeInfoListHookUrl,
    fetcher,
  );
};
