import { BugoData } from '@shared/api/bugo/bugo.interface';
import { ShopItemForStoreData } from '@shared/api/shopItem/shopItem.interface';
import {
  ShopOrderCreateDto,
  ShopOrderType,
} from '@shared/api/shopOrder/shopOrder.interface';

import { IShopItemFormData } from '../state/shopItemCheckout.interface';
import { getEstimateDeliveredAt } from './getEstimateDeliveredAt';

interface IParams {
  formData: IShopItemFormData;
  shopItem: ShopItemForStoreData;
  bugo: BugoData;
  userId: string;
}

export const getShopOrderDto = ({
  formData,
  shopItem,
  bugo,
  userId,
}: IParams): ShopOrderCreateDto => ({
  user: userId,
  shop: shopItem.shop,
  bugo: bugo?._id ?? '',
  type: ShopOrderType.Bugo,
  bugoDetail: {
    condolencePhrase: formData.phraseCondolence,
    senderPhrase: formData.phraseSender,
  },
  deliveryDetail: {
    estimatedDeliveredAt: getEstimateDeliveredAt(),
    senderAddress: '', // sender address 정보 입력된것이 없음
    senderName: formData.senderName,
    senderPhoneNumber: formData.senderTel,
    receiverAddress: formData.receiverAddress,
    receiverName: formData.receiverName,
    receiverPhoneNumber: bugo?.member.phoneNumber ?? '',
    receiverPostalCode: bugo.fevent.funeralHomeInfo.postalCode,
    // deliveredAt 서버에서 생성
    // deliveredImage 서버에서 생성
  },
  orderDetail: {
    shopItem: shopItem._id,
    quantity: formData.itemCount,
  },
  paymentDetail: {
    paymentByCash: shopItem.priceRetail,
    paymentByPoint: 0,
  },
});
