import { Fragment } from 'react';

import { useRecoilState } from 'recoil';

import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { classNames } from '@shared/utils/joinClassNamesUtils';
import { Link, useLocation } from 'react-router-dom';

interface SidebarProps {
  navigation: any[];
  secondaryNavigation: any[];
}

export const Sidebar = function Sidebar({
  navigation,
  secondaryNavigation,
}: SidebarProps) {
  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useRecoilState(
    simpleOpenAtomFamily(`AdvancedSidebar-flower-seller-shop`),
  );
  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 items-center px-4">
                <Link to={'/flower-seller/home'}>
                  {/* TODO: 로고 */}
                  <p className="h-8 text-16 font-medium text-white">가온인트라넷</p>
                </Link>
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/easywire-logo-cyan-300-mark-white-text.svg"
                  alt="Easywire logo"
                /> */}
              </div>
              <nav
                className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="space-y-1 px-2">
                  {navigation
                    .filter((item) => item.active)
                    .map((item) => {
                      if (item.active)
                        return (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              pathname === item.href
                                ? 'bg-cyan-800 text-white hover:text-white'
                                : 'text-cyan-100 hover:bg-cyan-600 hover:text-white',
                              'group flex items-center rounded-md px-2 py-2 text-base font-medium',
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon
                              className="wh-6 mr-4 flex-shrink-0 fill-cyan-200"
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        );
                    })}
                </div>
                <div className="mt-6 pt-6">
                  <div className="space-y-1 px-2">
                    {secondaryNavigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-cyan-100 hover:bg-cyan-600 hover:text-white"
                      >
                        <item.icon
                          className="wh-6 mr-4 fill-cyan-200 "
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </nav>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-grow flex-col overflow-y-auto bg-cyan-700 pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center px-4">
            <Link to={'/flower-seller/home'}>
              {/* TODO: 로고 */}
              <p className="h-8 text-16 font-medium text-white">가온인트라넷</p>
            </Link>
            {/* <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/easywire-logo-cyan-300-mark-white-text.svg"
              alt="Easywire logo"
            /> */}
          </div>
          <nav
            className="mt-5 flex flex-1 flex-col divide-y divide-cyan-200 overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="space-y-1 px-2">
              {navigation.map((item) => {
                if (item.active)
                  return (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        pathname === item.href
                          ? 'bg-cyan-800 text-white hover:text-white'
                          : 'text-cyan-100 hover:bg-cyan-600 hover:text-white',
                        'group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className="wh-6 mr-4 flex-shrink-0 fill-cyan-200"
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  );
              })}
            </div>
            <div className="mt-6 pt-6">
              <div className="space-y-1 px-2">
                {secondaryNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className="group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-cyan-100 hover:bg-cyan-600 hover:text-white"
                  >
                    <item.icon
                      className="wh-6 mr-4 fill-cyan-200 text-cyan-200"
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};
