import { FC, useEffect, useMemo, useState } from 'react';

import { useRecoilState } from 'recoil';

import { BugoData } from '@shared/api/bugo/bugo.interface';
import { CourtesyContent } from '@shared/api/courtesyAsset/courtesyAsset.interface';
import Tabs from '@shared/components/Tabs';
import Select from '@shared/containers/Select/Select';
import { useForm, useWatch } from 'react-hook-form';

import { courtesyContentAtom, courtesyContentStringAtom } from './courtesy.atom';
import { getCourtesyStringFromContent } from './courtesyGenerator';

interface Props {
  courtesyContentList: CourtesyContent[];
  bugo: BugoData;
}

//* 해당 멤버가 답례글에서 예문 선택기
export const MemberCourtesyContentSelector: FC<Props> =
  function MemberCourtesyContentSelector({ courtesyContentList, bugo }: Props) {
    const [courtesyContent, setCourtesyContent] = useRecoilState(courtesyContentAtom);

    const [courtesyContentString, setCourtesyContentString] = useRecoilState(
      courtesyContentStringAtom,
    );

    const deafaultCourtesyContentId = bugo.fevent.courtesyContent;

    const defaultCourtesyContent = courtesyContentList.find((item) => {
      return item._id === deafaultCourtesyContentId;
    });

    // !초기값 courtesyContent  -> defaultCourtesyContent(fevent) -> 0번째
    const { control, setValue } = useForm({
      defaultValues: {
        courtesyContent:
          courtesyContent?.name ??
          defaultCourtesyContent?.name ??
          courtesyContentList[0]?.name,
        courtesyContentString: courtesyContent
          ? getCourtesyStringFromContent(courtesyContent, bugo)
          : defaultCourtesyContent
          ? getCourtesyStringFromContent(defaultCourtesyContent, bugo)
          : courtesyContentList[0]
          ? getCourtesyStringFromContent(courtesyContentList[0], bugo)
          : '',
      },
    });

    const courtesyContentSetList = useMemo(() => {
      const courtesyContentCategory = courtesyContentList.map((item) => {
        return item.name.split('(')[0];
      });
      const categorySet = Array.from(new Set(courtesyContentCategory)) as string[];
      return categorySet;
    }, [courtesyContentList]);

    // !tab 초기값  default(fevent)
    const [selectedTab, setSelectedTab] = useState<number>(
      // index === -1이면 0 처리
      Math.max(
        courtesyContentSetList.indexOf(defaultCourtesyContent?.name?.split('(')[0] ?? ''),
        0,
      ),
    );

    // 해당 탭 클릭시 해당 탭에서 선택 가능한 예문 리스트
    const categoriedCourtesyContentList = courtesyContentList.filter(
      (courtesyContent) => {
        return courtesyContent.name.split('(')[0] === courtesyContentSetList[selectedTab];
      },
    );

    // 해당 탭 클릭시 첫번째걸로 courtesyContent 변경
    const onTabClick = (tab: number) => {
      const tabCourtesyCotentList = courtesyContentList.filter((courtesyContent) => {
        return courtesyContent.name.split('(')[0] === courtesyContentSetList[tab];
      });
      setValue('courtesyContent', tabCourtesyCotentList[0]?.name);
    };

    const courtesyContentName = useWatch({ control, name: 'courtesyContent' });

    //! control courtesyContent 변경시 recoilState CourtesyContent courtesyContentString 변경되도록함

    useEffect(() => {
      const courtesyContentByName = courtesyContentList.find((item) => {
        return item.name === courtesyContentName;
      });
      if (courtesyContentByName && courtesyContent?.name !== courtesyContentByName.name) {
        setCourtesyContent(courtesyContentByName);

        setCourtesyContentString(
          getCourtesyStringFromContent(courtesyContentByName, bugo),
        );
      }
    }, [
      bugo,
      courtesyContent,
      courtesyContentList,
      courtesyContentName,
      setCourtesyContent,
      setCourtesyContentString,
    ]);

    return (
      <>
        <div className="bg-white pt-4">
          <h1 className="bugo-h1 px-4">답례글 선택</h1>
          <Tabs
            items={courtesyContentSetList}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            onTabClick={onTabClick}
          />
          {/* 예문 상세 선택 */}
        </div>
        <div className="px-4 text-sm font-medium">
          <Select
            name="courtesyContent"
            placeholder={'예문 선택'}
            control={control}
            optionList={categoriedCourtesyContentList.map((item) => {
              return item.name;
            })}
            defaultValue={categoriedCourtesyContentList[0]?.name}
          ></Select>
        </div>
        <div className="px-4 text-sm">
          <textarea
            className="min-h-[328px] w-full border bg-white px-4 py-3 text-sm text-[12px] theme-border-main"
            value={courtesyContentString ?? ''}
            onChange={(e) => {
              setCourtesyContentString(e.target.value);
            }}
          ></textarea>
        </div>
      </>
    );
  };
