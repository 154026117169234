import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';

import {
  enquiryListDataAtom,
  enquiryListStatusAtom,
  userEnquiryListDataAtom,
  userEnquiryListStatusAtom,
} from './enquiry.atom';

export const enquiryListHookUrl = () => {
  return `admin/enquiry/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    populate: [
      { path: 'user' },
      {
        path: 'image',
      },
    ],
  })}`;
};

export const useEnquiryListhook = () => {
  const { isAdmin } = useAuth();
  useRecoilQuery(
    enquiryListStatusAtom,
    enquiryListDataAtom,
    enquiryListHookUrl(),
    fetcher,
    !isAdmin(),
  );
};

export const userEnquiryListHookUrl = (role: string) => {
  return `/${role}/enquiry/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    populate: [
      {
        path: 'image',
      },
    ],
  })}`;
};

export const useUserEnquiryListHook = () => {
  const { assetRole, isAuthenticated } = useAuth();
  useRecoilQuery(
    userEnquiryListStatusAtom,
    userEnquiryListDataAtom,
    userEnquiryListHookUrl(assetRole()),
    fetcher,
    !isAuthenticated,
  );
};
