import { FC } from 'react';

import MobileAutoComplete from '@shared/components/AutoComplete/MobileAutoComplete';
import PCAutoComplete from '@shared/components/AutoComplete/PCAutoComplete';
import { IAutoCompleteProps } from '@shared/interfaces/autoComplete.interface';
import { useMediaQuery } from 'react-responsive';

const AutoComplete: FC<IAutoCompleteProps> = ({
  placeholder,
  optionList,
  control,
  name,
  className,
  optionClassName,
  defaultValue = '',
  onChangeFunc,
}: IAutoCompleteProps) => {
  const isMobile = !useMediaQuery({
    query: '(min-width: 768px)',
  });

  return isMobile ? (
    <MobileAutoComplete
      placeholder={placeholder}
      optionList={optionList}
      control={control}
      name={name}
      className={className}
      optionClassName={optionClassName}
      defaultValue={defaultValue}
      onChangeFunc={onChangeFunc}
    />
  ) : (
    <PCAutoComplete
      placeholder={placeholder}
      optionList={optionList}
      control={control}
      name={name}
      className={className}
      optionClassName={optionClassName}
      defaultValue={defaultValue}
      onChangeFunc={onChangeFunc}
    />
  );
};

export default AutoComplete;
