// Splash Component that cover the screen

import { FC, useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import splashImg from 'src/assets/image/aplusSplash.png';

interface IProps {
  isLoading: boolean;
}

const Splash: FC<IProps> = ({ isLoading }: IProps) => {
  const [localLoading, setLocalLoading] = useState<boolean>(true);

  // 로딩이 너무 빠르면 1초 있다가 Splash를 없앤다.
  useEffect(() => {
    const timeoutId = setTimeout(() => setLocalLoading(false), 1400);

    return () => clearTimeout(timeoutId);
  }, []);

  // 둘 모두 false일 때 Splash를 없앤다.
  const loading = isLoading && localLoading;

  return (
    <AnimatePresence>
      {loading && (
        <motion.div
          className={`center-box fixed top-0 left-1/2 z-50 h-full w-full max-w-screen-md -translate-x-1/2 bg-cover bg-bottom`}
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <img src={splashImg} className="w-full"></img>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Splash;
