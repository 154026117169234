import { FC } from 'react';

import CemeteryAdmin from '@service/bugo/components/CemeteryAdmin';
import { BottomPopup } from '@shared/components/BottomPopup';
import AutoComplete from '@shared/containers/AutoComplete/AutoComplete';
import { IAutoCompleteProps } from '@shared/interfaces/autoComplete.interface';
import { UseFormSetValue } from 'react-hook-form';
import { useId } from 'react-id-generator';

interface IProps extends IAutoCompleteProps {
  className?: string;
  setValue: UseFormSetValue<any>;
}

const CemeteryBar: FC<IProps> = ({
  placeholder,
  optionList,
  control,
  name,
  setValue,
  className,
}: IProps) => {
  const [id] = useId(1, 'cemetery');
  const openId = `cemetery-${id}`;
  // const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  // const onClick = () => {
  //   setOpen(true);
  // };

  return (
    <>
      <div className={`${className} flex w-full`}>
        <AutoComplete
          placeholder={placeholder}
          optionList={optionList}
          control={control}
          name={name}
          className="flex-1"
        />
        {/* <div className="center-box border border-l-0 bg-white pr-[1px]">
          <Button
            type="button"
            onClick={onClick}
            className="button-rectangle h-[calc(100%-2px)] px-6 theme-text-4 theme-bg-13"
          >
            관리
          </Button>
        </div> */}
      </div>
      <BottomPopup openId={openId}>
        <div className="w-screen bg-transparent">
          <CemeteryAdmin
            optionList={optionList}
            name={name}
            setValue={setValue}
            openId={openId}
          />
        </div>
      </BottomPopup>
    </>
  );
};

export default CemeteryBar;
