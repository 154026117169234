import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import {
  storeNavBackUrlAtom,
  storeNavBarTitleAtom,
  storeNavBarUseAtom,
  storeNavRightTextAtom,
} from '@service/store/containers/StoreNavBar/storeNavBar.atom';

export const useStoreTitleHook = function (
  title: string,
  rightText?: string,
  onRightTextClick?: () => void,
  use = true,
  backUrlString?: string,
) {
  const setTitle = useSetRecoilState(storeNavBarTitleAtom);
  const setRightText = useSetRecoilState(storeNavRightTextAtom);
  const setUse = useSetRecoilState(storeNavBarUseAtom);
  const setBackUrl = useSetRecoilState(storeNavBackUrlAtom);

  useEffect(() => {
    setTitle(title);
    setRightText(rightText ?? '');
    setUse(use);
    if (backUrlString) {
      setBackUrl(backUrlString);
    }
    return () => {
      setTitle('');
      setRightText('');
      setUse(true);
      setBackUrl(null);
    };
  }, [
    backUrlString,
    onRightTextClick,
    rightText,
    setBackUrl,
    setRightText,
    setTitle,
    setUse,
    title,
    use,
  ]);
};
