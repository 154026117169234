import React from 'react';

import { StoreSignupForm } from '@service/store/components/StoreSignupForm';
import Logo from 'src/assets/logo/aplusTop.png';

import { useStoreTitleHook } from '../hooks/useStoreTitleHook';

//! pseudocode
/**
 * @components
 * @shared
 *! - Input(TextField)
 *! - Button
 * @structure
 *! - Logo
 *! - Title
 *! - SubmitForm
 *! |- InputSet
 *! ||- Id
 *! ||- Password
 *! |- ButtonSet
 *! ||- PersistCheckBox
 *! ||- SubmitButton
 *! ||- FindIdButton
 *! ||- SignupButton
 */

const StoreSignupPage: React.FC = () => {
  useStoreTitleHook('회원가입');

  return (
    <React.Fragment>
      {/* Title */}
      <div className="mt-8 pl-4 pr-4">
        <div className="px-3 pt-4 pb-4 shadow-smd theme-bg-JM05">
          {/* Logo */}
          <div className="grid place-items-center">
            <img src={Logo} className="h-[60px]"></img>
          </div>
          {/* SubmitForm */}
          <StoreSignupForm />
        </div>
      </div>
    </React.Fragment>
  );
};

export { StoreSignupPage };
