//* seller용 배송상태 관리 카드
import { useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { orderContentString as shopOrderForSellerContentString } from '@shared/api/shopOrder/shopOrder.utils';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { DeliveredImageUploader } from '@shared/components/ImageUploader';
import { DeliveryProgressIndicator } from '@shared/containers/DeliveryProgressIndicator/DeliveryProgressIndicator';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { DeliveryState, PaymentState } from '@shared/types';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import { formatDate } from '@shared/utils/formatDate';
import { Image, Tag } from 'antd';

import { DeliveryContentCard } from './DeliveryContentCard';
import { DeliveryStatusManageButton } from './DeliveryStatusManageButton';
import { OrderBugoDetailEditModal } from './OrderBugoDetailEditModal';
import { ShopOrderDetailInfoCard } from './ShopOrderDetailInfoCard';

interface DeliveryManageCardProps {
  order: ShopOrderForSeller;
}

export const DeliveryManageCard = function DeliveryManageCard({
  order,
}: DeliveryManageCardProps) {
  const [detailOpen, setDetailOpen] = useState(false);
  const [contentOpen, setContentOpen] = useState(false);
  const deliveryDetail = order.deliveryDetail;
  const shopItem = order.orderDetail.shopItem;
  const openId = `deliveryManageCard-${order._id}`;
  const setDeliveredImageUpload = useSetRecoilState(simpleOpenAtomFamily(openId));
  const newTag = (order: ShopOrderForSeller) => {
    if (
      order.deliveryDetail.status === DeliveryState.BEFORE_PREPARING &&
      [PaymentState.PAID, PaymentState.CONFIRMED].includes(order.paymentDetail.status)
    ) {
      return <Tag className="filled-warn border-0 py-1 font-bold opacity-80">NEW</Tag>;
    }
  };

  const agencyTag = (order: ShopOrderForSeller) => {
    return (
      <Tag className="filled-gray-800 border-0 py-1 font-bold opacity-80">
        {order.bugo?.bugoBrand?.bugoAgency?.nickName}
      </Tag>
    );
  };

  const editOpenId = `deliveryManageCard-edit-phrase-${order._id}`;
  const setEditOpen = useSetRecoilState(simpleOpenAtomFamily(editOpenId));

  const { UpFromBracket, Copy } = Icon();

  return (
    <>
      <DeliveredImageUploader order={order} openId={openId} />
      <div key={order._id} className="relative min-w-[320px] font-sans">
        <div className="absolute top-0 right-0">
          {agencyTag(order)} {newTag(order)}
        </div>
        <div className="h-auto w-full overflow-hidden rounded-lg border shadow">
          <div className="p-5 pb-3">
            <div className="flex items-center">
              <div className="ml-2 w-0 flex-1">
                <p className="flex-wrap truncate text-base font-medium  text-gray-900">
                  {shopItem.name ?? shopItem.shopItemInfo.name}
                </p>
                <p className="flex-wrap truncate text-base font-medium  text-gray-900">
                  {shopItem.shopItemInfo.priceWhole.toLocaleString()}원{' '}
                  {order.paymentDetail.method?.indexOf('계좌') !== -1 ? '#' : ''}
                </p>
                <div className="">
                  <p className="whitespace-pre-wrap break-keep text-sm text-gray-700">
                    {deliveryDetail.receiverAddress}
                  </p>
                  <div className="flex items-center justify-between">
                    <div>
                      <p className="text-sm font-bold text-gray-700">
                        보내는분: {order.bugoDetail.senderPhrase}
                      </p>
                      <p className="text-sm font-bold text-gray-700">
                        조의문구: {order.bugoDetail.condolencePhrase}
                      </p>
                    </div>
                    <div>
                      <Button
                        className="shadow-sm"
                        onClick={() => {
                          setEditOpen(true);
                        }}
                      >
                        문구변경
                      </Button>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-1 whitespace-pre">
                    <p className="text-sm text-gray-700">
                      결제:{' '}
                      {formatDate(order.paymentDetail.purchasedAt, {
                        dateSeparater: '.',
                        contains: {
                          year: false,
                          day: true,
                          hours: true,
                          minutes: true,
                        },
                      })}
                    </p>
                    {order.deliveryDetail?.estimatedDeliveredAt && (
                      <p className="text-sm text-gray-700">
                        예상:{' '}
                        {formatDate(order.deliveryDetail.estimatedDeliveredAt, {
                          dateSeparater: '.',
                          contains: {
                            year: false,
                            day: true,
                            hours: true,
                            minutes: true,
                          },
                        })}
                      </p>
                    )}
                  </div>
                  <div className="grid grid-cols-2 gap-1 whitespace-pre">
                    <p className="text-sm text-gray-700">
                      입실:{' '}
                      {formatDate(order.bugo?.fevent?.deceasedInfo?.enterance?.date, {
                        dateSeparater: '.',
                        contains: {
                          year: false,
                          day: true,
                          hours: true,
                          minutes: true,
                        },
                      })}
                    </p>
                    <p className="text-sm text-gray-700">
                      발인:{' '}
                      {formatDate(order.bugo?.fevent?.deceasedInfo?.coffinOut?.date, {
                        dateSeparater: '.',
                        contains: {
                          year: false,
                          day: true,
                          hours: true,
                          minutes: true,
                        },
                      })}
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-1 whitespace-pre">
                    {order.paymentDetail?.canceledAt && (
                      <p className="text-sm text-gray-700">
                        취소:{' '}
                        {formatDate(order.paymentDetail.canceledAt, {
                          dateSeparater: '.',
                          contains: {
                            year: false,
                            day: true,
                            hours: true,
                            minutes: true,
                          },
                        })}
                      </p>
                    )}
                    {order.paymentDetail?.cancelReason && (
                      <p className="text-sm text-gray-700">
                        사유: {order.paymentDetail?.cancelReason}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <DeliveryProgressIndicator order={order} size="small" />
            <div className="flex space-x-3 pt-3">
              <DeliveryStatusManageButton shopOrder={order} />
            </div>
            <div className="flex justify-between pt-3">
              {order.deliveryDetail.deliveredImage ? (
                <div className="w-1/2">
                  <Image
                    src={fileBucketUrl(order.deliveryDetail.deliveredImage?.url, 'w200')}
                    alt="배송 완료사진"
                    preview={{
                      src: fileBucketUrl(order.deliveryDetail.deliveredImage?.url, 'raw'),
                    }}
                  />
                </div>
              ) : (
                <div></div>
              )}
              <div
                className="flex h-fit cursor-pointer gap-1 rounded-lg border border-gray-400 p-1.5"
                onClick={() => {
                  setDeliveredImageUpload(true);
                }}
              >
                <p className="font-medium">배송 완료 사진</p>
                <UpFromBracket className="wh-5 fill-gray-700" />
              </div>
            </div>
          </div>
          <div className="space-y-4 bg-gray-50 px-5 py-3">
            <div className="flex items-center justify-between text-sm">
              <button
                className="font-medium text-cyan-700 hover:text-cyan-900"
                onClick={() => {
                  setDetailOpen(!detailOpen);
                }}
              >
                View all
              </button>
              <button
                className="font-medium text-cyan-700 hover:text-cyan-900"
                onClick={() => {
                  setContentOpen(!contentOpen);
                }}
              >
                발주용 내용 보기
              </button>
            </div>
            {detailOpen && (
              <div>
                <div className="py-2">
                  <p className="-mt-4 font-medium">주문번호: {order._id}</p>
                </div>
                <ShopOrderDetailInfoCard shopOrder={order} />
              </div>
            )}
            {contentOpen && (
              <div>
                <div className="flex justify-between py-2 px-2 font-medium">
                  <p>발주내용</p>
                  <div className="px-1">
                    <Button
                      className="center-box button-icon gap-0.5"
                      onClick={async (): Promise<void> => {
                        navigator.clipboard.writeText(
                          shopOrderForSellerContentString(order),
                        );
                        alert('복사 완료');
                      }}
                    >
                      <Copy className="wh-4" />
                      <p className="keep-all-preline text-sm">복사</p>
                    </Button>
                  </div>
                </div>
                <DeliveryContentCard shopOrder={order} />
              </div>
            )}
          </div>
        </div>
      </div>
      <OrderBugoDetailEditModal openId={editOpenId} order={order} />
    </>
  );
};
