import { Image } from '@shared/api/media/media.interface';

export enum BugoBrandFormInputsEnum {
  CorpName = 'corpName',
  NickName = 'nickName',
  PhoneNumber = 'phoneNumber',
  Address = 'address',
  HeaderImage = 'headerImage',
  FooterImage = 'footerImage',
  Rebate = 'rebate',
  Theme = 'theme',
  FrontUrl = 'frontUrl',
  RegAlimTalkWebDomain = 'regAlimTalkWebDomain',
  Regions = 'regions',
  TeamTypes = 'teamTyeps',
  TeamNames = 'teamNames',
  HomePageUrl = 'homepageUrl',
  KakaoAppJavascriptKey = 'kakaoAppJavascriptKey',
  KakaoTemplateBugoButtonId = 'kakaoTemplateBugoButtonId',
  ShareRebateWithWorker = 'shareRebateWithWorker',
  Shop = 'shop',
}

export interface BugoBrandFormInputs {
  [BugoBrandFormInputsEnum.CorpName]: string;
  [BugoBrandFormInputsEnum.NickName]: string;
  [BugoBrandFormInputsEnum.PhoneNumber]: string;
  [BugoBrandFormInputsEnum.Address]: string;
  [BugoBrandFormInputsEnum.HeaderImage]: Image;
  [BugoBrandFormInputsEnum.FooterImage]?: Image;
  [BugoBrandFormInputsEnum.Rebate]: string;
  [BugoBrandFormInputsEnum.Theme]: string;
  [BugoBrandFormInputsEnum.FrontUrl]: string;
  [BugoBrandFormInputsEnum.RegAlimTalkWebDomain]: string;
  [BugoBrandFormInputsEnum.Regions]: string[];
  [BugoBrandFormInputsEnum.TeamTypes]: string[];
  [BugoBrandFormInputsEnum.TeamNames]: string[];
  [BugoBrandFormInputsEnum.HomePageUrl]: string;
  [BugoBrandFormInputsEnum.KakaoAppJavascriptKey]: string;
  [BugoBrandFormInputsEnum.KakaoTemplateBugoButtonId]: string;
  [BugoBrandFormInputsEnum.ShareRebateWithWorker]?: boolean;
  [BugoBrandFormInputsEnum.Shop]: string;
}
