import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { Shop, ShopData } from './shop.interface';

export const sellerShopListStatusAtom = atom<QueryStatus>({
  key: 'sellerShopListStatusAtom',
  default: 'idle',
});

export const sellerShopListDataAtom = atom<Shop[] | null>({
  key: 'sellerShopListDataAtom',
  default: null,
});

export const sellerShopStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'sellerShopStatusAtomFamily',
  default: 'idle',
});

export const sellerShopDataAtomFamily = atomFamily<ShopData | null, string>({
  key: 'sellerShopDataAtomFamily',
  default: null,
});
