import { FC } from 'react';

import { useRecoilState } from 'recoil';

import { Image } from '@shared/api/media/media.interface';
import Modal from '@shared/components/Modal';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { Carousel } from 'antd';

interface Props {
  openId: string;
  detailImageList: Image[];
}

export const DetailImageDisplayModal: FC<Props> = function DetailImageDisplayModal({
  openId,
  detailImageList,
}) {
  const [open, setOpen] = useRecoilState(
    simpleOpenAtomFamily(`detailImageDisplay-${openId}`),
  );

  return (
    <Modal openId={`detailImageDisplay-${openId}`}>
      <Carousel className="" swipeToSlide draggable>
        {detailImageList.map((item: { url: string }, index: number) => {
          return (
            <div className="h-80 w-full" key={index}>
              <img
                src={`${process.env.REACT_APP_BUCKET_URL}/w400/${item.url}`}
                alt="detailimg"
                className="mx-auto h-full object-contain"
              />
            </div>
          );
        })}
      </Carousel>
    </Modal>
  );
};
