import { FC, HTMLAttributes } from 'react';

import { useRecoilValue } from 'recoil';

import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { CustomCallback } from '@shared/types';
import { mainColorGenerator } from '@shared/utils/mainColorGenerator';

import { Button } from './Button';
import UnClosableModal from './UnClosableModal';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  title: string;
  description?: string;
  cancleBtnText?: string;
  confirmBtnText?: string;
  cancleBtnCallback?: CustomCallback<void, void>;
  confirmBtnCallback?: CustomCallback<void, void>;
}

const UnClosableConfirmModal: FC<IProps> = ({
  open,
  title,
  id,
  description,
  cancleBtnText = '취소',
  confirmBtnText = '확인',
  cancleBtnCallback,
  confirmBtnCallback,
}: IProps) => {
  const customTheme = useRecoilValue(customThemeAtom);

  const onCancleClick = () => {
    if (cancleBtnCallback) cancleBtnCallback();
  };

  const onConfirmClick = () => {
    if (confirmBtnCallback) confirmBtnCallback();
  };

  return (
    <UnClosableModal open={open} size={`max-w-max`} id={id}>
      <div className="space-y-4 text-center font-sans text-sm">
        <h4 className="whitespace-pre-line break-keep text-base font-bold">{title}</h4>
        <p className="whitespace-pre-line break-keep text-gray-500">{description}</p>
        {/* Buttons */}
        <div className="grid grid-cols-2 gap-2 pt-4 text-white">
          <Button
            text={cancleBtnText}
            className="h-11 w-32 rounded-none bg-gray-300 font-medium shadow-none"
            onClick={onCancleClick}
          />
          <Button
            text={confirmBtnText}
            onClick={onConfirmClick}
            className="h-11 w-32 rounded-none font-medium shadow-none"
            style={{
              backgroundColor: mainColorGenerator(customTheme),
            }}
          />
        </div>
      </div>
    </UnClosableModal>
  );
};

export default UnClosableConfirmModal;
