//* 상주 개인정보 동의
import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { BugoBrand } from '@shared/api/bugoBrand/bugoBrand.interface';
import AgreementContentBlock from '@shared/components/AgreementContentBlock';
import { Button } from '@shared/components/Button';
import UnClosableModal from '@shared/components/UnClosableModal';
import { CheckBox } from '@shared/containers';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { CustomCallback } from '@shared/types';
import { mainColorGenerator } from '@shared/utils/mainColorGenerator';

interface Props {
  open: boolean;
  bugoBrand?: BugoBrand;
  cancelBtnCallback?: CustomCallback<void, void>;
  agreeBtnCallback?: CustomCallback<void, void>;
  agreeBtnText?: string;
}

const contentDesc1 = `• 항목 : 성명, 연락처
• 수집·이용 목적 : 부고문자 발급
• 보유·이용기간 : 발인 후 5일

※ 위의 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 부고문자 서비스 이용에 제한을 받을 수 있습니다.

※ 위 내용을 확인하였으며, 개인정보 제공에 동의합니다.`;

const contentDesc2 = `• 항목 : 계좌번호
• 수집·이용 목적 : 부고문자 발급
• 보유·이용기간 : 발인 후 5일

※ 위의 금융정보 수집·이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 부고문자 서비스 이용에 제한을 받을 수 있습니다.

※ 위 내용을 확인하였으며, 개인정보 제공에 동의합니다.`;

export const MemberPrivacyAgreementModal: FC<Props> =
  function MemberPrivacyAgreementModal({
    open,
    bugoBrand,
    cancelBtnCallback,
    agreeBtnCallback,
    agreeBtnText = '확인',
  }: Props) {
    const customTheme = useRecoilValue(customThemeAtom);
    const [isChecked1, setIsChecked1] = useState<boolean>(false);
    const [isChecked2, setIsChecked2] = useState<boolean>(false);

    const isAllChecked = useMemo(() => {
      return isChecked1 && isChecked2;
    }, [isChecked1, isChecked2]);

    const onCancleClick = () => {
      if (cancelBtnCallback) return cancelBtnCallback();
    };

    const onAllAgreeChange = (checked: boolean) => {
      setIsChecked1(checked);
      setIsChecked2(checked);
    };

    const onAgreeClick = () => {
      if (isAllChecked && agreeBtnCallback) return agreeBtnCallback();
    };

    return (
      <UnClosableModal open={open} size={'max-w-[340px]'}>
        <div className="space-y-4 font-sans text-xs">
          <h3 className="text-center text-base font-bold">
            부고문자 서비스 사용을 위한 <br />
            개인정보 수집 · 이용 동의서
          </h3>
          <p className="break-keep text-xs leading-[13px]">
            {bugoBrand?.bugoAgency?.corpName ?? '(주)가온프라임'}의 부고문자 서비스 사용을
            위하여 아래와 같이 개인정보 및 금융정보를 수집·이용합니다. 내용을 자세히
            읽으신 후 동의 여부를 결정하여 주십시오.
          </p>
          {/* Contens */}
          <div className="space-y-3">
            <div className="flex items-center justify-between">
              <p className="text-sm font-bold">전체동의</p>
              <div className="pr-2">
                <CheckBox checked={isAllChecked} onCheckedChange={onAllAgreeChange} />
              </div>
            </div>
            <div className="py-1">
              <div className="border-b-[1.5px] theme-border-3"></div>
            </div>
            <AgreementContentBlock
              title={'개인정보 수집 · 이용'}
              description={contentDesc1}
              isChecked={isChecked1}
              setIsChecked={setIsChecked1}
            />
            <AgreementContentBlock
              title={'금융정보 수집 · 이용'}
              description={contentDesc2}
              isChecked={isChecked2}
              setIsChecked={setIsChecked2}
            />
          </div>
          {/* Buttons */}
          <div className="pt-4 text-white">
            <Button
              text={agreeBtnText}
              onClick={onAgreeClick}
              className="button-rectangle font-medium transition-colors"
              style={{
                backgroundColor: isAllChecked
                  ? mainColorGenerator(customTheme)
                  : '#d1d5db', // gray-300
              }}
            />
          </div>
        </div>
      </UnClosableModal>
    );
  };
