import { QueryStatus } from 'react-query';

export enum CallOrderStatus {
  Todo = 'Todo',
  Done = 'Done',
}

export type CallOrder<Bugo = string, Shop = string, User = string> = {
  _id: string;
  bugo: Bugo;
  shop: Shop;
  storeSellerWorker: User;
  status: CallOrderStatus;
  createdAt: Date;
  updatedAt: Date;
};

export type CallOrderState<Bugo = string, Shop = string, User = string> = {
  status: QueryStatus;
  data: CallOrder<Bugo, Shop, User>[] | null;
};

export type CallOrderCreateDto = Omit<
  CallOrder,
  '_id' | 'storeSellerWorker' | 'createdAt' | 'updatedAt'
>;

export type CallOrderPatchDto = Omit<
  CallOrder,
  'bugo' | 'shop' | 'createdAt' | 'updatedAt'
>;
