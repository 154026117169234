import { FC, useEffect, useState } from 'react';

import { useRecoilState } from 'recoil';

import { FuneralHomeInfoEmbed } from '@shared/api/funeralHomeInfoRequest/funeralHomeInfoRequest.interface';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import FuneralHomeInfoSearchModal from '@shared/containers/FuneralHomeInfoSearcher/FuneralHomeInfoSearchModal';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { CustomCallback } from '@shared/types';
import { useId } from 'react-id-generator';

interface IProps {
  value: string;
  onValueChange: CustomCallback<string, void>;
  onEmbedSelect: (
    funeralHomeInfoEmbed: FuneralHomeInfoEmbed,
    funeralHomeInfoId: string,
  ) => void;
}

const FuneralHomeInfoSearcher: FC<IProps> = ({
  value: defaultValue,
  onValueChange,
  onEmbedSelect,
}: IProps) => {
  const { MagnifyingGlassRegular } = Icon();
  const [id] = useId(1, 'searchFuneralHome');
  const openId = `modal-${id}`;
  const [open, setOpen] = useRecoilState(simpleOpenAtomFamily(openId));
  const [funeralHomeInfoName, setFuneralHomeInfoName] = useState<string>(defaultValue);

  useEffect(() => {
    setFuneralHomeInfoName(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Button
        type="button"
        onClick={() => setOpen(true)}
        className="button-rectangle textfield relative block border-warn text-left theme-bg-1"
      >
        <p className={`${funeralHomeInfoName ? '' : 'theme-text-6'}`}>
          {funeralHomeInfoName ? funeralHomeInfoName : '장례식장 검색'}
        </p>
        <MagnifyingGlassRegular className="absolute top-0 right-0 h-full p-[11px]" />
      </Button>
      {open && (
        <FuneralHomeInfoSearchModal
          openId={openId}
          onValueChange={onValueChange}
          onEmbedSelect={onEmbedSelect}
          setFuneralHomeInfoName={setFuneralHomeInfoName}
        />
      )}
    </>
  );
};

export default FuneralHomeInfoSearcher;
