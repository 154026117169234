import { FC, useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bugoBrandListHookUrl } from '@shared/api/bugoBrand/bugoBrand.hook';
import { BugoBrandData } from '@shared/api/bugoBrand/bugoBrand.interface';
import { postImage } from '@shared/api/uploadImage';
import { Button } from '@shared/components/Button';
import { SingleImageUploader } from '@shared/components/ImageUploader';
import { Label } from '@shared/components/Label';
import { LoadingModal } from '@shared/components/LoadingModal';
import Modal from '@shared/components/Modal';
import { TextField } from '@shared/components/TextField';
import Select from '@shared/containers/Select/Select';
import {
  notificateError,
  notificateSuccess,
} from '@shared/plugIn/ant-notification/ant-notifiaction';
import { defaultApiMutationOptions } from '@shared/plugIn/apiMutation';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { uploadFileAtomFamily } from '@shared/state/atom/file.atom';
import { notificationInstanceAtom } from '@shared/state/atom/notification.atom';
import { useAuth } from '@shared/state/hooks/useAuth';
import { fileBucketUrl } from '@shared/utils/fileUtils';
import _ from 'lodash';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';

import { onBugoBrandMultiAgencyUpsert } from './AdminBugoBrandMultiAgency.util';
import {
  BugoBrandMultiAgencyFormInputs,
  BugoBrandMultiAgencyFormInputsEnum,
} from './BugoBrandMultiAgencyForm.interface';

interface Props {
  openId: string;
  bugoBrand: BugoBrandData;
  multiAgencyIndex?: number;
}

//* 관리자용 BugoBrand의 행사용 bugoAgencyList 추가 or 수정 시 사용할 Form
export const AdminBugoBrandMultiAgencyFormModal: FC<Props> = function ({
  openId,
  bugoBrand,
  multiAgencyIndex,
}: Props) {
  const { assetRole } = useAuth();

  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  const notifiacationInstance = useRecoilValue(notificationInstanceAtom);

  const bugoMultiAgency =
    !_.isNil(multiAgencyIndex) && bugoBrand.bugoAgencyList
      ? bugoBrand.bugoAgencyList[multiAgencyIndex]
      : undefined;

  const headerImageAtomId = `bugoBrandMultiAgencyForm-headerImage-${bugoBrand._id}-${multiAgencyIndex}`;
  const footerImageAtomId = `bugoBrandMultiAgencyForm-footerImage-${bugoBrand._id}-${multiAgencyIndex}`;
  const uploadHeaderImageFile = useRecoilValue(uploadFileAtomFamily(headerImageAtomId));
  const uploadFooterImageFile = useRecoilValue(uploadFileAtomFamily(footerImageAtomId));
  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(defaultApiMutationOptions, {
    onSuccess: () => {
      notificateSuccess(notifiacationInstance, '저장 완료');
      queryClient.invalidateQueries(bugoBrandListHookUrl);
    },
    onError: () => {
      notificateError(notifiacationInstance, '상조정보 저장 실패');
    },
  });

  const { register, handleSubmit, control, reset } =
    useForm<BugoBrandMultiAgencyFormInputs>({
      defaultValues: {
        corpName: bugoMultiAgency?.corpName,
        nickName: bugoMultiAgency?.nickName,
        phoneNumber: bugoMultiAgency?.phoneNumber,
        address: bugoMultiAgency?.address,
        headerImage: bugoMultiAgency?.headerImage,
        footerImage: bugoMultiAgency?.footerImage,
        theme: bugoMultiAgency?.theme,
        frontUrl: bugoMultiAgency?.frontUrl,
        regAlimTalkWebDomain: bugoMultiAgency?.regAlimTalkWebDomain,
        homepageUrl: bugoMultiAgency?.homepageUrl,
        kakaoAppJavascriptKey: bugoMultiAgency?.kakaoAppJavascriptKey,
        kakaoTemplateBugoButtonId: bugoMultiAgency?.kakaoTemplateBugoButtonId,
      },
    });

  useEffect(() => {
    reset({
      corpName: bugoMultiAgency?.corpName,
      nickName: bugoMultiAgency?.nickName,
      phoneNumber: bugoMultiAgency?.phoneNumber,
      address: bugoMultiAgency?.address,
      headerImage: bugoMultiAgency?.headerImage,
      footerImage: bugoMultiAgency?.footerImage,
      theme: bugoMultiAgency?.theme,
      frontUrl: bugoMultiAgency?.frontUrl,
      regAlimTalkWebDomain: bugoMultiAgency?.regAlimTalkWebDomain,
      homepageUrl: bugoMultiAgency?.homepageUrl,
      kakaoAppJavascriptKey: bugoMultiAgency?.kakaoAppJavascriptKey,
      kakaoTemplateBugoButtonId: bugoMultiAgency?.kakaoTemplateBugoButtonId,
    });
  }, [bugoMultiAgency, reset]);

  const onSubmit: SubmitHandler<BugoBrandMultiAgencyFormInputs> = async (data) => {
    try {
      //1. 이미지가 있을 경우
      if (uploadHeaderImageFile) {
        const headerImage = await postImage(uploadHeaderImageFile, assetRole());
        data[BugoBrandMultiAgencyFormInputsEnum.HeaderImage] = headerImage;
      }

      if (uploadFooterImageFile) {
        const footerImage = await postImage(uploadFooterImageFile, assetRole());
        data[BugoBrandMultiAgencyFormInputsEnum.FooterImage] = footerImage;
      }

      const ret = await onBugoBrandMultiAgencyUpsert(
        data,
        bugoBrand._id,
        bugoBrand.bugoAgencyList?.map((bugoMultiAgency) => {
          return {
            ...bugoMultiAgency,
            headerImage: bugoMultiAgency.headerImage?._id,
            footerImage: bugoMultiAgency.footerImage?._id,
          };
        }) ?? [],
        multiAgencyIndex,
      );
      console.log('ret', ret);
    } catch (err: any) {
      console.error(err);
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // 폼 제출 방지
      // 폼 제출 대신 다른 동작 실행
    }
  };
  return (
    <Modal openId={openId}>
      <p>{multiAgencyIndex ? '수정' : '추가'}</p>
      <form onKeyDown={handleKeyDown}>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          <TextField
            className="w-full"
            {...register(BugoBrandMultiAgencyFormInputsEnum.CorpName)}
            label={'상조회사명'}
            placeholder="상조회사명"
          ></TextField>
          <TextField
            className="w-full"
            {...register(BugoBrandMultiAgencyFormInputsEnum.NickName)}
            label={'별칭'}
            placeholder="별칭"
          ></TextField>
          <TextField
            placeholder="전화번호"
            className="w-full"
            label="전화번호"
            {...register(BugoBrandMultiAgencyFormInputsEnum.PhoneNumber)}
          />
          <TextField
            className="w-full"
            label="주소"
            {...register(BugoBrandMultiAgencyFormInputsEnum.Address)}
            placeholder="주소"
          />
          <div className="label-col">
            <Label>로고</Label>
            <SingleImageUploader
              uploadFileAtomId={headerImageAtomId}
              formerImageUrl={
                bugoMultiAgency?.headerImage?.url
                  ? fileBucketUrl(bugoMultiAgency?.headerImage?.url, 'w400')
                  : undefined
              }
            />
          </div>
          <div className="label-col">
            <Label>하단배너</Label>
            <SingleImageUploader
              uploadFileAtomId={footerImageAtomId}
              formerImageUrl={
                bugoMultiAgency?.footerImage?.url
                  ? fileBucketUrl(bugoMultiAgency?.footerImage.url, 'w400')
                  : undefined
              }
            />
          </div>
          <div className="label-col">
            <Label>테마</Label>
            <Select
              placeholder="테마"
              optionList={[
                { value: 'Default', label: '기본' },
                { value: 'Preed', label: '프리드' },
                { value: 'Dark', label: '다크' },
                { value: 'DaeMyung', label: '대명' },
              ]}
              control={control}
              name={BugoBrandMultiAgencyFormInputsEnum.Theme}
            ></Select>
          </div>
          <TextField
            className="w-full"
            label="URL"
            placeholder="URL"
            {...register(BugoBrandMultiAgencyFormInputsEnum.FrontUrl)}
          />
          <TextField
            className="w-full"
            label="알림도메인"
            {...register(BugoBrandMultiAgencyFormInputsEnum.RegAlimTalkWebDomain)}
          />
          <div className="col-span-2">
            <TextField
              className="w-full"
              label="홈페이지URL"
              placeholder="홈페이지URL"
              {...register(BugoBrandMultiAgencyFormInputsEnum.HomePageUrl)}
            />
          </div>
          <TextField
            className="w-full"
            label="kakaoAppKey"
            placeholder="kakaoAppKey"
            {...register(BugoBrandMultiAgencyFormInputsEnum.KakaoAppJavascriptKey)}
          />
          <TextField
            className="w-full"
            label="부고버튼Id"
            placeholder="부고버튼Id"
            {...register(BugoBrandMultiAgencyFormInputsEnum.KakaoTemplateBugoButtonId)}
          />
        </div>
        <div className="flex flex-col-reverse gap-4 pt-6 sm:grid sm:grid-cols-2 sm:gap-4">
          <Button
            type="button"
            className="button-rectangle w-full theme-text-3 theme-bg-12"
            onClick={() => {
              setOpen(false);
            }}
          >
            취소
          </Button>
          <Button
            disabled={isLoading}
            className="button-rectangle w-full theme-text-3 theme-bg-main"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              mutate(handleSubmit(onSubmit)(e));
            }}
          >
            저장
          </Button>
        </div>
      </form>
      <LoadingModal open={isLoading} title={'저장중'} />
    </Modal>
  );
};
