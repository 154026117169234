import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { Image } from '@shared/api/media/media.interface';
import { Rebate } from '@shared/api/rebate/rebate.interface';
import { ShopItem } from '@shared/api/shopItem/shopItem.interface';
import { ShopItemInfo } from '@shared/api/shopItemInfo/shopItemInfo.interface';
import { Button } from '@shared/components/Button';
import { simpleOpenAtomFamily } from '@shared/state/atom';
import { UpsertMode } from '@shared/types';

import { ShopItemUpsertModal } from './ShopItemUpsertModal';

interface Props {
  shopItem: ShopItem<ShopItemInfo, string, Rebate>;
  shopItemInfo?: ShopItemInfo<string, Image, Image>;
}

export const ShopItemManageCard: FC<Props> = function ShopItemManageCard({
  shopItem,
  shopItemInfo,
}: Props) {
  const openId = `shopItemManage-${shopItem._id}`;
  const setOpen = useSetRecoilState(simpleOpenAtomFamily(openId));

  return (
    <div className="min-w-[250px] rounded-lg border-[1.5px] p-5 font-sans font-medium shadow-smd">
      <p>id: {shopItem._id}</p>
      <p>상품명: {shopItem.shopItemInfo.name}</p>
      <p>리베이트: {shopItem.rebate.name}</p>
      <p>정가: {shopItem.priceRetailShow}</p>
      <p>할인판매가: {shopItem.priceRetail}</p>
      <p>도매가: {shopItem.shopItemInfo.priceWhole}</p>
      <p>리베이트가: {shopItem.priceRebate}</p>
      <p>리베이트가(상조): {shopItem.priceRebateAgency}</p>
      <p>리베이트가(지도사): {shopItem.priceRebateWorker}</p>

      <Button
        className="button-rectangle mt-4 h-10 rounded-md theme-text-3 theme-bg-main"
        onClick={() => {
          setOpen(true);
        }}
      >
        수정하기
      </Button>
      <ShopItemUpsertModal
        openId={openId}
        mode={UpsertMode.Update}
        shopItem={shopItem}
        shopItemInfo={shopItemInfo}
      ></ShopItemUpsertModal>
    </div>
  );
};
