import { atom, atomFamily } from 'recoil';

import { QueryStatus } from 'react-query';

import { User, WorkerUser } from './user.interface';

export const userStatusAtom = atom<QueryStatus>({
  key: 'userStatusAtom',
  default: 'idle',
});

export const userDataAtom = atom<User | null>({
  key: 'userDataAtom',
  default: null,
});

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook
export const workerUserListByBugoBrandStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'workerUserListByBugoBrandStatusAtomFamily',
  default: 'idle',
});

export const workerUserListByBugoBrandDataAtomFamily = atomFamily<
  WorkerUser[] | null,
  string
>({
  key: 'workerUserListByBugoBrandDataAtomFamily',
  default: null,
});

//* bugoBrand 별로 해당 지도사님들을 불러오는 hook (refactoring)
export const workerUserAgencyAdminStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'workerUserAgencyAdminStatusAtomFamily',
  default: 'idle',
});

export const workerUserAgencyAdminDataAtomFamily = atomFamily<
  WorkerUser[] | null,
  string
>({
  key: 'workerUserAgencyAdminDataAtomFamily',
  default: null,
});

export const workerUserStatusAtomFamily = atomFamily<QueryStatus, string>({
  key: 'workerUserStatusAtomFamily',
  default: 'idle',
});

export const workerUserDataAtomFamily = atomFamily<WorkerUser | null, string>({
  key: 'workerUserDataAtomFamily',
  default: null,
});

export const workerUserListByBugoBrandLightStatusAtomFamily = atomFamily<
  QueryStatus,
  string
>({
  key: 'workerUserListByBugoBrandLightStatusAtomFamily',
  default: 'idle',
});

export const workerUserListByBugoBrandLightDataAtomFamily = atomFamily<
  User[] | null,
  string
>({
  key: 'workerUserListByBugoBrandLightDataAtomFamily',
  default: null,
});
