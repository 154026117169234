import { api_ } from '@shared/plugIn/axios';
import { LowerRole } from '@shared/types';

import { Fevent, FeventCreate, FeventUpdate } from './fevent.interface';

const createFevent = async (feventCreate: FeventCreate, bugoRole: LowerRole) => {
  const { data: fevent } = await api_.post<Fevent>(`/${bugoRole}/fevent`, feventCreate);
  return fevent;
};

const updateFevent = async (feventUpdate: FeventUpdate, bugoRole: LowerRole) => {
  const { data: fevent } = await api_.patch(
    `/${bugoRole}/fevent/${feventUpdate._id}`,
    feventUpdate,
  );
  return fevent;
};

const deleteFevent = async (feventId: string, bugoRole: LowerRole) => {
  await api_.delete(`/${bugoRole}/fevent/${feventId}`);
};

export { createFevent, updateFevent, deleteFevent };
