//* 계좌 인증 필요, 계좌인증 완료 여부 보여주는 태그
import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { RoundedTag } from '@shared/components/RoundedTag';
import { simpleOpenAtomFamily } from '@shared/state/atom';

interface Props {
  isVerified: boolean;
  foldId: string;
}

export const CashAccountApprovedTag: FC<Props> = function CashAccountApprovedTag({
  isVerified,
  foldId,
}: Props) {
  const setFold = useSetRecoilState(simpleOpenAtomFamily(foldId));

  if (isVerified) {
    return <RoundedTag text="계좌인증완료" className="theme-text-1 theme-bg-8" />;
  } else {
    return (
      <div
        onClick={() => {
          setFold(false);
        }}
      >
        <RoundedTag
          text="계좌인증필요"
          className="cursor-pointer border theme-text-1 theme-border-main"
        />
      </div>
    );
  }
};
