import { FC, useCallback } from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { BugoStatRangeQueryEnum } from '../page/AgencyAdminBugoStatMobilePage';

interface IProps {
  bugoLenList: (number | null)[];
  bugoBrandId?: string;
  navigateDisabled?: boolean;
}

const RegisteredBugoStatusBar: FC<IProps> = ({
  bugoLenList,
  bugoBrandId,
  navigateDisabled = false,
}: IProps) => {
  const navigate = useNavigate();

  const onClick = useCallback(
    (range: BugoStatRangeQueryEnum) => {
      if (navigateDisabled) return;
      navigate(`/bugo-brand/${bugoBrandId}/bugo-stat?range=${range}`);
    },
    [bugoBrandId, navigate, navigateDisabled],
  );

  return (
    <div className="flex items-center py-3 text-xs font-bold theme-bg-1">
      {/* 금일 */}
      <button onClick={() => onClick(BugoStatRangeQueryEnum.Day)} className="flex-1 pb-1">
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">금일</h6>
          <p className="w-full border-r py-3 text-center">
            {_.isNil(bugoLenList[0]) ? '-' : bugoLenList[0]}건
          </p>
        </div>
      </button>
      {/* 금주 */}
      <button
        onClick={() => onClick(BugoStatRangeQueryEnum.Week)}
        className="flex-1 pb-1"
      >
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">금주</h6>
          <p className="w-full border-r py-3 text-center">
            {_.isNil(bugoLenList[1]) ? '-' : bugoLenList[1]}건
          </p>
        </div>
      </button>
      {/* 금월 */}
      <button
        onClick={() => onClick(BugoStatRangeQueryEnum.Month)}
        className="flex-1 pb-1"
      >
        <div className="flex flex-col items-center space-y-1">
          <h6 className="theme-text-main">금월</h6>
          <p className="w-full py-3 text-center">
            {_.isNil(bugoLenList[2]) ? '-' : bugoLenList[2]}건
          </p>
        </div>
      </button>
    </div>
  );
};

export default RegisteredBugoStatusBar;
