import { selector, selectorFamily } from 'recoil';

import {
  ShopOrderListAgencyAdminState,
  shopOrderListAgencyAdminDataAtom,
  shopOrderListAgencyAdminStatusAtom,
  shopOrderListByBugoDataAtom,
  shopOrderListByBugoStatusAtom,
  shopOrderListForAgencyAdminDataAtom,
  shopOrderListForAgencyAdminStatusAtom,
  shopOrderListForSellerByShopAccountDataAtomFamily,
  shopOrderListForSellerByShopAccountStatusAtomFamily,
  shopOrderListForSellerByShopDataAtomFamily,
  shopOrderListForSellerByShopStatusAtomFamily,
  shopOrderListForStoreDataAtom,
  shopOrderListForStoreStatusAtom,
  shopOrderListForWorkerDataAtom,
  shopOrderListForWorkerStatusAtom,
  shopOrderNotPaidListForSellerByShopDataAtomFamily,
  shopOrderNotPaidListForSellerByShopStatusAtomFamily,
  shopOrderWaitingListForSellerByShopDataAtomFamily,
  shopOrderWaitingListForSellerByShopStatusAtomFamily,
} from '@shared/api/shopOrder/shopOrder.atom';
import {
  ShopOrderForSeller,
  ShopOrderForSellerAccount,
  ShopOrderForWorker,
  ShopOrderListByBugoState,
  ShopOrderListForAgencyAdminState,
} from '@shared/api/shopOrder/shopOrder.interface';
import { QueryDataState } from '@shared/interfaces';

import {
  shopOrderForStoreDataAtomFamily,
  shopOrderForStoreStatusAtomFamily,
} from './shopOrder.atom';
import {
  ShopOrderForStoreState,
  ShopOrderListForStoreState,
} from './shopOrder.interface';

export const shopOrderListByBugoStateSelector = selector<ShopOrderListByBugoState>({
  key: 'shopOrderListByBugoStateSelector',
  get: ({ get }) => {
    return {
      status: get(shopOrderListByBugoStatusAtom),
      data: get(shopOrderListByBugoDataAtom),
    };
  },
});

export const shopOrderListForStoreStateSelector = selector<ShopOrderListForStoreState>({
  key: 'shopOrderListForStoreStateSelector',
  get: ({ get }) => {
    return {
      status: get(shopOrderListForStoreStatusAtom),
      data: get(shopOrderListForStoreDataAtom),
    };
  },
});

export const shopOrderForStoreStateSelectorFamily = selectorFamily<
  ShopOrderForStoreState,
  string
>({
  key: 'shopOrderForStoreStateSelectorFamily',
  get:
    (shopOrderId) =>
    ({ get }) => {
      return {
        status: get(shopOrderForStoreStatusAtomFamily(shopOrderId)),
        data: get(shopOrderForStoreDataAtomFamily(shopOrderId)),
      };
    },
});

//*상조관리자가 볼 화환내역 페이지용

export const shopOrderListForAgencyAdminStateSeletor =
  selector<ShopOrderListForAgencyAdminState>({
    key: 'shopOrderListForAgencyAdminStateSeletor',
    get: ({ get }) => {
      return {
        status: get(shopOrderListForAgencyAdminStatusAtom),
        data: get(shopOrderListForAgencyAdminDataAtom),
      };
    },
  });

//*상조관리자가 볼 화환내역 페이지용 (refactoring)

export const shopOrderListAgencyAdminStateSeletor =
  selector<ShopOrderListAgencyAdminState>({
    key: 'shopOrderListAgencyAdminStateSeletor',
    get: ({ get }) => {
      return {
        status: get(shopOrderListAgencyAdminStatusAtom),
        data: get(shopOrderListAgencyAdminDataAtom),
      };
    },
  });

//* seller용 배송관리용
export const shopOrderListForSellerByShopStateSelectorFamily = selectorFamily<
  QueryDataState<ShopOrderForSeller[]>,
  string
>({
  key: 'shopOrderListForSellerByShopStateSelectorFamily',
  get:
    (shopOrderId) =>
    ({ get }) => {
      return {
        status: get(shopOrderListForSellerByShopStatusAtomFamily(shopOrderId)),
        data: get(shopOrderListForSellerByShopDataAtomFamily(shopOrderId)),
      };
    },
});

export const shopOrderWaitingListForSellerByShopStateSelectorFamily = selectorFamily<
  QueryDataState<ShopOrderForSeller[]>,
  string
>({
  key: 'shopOrderWaitingListForSellerByShopStateSelectorFamily',
  get:
    (shopOrderId) =>
    ({ get }) => {
      return {
        status: get(shopOrderWaitingListForSellerByShopStatusAtomFamily(shopOrderId)),
        data: get(shopOrderWaitingListForSellerByShopDataAtomFamily(shopOrderId)),
      };
    },
});

export const shopOrderNotPaidListForSellerByShopStateSelectorFamily = selectorFamily<
  QueryDataState<ShopOrderForSeller[]>,
  string
>({
  key: 'shopOrderNotPaidListForSellerByShopStateSelectorFamily',
  get:
    (shopOrderId) =>
    ({ get }) => {
      return {
        status: get(shopOrderNotPaidListForSellerByShopStatusAtomFamily(shopOrderId)),
        data: get(shopOrderNotPaidListForSellerByShopDataAtomFamily(shopOrderId)),
      };
    },
});

//* seller용 account 용
export const shopOrderListForSellerByShopAccountStateSelectorFamily = selectorFamily<
  QueryDataState<ShopOrderForSellerAccount[]>,
  string
>({
  key: 'shopOrderListForSellerByShopAccountStateSelectorFamily',
  get:
    (shopOrderId) =>
    ({ get }) => {
      return {
        status: get(shopOrderListForSellerByShopAccountStatusAtomFamily(shopOrderId)),
        data: get(shopOrderListForSellerByShopAccountDataAtomFamily(shopOrderId)),
      };
    },
});

//* worker account 용
export const shopOrderListForWorkerSelector = selector<
  QueryDataState<ShopOrderForWorker[]>
>({
  key: 'shopOrderListForWorkerSelector',
  get: ({ get }) => {
    return {
      status: get(shopOrderListForWorkerStatusAtom),
      data: get(shopOrderListForWorkerDataAtom),
    };
  },
});
