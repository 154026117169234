import { FC } from 'react';

import { DeliveryManageCard } from '@service/seller/components/DeliveryManageCard';
import { ShopOrderForSeller } from '@shared/api/shopOrder/shopOrder.interface';
import { DeliveryState, PaymentState } from '@shared/types';
import { Tabs, TabsProps } from 'antd';

interface Props {
  shopOrderList: ShopOrderForSeller[];
}

export const DeliveryManageContent: FC<Props> = function DeliveryManageContent({
  shopOrderList,
}: Props) {
  const shopOrderNotDeliveredListDisplay = shopOrderList.filter((shopOrder) => {
    if (
      [PaymentState.PAID, PaymentState.CONFIRMED].includes(
        shopOrder.paymentDetail.status,
      ) &&
      shopOrder.deliveryDetail.status !== DeliveryState.DELIVERED
    ) {
      return true;
    } else {
      return false;
    }
  });
  const shopOrderDeliveredListDisplay = shopOrderList.filter((shopOrder) => {
    if (
      [PaymentState.PAID, PaymentState.CONFIRMED].includes(
        shopOrder.paymentDetail.status,
      ) &&
      shopOrder.deliveryDetail.status === DeliveryState.DELIVERED
    ) {
      return true;
    } else {
      return false;
    }
  });

  const shopOrderCacnceledListDisplay = shopOrderList.filter((shopOrder) => {
    if (
      [PaymentState.CANCELED, PaymentState.CANCEL_REQUESTED].includes(
        shopOrder.paymentDetail.status,
      )
    ) {
      return true;
    } else {
      return false;
    }
  });

  const items: TabsProps['items'] = [
    {
      key: 'notDelivered',
      label: <div className="px-4 text-gray-700">배송 필요</div>,
      children: (
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}
          {shopOrderNotDeliveredListDisplay.map((order) => (
            <DeliveryManageCard order={order} key={order._id} />
          ))}
        </div>
      ),
    },
    {
      key: 'delivered',
      label: <div className="px-4 text-gray-500">배송 완료</div>,
      children: (
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}
          {shopOrderDeliveredListDisplay
            .sort((a, b) => {
              const aDate = a.deliveryDetail.deliveredAt as Date;
              const bDate = b.deliveryDetail.deliveredAt as Date;
              return -aDate.getTime() + bDate.getTime();
            })
            .map((order) => (
              <DeliveryManageCard order={order} key={order._id} />
            ))}
        </div>
      ),
    },
    {
      key: 'canceled',
      label: <div className="px-4 text-gray-500">취소</div>,
      children: (
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card */}
          {shopOrderCacnceledListDisplay.map((order) => (
            <DeliveryManageCard order={order} key={order._id} />
          ))}
        </div>
      ),
    },
  ];

  return (
    <div className="space-y-3">
      <h2 className="text-xl font-medium leading-6 text-gray-900">주문 배송 상태</h2>
      <Tabs defaultActiveKey="notDelivered" items={items}></Tabs>
    </div>
  );
};
