import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { bugoBrandListStateSelector } from '@shared/api/bugoBrand/bugoBrand.selector';
import { Button } from '@shared/components/Button';
import { Icon } from '@shared/components/icons';
import { Link } from 'react-router-dom';

import { AdminBugoBrandListTable } from '../containers/AdminBugoBrandListTable/AdminBugoBrandListTable';
import { useAdminTitleHook } from '../hooks/useAdminTitleHook';

//* Admin 부고브랜드 페이지(홈)
//! 기능
// 1. 등록된 부고브랜드들을 살펴보는 페이지
// 2. 선택하여 수정하는 페이지로 이동
// 3. 생성페이지로 접근
const AdminBugoBrandPage: FC = function AdminBugoBrandPage() {
  useAdminTitleHook('상조관리');
  const { PlusSolid } = Icon();
  const bugoBrandListState = useRecoilValue(bugoBrandListStateSelector);
  const bugoBrandListRender = useMemo(() => {
    if (bugoBrandListState.status === 'success' && bugoBrandListState.data) {
      const bugoBrandList = bugoBrandListState.data;
      return <AdminBugoBrandListTable bugoBrandList={bugoBrandList} />;
    }
  }, [bugoBrandListState.data, bugoBrandListState.status]);

  const createBugoBrandButton = useMemo(() => {
    return (
      <Button className="shadow-sm theme-bg-1">
        <Link to="add" className="center-box gap-1">
          <PlusSolid className="wh-4" />
          <p>상조생성</p>
        </Link>
      </Button>
    );
  }, [PlusSolid]);

  return (
    <div className="space-y-4 p-4">
      <div className="flex justify-end">{createBugoBrandButton}</div>
      {bugoBrandListRender}
    </div>
  );
};

export default AdminBugoBrandPage;
