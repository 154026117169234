export const checkIOS = (): boolean => {
  const varUA = navigator.userAgent.toLowerCase();
  if (
    varUA.indexOf('iphone') > -1 ||
    varUA.indexOf('ipad') > -1 ||
    varUA.indexOf('ipod') > -1
  ) {
    return true;
  } else return false;
};
