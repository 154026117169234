import { FC, ReactNode, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useFeventHook } from '@shared/api/fevent/fevent.hook';
import { FeventOne } from '@shared/api/fevent/fevent.interface';
import { feventStateSelectorFamily } from '@shared/api/fevent/fevent.selector';
import { Loading } from '@shared/components/Loading';
import { ParamStepperUnit } from '@shared/components/ParamStepperUnit';
import { useSearchParams } from 'react-router-dom';

import FeventSendBugoForm from '../containers/FeventBugoSendForm/FeventSendBugoForm';
import FeventDeceasedInfoForm from '../containers/FeventDeceasedInfoForm/FeventDeceasedInfoForm';
import FeventMournerInfoForm from '../containers/FeventMournerInfoForm/FeventMournerInfoForm';
import { useTitleHook } from '../hooks/useTitleHook';
import { FeventFormUseMode } from '../utils/feventAddEdit.util';

const getPage = (step: number, fevent?: FeventOne): ReactNode | null => {
  switch (step) {
    case 0:
      return <FeventDeceasedInfoForm fevent={fevent} />;
    case 1:
      if (fevent) {
        return <FeventMournerInfoForm fevent={fevent} />;
      } else {
        return;
      }
    case 2:
      if (fevent) {
        return <FeventSendBugoForm fevent={fevent} useMode={FeventFormUseMode.Add} />;
      } else {
        return;
      }
    default:
      return;
  }
};

const FeventAddPage: FC = () => {
  useTitleHook('부고 등록', undefined, undefined, true, '/');

  const [searchParams, setSearchParams] = useSearchParams();
  const feventId = searchParams.get('feventId');
  const step = searchParams.get('step');
  const stepNumber = Number(step);

  useFeventHook(feventId ?? 'undefined', !feventId);
  const feventState = useRecoilValue(feventStateSelectorFamily(feventId ?? 'undefined'));

  const stepNameList = ['고인정보', '상주정보', '부고전송'];
  const formRender = useMemo(() => {
    if (feventId) {
      if (feventState.status === 'success' && feventState.data) {
        const fevent = feventState.data;
        return getPage(stepNumber, fevent);
      } else {
        return (
          <div className="center-box h-screen-15">
            <Loading />
          </div>
        );
      }
    } else {
      return getPage(stepNumber);
    }
  }, [feventId, feventState.data, feventState.status, stepNumber]);

  return (
    <div className="space-y-6 p-6">
      {/* stepper */}
      <div className="px-6">
        <ParamStepperUnit
          step={stepNumber}
          stepNameList={stepNameList}
          onStepClick={(stepNumber: number) => {
            feventId
              ? setSearchParams({ feventId: feventId, step: String(stepNumber) })
              : setSearchParams({ step: String(stepNumber) });
          }}
        />
      </div>
      {formRender}
    </div>
  );
};

export default FeventAddPage;
