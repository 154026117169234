import { FC } from 'react';

import { IconFormer } from '@shared/components/IconsFormer';
import { Sidebar } from '@shared/components/Sidebar';
import { useAuth } from '@shared/state/hooks/useAuth';

// const { Delivery, Flower, Modify, Receipt, Home, Question, Setting } = Icon();

const navigation = (shopId: string, isAdmin: boolean) => [
  {
    name: 'Home',
    href: `/flower-seller/shop/${shopId}`,
    icon: IconFormer.Home,
    active: true,
  },
  {
    name: '주문배송관리',
    href: `/flower-seller/shop/${shopId}/delivery-manage`,
    icon: IconFormer.DeliveryBlue,
    active: true,
  },
  {
    name: '과거배송검색',
    href: `/flower-seller/shop/${shopId}/past-delivery`,
    icon: IconFormer.MagnifyingGlassRegular,
    active: true,
  },

  {
    name: '부고조회',
    href: `/flower-seller/shop/${shopId}/bugo-search`,
    icon: IconFormer.DPBlue,
    active: true,
  },
  {
    name: '입금대기',
    href: `/flower-seller/shop/${shopId}/waiting-list`,
    icon: IconFormer.Loading,
    active: true,
  },
  {
    name: isAdmin ? '도매상품메뉴관리' : '상품메뉴관리',
    href: `/flower-seller/shop/${shopId}/shop-item-info`,
    icon: IconFormer.Flower,
    active: true,
  },
  {
    name: '소매상품메뉴관리',
    href: `/flower-seller/shop/${shopId}/shop-item`,
    icon: IconFormer.Flower,
    active: isAdmin,
  },
  {
    name: '정산',
    href: `/flower-seller/shop/${shopId}/account`,
    icon: IconFormer.ModifyBlue,
    active: true,
  },
  {
    name: '판매자 정보',
    href: `/flower-seller/shop/${shopId}/info`,
    icon: IconFormer.UserBlue,
    active: true,
  },
];
const secondaryNavigation = (shopId: string) => [
  {
    name: '설정',
    href: `/flower-seller/shop/${shopId}/settings`,
    icon: IconFormer.Setting,
  },
  { name: 'Help', href: `/flower-seller/shop/${shopId}/help`, icon: IconFormer.Question },
];

interface Props {
  shopId: string;
}

export const SellerSidebar: FC<Props> = function SellerSidebar({ shopId }: Props) {
  const { isAdmin } = useAuth();
  return (
    <Sidebar
      navigation={navigation(shopId, isAdmin())}
      secondaryNavigation={secondaryNavigation(shopId)}
    />
  );
};
