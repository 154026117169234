import { FC, useMemo } from 'react';

import { ShopData } from '@shared/api/shop/shop.interface';
import { phoneNumberStringFormator } from '@shared/utils/phoneNumberStringFormator';

interface IProps {
  shop: ShopData;
}

const SellerShopInfo: FC<IProps> = ({ shop }: IProps) => {
  const address = useMemo(() => {
    let result = shop.address;

    if (shop.address && shop.addressDetail) result += ' ';

    if (shop.addressDetail) result += shop.addressDetail;

    return result;
  }, [shop.address, shop.addressDetail]);

  return (
    <div className="space-y-4 whitespace-pre-line break-keep font-bold">
      <div className="grid grid-cols-5 items-center gap-4">
        <p className="col-span-2">상호</p>
        <p className="col-span-3 font-medium">{shop.name}</p>
      </div>
      <div className="grid grid-cols-5 items-center gap-4">
        <p className="col-span-2">대표전화번호</p>
        <p className="col-span-3 font-medium">
          {phoneNumberStringFormator(shop.phoneNumber)}
        </p>
      </div>
      <div className="grid grid-cols-5 items-center gap-4">
        <p className="col-span-2">사업자번호</p>
        <p className="col-span-3 font-medium">{shop.businessNumber}</p>
      </div>
      <div className="grid grid-cols-5 items-center gap-4">
        <p className="col-span-2">주소</p>
        <p className="col-span-3 font-medium">{address}</p>
      </div>
      <div className="grid grid-cols-5 items-center gap-4">
        <p className="col-span-2">우편번호</p>
        <p className="col-span-3 font-medium">{shop.postalCode}</p>
      </div>
    </div>
  );
};

export default SellerShopInfo;
