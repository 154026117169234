//* Stepper Unit은 말 그대로 스텝 보여주기 위한 놈

import React, { FC } from 'react';

import { CustomCallback } from '@shared/types';
import _ from 'lodash';

interface Props {
  step: number;
  stepNameList: string[];
  onStepClick: CustomCallback<number, void>;
}

export const ParamStepperUnit: FC<Props> = function ParamStepperUnit({
  step,
  stepNameList,
  onStepClick,
}) {
  const bgColorGenerator = (predicate: boolean) => {
    if (predicate) return 'theme-bg-main';
    return 'theme-bg-10';
  };

  const textColorGenerator = (predicate: boolean) => {
    if (predicate) return 'theme-text-1';
    return 'theme-text-8';
  };

  return (
    <div className="pb-6">
      <div className="relative flex items-center justify-between px-2">
        {_.map(stepNameList, (stepName, idx) => {
          return (
            <React.Fragment key={`stepper-status-${idx}`}>
              <div
                className="grid h-full w-[fit] place-items-center"
                onClick={() => {
                  onStepClick(idx);
                }}
              >
                <div
                  className={`h-10 w-10 ${bgColorGenerator(
                    step === idx,
                  )} z-10 grid place-items-center rounded-full pt-[2px] text-16 text-white`}
                >
                  <span>{idx + 1}</span>
                </div>
                <div
                  className={`absolute -bottom-[4px] h-5 translate-y-full text-center text-12 ${textColorGenerator(
                    step === idx,
                  )} ${step === idx ? 'font-bold' : ''}`}
                >
                  <span className="leading-5">{stepName}</span>
                </div>
              </div>
              {idx !== stepNameList.length - 1 && (
                <div className="h-[1px] flex-1 theme-bg-10"></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
