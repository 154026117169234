import { selector } from 'recoil';

import {
  enquiryListDataAtom,
  enquiryListStatusAtom,
  userEnquiryListDataAtom,
  userEnquiryListStatusAtom,
} from './enquiry.atom';
import { EnquiryListState, UserEnquiryListState } from './enquiry.interface';

export const enquiryListStateSelector = selector<EnquiryListState>({
  key: 'enquiryListStateSelector',
  get: ({ get }) => {
    return {
      status: get(enquiryListStatusAtom),
      data: get(enquiryListDataAtom),
    };
  },
});

export const userEnquiryListStateSelector = selector<UserEnquiryListState>({
  key: 'userEnquiryListStateSelector',
  get: ({ get }) => {
    return {
      status: get(userEnquiryListStatusAtom),
      data: get(userEnquiryListDataAtom),
    };
  },
});
