import { FC, useEffect, useMemo } from 'react';

import { useSetRecoilState } from 'recoil';

import { ShopData } from '@shared/api/shop/shop.interface';
import { ShopOrderForSellerAccount } from '@shared/api/shopOrder/shopOrder.interface';
import { CheckBoxFilter } from '@shared/components/CheckboxFilter';

import { sellerAccountBugoBrandFilterAtom } from './accountContent.atom';
import { AnnualAccountedContent } from './AnnualAccountedContent';
import { PresentAccountContent } from './PresentAccountContent';

interface Props {
  shopOrderList: ShopOrderForSellerAccount[];
  shop: ShopData;
}

export const SellerAccountContent: FC<Props> = function SellerAccountContent({
  shopOrderList,
  shop,
}: Props) {
  const setSellerAccountBugoBrandFilter = useSetRecoilState(
    sellerAccountBugoBrandFilterAtom,
  );

  // 정산이 필요한 녀석들만 고름
  const needAccountOrder = shopOrderList.filter((order) => {
    return !order.paymentDetail.isAccounted;
  });

  useEffect(() => {
    if (shop) {
      const result: CheckBoxFilter = {};
      shop.bugoBrands.forEach(
        (bugoBrand) => (result[bugoBrand?.bugoAgency?.nickName ?? bugoBrand._id] = true),
      );
      result['etc'] = true;
      setSellerAccountBugoBrandFilter(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const presentAccount = useMemo(() => {
    return <PresentAccountContent shopOrderList={needAccountOrder} />;
  }, [needAccountOrder]);

  return (
    <div className="space-y-4 py-4">
      {presentAccount}
      <AnnualAccountedContent shopOrderList={shopOrderList} />
    </div>
  );
};
