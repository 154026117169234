//* 상주가 직접 본인의 계좌를 세팅할때 사용함

import { FC, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import VerifiedCashAccountListTable from '@service/bugo/components/VerifiedCashAccountListTable';
import { bugoByMemberIdUrl } from '@shared/api/bugo/bugo.hook';
import { BugoData } from '@shared/api/bugo/bugo.interface';
import { CashAccount } from '@shared/api/cashAccount/cashAccount.interface';
import { updateMember } from '@shared/api/member/member.controller';
import { MemberUpdate } from '@shared/api/member/member.interface';
import { Button } from '@shared/components/Button';
import CashAccountForm from '@shared/components/CashAccountForm';
import Modal from '@shared/components/Modal';
import RadioInput from '@shared/components/RadioInput';
import ResizableBox from '@shared/components/ResizableBox';
import { customThemeAtom } from '@shared/state/atom/theme.atom';
import { CustomCallback, LowerRole } from '@shared/types';
import { mainColorGenerator } from '@shared/utils/mainColorGenerator';
import { useQueryClient } from 'react-query';

const DURATION = 0.3;

interface Props {
  openId: string;
  closeModal: CustomCallback<void, void>;
  bugo: BugoData;
  confirmBtnText?: string;
}

enum RadioEnum {
  MyAccount = 'myAccount',
  VerifiedAccount = 'verifiedAccount',
  None = 'none',
}

export const MemberCashAccountModal: FC<Props> = function MemberCashAccountModal({
  openId,
  closeModal,
  bugo,
  confirmBtnText = '확인',
}: Props) {
  const customTheme = useRecoilValue(customThemeAtom);
  const [radioValue, setRadioValue] = useState<RadioEnum | null>(null);

  const queryClient = useQueryClient();

  const [newVerifiedCashAccountId, setNewVerifiedCashAccountId] = useState<string | null>(
    bugo.member.cashAccount?.isVerified ? bugo.member.cashAccount?._id : null,
  );
  const [selectedVerifiedCashAccountId, setSelectedVerifiedCashAccountId] = useState<
    string | null
  >(null);

  const onVerifiedCashAccountChange = (cashAccount: CashAccount) => {
    setSelectedVerifiedCashAccountId(cashAccount._id);
  };

  const onCashAccountFormVerified = (cashAccountId: string) => {
    setNewVerifiedCashAccountId(cashAccountId);
  };

  const isConfirmable: boolean = useMemo(() => {
    switch (radioValue) {
      case RadioEnum.MyAccount:
        return !!newVerifiedCashAccountId;
      case RadioEnum.VerifiedAccount:
        return !!selectedVerifiedCashAccountId;
      case RadioEnum.None:
        return true;
      default:
        return false;
    }
  }, [newVerifiedCashAccountId, radioValue, selectedVerifiedCashAccountId]);

  const onConfirmClick = async () => {
    if (!isConfirmable && !closeModal) return;

    const memberUpdate: MemberUpdate = {
      _id: bugo.member._id,
      cashAccountModalConfirmed: true,
    };

    switch (radioValue) {
      case RadioEnum.VerifiedAccount:
        if (selectedVerifiedCashAccountId)
          memberUpdate.cashAccount = selectedVerifiedCashAccountId;
        break;
      case RadioEnum.MyAccount:
        if (newVerifiedCashAccountId) memberUpdate.cashAccount = newVerifiedCashAccountId;
        break;
      case RadioEnum.None:
        memberUpdate.cashAccount = null;
        break;
      default:
        break;
    }

    await updateMember(memberUpdate, LowerRole.Public);
    queryClient.invalidateQueries(bugoByMemberIdUrl(bugo.member._id));

    closeModal();
  };

  const verifiedCashAccountList = useMemo(
    () => bugo.fevent.cashAccounts?.filter((account) => account.isVerified),
    [bugo.fevent.cashAccounts],
  );

  return (
    <Modal openId={openId} position="center">
      <form className="space-y-4 font-sans text-xs">
        <h3 className="text-base font-bold">부의금(계좌) 정보 등록(수정)</h3>
        {/* Radios */}
        <div className="min-w-[288px] space-y-2 text-sm">
          <ResizableBox duration={DURATION}>
            <div className="rounded-lg border">
              <RadioInput
                label="본인 부의금(계좌) 등록"
                labelClassName={`${
                  radioValue === RadioEnum.MyAccount ? 'text-black' : 'theme-text-8'
                }`}
                value={RadioEnum.MyAccount}
                frameClassName="font-bold"
                name="radio"
                onChange={(event) =>
                  setRadioValue(event.currentTarget.value as RadioEnum)
                }
              />
              {/* 계좌 인증 */}
              {radioValue === RadioEnum.MyAccount && (
                <div className="p-2">
                  <CashAccountForm
                    feventId={bugo.fevent._id}
                    defaultCashAccount={bugo.member.cashAccount}
                    onVerified={onCashAccountFormVerified}
                  />
                </div>
              )}
            </div>
          </ResizableBox>
          {verifiedCashAccountList && verifiedCashAccountList.length > 0 && (
            <ResizableBox duration={DURATION}>
              <div className="rounded-lg border">
                <RadioInput
                  label="인증된 다른 상주님의 계좌 사용"
                  labelClassName={`${
                    radioValue === RadioEnum.VerifiedAccount
                      ? 'text-black'
                      : 'theme-text-8'
                  }`}
                  value={RadioEnum.VerifiedAccount}
                  frameClassName="font-bold"
                  name="radio"
                  onChange={(event) =>
                    setRadioValue(event.currentTarget.value as RadioEnum)
                  }
                />
                {/* 계좌 인증 */}
                {radioValue === RadioEnum.VerifiedAccount && (
                  <div className="p-2">
                    <VerifiedCashAccountListTable
                      cashAccountList={verifiedCashAccountList}
                      radioName="member-cash-account-modal-check"
                      onChange={onVerifiedCashAccountChange}
                      defaultCashAccountId={selectedVerifiedCashAccountId ?? undefined}
                    />
                  </div>
                )}
              </div>
            </ResizableBox>
          )}
          <div className="rounded-lg border">
            <RadioInput
              label="부의금(계좌) 사용하지 않음"
              labelClassName={`${
                radioValue === RadioEnum.None ? 'text-black' : 'theme-text-8'
              }`}
              value={RadioEnum.None}
              frameClassName="font-bold"
              name="radio"
              onChange={(event) => setRadioValue(event.currentTarget.value as RadioEnum)}
            />
          </div>
        </div>
        {/* Buttons */}
        <div className="pt-4 text-white">
          <Button
            text={confirmBtnText}
            onClick={onConfirmClick}
            className="button-rectangle font-medium transition-colors"
            style={{
              backgroundColor: isConfirmable
                ? mainColorGenerator(customTheme)
                : '#d1d5db', // gray-300
            }}
          />
        </div>
      </form>
    </Modal>
  );
};
