import { fetcher, queryString, useRecoilQuery } from '@shared/hooks/recoil-query';
import { useAuth } from '@shared/state/hooks/useAuth';
import { LowerRole } from '@shared/types';

import {
  bugoBrandAnnouncementDataAtomFamily,
  bugoBrandAnnouncementListDataAtom,
  bugoBrandAnnouncementListForWorkerDataAtom,
  bugoBrandAnnouncementListForWorkerStatusAtom,
  bugoBrandAnnouncementListStatusAtom,
  bugoBrandAnnouncementStatusAtomFamily,
} from './bugoBrandAnnouncement.atom';

export const bugoBrandAnnouncementHookUrl = (id: string, bugoRole: LowerRole) => {
  return `/${bugoRole}/bugo-brand-announcement/${id}`;
};

export const useBugoBrandAnnouncementHook = function (id: string, disabled?: boolean) {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    bugoBrandAnnouncementStatusAtomFamily(id),
    bugoBrandAnnouncementDataAtomFamily(id),
    bugoBrandAnnouncementHookUrl(id, bugoRole()),
    fetcher,
    disabled,
  );
};

//* for agency
export const bugoBrandAnnouncementListHookUrl = (bugoRole: LowerRole) => {
  return `/${bugoRole}/bugo-brand-announcement/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
  })}`;
};

//* for agency
export const useBugoBrandAnnouncementListhook = () => {
  const { bugoRole } = useAuth();
  useRecoilQuery(
    bugoBrandAnnouncementListStatusAtom,
    bugoBrandAnnouncementListDataAtom,
    bugoBrandAnnouncementListHookUrl(bugoRole()),
    fetcher,
  );
};

export const bugoBrandAnnouncementListForWorkerHookUrl = (
  bugoRole: LowerRole,
  teamType?: string,
) => {
  return `/${bugoRole}/bugo-brand-announcement/?${queryString({
    options: {
      sort: { createdAt: -1 },
    },
    filter: {
      teamTypes: teamType,
    },
  })}`;
};

export const useBugoBrandAnnouncementListForWorkerhook = () => {
  const { bugoRole, userProfile } = useAuth();
  const teamType = userProfile?.bugoAgencyWorkerDetail?.teamType;

  useRecoilQuery(
    bugoBrandAnnouncementListForWorkerStatusAtom,
    bugoBrandAnnouncementListForWorkerDataAtom,
    bugoBrandAnnouncementListForWorkerHookUrl(bugoRole(), teamType),
    fetcher,
  );
};
